import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import HomeController from '../Controller/HomeController'
import { setProps } from '../redux/actions'
import { Config, IISMethods, allowdedImage } from '../config/IISMethods'

const VerificationData = (props) => {
 
    useEffect(() => {
        async function fetchMyAPI() {
            await props.setProps({
                rightSidebarFormData:masterjson('verificationdatajson')
            })
        }

        fetchMyAPI()
    }, [])
    return (
        <HomeController
            masterjson={masterjson}
        />
    )
}

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    setProps: (payload) => dispatch(setProps(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(VerificationData)

const masterjson = (key) => {
    switch (key) {
    
        case 'verificationdatajson':
            return [
                {
                    rightsidebarsize: Config.getModalsizeclasses()['sm'],
                    pagename: 'verificationdata',
                    formname: 'verificationdata',
                    alias: 'verificationdata',
                    formfields: [
                        {
                             'field': 'selfphoto',
                    'text': 'Document File',
                    'type': Config.getHtmlcontorls()['image'],
                    'disabled': false,
                    'defaultvisibility': true,
                    'required': true,
                    'gridsize': Config.getGridclasses()['col-12']
                        }
                    ]
                }
                ]
    }
}