import React, { useEffect, useState } from 'react'
import Callhelp from '../assets/images/callhelp.png'
import Email from '../assets/images/email.png'
import Chatwithus from '../assets/images/Chatwithus.png'
import { Link } from 'react-router-dom'
import { setProps } from '../redux/actions'
import { connect } from 'react-redux'
import { store } from '..'
import Header from '../Header'
import Footer from '../Footer'
import { Placeholder } from 'rsuite'

const ContactUsView = (props) => {
    const getCurrentState = () => {
        return store.getState()['root']
    }

    //email id, phoneno and countrycode JENIL 13/9/2023 1:30 PM
    const [emailId, setEmailId] = useState("")
    const [phoneNo, setPhoneNo] = useState("")
    const [countryCode, setCountryCode] = useState("")

    //set data into state JENIL 13/9/2023 1:30 PM
    useEffect(() => {
        if (getCurrentState().masterData['wifidetails']?.length > 0) {
            if (getCurrentState().masterData['wifidetails'][0]["supportcontactemail"]) {
                setEmailId(getCurrentState().masterData['wifidetails'][0]["supportcontactemail"])
            } else {
                setEmailId("")
            }
            if (getCurrentState().masterData['wifidetails'][0]["supportcontactno"]) {
                setPhoneNo(getCurrentState().masterData['wifidetails'][0]["supportcontactno"].replace(/\D+/g, ''))
            } else {
                setPhoneNo("")
            }
            if (getCurrentState().masterData['wifidetails'][0]["supportcontactnocountrycode"]) {
                setCountryCode(getCurrentState().masterData['wifidetails'][0]["supportcontactnocountrycode"])
            } else {
                setCountryCode("")
            }
        } else {
            setEmailId("")
            setPhoneNo("")
            setCountryCode("")
        }
    }, [getCurrentState().masterData['wifidetails']])

    return (
        <>
            {/* <Header /> */}
            <Header />
            <div className="body-content">
                <div className="col-12">
                    <div className="set-data-id text-center">
                        <h1 className="contactus-main-label my-3 mx-auto">Contact Us</h1>
                        {
                            //state from homecontroller wifi details api calling then show simmer JENIL 13/9/2023 1:31 PM
                            props.contactUsLoad ?
                                <>
                                    <Placeholder.Graph active className='d-block mb-3'></Placeholder.Graph>
                                    <Placeholder.Graph active className='d-block mb-3'></Placeholder.Graph>
                                    <Placeholder.Graph active className='d-block mb-3'></Placeholder.Graph>
                                </>
                                :
                                <>
                                    {
                                        emailId ?
                                            <a href={`mailto:${emailId ? emailId : ""}`} className='d-block mb-3'>
                                                <div className="card-contact">
                                                    <div className="contact-imgs">
                                                        <img src={Email} alt="" />
                                                    </div>
                                                    <p className="text-black label-name-contact mt-2">Email Us</p>
                                                </div>
                                            </a>
                                            :
                                            ""
                                    }
                                    {
                                        phoneNo ?
                                            <a href={`tel:${countryCode ? "+" + countryCode : ""} ${phoneNo ? phoneNo?.replace(/\D+/g, '') : ""}`} className='d-block mb-3'>
                                                <div className="card-contact">
                                                    <div className="contact-imgs">
                                                        <img src={Callhelp} alt="" />
                                                    </div>
                                                    <p className="text-black label-name-contact mt-2">Call Us</p>
                                                </div>
                                            </a>
                                            :
                                            ""
                                    }
                                    {/* jatin 5-07-2023 */}
                                    <Link to={`/chatwithus`} className='d-block mb-3'>
                                        <div className="card-contact">
                                            <div className="contact-imgs">
                                                <img src={Chatwithus} alt="" />
                                            </div>
                                            <p className="text-black label-name-contact mt-2">Chat With Us</p>
                                        </div>
                                    </Link>
                                </>
                        }
                    </div>
                </div>
            </div>
            <Footer />
            {/* <div className="col-12">
                <div className="extra-space"></div>
              </div>
              <div className="col-12">
  
              </div> */}
        </>
    )
}


const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    setProps: (payload) => dispatch(setProps(payload))
})


export default connect(mapStateToProps, mapDispatchToProps)(ContactUsView)



