import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import HomeController from '../Controller/HomeController'
import { setProps } from '../redux/actions'

const ConatactUs = (props) => {

    useEffect(() => {
        async function fetchMyAPI() {
            await props.setProps({
                rightSidebarFormData: []
            })
        }

        fetchMyAPI()
    }, [])
    return (
        <HomeController
            masterjson={masterjson}
        />
    )
}


const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    setProps: (payload) => dispatch(setProps(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(ConatactUs)
const masterjson = (key) => {
    switch (key) {


        case 'contactus':
            return [
                {
                  
                }
            ]


    }
}