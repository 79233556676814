import React, { useEffect, useRef, useState } from 'react'
import { store } from '..'
import { Config, IISMethods, JsCall } from '../config/IISMethods'
// import Loader from './Components/Loader'
import { Loader } from 'rsuite';

import ModalRsuite from './Components/ModalRsuite'
import Capture from '../assets/images/capture.svg'
import { Carousel } from 'react-bootstrap'
import SelectPickerRsuite from './Components/SelectPickerRsuite'
import MultipleSelectDropdown from './Components/MultipleSelectDropdown'
import { Panel, PanelGroup, Placeholder } from 'rsuite'
import TooltipRsuite from './Components/TooltipRsuite'
import Rating from '../assets/images/rating.svg'
import DateRangePickerRsuite from './Components/DateRangePickerRsuite'
import DatePickerRsuite from './Components/DatePickerRsuite'
import { isBefore } from "date-fns"
import noimgfound from "../assets/images/no-product.png"

export default function ReportProblemRightSidebar(props) {

    //modal step of maintenance modal JENIL 23/8/2023 10:35 PM
    const [modalStep, setModalStep] = useState(1)
    //search product subcategory and products JENIL 23/8/2023 10:34 PM
    const [search, setSearch] = useState("")

    //selected item of maintenance JENIL 23/8/2023 10:41 AM
    const [selectedProduct, setSelectedProduct] = useState({})
    const [issueModal, setIssueModal] = useState(false);

    // srushti
    const getCurrentState = () => {
        return store.getState()['root']
    }
    // jatin 2:15 Pm For offline search in Room & Area Modal
    const [searchValue, setSearchValue] = useState('')
    const handleSearch = (e) => {
        setSearchValue(e.target.value)
    }
    const handleSearchClose = (e) => {
        setSearchValue('')
        var searchinput = document.querySelector('[class="form-control search-items"]')
        searchinput = document.getElementById(searchinput.id).value = "";
    }
    const form = 'form-'
    const star_fill = <svg className='star star-curser' xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 64 61.335">
        <path id="star_13_" data-name="star (13)" d="M63.833,23.669A3.4,3.4,0,0,0,60.9,21.33L42.433,19.653l-7.3-17.1a3.4,3.4,0,0,0-6.256,0l-7.3,17.094L3.093,21.33a3.406,3.406,0,0,0-1.931,5.953L15.125,39.528,11.008,57.666a3.4,3.4,0,0,0,5.059,3.675L32,51.817l15.928,9.524a3.4,3.4,0,0,0,5.062-3.675L48.873,39.528,62.836,27.285a3.4,3.4,0,0,0,1-3.616Zm0,0" transform="translate(0 -0.491)" fill="#ffc107" />
    </svg>
    const star_unfill = <svg className='star star-curser' xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 64 61.335">
        <path id="star_14_" data-name="star (14)" d="M14.325,61.827a3.407,3.407,0,0,1-3.315-4.16l4.117-18.137L1.165,27.284a3.4,3.4,0,0,1,1.928-5.953l18.475-1.677,7.3-17.094a3.4,3.4,0,0,1,6.256,0l7.3,17.1L60.9,21.331a3.405,3.405,0,0,1,1.934,5.953L48.875,39.527l4.117,18.137a3.4,3.4,0,0,1-5.061,3.675L32,51.818,16.069,61.344a3.409,3.409,0,0,1-1.744.483ZM32,47.717a3.41,3.41,0,0,1,1.744.482L48.78,57.192,44.894,40.074a3.4,3.4,0,0,1,1.078-3.315L59.156,25.2,41.712,23.612a3.4,3.4,0,0,1-2.827-2.062L32,5.418,25.107,21.552a3.388,3.388,0,0,1-2.818,2.054L4.842,25.19,18.026,36.753A3.4,3.4,0,0,1,19.1,40.071L15.221,57.189,30.256,48.2A3.408,3.408,0,0,1,32,47.717ZM21.427,19.984a.008.008,0,0,1,0,.005Zm21.142-.008,0,.005S42.571,19.979,42.568,19.976Zm0,0" transform="translate(0 -0.492)" fill="#ffe188" />
    </svg>

    const [floorwise, setFloorwise] = useState([])

    filterData = IISMethods.getCopy(getCurrentState().filterData)
    oldFilterData = IISMethods.getCopy(getCurrentState().oldFilterData)

    // srushti
    const handleFormData = (value, floorid) => {
        let floorwisedata = floorwise || []
        let floorobj = IISMethods.getobjectfromarray(getCurrentState().masterDataList['floorwisearea'], '_id', floorid)
        let valueobj = IISMethods.getobjectfromarray(floorobj.areadata, '_id', value)
        if (floorwisedata.length > 0) {
            let arrayindex = IISMethods.getindexfromarray(floorwisedata, 'areaid', value)
            if (arrayindex !== -1) {
                floorwisedata = floorwisedata.filter(data => data.areaid !== value)
            }
            else {
                floorwisedata = floorwisedata.concat([{ 'area': valueobj.area, 'areaid': valueobj._id }])
            }
        }
        else {
            floorwisedata = floorwisedata.concat([{ 'area': valueobj.area, 'areaid': valueobj._id }])
        }
        setFloorwise(floorwisedata)
    }

    // srushti
    const getColor = (colorCode, opacity) => {
        let r = colorCode.r || 0
        let g = colorCode.g || 0
        let b = colorCode.b || 0
        let a = opacity ? opacity : colorCode.a
        return `rgba(${r}, ${g}, ${b}, ${a})`
    }

    // for filter rendering
    const renderFilterHtmlControls = (formfield) => {
        if (formfield && (formfield.defaultvisibility)) {
            if (formfield.type === 'multipleselectdropdown') { // for multipleselect dropdown
                let value = getCurrentState().filterData[formfield.field] || []

                value = value.map((fieldid) => {
                    return IISMethods.getobjectfromarray(getCurrentState().masterDataList[formfield.masterdata], 'complaintstatustype', fieldid)['_id']
                })

                return (
                    <div className={formfield.gridsize} >
                        <div className="form-group validate-input">
                            <label className=" guest-request-maintenance-lable">{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}</label>
                            <MultipleSelectDropdown
                                cleanable={formfield.cleanable}
                                placeholder={`Select ${formfield.text}`} className="form-control has-val p-0"
                                data={getCurrentState().masterData[formfield.masterdata]}
                                id={"form-" + formfield.field}
                                value={value}
                                name={formfield.field}
                                onChange={(e) => props.handleFilterData(formfield.type, formfield.field, e, formfield)}
                                selectall={formfield.selectall}
                            />
                        </div>
                    </div>
                )
            } else if (formfield.type === 'datepicker') {
                let options = {
                    disabledDate: (date) => {
                        let fromdate = IISMethods.GetDateTimefromISOString(IISMethods.GetTZdatetime(date).toISOString())
                        if (formfield.disablefromdate) {
                            let selectedDate = IISMethods.GetDateTimefromISOString(formfield.disablefromdate)
                            selectedDate.setDate(selectedDate.getDate())
                            return isBefore(fromdate, selectedDate)
                        }
                        else if (formfield.disablefromfield) {
                            let selectedDate = IISMethods.GetDateTimefromISOString(getCurrentState().filterData[formfield.disablefromfield])
                            selectedDate.setDate(selectedDate.getDate())
                            return isBefore(fromdate, selectedDate)
                        }
                    }
                }
                let value = getCurrentState().filterData[formfield.field]
                return (
                    <div className={formfield.gridsize} >
                        <div className="form-group validate-input">
                            <label className="guest-request-maintenance-lable">{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}</label>
                            <DatePickerRsuite
                                className="form-control p-0 has-val"
                                placeholder={`Enter ${formfield.text}`}
                                format={IISMethods.getDateFormatByProperty()}
                                id={"form-" + formfield.field}
                                name={formfield.field}
                                value={value ? IISMethods.GetDT(IISMethods.GetISOdate(value)) : ''}
                                type="text"
                                onChange={(e) => props.handleFilterData(formfield.type, formfield.field, e, formfield)}
                                cleanable={formfield.cleanable}
                                disabled={formfield.disabled}
                                ranges={[]}
                                {...options}
                            />
                        </div>
                    </div>
                )
            }
            else {
                return <></>
            }
        }
    }

    // srushti
    const renderHtmlControls = (formfield) => {
        let condition = formfield?.condition ? getCurrentState().formData[formfield?.condition?.field] === formfield?.condition?.onvalue : false
        // if (formfield && (formfield.defaultvisibility || getCurrentState().formData[formfield.condition?.field] === formfield.condition?.onvalue)) {
        if (formfield && (formfield.defaultvisibility || condition)) {
            if (formfield.type === 'multipleselectdropdown') {

                let field = formfield.formdatafield + 'id'
                let value = getCurrentState().formData[formfield.field] || []
                try {
                    value = value.map((data) => data[field])
                }
                catch {
                    value = []
                }
                return (
                    <div className={formfield.gridsize} >
                        {
                            formfield.field !== 'issue'
                            &&
                            <div className='row'>
                                <div className='col-12'>
                                    <p className='report-or-border my-2'>Or</p>
                                </div>
                            </div>
                        }
                        <div className="form-group validate-input">
                            <label className=" guest-request-maintenance-lable">{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}</label>  {/* keval 6-4-2023*/}
                            <MultipleSelectDropdown
                                cleanable={formfield.cleanable}
                                placeholder={`Select ${formfield.text}`} className="form-control has-val p-0"
                                data={getCurrentState().masterData[formfield.masterdata]}
                                id={"form-" + formfield.field}
                                value={value}
                                name={formfield.field}
                                onChange={(e) => props.handleFormData(formfield.type, formfield.field, e)}
                            />
                        </div>
                    </div>
                )
            }
            else if (formfield.type === 'dropdown') {
                return (
                    <div className={formfield.gridsize}>
                        <div className="form-group validate-input">
                            <label className=" guest-request-maintenance-lable">{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}</label> {/* keval 6-4-2023*/}
                            {
                                formfield.field === "productid" ?
                                    <>
                                        {
                                            //add item structure JENIL 23/8/2023 1:14 PM
                                            // getCurrentState().formData["productdata"]?.length > 0 &&
                                            // <div className='product-list'>
                                            //     {
                                            //         getCurrentState().formData["productdata"].map((product, index) => {
                                            //             let image = getCurrentState().masterDataList["product"]?.find(a => a.productid === product.productid)?.image
                                            //             return (
                                            //                 <div className='single-product d-flex gap-10 align-items-start' key={product.productid + index.toString()}>
                                            //                     <div className='single-product-image pr-0'>
                                            //                         <img src={image ? image : noimgfound} alt="product" />
                                            //                     </div>
                                            //                     <div className='single-product-content'>
                                            //                         <div className='single-product-description mb-2'>
                                            //                             <span className='d-block text-14 text-dark font-weight-400'>{product.product}</span>
                                            //                         </div>
                                            //                         <>
                                            //                             {
                                            //                                 product?.issue?.length > 0 &&
                                            //                                 <div className='single-product-issue d-flex gap-5 flex-wrap'>
                                            //                                     {
                                            //                                         product?.issue?.map((issue, i) => {
                                            //                                             return (
                                            //                                                 <span key={issue._id + i.toString()} className='product-issue-tags'>{issue.issue}</span>
                                            //                                             )
                                            //                                         })
                                            //                                     }
                                            //                                 </div>
                                            //                             }
                                            //                         </>
                                            //                     </div>
                                            //                 </div>
                                            //             )
                                            //         })
                                            //     }
                                            // </div>
                                        }
                                        <div className="col-12 form-group col mt-2 px-0">
                                            <button onClick={(e) => { setModalStep(2) }} className="guest-request-add-item">
                                                Select Item
                                            </button>
                                        </div>
                                    </>
                                    :
                                    <SelectPickerRsuite
                                        cleanable={formfield.cleanable}
                                        // searchable={formfield.searchable}
                                        placeholder={`Select ${formfield.text}`}
                                        className="form-control has-val p-0"
                                        data={getCurrentState().masterData[formfield.masterdata]}
                                        id={"form-" + formfield.field}
                                        name={formfield.field}
                                        onChange={(e) => props.handleFormData(formfield.type, formfield.field, e)}
                                        value={getCurrentState().formData[formfield.field]}
                                        groupBy={"role"}
                                    />
                            }
                        </div>
                    </div>
                )
            }
            else if (formfield.type === 'table') {
                if (formfield.field === "productdata") {
                    return (
                        <div className='col-12'>
                            <div className="row">
                                <div className="col-12">
                                    {/* <div className="table-responsive project-table table-bordered mb-3"> */}   {/* keval 6-4-2023 */}
                                    {/* <div className="mt-3"> */}
                                    <div className="req-item-list mb-2">
                                        Problem Item List
                                    </div>
                                    <div className='table-responsive project-table'>
                                        <table className="table mb-0" id={'form-' + formfield.field}>
                                            <thead>
                                                <tr>
                                                    <th>{getCurrentState().rightSidebarFormData[0]?.formfields[4].text}</th>
                                                    <th>{getCurrentState().rightSidebarFormData[0]?.formfields[5].text}</th>
                                                    <th>{getCurrentState().rightSidebarFormData[0]?.formfields[6].text}</th>
                                                    <th className="text-center">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    getCurrentState().formData[formfield.field] && getCurrentState().formData[formfield.field].length > 0 ?
                                                        getCurrentState().formData[formfield.field] && getCurrentState().formData[formfield.field].map((data, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{data[getCurrentState().rightSidebarFormData[0]?.formfields[4].formdatafield]}</td>
                                                                    <td>{data[getCurrentState().rightSidebarFormData[0]?.formfields[5].masterdatafield]}</td>
                                                                    <td>{data[getCurrentState().rightSidebarFormData[0]?.formfields[6].field].map((item) => item.issue).join(', ')}</td>
                                                                    <td className="text-center close-button"><a className="action-col-icon"
                                                                        onClick={() => props.handleFormData('table-remove-button', formfield.field, index)}>
                                                                        {/* <i className="material-icons-round closeicon-rep-prob">close</i></a></td>*/}  {/*  keval 4-4-2023 */}
                                                                        <TooltipRsuite
                                                                            tooltip={Config.getTooltip()['remove']}
                                                                            content={
                                                                                <div className='close-icon'>
                                                                                    <i className="material-icons-round closeicon-guest-req d-block text-danger">close</i>
                                                                                </div>
                                                                            }
                                                                        />
                                                                    </a>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                        : <tr>
                                                            <td colSpan={12} className='text-14 font-weight-500 text-gray text-center'>No Data</td>
                                                        </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* </div> */}
                                </div>
                            </div>
                        </div>
                    )
                }
            }
            else if (formfield.type === 'radio') {
                return (
                    <div className={formfield.gridsize}>
                        <div className="form-group validate-input">
                            {/* <label className="guest-request-maintenance-lable">{formfield.text}{formfield.required}</label> */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="task-btn-custom-control " id={"form-" + formfield.field}>

                                        {formfield.field &&
                                            getCurrentState().masterData[formfield.masterdata]?.map((data, index) => {
                                                if (getCurrentState().formData._id && formfield.field !== "priorityid") {
                                                    if (getCurrentState().formData[formfield.field] === data.value) {
                                                        return (
                                                            <div className="custom-control " key={data.value}>
                                                                <input disabled={formfield.disabled} type="radio" id={formfield.field + data.value}
                                                                    readOnly name={formfield.field + data.value} className="custom-control-input" checked={getCurrentState().formData[formfield.field] === data.value} />

                                                                <label className="custom-control-label m-1 radio-label" htmlFor={formfield.field + data.value}
                                                                    onClick={() => !getCurrentState().formData._id && props.handleFormData(formfield.type, formfield.field, data.value)
                                                                        // && props.handleRoomAreaItems(data.value)
                                                                    }>{data.label}</label>
                                                            </div>
                                                        )
                                                    } else {
                                                        return <></>
                                                    }
                                                } else {
                                                    return (
                                                        <div className="custom-control" key={data.value}>
                                                            <input disabled={formfield.disabled} type="radio" id={formfield.field + data.value}
                                                                readOnly name={'form-' + formfield.field} className="custom-control-input"
                                                                defaultChecked={getCurrentState().formData[formfield.field] === data.value || index === 0}
                                                            />
                                                            {formfield.field === "priorityid"
                                                                ? <label className="custom-control-label m-1 radio-label" htmlFor={formfield.field + data.value} onClick={() => props.handleFormData(formfield.type, formfield.field, data.value)
                                                                    // && props.handleRoomAreaItems(data.value)
                                                                }
                                                                    style={{
                                                                        backgroundColor: getCurrentState().formData[formfield.field] === data.value ? `${getColor(getCurrentState().formData.color)}` : "white",
                                                                        color: getCurrentState().formData[formfield.field] === data.value ? "black" : "#000000"
                                                                    }}>{data.label}</label>
                                                                : <label className="custom-control-label radio-label" htmlFor={formfield.field + data.value} onClick={() => props.handleFormData(formfield.type, formfield.field, data.value)
                                                                    // && props.handleRoomAreaItems(data.value)
                                                                }
                                                                >{data.label}</label>
                                                            }
                                                        </div>
                                                    )
                                                }
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            else if (formfield.type === 'tree-label-picker') {

                return <div className="tree-tag-picker px-3 mb-3">
                    {getCurrentState().masterDataList?.floorwisearea?.map((data, index) => {
                        return <div className="tree-tag-control pt-3">
                            <label className="tree-tag-label">{data.floor}</label>
                            <div className="tree-label-box mt-1">
                                {data.areadata?.filter((data) => {
                                    // jatin 10-08-2023 for offline Search
                                    let serachfields = ["area"]
                                    let findvalue = serachfields.map(item => {
                                        let datafield = data[item].toLowerCase()
                                        let searchtext = searchValue.toLowerCase()
                                        if (datafield.includes(searchtext)) return true
                                        return data[item]
                                    })
                                    if (!searchValue || findvalue.some(item => item === true)) return true
                                    else return false
                                }).map((item, i) => {
                                    let dataindex = -1
                                    let opacity
                                    let backgroundcolor
                                    let textcolor
                                    if (floorwise?.length > 0) {
                                        dataindex = IISMethods.getindexfromarray(floorwise, 'areaid', item._id)
                                    }
                                    opacity = dataindex !== -1 ? 1 : item.isdisabled === 1 ? 0.3 : 0.5
                                    backgroundcolor = dataindex !== -1 ? item.areatypecolor : { r: 255, g: 255, b: 255, a: 1 }
                                    textcolor = dataindex !== -1 ? { r: 255, g: 255, b: 255, a: 1 } : item.areatypecolor
                                    return <label
                                        key={i}
                                        className="tree-label-text content-center px-4"
                                        style={{
                                            borderWidth: 2,
                                            borderStyle: 'solid',
                                            borderColor: getColor(item.areatypecolor, opacity),
                                            background: getColor(backgroundcolor, opacity),
                                            color: getColor(textcolor, opacity)
                                        }}
                                        onClick={() => {
                                            if (!item.isdisabled) handleFormData(item._id, data._id)
                                        }}
                                    >{item.area}</label>
                                })}

                            </div>
                        </div>
                    })}
                </div>
            }
            else if (formfield.type === 'multipletagpicker') {
                if (['tasksubcategoryid', 'roomtypeid', 'areatypeid'].includes(formfield.field)) {
                    return (
                        <div className={formfield.gridsize}>
                            <div className="task-tag-custom-control px-0 mb-2">
                                {
                                    getCurrentState().masterDataList[formfield.masterdata]?.map((data, index) => {
                                        let masterdata = data
                                        let formdata = getCurrentState().formData[formfield.field]
                                        var bg_r
                                        var bg_g
                                        var bg_b
                                        var bg_a

                                        var txt_r
                                        var txt_g
                                        var txt_b
                                        var txt_a

                                        try {
                                            bg_r = masterdata.backgroundcolor?.r
                                            bg_g = masterdata.backgroundcolor?.g
                                            bg_b = masterdata.backgroundcolor?.b
                                            bg_a = masterdata.backgroundcolor?.a

                                            txt_r = masterdata.textcolor?.r
                                            txt_g = masterdata.textcolor?.g
                                            txt_b = masterdata.textcolor?.b
                                            txt_a = masterdata.textcolor?.a
                                        }
                                        catch {
                                        }
                                        let bgcolor = `rgba(${bg_r},${bg_g},${bg_b},${bg_a})`
                                        let textcolor = `rgba(${txt_r},${txt_g},${txt_b},${txt_a})`
                                        let bordercolor = bgcolor
                                        let backgroundcolor = formdata.includes(data._id) ? bgcolor : textcolor
                                        let color = formdata.includes(data._id) ? textcolor : bgcolor

                                        return <div className="custom-control-tag" key={data._id}>
                                            <input disabled={formfield.disabled} type="checkbox" id={formfield.field + data._id}
                                                readOnly name={formfield.field + data._id} className="custom-control-input" />
                                            <label className={`${formfield.field === "tasksubcategoryid" ? "custom-label" : "custom-label-rt"} `} style={{
                                                borderRadius: '14px',
                                                border: `1px solid ${bordercolor}`,
                                                backgroundColor: backgroundcolor,
                                                color: color
                                            }} htmlFor={formfield.field + data._id} onClick={() => {
                                                props.handleFormData(formfield.type, formfield.field, data._id)
                                            }}>{data[formfield.masterdatafield]}</label>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    )
                } else {
                    return (
                        <div className={formfield.gridsize}>
                            <div className="form-group validate-input">
                                <label className="label-form-control">{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}</label>
                                <div className="row">
                                    <div className="col-12" >
                                        <div className="task-btn-custom-control border-0 d-flex" id={"form-" + formfield.field} >
                                            {
                                                getCurrentState().masterData[formfield.masterdata].map((data, index) => {
                                                    return (
                                                        <div className="custom-control custom-ckeckbox mb-3 mr-3 bg-white" key={index}>
                                                            <input disabled={formfield.disabled} type="checkbox" id={formfield.field + data.value}
                                                                readOnly name={formfield.field + data.value} className="custom-control-input" checked={getCurrentState().formData[formfield.field]?.includes(data.value)} />
                                                            <label className="custom-control-label-checkbox mb-0 text-center mr-2 rounded-circle content-center" htmlFor={formfield.field + data.value}
                                                                onClick={() => props.handleFormData(formfield.type, formfield.field, data.value)}>{data.label.substring(0, 3)}</label>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            }

            else if (formfield.type === 'input-textarea') // for textarea
            {
                return (

                    <div className={formfield.gridsize}>
                        <div className="form-group validate-input">
                            <label className="guest-request-maintenance-lable">{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}</label> {/* keval 6-4-2023*/}
                            <textarea
                                // disabled={disabled}
                                type="text"
                                onChange={(e) => props.handleFormData(formfield.type, formfield.field, e.target.value)}
                                value={getCurrentState().formData[formfield.field]}
                                className="form-control"
                                id={"form-" + formfield.field}
                                name={formfield.field}
                                autoComplete="off"
                                placeholder={`Enter ${formfield.text}`}
                            />
                        </div>
                    </div>
                )
            }
            else if (formfield.type === 'image') {
                const imagedata = getCurrentState().formData[formfield.field] || Config.getDefaulImageUploadUrl()
                return (
                    <div className={formfield.gridsize}>
                        <div className="form-group validate-input filepreview  capture-image-mainten">
                            <label className="guest-request-maintenance-lable">{formfield.text}{formfield.required}</label>
                            <input
                                accept={formfield.accepttype}
                                disabled={formfield.disabled}
                                type="file"
                                className="capture-input"
                                onChange={(e) => {
                                    props.handleFormData(formfield.type, formfield.field, e.target.files)
                                }}
                                id={"form-" + formfield.field}
                                name={formfield.field}
                                autoComplete="off"
                                multiple={formfield.multiple}
                            />
                            <img src={Capture} />
                            {/* <label className="filepreviewbg" htmlFor={"form-" + formfield.field}>
                                <img className="filepreviewimg" id={formfield.field} src={imagedata} alt="img" />
                            </label> */}
                        </div>
                    </div>
                )
            }
            else {
                return <></>
            }
        }
    }

    var rightSidebarFormData
    var rightSidebar
    var pageName
    var formData
    var formname
    var filterData
    var oldFilterData

    try {
        rightSidebar = 'rightSidebar'
        rightSidebarFormData = getCurrentState().rightSidebarFormData
        pageName = getCurrentState().rightSidebarFormData.pageName
        formname = 'form-'
        formData = IISMethods.getCopy(getCurrentState().rightSidebarFormData.formData)

        var dateValueFrom = filterData['fromdate']
        var dateValueTo = filterData['todate']

        var dateValue = (dateValueFrom && dateValueTo) ? [dateValueFrom, dateValueTo] : []
    }
    catch {
    }

    //handleSelect category of maintenance JENIL 23/8/2023 10:50 AM
    const handleSelectCategory = (category) => {
        setSelectedProduct({ ...selectedProduct, productsubcategoryid: category._id, productsubcategory: category.productsubcategory })
        setModalStep(3)
    }

    //handleSelect product of maintenance JENIL 23/8/2023 10:50 AM
    const handleSelectProduct = async (product) => {
        setSelectedProduct({ ...selectedProduct, product: product.productname, productid: product.productid + product.serialnumber })
        await props.getIssueList(product.productid)
        setIssueModal(true);
    }

    //handle issue select JENIL 23/8/2023 12:19 PM
    const handleSelectProductIssues = (issue) => {
        let changes = selectedProduct?.issue?.length > 0 ? [...selectedProduct?.issue] : []
        let index = changes.findIndex(a => a._id === issue._id);
        if (index >= 0) {
            changes.splice(index, 1)
        } else {
            changes.push(issue)
        }
        setSelectedProduct({ ...selectedProduct, issue: [...changes] })
    }

    try {

        return (
            <>
                {/* issue selection modal JENIL 23/8/2023 5:16 PM */}
                <ModalRsuite
                    size={"xs"}
                    open={issueModal}
                    onClose={() => {
                        setIssueModal(false)
                        setSelectedProduct({ ...selectedProduct, issue: [], serialnumber: "", product: "", productid: "" })
                    }}
                    title={
                        <div className="model-header icon">
                            {selectedProduct?.product}
                        </div>
                    }
                    body={
                        <>
                            <div className="col-12">
                                <div className='row'>
                                    <div className="col-12">
                                        <div className="form-group validate-input">
                                            <label className="label-form-control mb-3">Issue<span className="text-danger"> * </span></label>
                                            <div className="row">
                                                <div className="col-12" >
                                                    <div className="d-flex gap-5 align-items-center product-checkbox-block flex-wrap">
                                                        {
                                                            getCurrentState().masterDataList["issuemaster"]?.length > 0 &&
                                                            getCurrentState().masterDataList["issuemaster"]?.map((data, index) => {
                                                                let isChecked = selectedProduct["issue"]?.some(a => a._id === data._id)
                                                                return (
                                                                    <div className={`custom-control custom-ckeckbox pl-0 product-checkbox ${isChecked ? "fill-checkbox" : ""}`} key={data._id + index.toString()}>
                                                                        <input
                                                                            type="checkbox"
                                                                            id={"issuemater-id-" + data._id}
                                                                            readOnly
                                                                            name={"issuemater-id-" + data._id}
                                                                            className="custom-control-input"
                                                                            checked={isChecked ? true : false}
                                                                        />
                                                                        <label
                                                                            className="custom-control-label-checkbox mb-0"
                                                                            htmlFor={"issuemater-id-" + data._id}
                                                                            onClick={() => handleSelectProductIssues(data)}
                                                                        >
                                                                            {data.issue}
                                                                        </label>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    footer={
                        <div className="col-12 mt-1 mb-3">
                            <div className='row'>
                                <div className='col-6 pr-2'>
                                    <button
                                        onClick={() => {
                                            setIssueModal(false)
                                            setSelectedProduct({ ...selectedProduct, issue: [], serialnumber: "", product: "", productid: "" })
                                        }}
                                        className="btn btn-secondary-light btn-sm button" >
                                        Cancel
                                    </button>
                                </div>
                                <div className='col-6 pl-2'>
                                    <button
                                        onClick={async (e) => {
                                            if (selectedProduct?.issue?.length > 0) {
                                                // await props.handleFormData("dropdown", "productid", selectedProduct.productid)
                                                await props.handleItemAdd("productid", selectedProduct);
                                                setIssueModal(false)
                                                setModalStep(1);
                                                setSelectedProduct({})
                                            } else {
                                                IISMethods.notify("Please select issue", 2)
                                            }
                                        }}
                                        className="btn btn-primary-light btn-sm button">
                                        Apply
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                />
                {/* reportproblemModal srushti 28-2-23 */}

                <ModalRsuite
                    size={"xs"}
                    open={getCurrentState().modal['reportproblemModal']}
                    onClose={() => props.handleGrid(false, 'modal', 'reportproblemModal', 0)}
                    title={
                        <div className="model-header icon">
                            <span className="closeicon">
                                Report a Problem
                            </span>
                        </div>
                    }
                    body={
                        <div className="col-12">
                            {
                                modalStep === 1 &&
                                <div className="row">
                                    {renderHtmlControls(rightSidebarFormData[0]?.formfields[0])}
                                    {renderHtmlControls(rightSidebarFormData[0]?.formfields[1])}
                                    {renderHtmlControls(rightSidebarFormData[0]?.formfields[2])}
                                    {getCurrentState()?.formData?.assignmentforid === Config.assignmentfor.area &&
                                        /* <div className="col-12 col-md-12 my-auto content-center" > */    /* keval 5-4-2023 */
                                        <div className="col-12 col-md-12 mt-auto mb-3 content-center">
                                            <button className="btn-first-popup mb-0 bg-dark area-btn" onClick={async () => {
                                                await props.getAreaList()
                                                setFloorwise(getCurrentState().formData.areadetails)
                                            }}>Add Area</button>
                                        </div>
                                    }
                                    {
                                        getCurrentState().formData.areadetails?.map((areadata, i) => {
                                            return getCurrentState().masterDataList.floorwisearea?.map((floorwisedata, i) => {
                                                return floorwisedata?.areadata.filter(data => data._id === areadata.areaid).map((area, i) => {
                                                    let textcolor = area.areatypecolor
                                                    return (
                                                        <div className='col-6'>
                                                            <div className='position-relative'>
                                                                <span className='remove-roomarea' style={{ color: getColor(textcolor, 1) }}
                                                                    onClick={() => props.handleRemoveAreaItems(area._id)}
                                                                >
                                                                    <i className="material-icons-round">cancel</i>
                                                                </span>
                                                                <label
                                                                    className='tree-label-text content-center px-2 border-radius-5 '
                                                                    style={{
                                                                        borderWidth: 2, borderStyle: 'solid',
                                                                        borderColor: getColor(textcolor, 1),
                                                                        color: getColor(textcolor, 1),
                                                                        backgroundColor: getColor(textcolor, 0.1)
                                                                    }}
                                                                >{area.area}</label>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            })
                                        })
                                    }
                                    <div className='col-12 mb-3'>
                                        <Panel header="Add Items Of Complaint" collapsible bordered>
                                            {/* <Placeholder.Paragraph /> */}
                                            {renderHtmlControls(rightSidebarFormData[0]?.formfields[3])}
                                            {renderHtmlControls(rightSidebarFormData[0]?.formfields[4])}
                                            {renderHtmlControls(rightSidebarFormData[0]?.formfields[5])}
                                            {/* issue field comment JENIL 23/8/2023 5:22 PM */}
                                            {/* {renderHtmlControls(rightSidebarFormData[0]?.formfields[6])} */}
                                            {/* <div className='col-12'>
                                                <PanelGroup className='row'>
                                                    <div className='col-6 pr-2'>
                                                        <button
                                                            onClick={(e) => {
                                                                props.handleFormData('complaint-remove-data', 'productdata', 'submit');
                                                            }}
                                                            className="btn btn-secondary-light btn-sm py-10">
                                                            Cancel
                                                        </button>
                                                    </div>
                                                    <div className='col-6 pl-2'>
                                                        <button
                                                            onClick={(e) => {
                                                                props.handleFormData('table-add-button', 'productdata', 'submit')
                                                            }}
                                                            className="btn btn-primary-light btn-sm py-10">
                                                            Add Item
                                                        </button>
                                                    </div>
                                                </PanelGroup>
                                            </div> */}
                                        </Panel>
                                    </div>
                                    {getCurrentState()?.formData?.productdata?.length > 0 &&
                                        <>
                                            {renderHtmlControls(rightSidebarFormData[0]?.formfields[7])}
                                        </>
                                    }
                                    {renderHtmlControls(rightSidebarFormData[0]?.formfields[8])}
                                    {renderHtmlControls(rightSidebarFormData[0]?.formfields[9])}
                                    {renderHtmlControls(rightSidebarFormData[0]?.formfields[10])}
                                    <div className='col-12'>
                                        <div className='images-block'>
                                            {
                                                getCurrentState().formData.images?.map((item, index) => {
                                                    return (
                                                        <div className='d-inline-flex' key={index}>
                                                            <div className='img-block'>
                                                                <i className='material-icons-round text-danger cancel-btn' onClick={() => props.handleFormData('image', 'images', item, index)}>cancel</i>
                                                                <div className='img-wrap'>
                                                                    <img src={item} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                modalStep === 2 &&
                                <div className='categories-list'>
                                    <div className='search-categories'>
                                        <div className='back-icon d-flex align-items-center gap-5' onClick={() => {
                                            setModalStep(1);
                                            setSearch("")
                                        }}>
                                            <i className='material-icons-round text-18 font-weight-700'>arrow_back_ios</i>
                                            <span className='text-16 text-dark font-weight-600'>Add Item</span>
                                        </div>
                                        <div className='search-input-icon'>
                                            <i className="material-icons-round">search</i>
                                            <input
                                                type='text'
                                                placeholder='Search Category'
                                                value={search}
                                                onChange={(e) => setSearch(e?.target?.value ? e.target.value : "")}
                                            />
                                        </div>
                                    </div>
                                    {
                                        <div className='single-category-grid-list'>
                                            {
                                                getCurrentState().masterDataList["productsubcategory"]?.length > 0 &&
                                                getCurrentState().masterDataList["productsubcategory"].filter((category) => {
                                                    if (search?.trim().length === 0) {
                                                        return true
                                                    } else if (category?.productsubcategory?.replace(/\s+/g, '')?.toLowerCase()?.includes(search.replace(/\s+/g, '')?.toLocaleLowerCase())) {
                                                        return true
                                                    }
                                                })?.map((category, index) => {
                                                    return (
                                                        <div className='single-category' key={category._id + index.toString()}>
                                                            <div className='category-image' onClick={() => { handleSelectCategory(category) }}>
                                                                <img className={'active'} src={category?.icon ? category?.icon : noimgfound} alt="Category" />
                                                            </div>
                                                            <div className='category-name-info d-flex align-items-center mt-1 justify-content-center gap-5'>
                                                                <span className='product-name text-12 text-dark font-weight-600'>{category.productsubcategory}</span>
                                                                <TooltipRsuite
                                                                    placement={'top'}
                                                                    trigger="click"
                                                                    tooltip={category.productsubcategory}
                                                                    content={
                                                                        <span className="material-icons d-block product-info text-14 text-dark">info</span>
                                                                    } />
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                </div>
                            }
                            {
                                modalStep === 3 && selectedProduct?.productsubcategoryid &&
                                <div className='categories-list'>
                                    <div className='search-categories'>
                                        <div className='back-icon d-flex align-items-center gap-5' onClick={() => {
                                            setModalStep(2);
                                            setSearch("")
                                        }}>
                                            <i className='material-icons-round text-18 font-weight-700'>arrow_back_ios</i>
                                            <span className='text-16 text-dark font-weight-600'>{selectedProduct?.productsubcategory}</span>
                                        </div>
                                        <div className='search-input-icon'>
                                            <i className="material-icons-round">search</i>
                                            <input
                                                type='text'
                                                placeholder='Search Product'
                                                value={search}
                                                onChange={(e) => setSearch(e?.target?.value ? e.target.value : "")}
                                            />
                                        </div>
                                    </div>
                                    {
                                        <div className='single-category-grid-list'>
                                            {
                                                getCurrentState().masterDataList["product"]?.length > 0 &&
                                                getCurrentState().masterDataList["product"]?.filter(obj => obj.productsubcategoryid === selectedProduct?.productsubcategoryid)?.filter((product) => {
                                                    if (search?.trim().length === 0) {
                                                        return true
                                                    } else if (product?.productname?.replace(/\s+/g, '')?.toLowerCase()?.includes(search.replace(/\s+/g, '')?.toLocaleLowerCase())) {
                                                        return true
                                                    }
                                                })?.map((product, index) => {
                                                    return (
                                                        <div className='single-category' key={product.serialnumber + index.toString()}>
                                                            <div className='category-image' onClick={() => { handleSelectProduct(product) }}>
                                                                <img className='active' src={product?.image ? product?.image : noimgfound} alt="Category" />
                                                            </div>
                                                            <div className='category-name-info d-flex align-items-center mt-1 justify-content-center gap-5'>
                                                                <span className='product-name text-12 text-dark font-weight-600'>{product.productname}</span>
                                                                <TooltipRsuite
                                                                    placement={'top'}
                                                                    trigger="click"
                                                                    tooltip={product.productname}
                                                                    content={
                                                                        <span className="material-icons d-block product-info text-14 text-dark">info</span>
                                                                    } />
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    }
                    footer={
                        modalStep === 1 &&
                        <div className="col-12 mt-1">
                            <button className="btn-first-popup mb-3" type="submit" onClick={(e) => { getCurrentState().loading === 0 && props.handleAddButtonClick(e, 'reportproblemModal') }}>
                                {
                                    props.reportloder === true ?
                                    <Loader size="sm" content={'Submit'} />
                                    : "Submit"

                                }
                            </button>
                        </div>
                    }
                />

                <ModalRsuite
                    size={"xs"}
                    open={getCurrentState().modal['multipalimagemodal']}
                    onClose={() => props.handleGrid(false, 'modal', 'multipalimagemodal', 0)}
                    title={
                        <div className="model-header icon">
                            <span className="closeicon">
                                Photos
                            </span>
                        </div>
                    }
                    body={
                        <div className="col-12">
                            {
                                getCurrentState().data.filter(obj => obj._id === getCurrentState().modal['multipalimagemodal']).map((item, index) => {

                                    return (
                                        <div className="row" key={index}>
                                            {
                                                item.images?.length > 1
                                                    ?
                                                    <div className="col-12">
                                                        <Carousel interval={6000} fade={true} className="custom-slider">
                                                            {
                                                                item.images.map((data, index) => {
                                                                    let imagetype = data.image.split(/[#?]/)[0].split('.').pop().trim();

                                                                    if (['png', 'jpeg', 'gif', 'svg', 'jpg', 'svg', 'webp', 'svg+xml'].includes(imagetype)) {
                                                                        return (
                                                                            <Carousel.Item>
                                                                                <div className="multiple-image-slider" key={index}>
                                                                                    <img className='' src={data?.image} />
                                                                                </div>
                                                                            </Carousel.Item>
                                                                        )
                                                                    }
                                                                    else if (['mov', 'wmv', 'avi', 'mp4', 'avchd', 'flv', 'f4v', 'swf', 'mkv', 'webm'].includes(imagetype)) {
                                                                        return (
                                                                            <Carousel.Item>
                                                                                <div className="multiple-image-slider" key={index}>
                                                                                    <video controls="controls autoplay" className="attached-video d-block w-100">
                                                                                        <source src={data?.image} type={`video/${imagetype}`} />
                                                                                    </video>
                                                                                </div>
                                                                            </Carousel.Item>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </Carousel>
                                                    </div>
                                                    :
                                                    <>
                                                        {
                                                            ['png', 'jpeg', 'gif', 'svg', 'jpg', 'svg', 'webp', 'svg+xml'].includes(item.images[0]?.image.split(/[#?]/)[0].split('.').pop().trim())
                                                                ?
                                                                <div className="col-12">
                                                                    <div className="single-image">
                                                                        <img className='' src={item.images[0].image} />
                                                                    </div>
                                                                </div>
                                                                :
                                                                ['mov', 'wmv', 'avi', 'mp4', 'avchd', 'flv', 'f4v', 'swf', 'mkv', 'webm'].includes(item.images[0]?.image.split(/[#?]/)[0].split('.').pop().trim())
                                                                    ?
                                                                    <div className="col-12">
                                                                        <div className="single-image">
                                                                            <video controls="controls autoplay" className="attached-video d-block w-100">
                                                                                <source src={item.images[0]?.image} type={`video/${item.images[0].image?.split(/[#?]/)[0].split('.').pop().trim()}`} />
                                                                            </video>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <></>
                                                        }
                                                    </>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }
                />

                <ModalRsuite
                    size={"sm"}
                    open={getCurrentState().modal['areamodal']}
                    onClose={() => props.handleGrid(false, 'modal', 'areamodal', 0)}
                    title={
                        <div className="model-header icon">
                            <span className="closeicon">
                                Select Area
                            </span>
                        </div>
                    }
                    body={
                        <div className="col-12 mt-0 mb-0 px-0">
                            {renderHtmlControls(rightSidebarFormData[0]?.formfields[12])}
                            {/* jatin 2:15 Pm For offline search in Room & Area Modal */}
                            <div className="col-12 ml-auto">
                                <div className="validate-input my-1 position-relative">
                                    {
                                        searchValue?.length > 0 &&
                                        <label className="serachclose" onClick={(e) => { handleSearchClose(e) }}>
                                            <i className="material-icons-round text-12">close</i>
                                        </label>
                                    }
                                    <input className="form-control search-items" type="text" id="searchitemvalue" placeholder='Search Area' autoComplete='off' onChange={(e) => handleSearch(e)} />
                                </div>
                            </div>
                            {renderHtmlControls(rightSidebarFormData[0]?.formfields[11])}
                        </div>
                    }
                    footer={
                        <div className="col-12 mt-1 mb-3">
                            <div className='row'>
                                <div className='col-6 pr-2'>
                                    <button
                                        onClick={(e) => {
                                            props.handleGrid(false, 'modal', 'areamodal', 0)
                                        }}
                                        className="btn btn-secondary-light btn-sm button">
                                        Cancel
                                    </button>
                                </div>
                                <div className='col-6 pl-2'>
                                    <button
                                        onClick={(e) => {
                                            props.handleFormData('submit-floorwisedata', 'areadetails', floorwise)
                                            setFloorwise({})
                                            props.handleGrid(false, 'modal', 'areamodal', 0)
                                        }}
                                        className="btn btn-primary-light btn-sm button">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                />

                <ModalRsuite
                    size={"xs"}
                    open={getCurrentState().modal['reportratingmodal']}
                    onClose={() => props.handleGrid(false, 'modal', 'reportratingmodal', 0)}
                    title={""}
                    body={
                        <form method='post'>
                            <div className={`col-12 text-center`}>
                                <div className="first-pop-logo">
                                    <img src={Rating} />
                                </div>
                            </div>
                            <div className="col-12 text-center">
                                <h1 className="first-popup-title mt-2 mb-4">We Value Your Feedback</h1>
                            </div>
                            {
                                getCurrentState().rightSidebarFormData[0]?.formfields.filter(formfield => {
                                    try {
                                        return formfield.defaultvisibility || getCurrentState().formData[formfield.condition.field] === formfield.condition.onvalue
                                    }
                                    catch {
                                        return false
                                    }
                                }).map((formfield, fieldindex) => {
                                    if (formfield.type === 'dropdown') {
                                        return (
                                            <>
                                                {/* vehicle modal rating 17-4-23 */}

                                                {/* charmi(09-06-2023)(rating tag print change) */}
                                                {getCurrentState().masterDataList[formfield.masterdata]?.map((data, dataindex) => {
                                                    let tagdata = data.tagdata || []
                                                    let ratingdataobj = {}
                                                    ratingdataobj[dataindex] = []
                                                    let singlearray = ratingdataobj[dataindex]
                                                    for (const item of tagdata) {
                                                        if (getCurrentState().formData['complaintguestrating'] && getCurrentState().formData['complaintguestrating'][dataindex]['stars'] !== 0 && getCurrentState().formData['complaintguestrating'][dataindex]['stars'] <= item.stars && item.abovebelowflag === 1) {
                                                            let temptagdataarray = singlearray.map(data => data.tagid)
                                                            let tagdata = item.tags.filter(data => !temptagdataarray.includes(data.tagid))
                                                            singlearray.push(...tagdata)
                                                        }
                                                        else if (getCurrentState().formData['complaintguestrating'] && getCurrentState().formData['complaintguestrating'][dataindex]['stars'] !== 0 && getCurrentState().formData['complaintguestrating'][dataindex]['stars'] >= item.stars && item.abovebelowflag === 0) {
                                                            let temptagdataarray = singlearray.map(data => data.tagid)
                                                            let tagdata = item.tags.filter(data => !temptagdataarray.includes(data.tagid))
                                                            singlearray.push(...tagdata)
                                                        }
                                                    }
                                                    return (
                                                        <div className="col-12 mt-3">
                                                            <div className="rate-box">
                                                                <div className="rate-box-content">
                                                                    <p>
                                                                        {data.rating}
                                                                    </p>
                                                                </div>
                                                                <div className="d-flex justify-content-between mx-5">
                                                                    {
                                                                        // handlestars(formfield.field,dataindex,data._id)
                                                                        [...Array(5)].map((star, index) => {
                                                                            if (getCurrentState().formData['complaintguestrating'] && getCurrentState().formData['complaintguestrating'][dataindex]['stars'] - 1 >= index) {
                                                                                return <div className='my-3'
                                                                                    onClick={() => { props.handleFormData('add-remove-star', formfield.field, index, '', dataindex, data._id) }}
                                                                                >
                                                                                    {star_fill}
                                                                                </div>
                                                                            } else {
                                                                                return <div className='my-3'
                                                                                    onClick={() => { props.handleFormData('add-remove-star', formfield.field, index, '', dataindex, data._id) }}
                                                                                >
                                                                                    {star_unfill}
                                                                                </div>
                                                                            }
                                                                        })

                                                                    }
                                                                </div>
                                                                {
                                                                    singlearray?.length > 0
                                                                    &&
                                                                    <div className='ratingTagGrid'>
                                                                        {
                                                                            singlearray.map((tagitem, index) => {
                                                                                let tagdata = IISMethods.getindexfromarray(getCurrentState().formData['complaintguestrating'][dataindex]['tags'], 'tagid', tagitem.tagid)
                                                                                return (
                                                                                    <div className="task-tag-custom-control" key={index}>
                                                                                        <div className="custom-control-tag ">

                                                                                            <label className={`custom-label-rt content-center ${(tagdata !== -1) ? "tagactive" : ''}`}
                                                                                                onClick={() => props.handleFormData('add-remove-star', formfield.field, tagitem.tagid, 'tags', dataindex, tagitem.tag)}
                                                                                                style={{
                                                                                                    borderRadius: '20px',
                                                                                                    border: `1px solid #9E9E9E`,
                                                                                                    // background : 'black'
                                                                                                }} >
                                                                                                {tagitem.tag}
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                }
                                            </>
                                        )
                                    }
                                    else if (formfield.type === 'input-textarea') // for textarea
                                    {
                                        return (
                                            <div className={formfield.gridsize} key={`${fieldindex}`}>
                                                <div className="form-group validate-input mt-0">
                                                    <center><label className="first-popup-label my-3">{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}</label></center>
                                                    <textarea
                                                        disabled={formfield.disabled} type="text" rows={3}
                                                        defaultValue={getCurrentState().formData[formfield.field]}
                                                        onChange={(e) => props.handleFormData(formfield.type, formfield.field, e.target.value, '', 0, 'suggestion')}
                                                        className="form-control form-control-textarea"
                                                        id={form + formfield.field}
                                                        name={formfield.field} autoComplete="off"
                                                        placeholder={`Enter ${formfield.text}`}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            }

                        </form>
                    }
                    footer={
                        <>
                            {/* {(getCurrentState().formData.ratingbtnlink && getCurrentState().formData.ratingbtnlink) &&
                                <div className="col-12 mt-1">
                                    <a target='_blank' href={getCurrentState().formData.ratingbtnlink}>
                                        <span className='vehicle-model-btn text-center'>{getCurrentState().formData.ratingbtnname}</span>
                                    </a>
                                </div>
                            } */}
                            <div className="col-12 mt-1">
                                <button type="submit" className='btn-first-popup mb-3'
                                    onClick={(e) => {
                                        getCurrentState().loading === 0 && props.handleAddButtonClick(e, 'reportratingmodal', getCurrentState().formData.ratingbtnlink)
                                    }} >
                                    <Loader content={'Submit'} />
                                </button>
                            </div>
                        </>
                    }
                />

                {/* report problem filter modal 13-6-23 */}
                <ModalRsuite
                    size={"xs"}
                    open={getCurrentState().modal['reportfiltermodal']}
                    onClose={(e) => { props.handleGrid(false, 'modal', 'reportfiltermodal', 0); }}
                    title={
                        <div className="model-header icon">
                            <span className="closeicon">
                                Apply Filter
                            </span>
                        </div>
                    }
                    body={
                        <>
                            <div className="col-12 mt-0 mb-0 px-0">
                                {renderFilterHtmlControls(rightSidebarFormData[0]?.formfields[0])}
                                {renderFilterHtmlControls(rightSidebarFormData[0]?.formfields[1])}
                                {renderFilterHtmlControls(rightSidebarFormData[0]?.formfields[2])}
                            </div>
                            {/* <div className="col-11 mt-0 mb-0 px-0 ml-3">
                                <label className=" guest-request-maintenance-lable">Date</label>
                                <DateRangePickerRsuite
                                    className="form-control p-0 has-val" placeholder={`Enter Date`} format={IISMethods.getDateFormatByProperty()} id='filter-viewdate'
                                    name='filter-viewdate' ranges={[]}
                                    value={dateValue}
                                    onChange={(e) => props.handleFilterData('daterangepicker', '', e)}
                                />
                            </div> */}
                        </>
                    }
                    footer={
                        <div className="col-12 mt-1 mb-3">
                            <div className='row'>
                                <div className='col-6 pr-2'>
                                    <button
                                        onClick={async () => {
                                            await props.handleGrid(false, 'modal', 'reportfiltermodal', 0);
                                            await props.handleFilterData('filter-reset-data', '', '')
                                            await props.getList()
                                        }}
                                        className="btn btn-secondary-light btn-sm button">
                                        Reset
                                    </button>
                                </div>
                                <div className='col-6 pl-2'>
                                    <button
                                        onClick={(e) => {
                                            props.handleAddFilterButtonClick(e)
                                        }}
                                        className="btn btn-primary-light btn-sm button">
                                        <Loader content={'Apply'} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                />
            </>
        )
    }
    catch (e) {
        console.log(e)
        return <></>
    }
}