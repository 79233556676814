import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { store } from '..'
import { Config, IISMethods, JsCall, pagelimit, resBody, setReqParams } from '../config/IISMethods'
import { clearData, setLoading, setProps } from '../redux/actions'
import WifiView from '../view/WifiView'
import { useNavigate, useLocation } from 'react-router-dom'
import useFirebase from '../ServiceWorker/useFirebase'
import useAPICall from '../hooks/useApiCall'



// const WifiController = (props) => {
//     const { getReservationDetails, getAccessToken, getPropertyData } = useAPICall()

//     // for notification request
//     const { requestPermission } = useFirebase()

//     const getCurrentState = () => {
//         return store.getState()['root']
//     }

//     const history = useNavigate()
//     let subdomainname = IISMethods.getBookingIDSubDomain()['subdomainname']
//     let bookingid = IISMethods.getBookingIDSubDomain()['bookingid']
//     let querystring = IISMethods.getQueryString()

//     //first call - default props set & grid call - getlist
//     useEffect(() => {
//         async function fetchMastersJson() {
//             props.setProps({
//                 fieldOrder: [],
//                 newFieldOrder: [],
//                 nextpage: 0,
//                 pageNo: 1,
//                 pagelimit: 20,
//                 pageName: '',
//                 formName: '',
//                 sortData: {},
//                 formData: {},
//                 filterData: {},
//                 oldFilterData: {},
//                 modal: {},
//                 reservationDetails: {},
//                 propertyData: {},
//                 // rightSidebarFormData : [],
//                 masterData: {},
//                 masterDataList: {}
//             })

//             let guestInfo = IISMethods.getCookiesData(Config.cookiestorage['loginInfo'])
//             if (!guestInfo || !guestInfo.querystring || (guestInfo.querystring !== querystring)) {
//                 await getAccessToken()
//             }
//             else {
//                 await getReservationDetails()
//                 if (getCurrentState().reservationDetails.ischeckin === 1) await getPropertyData()
//             }

//             await props.clearData()           
            
//             if (getCurrentState().reservationDetails.ischeckin === 1) await setFormData(null, 'wifijson')
//         }
//         fetchMastersJson()
//     }, [])


//     // const getAccessToken = async () => {

//     //     const url = Config.weburl + 'getaccesstoken'
//     //     const reqData = {}
//     //     const headers = {
//     //         key: "Kiosist_98F2B6E0-240A-457B-853A-A0079862315F",
//     //         issuer: "website",
//     //         // key: process.env.REACT_APP_KEY,
//     //         // issuer: process.env.REACT_APP_ISSUER
//     //     }

//     //     await IISMethods.axiosrequest("POST", url, reqData, headers, successCallback, errorCallback);

//     //     var resp

//     //     function successCallback(response) {
//     //         resp = response
//     //     }
//     //     function errorCallback(error) {
//     //         resp = error
//     //     }

//     //     if (resp.status === 200) {
//     //         let obj = Object.assign({}, {})
//     //         if (subdomainname && bookingid) {
//     //             obj.subdomainname = subdomainname
//     //             obj.bookingid = bookingid
//     //             obj.querystring = querystring
//     //             obj.token = resp.headers.token
//     //             obj.unqkey = resp.data.data.unqkey
//     //             obj.uid = resp.data.data.uid
//     //             obj.step = 0
//     //             IISMethods.setCookiesData(Config.cookiestorage['loginInfo'], obj)
//     //             await getReservationDetails()
//     //             if (getCurrentState().reservationDetails.ischeckin === 1) {
//     //                 await getPropertyData()
//     //                 await requestPermission()
//     //             }
//     //         }
//     //     }
//     //     else {
//     //         localStorage.clear()
//     //     }
//     // }

//     // const getPropertyData = async () => {
//     //     const url = Config.weburl + 'property'
//     //     const useraction = 'listproperty'
//     //     const reqBody = {

//     //         paginationinfo: {
//     //             pageno: 1,
//     //             filter: {
//     //                 _id: getCurrentState().reservationDetails.propertyid
//     //             },
//     //             sort: {}
//     //         }
//     //     }

//     //     await setReqParams(url, reqBody)
//     //     await IISMethods.listData(useraction)

//     //     if (resBody.status === 200) {
//     //         let cookiedata = IISMethods.getCookiesData(Config.cookiestorage['loginInfo'])
//     //         cookiedata.companyid = resBody.data[0].companyid
//     //         IISMethods.setCookiesData(Config.cookiestorage['loginInfo'], cookiedata)
//     //         await props.setProps({ propertyData: resBody.data[0] })
//     //     }
//     // }

//     // const getReservationDetails = async () => {
//     //     const reqData = {
//     //         paginationinfo: {
//     //             pageno: 1,
//     //             pagelimit: 20,
//     //             filter: {
//     //                 _id: bookingid
//     //             },
//     //             sort: {}
//     //         }
//     //     }
//     //     if (bookingid !== null) {
//     //         const url = Config.weburl + "guestexpreservation"
//     //         await setReqParams(url, reqData)
//     //         await IISMethods.addData("reservation", "home")
            
//     //     }

//     //     if (resBody.status === 200) {
//     //         await props.setProps({ reservationDetails: resBody.data[0] })
//     //         if (resBody.data[0].ischeckin === 0) {
//     //             history(`/notcheckedin${querystring}`)
//     //         }
//     //         if(getCurrentState().reservationDetails.isreservationexpired ===  1){   
//     //             history({ pathname: `/linkexpired`, querystring })
//     //         }
//     //     }
//     //     else {
//     //     }
//     // }
    
//     //select picker printing 
//     const printSelectPicker = (data, fieldobj) => {
//         return (
//                 `${data[fieldobj.masterdatafield]}`
//         )
//     }

//     //for selectpicker data print and api call 
//     const getMasterData = async (pageNo, fieldobj, formData, storemasterdatabyfield) => {
//         var filter = {}
//         var isdepent = 0
//         if (fieldobj.dependentfilter) { //dependent filter set - object set
//             Object.keys(fieldobj.dependentfilter).map(key => {
//                 const value = formData[fieldobj.dependentfilter[key]]
//                 if (value) {
//                     isdepent = 1
//                     filter[key] = value
//                 }
//             })
//         }
//         if (fieldobj.staticfilter) { //static filter object assign
//             Object.assign(filter, fieldobj.staticfilter)
//         }

//         const masterdatakey = (fieldobj.storemasterdatabyfield || storemasterdatabyfield ? fieldobj.field : fieldobj.masterdata)
//         if (!fieldobj.masterdatadependancy || isdepent == 1) { //show only non dependent dropdown or dependent dropdown dependency fullfill
//             const url = Config.weburl + fieldobj.apipath
//             const useraction = 'list' + fieldobj.masterdata + 'data'

//             filter = {
//                 ...IISMethods.getCopy(fieldobj.filter),
//                 ...filter,
//             }
//             var reqBody

//             // for wifi listing api calling
//             if (fieldobj.field === 'wifidetails') {
//                 reqBody = {
//                     _id: getCurrentState().reservationDetails.propertyid
//                 }
//             }
//             else {
//                 reqBody = {
//                     paginationinfo: {
//                         pageno: pageNo,
//                         pagelimit: '*',
//                         filter: filter,
//                         sort: {}
//                     }
//                 }
//             }

//             await setReqParams(url, reqBody)
//             await IISMethods.listData(useraction, true)

//             if (resBody.status === 200) {
//                 getCurrentState().masterData = IISMethods.getCopy(getCurrentState().masterData)
//                 getCurrentState().masterDataList = IISMethods.getCopy(getCurrentState().masterDataList)
//                 if (pageNo === 1) {
//                     getCurrentState().masterData[masterdatakey] = []
//                     getCurrentState().masterDataList[masterdatakey] = []
//                 }
//                 resBody.data.map((data) =>
//                     getCurrentState().masterData[masterdatakey].push({ label: printSelectPicker(data, fieldobj), value: data._id })
//                 )
//                 getCurrentState().masterDataList[masterdatakey] = getCurrentState().masterDataList[masterdatakey].concat(resBody.data)
//                 await props.setProps({ masterData: getCurrentState().masterData, masterDataList: getCurrentState().masterDataList })
//                 if (resBody.nextpage)
//                     await getMasterData(pageNo + 1, fieldobj, formData, storemasterdatabyfield)
//             }
//             else {
//             }
//         }
//         else {
//             delete getCurrentState().masterData[masterdatakey]
//             delete getCurrentState().masterDataList[masterdatakey]
//             await props.setProps({ masterData: IISMethods.getCopy(getCurrentState().masterData), masterDataList: IISMethods.getCopy(getCurrentState().masterDataList) })
//         }
//     }

//     //edit button formdata set - props set 
//     const setFormData = async (id, jsoncase) => {
//         getCurrentState().rightSidebarFormData = props.masterjson(jsoncase)
//         var tempFormData = {}
//         if (id) {
//             tempFormData = IISMethods.getCopy(IISMethods.getobjectfromarray(getCurrentState().data, '_id', id))
//         }
//         else {
//             //formdata set 
//             getCurrentState().rightSidebarFormData && getCurrentState().rightSidebarFormData.map((data) => {
//                 return data.formfields.map((fields) => {
                   
//                         tempFormData[fields.field] = fields.defaultvalue !== undefined ? fields.defaultvalue : ''
                    
//                 })
//             })
//         }
//         await props.setProps({ formData: tempFormData })
//         //formdata set 

//         //select picker data call
//         for (const data of getCurrentState().rightSidebarFormData) {
//             for (const fields of data.formfields) {

//                 if (fields.masterdata && !fields.masterdataarray) {
//                     if (fields.masterdatadependancy || !getCurrentState().masterData[fields.masterdata]) //
//                         await getMasterData(1, fields, getCurrentState().formData) //
//                 }
//                 else if (fields.masterdata && fields.masterdataarray && !getCurrentState().masterData[fields.masterdata]) { //
//                     var array = []
//                     for (const object of fields.masterdataarray) {
//                         array.push({
//                             label: object,
//                             value: object
//                         })
//                     }
//                     getCurrentState().masterData[fields.masterdata] = array
//                     await props.setProps({ masterData: IISMethods.getCopy(getCurrentState().masterData) }) //
//                 }
//             }
//         }
//     }

//     const handleFormData = async (type, key, value, modalname) => {
//         if (type === 'modal') {
//             getCurrentState().formData[key] = IISMethods.getobjectfromarray(getCurrentState().masterDataList['wifisetting'], '_id', value)
//         }
//         await props.setProps({ formData: IISMethods.getCopy(getCurrentState().formData) })
//         await handleGrid(true, 'modal', modalname, 1)
//     }

//     //for open modal - recordinfo - create button
//     const handleGrid = (id, type, key, value) => {
//        if (type === 'dropdown') {
//             var object = IISMethods.getCopy(IISMethods.getobjectfromarray(getCurrentState().data, '_id', id))
//             const fieldobj = IISMethods.getobjectfromarray(getCurrentState().fieldOrder, 'field', key)
//             const masterdataobject = IISMethods.getobjectfromarray(getCurrentState().masterData[fieldobj.masterdata], 'value', value)
//             object[key] = masterdataobject.label
//             object[key + 'id'] = masterdataobject.value
//         }
//         else if (type === 'modal') {
//             if (value) {
//                 getCurrentState().modal[key] = id
//             }
//             else {
//                 delete getCurrentState().modal[key]
//             }
//             props.setProps({ modal: IISMethods.getCopy(getCurrentState().modal) })
//         }
//         else { }
//     }

//     return (
//         <>
//             <WifiView
//                 setFormData={setFormData}
//                 handleGrid={handleGrid}
//                 handleFormData={handleFormData}
//             />
//         </>
//     )
// }
const WifiController = (props) => {
    const { getAccessToken, getReservationDetails, getPropertyData } = useAPICall()
    // for notification request
    const { requestPermission } = useFirebase()

    const getCurrentState = () => store.getState()['root']

    // const history = useNavigate()
    // let subdomainname = IISMethods.getBookingIDSubDomain()['subdomainname']
    // let bookingid = IISMethods.getBookingIDSubDomain()['bookingid']
    // let querystring = IISMethods.getQueryString()

    //first call - default props set & grid call - getlist
    useEffect(() => {
        async function fetchMastersJson() {
            props.setProps({
                fieldOrder: [],
                newFieldOrder: [],
                nextpage: 0,
                pageNo: 1,
                pagelimit: 20,
                pageName: '',
                formName: '',
                sortData: {},
                formData: {},
                filterData: {},
                oldFilterData: {},
                modal: {},
                reservationDetails: {},
                propertyData: {},
                // rightSidebarFormData : [],
                masterData: {},
                masterDataList: {}
            })
            //(DO NOT CHANGE WITHOUT PERMISSION)charmi(06-09-2023 12:35)(listing api calling issue fix & install application modal open issue fix)
            await props.clearData()
            await getAccessToken()
            await getReservationDetails()
            if (getCurrentState().reservationDetails.ischeckin === 1) {
                await getPropertyData()
                if (getCurrentState().reservationDetails['isreservationexpired'] !== 1) {
                    await requestPermission()
                }
                await setFormData(null, 'wifijson')            
            }
            //(DO NOT CHANGE WITHOUT PERMISSION)charmi(06-09-2023 12:35)(listing api calling issue fix & install application modal open issue fix)
        }
        fetchMastersJson()
    }, [])


    // const getAccessToken = async () => {

    //     const url = Config.weburl + 'getaccesstoken'
    //     const reqData = {}
    //     const headers = {
    //         key: "Kiosist_98F2B6E0-240A-457B-853A-A0079862315F",
    //         issuer: "website",
    //         // key: process.env.REACT_APP_KEY,
    //         // issuer: process.env.REACT_APP_ISSUER
    //     }

    //     await IISMethods.axiosrequest("POST", url, reqData, headers, successCallback, errorCallback);

    //     var resp

    //     function successCallback(response) {
    //         resp = response
    //     }
    //     function errorCallback(error) {
    //         resp = error
    //     }

    //     if (resp.status === 200) {
    //         let obj = Object.assign({}, {})
    //         if (subdomainname && bookingid) {
    //             obj.subdomainname = subdomainname
    //             obj.bookingid = bookingid
    //             obj.querystring = querystring
    //             obj.token = resp.headers.token
    //             obj.unqkey = resp.data.data.unqkey
    //             obj.uid = resp.data.data.uid
    //             obj.step = 0
    //             IISMethods.setCookiesData(Config.cookiestorage['loginInfo'], obj)
    //             await getReservationDetails()
    //             if (getCurrentState().reservationDetails.ischeckin === 1) {
    //                 await getPropertyData()
    //                 await requestPermission()
    //             }
    //         }
    //     }
    //     else {
    //         localStorage.clear()
    //     }
    // }

    // const getPropertyData = async () => {
    //     const url = Config.weburl + 'property'
    //     const useraction = 'listproperty'
    //     const reqBody = {

    //         paginationinfo: {
    //             pageno: 1,
    //             filter: {
    //                 _id: getCurrentState().reservationDetails.propertyid
    //             },
    //             sort: {}
    //         }
    //     }

    //     await setReqParams(url, reqBody)
    //     await IISMethods.listData(useraction)

    //     if (resBody.status === 200) {
    //         let cookiedata = IISMethods.getCookiesData(Config.cookiestorage['loginInfo'])
    //         cookiedata.companyid = resBody.data[0].companyid
    //         IISMethods.setCookiesData(Config.cookiestorage['loginInfo'], cookiedata)
    //         await props.setProps({ propertyData: resBody.data[0] })
    //     }
    // }

    // const getReservationDetails = async () => {
    //     const reqData = {
    //         paginationinfo: {
    //             pageno: 1,
    //             pagelimit: 20,
    //             filter: {
    //                 _id: bookingid
    //             },
    //             sort: {}
    //         }
    //     }
    //     if (bookingid !== null) {
    //         const url = Config.weburl + "guestexpreservation"
    //         await setReqParams(url, reqData)
    //         await IISMethods.addData("reservation", "home")
            
    //     }

    //     if (resBody.status === 200) {
    //         await props.setProps({ reservationDetails: resBody.data[0] })
    //         if (resBody.data[0].ischeckin === 0) {
    //             history(`/notcheckedin${querystring}`)
    //         }
    //         if(getCurrentState().reservationDetails.isreservationexpired ===  1){   
    //             history({ pathname: `/linkexpired`, querystring })
    //         }
    //     }
    //     else {
    //     }
    // }
    
    //select picker printing 
   
    const printSelectPicker = (data, fieldobj) => {
        return (
                `${data[fieldobj.masterdatafield]}`
        )
    }

    //for selectpicker data print and api call 
    const getMasterData = async (pageNo, fieldobj, formData, storemasterdatabyfield) => {
        var filter = {}
        var isdepent = 0
        if (fieldobj.dependentfilter) { //dependent filter set - object set
            Object.keys(fieldobj.dependentfilter).map(key => {
                const value = formData[fieldobj.dependentfilter[key]]
                if (value) {
                    isdepent = 1
                    filter[key] = value
                }
            })
        }
        if (fieldobj.staticfilter) { //static filter object assign
            Object.assign(filter, fieldobj.staticfilter)
        }

        const masterdatakey = (fieldobj.storemasterdatabyfield || storemasterdatabyfield ? fieldobj.field : fieldobj.masterdata)
        if (!fieldobj.masterdatadependancy || isdepent == 1) { //show only non dependent dropdown or dependent dropdown dependency fullfill
            const url = Config.weburl + fieldobj.apipath
            const useraction = 'list' + fieldobj.masterdata + 'data'

            filter = {
                ...IISMethods.getCopy(fieldobj.filter),
                ...filter,
            }
            var reqBody

            // for wifi listing api calling
            if (fieldobj.field === 'wifidetails') {
                reqBody = {
                    _id: getCurrentState().reservationDetails.propertyid
                }
            }
            else {
                reqBody = {
                    paginationinfo: {
                        pageno: pageNo,
                        pagelimit: '*',
                        filter: filter,
                        sort: {}
                    }
                }
            }

            await setReqParams(url, reqBody)
            await IISMethods.listData(useraction, true)

            if (resBody.status === 200) {
                getCurrentState().masterData = IISMethods.getCopy(getCurrentState().masterData)
                getCurrentState().masterDataList = IISMethods.getCopy(getCurrentState().masterDataList)
                if (pageNo === 1) {
                    getCurrentState().masterData[masterdatakey] = []
                    getCurrentState().masterDataList[masterdatakey] = []
                }
                resBody.data.map((data) =>
                    getCurrentState().masterData[masterdatakey].push({ label: printSelectPicker(data, fieldobj), value: data._id })
                )

                getCurrentState().masterDataList[masterdatakey] = getCurrentState().masterDataList[masterdatakey].concat(resBody.data)
                await props.setProps({ masterData: getCurrentState().masterData, masterDataList: getCurrentState().masterDataList })
                if(fieldobj.apipath === "property/wifisetting"){
                    getCurrentState().formName = resBody.formname
                    props.setProps({ formName: getCurrentState().formName})
                }
                if (resBody.nextpage)
                    await getMasterData(pageNo + 1, fieldobj, formData, storemasterdatabyfield)
            }
            else {
            }
        }
        else {
            delete getCurrentState().masterData[masterdatakey]
            delete getCurrentState().masterDataList[masterdatakey]
            await props.setProps({ masterData: IISMethods.getCopy(getCurrentState().masterData), masterDataList: IISMethods.getCopy(getCurrentState().masterDataList) })
        }
    }

    //edit button formdata set - props set 
    const setFormData = async (id, jsoncase) => {
        getCurrentState().rightSidebarFormData = props.masterjson(jsoncase)
        var tempFormData = {}
        if (id) {
            tempFormData = IISMethods.getCopy(IISMethods.getobjectfromarray(getCurrentState().data, '_id', id))
        }
        else {
            //formdata set 
            getCurrentState().rightSidebarFormData && getCurrentState().rightSidebarFormData.map((data) => {
                return data.formfields.map((fields) => {
                   
                        tempFormData[fields.field] = fields.defaultvalue !== undefined ? fields.defaultvalue : ''
                    
                })
            })
        }
        await props.setProps({ formData: tempFormData })
        //formdata set 

        //select picker data call
        for (const data of getCurrentState().rightSidebarFormData) {
            for (const fields of data.formfields) {

                if (fields.masterdata && !fields.masterdataarray) {
                    if (fields.masterdatadependancy || !getCurrentState().masterData[fields.masterdata]) //
                        await getMasterData(1, fields, getCurrentState().formData) //
                }
                else if (fields.masterdata && fields.masterdataarray && !getCurrentState().masterData[fields.masterdata]) { //
                    var array = []
                    for (const object of fields.masterdataarray) {
                        array.push({
                            label: object,
                            value: object
                        })
                    }
                    getCurrentState().masterData[fields.masterdata] = array
                    await props.setProps({ masterData: IISMethods.getCopy(getCurrentState().masterData) }) //
                }
            }
        }
    }

    const handleFormData = async (type, key, value, modalname) => {
        if (type === 'modal') {
            getCurrentState().formData[key] = IISMethods.getobjectfromarray(getCurrentState().masterDataList['wifisetting'], '_id', value)
        }
        await props.setProps({ formData: IISMethods.getCopy(getCurrentState().formData) })
        await handleGrid(true, 'modal', modalname, 1)
    }

    //for open modal - recordinfo - create button
    const handleGrid = (id, type, key, value) => {
       if (type === 'dropdown') {
            var object = IISMethods.getCopy(IISMethods.getobjectfromarray(getCurrentState().data, '_id', id))
            const fieldobj = IISMethods.getobjectfromarray(getCurrentState().fieldOrder, 'field', key)
            const masterdataobject = IISMethods.getobjectfromarray(getCurrentState().masterData[fieldobj.masterdata], 'value', value)
            object[key] = masterdataobject.label
            object[key + 'id'] = masterdataobject.value
        }
        else if (type === 'modal') {
            if (value) {
                getCurrentState().modal[key] = id
            }
            else {
                delete getCurrentState().modal[key]
            }
            props.setProps({ modal: IISMethods.getCopy(getCurrentState().modal) })
        }
        else { }
    }

    return (
        <>
            <WifiView
                setFormData={setFormData}
                handleGrid={handleGrid}
                handleFormData={handleFormData}
            />
        </>
    )
}

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    clearData: (payload) => dispatch(clearData(payload)),
    setProps: (payload) => dispatch(setProps(payload)),
    setLoading: (payload) => dispatch(setLoading(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(WifiController)