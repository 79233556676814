
import React from 'react'
import { setProps } from '../redux/actions'
import { connect } from 'react-redux'
import RightSidebar from './RightSidebar'
import { useNavigate } from 'react-router-dom'
import CheckOutRightSidebar from './CheckOutRightSidebar'
import SecoundHouseKeepingRightSidebar from './SecoundHouseKeepingRightSidebar'
import LateCheckOutRightSidebar from './LateCheckOutRightSidebar'
import ExtendStayRightSidebar from './ExtendStayRightSidebar'
import { store } from '..'
import Header from '../Header'
import Footer from '../Footer'
import HousekeepingRating from './HouseKeepingRating'
import { Placeholder } from 'rsuite'
import WakeupCallRightsidebar from './WakeupCallRightsidebar'
import { IISMethods } from '../config/IISMethods'
import placeholderImg from '../assets/images/placeholderImgNotify.png'
import PWA from '../ServiceWorker/PWA'
import useIsIOS from '../hooks/useIsIOS'


const MyStayView = (props) => {

    const getCurrentState = () => {
        return store.getState()['root']
    }

    const guestIconData = getCurrentState().menuData ? getCurrentState().menuData : []
    const queryString = IISMethods.getQueryString()
    const history = useNavigate()
    const pagename = IISMethods.getpagename()

    const handleRedirectMenu = (alias) => {

        let menuindex = getCurrentState()?.menuData?.findIndex((a) => a.alias === alias)
        let obj = { ...getCurrentState().menuData[menuindex] }
        obj.ishighlight = 0
        getCurrentState().menuData?.splice(menuindex, 1, obj);
        props.setProps({ menuData: IISMethods.getCopy(getCurrentState().menuData) })

        let pagelink = queryString

        // for menu redirect 29-3-2023 srushti
        // reservatin exprire conditin manage with hide and show menu details
        if (!getCurrentState().reservationDetails.isguestcheckout || getCurrentState().reservationDetails.isguestcheckout === 0) {
            if (alias === 'wifi') {
                history(`/wifi`)
            }
            else if (alias === 'requestitem') {
                history(`/requestitem`)
            }
            else if (alias === 'maintenance') {
                history(`/maintenance`)
            }
            else if (alias === 'tvchannel') {
                history(`/tvchannellineup`)
            }
            else if (alias === 'reportproblem') {
                history(`/reportproblem`)
            }
            else if (alias === 'chatwithus') {
                history(`/chatwithus`)
            }
            else if (alias === 'callus') {
                history(`/contactus`)
            }
            else if (alias === 'lost') {
                history(`/lost`)
            }
            else if (alias === 'promotions') {
                history(`/promotions`)
            }
            else if (alias === 'survey') {
                history(`/survey`)
            }
            else if (alias === 'housekeeping') {
                if (getCurrentState().reservationDetails.enablehousekeepingrating === 1) {
                    props.setFormData(null, 'housekeepingrating', 'housekeepingratingModal')
                }
                else if (getCurrentState().reservationDetails.ischeckinday === 0) {
                    props.setFormData(null, 'housekeeping', 'housekeepingModal')
                }
                else if (getCurrentState().reservationDetails.ischeckinday === 1) {
                    props.setFormData(null, 'housekeepingtwo', 'housekeepingModal')
                }
            }
            else if (alias === 'checkout') {
                //when extend stay menu click open chatwithus and routes "checkout" is JENIL 03/08/2023 03:51 PM
                history(`/checkout`)
                // if (getCurrentState().reservationDetails.isguestexpcheckout === 1) {
                //     props.handleGrid(true, 'modal', 'checkoutRqstSubmitModal', 1)
                // }
                // else {
                //     props.setFormData(null, alias, alias + 'Modal')
                // }
                // if (getCurrentState().reservationDetails.isguestcheckout === 1 && getCurrentState().reservationDetails.checkoutratingsubmitted === 0) {
                // props.setFormData(null, 'checkoutrating', 'checkoutratingModal')
                // }
                // else if (getCurrentState().reservationDetails.isguestcheckout === 0) {
                //     props.setFormData(null, alias, alias + 'Modal')
                // }
                // else if (getCurrentState().reservationDetails.isguestcheckout === 1 && getCurrentState().reservationDetails.checkoutratingsubmitted === 1) {
                //     IISMethods.notify("You have already checkout.you can't perform this action.")
                // }
            }
            else if (alias === "extendstay") {
                //when extend stay menu click open extendstay JENIL 19/7/2023 11:08 AM
                history(`/extendstay`)
                // if (getCurrentState().reservationDetails.isalreadyrequested === 0) {
                //     props.setFormData(null, 'extendstay', 'extendstayModal')
                // }
                // else if (getCurrentState().reservationDetails.isalreadyrequested === 1) {
                //     props.handleGrid(true, 'modal', 'verificationdatamodal', 1)
                // }
            }
            else if (alias === "latecheckout") {
                //when extend stay menu click open "latecheckout" JENIL 19/7/2023 11:08 AM
                history(`/latecheckout`)
                // if (getCurrentState().reservationDetails.guestlatecheckoutrequest === 0) {
                //     props.setFormData(null, 'latecheckout', 'latecheckoutModal')
                // }
                // else if (getCurrentState().reservationDetails.guestlatecheckoutrequest === 1) {
                //     props.handleGrid(true, 'modal', 'latecheckoutdatamodal', 1)
                // }
            }
            else if (alias === 'contactus') {
                history(`/contactus`)
            }
            else if (alias === 'wakeupcall') {
                props.getList('property/guestexperience/wakeupcall', 'requestitem', alias, 'wakeupcallModal')
            }
            else {
                props.setFormData(null, alias, alias + 'Modal')
            }
        }
        else if (getCurrentState().reservationDetails.isguestcheckout === 1) {
            if (alias === 'reportproblem') {
                history(`/reportproblem`, { state: { pagename: 'reportproblem', goback: true } })
            }
            else if (alias === 'chatwithus') {
                history(`/chatwithus`)
            }
            else if (alias === 'callus') {
                history(`/contactus`)
            }
            else if (alias === 'wifi') {//charmi(06-09-2023 03:51)
                history(`/wifi`)
            }
            else if (alias === 'tvchannel') {//charmi(06-09-2023 03:51)
                history(`/tvchannellineup`)
            }
            else if (alias === 'lost') {//charmi(06-09-2023 03:51)
                history(`/lost`)
            }
            else if (alias === 'promotions') {//charmi(06-09-2023 03:51)
                history(`/promotions`)
            }
            else if (alias === 'survey') {//charmi(06-09-2023 03:51)
                history(`/survey`)
            }
            else {
                history(`/successfullycheckout`)//charmi(21-08-2023 03:04)
                // IISMethods.localnotify("You have already checkout.you can't perform this action.")//charmi(21-08-2023 03:04)
            }
        }
    }
    try {
        return (
            <>
                <Header />
                {/* for menu listing filterwise 29-3-2023 srushti */}
                <div className="body-content">
                    <div className="col-12">
                        {
                            // (getCurrentState().formName === '')
                            (getCurrentState().menuData.length === 0 && pagename === "mystay")
                                ?
                                <>
                                <div className="row">
                                    {
                                        [...Array(9)].map((data , index)=>{
                                            return <div className='col-4 col-sm-3 col-md-3 mx-0 box-space'>
                                            <Placeholder.Graph active className='mb-0' style={{height : "100px", width : "100%"}}> </Placeholder.Graph>
                                        </div>
                                        })
                                    }
                                </div>
                                </>
                                :
                                getCurrentState().menuData.length > 0 &&
                                <div className='row p-2'>
                                    {
                                        guestIconData.filter(obj => obj.mystay === 1).sort((a, b) => a.innerdashboardorder > b.innerdashboardorder ? 1 : -1).map((data, index) => {
                                            return (
                                                <>
                                                    <div className={`col-4 col-sm-3 col-md-3 mx-0 box-space${data.alias === 'dndon' && data.dndon === 1 ? ' dnd-on' : ''}`} key={index}>
                                                        <div className="card-stay">
                                                            <div onClick={() => { handleRedirectMenu(data.alias); }}>
                                                                <div className="card-stay-imgs" >
                                                                    {(data.dashboardicon !== '' || data.dashboardicon !== undefined) && <img src={data.dashboardicon} />}
                                                                </div>
                                                            </div>
                                                            {(data.ishighlight === 1) &&
                                                                <span className='blink-red-dot'></span>}
                                                            {(data.menuname !== '' || data.menuname !== undefined) && <p className="label-name-stay mt-2">{data.menuname}</p>}
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                                </div>
                        }
                    </div>
                </div>
                <Footer />

                <RightSidebar
                    handleFormData={props.handleFormData}
                    handleAddButtonClick={props.handleAddButtonClick}
                    handleGrid={props.handleGrid}
                    setFormData={props.setFormData}
                />

                <CheckOutRightSidebar
                    handleFormData={props.handleFormData}
                    handleAddButtonClick={props.handleAddButtonClick}
                    handleGrid={props.handleGrid}
                    setFormData={props.setFormData}
                    setCheckOutFormData={props.setCheckOutFormData}
                    getReservationDetails={props.getReservationDetails}
                />

                <SecoundHouseKeepingRightSidebar
                    handleFormData={props.handleFormData}
                    handleAddButtonClick={props.handleAddButtonClick}
                    handleGrid={props.handleGrid}
                    setFormData={props.setFormData}
                    HouseKeepingAddButtonClick={props.HouseKeepingAddButtonClick}
                />

                <WakeupCallRightsidebar
                    handleFormData={props.handleFormData}
                    handleAddButtonClick={props.handleAddButtonClick}
                    handleGrid={props.handleGrid}
                    setFormData={props.setFormData}
                />

                <LateCheckOutRightSidebar
                    handleFormData={props.handleFormData}
                    handleAddButtonClick={props.handleAddButtonClick}
                    handleGrid={props.handleGrid}
                    setFormData={props.setFormData}
                    HouseKeepingAddButtonClick={props.HouseKeepingAddButtonClick}
                    handleLateCheckout={props.handleLateCheckout}
                />

                <ExtendStayRightSidebar
                    handleFormData={props.handleFormData}
                    handleAddButtonClick={props.handleAddButtonClick}
                    handleGrid={props.handleGrid}
                    setFormData={props.setFormData}
                    HouseKeepingAddButtonClick={props.HouseKeepingAddButtonClick}
                    itemqty={props.itemqty}
                />

                <HousekeepingRating
                    handleFormData={props.handleFormData}
                    handleAddButtonClick={props.handleAddButtonClick}
                    handleGrid={props.handleGrid}
                    setFormData={props.setFormData}
                    HouseKeepingAddButtonClick={props.HouseKeepingAddButtonClick}
                />
            </>
        )
    } catch (error) {
    }

}

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    setProps: (payload) => dispatch(setProps(payload))
})


export default connect(mapStateToProps, mapDispatchToProps)(MyStayView)



