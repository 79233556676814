import React, { useEffect, useState } from 'react'
import BackHeader from '../BackHeader'
import { store } from '..'
import SignaturePad from 'react-signature-canvas'
import selfverificationimg from '../assets/images/selfverificationimg.png'
import { useLocation, useNavigate } from 'react-router-dom'
import { IISMethods } from '../config/IISMethods'
import Resizer from "react-image-file-resizer";
import Loader from './Components/Loader'
import Camera from '../assets/images/camera.png'

const VerificationDataView = (props) => {
    const loc = useLocation()
    const getCurrentState = () => {
        return store.getState()['root']
    }
    let sigPad = {}
    const clear = () => { sigPad.clear() }
    const styles = {
        sigContainer: {
            width: " 100%",
            height: " 100%",
        },
        signpad: {
            width: "100%",
            height: "205px",
            marginTop: "8px",
        }
    }


    const history = useNavigate()
    const urlparams = new URLSearchParams(window.location.search)
    const subdomainname = urlparams?.get('bookingid')?.split('-')[0]
    const bookingid = urlparams?.get('bookingid')?.split('-')[1]
    const search = `?bookingid=${subdomainname}-${bookingid}`
    const selfVerificationData = getCurrentState().reservationDetails
    var _URL = window.URL || window.webkitURL;
    const documentfile = selfVerificationData?.selfphoto
    const [photo, setPhoto] = useState(documentfile)
    const [flag, setFlag] = useState(0) //flag for display 2nd page
    const [tempphoto, setTempphoto] = useState('')

    const handlePhotoCapture = async (target) => {
        setFlag(1)

        if (target.files) {
            if (target.files.length !== 0) {
                var width
                var height
                let img = new Image();
                const file = target.files[0]
                const newUrl = URL.createObjectURL(file)
                img.onload = function () {
                    width = (img.width) / 2
                    height = (img.height) / 2
                    console.log('first', img.width, img.height)
                    var fileInput = false;
                    if (target.files[0]) {
                        fileInput = true;
                    }

                    if (fileInput) {
                        try {
                            console.log('width', width)
                            setTempphoto(target.files[0])
                            Resizer.imageFileResizer(
                                target.files[0],
                                width,
                                height,
                                "JPEG", 60, 0,
                                (uri) => {
                                    const base64Image = uri
                                    const yourBase64String = base64Image.substring(base64Image.indexOf(',') + 1);
                                    const kb = ((yourBase64String.length * 6) / 8) / 1000;
                                    console.log('kb', kb)
                                    // setnewImage(uri);
                                    // console.log('uri', uri)
                                    _URL.revokeObjectURL(uri);
                                    setPhoto(uri)
                                    props.handleFormData('images', 'selfphoto', uri)

                                    return uri
                                },
                                "base64"
                            );


                        } catch (err) {
                            console.log(err);
                        }
                    }
                };
                img.src = newUrl;

                // console.log('width convert', img.width,height)
                setPhoto(newUrl)
            }
        }
    }

    const renderHtmlControls = (formfield, btnname) => {
        try {
            console.log(formfield)
            if (formfield.type === "image") {

                return <>
                    <input
                        accept="image/*"
                        className='input d-none'
                        id="take-photo-icon"
                        type="file"
                        name="image"
                        capture="user" // front-camera
                        onChange={(e) => {
                            handlePhotoCapture(e.target)
                        }}
                    />

                    <label

                        className={(btnname === "Take A Photo Of Yourself") ? "btn submit-btn " : ""}
                        // className={(btnname === "Take A Photo Of Yourself") ? "" : "btn submit-btn btn-icon mb-0"}
                        // onClick={() => {
                        //     if (btnname === "Retake") { setFlag(0) }
                        //     else if (btnname === 'Take A Photo Of Yourself') {
                        //         // props.handleNextPag
                        //         // if (loc?.state?.goBack === 1) {

                        //         //   }
                        //     }

                        // }}
                        htmlFor={(btnname === "Take A Photo Of Yourself") ? "take-photo-icon" : (!photo && btnname === "Take A Photo Of Yourself") ? "take-photo-icon" : ""}>
                        {
                            btnname === "Take A Photo Of Yourself" ?
                                <span className="retake-btn">{btnname}</span>
                                :
                                <div className="mx-auto">
                                    <span className={"d-inline-block mr-3"}>{btnname}</span>
                                    {/* <div className="left-arrow-icone d-inline-block"><img src={Nextarrow} /></div> */}
                                </div>
                        }
                    </label>
                </>
            }

        }
        catch { }
    }

    var rightSidebarFormData
    var rightSidebar

    rightSidebar = 'rightSidebar'
    rightSidebarFormData = getCurrentState().rightSidebarFormData
    console.log(rightSidebarFormData[0])
    return (
        <>
            {/* srushti */}
            {/* back history not static link JENIL 13/9/2023 10:47 PM */}
            <BackHeader isHistoryBack={true} linkto='/mystay' backtopagename='Verification Details' />
            <div className="body-content">
                <div className="col-12 my-3 ">
                    <div className='row'>
                        <label className='first-popup-label pl-3'>Share a photo of yourself</label>
                        <div className="col-12 ">
                            <div className="select-document self-verification-info">
                                <div className="card">
                                    <div className="card-body">
                                        {!getCurrentState().formData.selfphoto &&
                                            <div className="selfverification-img">
                                                <img src={selfverificationimg} />
                                            </div>
                                        }
                                        <div className="selfverification-img">
                                            {photo &&
                                                <img src={photo}
                                                // title={selfVerificationData?.property?.propertyname}
                                                // alt={selfVerificationData?.property?.propertyname}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 my-3 " >
                    <div className="row">
                        <div className="col-12">
                            {renderHtmlControls(rightSidebarFormData[0]?.formfields[0], "Take A Photo Of Yourself")}
                        </div>
                    </div>
                </div>

                <div className="col-12 my-3 " >
                    <div className='row'>
                        <label className='first-popup-label pl-3'>Add Electronic Signature</label>
                        <div className="col-12 electronic-signature-pad">
                            <div className="card">
                                <div className='p-3'>
                                    <div className="electronicsign-area">
                                        <div className='electronicsign-title'>
                                            <p>Electronic Signature</p>
                                        </div>
                                        <div style={styles.sigContainer} id='canvas' >
                                            <SignaturePad
                                                canvasProps={{ style: styles.signpad }}
                                                ref={(ref) => { sigPad = ref }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12  my-3 mt-1">
                    <div className='row justify-content-center'>
                        <div className='col-6 pr-2'>
                            <div className="submit-btn-sec set-clearbtn submit-btn btn-yes-no btn-secondary-light" onClick={() => clear()}>
                                <a className="">Clear Signature</a>
                            </div>
                        </div>
                        <div className='col-6 pl-2'>
                            <button type="submit" className="vehicle-model-btn btn-yes-no btn-primary-light">
                                <Loader content={'Submit'} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default VerificationDataView