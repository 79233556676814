// importScripts('https://www.gstatic.com/firebasejs/7.8.0/firebase-app.js');
// importScripts('https://www.gstatic.com/firebasejs/7.8.0/firebase-messaging.js');
export function isPushNotificationSupported() {
    return "serviceWorker" in navigator && "PushManager" in window;
}

// // PWA - App ready to install
// window.self.addEventListener("install", function (event) {
//     event.waitUntil(preLoad());
// });

// // PWA - Offline Install
// function preLoad() {
//     return caches.open("PWA-V1").then(function (cache) {
//         return cache.addAll(["../src/index.js"]);
//     });
// }

// self.addEventListener("activate", () => {
//     self.clients.matchAll({ type: "window" }).then((windowClients) => {
//         for (let windowClient of windowClients) {
//             // Force open pages to refresh, so that they have a chance to load the
//             // fresh navigation response from the local dev server.
//             windowClient.navigate(windowClient.url);
//         }
//     });
// });

// // PWA - App ready to install
// self.addEventListener("install", function (event) {
//     event.waitUntil(preLoad());
//     // PWA - Offline Install
//     function preLoad() {
//         return caches.open("offline").then(function (cache) {
//             return cache.addAll(["../src/index.js"]);
//         });
//     }
// });
export function register(config) {
    let cacheName = "GuestExp"; // offline
    // window.addEventListener("beforeinstallprompt", function (event) {
    //     // Stop Chrome from asking _now_
    //     event.preventDefault();

    //     // Create your custom "add to home screen" button here if needed.
    //     // Keep in mind that this event may be called multiple times,
    //     // so avoid creating multiple buttons!
    //     // myCustomButton.onclick = () =>
    //     event.prompt();
    // });

    window.self.addEventListener("install", function (event) {
        event.waitUntil(preLoad());
    });

    var preLoad = function () {
        return caches.open(cacheName).then(function (cache) {
            return cache.addAll(["../index.js"]);
        });
    };

    window.self.addEventListener("fetch", function (event) {
        //
        event.respondWith(
            checkResponse(event.request).catch(function () {
                return returnFromCache(event.request);
            })
        );
        event.waitUntil(addToCache(event.request));
    });

    var checkResponse = function (request) {
        return new Promise(function (fulfill, reject) {
            fetch(request).then(function (response) {
                if (response.status !== 404) fulfill(response);
                else reject();
            }, reject);
        });
    };

    var addToCache = function (request) {
        return caches.open(cacheName).then(function (cache) {
            return fetch(request).then(function (response) {
                return cache.put(request, response);
            });
        });
    };

    var returnFromCache = function (request) {
        return caches.open(cacheName).then(function (cache) {
            return cache.match(request).then(function (matching) {
                if (!matching || matching.status == 404)
                    return cache.match("../index.js");
                else return matching;
            });
        });
    };

    if ("serviceWorker" in navigator) {
        return navigator.serviceWorker
            .register("/firebase-messaging-sw.js", {
                scope: "/firebase-cloud-messaging-push-scope",
            })
            .then((swRegistration) => {
                
                //client.postmesaage in service worker event listner JENIL 23/5/2023 3:12 PM
                navigator.serviceWorker.addEventListener(
                    "message",
                    (message) => {
                        console.log('message.data', message)
                        console.log('message.data', message.data.type)
                        if (message.data.type === "NOTIFICATION_CLICK") {
                            // navigate(`${message.data.path}`);
                            window.location.href = message.data.url;
                        }
                    }
                );

            });
    }
}
// return navigator.serviceWorker.register('/serviceWorker.js', { scope: "/" });
// swRegistration.addEventListener("push", (e) => {
//     const data = e?.data?.json();
//     console.log("notification push", data);

//     let NotificationTitle = data.notification.title;
//     let NotificationOptions = data.notification;
//     //notification date set in options
//     NotificationOptions["data"] = data.data;
//     NotificationOptions["icon"] = NotificationOptions.image;
//     delete NotificationOptions.image;
//     //this site has been updated in the background firebase extra message remove from notification
//     // e.waitUntil(
//     //     swRegistration.registration.showNotification(
//     //         NotificationTitle,
//     //         NotificationOptions
//     //     )
//     // );
//     e.waitUntil(
//         swRegistration.showNotification('title', {
//             body: "Push notification", //the body of the push notification
//             // image: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/97/OOjs_UI_icon_bell.svg/1024px-OOjs_UI_icon_bell.svg.png",
//             icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/97/OOjs_UI_icon_bell.svg/1024px-OOjs_UI_icon_bell.svg.png", // icon 
//         })
//     )
//     // data.title, // title of the notification
//     // data.options // the body of the push notification
// });

// // swRegistration.showNotification('title', {
// //     body: "Push notification", //the body of the push notification
// //     // image: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/97/OOjs_UI_icon_bell.svg/1024px-OOjs_UI_icon_bell.svg.png",
// //     icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/97/OOjs_UI_icon_bell.svg/1024px-OOjs_UI_icon_bell.svg.png", // icon 
// //   })
// // console.log("SW : Status > ", status);

export function unregister() {
    console.log('call', 'Unregister')
    if ("serviceWorker" in navigator) {
        return navigator.serviceWorker.getRegistrations().then(function(registrations) {
            console.log("🚀 ~ file: SW.js:123 ~ returnnavigator.serviceWorker.getRegistrations ~ registrations:", registrations)
            for(let registration of registrations) {
                registration.unregister();
            } 
        });
        // return navigator.serviceWorker
        //     .register("/firebase-messaging-sw.js", {
        //         scope: "/firebase-cloud-messaging-push-scope",
        //     })
        //     .then((registration) => {
        //         registration.unregister().then((boolean) => {
        //             console.log("SW : boolean > ", boolean);
        //             // if boolean = true, unregister is successful
        //           });
        //     })
        //     .catch((error) => {
        //       // registration failed
        //       console.error(`Registration failed with ${error}`);
        //     });
    }
    // console.log('pushSubscription 0')
    // return navigator.serviceWorker.ready
    //     .then(function (serviceWorker) {
    //         console.log('pushSubscription 1', serviceWorker)
    //         return serviceWorker.pushManager.getSubscription();
    //     })
    //     .then(function (pushSubscription) {
    //         console.log('pushSubscription 2', pushSubscription)
    //         if (pushSubscription) {
    //             console.log('pushSubscription 3', pushSubscription)
    //             return pushSubscription.unsubscribe();
    //         }
    //         return;
    //     });
}
