import React from 'react'
import { store } from '..'
import Nextarrows from '../assets/images/Group 28993.png'
import Header from '../Header'
import Footer from '../Footer'
import NoImg from '../assets/images/microsoft-teams.png'
import NoDataImage from '../assets/images/no-data-img.png'
import placeholderImg from '../assets/images/placeholderImgNotify.png'
import { Placeholder } from 'rsuite'
const NearByView = (props) => {

  const getCurrentState = () => {
    return store.getState()['root']
  }

  return (
    <>
      <Header />
      <div className="body-content notify-body-content" onScroll={(e) => props.handleScroll(e, 'nearby')} >
        <div className="col-12">
          <div className='row'>
            <div className='col-12'>
              {
                (getCurrentState().formName === '')
                  ?
                  <>
                    <Placeholder.Graph active className='notify-inner mt-3 mb-0'>
                      <div className='notify-graph'>
                        <div className='notify-img'>
                          <img graph="image" src={placeholderImg} className='placeholder-img' />
                        </div>
                        <div className='notify-graph-content'>
                          <p className='graph'></p>
                          <p className='graph'></p>
                          <p className='graph'></p>
                        </div>
                      </div>
                    </Placeholder.Graph>
                    <Placeholder.Graph active className='notify-inner mt-3 mb-0'>
                      <div className='notify-graph'>
                        <div className='notify-img'>
                          <img graph="image" src={placeholderImg} className='placeholder-img' />
                        </div>
                        <div className='notify-graph-content'>
                          <p className='graph'></p>
                          <p className='graph'></p>
                          <p className='graph'></p>
                        </div>
                      </div>
                    </Placeholder.Graph>
                    <Placeholder.Graph active className='notify-inner mt-3 mb-0'>
                      <div className='notify-graph'>
                        <div className='notify-img'>
                          <img graph="image" src={placeholderImg} className='placeholder-img' />
                        </div>
                        <div className='notify-graph-content'>
                          <p className='graph'></p>
                          <p className='graph'></p>
                          <p className='graph'></p>
                        </div>
                      </div>
                    </Placeholder.Graph>
                    <Placeholder.Graph active className='notify-inner mt-3 mb-0'>
                      <div className='notify-graph'>
                        <div className='notify-img'>
                          <img graph="image" src={placeholderImg} className='placeholder-img' />
                        </div>
                        <div className='notify-graph-content'>
                          <p className='graph'></p>
                          <p className='graph'></p>
                          <p className='graph'></p>
                        </div>
                      </div>
                    </Placeholder.Graph>
                    <Placeholder.Graph active className='notify-inner mt-3 mb-0'>
                      <div className='notify-graph'>
                        <div className='notify-img'>
                          <img graph="image" src={placeholderImg} className='placeholder-img' />
                        </div>
                        <div className='notify-graph-content'>
                          <p className='graph'></p>
                          <p className='graph'></p>
                          <p className='graph'></p>
                        </div>
                      </div>
                    </Placeholder.Graph>
                    <Placeholder.Graph active className='notify-inner mt-3 mb-0'>
                      <div className='notify-graph'>
                        <div className='notify-img'>
                          <img graph="image" src={placeholderImg} className='placeholder-img' />
                        </div>
                        <div className='notify-graph-content'>
                          <p className='graph'></p>
                          <p className='graph'></p>
                          <p className='graph'></p>
                        </div>
                      </div>
                    </Placeholder.Graph>
                  </>
                  :
                  ((getCurrentState().formName?.length > 0) && (getCurrentState().masterDataList['nearby']?.length === 0)) ?    // keval 7-4-2023
                    <div className='col-12'>
                      <div className='no-data-found'>
                        <div className='noData-content'>
                          <img src={NoDataImage} />
                          <h2 className="text-center text-16 mb-0 font-weight-600">No nearby locations found</h2>
                        </div>
                      </div>
                    </div>
                    :
                    getCurrentState().masterDataList['nearby']?.length > 0 && getCurrentState().masterDataList['nearby'].map((data, index) => {
                      return (
                        <div className='content' key={index}>
                          <div className={`${data.locationcount > 0 ? 'cursor-pointer' : ''}`} onClick={() => {
                            props.handleFormData('dropdown', "locationid", data._id)
                          }
                          }>
                            <div className='d-flex nearbyContent justify-content-between align-items-center'>
                              <div className='d-flex notificationContent align-items-center'>
                                <div className=''>
                                  <img className='nearby-img' src={data.icon ? data.icon : NoImg} />
                                </div>
                                <div className=''>
                                  {(data.location !== '' || data.location !== undefined) && <>
                                    <span className='maintext d-block nearbyContentTitle'>{data?.location}</span> </>}
                                  {(data.locationcount !== '' || data.locationcount !== undefined) && <span className='subtext d-block nearbyContentText'>Found {data?.locationcount} places near by you</span>}{/* charmi(10-08-2023 04:38) */}
                                </div>
                              </div>
                              {data?.locationcount > 0 &&
                                <div className=''>
                                  <img className='arrow-img' src={Nextarrows} />
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                      )
                    })
              }
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default NearByView