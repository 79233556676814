import React from 'react'
import { connect } from 'react-redux'
import 'rsuite/dist/rsuite.css'
import { setProps } from '../redux/actions'
import BackHeader from '../BackHeader'
import Footer from '../Footer'
import { useLocation } from 'react-router-dom'
import { store } from '..'
import SurveyGridList from './SurveyGridList'

const SurveyView = (props) => {
    const { page, setPage } = props
    const location = useLocation()

    return (
        <>
            {/* back history not static link JENIL 13/9/2023 10:47 PM */}
            <BackHeader isHistoryBack={page === 'listrequest' ? true : false} linkto={page === 'listrequest' ? '/mystay' : location.pathname} backtopagename='Survey' handleFilterData={props.handleFilterData} handleApplyFilterButtonClick={props.handleApplyFilterButtonClick} handleSerachClose={props.handleSerachClose} />
            {
                <SurveyGridList
                    setPage={setPage}
                    handleFormData={props.handleFormData}
                    handleAddButtonClick={props.handleAddButtonClick}
                    setFormData={props.setFormData}
                    setSecondaryFormData={props.setSecondaryFormData}
                    handleGrid={props.handleGrid}
                    updateData={props.updateData}
                    handleScroll={props.handleScroll}
                    handleViewFilterData={props.handleViewFilterData}
                    handleFilterData={props.handleFilterData}
                    getList={props.getList}
                    setFilterData={props.setFilterData}
                    handleApplyFilterData={props.handleApplyFilterData}
                    fromData={props.fromData}
                    ratingData={props.ratingData}
                    load={props.load}
                />

            }
            <Footer />
        </>
    )
}

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    setProps: (payload) => dispatch(setProps(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(SurveyView)
