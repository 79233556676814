import React, { useEffect } from 'react'
import { IISMethods } from '../config/IISMethods'
import { setProps } from '../redux/actions'
import { connect } from 'react-redux'
import { store } from '..'
import { useNavigate } from 'react-router-dom'
import Header from '../Header'
import Footer from '../Footer'
import { Placeholder } from 'rsuite'

const MyHotelView = (props) => {
    const getCurrentState = () => {
        return store.getState()['root']
    }

    const guestIconData = getCurrentState().menuData ? getCurrentState().menuData : []
    const queryString = IISMethods.getQueryString()
    const history = useNavigate()

    const redirectpage = (alias) => {
        let pagelink = queryString
        // for menu redirect 29-3-2023 srushti
        // reservatin exprire conditin manage with hide and show menu details
        if (!getCurrentState().reservationDetails.isguestcheckout || getCurrentState().reservationDetails.isguestcheckout === 0) {
            if (alias === 'allamenities') {
                history(`/amenities`)
            }
            else if(alias === 'propertylayout'){
                history(`/propertylayout`)
            }
        }
        else {
            //charmi(06-09-2023 03:55)
            if (alias === 'allamenities') {
                history(`/amenities`)
            }
            else if(alias === 'propertylayout'){
                history(`/propertylayout`)
            }
            else {
                history(`/successfullycheckout`)//charmi(21-08-2023 03:04)
                // IISMethods.localnotify("You have already checkout.you can't perform this action.")//charmi(21-08-2023 03:04)
            }
            //charmi(06-09-2023 03:55)
        }
    }

    return (
        <>
            {/* <Header /> */}
            <Header />
            {/* for menu listing filterwise 29-3-2023 srushti */}
            <div className="body-content">
                <div className="col-12">
                    {
                        // (getCurrentState().formName === '')
                        (getCurrentState().menuData.length === 0)
                            ?
                            <div className="row">
                            {
                                [...Array(9)].map((data , index)=>{
                                    return <div className='col-4 col-sm-3 col-md-3 mx-0 box-space'>
                                    <Placeholder.Graph active className='mb-0' style={{height : "100px", width : "100%"}}> </Placeholder.Graph>
                                </div>
                                })
                            }
                        </div>
                            :
                            getCurrentState().menuData.length > 0
                            &&
                            <div className="row p-2">
                                {
                                    guestIconData.filter(obj => obj.myhotel === 1).sort((a, b) => a.innerdashboardorder > b.innerdashboardorder ? 1 : -1).map((data, index) => {
                                        return (
                                            <div className="col-4 col-sm-3 col-md-3 mx-0 box-space" key={index}>
                                                <div className="card-stay">
                                                    {/* <div className="card-stay-imgs mt-2"> */}
                                                    <div className="card-stay-imgs" onClick={() => { redirectpage(data.alias) }}>
                                                        {(data.dashboardicon !== '' || data.dashboardicon !== undefined) && <img src={data.dashboardicon} />}
                                                    </div>
                                                    {(data.menuname !== '' || data.menuname !== undefined) && <p className="label-name-stay mt-2">{data.menuname}</p>}
                                                </div>
                                                {/* <p className="label-name-stay mt-1">{data.menuname}</p> */}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                    }
                </div>
            </div>
            <Footer />
        </>
    )
}

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    setProps: (payload) => dispatch(setProps(payload))
})


export default connect(mapStateToProps, mapDispatchToProps)(MyHotelView)



