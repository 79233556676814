import { useState, useEffect } from "react";

function checkForIOS() {
    if (navigator.standalone) {
        return false;
    }
    let today = new Date();
    today.setHours(0, 0, 0, 0)
    let lastPrompt = new Date(localStorage.getItem("installPrompt"));
    let days = getDiffInDay(lastPrompt, today)
    let ua = window.navigator.userAgent;
    let webkit = !!ua.match(/WebKit/i);
    let isIPad = !!ua.match(/iPad/i);
    let isIPhone = !!ua.match(/iPhone/i);
    let isIOS = isIPad || isIPhone;
    let isSafari = isIOS && webkit && !ua.match(/CriOS/i);

    let iosPrompt = (isNaN(days) || days > 30) && isIOS && isSafari;

    if (iosPrompt && "localStorage" in window) localStorage.setItem("installPrompt", today)

    return { isIOS, isSafari, iosPrompt };
}

const getDiffInDay = (d1, d2) => {        
    let days = (d2 - d1) / (1000 * 60 * 60 * 24)
    return Math.round(days)
}

export default function useIsIOS() {
    const [isIOS, setIsIOS] = useState({ isIOS: false, isSafari: false, iosPrompt: false });

    useEffect(() => {
        setIsIOS(checkForIOS());
        return () => console.log("CLEANUP INSTALL PROMPT", isIOS);
    }, []);

    return isIOS;
}
