import React, { useEffect, useState } from 'react'
import { store } from '../index'
import ModalRsuite from './Components/ModalRsuite'
import Loader from './Components/Loader'

export default function DndRightsidebar(props) {
    const getCurrentState = (props) => {
        return store.getState()['root']
    }

    try {
        return (
            <>
                <ModalRsuite
                    size={"xs"}
                    open={getCurrentState().modal['dndonModal']}
                    onClose={() => props.handleGrid(false, 'modal', 'dndonModal', 0)}
                    body={
                        <>
                            <div className="col-12">
                                <span className='text-center d-block'>Are you sure you want to</span>
                            </div>
                            <div className={`col-12 text-center`}>
                                <div className="first-pop-logo my-3">
                                    <img src={props.dndMenuData?.dashboardsvg} />
                                </div>
                            </div>
                            <div className="col-12 text-center">
                                {
                                    props.dndMenuData?.dndon === 1
                                        ?
                                        <span>turn off DND ?</span>
                                        :
                                        <span>turn on DND ?</span>
                                }
                            </div>
                            <div className="col-12 mt-4">
                                <div className='row justify-content-center'>
                                    <div className='col-6 pr-2'>
                                        <button type="submit" className="btn-yes-no btn-secondary-light"
                                            onClick={(e) => {
                                                e.preventDefault()
                                                props.handleGrid(false, 'modal', 'dndonModal', 0)
                                            }} >No</button>
                                    </div>
                                    <div className='col-6 pl-2'>
                                        <button type="submit" className="btn-yes-no btn-primary-light"
                                            onClick={(e) => {
                                                getCurrentState().loading === 0 && props.setDnd(e, 'dndonModal')
                                            }} >
                                            <Loader content={'Yes'} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                />
            </>
        )
    }
    catch (e) {
        console.log(e)
        return <></>
    }
}
