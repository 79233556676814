import React, { useEffect, useState } from 'react'
import { setProps } from '../redux/actions'
import { connect } from 'react-redux'
import AmenitiesController from '../Controller/AmenitiesController'

const Amenities = (props) => {
  
  useEffect(() => {
    async function fetchMyAPI() {
      await props.setProps({
          rightSidebarFormData: []
      })
  }
  fetchMyAPI()
  }, [])

  return <AmenitiesController pagename="amenities" backpage={false}  />
}

const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
  setProps: (payload) => dispatch(setProps(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Amenities)

