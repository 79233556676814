import { Component } from 'react'

export default class JsCall extends Component {

    handleSort(fieldObj) {
        const element = document.querySelector(`[class~=${fieldObj.tblsize}][name=${fieldObj.field}]`)
        element.classList.toggle('sorting_asc')
        element.classList.toggle('sorting_desc')
    }

    handleRightSidebarTab(e){
        var tabname
        if(typeof(e)==='string') {
            tabname = e
        }
        else {
            e.preventDefault()
            tabname = e.target.id
        }
        var element = document.querySelector("[className='nav-link active']")
        element.classList.remove('active')

        element = document.getElementById(tabname)
        element.classList.add('active')

        element = document.querySelector("[className='tab-pane fade active show']")
        element.classList.remove('active')
        element.classList.remove('show')

        element = document.getElementById(tabname+'-tab')
        element.classList.add('active')
        element.classList.add('show')
    }

    handleCloseDropdown(e) {
        var element = e.currentTarget

        element = element.parentNode
        element.setAttributeNode(document.createAttribute("hidden"))

        element = element.parentNode
        element.classList.remove('rs-dropdown-open')

        element = element.childNodes[0]
        element.removeAttribute('area-expanded')
    }

    handleError(id, hasError) {
        var element = document.getElementById(id)
        try {
            while(!element.classList.contains('form-group')) {
                element = element.parentNode
            }
        }
        catch{
            try {
                if(id==='form-contact') {
                    element = document.getElementById(id)
                    element = element.childNodes[0]
                    element = element.childNodes[0]
                }
            }
            catch{
            }
        }
        try {
            if(hasError){
                element.classList.add('error')
            }
            else{
                element.classList.remove('error')
            }
        }
        catch{
        }
    }

    getobjectfromformdata(formData, field) {
       
        var object = {}
        formData.map((data) => !Object.keys(object).length ? object = data.formfields.find(o => o.field===field) || {} : object = object)
        return object
    }
    
    validateForm(formData, validations, key, formname)
    {
        var hasError
        if(typeof key==="string") {
            var value = formData[key]
            validations = this.getobjectfromformdata(validations, key)

            if(validations.type==='input-text' || validations.type==='number-input' || validations.type==='radio' || validations.type === 'intltelinput') {
                    try {
                        value = value.toString()
                       
                        hasError = !value.match(new RegExp(validations.regex))
                            || parseFloat(value) < validations.minvalue
                            || parseFloat(value) > validations.maxvalue
                            || value.length < validations.minlength
                            || value.length > validations.maxlength
                            || parseFloat(value) <= parseFloat(formData[validations.shouldgreaterthan])

                    }
                    catch {
                        hasError = true
                    }
            }
            else if(validations.type==='table' || validations.type==='multipleselectdropdown' || validations.type==='daterangepicker' || validations.type==='timerangepicker' || validations.type==='checkpicker') {
                try {
                    hasError = !value.length

                    hasError ||= (!value.length && validations.required)
                    hasError &&= (value.length || validations.required)
                }
                catch {
                    hasError = validations.required
                }
            }
            else if(validations.type === 'html-editor'){
                // remove new lines 
                value =  value.replaceAll('<p><br></p>','')
                // remove spaces
                value = value.replaceAll(/<p>\s*<\/p>/g, '')
                hasError = !value.length 
            }
            else {
                hasError = false
            }

            hasError ||= (!value && validations.required)
            hasError &&= (value || validations.required)

            
            if(document.getElementById((formname ? formname : 'form')+'-'+key)) {
                // if(validations.type!=='dropdown' && validations.type!=='multipleselectdropdown')
                //     this.handleHasVal((formname ? formname : 'form')+'-'+key,value)
                this.handleError((formname ? formname : 'form')+'-'+key, hasError)
            }
            else {
                hasError = false
            }

            if(hasError) {
            }

            return hasError
        }
        else {
            var hasError = false

            var view

            validations.map((data) =>
                data.formfields.filter(formfield => !formfield.istablefield).map((fields) => {
                    const tempHasError = this.validateForm(formData, validations, fields.field, formname)
                    hasError ||= tempHasError
                    if(tempHasError && !view)
                        view = data.tabname
                })
            )

            if(view) {
                this.handleRightSidebarTab(view)
            }

            return hasError
        }
    }
}