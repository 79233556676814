import React, { useState } from 'react'
import { store } from '../index'
import ModalRsuite from './Components/ModalRsuite'
import SelectPickerRsuite from './Components/SelectPickerRsuite'
import LastThankYou from '../assets/images/Thank You.svg'
import SignaturePad from 'react-signature-canvas'
import Loader from './Components/Loader'

export default function LateCheckOutRightSidebar(props) {

    const getCurrentState = () => {
        return store.getState()['root']
    }

    let sigPad = {}
    const clear = () => { sigPad.clear() }
    const styles = {
        sigContainer: {
            width: " 100%",
            height: " 100%",
        },
        signpad: {
            width: "100%",
            height: "205px",
            marginTop: "8px",
        }
    }

    try {
        return (
            <>
                {/* late checkout Modal Frist Modal */}
                {
                    getCurrentState().modal['latecheckoutModal']
                    &&
                    <ModalRsuite
                        size={"xs"}
                        open={getCurrentState().modal['latecheckoutModal']}
                        onClose={() => { props.handleGrid(false, 'modal', 'latecheckoutModal', 0) }}
                        className="modalContentPad"
                        title={
                            <div className="model-header icon">
                                <span className="closeicon">
                                    Late Check-Out
                                </span>
                            </div>
                        }
                        body={
                            <form method="post" >
                                <div className="col-12">
                                    <div className="row">
                                        {
                                            getCurrentState().rightSidebarFormData[0]?.formfields.filter(formfield => {
                                                let conditions = false
                                                if (formfield.condition) {
                                                    let condition = formfield.condition?.map((item) => {
                                                        if (getCurrentState().formData[item.field] === item.onvalue) {
                                                            return true
                                                        }
                                                        return false
                                                    })
                                                    let status = condition.includes(true) ? true : false
                                                    conditions = status
                                                }
                                                try {
                                                    return formfield.defaultvisibility || conditions
                                                }
                                                catch {
                                                    return false
                                                }
                                            }).map((formfield, fieldindex) => {
                                                if (formfield.type === 'dropdown') {
                                                    return (
                                                        <div className={formfield.gridsize} >
                                                            <div className="form-group validate-input">
                                                                <label className='first-popup-label'>{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}</label>
                                                                <SelectPickerRsuite
                                                                    cleanable={formfield.cleanable}
                                                                    placeholder={`${formfield.text}`}
                                                                    className="form-control "
                                                                    data={getCurrentState().masterData[formfield.masterdata]}
                                                                    id={"form-" + formfield.field}
                                                                    name={formfield.field}
                                                                    onChange={(e) => props.handleFormData(formfield.type, formfield.field, e)}
                                                                    value={getCurrentState().formData[formfield.field]}
                                                                    disabled={formfield.disabled}
                                                                />
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                else if (formfield.type === 'number-input') {
                                                    return (
                                                        <div className={formfield.gridsize} key={`${fieldindex}`}>
                                                            <div className="form-group validate-input">
                                                                <label className='first-popup-label'>{formfield.text}</label>
                                                                <input type="text" pattern="[0-9]*"
                                                                    className="form-control"
                                                                    id={"form-" + formfield.field}
                                                                    name={formfield.field}
                                                                    value={getCurrentState().formData[formfield.field]}
                                                                    disabled={formfield.disabled}
                                                                    onChange={(e) => props.handleFormData(formfield.type, formfield.field, e.target.validity.valid ? e.target.value : getCurrentState().formData[formfield.field])}
                                                                    placeholder={`$0`} />
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })
                                        }
                                    </div>
                                    <label className='first-popup-label'>Add Electronic Signature</label>
                                    <div className="electronic-signature-pad">
                                        <div className="card">
                                            <div className='p-3'>
                                                <div className="electronicsign-area">
                                                    <div className='electronicsign-title'>
                                                        <p>Electronic Signature</p>
                                                    </div>
                                                    <div style={styles.sigContainer} id='canvas' >
                                                        <SignaturePad
                                                            canvasProps={{ style: styles.signpad }}
                                                            ref={(ref) => { sigPad = ref }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        }
                        footer={
                            <>
                                <div className="col-12 mt-1">
                                    <div className='row justify-content-center'>
                                        <div className='col-6 pr-2'>
                                            <div className="submit-btn-sec set-clearbtn submit-btn btn-yes-no btn-secondary-light" onClick={() => clear()}>
                                                <a className="">Clear Signature</a>
                                            </div>
                                        </div>
                                        <div className='col-6 pl-2'>
                                            <button type="submit" className="vehicle-model-btn btn-yes-no btn-primary-light"
                                                onClick={(e) => {
                                                    getCurrentState().loading === 0 && props.handleLateCheckout(sigPad, 'latecheckoutModal')
                                                }} >
                                                <Loader content={'Submit'} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    />
                }

                {/* last Thank You Pop Modal */}

                <ModalRsuite
                    size={"xs"}
                    open={getCurrentState().modal['LateCheckoutThankyou']}
                    onClose={() => props.handleGrid(false, 'modal', 'LateCheckoutThankyou', 0)}
                    body={
                        <>
                            <div className='col-12 text-center'>
                                <img src={LastThankYou} height="50px" />
                            </div>
                            <div className="col-12 mt-3 checkout-email">
                                <span className='text-center d-block'>We got your late Check-out request.</span>
                                <span className='text-center d-block'>It will be approved based on availability.</span>
                            </div>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12 mt-4">
                                        <button type="submit" className="btn-first-popup mb-0"
                                            onClick={(e) => {
                                                props.handleGrid(false, 'modal', 'LateCheckoutThankyou', 0)
                                            }}
                                        >Okay
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                />


                {/* latecheckout data modal srushti 23-5-23 */}
                {
                    getCurrentState().modal?.latecheckoutdatamodal &&
                    <>
                        {
                            getCurrentState().modal['latecheckoutdatamodal']
                            &&
                            <ModalRsuite
                                size={"xs"}
                                open={getCurrentState().modal['latecheckoutdatamodal']}
                                onClose={() => props.handleGrid(false, 'modal', 'latecheckoutdatamodal', 0)}
                                body={
                                    <div className=''>
                                        <div className="col-md-12 text-center mt-4">
                                            <span>{getCurrentState().reservationDetails.guestlatecheckoutmessage}</span>
                                        </div>
                                        <div className="col-12 ">
                                            <div className='row'>
                                                {(getCurrentState().reservationDetails.addnewlatecheckoutrequest !== 1 && getCurrentState().reservationDetails.showlatecheckoutrejectreason !== 1)
                                                    &&
                                                    <div className="col-12">
                                                        <div className='row'>
                                                            <div className="col-12 mt-3">
                                                                <button type="submit" className="btn-first-popup mb-0" onClick={(e) => props.handleGrid(false, 'modal', 'latecheckoutdatamodal', 0)}>Okay</button>
                                                            </div>
                                                        </div>
                                                    </div>}

                                                {getCurrentState().reservationDetails.addnewlatecheckoutrequest === 1 &&
                                                    <div className="col-6 m-auto">
                                                        <button type="submit" className="vehicle-model-btn mt-4"
                                                            onClick={(e) => {
                                                                props.handleGrid(false, 'modal', 'latecheckoutdatamodal', 0)
                                                                props.setFormData(null, 'latecheckout', 'latecheckoutModal')
                                                            }} >
                                                            <Loader content={'Add New request'} />
                                                        </button>
                                                    </div>}

                                                {getCurrentState().reservationDetails.showlatecheckoutrejectreason === 1 &&
                                                    <div className="col-6 m-auto">
                                                        <button type="submit" className="vehicle-model-btn mt-4"
                                                            onClick={(e) => {
                                                                props.handleGrid(false, 'modal', 'latecheckoutdatamodal', 0)
                                                                props.handleGrid(true, 'modal', 'rejectedreasonmodal', 1)
                                                            }} >
                                                            <Loader content={'Reject Reason'} />
                                                        </button>
                                                    </div>}

                                            </div>
                                        </div>
                                    </div>
                                }
                            />
                        }
                    </>
                }

                {/* reject reason modal */}
                {getCurrentState().modal?.rejectedreasonmodal &&
                    <>
                        {getCurrentState().modal['rejectedreasonmodal']
                            &&
                            <ModalRsuite
                                size={"xs"}
                                open={getCurrentState().modal['rejectedreasonmodal']}
                                onClose={() => props.handleGrid(false, 'modal', 'rejectedreasonmodal', 0)}
                                body={
                                    <>
                                        <div className="col-md-12 text-center mt-4">
                                            <span>{getCurrentState().reservationDetails.latecheckoutrejectreason}</span>
                                        </div>
                                        <div className="col-12 mt-3">
                                            <button type="submit" className="btn-first-popup mb-0" onClick={(e) => props.handleGrid(false, 'modal', 'rejectedreasonmodal', 0)}>Okay</button>
                                        </div>
                                    </>
                                }
                            />}
                    </>}
            </>
        )
    } catch (error) {
        console.log(error)
        return <></>
    }

}