import React, { useEffect, useState } from 'react'
import { Config, IISMethods, resBody, setReqParams } from '../config/IISMethods'
import { connect, useSelector } from 'react-redux'
import 'rsuite/dist/rsuite.css'
import { setProps } from '../redux/actions'
import { store } from '../index'
import Clock from '../assets/images/clock.svg'
import Dinner from '../assets/images/dinner.svg'
import BackHeader from '../BackHeader'
import NoDataImage from '../assets/images/no-data-img.png'
import placeholderImg from '../assets/images/placeholderImgNotify.png'
import Footer from '../Footer'
import { Placeholder } from 'rsuite'
import { useNavigate } from 'react-router-dom'

const NotificationView = (props) => {
    // srushti 23-2-23
    const getCurrentState = () => {
        return store.getState()['root']
    }

    const navigate = useNavigate();
    const queryString = IISMethods.getQueryString()

    // srushti
    const Notifiaction = ({ notification }) => {
        return (
            <>
                <div className='notification-text'>
                    <span className='notification-title'>{notification.title}</span>
                    {(notification.description?.length > 0) && <span className='d-block mt-1 notification-sub-title'>{notification.description}</span>}
                    {/* <div className='d-flex notification'>
                        <img src={Clock} />
                        <p>{IISMethods.GetTimeFormat(notification.date)}</p>
                    </div> */}
                </div>
            </>
        )
    }

    //when click on chat notification navigate to chat
    const chatNotificationNavigation = (item) => {
        //extend stay category
        if (item?.redirectguestexpcategoryid === "646ddb78f288c1b0423cc3a5") {
            navigate(`/extendstay`, { state: item.msgid })
        }
        //latecheckout category
        else if (item?.redirectguestexpcategoryid === "645a348357f45b2c947e5282") {
            navigate(`/latecheckout`, { state: item.msgid })
        }
        //checkout category
        else if (item?.redirectguestexpcategoryid === "645a348357f45b2c947e5283") {
            navigate(`/checkout`, { state: item.msgid })
        }
        //chatwithus category
        else if (item?.redirectguestexpcategoryid === "625d5ed5220ccd5a20904b79") {
            navigate(`/chatwithus`, { state: item.msgid })
        }
        else if (item?.redirectguestexpcategoryid === "62fe1718cbac206928991225") {
            navigate(`/survey`, { state: { guestexpsurveytype: item.guestexpsurveytype, guestexpratingcategoryid: item.guestexpratingcategoryid, guestexpsurveyid: item.guestexpsurveyid } })
        }
        // click on guest request notification then redirect guest request page srushti 11-5-23 14:47
        // else if (item?.taskcategoryid === "62fe170fcbac206928991221") {
        //     // navigate(`/guestrequest${queryString}`)
        //     navigate(`/guestrequest${queryString}`, { state: { taskid: item.taskid } })
        // }
        // // click on maintenance notification then redirect maintenance page srushti 11-5-23 15:09
        // else if (item?.taskcategoryid === "62fe1718cbac206928991222") {
        //     // navigate(`/maintenance${queryString}`)
        //     navigate(`/maintenance${queryString}`, { state: { taskid: item.taskid } })
        // }
        // // click on Report problem notification then redirect Report problem page srushti 11-5-23 15:04
        // else if (item?.taskcategoryid === "63c52a49581f7a2dc5099cf4") {
        //     // navigate(`/reportproblem${queryString}`)
        //     navigate(`/reportproblem${queryString}`, { state: { taskid: item.taskid } })
        // }
    }

    // const dateformat = () => {
    //     return IISMethods.GetDateFormat(notificationdata.date)

    // }
    // console.log('date', IISMethods.GetDateTimefromISOString('2023-06-02T10:38:45.920Z'))
    // console.log('date', IISMethods.getPropertyOffset('2023-06-02T10:38:45.920Z'))
    // dateformat()

    // srushti 23-2-23

    return (
        <>
            {/* back history not static link JENIL 13/9/2023 10:47 PM */}
            <BackHeader isHistoryBack={true} linkto='/home' backtopagename='Notification' handleFilterData={props.handleFilterData} handleApplyFilterButtonClick={props.handleApplyFilterButtonClick} handleSerachClose={props.handleSerachClose} />
            {/* <Header backTo="guestexperiencehome" title="Notification" backtoPreviousPage={props.backtoPreviousPage} /> */}
            <div className="body-content notify-body-content" onScroll={(e) => props.handleScroll(e)}>
                <div className="col-12 mb-3">
                    <div className="row">
                        {
                            (getCurrentState().formName === '')
                                ?
                                <div className="col-12">
                                    <Placeholder.Graph active className='notify-inner mt-3 mb-0'>
                                        <div className='notify-graph'>
                                            <div className='notify-img'>
                                                <img graph="image" src={placeholderImg} className='placeholder-img' />
                                            </div>
                                            <div className='notify-graph-content'>
                                                <p className='graph'></p>
                                                <p className='graph'></p>
                                                <p className='graph'></p>
                                            </div>
                                        </div>
                                    </Placeholder.Graph>
                                    <Placeholder.Graph active className='notify-inner mt-3 mb-0'>
                                        <div className='notify-graph'>
                                            <div className='notify-img'>
                                                <img graph="image" src={placeholderImg} className='placeholder-img' />
                                            </div>
                                            <div className='notify-graph-content'>
                                                <p className='graph'></p>
                                                <p className='graph'></p>
                                                <p className='graph'></p>
                                            </div>
                                        </div>
                                    </Placeholder.Graph>
                                    <Placeholder.Graph active className='notify-inner mt-3 mb-0'>
                                        <div className='notify-graph'>
                                            <div className='notify-img'>
                                                <img graph="image" src={placeholderImg} className='placeholder-img' />
                                            </div>
                                            <div className='notify-graph-content'>
                                                <p className='graph'></p>
                                                <p className='graph'></p>
                                                <p className='graph'></p>
                                            </div>
                                        </div>
                                    </Placeholder.Graph>
                                    <Placeholder.Graph active className='notify-inner mt-3 mb-0'>
                                        <div className='notify-graph'>
                                            <div className='notify-img'>
                                                <img graph="image" src={placeholderImg} className='placeholder-img' />
                                            </div>
                                            <div className='notify-graph-content'>
                                                <p className='graph'></p>
                                                <p className='graph'></p>
                                                <p className='graph'></p>
                                            </div>
                                        </div>
                                    </Placeholder.Graph>
                                    <Placeholder.Graph active className='notify-inner mt-3 mb-0'>
                                        <div className='notify-graph'>
                                            <div className='notify-img'>
                                                <img graph="image" src={placeholderImg} className='placeholder-img' />
                                            </div>
                                            <div className='notify-graph-content'>
                                                <p className='graph'></p>
                                                <p className='graph'></p>
                                                <p className='graph'></p>
                                            </div>
                                        </div>
                                    </Placeholder.Graph>
                                    <Placeholder.Graph active className='notify-inner mt-3 mb-0'>
                                        <div className='notify-graph'>
                                            <div className='notify-img'>
                                                <img graph="image" src={placeholderImg} className='placeholder-img' />
                                            </div>
                                            <div className='notify-graph-content'>
                                                <p className='graph'></p>
                                                <p className='graph'></p>
                                                <p className='graph'></p>
                                            </div>
                                        </div>
                                    </Placeholder.Graph>
                                </div>
                                :
                                ((getCurrentState().formName?.length > 0) && (getCurrentState().data?.length === 0)) ?
                                    <div className='col-12'>
                                        <div className='no-data-found'>
                                            <div className='noData-content'>
                                                <img src={NoDataImage} />
                                                <h2 className="text-center text-16 mb-0 font-weight-600">No notifications present</h2>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    getCurrentState().data?.length > 0 && getCurrentState().data?.sort(function (a, b) {
                                        a = new Date(a.date)
                                        b = new Date(b.date)
                                        return b.getTime() - a.getTime();
                                    })?.map((notificationdata, index) => {
                                        return (
                                            <div className="col-12 col-md-12 mt-3" key={index}>
                                                <div className='text-center notification-date-text mb-3'>
                                                    {notificationdata.date && <span>{IISMethods.getDateFormat(IISMethods.GetDT(notificationdata.date))}</span>}
                                                </div>
                                                {notificationdata?.data?.map((notification, i) => {
                                                    return (

                                                        <div className="notification-box mt-3" key={i} onClick={() => chatNotificationNavigation(notification)}>
                                                            <div className="d-flex justify-content-between align-items-center gap-1">
                                                                <div className="d-flex notificationContent align-items-center">
                                                                    {notification.isseenbyguest === 0 && <span className='blink-red-dot'></span>}
                                                                    <div className='notification-img'>
                                                                        <img src={Dinner} />
                                                                    </div>
                                                                    <Notifiaction notification={notification} />
                                                                </div>
                                                                <div className='notification-box-border text-center'>
                                                                    {notificationdata.date && <span className='d-block'>{IISMethods.GetDateFormat(notification.date)}</span>}
                                                                    {notificationdata.date && <span className='d-block'>{IISMethods.GetTimeFormat(notification.date)}</span>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        )
                                    })
                        }
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}


const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    setProps: (payload) => dispatch(setProps(payload))
})


export default connect(mapStateToProps, mapDispatchToProps)(NotificationView)
