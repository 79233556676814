import React, { useState } from 'react'
import 'rsuite/dist/rsuite.css'//"rsuite/dist/rsuite.css";
import ReportProblemRightSidebar from './ReportProblemRightSidebar'
import ReportProblemProgress from './ReportProblemProgress'
import BackHeader from '../BackHeader'
import Footer from '../Footer'
import ReportProblemList from './ReportProblemList'
import { useLocation, useNavigate } from 'react-router-dom'
import Progress from './Components/Progress'
import { store } from '..'

let initialstate = 'reportproblemhome'

const ReportProblemView = (props) => {
    const { page, setPage } = props
    const location = useLocation()

    const getCurrentState = () => {
        return store.getState()['root']
    }

    // srushti 27-2-23
    // reportproblem
    try {
        return (
            <>
                {/* back history not static link JENIL 13/9/2023 10:47 PM */}
                <BackHeader isHistoryBack={page === 'reportproblemhome' ? true : false} linkto={page === 'reportproblemhome' ? '/mystay' : location.pathname} backtopagename='Reported Problems' onClick={() => setPage(initialstate)} handleFilterData={props.handleFilterData} handleApplyFilterButtonClick={props.handleApplyFilterButtonClick} handleSerachClose={props.handleSerachClose} />
                {
                    page === "reportproblemhome" ?
                        <ReportProblemList
                            setSecondaryFormData={props.setSecondaryFormData}
                            // handleFormData={props.handleFormData}
                            setFormData={props.setFormData}
                            handleGrid={props.handleGrid}
                            handleScroll={props.handleScroll}
                            getAreaList={props.getAreaList}
                            setPage={setPage}
                            handleAddButtonClick={props.handleAddButtonClick}
                            getList={props.getList}
                            setFilterData={props.setFilterData}
                            handleApplyFilterData={props.handleApplyFilterData}
                            handleFilterData={props.handleFilterData}
                        />
                        : page === "reportproblemdemo" ?
                            <Progress
                                name='Complaint In Progress'
                                pagename='reportproblem'
                                masterdatastate={getCurrentState().secondarySourceMasterData}
                                handleGrid={props.handleGrid}
                                handleScroll={props.handleScroll}
                                setFormData={props.setFormData}
                                handleFormData={props.handleFormData}
                            />
                            // <ReportProblemProgress
                            //     handleFormData={props.handleFormData}
                            //     setFormData={props.setFormData}
                            //     handleScroll={props.handleScroll}
                            //     handleGrid={props.handleGrid}
                            // />

                            :
                            ""
                }
                <ReportProblemRightSidebar
                    handleFormData={props.handleFormData}
                    handleGrid={props.handleGrid}
                    setFormData={props.setFormData}
                    handleAddButtonClick={props.handleAddButtonClick}
                    handleScroll={props.handleScroll}
                    getAreaList={props.getAreaList}
                    handleRemoveAreaItems={props.handleRemoveAreaItems}
                    getList={props.getList}
                    setFilterData={props.setFilterData}
                    handleApplyFilterData={props.handleApplyFilterData}
                    handleFilterData={props.handleFilterData}
                    handleAddFilterButtonClick={props.handleAddFilterButtonClick}
                    getIssueList={props.getIssueList}
                    handleItemAdd={props.handleItemAdd}
                    reportloder={props.reportloder}
                // handleRoomAreaItems = {props.handleRoomAreaItems}
                // activeacc={props.activeacc}
                // setActiveacc={props.setActiveacc}
                />
                <Footer />
            </>
        )
    } catch (error) {
    }

}

export default ReportProblemView

