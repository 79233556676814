import React, { useEffect, useState } from 'react'
import { store } from '..'
import ModalRsuite from './Components/ModalRsuite'

export default function WifiRightSidebar(props) {
    const getCurrentState = () => {
        return store.getState()['root']
    }

    try {
        return (
            <>
                {/* wifi model / device-wise data srushti 23-2-23 */}
                <ModalRsuite
                    size={"xs"}
                    open={getCurrentState().modal['wifiModal']}
                    onClose=
                    {() => {
                        props.handleGrid(false, 'modal', 'wifiModal', 0);

                    }}

                    title={
                        <div className="model-header icon">
                            <span className="closeicon">
                                {getCurrentState().formData?.modaldata?.title}
                            </span>
                        </div>
                    }
                    body={
                        <div className="col-12">
                            <div className="wifi-modal-step">
                                {<div dangerouslySetInnerHTML={{ __html: getCurrentState().formData?.modaldata?.description }} />}
                            </div>
                            {(getCurrentState().formData.modaldata && getCurrentState().formData?.modaldata?.tip !== '') &&
                                <div className='wifi-tip'>
                                    <hr className='my-3' />
                                    <div>Tip: {getCurrentState().formData.modaldata.tip}</div>
                                </div>

                            }
                        </div>
                    }
                />

            </>
        )
    }
    catch (e) {
        console.log(e)
        return <></>
    }
}


