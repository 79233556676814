import React, {useEffect, useRef} from 'react'

const Audio = (props) => {
    const audioRef = useRef()

    useEffect(() => {
        audioRef.current.audio?.load()
    }, [props.src])

    return (
        <audio ref={audioRef} controls src={props.src}>
            <source src={props.src} type="audio/mpeg" />
        </audio>
    )
}

export default Audio