import { store } from '..'
import BackHeader from '../BackHeader'
import Footer from '../Footer'
import NoDataImage from '../assets/images/no-data-img.png'
import iconsImg from '../assets/images/car.png'
import { Placeholder } from 'rsuite'

const TvChannelLineUpView = (props) => {
    const getCurrentState = () => {
        return store.getState()['root']
    }

    return (
        <>
            {/* srushti */}
            {/* back history not static link JENIL 13/9/2023 10:47 PM */}
            <BackHeader isHistoryBack={true} linkto='/mystay' backtopagename='TV Channel LineUp' handleFilterData={props.handleFilterData} handleApplyFilterButtonClick={props.handleApplyFilterButtonClick} handleSerachClose={props.handleSerachClose} />
            <div className="body-content tvchannelBody">
                <div className="col-12" >
                    <div className='row'>
                        {
                            (getCurrentState().formName === '')
                                ?
                                <div className="col-12 placeholder-col">
                                    <Placeholder.Graph active className='placeHolderHeight mt-3'></Placeholder.Graph>
                                </div>
                                :
                                ((getCurrentState().formName?.length > 0) && (getCurrentState().filterMasterDataList?.channelcategory?.length === 0))
                                    ?
                                    <div className='col-12'>
                                        <div className='no-data-found'>
                                            <div className='noData-content'>
                                                <img src={NoDataImage} />
                                                <h2 className="text-center text-16 mb-0 font-weight-600">No channels found</h2>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className='col-12 px-0'>
                                        <div className="tvchannne-box-scroll">
                                            {
                                                getCurrentState().filterMasterDataList['channelcategory']?.map((data, index) => {
                                                    return (
                                                        <div className="box-tvchannel" key={index}>
                                                            <div
                                                                className={`icon-card ${getCurrentState().filterData['categoryid'] === data._id ? 'active' : ""}`}
                                                                id={data._id}
                                                                onClick={(e) => { props.handleFilterData('tab', 'categoryid', data._id) }}>
                                                                <div className="tvch-card-body">
                                                                    {(data.icon !== '' || data.icon !== undefined) && <img className='imgsize' src={data.icon} />}
                                                                    <div className="channel-name mt-1">
                                                                        {(data.channelcategory !== '' || data.channelcategory !== undefined) && <p className="text-center">{data.channelcategory}</p>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className='tvchannel-header'>
                                            <div className='col-12'>
                                                <div className="tvchannelInner align-items-center">
                                                    {/* <div className='tableHeader'>Icon</div> */}
                                                    <span className='tableHeader'>Channel</span>
                                                    <span className='text-right tableHeader'>Channel No.</span>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            ((getCurrentState().formName?.length > 0) && (getCurrentState().data?.length === 0)) ?
                                                <div className='col-12'>
                                                    <div className='no-data-found'>
                                                        <div className='noData-content'>
                                                            <img src={NoDataImage} />
                                                            <h2 className="text-center text-16 mb-0 font-weight-600">No channels found in the specified category</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className='tv-grid-height col-12 px-0' onScroll={props.handleScroll}>
                                                    {
                                                        getCurrentState().data?.map((data) => {
                                                            return (
                                                                <>
                                                                    <div className='tvchannelInner maincontent align-items-center'>
                                                                        <div className='tvchannelContent'>
                                                                            <div className='tableImg'><img src={data.icon} /></div>
                                                                            {(data.channelname !== '' || data.channelname !== undefined) && <div className='tableContent text-left'>{data.channelname}</div>}
                                                                        </div>
                                                                        <div>
                                                                            <div className=''>
                                                                                {(data.channelno !== '' || data.channelno !== undefined) && <span className='d-block channelNoboder tableContent'>{data.channelno}</span>}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </div>
                                        }
                                    </div>
                        }
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default TvChannelLineUpView



