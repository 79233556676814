import _Config from './Config'
import _JsCall from '../view/JsCall'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { Buffer } from 'buffer'
import { toast } from 'react-toastify'
import { store } from '../index'
import { io } from 'socket.io-client'
import Resizer from "react-image-file-resizer";
import { setProps } from '../redux/actions'
import imageCompression from 'browser-image-compression'

import * as CryptoJS from 'crypto-js'


window.Buffer = Buffer

export var Config = new _Config()
export var JsCall = new _JsCall()

export var socket = io.connect(Config.getSocketserver())

export var url
export var reqHeaders
export var reqBody
export var resHeaders
export var resBody

export const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

export const pagelimit = [
    {
        "label": 20,
        "value": 20,
    },
    {
        "label": 50,
        "value": 50,
    },
    {
        "label": 100,
        "value": 100,
    },
    {
        "label": 500,
        "value": 500,
    },
    {
        "label": 1000,
        "value": 1000,
    }
]

export async function setReqParams(URL, reqbody) {
    url = URL
    reqBody = reqbody
}

//Image Types
export const allowdedImage = [".png", ".jpg", ".jpeg", ".gif"]
export const allowdedSVG = [".svg"]
export const allowdedAudio = [".mp3", ".flac", '.alac', '.pcm', '.wav', '.wma', '.aac', '.aiff', '.mpeg']
export const allowdedVideo = [".mov", ".wmv", '.avi', '.mp4', '.avchd', '.flv', '.f4v', '.swf', '.mkv', '.webm']
export const allowdedDocs = [".doc", ".docx", ".pdf", ".xlsx", ".pptx"]
export const allowdedAll = [".doc", ".svg", ".docx", ".pdf", ".xlsx", ".pptx", ".png", ".jpg", ".gif", ".mp3", ".flac", '.pcm', '.alac', '.aiff', '.m4a', '.mp4', '.wav', '.wma', '.aac', '.mov', '.wmv', '.avi', '.avchd', '.flv', '.f4v', '.swf', '.mkv', '.webm']
//Image Types

class _IISMethods {

    setCookiesData(key, value, path = '/') {
        const cookies = new Cookies()
        cookies.set(key, value, { path: path, maxAge: 12 * 60 * 60 })
    }

    getCookiesData(key) {
        const cookies = new Cookies()
        return cookies.get(key)
    }

    clearCookiesData(key, path = '/') {
        const cookies = new Cookies()
        cookies.remove(key, { path: path })
    }

    setLocalData(key, value) {
        localStorage.setItem(key, JSON.stringify(value))
    }

    getLocalData(key) {
        return JSON.parse(localStorage.getItem(key))
    }

    clearLocalData(key) {
        localStorage.removeItem(key)
    }

    getpagename() {
        var urlpath = window.location.pathname
        var pagename = urlpath.split("/")
        var pagename = pagename[pagename.length - 1]
        return pagename
    }

    getPreviouspage() {
        return window.history
    }

    getPageRights(pagename = this.getpagename()) {
        return this.getobjectfromarray(this.getLocalData("userRights"), 'alias', pagename) || {}
    }

    async axiosrequest(method, url, reqBody, reqHeaders, successCallback, errorCallback) {

        if (reqBody?._id?.length !== 24) {
            delete reqBody._id
        }
        await axios({
            method: method,
            url: url,
            data: reqBody,
            headers: reqHeaders
        })
            .then(response => { return successCallback(response) })
            .catch(error => { errorCallback(error) })
    }

    getRandom(number) {
        return Math.floor((Math.random() * number) + 1)
    }

    // Bhautik 12/06/2023 ~
    ConvertDateObjTOISOString(date) {
        let year = date.getFullYear()
        let month = date.getMonth()
        month = (month + 1).toString().length > 1 ? (month + 1) : `0${(month + 1)}`
        let day = date.getDate()
        day = (day).toString().length > 1 ? (day) : `0${(day)}`
        let hr = date.getHours()
        hr = (hr).toString().length > 1 ? (hr) : `0${(hr)}`
        let min = date.getMinutes()
        min = (min).toString().length > 1 ? (min) : `0${(min)}`
        let sec = date.getSeconds()
        sec = (sec).toString().length > 1 ? (sec) : `0${(sec)}`
        return `${year}-${month}-${day}T${hr}:${min}:${sec}.000Z`
    }

    setStartDateTime(date) {
        let hh = new Date(date).getHours()
        let mm = new Date(date).getMinutes()
        let dd = new Date(date).getSeconds()
        return new Date('1970', '00', '01', hh, mm, dd)
    }

    // pass date object and set time to 00:00:00
    setStartTimeOfDay(date) {
        let year = new Date(date).getFullYear()
        let month = new Date(date).getMonth()
        let day = new Date(date).getDate()
        return new Date(year, month, day, 0, 0, 0, 0)
    }

    // pass date object and set time to 23:59:59
    setEndTimeOfDay(date) {
        let year = new Date(date).getFullYear()
        let month = new Date(date).getMonth()
        let day = new Date(date).getDate()
        return new Date(year, month, day, 23, 59, 59)
    }

    GetISOdate(date) {
        let propertydata = this.getCurrentState().propertyData
        let sysdate = date || propertydata?.systemdate
        let splitdate = sysdate ? sysdate?.split('T')[0] : ''
        return splitdate.replaceAll('-', '/')
    }

    //srushti 22-2-23
    checkFiletype(fileName, allowedFiles) {
        var fileExtensionPattern = /\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/gmi

        let fileExtension = fileName.match(fileExtensionPattern)[0]
        // var allowedFiles = [".doc", ".docx", ".pdf",".xlsx",".pptx" , ".png" , ".jpg"]
        var regex = new RegExp("([a-zA-Z0-9\s_\\.\-:])+(" + allowedFiles.join('|') + ")$")
        return regex.test("iis" + fileExtension.toLowerCase())
    }

    getCurrentState() {
        return store.getState()['root']
    }

    //Heli 15/08/2023 01:25 start
    // encrypt
    encryptData(data) {
        const randomiv = this.generateRandomIv()
        const secretKey = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPT_SECRETKEY)
        // console.log("randomiv",randomiv)
        // const secretKey = CryptoJS.enc.Hex.parse(Config.getSekretkey())
        const iv = CryptoJS.enc.Utf8.parse(randomiv)
        // console.log("iv",iv)
        const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey, { iv: iv })
        // console.log(encrypted)
        // console.log("encrypted tostring",encrypted.toString())
        return encrypted.toString() + '.' + randomiv
    }

    // decrypt
    decryptData(data) {
        console.log(data, "data")
        let plaintext
        const secretKey = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPT_SECRETKEY)
        // const secretKey = CryptoJS.enc.Hex.parse(Config.getSekretkey())
        let ciphertext = data.split('.')
        let message = ciphertext[0]
        let iv = CryptoJS.enc.Utf8.parse(ciphertext[1])
        const bytes = CryptoJS.AES.decrypt(message, secretKey, { iv: iv })
        plaintext = bytes.toString(CryptoJS.enc.Utf8)
        return plaintext
    }

    // BR ~ 01-09-2023 ~ 02:26 PM ~ get data Encryted/Descripted
    getEDD(mode = 0, data) {
        let key = Config.socketencryptdata ? mode : 0
        switch (key) {
            case 1:
                return this.encryptData(data)

            case 2:
                return JSON.parse(this.decryptData(data))

            default:
                return data || null
        }
        // if (Config.encryptdata) return this.encryptData(data)
    }

    // get random 16-bit iv for encryption/decryption
    generateRandomIv(length = 16) {
        return this.generateRandomString(length)
    }

    /**
     * generate n-length random alpha numeric str
     * @param {Number} length 
     * @returns 
     */
    generateRandomString(length) {
        let result = ''
        const charactersLength = characters.length
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength))
        }
        return result
    }

    //Heli 15/08/2023 01:25 end

    GetCurrentTime() {
        // let propertydata = this.getPropertydata()
        // let sysdate = this.GetDT().toISOString()
        // let systime = this.GetDateTimefromISOString(this.GetDateTimefromISOString(this.GetDateTime().toISOString()).toISOString()).toLocaleTimeString()
        // let date = (sysdate && systime) ? this.GetISOdate(sysdate) + ' ' + systime : ''
        // let convertdate = date ? this.GetDateTime(this.GetDT(date)).toISOString() : ''
        let systime = this.GetDateTimefromISOString(this.GetDateTime().toISOString())
        // let date = systime 
        let convertdate = systime.toISOString()
        return convertdate
    }

    //getDateFormat
    getDateFormatByProperty() {
        return 'dd MMM, yyyy'
    }

    //getTimeFormat
    getTimeFormatByProperty() {
        return 'hh:mm aa'
    }

    //getDateTimeFormat
    getDateTimeFormatByProperty() {
        return 'dd MMM, yyyy hh:mm aa'
    }

    // day difference
    GetDiffInDay(d1, d2) {
        let days = (d2 - d1) / (1000 * 60 * 60 * 24)
        return Math.round(days)
    }

    // Bhautik 16-03-2023 09:26 AM ~ For Get Date 
    GetDT(date, days = 0) {
        try {
            let dt = date ? new Date(date) : new Date()
            dt.setDate(dt.getDate() + days)
            return dt
        }
        catch {
            return 'Invalid Date'
        }
    }

    // Get Timezone Date/Time Obj from ISO String
    GetDateTimefromISOString(isostring) {
        let offset = this.getPropertyOffset(isostring) // only date
        let decimaloffset = offset[0] + (Number(offset[1] + offset[2]) + (Number(offset[3] + offset[4])) / 60).toString()
        let date = new Date(isostring)
        let utc = date.getTime() + date.getTimezoneOffset() * 60000;
        let additionaldiff = ((60 * decimaloffset) + date.getTimezoneOffset())
        let nd = new Date(utc - (date.getTimezoneOffset() * 60000) + (additionaldiff * 60000))
        return nd
    }

    // Bhautik 13-03-2023 10:59 AM
    GetTZdatetime(date = null) {
        try {
            let tzDate = this.GetDateTimefromISOString(this.GetDateTime(date).toISOString())
            return tzDate
        }
        catch {
            return ''
        }
    }

    GetDateTime(date = null, offset = null) {
        if (!date) { date = new Date() }
        offset = !offset ? this.getPropertyOffset(date) : offset// only date
        let sysdate = new Date()

        let decimaloffset = offset[0] + (Number(offset[1] + offset[2]) + (Number(offset[3] + offset[4])) / 60).toString()

        let utc = date.getTime() + date.getTimezoneOffset() * 60000;
        let additionaldiff = ((60 * decimaloffset) + sysdate.getTimezoneOffset()) * 2
        let nd = new Date(utc + (3600000 * decimaloffset) - (additionaldiff * 60000))
        return nd
    }

    GetISOFromDTobj(date) {
        try {
            return this.GetDateTime(date).toISOString()
        }
        catch {
            return ''
        }
    }

    getPropertyLocation() {
        let propertydata = this.getCurrentState().propertyData
        return propertydata?.location || {}
    }

    getPropertyOffset(date) {
        let propertydata = this.getPropertyLocation()
        let offset = propertydata['offset']
        let offsetdata = propertydata['offsetdata'] || []
        let offsetindex = offsetdata.findIndex(poffset => {
            let getdate = date
            if ((new Date(poffset.fromdate) <= new Date(getdate)) && (new Date(poffset.todate) >= new Date(getdate))) return true
            else return false
        })
        if (offsetindex !== -1) {
            offset = offsetdata[offsetindex]['offsets']
        }
        return offset || '+0000'
    }

    //copy from Frontdesk JENIL 05/08/2023 1:04 PM
    // GetPropertyDateWithCurrentTime() {
    //     let propertydata = IISMethods.getPropertyData()
    //     let sysdate = propertydata?.systemdate
    //     let systime = this.GetDateTimefromISOString(this.GetDateTimefromISOString(this.GetDateTime().toISOString()).toISOString()).toLocaleTimeString()
    //     let date = (sysdate && systime) ? this.GetISOdate(sysdate) + ' ' + systime : ''
    //     let convertdate = date ? this.GetDateTime(this.GetDT(date)).toISOString() : ''
    //     return convertdate
    // }

    // //copy from Frontdesk JENIL 05/08/2023 1:04 PM
    // handleDate = (responsedate) => {
    //     let resdate = IISMethods.GetDateTimefromISOString(responsedate)
    //     let sysdate = IISMethods.GetDateTimefromISOString(IISMethods.GetPropertyDateWithCurrentTime())

    //     let datadiff = resdate.getDate() - sysdate.getDate()
    //     if (datadiff == 0) {
    //         return ('Today')
    //     }
    //     else if (datadiff == -1) {
    //         return ('Yesterday')
    //     }
    //     else if (datadiff == 1) {
    //         return ('Tomorrow')
    //     }
    //     else {
    //         return IISMethods.getDateFormat(responsedate)
    //     }
    // }

    // Get Timezone Date/Time Obj from ISO String
    // GetDateTimefromISOString(isostring) {
    //     let offset = this.getPropertyOffset(isostring) // only date
    //     let decimaloffset = offset[0] + (Number(offset[1] + offset[2]) + (Number(offset[3] + offset[4])) / 60).toString()

    //     let date = new Date(isostring)
    //     let utc = date.getTime() + date.getTimezoneOffset() * 60000;
    //     let additionaldiff = ((60 * decimaloffset) + date.getTimezoneOffset())
    //     let nd = new Date(utc - (date.getTimezoneOffset() * 60000) + (additionaldiff * 60000))
    //     return nd
    // }

    // Bhautik 13-03-2023 10:59 AM
    GetTZdatetime(date = null) {
        try {
            let tzDate = this.GetDateTimefromISOString(this.GetDateTime(date).toISOString())
            return tzDate
        }
        catch {
            return ''
        }
    }

    // Bhautik 14-03-2023 10:47AM ~ Get Date Obj from ISO String
    GetTimefromISOString(isostring) {
        let offset = this.getPropertyOffset(isostring) // only date
        let decimaloffset = offset[0] + (Number(offset[1] + offset[2]) + (Number(offset[3] + offset[4])) / 60).toString()

        let date = new Date(isostring)
        let utc = date.getTime() + date.getTimezoneOffset() * 60000;
        let additionaldiff = ((60 * decimaloffset) + date.getTimezoneOffset())
        let nd = new Date(utc - (date.getTimezoneOffset() * 60000) + (additionaldiff * 60000))
        nd.setFullYear('1970')
        return nd
    }

    // Bhautik 14-03-2023 10:47AM ~ Get Time from Current timezone to property timezone
    GetTZTime(date = null, offset = null) {
        if (!date) { date = new Date('1970-01-01') }
        date.setMilliseconds('00')
        date.setSeconds('00')
        date.setDate('01')
        date.setMonth('00')
        date.setFullYear('1970')
        offset = !offset ? this.getPropertyOffset(date) : offset// only date
        let sysdate = new Date('1970-01-01')
        let decimaloffset = offset[0] + (Number(offset[1] + offset[2]) + (Number(offset[3] + offset[4])) / 60).toString()

        let utc = date.getTime() + date.getTimezoneOffset() * 60000;
        let additionaldiff = ((60 * decimaloffset) + sysdate.getTimezoneOffset()) * 2
        let nd = new Date(utc + (3600000 * decimaloffset) - (additionaldiff * 60000))
        nd.setUTCDate('01')
        nd.setUTCFullYear('1970')
        return nd
    }

    GetISOFromTMobj(time) {
        try {
            return this.GetTZTime(time).toISOString()
        }
        catch {
            return ''
        }
    }

    // Bhautik 14-03-2023 10:47AM ~ Get Date Obj from ISO String
    GetISOtoTZtime(date) {
        try {
            let tzDate = this.GetTimefromISOString(date)
            return tzDate
        }
        catch {
            return ''
        }
    }

    secondToHrsAndMins(second, format = 'HH:MM') {
        //charmi(17-05-2023)(for second < 0)
        if (second < 0) {
            second = second.toString()
            second = second.replace('-', '')
            second = parseInt(second)
        }
        else {
            second = second
        }
        //charmi(17-05-2023)
        const totalMinutes = Math.floor(second / 60);
        const seconds = second % 60;
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;

        return hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + (format === "HH:MM" ? '' : ':' + parseInt(seconds).toString().padStart(2, '0'))
    }

    getDateFormat(date, format = 4) {
        try {
            let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
            let dateobj = date
            let year = dateobj.getFullYear()  // 2022
            let month = dateobj.getMonth() + 1  // 9
            let day = dateobj.getDate()      // 1
            let yy = year.toString().slice(-2) || ''; // 22
            let mm = month.toString().padStart(2, '0') || ''; // 09
            let dd = day.toString().padStart(2, '0') || '';   // 01
            let shortmonth = dateobj.toLocaleString('default', { month: 'short' }) || ''; // Sep
            let weekday = days[dateobj.getDay()]

            let dateyear = dateobj.getFullYear()

            if (year == 'NaN') {
                alert('NaN')
            }

            if (format === 1) {
                date = (year || '') + "-" + (mm || '') + "-" + (dd || '') //  2022-09-01
            }
            else if (format === 2) {
                date = month + "/" + day + "/" + year  //  9/1/2022
            }
            else if (format === 3) {
                date = month + "/" + day + "/" + yy
            }
            else if (format === 4) {
                date = dd + " " + shortmonth + ", " + year
            }
            else if (format === 5) {
                date = yy + "/" + mm + "/" + dd
            }
            else if (format === 6) {
                date = mm + "/" + dd + "/" + yy
            }
            else if (format === 7) {
                date = dd + "/" + mm + "/" + year
            }
            else if (format === 8) {
                if (dateyear === new Date().getFullYear()) {
                    date = weekday + ", " + shortmonth + " " + mm
                }
                else {
                    date = shortmonth + " " + mm + ', ' + year
                }
            }
            else if (format === 9) { // for Chrome & Firefox support
                date = (year || '') + "/" + (mm || '') + "/" + (dd || '') //  2022/09/01
            }
            else if (format === 10) { //heli_15_02_2023 5:23 p.m.
                date = mm + "-" + dd + "-" + year
            }
            // else  if (format === 1) {
            //     
            // }

        }
        catch {
            date = "-"

        }
        return date
    }

    //copy from frontdesk JENIL 05/08/2023 4:30 PM
    handleDate = (responsedate = new Date()) => {
        let resdate = IISMethods.GetDateTimefromISOString(responsedate)
        let sysdate = IISMethods.GetDateTimefromISOString(IISMethods.GetDT())
        let datadiff = resdate.getDate() - sysdate.getDate()
        if (datadiff == 0) {
            return ('Today')
        }
        else if (datadiff == -1) {
            return ('Yesterday')
        }
        else if (datadiff == 1) {
            return ('Tomorrow')
        }
        else {
            return IISMethods.GetDateFormat(responsedate)
        }
    }

    GetDateFormat(date, format = 4, timezone = false) {
        try {
            // let dateformat = this.getCookiesData("kiosist_logininfo").dateformat || 1
            let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
            let propertylist = this.getCurrentState().propertyData//charmi(30-03-2023)
            // date = date.toString()
            const dateobj = this.GetDateTimefromISOString(date)
            let year = dateobj.getFullYear()  // 2022
            let month = dateobj.getMonth() + 1  // 9
            let day = dateobj.getDate()      // 1
            let yy = year.toString().slice(-2) || ''; // 22
            let mm = month.toString().padStart(2, '0') || ''; // 09
            let dd = day.toString().padStart(2, '0') || '';   // 01
            let shortmonth = dateobj.toLocaleString('default', { month: 'short' }) || ''; // Sep
            let weekday = days[dateobj.getDay()]
            let dateyear = dateobj.getFullYear()

            if (year == 'NaN') {
                alert('NaN')
            }

            if (format === 1) {
                date = (year || '') + "-" + (mm || '') + "-" + (dd || '') //  2022-09-01
            }
            else if (format === 2) {
                date = month + "/" + day + "/" + year  //  9/1/2022
            }
            else if (format === 3) {
                date = month + "/" + day + "/" + yy
            }
            else if (format === 4) {
                date = dd + " " + shortmonth + ", " + year
            }
            else if (format === 5) {
                date = yy + "/" + mm + "/" + dd
            }
            else if (format === 6) {
                date = mm + "/" + dd + "/" + yy
            }
            else if (format === 7) {
                date = dd + "/" + mm + "/" + year
            }
            else if (format === 8) {
                if (dateyear === new Date().getFullYear()) {
                    date = weekday + ", " + shortmonth + " " + mm
                }
                else {
                    date = shortmonth + " " + mm + ', ' + year
                }
            }
            else if (format === 9) {
                date = (year || '') + "/" + (mm || '') + "/" + (dd || '')
            }
            else if (format === 10) {
                date = mm + "-" + dd + "-" + year
            }
            else if (format === 11) {
                date = shortmonth + " " + dd + ", " + year
            }
            // else  if (format === 1) {
            // }

        }
        catch (e) {
            date = "-"
        }
        return date
    }

    GetTimeFormat(time, format = 1) {// notime===true ==> if time is empty string and want to display dash(-)
        try {
            // let propertylist = this.getobjectfromarray(this.getLocalData('headerpropertyData'), '_id', this.getCookiesData("kiosist_logininfo").propertyid)

            if (time === "" || !time) {
                time = "-"
            }
            else {
                const timeobj = this.GetDateTimefromISOString(time)
                let hour = timeobj.getHours()
                let minute = timeobj.getMinutes().toString().padStart(2, '0')
                let second = timeobj.getSeconds().toString().padStart(2, '0')

                if (format === 1) {
                    const ampm = hour >= 12 ? 'PM' : 'AM'
                    hour = hour % 12
                    hour = hour ? hour : 12
                    time = hour.toString().padStart(2, '0') + ":" + minute.toString().padStart(2, '0') + " " + ampm
                }
                else if (format === 2) {
                    time = hour + ":" + minute
                }
                else if (format === 3) {
                    const ampm = hour >= 12 ? 'PM' : 'AM'
                    hour = hour % 12
                    hour = hour ? hour : 12
                    time = hour.toString().padStart(2, '0') + ":" + minute.toString().padStart(2, '0') + ":" + second.toString().padStart(2, '0') + " " + ampm
                }
            }
        }
        catch {
            time = "Invalid Time"

        }
        return time
    }

    stringToInt(value) {
        value = parseInt(value)
        return isNaN(value) ? 0 : value
    }

    getUniqueElementsFormArray(arr, n = 1) {
        arr = arr.sort(() => 0.5 - Math.random())
        // let m = arr.length
        // while (m) {
        //     const i = Math.floor(Math.random() * m--);
        //     [arr[m], arr[i]] = [arr[i], arr[m]]
        // }
        return arr.slice(0, n)
    }

    getobjectfromarray(arr, key, value) {
        try {
            return arr.find(o => o[key] == value)
        }
        catch (e) {
            return undefined
        }
    }

    getindexfromarray(arr, key, value) {
        return arr.findIndex(o => o[key] === value)
    }

    getindexfromarraydoublekey(arr, key1, key2, value1, value2) {
        return arr.findIndex(o => o[key1] === value1 && o[key2] === value2)
    }

    getmultipleobjectsfromarray(arr, key, value) {
        return arr.filter(o => o[key] === value)
    }

    getCopy(element) {
        try {
            return element.map(o => Object.assign({}, o))
        }
        catch {
            return Object.assign({}, element)
        }
    }

    removeDuplicateObjectsFromArray(arr) {
        return arr.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.place === value.place && t.name === value.name
            ))
        )
    }

    notify(message, status) {
        if (!status && resBody.status === 200) {
            toast.success(message ? message : Config.errmsg.inserteddata, {
                theme: "light",
                icon: "🟢"
            })
        }
        else if (status === 1) {
            toast.warn(message, {
                theme: "light",
                icon: "🟡"
            })
        }
        else {
            toast.error(message, {
                theme: "light",
                icon: "🔴"
            })

        }
    }
    // for local notification without req , res 
    localnotify(message, status) {
        if (status == 1) {
            toast.success(message, {
                theme: "light",
                icon: "🟢"
            })
        }
        else if (status == 2) {
            toast.warn(message, {
                theme: "light",
                icon: "🟡"
            })
        }
        else {
            toast.error(message, {
                theme: "light",
                icon: "🔴"
            })
        }
    }

    tokenExpiry() {
        if (resHeaders.key && resHeaders.unqkey) {
            var loginInfo = this.getCookiesData(Config.cookiestorage['loginInfo'])

            loginInfo.token = resHeaders.key
            loginInfo.unqkey = resHeaders.unqkey

            this.setCookiesData(Config.cookiestorage['loginInfo'], loginInfo)//charmi(13-02-2023)
            // this.setCookiesData("kiosist_logininfo_mobilecheckin", loginInfo)
        }
    }

    // sessionExpiry() {
    //     if (resBody.status === 401 && resBody.message === Config.getErrmsg()['invalidtoken']) {
    //         this.logout()
    //     }
    // }

    async fileToBase64(file) {
        const getBase64 = (file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = () => resolve(reader.result)
                reader.onerror = error => reject(error)
            })
        }

        var base64File

        await getBase64(file)
            .then(data => base64File = data)
            .catch(error => base64File = '')

        return base64File
    }

    async listData(useraction, pagename = "", masterlisting, mainDb = {}) {
        const loginInfo = this.getCookiesData(Config.cookiestorage['loginInfo'])
        const propertydata = this.getCurrentState().propertyData//charmi(30-03-2023)
        const systemdate = this.GetDT().toISOString()//charmi(03-04-2023)

        let reqheader = {
            token: loginInfo?.token,
            issuer: "website",// process.env.REACT_APP_ISSUER,
            unqkey: loginInfo.unqkey,
            uid: loginInfo.uid,
            platform: "1",// process.env.REACT_APP_PLATFORM,
            useraction: useraction,
            pagename: "/" + pagename,
            subdomainname: loginInfo.subdomainname,
            // companyid : this.getLocalData("companyid"),
            companyid: loginInfo.companyid,//charmi(13-02-2023)
            // systemdate: systemdateIOSFormate, //jatin(22-02-2023)
            systemdate: systemdate, //charmi(03-04-2023)
            timezone: propertydata?.location?.timezone,
            username: 'guest', //charmi(16-03-2023)
            propertyid: propertydata?._id || '', // Bhautik 15-06-2023 10:09 AM
            branchid: propertydata?._id || '', // Bhautik 15-06-2023 10:09 AM
        }

        if (Config.encryptdata) { reqHeaders = { reqheader: this.encryptData(reqheader) } }
        else { reqHeaders = { ...reqheader } }

        let index = -1
        try { index = reqBody?.indexOf('.') }
        catch { index = -1 }

        if (Config.encryptdata && index == -1) {
            if (!reqBody.reqBody) { reqBody = { reqBody: this.encryptData(reqBody) } }
        }
        await this.axiosrequest(process.env.REACT_APP_METHOD_POST, url, reqBody, reqHeaders, listDataSuccessCallback.bind(this), listDataErrorCallback.bind(this))

        function listDataSuccessCallback(res) {
            if (Config.encryptdata) {
                try { resBody = JSON.parse(JSON.parse(this.decryptData(res.data))) }
                catch (e) { resBody = res.data }
                // decrypt res header
                if (res.headers.reqheader) { resHeaders = JSON.parse(JSON.parse(this.decryptData(res.headers.reqheader))) }
                else { resHeaders = res.headers }
            }
            else {
                resBody = res.data
                resHeaders = res.headers
            }
        }

        function listDataErrorCallback(error) {
            if (Config.encryptdata) {
                // decrypt res body
                try { resBody = JSON.parse(JSON.parse(this.decryptData(error.response.data))) }
                catch (e) { resBody = error.response.data }
                // decrypt res header
                if (error.response.headers.reqheader) { resHeaders = JSON.parse(JSON.parse(this.decryptData(error.response.headers.reqheader))) }
                else { resHeaders = error.response.headers }
            }
            else {
                resBody = error.response.data
                resHeaders = error.response.headers
            }
        }

        // this.sessionExpiry()
        this.tokenExpiry()
    }

    // Add Function ~ mainDb > for add header data
    async addData(useraction, pagename = "", notify = false, mainDb = {}) {
        const loginInfo = this.getCookiesData(Config.cookiestorage['loginInfo'])
        const propertydata = this.getCurrentState().propertyData//charmi(30-03-2023)
        //charmi(03-04-2023)
        const systemdate = this.GetDT().toISOString()
        let reqheader = {
            token: loginInfo?.token,
            issuer: "website",// process.env.REACT_APP_ISSUER,
            unqkey: loginInfo.unqkey,
            // uid: loginInfo.bookingid,
            uid: loginInfo.uid,
            platform: "1",// process.env.REACT_APP_PLATFORM,
            useraction: useraction,
            pagename: "/" + pagename,
            subdomainname: loginInfo.subdomainname,
            companyid: loginInfo.companyid,//charmi(13-02-2023)
            // systemdate: systemdateIOSFormate, //jatin(23-02-2023)
            propertyid: propertydata?._id || '', // Bhautik 15-06-2023 10:09 AM
            branchid: propertydata?._id || '', // Bhautik 15-06-2023 10:09 AM
            systemdate: systemdate, //charmi(03-04-2023)
            timezone: propertydata?.location?.timezone,
            username: 'guest', //charmi(16-03-2023) 
            moduletype: 'guestexperience',
            ...mainDb
        }

        if (Config.encryptdata) { reqHeaders = { reqheader: this.encryptData(reqheader) } }
        else { reqHeaders = { ...reqheader } }

        let index = -1
        try { index = reqBody?.indexOf('.') }
        catch { index = -1 }

        if (Config.encryptdata && index == -1) {
            if (!reqBody.reqBody) { reqBody = { reqBody: this.encryptData(reqBody) } }
        }
        await this.axiosrequest(process.env.REACT_APP_METHOD_POST, url, reqBody, reqHeaders, addDataSuccessCallback.bind(this), addDataErrorCallback.bind(this))

        function addDataSuccessCallback(res) {
            if (Config.encryptdata) {
                try { resBody = JSON.parse(JSON.parse(this.decryptData(res.data))) }
                catch (e) { resBody = res.data }
                // decrypt res header
                if (res.headers.resheader) {
                    resHeaders = JSON.parse(JSON.parse(this.decryptData(res.headers.resheader)))
                }
                else { resHeaders = res.headers }
            }
            else {
                resBody = res.data
                resHeaders = res.headers
            }
        }

        function addDataErrorCallback(error) {
            if (Config.encryptdata) {
                // decrypt res body
                try { resBody = JSON.parse(JSON.parse(this.decryptData(error.response.data))) }
                catch (e) { resBody = error.response.data }
                // decrypt res header
                if (error.response.headers.reqheader) { resHeaders = JSON.parse(JSON.parse(this.decryptData(error.response.headers.reqheader))) }
                else { resHeaders = error.response.headers }
            }
            else {
                resBody = error.response.data
                resHeaders = error.response.headers
            }
        }

        // this.sessionExpiry()
        this.tokenExpiry()
        if (notify)
            this.notify(resBody.message)
    }

    async updateData(useraction, showResponseMessage = true, decrypt) {
        const loginInfo = this.getCookiesData(Config.cookiestorage['loginInfo'])

        const propertydata = this.getCurrentState().propertyData//charmi(30-03-2023)
        //charmi(03-04-2023)
        const systemdate = this.GetDT().toISOString()
        //charmi(03-04-2023)

        let reqheader = {
            token: loginInfo.token,
            issuer: process.env.REACT_APP_ISSUER,
            unqkey: loginInfo.unqkey,
            uid: loginInfo.uid,
            platform: process.env.REACT_APP_PLATFORM,
            useraction: useraction,
            pagename: this.getpagename(),
            subdomainname: loginInfo.subdomainname,
            username: loginInfo.firstname + ' ' + loginInfo.lastname,
            companyid: loginInfo.companyid,
            // systemdate : systemdateIOSFormate, // jatin 23-02-2023
            systemdate: systemdate, // charmi(03-04-2023)
            timezone: propertydata?.location?.timezone,
            username: 'guest',//charmi(16-03-2023)
            propertyid: propertydata?._id || '', // Bhautik 15-06-2023 10:09 AM
            branchid: propertydata?._id || '', // Bhautik 15-06-2023 10:09 AM
            // branchid: loginInfo.branchid,
            // userroleid: loginInfo.userroleid
        }
        if (Config.encryptdata) { reqHeaders = { reqheader: this.encryptData(reqheader) } }
        else { reqHeaders = { ...reqheader } }

        let index = -1
        try { index = reqBody?.indexOf('.') }
        catch { index = -1 }

        if (Config.encryptdata && index == -1 && !decrypt) {
            if (!reqBody.reqBody) { reqBody = { reqBody: this.encryptData(reqBody) } }
        }

        await this.axiosrequest(process.env.REACT_APP_METHOD_POST, url, reqBody, reqHeaders, upadateDataSuccessCallback.bind(this), upadateDataErrorCallback.bind(this))

        function upadateDataSuccessCallback(res) {
            if (Config.encryptdata) {
                try { resBody = JSON.parse(JSON.parse(this.decryptData(res.data))) }
                catch (e) { resBody = res.data }
                // decrypt res header
                if (res.headers.reqheader) { resHeaders = JSON.parse(JSON.parse(this.decryptData(res.headers))) }
                else { resHeaders = res.headers }
            }
            else {
                resBody = res.data
                resHeaders = res.headers
            }
        }

        function upadateDataErrorCallback(error) {
            if (Config.encryptdata) {
                // decrypt res body
                try { resBody = JSON.parse(JSON.parse(this.decryptData(error.response.data))) }
                catch (e) { resBody = error.response.data }
                // decrypt res header
                if (error.response.headers.reqheader) { resHeaders = JSON.parse(JSON.parse(this.decryptData(error.response.headers.reqheader))) }
                else { resHeaders = error.response.headers }
            }
            else {
                resBody = error.response.data
                resHeaders = error.response.headers
            }
        }

        // this.sessionExpiry()
        // this.tokenExpiry()
        if (showResponseMessage)
            this.notify(resBody.message)
    }

    async deleteData(useraction) {
        const loginInfo = this.getCookiesData(Config.cookiestorage['loginInfo'])
        const propertydata = this.getCurrentState().propertyData//charmi(30-03-2023)

        let reqheader = {
            token: loginInfo.token,
            issuer: process.env.REACT_APP_ISSUER,
            unqkey: loginInfo.unqkey,
            uid: loginInfo.uid,
            platform: process.env.REACT_APP_PLATFORM,
            useraction: useraction,
            pagename: this.getpagename(),
            subdomainname: loginInfo.subdomainname,
            username: loginInfo.firstname + ' ' + loginInfo.lastname,
            companyid: loginInfo.companyid,
            branchid: loginInfo.branchid,
            userroleid: loginInfo.userroleid,
            timezone: propertydata?.location?.timezone,
            username: 'guest', //charmi(16-03-2023)
            propertyid: propertydata?._id || '', // Bhautik 15-06-2023 10:09 AM
            branchid: propertydata?._id || '', // Bhautik 15-06-2023 10:09 AM
        }
        if (Config.encryptdata) { reqHeaders = { reqheader: this.encryptData(reqheader) } }
        else { reqHeaders = { ...reqheader } }

        let index = -1
        try { index = reqBody?.indexOf('.') }
        catch { index = -1 }

        if (Config.encryptdata && index == -1) {
            if (!reqBody.reqBody) { reqBody = { reqBody: this.encryptData(reqBody) } }
        }
        await this.axiosrequest(process.env.REACT_APP_METHOD_DEL, url, reqBody, reqHeaders, deleteDataSuccessCallback.bind(this), deleteDataErrorCallback.bind(this))

        function deleteDataSuccessCallback(res) {
            if (Config.encryptdata) {
                try { resBody = JSON.parse(JSON.parse(this.decryptData(res.data))) }
                catch (e) { resBody = res.data }
                // decrypt res header
                if (res.headers.reqheader) { resHeaders = JSON.parse(JSON.parse(this.decryptData(res.headers))) }
                else { resHeaders = res.headers }
            }
            else {
                resBody = res.data
                resHeaders = res.headers
            }
        }

        function deleteDataErrorCallback(error) {
            if (Config.encryptdata) {
                // decrypt res body
                try { resBody = JSON.parse(JSON.parse(this.decryptData(error.response.data))) }
                catch (e) { resBody = error.response.data }
                // decrypt res header
                if (error.response.headers.reqheader) { resHeaders = JSON.parse(JSON.parse(this.decryptData(error.response.headers.reqheader))) }
                else { resHeaders = error.response.headers }
            }
            else {
                resBody = error.response.data
                resHeaders = error.response.headers
            }
        }

        // this.sessionExpiry()
        this.tokenExpiry()
        this.notify(resBody.message)
    }

    async updateFieldOrder(tablename, fieldorder, useraction) {
        const url = Config.weburl + 'fieldorder/add'
        const loginInfo = this.getCookiesData(Config.cookiestorage['loginInfo'])
        const propertydata = this.getCurrentState().propertyData //Bhautik 15-06-2023 10:09 AM

        let reqheader = {
            token: loginInfo.token,
            issuer: process.env.REACT_APP_ISSUER,
            unqkey: loginInfo.unqkey,
            uid: loginInfo.uid,
            platform: process.env.REACT_APP_PLATFORM,
            useraction: useraction,
            pagename: this.getpagename(),
            subdomainname: loginInfo.subdomainname,
            username: loginInfo.firstname + ' ' + loginInfo.lastname,
            companyid: loginInfo.companyid,
            branchid: loginInfo.branchid,
            userroleid: loginInfo.userroleid,
            username: 'guest', //charmi(16-03-2023)
            propertyid: propertydata?._id || '', // Bhautik 15-06-2023 10:09 AM
            branchid: propertydata?._id || '', // Bhautik 15-06-2023 10:09 AM

        }

        if (Config.encryptdata) { reqHeaders = { reqheader: this.encryptData(reqheader) } }
        else { reqHeaders = { ...reqheader } }

        if (Config.encryptdata) {
            reqBody = {
                pagename: tablename,
                userid: loginInfo.uid,
                fields: fieldorder
            }
            if (!reqBody.reqbody) { reqBody = { reqBody: this.encryptData(reqBody) } }
        }
        else {
            reqBody = {
                pagename: tablename,
                userid: loginInfo.uid,
                fields: fieldorder
            }
        }

        await this.axiosrequest(process.env.REACT_APP_METHOD_POST, url, reqBody, reqHeaders, updateFieldOrderSuccessCallback, updateFieldOrderErrorCallback)

        function updateFieldOrderSuccessCallback(res) {
            if (Config.encryptdata) {
                try { resBody = JSON.parse(JSON.parse(this.decryptData(res.data))) }
                catch (e) { resBody = res.data }
                // decrypt res header
                if (res.headers.reqheader) { resHeaders = JSON.parse(JSON.parse(this.decryptData(res.headers.reqheader))) }
                else { resHeaders = res.headers }
            }
            else {
                resBody = res.data
                resHeaders = res.headers
            }
        }

        function updateFieldOrderErrorCallback(error) {
            if (Config.encryptdata) {
                // decrypt res body
                try { resBody = JSON.parse(JSON.parse(this.decryptData(error.response.data))) }
                catch (e) { resBody = error.response.data }
                // decrypt res header
                if (error.response.headers.reqheader) { resHeaders = JSON.parse(JSON.parse(this.decryptData(error.response.headers.reqheader))) }
                else { resHeaders = error.response.headers }
            }
            else {
                resBody = error.response.data
                resHeaders = error.response.headers
            }
        }

        // this.sessionExpiry()
        this.tokenExpiry()
        // this.notify(resBody.message)
    }

    //get booking id and subdomain
    getBookingIDSubDomain() {
        // if (Config.getServermode() === 'prod') {
        //     let bookingid = window.location.search !== '' ? window.location.search.split('=')[1].split('-')[1] : this.getCookiesData(Config.cookiestorage['loginInfo']).bookingid
        //     let subdomainname = window.location.search !== '' ? window.location.search.split('=')[1].split('-')[0] : this.getCookiesData(Config.cookiestorage['loginInfo']).subdomainname
        //     return { bookingid, subdomainname }
        // }
        // else {
        let urlparams = new URLSearchParams(window.location.search)
        let subdomainname = window.location.search !== '' ? urlparams?.get('bookingid')?.split('-')[0] : this.getCookiesData(Config.cookiestorage['loginInfo']).subdomainname
        let bookingid = window.location.search !== '' ? urlparams?.get('bookingid')?.split('-')[1] : this.getCookiesData(Config.cookiestorage['loginInfo']).bookingid
        return { bookingid, subdomainname }
        // }
    }

    // Bhautik 06-07-2023 11:09 AM ~ for get Login Info
    getGuestInfo() {
        let loginInfo = this.getCookiesData(Config.cookiestorage['loginInfo'])
        return loginInfo || {}
    }

    //get query string url
    // getQueryString(pagename = null) {
    //     if (Config.getServermode() === 'prod') {
    //         let bookingid = window.location.search !== '' ? window.location.search.split('=')[1].split('-')[1] : this.getCookiesData(Config.cookiestorage['loginInfo']).bookingid
    //         let subdomainname = window.location.search !== '' ? window.location.search.split('=')[1].split('-')[0] : this.getCookiesData(Config.cookiestorage['loginInfo']).subdomainname
    //         let querystring = `?bookingid=${subdomainname}-${bookingid}`
    //         return querystring
    //     }
    //     else {
    //         if (pagename !== 'home' && !pagename) {
    //         let urlparams = new URLSearchParams(window.location.search)
    //         let subdomainname = window.location.search !== '' ? urlparams?.get('bookingid')?.split('-')[0] : this.getCookiesData(Config.cookiestorage['loginInfo']).subdomainname
    //         let bookingid = window.location.search !== '' ? urlparams?.get('bookingid')?.split('-')[1] : this.getCookiesData(Config.cookiestorage['loginInfo']).bookingid
    //         let querystring = `?bookingid=${subdomainname}-${bookingid}`
    //         return querystring
    //         }
    //         return ""
    //     }
    // }
    //get query string url
    getQueryString(pagename = null) {
        if (Config.getServermode() === 'prod') {
            let bookingid = window.location.search !== '' ? window.location.search.split('=')[1].split('-')[1] : this.getCookiesData(Config.cookiestorage['loginInfo'])?.bookingid
            let subdomainname = window.location.search !== '' ? window.location.search.split('=')[1].split('-')[0] : this.getCookiesData(Config.cookiestorage['loginInfo'])?.subdomainname
            let querystring = `?bookingid=${subdomainname}-${bookingid}`
            return querystring
        }
        else {
            if (pagename !== 'home' && !pagename) {

                let urlparams = new URLSearchParams(window.location.search)
                let subdomainname = window.location.search !== '' ? urlparams?.get('bookingid')?.split('-')[0] : this.getCookiesData(Config.cookiestorage['loginInfo'])?.subdomainname
                let bookingid = window.location.search !== '' ? urlparams?.get('bookingid')?.split('-')[1] : this.getCookiesData(Config.cookiestorage['loginInfo'])?.bookingid
                let querystring = `?bookingid=${subdomainname}-${bookingid}`
                return querystring
            }
            return ''
        }
    }

    //get url
    setURL(pagename) {
        if (Config.getServermode() === 'prod') {
            let windowurl = window.location.search !== '' ? window.location.search : this.getCookiesData(Config.cookiestorage['loginInfo']).querystring
            return pagename + windowurl
        }
        else {
            let urlparams = new URLSearchParams(window.location.search)
            let subdomainname = window.location.search !== '' ? urlparams?.get('bookingid')?.split('-')[0] : this.getCookiesData(Config.cookiestorage['loginInfo']).subdomainname
            let bookingid = window.location.search !== '' ? urlparams?.get('bookingid')?.split('-')[1] : this.getCookiesData(Config.cookiestorage['loginInfo']).bookingid
            let querystring = `?bookingid=${subdomainname}-${bookingid}`
            return pagename + querystring
        }
    }

    async getAccessToken(subdomainname, bookingid, querystring, getmenu) {
        const cookies = new Cookies()
        const url = Config.weburl + 'getaccesstoken'
        const reqData = {}
        const headers = {
            key: "Kiosist_98F2B6E0-240A-457B-853A-A0079862315F",
            issuer: "website",
            // key: process.env.REACT_APP_KEY,
            // issuer: process.env.REACT_APP_ISSUER
        }

        await this.axiosrequest("POST", url, reqData, headers, successCallback, errorCallback)

        var resp
        function successCallback(response) {
            resp = response
        }

        function errorCallback(error) {
            resp = error
        }

        if (resp.status === 200) {
            let obj = Object.assign({}, {})
            if (subdomainname && bookingid) {
                obj.subdomainname = subdomainname
                obj.bookingid = bookingid
                obj.querystring = querystring
                obj.token = resp.headers.token
                obj.unqkey = resp.data.data.unqkey
                obj.uid = resp.data.data.uid
                obj.step = 0
                this.setCookiesData(Config.cookiestorage['loginInfo'], obj)
                await this.getReservationDetails(bookingid, querystring)
                if (getmenu) {
                    await this.getMenus()
                }
                await this.getPropertyData()
            }
        }
        else {
            localStorage.clear()
            cookies.remove(Config.cookiestorage['loginInfo'])
        }
    }

    async getReservationDetails(bookingid, querystring) {
        const reqData = {
            paginationinfo: {
                pageno: 1,
                pagelimit: 20,
                filter: {
                    _id: bookingid
                },
                sort: {}
            }
        }
        if (bookingid !== null) {
            const url = Config.weburl + "guestexpreservation"//reservation guestexpreservation
            await setReqParams(url, reqData)
            await this.addData("reservation", "home")
        }

        if (resBody.status === 200) {
            // await store.dispatch(setProps({ reservationDetails: resBody.data[0] }))
            if (this.getCurrentState().reservationDetails.isreservationexpired === 1) {
                window.location = `/linkexpired` + querystring
                // history({ pathname: `/successfullycheckout`, querystring })
            }
        } else {
            localStorage.clear()
        }
    }

    async getMenus() {
        const url = Config.weburl + 'listguestexperiencemenu'
        const useraction = 'viewright'
        const reqBody = {
            propertyid: this.getCurrentState().reservationDetails.propertyid,
            roomnoid: this.getCurrentState().reservationDetails.roomnoid,
            reservationid: this.getCurrentState().reservationDetails._id,
        }
        await setReqParams(url, reqBody)
        await this.listData(useraction)
        if (resBody.status === 200) {
            // await props.setProps({ menuData: resBody.userrights, formName: resBody.formname })
        }
    }

    async getPropertyData() {
        const url = Config.weburl + 'property'
        const useraction = 'listproperty'
        const reqBody = {
            paginationinfo: {
                pageno: 1,
                filter: {
                    _id: this.getCurrentState().reservationDetails.propertyid
                },
                sort: {}
            }
        }
        await setReqParams(url, reqBody)
        await this.listData(useraction)
        if (resBody.status === 200) {
            let cookiedata = this.getCookiesData(Config.cookiestorage['loginInfo'])
            cookiedata.companyid = resBody.data[0].companyid
            this.setCookiesData(Config.cookiestorage['loginInfo'], cookiedata)
            // await props.setProps({ propertyData: resBody.data[0] })
        }
    }

    // get OS Details ~ Bhautik 26-04-2023 05:36 PM
    getOSDetail() {
        let userAgent = window.navigator.userAgent,
            platform = window.navigator.platform,
            macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
            windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
            iosPlatforms = ['iPhone', 'iPad', 'iPod'],
            os = null

        if (macosPlatforms.indexOf(platform) !== -1) os = 'Mac OS'
        else if (iosPlatforms.indexOf(platform) !== -1) os = 'iOS'
        else if (windowsPlatforms.indexOf(platform) !== -1) os = 'Windows'
        else if (/Android/.test(userAgent)) os = 'Android'
        else if (!os && /Linux/.test(platform)) os = 'Linux'

        return os
    }

    dateToFormat(date, format) {
        try {
            date = date.toString()
            const dateobject = new Date(date)
            // if (format === "dd/mm/yyyy" || format === "yyyy/mm/dd" ||) {
            if (format) {
                const dd = String(dateobject.getDate()).padStart(2, '0')
                const mm = String(dateobject.getMonth() + 1).padStart(2, '0')
                const yyyy = dateobject.getFullYear()
                if (isNaN(dd) || isNaN(mm) || isNaN(yyyy)) {
                    date = '-'
                }
                else {
                    // if (format === "yyyy/mm/dd") {
                    date = format.replace('yyyy', yyyy).replace('mm', mm).replace('dd', dd)
                    //  yyyy + '/' + mm + '/' + dd
                    // } else {
                    // date = dd + '/' + mm + '/' + yyyy
                    // }
                }
            }
            else {
                const dd = String(dateobject.getDate()).padStart(2, '0')
                const mm = dateobject.getMonth()
                const yyyy = dateobject.getFullYear()
                if (isNaN(dd) || isNaN(mm) || isNaN(yyyy)) {
                    date = '-'
                }
                else {
                    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
                    date = dd + ' ' + months[mm] + ', ' + yyyy
                }
            }
        }
        catch {
            date = '-'
        }

        return date
    }

    //image resizer function and return base 64
    //(file, maxwidth, maxheight, compressformat, qaulity, rotation, return uri function, outputtype)
    resizeImageFile = async (imgFile, option = {}) => {
        let options = {
            maxSizeMB: 0.7,
            maxWidthOrHeight: option.maxHW || 800,
            useWebWorker: true,
            fileType: 'image/jpg',
            initialQuality: 1,
        }
        let compressedFile = await imageCompression(imgFile, options);
        return imageCompression.getDataUrlFromFile(compressedFile)
    }
    // resizeImageFile = (file) =>
    //     new Promise((resolve) => {
    //         Resizer.imageFileResizer(
    //             file,
    //             300,
    //             300,
    //             "JPEG",
    //             100,
    //             0,
    //             (uri) => {
    //                 resolve(uri);
    //             },
    //             "base64"
    //         );
    //     })

    timeToFormat(time, format) {
        try {
            time = time.toString()
            const timeobject = new Date(time)
            var hh = timeobject.getHours()
            const am_pm = (hh >= 12 ? 'PM' : 'AM')
            hh = (hh > 12 ? hh - 12 : hh)
            hh = String(hh).padStart(2, '0')
            const mm = String(timeobject.getMinutes()).padStart(2, '0')
            const ss = String(timeobject.getSeconds()).padStart(2, '0')
            if (isNaN(hh) || isNaN(mm) || isNaN(ss)) {
                time = '-'
            }
            else {
                time = hh + ":" + mm + " " + am_pm
            }
        }
        catch {
            time = '-'
        }
        return time
    }

    // for generate custom UID
    generateUUID() {
        var d = this.GetDT().getTime();
        var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16;//random number between 0 and 16
            if (d > 0) {//Use timestamp until depleted
                r = (d + r) % 16 | 0;
                d = Math.floor(d / 16);
            } else {//Use microseconds since page-load if supported
                r = (d2 + r) % 16 | 0;
                d2 = Math.floor(d2 / 16);
            }
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }
}



export var IISMethods = new _IISMethods()