import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Config, IISMethods, allowdedImage } from '../config/IISMethods'
import LostController from '../Controller/LostController'
import { setProps } from '../redux/actions'
import { store } from '../index'

const Lost = (props) => {
    useEffect(() => {
        async function fetchMyAPI() {
            await props.setProps({
                rightSidebarFormData: []
            })
        }
        fetchMyAPI()
    }, [])
    return (
        <LostController
            masterjson={masterjson}
            pagename={props.pagename}
        />
    )
}

const getCurrentState = () => {
    return store.getState()['root']
}

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    setProps: (payload) => dispatch(setProps(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Lost)

const masterjson = (key) => {
    let propertyid = getCurrentState().reservationDetails.propertyid
    switch (key) {
        case 'lostjson':
            return [
                {
                    rightsidebarsize: Config.getModalsizeclasses()['sm'],
                    pagename: 'lost',
                    formname: 'lost',
                    apipath: 'lostandfound/add',
                    alias: 'lost',
                    formfields: [
                        {
                            // 0
                            'field': 'assignmentforid',
                            'text': 'Assignment For',
                            'type': Config.getHtmlcontorls()['radio'],
                            'disabled': false,
                            'defaultvisibility': true,
                            'required': false,
                            'cleanable': false,
                            'apipath': 'masters/amenitiescategory',//api path
                            'masterdata': 'assignmentfor',
                            'masterdatafield': 'name',
                            'formdatafield': 'assignmentforid',
                            // 'onchangefill': ['buildingid', 'wingid', 'productsubcategoryid'],
                            'gridsize': Config.getGridclasses()['col-12'],
                            'defaultselected': 0,
                        },

                        {
                            // 1
                            'field': 'buildingid',
                            'text': 'Building',
                            'type': Config.getHtmlcontorls()['dropdown'],
                            'disabled': false,
                            'defaultvisibility': true,
                            'required': false,
                            'cleanable': false,
                            'apipath': 'property/building',//api path
                            'masterdata': 'buildingname',
                            'masterdatafield': 'buildingname',
                            'formdatafield': 'buildingid',
                            'dependentfilter': { 'propertyid': 'propertyid' },
                            'onchangefill': ['wingid'],
                            'onchangedata': ['buildingid'],
                            'gridsize': Config.getGridclasses()['col-12'] + ' ' + Config.getGridclasses()['col-md-6'],
                            'extrafield': ['propertyid', 'propertyname'],
                            'staticfilter': { 'propertyid': propertyid, 'isactive': 1 },
                        },

                        {
                            // 2
                            'field': 'wingid',
                            'text': 'Wing',
                            'type': Config.getHtmlcontorls()['dropdown'],
                            'disabled': false,
                            'defaultvisibility': true,
                            'required': false,
                            'cleanable': false,
                            'apipath': 'property/wing',//api path
                            'masterdata': 'wingname',
                            'masterdatafield': 'wingname',
                            'formdatafield': 'wingid',
                            'dependentfilter': { 'buildingid': 'buildingid' },
                            'gridsize': Config.getGridclasses()['col-12'] + ' ' + Config.getGridclasses()['col-md-6'],
                            'masterdatadependancy': true,
                            'staticfilter': { 'isactive': 1 },
                        },

                        {
                            // 3
                            'field': 'areaid',
                            'text': 'Area',
                            'type': Config.getHtmlcontorls()['text-array'],
                            'disabled': false,
                            'defaultvisibility': true,
                            'required': false,
                            'cleanable': false,
                            'datafield': 'areadata',
                            'masterdatafield': 'area',
                            'formdatafield': 'floorwisearea',
                            'gridsize': Config.getGridclasses()['col-12'],
                        },

                        {
                            // 4
                            'field': 'productsubcategoryid',
                            'text': ' Sub Category',
                            'type': Config.getHtmlcontorls()['dropdown'],
                            'disabled': false,
                            'required': true,
                            'defaultvisibility': true,
                            'gridsize': Config.getGridclasses()['col-12'] + ' ' + Config.getGridclasses()['col-md-12'],
                            'apipath': 'complaint/productsubcategory',
                            'masterdata': 'productsubcategory',
                            'masterdatafield': 'productsubcategory',
                            'formdatafield': 'productsubcategory',
                            'cleanable': false,
                            'searchable': false,
                            'istablefield': 1,
                            'onchangefill': ['productid'],
                            'parantstaticfilter': { 'ismaintainable': 1, 'isrequestitem': 1 }, // for pass filter outside pagination
                            'staticfilter': { 'propertyid': propertyid },
                        },

                        {
                            // 5
                            'field': 'itemlostfound',
                            'text': 'Item',
                            'type': Config.getHtmlcontorls()['input-text'],
                            'disabled': false,
                            'required': true,
                            'defaultvisibility': true,
                            'gridsize': Config.getGridclasses()['col-12'] + ' ' + Config.getGridclasses()['col-10'],
                        },

                        {
                            // 6
                            'field': 'lostcomment',
                            'text': 'Item Description',
                            'type': Config.getHtmlcontorls()['input-textarea'],
                            'disabled': false,
                            'required': false,
                            'defaultvisibility': true,
                            'gridsize': Config.getGridclasses()['col-12'] + ' ' + Config.getGridclasses()['col-10'],
                        },

                        {
                            // 7
                            'field': 'floorwiseareaid',
                            'text': 'Floor',
                            'type': 'tree-label-picker',
                            'disabled': false,
                            'required': true,
                            'defaultvisibility': true,
                            'gridsize': Config.getGridclasses()['col-12'] + ' ' + Config.getGridclasses()['col-md-9'],
                            'apipath': 'task/listarea',
                            'masterdata': 'floorwisearea',
                            'masterdatafield': 'floor',
                            'formdatafield': 'floorwiseareaid',
                            'cleanable': true,
                            'searchable': false,
                            'parantfilter': 1,
                            'masterdatadependancy': true,
                            'dependentfilter': { 'propertyid': 'propertyid', 'buildingid': 'buildingid', 'wingid': 'wingid', 'areatype': 'areatypeid' },
                        },

                        {
                            // 8
                            'field': 'areatypeid',
                            'text': 'Area',
                            'type': 'multipletagpicker',
                            'disabled': false,
                            'required': true,
                            'defaultvisibility': true,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'apipath': 'property/areatype',
                            'masterdata': 'areatype',
                            'masterdatafield': 'name',
                            'formdatafield': ['areatypeid'],
                            'cleanable': true,
                            'defaultselected': 1,
                            'searchable': false,
                            'parantfilter': { 'showall': 1 },
                            'dependentfilter': { 'propertyid': 'propertyid' },
                            'masterdatadependancy': true,
                            'defaultselected': 1,
                            'onchangefill': ['floorwiseareaid'],
                            'staticfilter': {
                                'isactive': 1
                            },
                        },

                        {
                            // 9
                            'field': 'floorwiseroomid',
                            'text': 'Floor',
                            'type': 'tree-tag-picker',
                            'disabled': false,
                            'required': true,
                            'defaultvisibility': true,
                            'gridsize': Config.getGridclasses()['col-12'] + ' ' + Config.getGridclasses()['col-md-9'],
                            'apipath': 'task/listroom',
                            'masterdata': 'floorwiseroom',
                            'masterdatafield': 'floor',
                            'formdatafield': 'floorwiseroomid',
                            'cleanable': true,
                            'searchable': false,
                            'parantfilter': 1,
                            'masterdatadependancy': true,
                            'dependentfilter': { 'propertyid': 'propertyid', 'wingid': 'wingid', 'roomtype': 'roomtypeid' },
                        },

                        {
                            // 10
                            'field': 'roomtypeid',
                            'text': 'Room',
                            'type': 'multipletagpicker',
                            'disabled': false,
                            'required': true,
                            'defaultvisibility': true,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'apipath': 'reservation/roomtype',
                            'masterdata': 'roomtype',
                            'masterdatafield': 'roomtype',
                            'formdatafield': ['roomtypeid'],
                            'cleanable': true,
                            'defaultselected': 1,
                            'searchable': false,
                            'parantfilter': { 'showall': 1 },
                            'dependentfilter': { 'propertyid': 'propertyid' },
                            'masterdatadependancy': true,
                            'defaultselected': 1,
                            'onchangefill': ['floorwiseroomid'],
                            'staticfilter': {
                                'isactive': 1
                            },
                        },
                    ]
                }
            ]

        case 'lostrating':
            return [
                {
                    rightsidebarsize: Config.getModalsizeclasses()['sm'],
                    pagename: 'lostrating',
                    formname: 'lost rating',
                    apipath: 'guestexperience/lostandfoundrating/add',

                    formfields: [
                        //0
                        {
                            'field': 'lostandfoundguestratings',
                            'text': 'stars',
                            'type': Config.getHtmlcontorls()['dropdown'],
                            'disabled': false,
                            'defaultvisibility': true,
                            'cleanable': true,
                            'required': true,
                            'gridsize': Config.getGridclasses()['col-12'] + ' ' + Config.getGridclasses()['col-md-4'],
                            'apipath': 'masters/ratings',
                            'masterdata': 'lostrequestrating',
                            'masterdatafield': 'rating',
                            'formdatafield': 'rating',
                            'istablefield': 1,
                            'staticfilter': {
                                'propertyid': getCurrentState().reservationDetails.propertyid,
                                'ratingcategoryid': Config.ratingcategoryid.lostratings
                            },
                        },

                        //1
                        {
                            'field': 'guestratingsuggestion',
                            'text': 'Suggestion',
                            'type': Config.getHtmlcontorls()['input-textarea'],
                            'disabled': false,
                            'cleanable': true,
                            'defaultvisibility': true,
                            'required': false,
                            'gridsize': Config.getGridclasses()['col-12'],
                        },
                    ]
                }
            ]

        case 'lostfilterjson':
            return [
                {
                    rightsidebarsize: Config.getModalsizeclasses()['sm'],
                    pagename: 'lostfilter',
                    formname: 'lost filter',
                    apipath: 'lostandfound',

                    formfields: [
                        //0
                        {
                            'field': 'statusid',
                            'text': 'Status',
                            'type': Config.getHtmlcontorls()['multipleselectdropdown'],
                            'disabled': false,
                            'defaultvisibility': true,
                            'cleanable': true,
                            'required': false,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'apipath': 'masters/lostandfoundstatus',
                            'masterdata': 'name',
                            'masterdatafield': 'name',
                            'formdatafield': 'name',
                            'selectall': true
                        },
                        //1 JENIL 28/6/2023 6:22 PM
                        {
                            'field': 'fromdate',
                            'text': 'From Date',
                            'type': Config.getHtmlcontorls()['datepicker'],
                            'disabled': false,
                            'cleanable': true,
                            'defaultvisibility': true,
                            'required': false,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'resetfield': ['todate'],
                            'disablefromdate': IISMethods.GetDateTimefromISOString()
                        },
                        //2 JENIL 28/6/2023 6:22 PM
                        {
                            'field': 'todate',
                            'text': 'To Date',
                            'type': Config.getHtmlcontorls()['datepicker'],
                            'disabled': false,
                            'cleanable': true,
                            'defaultvisibility': false,
                            'required': true,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'disablefromfield': 'fromdate'
                        },
                    ]
                }
            ]

        default:
            return []
    }
}