import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { store } from '.'
import Announcement from './assets/images/announcement.svg'
import NotificationBell from './assets/images/notificationbell.svg'
import { IISMethods, socket } from './config/IISMethods'
import { connect } from 'react-redux'
import { setProps } from './redux/actions'
// import { useSocketListener } from './hooks/useSocket'

const Header = (props) => {

    const getCurrentState = () => store.getState()['root']


    // const getCurrentState = () => {
    //     return store.getState()
    // }

    // get chat list and socket called Jenil
    // useEffect(() => {
        // const notificationCountAdded = async (resp) => {
        //     if (getCurrentState()?.reservationDetails?._id === resp?.userid) {
        //         //comment notify count JENIL 05/08/2023 3:46 PM
        //         // let count = parseInt(getCurrentState().notificationCount);
        //         // IISMethods.setLocalData("notificationCount", count + 1)
        //         let index = getCurrentState()?.menuData?.findIndex((a) => a.alias === resp.alias)
        //         if (index >= 0) {
        //             //blink should be start(ishighlight = 1)
        //             let obj = { ...getCurrentState().menuData[index] }
        //             obj.ishighlight = 1
        //             getCurrentState().menuData?.splice(index, 1, obj);
        //             await props.setProps({ menuData: IISMethods.getCopy(getCurrentState().menuData) })
        //         }
        //         // await props.setProps({ notificationCount: count + 1 });
        //     } else {
        //         // IISMethods.setLocalData("notificationCount", 0)
        //         // await props.setProps({ notificationCount: 0 });
        //     }
        // }

        // const menuNotify = async (resp) => {
        //     let menuindex = getCurrentState()?.menuData?.findIndex((a) => a.alias === resp.alias)
        //     if (menuindex >= 0) {
        //         let obj = { ...getCurrentState().menuData[menuindex] }
        //         obj.ishighlight = 1
        //         getCurrentState().menuData?.splice(menuindex, 1, obj);
        //         await props.setProps({ menuData: IISMethods.getCopy(getCurrentState().menuData) })
        //     }
        // }
        
        // // jatin 10-08-2023 on Socket call notification plus 1
        // const notificationCountPlus = async(resp) => {
        //     if(resp.isguestexpnotify === 1){
        //         getCurrentState().reservationDetails["notificationcounts"] = getCurrentState().reservationDetails["notificationcounts"] + 1
        //         await props.setProps({ menuData: IISMethods.getCopy(getCurrentState().menuData) })
        //     }
        // }

        // socket.on("guestexpnotify", menuNotify)
        // //when add msg api called this receiver called for dynamic update notification count for guest chat only
        // socket.on("notifyguest", notificationCountAdded)
        // socket.on("guestexpnotify", notificationCountPlus); // jatin 10-08-2023 notification plus one
        // return () => {
        //     socket.off("guestexpnotify", menuNotify);
        //     socket.off("notifyguest", notificationCountAdded);
        //     socket.off("guestexpnotify", notificationCountPlus);
        // }

    // }, [])

    // get chat list and socket called Jenil
    // const notificationCountAdded = async (resp) => {
    //     if (getCurrentState()?.reservationDetails?._id === resp?.userid) {
    //         //comment notify count JENIL 05/08/2023 3:46 PM
    //         // let count = parseInt(getCurrentState().notificationCount);
    //         // IISMethods.setLocalData("notificationCount", count + 1)
    //         let index = getCurrentState()?.menuData?.findIndex((a) => a.alias === resp.alias)
    //         if (index >= 0) {
    //             //blink should be start(ishighlight = 1)
    //             let obj = { ...getCurrentState().menuData[index] }
    //             obj.ishighlight = 1
    //             getCurrentState().menuData?.splice(index, 1, obj);
    //             await props.setProps({ menuData: IISMethods.getCopy(getCurrentState().menuData) })
    //         }
    //         // await props.setProps({ notificationCount: count + 1 });
    //     } else {
    //         // IISMethods.setLocalData("notificationCount", 0)
    //         // await props.setProps({ notificationCount: 0 });
    //     }
    // }
    
    // const menuNotify = async (resp) => {
    //     debugger
    //     let menuindex = getCurrentState()?.menuData?.findIndex((a) => a.alias === resp.alias)
    //     if (menuindex >= 0) {
    //         let obj = { ...getCurrentState().menuData[menuindex] }
    //         obj.ishighlight = 1
    //         getCurrentState().menuData?.splice(menuindex, 1, obj);
    //         await props.setProps({ menuData: IISMethods.getCopy(getCurrentState().menuData) })
    //     }
    //     if(resp.isguestexpnotify === 1){
    //         getCurrentState().reservationDetails["notificationcounts"] = getCurrentState().reservationDetails["notificationcounts"] + 1
    //         await props.setProps({ reservationDetails: IISMethods.getCopy(getCurrentState().reservationDetails) })
    //     }
    // }
    
    // jatin 10-08-2023 on Socket call notification plus 1
    // const notificationCountPlus = async(resp) => {
    // }

    // useSocketListener("guestexpnotify", menuNotify)
    //when add msg api called this receiver called for dynamic update notification count for guest chat only
    // useSocketListener("notifyguest", notificationCountAdded)
    // useSocketListener("guestexpnotify", notificationCountPlus); // jatin 10-08-2023 notification plus one

    return (
        <>
            <div className='header'>
                <div className='col-12'>
                    <div className='row'>
                        <div className="col my-auto position-relative">
                            <div className='header-top-icon'>
                                <img src={getCurrentState().propertyData.logo}></img>
                            </div>
                        </div>
                        <div className="col my-auto">
                            <div className="header-icon text-right">
                                {/* srushti 21-6- for notification and announcement off after checkout / don't redirect */}
                                <Link className='notify-badge'
                                    to={(getCurrentState().reservationDetails.isguestcheckout === 1) ? '/' : '/announcement'}
                                    onClick={() => { (getCurrentState().reservationDetails.isguestcheckout === 1) ? IISMethods.localnotify("You have already checkout.you can't perform this action.") : IISMethods.setURL('/announcement') }}
                                >
                                    {
                                        // jatin 10-08-2023 Count Show for notification and Announcment
                                        getCurrentState().reservationDetails["announcementcounts"] > 0 &&
                                        <span className="add-badge bg-danger mr-1" >{getCurrentState().reservationDetails["announcementcounts"]}</span>
                                    }
                                    {/* <span className='notify-text'>20+</span> */}
                                    <img src={Announcement}></img>
                                </Link>
                                <Link className='notify-badge'
                                    to={(getCurrentState().reservationDetails.isguestcheckout === 1) ? '/' : '/notification'}
                                    onClick={() => { (getCurrentState().reservationDetails.isguestcheckout === 1) ? IISMethods.localnotify("You have already checkout.you can't perform this action.") : IISMethods.setURL('/notification') }}
                                >
                                    {
                                        getCurrentState().reservationDetails["notificationcounts"] > 0 &&
                                        <span className="add-badge bg-danger" >{getCurrentState().reservationDetails["notificationcounts"]}</span>
                                        // <span className='notify-text'>{getCurrentState().notificationCount}</span>
                                    }
                                    <img src={NotificationBell}></img>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    setProps: (payload) => dispatch(setProps(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)