import React from 'react'
import { Config, IISMethods } from '../config/IISMethods'
import { connect } from 'react-redux'
import 'rsuite/dist/rsuite.css'
import ModalRsuite from './Components/ModalRsuite'
import { setProps } from '../redux/actions'
import { store } from '../index'
import Edit from '../assets/images/add.svg'
import Withdraw1 from '../assets/images/withdraw1.svg'
import Reopen from '../assets/images/reopen.svg'
import { Placeholder } from 'rsuite'
import NoDataImage from '../assets/images/no-data-img.png'
import Loader from './Components/Loader'
import Filter from '../assets/images/filter.svg'

const getCurrentState = () => {
    return store.getState()['root']
}

const star_fill = <svg className='star' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 64 61.335">
    <path id="star_13_" data-name="star (13)" d="M63.833,23.669A3.4,3.4,0,0,0,60.9,21.33L42.433,19.653l-7.3-17.1a3.4,3.4,0,0,0-6.256,0l-7.3,17.094L3.093,21.33a3.406,3.406,0,0,0-1.931,5.953L15.125,39.528,11.008,57.666a3.4,3.4,0,0,0,5.059,3.675L32,51.817l15.928,9.524a3.4,3.4,0,0,0,5.062-3.675L48.873,39.528,62.836,27.285a3.4,3.4,0,0,0,1-3.616Zm0,0" transform="translate(0 -0.491)" fill="#ffc107" />
</svg>
const star_half_fill = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" className="bi bi-star-half" viewBox="0 0 16 16">
    <path d="M5.354 5.119 7.538.792A.516.516 0 0 1 8 .5c.183 0 .366.097.465.292l2.184 4.327 4.898.696A.537.537 0 0 1 16 6.32a.548.548 0 0 1-.17.445l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256a.52.52 0 0 1-.146.05c-.342.06-.668-.254-.6-.642l.83-4.73L.173 6.765a.55.55 0 0 1-.172-.403.58.58 0 0 1 .085-.302.513.513 0 0 1 .37-.245l4.898-.696zM8 12.027a.5.5 0 0 1 .232.056l3.686 1.894-.694-3.957a.565.565 0 0 1 .162-.505l2.907-2.77-4.052-.576a.525.525 0 0 1-.393-.288L8.001 2.223 8 2.226v9.8z" fill="#ffc107" />
</svg>
const star_unfill = <svg className='star' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 64 61.335">
    <path id="star_14_" data-name="star (14)" d="M14.325,61.827a3.407,3.407,0,0,1-3.315-4.16l4.117-18.137L1.165,27.284a3.4,3.4,0,0,1,1.928-5.953l18.475-1.677,7.3-17.094a3.4,3.4,0,0,1,6.256,0l7.3,17.1L60.9,21.331a3.405,3.405,0,0,1,1.934,5.953L48.875,39.527l4.117,18.137a3.4,3.4,0,0,1-5.061,3.675L32,51.818,16.069,61.344a3.409,3.409,0,0,1-1.744.483ZM32,47.717a3.41,3.41,0,0,1,1.744.482L48.78,57.192,44.894,40.074a3.4,3.4,0,0,1,1.078-3.315L59.156,25.2,41.712,23.612a3.4,3.4,0,0,1-2.827-2.062L32,5.418,25.107,21.552a3.388,3.388,0,0,1-2.818,2.054L4.842,25.19,18.026,36.753A3.4,3.4,0,0,1,19.1,40.071L15.221,57.189,30.256,48.2A3.408,3.408,0,0,1,32,47.717ZM21.427,19.984a.008.008,0,0,1,0,.005Zm21.142-.008,0,.005S42.571,19.979,42.568,19.976Zm0,0" transform="translate(0 -0.492)" fill="#ffe188" />
</svg>

const filterBatch = () => {
    let filterData = IISMethods.getCopy(getCurrentState().oldFilterData)
    let activeFilter = Object.keys(filterData).map(field => {
        if ((['string', 'number'].includes(typeof filterData[field]) && filterData[field]) || (typeof filterData[field] === 'object' && Object.values(filterData[field])?.length > 0)) return 1
        return 0
    })
    return activeFilter.find(status => status === 1)
}


const getColor = (colorCode, opacity) => {
    let r = colorCode?.r || 0
    let g = colorCode?.g || 0
    let b = colorCode?.b || 0
    let a = opacity ? opacity : colorCode?.a
    return `rgba(${r}, ${g}, ${b}, ${a})`
}

const MaintenanceList = (props) => {
    try {
        return (
            <>
                <div className="body-content" onScroll={props.handleScroll}>
                    <div className="col-12 my-3">
                        {
                            (getCurrentState().formName === '')
                                ?
                                <>
                                    <Placeholder.Graph active></Placeholder.Graph>
                                    <Placeholder.Graph active></Placeholder.Graph>
                                    <Placeholder.Graph active></Placeholder.Graph>
                                </>
                                :
                                ((getCurrentState().formName?.length > 0) && (getCurrentState().data?.length === 0)) ?
                                    <div className='no-data-found'>
                                        <div className='noData-content'>
                                            <img src={NoDataImage} />
                                            <h2 className="text-center text-16 mb-0 font-weight-600">No maintenance service requested</h2>
                                        </div>
                                    </div>
                                    :
                                    getCurrentState().data?.map((requestdata, index) => {
                                        return (

                                            <div className="request-box my-3">
                                                <div className="request-create d-flex align-items-baseline gap-10" key={index}>
                                                    <span className='d-inline-block'>
                                                        <img src={requestdata.taskstatussvg} />
                                                    </span>
                                                    <span className='w-100'>
                                                        <span className='d-flex justify-content-between align-items-center'>
                                                            <span>
                                                                <span className='d-block'>{requestdata.taskstatus}</span>
                                                                <p className='d-block'>{IISMethods.GetDateFormat(requestdata.startdate)} | {IISMethods.GetTimeFormat(requestdata.startdate)}</p>
                                                            </span>
                                                            <label
                                                                className='list-label '
                                                                style={{
                                                                    // color: getColor(requestdata.prioritycolor, 1),
                                                                    color: "white",
                                                                    backgroundColor: getColor(requestdata.prioritycolor)
                                                                }}
                                                            >{requestdata.priority}</label>
                                                        </span>
                                                    </span>
                                                </div>
                                                <div className="request-card-body">
                                                    {requestdata.images[0]?.image.length > 0 &&
                                                        <div className="request-card-body-img" onClick={() => props.handleGrid(requestdata._id, 'modal', 'multipalimagemodal', 1)}>
                                                            {
                                                                ['png', 'jpeg', 'gif', 'svg', 'jpg', 'svg', 'webp', 'svg+xml'].includes(requestdata.images[0]?.image.split(/[#?]/)[0].split('.').pop().trim())
                                                                    ?
                                                                    <figure>
                                                                        <img src={requestdata.images[0]?.image} />
                                                                    </figure>
                                                                    :
                                                                    <div className="play-icon-content">
                                                                        <span class="material-icons-outlined play-icon">play_circle</span>
                                                                    </div>
                                                            }
                                                        </div>
                                                    }

                                                    <div className="mainten-desc">
                                                        <span className="mainten-req-sentence">
                                                            {
                                                                requestdata.productsubcategoryid === Config.othersubcategory
                                                                    ?
                                                                    'Other Product'
                                                                    :
                                                                    requestdata.product
                                                            }
                                                        </span>
                                                        <div className="mainten-req-sentence-desc">
                                                            {requestdata.comment}
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    (requestdata.taskguestrating?.length > 0)
                                                    &&
                                                    <div className='d-block'>
                                                        <div className='d-flex m-2'>{/* guestratingsavg */}
                                                            {
                                                                [...Array(5)].map((star, index) => {
                                                                    let ratingtype = Number.isInteger(requestdata.guestratingsavg)
                                                                    let filstar
                                                                    let halffillstar
                                                                    if (!ratingtype) {
                                                                        filstar = requestdata.guestratingsavg.toString().split('.')[0]
                                                                        halffillstar = requestdata.guestratingsavg.toString().split('.')[1]
                                                                    }
                                                                    else {
                                                                        filstar = requestdata.guestratingsavg
                                                                    }
                                                                    if (filstar - 1 >= index) {
                                                                        return <div className=' mx-1'>
                                                                            {star_fill}
                                                                        </div>
                                                                    }
                                                                    else if (halffillstar && parseInt(filstar) === index) {
                                                                        return <div className=' mx-1'>
                                                                            {star_half_fill}
                                                                        </div>
                                                                    }
                                                                    else {
                                                                        return <div className=' mx-1'>
                                                                            {star_unfill}
                                                                        </div>
                                                                    }
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                                <div className="with-req">
                                                    {requestdata.iscompleted === 0
                                                        &&
                                                        <button className="withdraw" onClick={() => { props.setFormData(requestdata._id, '', 'withdrawmodal'); }} >
                                                            <span>WITHDRAW</span>
                                                        </button>
                                                    }
                                                    {requestdata.iscompleted === 1
                                                        &&
                                                        <button className="reopen"
                                                            onClick={() => { props.setFormData(requestdata._id, '', 'reopenmodal'); }}>
                                                            <span>REOPEN</span>
                                                        </button>
                                                    }
                                                    {(requestdata.iscompleted === 1 && requestdata.isrequestwithdraw === 0  && getCurrentState().masterDataList['maintenrating']?.length > 0 && requestdata.taskguestrating.length === 0) &&
                                                        <div className="withdraw" onClick={() => { props.setFormData(requestdata._id, 'maintenancerating', 'maintenanceratingmodal'); }}>
                                                            <span>Rate the service</span>
                                                        </div>
                                                    }
                                                    <button className="track" onClick={() => { props.setSecondaryFormData(requestdata._id); props.setPage("maintendemo"); }}>
                                                        <span>TRACK</span>
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    })
                        }
                    </div>
                </div>

                {/* Bhautik 04-07-2023 11:41 AM ~ for filter */}
                <div className="right-bottom-icons">
                    {/* <div className='filter-icons' onClick={() => getCurrentState().formName && props.setFilterData('maintenancefilterjson', 'maintenancefiltermodal')}>
                        <i className="material-icons-round text-26 text-white m-auto">tune</i>
                        {filterBatch() && <div className='filterdot'></div>}
                    </div> */}
                    <div className='filter-icons' onClick={() => getCurrentState().formName && props.setFormData(null, 'maintenancejson', 'maintenancemodal')}>
                        <i className="material-icons-round text-30 text-white m-auto">add</i>
                    </div>
                </div>

                {/* withdraw modal 3-4-23 */}
                {
                    getCurrentState().modal['withdrawmodal']
                    &&
                    <ModalRsuite
                        size={"xs"}
                        open={getCurrentState().modal['withdrawmodal']}
                        onClose={() => props.handleGrid(false, 'modal', 'withdrawmodal', 0)}

                        body={
                            <>
                                <div className="col-12 text-center">
                                    <img src={Withdraw1} /><br />
                                </div>
                                <div className="col-12 mt-3">
                                    <span className="text-center d-block">Are you sure you want to Withdraw ?</span>
                                </div>
                                <div className='col-12 mt-4'>
                                    <div className='row justify-content-center'>
                                        <div className='col-6 pr-2'>
                                            <button type="submit" className="btn-yes-no btn-secondary-light" onClick={() => { props.handleGrid(false, 'modal', 'withdrawmodal', 0) }}>No</button>
                                        </div>
                                        <div className='col-6 pl-2'>
                                            <button type="submit" className="btn-yes-no btn-primary-light" onClick={(e) => { getCurrentState().loading === 0 && props.handleAddButtonClick(e, 'withdrawmodal') }}><Loader content={'Yes'} /></button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    />
                }
                {/* reopen modal 3-4-23 */}
                {
                    getCurrentState().modal['reopenmodal']
                    &&
                    <ModalRsuite
                        size={"xs"}
                        open={getCurrentState().modal['reopenmodal']}
                        onClose={() => props.handleGrid(false, 'modal', 'reopenmodal', 0)}

                        body={
                            <>
                                <div className="col-12 text-center">
                                    <img src={Reopen} /><br />
                                </div>
                                <div className="col-12 mt-3 text-center">
                                    <span className="text-center">Are You Sure you want to Reopen ?</span>
                                </div>
                                <div className='col-12 mt-4'>
                                    <div className='row justify-content-center'>
                                        <div className='col-6 pr-2'>
                                            <button type="submit" className="btn-yes-no btn-secondary-light" onClick={() => { props.handleGrid(false, 'modal', 'reopenmodal', 0) }}>No</button>
                                        </div>
                                        <div className='col-6 pl-2'>
                                            <button type="submit" className="btn-yes-no btn-primary-light" onClick={(e) => { getCurrentState().loading === 0 && props.handleAddButtonClick(e, 'reopenmodal') }}><Loader content={'Yes'} /></button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    />
                }

            </>
        )

    }
    catch (error) {
    }
}

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    setProps: (payload) => dispatch(setProps(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceList)
