import React from 'react'
import { Tooltip, Whisper } from 'rsuite'

const TooltipRsuite = (props) => {
    return (
        <>
            <Whisper 
                placement={props.placement || 'auto'}
                controlId="control-id-hover"
                trigger={props.trigger || "hover"}
                speaker={<Tooltip>{props.tooltip}</Tooltip>}
            >
                {props.content}
            </Whisper>
        </>
    )
}
export default TooltipRsuite