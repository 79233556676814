import React, { useState } from 'react'
import { store } from '..'
import { connect } from 'react-redux'
import { setProps } from '../redux/actions'
import ModalRsuite from './Components/ModalRsuite'
import BackHeader from '../BackHeader'
import Footer from '../Footer'
import { Placeholder } from 'rsuite'
import NoDataImage from '../assets/images/no-data-img.png'
import promotionImg from '../assets/images/promotionImg.png'

const PromotionView = (props) => {

    const getCurrentState = () => store.getState()['root']
    const [modalData, setModalData] = useState({})

    const handleDescriptionModal = async (imgmodal, titleorurl, description) => {
        if (imgmodal) {
            await setModalData({
                url: titleorurl,
                title: description
            })
            await props.handleGrid(true, 'modal', 'promotionImgModal', 1)
        }
        else {
            await setModalData({
                title: titleorurl,
                desc: description
            })
            await props.handleGrid(true, 'modal', 'promotionDescModal', 1)
        }
    }

    return (
        <>
            {/* back history not static link JENIL 13/9/2023 10:47 PM */}
            <BackHeader isHistoryBack={true} linkto='/mystay' backtopagename='Promotions' handleFilterData={props.handleFilterData} handleApplyFilterButtonClick={props.handleApplyFilterButtonClick} handleSerachClose={props.handleSerachClose} />
            <div className="body-content" onScroll={props.handleScroll}>
                <div className="col-12 my-3">
                    {
                        (getCurrentState().formName === '')
                            ?
                            <>
                                <Placeholder.Graph active></Placeholder.Graph>
                                <Placeholder.Graph active></Placeholder.Graph>
                                <Placeholder.Graph active></Placeholder.Graph>
                            </>
                            :
                            ((getCurrentState().formName?.length > 0) && (getCurrentState().data?.length === 0)) ?
                                <div className='no-data-found'>
                                    <div className='noData-content'>
                                        <img src={NoDataImage} />
                                        <h2 className="text-center text-16 mb-0 font-weight-600">Currently no promotions available</h2>
                                    </div>
                                </div>
                                :
                                getCurrentState().data?.map((data, index) => {
                                    return (
                                        <div className='loyalty-sec-slider' key={index}>
                                            <figure className='cursor-pointer'>
                                                <img src={data.photo || promotionImg} onClick={() => handleDescriptionModal(true, data.photo || promotionImg, data.promotiontitle)} />
                                            </figure>
                                            <div className='loyalty-details'>
                                                <h4>{data.promotiontitle}</h4>
                                                <div className='loyalty-desc-block'>
                                                    <span className='loyalty-desc' id={'loyalty-desc' + index}>
                                                        <span>{data.promotiondesc}</span>
                                                    </span>
                                                    {
                                                        document.getElementById(`loyalty-desc${index}`)?.childNodes[0]?.offsetHeight > 50
                                                        &&
                                                        <span className='text-primary text-12 loyalty-read-btn cursor-pointer' onClick={() => handleDescriptionModal(false, data.promotiontitle, data.promotiondesc)}>...Read More</span>
                                                    }
                                                </div>
                                                <div className='mt-2 loyalty-top-btn'>
                                                    <a href={data.redirectlink} target='_blank' className='btn btn-xs loyalty-btn'>{data.buttonname}</a>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                    }
                </div>
            </div>

            <Footer />

            {/* charmi(07-08-2023 03:33) */}
            <ModalRsuite
                size={"sm"}
                open={getCurrentState().modal['promotionDescModal']}
                onClose={(e) => { props.handleGrid(false, 'modal', 'promotionDescModal', 0); }}
                title={
                    <div className="model-header icon">
                        <span className="closeicon ellipsis-header">
                            {modalData.title}
                        </span>
                    </div>
                }
                body={
                    <div className='col-12'>
                        <div className='card-content'>
                            {
                                modalData.desc
                                    ?
                                    <div className='white-space-pre' dangerouslySetInnerHTML={{ __html: modalData.desc }}></div>
                                    :
                                    <div className="right-sidebar-content">
                                        <div className="no-data-imgs-modal">
                                            <img src={NoDataImage} className='no-data-imgs' />
                                            <h2 className="text-center text-16 mb-0 font-weight-600">No information found</h2>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                }
            />
            {/* charmi(07-08-2023 03:33) */}

            {/* charmi(07-08-2023 03:33) */}
            <ModalRsuite
                size={"sm"}
                open={getCurrentState().modal['promotionImgModal']}
                onClose={(e) => { props.handleGrid(false, 'modal', 'promotionImgModal', 0); }}
                title={
                    <div className="model-header icon">
                        <span className="closeicon ellipsis-header">
                            {modalData.title}
                        </span>
                    </div>
                }
                body={
                    <>
                        <div className="col-12">
                            {
                                modalData.url
                                    ?
                                    <div className="single-image">
                                        <img className='' src={modalData.url} />
                                    </div>
                                    :
                                    <div className="right-sidebar-content">
                                        <div className="no-data-imgs-modal">
                                            <img src={NoDataImage} className='no-data-imgs' />
                                            <h2 className="text-center text-16 mb-0 font-weight-600">No information found</h2>
                                        </div>
                                    </div>
                            }
                        </div>
                    </>
                }
            />
            {/* charmi(07-08-2023 03:33) */}
        </>
    )
}

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    setProps: (payload) => dispatch(setProps(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(PromotionView)