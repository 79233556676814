import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { IISMethods, reqLoading } from '../../config/IISMethods'

const Loader = (props) => {
    const size = props.size || 'sm'
    const store = useSelector(state => state)


    // console.log('reqLoading001 ---><==>', IISMethods.reqLoadings)
    // useEffect(() => {
    //     console.log('reqLoading002 ---><==>', IISMethods.reqLoadings)
    // }, [IISMethods.reqLoadings])

    return (
        <div className="d-flex justify-content-center">
            {store.loading === 1 && <div className={`btn-loader  ${size} mr-2 mt-1`}></div>}
            <span>{props.content || ''}</span>
        </div>
    )
}

export default Loader