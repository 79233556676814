import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Config, IISMethods, allowdedImage } from '../config/IISMethods'
import MaintenanceController from '../Controller/MaintenanceController'
import { setProps } from '../redux/actions'
import { store } from '../index'

const Maintenance = (props) => {
    useEffect(() => {
        async function fetchMyAPI() {
            await props.setProps({
                rightSidebarFormData: []
            })
        }
        fetchMyAPI()
    }, [])
    return (
        <MaintenanceController
            masterjson={masterjson}
            pagename={props.pagename}
        />
    )
}

const getCurrentState = () => {
    return store.getState()['root']
}

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    setProps: (payload) => dispatch(setProps(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Maintenance)


const masterjson = (key) => {
    let propertyid = getCurrentState().reservationDetails.propertyid
    let roomnoid = getCurrentState().reservationDetails.roomnoid
    switch (key) {
        case 'maintenancejson':
            return [
                {
                    rightsidebarsize: Config.getModalsizeclasses()['sm'],
                    pagename: 'maintenance',
                    formname: 'maintenance',
                    apipath: 'task/add',
                    alias: 'maintenance',
                    formfields: [
                        //0
                        {
                            'field': 'productsubcategoryid',
                            'text': 'Sub Category',
                            'type': Config.getHtmlcontorls()['dropdown'],
                            'disabled': false,
                            'required': true,
                            'defaultvisibility': false,
                            'gridsize': Config.getGridclasses()['col-12'] + ' ' + Config.getGridclasses()['col-md-12'],
                            'apipath': 'product/productsubcategory',
                            'masterdata': 'productsubcategory',
                            'masterdatafield': 'productsubcategory',
                            'formdatafield': 'productsubcategory',
                            'cleanable': false,
                            'searchable': false,
                            'onchangefill': ['productid'],
                            'resetfield': ['productdata', 'comment', 'productid'],
                            'parantstaticfilter': { 'ismaintainable': 1,  'roomnoid': [roomnoid], 'getotheropt': 1 }, // for pass filter outside pagination
                            'staticfilter': { 'isactive': 1, 'propertyid': propertyid },
                            'istablefield': true,
                        },
                        //1
                        {
                            'field': 'productid',
                            'text': 'Item',
                            'type': Config.getHtmlcontorls()['dropdown'],
                            'disabled': false,
                            'defaultvisibility': true,
                            'required': true,
                            'cleanable': false,
                            'apipath': 'property/amenitiesserialnumber',
                            'masterdata': 'product',
                            'masterdatafield': 'productname',
                            'formdatafield': 'product',
                            'gridsize': Config.getGridclasses()['col-12'] + ' ' + Config.getGridclasses()['col-md-12'],
                            'staticfilter': {
                                'roomnoid': [roomnoid],
                            },
                            'parantstaticfilter': {
                                'ismaintainable': 1,//charmi(27-07-2023 10:45)(PARAS)
                                // 'isrequestitem': 0,//charmi(27-07-2023 10:45)(PARAS)
                                'getotheropt': 1,//charmi(09-08-2023 02:08)(HV)
                            },
                            // 'dependentfilter': { 'productsubcategoryid': 'productsubcategoryid' },
                            'istablefield': true,
                            // 'masterdatadependancy': true,
                            // 'condition': {
                            //     'field': 'productsubcategoryid',
                            //     'onvalue': Config.othersubcategory
                            // },
                            'defaultvalue': '',
                            'groupwise': true,//charmi(08-08-2023 12:06)
                            'groupfield': 'productsubcategory',//charmi(08-08-2023 12:06)
                            'extrafield': ['arearoomno', 'arearoomnoid', 'displayname', 'productcategory', 'productcategoryid', 'productsubcategoryid', 'productsubcategory']
                        },
                        // 2
                        {
                            'field': 'comment',
                            'text': 'Description',
                            'type': Config.getHtmlcontorls()['input-textarea'],
                            'disabled': false,
                            'required': false,
                            'cleanable': true,
                            'defaultvisibility': true,
                            'gridsize': Config.getGridclasses()['col-md-12'],
                        },
                        // 3
                        {
                            'field': 'priorityid',
                            'text': 'Priority',
                            'type': Config.getHtmlcontorls()['radio'],
                            // 'disabled': false,
                            'defaultvisibility': false,
                            'required': false,
                            'cleanable': false,
                            'apipath': 'masters/listpriority',//api path
                            'masterdata': 'priority',
                            'masterdatafield': 'name',
                            'formdatafield': 'priorityid',
                            'gridsize': Config.getGridclasses()['col-12'],
                            'defaultselected': 1,
                        },
                        // 4
                        {
                            'field': 'images',
                            'text': 'Add Photos',
                            'type': Config.getHtmlcontorls()['image'],
                            'disabled': false,
                            'defaultvisibility': true,
                            'required': false,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'filetypes': allowdedImage,
                            'multiple': true
                        },
                    ]
                }
            ]

        case 'maintenancerating':
            return [
                {
                    rightsidebarsize: Config.getModalsizeclasses()['sm'],
                    pagename: 'maintenancerating',
                    formname: 'maintenance rating',
                    apipath: 'guestexperience/taskguestrating/add',

                    formfields: [
                        //0
                        {
                            'field': 'taskguestrating',
                            'text': 'stars',
                            'type': Config.getHtmlcontorls()['dropdown'],
                            'disabled': false,
                            'defaultvisibility': true,
                            'cleanable': true,
                            'required': true,
                            'gridsize': Config.getGridclasses()['col-12'] + ' ' + Config.getGridclasses()['col-md-4'],
                            'apipath': 'masters/ratings',
                            'masterdata': 'maintenrating',
                            'masterdatafield': 'rating',
                            'formdatafield': 'rating',
                            'istablefield': 1,
                            'staticfilter': {
                                'propertyid': getCurrentState().reservationDetails.propertyid,
                                'ratingcategoryid': Config.ratingcategoryid.maintenanceratings
                            },
                        },
                        //1
                        {
                            'field': 'suggestion',
                            'text': 'Suggestion',
                            'type': Config.getHtmlcontorls()['input-textarea'],
                            'disabled': false,
                            'cleanable': true,
                            'defaultvisibility': true,
                            'required': false,
                            'gridsize': Config.getGridclasses()['col-12'],
                        },

                    ]
                }
            ]

        case 'maintenancefilterjson':
            return [
                {
                    rightsidebarsize: Config.getModalsizeclasses()['sm'],
                    pagename: 'maintenancefilter',
                    formname: 'maintenance filter',
                    apipath: 'tasklistforweb',

                    formfields: [
                        //0
                        {
                            'field': 'taskstatustype',
                            'text': 'Status',
                            'type': Config.getHtmlcontorls()['multipleselectdropdown'],
                            'disabled': false,
                            'defaultvisibility': true,
                            'cleanable': true,
                            'required': false,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'apipath': 'masters/taskstatus',
                            'masterdata': 'status',
                            'masterdatafield': 'status',
                            'formdatafield': 'taskstatustype',
                            'selectall': true,
                            'staticfilter': { 'taskcategoryid': Config.categoryid.maintenance }
                        },
                        {
                            'field': 'priorityid',
                            'text': 'Priority',
                            'type': Config.getHtmlcontorls()['multipleselectdropdown'],
                            // 'disabled': false,
                            'defaultvisibility': true,
                            'required': false,
                            'cleanable': true,
                            'apipath': 'masters/listpriority',//api path
                            'masterdata': 'priority',
                            'masterdatafield': 'name',
                            'formdatafield': 'priorityid',
                            'gridsize': Config.getGridclasses()['col-12'],
                            'defaultselected': 1,
                            'selectall': true,
                        },
                        //2 JENIL 28/6/2023 6:22 PM
                        {
                            'field': 'fromdate',
                            'text': 'From Date',
                            'type': Config.getHtmlcontorls()['datepicker'],
                            'disabled': false,
                            'cleanable': true,
                            'defaultvisibility': true,
                            'required': false,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'resetfield': ['todate'],
                            'disablefromdate': IISMethods.GetDateTimefromISOString()
                        },
                        //3 JENIL 28/6/2023 6:22 PM
                        {
                            'field': 'todate',
                            'text': 'To Date',
                            'type': Config.getHtmlcontorls()['datepicker'],
                            'disabled': false,
                            'cleanable': true,
                            'defaultvisibility': false,
                            'required': true,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'disablefromfield': 'fromdate'
                        },
                    ]
                }
            ]

        default:
            return []
    }
}