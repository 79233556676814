import React, { useEffect, useState } from 'react'
import { store } from '..'
import { Config, IISMethods } from '../config/IISMethods'
import ModalRsuite from './Components/ModalRsuite'
import Capture from '../assets/images/capture.svg'
import { connect, useSelector } from 'react-redux'
import { Carousel } from 'react-bootstrap'
import SelectPickerRsuite from './Components/SelectPickerRsuite'
import Rating from '../assets/images/rating.svg'
// import Loader from './Components/Loader';
import DateRangePickerRsuite from './Components/DateRangePickerRsuite'
import MultipleSelectDropdown from './Components/MultipleSelectDropdown'
import DatePickerRsuite from './Components/DatePickerRsuite'
import { isBefore } from "date-fns"
import TooltipRsuite from './Components/TooltipRsuite'
import noimgfound from "../assets/images/no-product.png"
import { Loader } from 'rsuite';


export default function MaintenanceRightsidebar(props) {

    //modal step of maintenance modal JENIL 23/8/2023 10:35 PM
    const [modalStep, setModalStep] = useState(1)
    //search product subcategory and products JENIL 23/8/2023 10:34 PM
    const [search, setSearch] = useState("")

    //selected item of maintenance JENIL 23/8/2023 10:41 AM
    const [selectedProduct, setSelectedProduct] = useState({})
    const [issueModal, setIssueModal] = useState(false);

    const getCurrentState = () => {
        return store.getState()['root']
    }
    const getColor = () => {
        let color = IISMethods.getobjectfromarray(getCurrentState().masterDataList['priority'], '_id', getCurrentState().formData.priorityid).color
        let r = color.r || 0
        let g = color.g || 0
        let b = color.b || 0
        let a = color.a || 1
        return `rgba(${r}, ${g}, ${b}, ${a})`
    }

    const form = 'form-'
    const star_fill = <svg className='star star-curser' xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 64 61.335">
        <path id="star_13_" data-name="star (13)" d="M63.833,23.669A3.4,3.4,0,0,0,60.9,21.33L42.433,19.653l-7.3-17.1a3.4,3.4,0,0,0-6.256,0l-7.3,17.094L3.093,21.33a3.406,3.406,0,0,0-1.931,5.953L15.125,39.528,11.008,57.666a3.4,3.4,0,0,0,5.059,3.675L32,51.817l15.928,9.524a3.4,3.4,0,0,0,5.062-3.675L48.873,39.528,62.836,27.285a3.4,3.4,0,0,0,1-3.616Zm0,0" transform="translate(0 -0.491)" fill="#ffc107" />
    </svg>
    const star_unfill = <svg className='star star-curser' xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 64 61.335">
        <path id="star_14_" data-name="star (14)" d="M14.325,61.827a3.407,3.407,0,0,1-3.315-4.16l4.117-18.137L1.165,27.284a3.4,3.4,0,0,1,1.928-5.953l18.475-1.677,7.3-17.094a3.4,3.4,0,0,1,6.256,0l7.3,17.1L60.9,21.331a3.405,3.405,0,0,1,1.934,5.953L48.875,39.527l4.117,18.137a3.4,3.4,0,0,1-5.061,3.675L32,51.818,16.069,61.344a3.409,3.409,0,0,1-1.744.483ZM32,47.717a3.41,3.41,0,0,1,1.744.482L48.78,57.192,44.894,40.074a3.4,3.4,0,0,1,1.078-3.315L59.156,25.2,41.712,23.612a3.4,3.4,0,0,1-2.827-2.062L32,5.418,25.107,21.552a3.388,3.388,0,0,1-2.818,2.054L4.842,25.19,18.026,36.753A3.4,3.4,0,0,1,19.1,40.071L15.221,57.189,30.256,48.2A3.408,3.408,0,0,1,32,47.717ZM21.427,19.984a.008.008,0,0,1,0,.005Zm21.142-.008,0,.005S42.571,19.979,42.568,19.976Zm0,0" transform="translate(0 -0.492)" fill="#ffe188" />
    </svg>

    try {
        const checkinData = useSelector(state => state)
        const renderHtmlControls = (formfield) => {
            if (formfield && (formfield.defaultvisibility || getCurrentState().formData[formfield?.condition?.field] !== formfield?.condition?.onvalue)) {
                if (formfield.type === 'dropdown') { // for select item dropdown

                    if (formfield.field === 'productid') {
                        return (
                            <div className={formfield.gridsize} >
                                <div className="form-group validate-input">
                                    <label className="guest-request-maintenance-lable">{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}</label>
                                    {/* <SelectPickerRsuite
                                        cleanable={formfield.cleanable}
                                        // searchable={formfield.searchable}
                                        placeholder={`Select ${formfield.text}`}
                                        className="form-control has-val p-0"
                                        data={getCurrentState().masterData[formfield.masterdata]}
                                        id={"form-" + formfield.field}
                                        name={formfield.field}
                                        onChange={(e) => props.handleFormData(formfield.type, formfield.field, e)}
                                        value={value || ''}
                                        disabled={formfield.disabled}
                                        groupBy={"role"}
                                    /> */}
                                    {
                                        //add item structure JENIL 23/8/2023 1:14 PM
                                        getCurrentState().formData["productdata"]?.length > 0 &&
                                        <div className='product-list'>
                                            {
                                                getCurrentState().formData["productdata"].map((product, index) => {
                                                    let image = getCurrentState().masterDataList["product"]?.find(a => a.productid === product.productid)?.image
                                                    return (
                                                        <div className='single-product d-flex gap-10 align-items-start' key={product.productid + index.toString()}>
                                                            <div className='w-25 d-block'>
                                                                <div className='single-product-image pr-0'>
                                                                    <img src={image ? image : noimgfound} alt="product" />
                                                                </div>
                                                            </div>
                                                            <div className='w-75 single-product-content'>
                                                                <>
                                                                    <div className='single-product-description'>
                                                                        <span className='text-12 text-dark font-weight-600 d-block'>{product.product}</span>
                                                                        {/* <TooltipRsuite
                                                                            placement={'top'}
                                                                            trigger="click"
                                                                            tooltip={product.product}
                                                                            content={
                                                                                <span className="material-icons d-block product-info text-14 text-dark">info</span>
                                                                            } /> */}
                                                                    </div>
                                                                    {
                                                                        product?.issue?.length > 0 &&
                                                                        <div className='single-product-issue d-flex gap-5 flex-wrap mt-2'>
                                                                            {
                                                                                product?.issue?.map((issue, i) => {
                                                                                    return (
                                                                                        <span key={issue._id + i.toString()} className='product-issue-tags'>{issue.issue}</span>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    }
                                                                </>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                    <div className="col-12 form-group col mt-2 px-0">
                                        <button onClick={(e) => { setModalStep(2) }} className="guest-request-add-item">
                                            Select Item
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    else {
                        return (
                            <div className={formfield.gridsize} >
                                <div className="form-group validate-input">
                                    <label className="guest-request-maintenance-lable">{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}</label>
                                    <SelectPickerRsuite
                                        cleanable={formfield.cleanable}
                                        // searchable={formfield.searchable}
                                        placeholder={`Select ${formfield.text}`}
                                        className="form-control has-val p-0"
                                        data={getCurrentState().masterData[formfield.masterdata]}
                                        id={"form-" + formfield.field}
                                        name={formfield.field}
                                        onChange={(e) => props.handleFormData(formfield.type, formfield.field, e)}
                                        value={getCurrentState().formData[formfield.field]}
                                        disabled={formfield.disabled}
                                    />
                                </div>
                            </div>
                        )
                    }
                }
                else if (formfield.type === 'input-textarea') // for textarea
                {
                    return (
                        <div className={formfield.gridsize}>
                            <div className="form-group validate-input">
                                <label className="guest-request-maintenance-lable">{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}</label> {/* keval 4-4-2023 */}
                                <textarea
                                    // disabled={disabled}
                                    type="text"
                                    onChange={(e) => props.handleFormData(formfield.type, formfield.field, e.target.value)}
                                    value={getCurrentState().formData[formfield.field]}
                                    className="form-control"
                                    id={"form-" + formfield.field}
                                    name={formfield.field}
                                    autoComplete="off"
                                    placeholder={`Enter ${formfield.text}`}
                                />
                            </div>
                        </div>
                    )
                }
                else if (formfield.type === 'radio') {
                    return (

                        <div className={formfield.gridsize}>
                            <div className="form-group validate-input">
                                <label className="guest-request-maintenance-lable">{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}</label>
                                <div className="row">
                                    <div className="col-12" >
                                        <div className="task-btn-custom-control" id={"form-" + formfield.field}>
                                            <div className="col-12">
                                                <div className="row">
                                                    {formfield.field &&
                                                        getCurrentState().masterData[formfield.masterdata]?.map((data, index) => {
                                                            if (getCurrentState().formData._id && formfield.field !== "priorityid") {
                                                                if (getCurrentState().formData[formfield.field] === data.value) {
                                                                    return (
                                                                        <div className="custom-control" key={data.value}>
                                                                            <input disabled={formfield.disabled} type="radio" id={formfield.field + data.value}
                                                                                readOnly name={formfield.field + data.value} className="custom-control-input" checked={getCurrentState().formData[formfield.field] === data.value} />
                                                                            <label className="custom-control-label m-1 radio-label" htmlFor={formfield.field + data.value} onClick={() => !getCurrentState().formData._id && props.handleFormData(formfield.type, formfield.field, data.value)}>{data.label}</label>
                                                                        </div>
                                                                    )
                                                                } else {
                                                                    return <></>
                                                                }
                                                            } else {
                                                                return (
                                                                    <div className="custom-control" key={data.value}>
                                                                        <input disabled={formfield.disabled} type="radio" id={formfield.field + data.value}
                                                                            readOnly name={'form-' + formfield.field} className="custom-control-input "
                                                                            defaultChecked={getCurrentState().formData[formfield.field] === data.value || index === 0}
                                                                        />
                                                                        {formfield.field === "priorityid"
                                                                            ? <label className="custom-control-label m-1 radio-label" htmlFor={formfield.field + data.value} onClick={() => props.handleFormData(formfield.type, formfield.field, data.value)}
                                                                                style={{
                                                                                    backgroundColor: getCurrentState().formData[formfield.field] === data.value ? `${getColor()}` : "rgb(249, 249, 249)",
                                                                                    color: getCurrentState().formData[formfield.field] === data.value ? "white" : "#000000"
                                                                                }}
                                                                            >
                                                                                {data.label}</label>
                                                                            : <label className="custom-control-label radio-label" htmlFor={formfield.field + data.value} onClick={() => props.handleFormData(formfield.type, formfield.field, data.value)}
                                                                            >{data.label}</label>
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                else if (formfield.type === 'image') {
                    const imagedata = getCurrentState().formData[formfield.field] || Config.getDefaulImageUploadUrl()
                    return (
                        <div className={formfield.gridsize}>
                            <div className="form-group validate-input filepreview capture-image-mainten">
                                <label className="guest-request-maintenance-lable">{formfield.text}{formfield.required}</label>
                                <input
                                    accept={formfield.accepttype}
                                    disabled={formfield.disabled}
                                    type="file"
                                    className="capture-input"
                                    onChange={(e) => {
                                        props.handleFormData(formfield.type, formfield.field, e.target.files)
                                    }}
                                    id={"form-" + formfield.field}
                                    name={formfield.field}
                                    autoComplete="off"
                                    multiple={formfield.multiple}
                                />
                                <img src={Capture} />
                            </div>
                        </div>
                    )
                }
                else {
                    return <></>
                }
            }
        }

        const renderFilterHtmlControls = (formfield) => {
            if (formfield && (formfield.defaultvisibility)) {
                if (formfield.type === 'multipleselectdropdown') { // for multipleselect dropdown
                    let value = getCurrentState().filterData[formfield.field] || []

                    value = value.map((fieldid) => {
                        if (formfield.field === 'taskstatustype') {
                            return IISMethods.getobjectfromarray(getCurrentState().masterDataList[formfield.masterdata], 'statustype', fieldid)['_id']
                        }
                        else if (formfield.field === 'priorityid') {
                            return IISMethods.getobjectfromarray(getCurrentState().masterDataList[formfield.masterdata], '_id', fieldid)['_id']
                        }
                    })

                    return (
                        <div className={formfield.gridsize} >
                            <div className="form-group validate-input">
                                <label className=" guest-request-maintenance-lable">{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}</label>
                                <MultipleSelectDropdown
                                    cleanable={formfield.cleanable}
                                    placeholder={`Select ${formfield.text}`}
                                    className="form-control has-val p-0 select-box"
                                    data={getCurrentState().masterData[formfield.masterdata]}
                                    id={"form-" + formfield.field}
                                    value={value}
                                    name={formfield.field}
                                    onChange={(e) => props.handleFilterData(formfield.type, formfield.field, e, formfield)}
                                    selectall={formfield.selectall}
                                />
                            </div>
                        </div>
                    )
                } else if (formfield.type === 'datepicker') {
                    let options = {
                        disabledDate: (date) => {
                            let fromdate = IISMethods.GetDateTimefromISOString(IISMethods.GetTZdatetime(date).toISOString())
                            if (formfield.disablefromdate) {
                                let selectedDate = IISMethods.GetDateTimefromISOString(formfield.disablefromdate)
                                selectedDate.setDate(selectedDate.getDate())
                                return isBefore(fromdate, selectedDate)
                            }
                            else if (formfield.disablefromfield) {
                                let selectedDate = IISMethods.GetDateTimefromISOString(getCurrentState().filterData[formfield.disablefromfield])
                                selectedDate.setDate(selectedDate.getDate())
                                return isBefore(fromdate, selectedDate)
                            }
                        }
                    }
                    let value = getCurrentState().filterData[formfield.field]
                    return (
                        <div className={formfield.gridsize} >
                            <div className="form-group validate-input">
                                <label className="guest-request-maintenance-lable">{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}</label>
                                <DatePickerRsuite
                                    className="form-control p-0 has-val"
                                    placeholder={`Enter ${formfield.text}`}
                                    format={IISMethods.getDateFormatByProperty()}
                                    id={"form-" + formfield.field}
                                    name={formfield.field}
                                    value={value ? IISMethods.GetDT(IISMethods.GetISOdate(value)) : ''}
                                    type="text"
                                    onChange={(e) => props.handleFilterData(formfield.type, formfield.field, e, formfield)}
                                    cleanable={formfield.cleanable}
                                    disabled={formfield.disabled}
                                    ranges={[]}
                                    {...options}
                                />
                            </div>
                        </div>
                    )
                }
                else {
                    return <></>
                }
            }
        }

        var rightSidebarFormData
        var rightSidebar
        var pageName
        var formData
        var formname
        var filterData
        var oldFilterData

        filterData = IISMethods.getCopy(getCurrentState().filterData)
        oldFilterData = IISMethods.getCopy(getCurrentState().oldFilterData)

        rightSidebar = 'rightSidebar'
        rightSidebarFormData = getCurrentState().rightSidebarFormData
        pageName = checkinData.pageName
        formname = 'form-'
        formData = IISMethods.getCopy(checkinData.formData)

        var dateValueFrom = filterData['fromdate']
        var dateValueTo = filterData['todate']

        var dateValue = (dateValueFrom && dateValueTo) ? [dateValueFrom, dateValueTo] : []

        //handleSelect category of maintenance JENIL 23/8/2023 10:50 AM
        const handleSelectCategory = (category) => {
            setSelectedProduct({ ...selectedProduct, productsubcategoryid: category._id, productsubcategory: category.productsubcategory })
            setModalStep(3)
        }

        //handleSelect product of maintenance JENIL 23/8/2023 10:50 AM
        const handleSelectProduct = async (product) => {
            let changeState = { ...selectedProduct, serialnumber: product?.serialnumber || Config.dummyserialno, product: product.productname, productid: product.productid }
            setSelectedProduct(changeState)
            await props.getIssueList(product.productid)
            //if product has issue list then show issue modal other wise select product and go to 1st modal step JENIL 24/8/2023 9:59 AM
            if (getCurrentState().masterDataList["issuemaster"]?.length > 0) {
                setIssueModal(true);
            } else {
                await props.handleFormData("selectproduct", "productid", changeState)
                setIssueModal(false)
                setModalStep(1);
                setSelectedProduct({})
            }
        }

        //handle issue select JENIL 23/8/2023 12:19 PM
        const handleSelectProductIssues = (issue) => {
            let changes = selectedProduct?.issue?.length > 0 ? [...selectedProduct?.issue] : []
            let index = changes.findIndex(a => a._id === issue._id);
            if (index >= 0) {
                changes.splice(index, 1)
            } else {
                changes.push(issue)
            }
            setSelectedProduct({ ...selectedProduct, issue: [...changes] })
        }

        return (
            <>
                <ModalRsuite
                    size={"xs"}
                    open={issueModal}
                    onClose={() => {
                        setIssueModal(false)
                        setSelectedProduct({ ...selectedProduct, issue: [], serialnumber: "", product: "", productid: "" })
                    }}
                    title={
                        <div className="model-header icon">
                            {selectedProduct?.product}
                        </div>
                    }
                    body={
                        <>
                            <div className="col-12">
                                <div className='row'>
                                    <div className="col-12">
                                        <div className="form-group validate-input mb-0">
                                            <label className="label-form-control mb-3">Issue<span className="text-danger"> * </span></label>
                                            <div className="row">
                                                <div className="col-12" >
                                                    <div className="d-flex gap-5 align-items-center product-checkbox-block flex-wrap">
                                                        {
                                                            getCurrentState().masterDataList["issuemaster"]?.length > 0 &&
                                                            getCurrentState().masterDataList["issuemaster"]?.map((data, index) => {
                                                                let isChecked = selectedProduct["issue"]?.some(a => a._id === data._id)
                                                                return (
                                                                    <div className={`custom-control custom-ckeckbox pl-0 product-checkbox ${isChecked ? "fill-checkbox" : ""}`} key={data._id + index.toString()}>
                                                                        <input
                                                                            type="checkbox"
                                                                            id={"issuemater-id-" + data._id}
                                                                            readOnly
                                                                            name={"issuemater-id-" + data._id}
                                                                            className="custom-control-input"
                                                                            checked={isChecked ? true : false}
                                                                        />
                                                                        <label
                                                                            className="custom-control-label-checkbox mb-0"
                                                                            htmlFor={"issuemater-id-" + data._id}
                                                                            onClick={() => handleSelectProductIssues(data)}
                                                                        >
                                                                            {data.issue}
                                                                        </label>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    footer={
                        <div className="col-12 mt-1 mb-3">
                            <div className='row'>
                                <div className='col-6 pr-2'>
                                    <button
                                        onClick={() => {
                                            setIssueModal(false)
                                            setSelectedProduct({ ...selectedProduct, issue: [], serialnumber: "", product: "", productid: "" })
                                        }}
                                        className="btn btn-secondary-light btn-sm button" >
                                        Cancel
                                    </button>
                                </div>
                                <div className='col-6 pl-2'>
                                    <button
                                        onClick={async (e) => {
                                            if (selectedProduct?.issue?.length > 0) {
                                                await props.handleFormData("selectproduct", "productid", selectedProduct)
                                                setIssueModal(false)
                                                setModalStep(1);
                                                setSelectedProduct({})
                                            } else {
                                                IISMethods.notify("Please select issue", 2)
                                            }
                                        }}
                                        className="btn btn-primary-light btn-sm button">
                                        Apply
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                />
                {/* maintenance model srushti 28-2-23 */}

                <ModalRsuite
                    size={"xs"}
                    open={getCurrentState().modal['maintenancemodal']}
                    onClose={() => props.handleGrid(false, 'modal', 'maintenancemodal', 0)}

                    title={
                        <div className="model-header icon">
                            <span className="closeicon">
                                Maintenance Request
                            </span>
                        </div>
                    }

                    body={
                        <div className="col-12">
                            {
                                modalStep === 1 &&
                                <div className="row">
                                    {renderHtmlControls(rightSidebarFormData[0]?.formfields[0])}
                                    {renderHtmlControls(rightSidebarFormData[0]?.formfields[1])}
                                    {renderHtmlControls(rightSidebarFormData[0]?.formfields[2])}
                                    {renderHtmlControls(rightSidebarFormData[0]?.formfields[3])}
                                    {renderHtmlControls(rightSidebarFormData[0]?.formfields[4])}
                                    {renderHtmlControls(rightSidebarFormData[0]?.formfields[5])}
                                    {renderHtmlControls(rightSidebarFormData[0]?.formfields[6])}

                                    <div className='col-12'>
                                        <div className='images-block'>
                                            {
                                                getCurrentState().formData.images?.map((item, index) => {
                                                    return (
                                                        <div className='d-inline-flex' key={index}>
                                                            <div className='img-block'>
                                                                <i className='material-icons-round text-danger cancel-btn' onClick={() => props.handleFormData('image', 'images', item, index)}>cancel</i>
                                                                <div className='img-wrap'>
                                                                    <img src={item} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                modalStep === 2 &&
                                <div className='categories-list'>
                                    <div className='search-categories'>
                                        <div className='back-icon d-flex align-items-center gap-5' onClick={() => {
                                            setModalStep(1);
                                            setSearch("")
                                        }}>
                                            <i className='material-icons-round text-18 font-weight-700'>arrow_back_ios</i>
                                            <span className='text-16 text-dark font-weight-600'>Add Item</span>
                                        </div>
                                        <div className='search-input-icon'>
                                            <i className="material-icons-round">search</i>
                                            <input
                                                type='text'
                                                placeholder='Search Category'
                                                value={search}
                                                onChange={(e) => setSearch(e?.target?.value ? e.target.value : "")}
                                            />
                                        </div>
                                    </div>
                                    {
                                        <div className='single-category-grid-list'>
                                            {
                                                getCurrentState().masterDataList["productsubcategory"]?.length > 0 &&
                                                getCurrentState().masterDataList["productsubcategory"].filter((category) => {
                                                    if (search?.trim().length === 0) {
                                                        return true
                                                    } else if (category?.productsubcategory?.replace(/\s+/g, '')?.toLowerCase()?.includes(search.replace(/\s+/g, '')?.toLocaleLowerCase())) {
                                                        return true
                                                    }
                                                })?.map((category, index) => {
                                                    return (
                                                        <div className='single-category' key={category._id + index.toString()}>
                                                            <div className='category-image' onClick={() => { handleSelectCategory(category) }}>
                                                                <img className={'active'} src={category?.icon ? category?.icon : noimgfound} alt="Category" />
                                                            </div>
                                                            <div className='category-name-info d-flex align-items-center mt-1 justify-content-center gap-5'>
                                                                <span className='product-name text-12 text-dark font-weight-600'>{category.productsubcategory}</span>
                                                                <TooltipRsuite
                                                                    placement={'top'}
                                                                    trigger="click"
                                                                    tooltip={category.productsubcategory}
                                                                    content={
                                                                        <span className="material-icons d-block product-info text-14 text-dark">info</span>
                                                                    } />
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                </div>
                            }
                            {
                                modalStep === 3 && selectedProduct?.productsubcategoryid &&
                                <div className='categories-list'>
                                    <div className='search-categories'>
                                        <div className='back-icon d-flex align-items-center gap-5' onClick={() => {
                                            setModalStep(2);
                                            setSearch("")
                                        }}>
                                            <i className='material-icons-round text-18 font-weight-700'>arrow_back_ios</i>
                                            <span className='text-16 text-dark font-weight-600'>{selectedProduct?.productsubcategory}</span>
                                        </div>
                                        <div className='search-input-icon'>
                                            <i className="material-icons-round">search</i>
                                            <input
                                                type='text'
                                                placeholder='Search Product'
                                                value={search}
                                                onChange={(e) => setSearch(e?.target?.value ? e.target.value : "")}
                                            />
                                        </div>
                                    </div>
                                    {
                                        <div className='single-category-grid-list'>
                                            {
                                                getCurrentState().masterDataList["product"]?.length > 0 &&
                                                getCurrentState().masterDataList["product"]?.filter(obj => obj.productsubcategoryid === selectedProduct?.productsubcategoryid)?.filter((product) => {
                                                    if (search?.trim().length === 0) {
                                                        return true
                                                    } else if (product?.productname?.replace(/\s+/g, '')?.toLowerCase()?.includes(search.replace(/\s+/g, '')?.toLocaleLowerCase())) {
                                                        return true
                                                    }
                                                })?.map((product, index) => {
                                                    return (
                                                        <div className='single-category' key={product.serialnumber + index.toString()}>
                                                            <div className='category-image' onClick={() => { handleSelectProduct(product) }}>
                                                                <img className='active' src={product?.image ? product?.image : noimgfound} alt="Category" />
                                                            </div>
                                                            <div className='category-name-info d-flex align-items-center mt-1 justify-content-center gap-5'>
                                                                <span className='product-name text-12 text-dark font-weight-600'>{product.productname}</span>
                                                                <TooltipRsuite
                                                                    placement={'top'}
                                                                    trigger="click"
                                                                    tooltip={product.productname}
                                                                    content={
                                                                        <span className="material-icons d-block product-info text-14 text-dark">info</span>
                                                                    } />
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    }
                    footer={
                        modalStep === 1 &&
                        <div className="col-12 mt-1 btn-popup-loader">
                            <button className="btn-first-popup mb-3" type="submit" onClick={(e) => { getCurrentState().loading === 0 && props.handleAddButtonClick(e, 'maintenancemodal') }}>
                                {
                                    props.maintenancetloder === true ?
                                    <Loader size='sm' content={'Submit'} />
                                    : 'Submit'

                                }
                                {/* <Loader className="btn-loaders" content={'Submit'} /> */}
                            </button>
                        </div>
                    }

                />

                {/* multipalimagemodal model srushti 28-2-23 */}

                <ModalRsuite
                    size={"xs"}
                    open={getCurrentState().modal['multipalimagemodal']}
                    onClose={() => props.handleGrid(false, 'modal', 'multipalimagemodal', 0)}

                    title={
                        <div className="model-header icon">
                            <span className="closeicon">
                                Photos
                            </span>
                        </div>
                    }
                    body={
                        <div className="col-12">
                            {
                                getCurrentState().data.filter(obj => obj._id === getCurrentState().modal['multipalimagemodal']).map((item, index) => {

                                    return (
                                        <div className="row" key={index}>
                                            {
                                                item.images?.length > 1
                                                    ?
                                                    <div className="col-12">
                                                        <Carousel interval={6000} fade={true} className="custom-slider">
                                                            {item.images?.map((data, index) => {
                                                                let imagetype = data.image.split(/[#?]/)[0].split('.').pop().trim();

                                                                if (['png', 'jpeg', 'gif', 'svg', 'jpg', 'svg', 'webp', 'svg+xml'].includes(imagetype)) {
                                                                    return (
                                                                        <Carousel.Item>
                                                                            <div className="multiple-image-slider" key={index}>
                                                                                <img className='' src={data?.image} />
                                                                            </div>
                                                                        </Carousel.Item>
                                                                    )
                                                                }
                                                                else if (['mov', 'wmv', 'avi', 'mp4', 'avchd', 'flv', 'f4v', 'swf', 'mkv', 'webm'].includes(imagetype)) {
                                                                    return (
                                                                        <Carousel.Item>
                                                                            <div className="multiple-image-slider" key={index}>
                                                                                <video controls="controls autoplay" className="attached-video d-block w-100">
                                                                                    <source src={data?.image} type={`video/${imagetype}`} />
                                                                                </video>
                                                                            </div>
                                                                        </Carousel.Item>
                                                                    )
                                                                }
                                                            })
                                                            }
                                                        </Carousel>
                                                    </div>
                                                    :
                                                    <>
                                                        {
                                                            ['png', 'jpeg', 'gif', 'svg', 'jpg', 'svg', 'webp', 'svg+xml'].includes(item.images[0]?.image.split(/[#?]/)[0].split('.').pop().trim())
                                                                ?
                                                                <div className="col-12">
                                                                    <div className="single-image">
                                                                        <img className='' src={item.images[0].image} />
                                                                    </div>
                                                                </div>
                                                                :
                                                                ['mov', 'wmv', 'avi', 'mp4', 'avchd', 'flv', 'f4v', 'swf', 'mkv', 'webm'].includes(item.images[0]?.image.split(/[#?]/)[0].split('.').pop().trim())
                                                                    ?
                                                                    <div className="col-12">
                                                                        <div className="single-image">
                                                                            <video controls="controls autoplay" className="attached-video d-block w-100">
                                                                                <source src={item.images[0]?.image} type={`video/${item.images[0].image?.split(/[#?]/)[0].split('.').pop().trim()}`} />
                                                                            </video>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <></>
                                                        }
                                                    </>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }
                />

                <ModalRsuite
                    size={"xs"}
                    open={getCurrentState().modal['maintenanceratingmodal']}
                    onClose={() => props.handleGrid(false, 'modal', 'maintenanceratingmodal', 0)}
                    title={""}
                    body={
                        <form method='post'>
                            <div className={`col-12 text-center`}>
                                <div className="first-pop-logo">
                                    <img src={Rating} />
                                </div>
                            </div>
                            <div className="col-12 text-center">
                                <h1 className="first-popup-title mt-2 mb-4">We Value Your Feedback</h1>
                            </div>
                            {
                                getCurrentState().rightSidebarFormData[0]?.formfields.filter(formfield => {
                                    try {
                                        return formfield.defaultvisibility || getCurrentState().formData[formfield.condition.field] === formfield.condition.onvalue
                                    }
                                    catch {
                                        return false
                                    }
                                }).map((formfield, fieldindex) => {
                                    if (formfield.type === 'dropdown') {
                                        return (
                                            <>
                                                {/* vehicle modal rating 17-4-23 */}

                                                {/* charmi(09-06-2023)(rating tag print change) */}
                                                {getCurrentState().masterDataList[formfield.masterdata]?.map((data, dataindex) => {
                                                    let tagdata = data.tagdata || []
                                                    let ratingdataobj = {}
                                                    ratingdataobj[dataindex] = []
                                                    let singlearray = ratingdataobj[dataindex]
                                                    for (const item of tagdata) {
                                                        if (getCurrentState().formData['taskguestrating'] && getCurrentState().formData['taskguestrating'][dataindex]['stars'] !== 0 && getCurrentState().formData['taskguestrating'][dataindex]['stars'] <= item.stars && item.abovebelowflag === 1) {
                                                            let temptagdataarray = singlearray.map(data => data.tagid)
                                                            let tagdata = item.tags.filter(data => !temptagdataarray.includes(data.tagid))
                                                            singlearray.push(...tagdata)
                                                        }
                                                        else if (getCurrentState().formData['taskguestrating'] && getCurrentState().formData['taskguestrating'][dataindex]['stars'] !== 0 && getCurrentState().formData['taskguestrating'][dataindex]['stars'] >= item.stars && item.abovebelowflag === 0) {
                                                            let temptagdataarray = singlearray.map(data => data.tagid)
                                                            let tagdata = item.tags.filter(data => !temptagdataarray.includes(data.tagid))
                                                            singlearray.push(...tagdata)
                                                        }
                                                    }
                                                    return (
                                                        <div className="col-12 mt-3">
                                                            <div className="rate-box">
                                                                <div className="rate-box-content">
                                                                    <p>
                                                                        {data.rating}
                                                                    </p>
                                                                </div>
                                                                <div className="d-flex justify-content-between mx-5">
                                                                    {
                                                                        // handlestars(formfield.field,dataindex,data._id)
                                                                        [...Array(5)].map((star, index) => {
                                                                            if (getCurrentState().formData['taskguestrating'] && getCurrentState().formData['taskguestrating'][dataindex]['stars'] - 1 >= index) {
                                                                                return <div className='my-3'
                                                                                    onClick={() => { props.handleFormData('add-remove-star', formfield.field, index, '', dataindex, data._id) }}
                                                                                >
                                                                                    {star_fill}
                                                                                </div>
                                                                            } else {
                                                                                return <div className='my-3'
                                                                                    onClick={() => { props.handleFormData('add-remove-star', formfield.field, index, '', dataindex, data._id) }}
                                                                                >
                                                                                    {star_unfill}
                                                                                </div>
                                                                            }
                                                                        })

                                                                    }
                                                                </div>
                                                                {
                                                                    singlearray?.length > 0
                                                                    &&
                                                                    <div className='ratingTagGrid'>
                                                                        {
                                                                            singlearray.map((tagitem, index) => {
                                                                                let tagdata = IISMethods.getindexfromarray(getCurrentState().formData['taskguestrating'][dataindex]['tags'], 'tagid', tagitem.tagid)
                                                                                return (
                                                                                    <div className="task-tag-custom-control" key={index}>
                                                                                        <div className="custom-control-tag ">
                                                                                            <label className={`custom-label-rt content-center ${(tagdata !== -1) ? "tagactive" : ''}`}
                                                                                                onClick={() => props.handleFormData('add-remove-star', formfield.field, tagitem.tagid, 'tags', dataindex, tagitem.tag)}
                                                                                                style={{
                                                                                                    borderRadius: '20px',
                                                                                                    border: `1px solid #9E9E9E`,
                                                                                                    // background : 'black'
                                                                                                }} >
                                                                                                {tagitem.tag}
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                }
                                            </>
                                        )
                                    }
                                    else if (formfield.type === 'input-textarea') // for textarea
                                    {
                                        return (
                                            <div className={formfield.gridsize} key={`${fieldindex}`}>
                                                <div className="form-group validate-input mt-0">
                                                    <center><label className="first-popup-label my-3">{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}</label></center>
                                                    <textarea
                                                        disabled={formfield.disabled} type="text" rows={3}
                                                        defaultValue={getCurrentState().formData[formfield.field]}
                                                        onChange={(e) => props.handleFormData(formfield.type, formfield.field, e.target.value, '', 0, 'suggestion')}
                                                        className="form-control form-control-textarea"
                                                        id={form + formfield.field}
                                                        name={formfield.field} autoComplete="off"
                                                        placeholder={`Enter ${formfield.text}`}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </form>
                    }
                    footer={
                        <>
                            {/* {(getCurrentState().formData.ratingbtnlink && getCurrentState().formData.ratingbtnlink) &&
                                <div className="col-12 mt-1">
                                    <a target='_blank' href={getCurrentState().formData.ratingbtnlink}>
                                        <span className='vehicle-model-btn text-center'>{getCurrentState().formData.ratingbtnname}</span>
                                    </a>
                                </div>
                            } */}
                            <div className="col-12 mt-1">
                                <button type="submit" className="btn-first-popup mb-3"
                                    onClick={(e) => {
                                        getCurrentState().loading === 0 && props.handleAddButtonClick(e, 'maintenanceratingmodal', getCurrentState().formData.ratingbtnlink)
                                    }} >
                                    <Loader content={'Submit'} />
                                </button>
                            </div>
                        </>
                    }
                />

                {/* maintenance filter modal 13-6-23 */}
                <ModalRsuite
                    size={"xs"}
                    open={getCurrentState().modal['maintenancefiltermodal']}
                    onClose={(e) => { props.handleGrid(false, 'modal', 'maintenancefiltermodal', 0); }}
                    title={
                        <div className="model-header icon">
                            <span className="closeicon">
                                Apply Filter
                            </span>
                        </div>
                    }
                    body={
                        <>
                            <div className="col-12 mt-0 mb-0 px-0">
                                {renderFilterHtmlControls(rightSidebarFormData[0]?.formfields[0])}
                                {renderFilterHtmlControls(rightSidebarFormData[0]?.formfields[1])}
                                {/* daterange picker to fromdate and todate datepicker JENIL 29/6/2023 9:24AM */}
                                {renderFilterHtmlControls(rightSidebarFormData[0]?.formfields[2])}
                                {renderFilterHtmlControls(rightSidebarFormData[0]?.formfields[3])}
                            </div>
                            {/* <div className="col-11 mt-0 mb-0 px-0 ml-3">
                                <label className=" guest-request-maintenance-lable">Date</label>
                                <DateRangePickerRsuite
                                    className="form-control p-0 has-val" placeholder={`Enter Date`} format={IISMethods.getDateFormatByProperty()} id='filter-viewdate'
                                    name='filter-viewdate' ranges={[]}
                                    value={dateValue}
                                    onChange={(e) => props.handleFilterData('daterangepicker', '', e)}
                                />
                            </div> */}
                        </>
                    }
                    footer={
                        <div className="col-12 mt-1 mb-3">
                            <div className='row'>
                                <div className='col-6 pr-2'>
                                    <button
                                        onClick={async () => {
                                            await props.handleGrid(false, 'modal', 'maintenancefiltermodal', 0);
                                            await props.handleFilterData('filter-reset-data', '', '')
                                            await props.getList()
                                        }}
                                        className="btn btn-secondary-light btn-sm button" >
                                        Reset
                                    </button>
                                </div>
                                <div className='col-6 pl-2'>
                                    <button
                                        onClick={(e) => {
                                            props.handleAddFilterButtonClick(e)
                                        }}
                                        className="btn btn-primary-light btn-sm button">
                                        <Loader className="btn-loaders" content={'Apply'} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                />
            </>

        )
    }
    catch (e) {
        console.log(e)
        return <></>
    }
}


