import React, { useState, useEffect } from 'react'
import { store } from '../index'
import { useDispatch, connect, useSelector } from 'react-redux'
import ModalRsuite from './Components/ModalRsuite'
import { clearData, setLoading, setProps } from '../redux/actions'
import TimePickerRsuite from './Components/TimePickerRsuite'
import { IISMethods } from '../config/IISMethods'
import WakeUpCall from '../assets/images/wakeupcall.svg'
import Loader from './Components/Loader'

function WakeupCallRightsidebar(props) {
    const getCurrentState = () => {
        return store.getState()['root']
    }

    const form = 'form-'
    const renderHtmlControls = (formfield) => {
        if (formfield && (formfield.defaultvisibility || getCurrentState().formData[formfield.condition?.field] === formfield.condition?.onvalue)) {

            if (formfield.type === 'timepicker') {
                let value = getCurrentState().formData[formfield.field]
                return (
                    <>
                        <div className={formfield.gridsize} >
                            <div className="form-group validate-input">
                                <label className="guest-request-maintenance-lable">{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}</label>
                                <TimePickerRsuite
                                    cleanable={formfield.cleanable}
                                    className="form-control p-0 has-val"
                                    placeholder={`Enter ${formfield.text}`}
                                    format={IISMethods.getTimeFormatByProperty()}
                                    id={form + formfield.field}
                                    name={formfield.field}
                                    value={value ? IISMethods.GetISOtoTZtime(value) : ''}
                                    // value={getCurrentState().formData[formfield.field] ? IISMethods.GetDT(getCurrentState()?.formData[formfield.field]) : ''}
                                    type="text"
                                    ranges={[]}
                                    showMeridian
                                    onChange={(e) => props.handleFormData(formfield.type, formfield.field, e)}
                                />
                            </div>
                        </div>
                    </>
                )
            }
            else if (formfield.type === 'multipletagpicker') {
                return (
                    <div className={formfield.gridsize} >
                        <div className="form-group validate-input">
                            <label className="guest-request-maintenance-lable">{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}</label>
                            <div className='wakeup-container'>
                                {
                                    getCurrentState().masterDataList[formfield.masterdata] && getCurrentState().masterDataList[formfield.masterdata].map((data, index) => {
                                        return (
                                            <div className='wakeup-inner-content' key={index}>
                                                <label className={`${IISMethods.getindexfromarray(getCurrentState().formData[formfield.field], '_id', data._id) !== -1 ? 'active' : ''}`}
                                                    onClick={(e) => props.handleFormData(formfield.type, formfield.field, data._id, data.name)}>{data.name}</label>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                        </div>
                    </div>
                )
            }

            else {
                return <>{formfield.field}</>
            }
        }
    }

    var rightSidebarFormData
    var rightSidebar
    var formname

    rightSidebar = 'rightSidebar'
    rightSidebarFormData = getCurrentState().rightSidebarFormData
    formname = 'form-'


    try {
        return (
            <>
                {/* WakeUp call model */}
                {
                    getCurrentState().modal['wakeupcallModal']
                    &&
                    <ModalRsuite
                        size={"xs"}
                        open={getCurrentState().modal['wakeupcallModal']}
                        onClose={() => { props.handleGrid(false, 'modal', 'wakeupcallModal', 0) }}
                        title={
                            <div className="model-header icon">
                                <span className="closeicon">
                                    Wakeup Call
                                </span>
                            </div>
                        }
                        className=""
                        body={
                            <>
                                {renderHtmlControls(rightSidebarFormData[0]?.formfields[0])}
                                {renderHtmlControls(rightSidebarFormData[0]?.formfields[1])}
                            </>
                        }
                        footer={
                            <>
                                <div className="col-12">
                                    <button type="submit" className="vehicle-model-btn" onClick={(e) => { getCurrentState().loading === 0 && props.handleAddButtonClick(e, 'wakeupcallModal') }}>
                                        <Loader content={'Submit'}/>
                                    </button>
                                </div>
                            </>
                        }
                    />
                }

                {
                    getCurrentState().modal['wakeupcallInfoModal']
                    &&
                    <ModalRsuite
                        size={"xs"}
                        open={getCurrentState().modal['wakeupcallInfoModal']}
                        onClose={() => { props.handleGrid(false, 'modal', 'wakeupcallInfoModal', 0) }}
                        title={
                            <div className="model-header icon">
                                <span className="closeicon">
                                    Wakeup Call
                                </span>
                            </div>
                        }
                        className=""
                        body={
                            <>
                                <div className='col-12 text-center'>
                                    <img src={WakeUpCall} height="50px" />
                                </div>
                                <div className="col-12 mt-3">
                                    <span className="text-center d-block text-12">You have scheduled the wakeup call for</span>
                                </div>
                                {getCurrentState().data?.map((timedata, index) => {
                                    return (
                                        <div className="col-12 mt-1">
                                            <h1 className='text-center d-block time-text mb-0'>{IISMethods.GetTimeFormat(timedata.datetime)}</h1>
                                        </div>
                                    )
                                })}
                                <div className="col-12 mt-1">
                                    <span className="text-center d-block text-12">on your phone, please leave your phone turned on.</span>
                                </div>

                            </>
                        }
                        footer={
                            <>
                                <div className="col-12">
                                    <button type="submit" className="vehicle-model-btn" onClick={() => { props.handleGrid(false, 'modal', 'wakeupcallInfoModal', 0) }}>Ok</button>
                                </div>
                            </>
                        }

                    />
                }

            </>
        )
    } catch (error) {
        console.log(error)
        return <></>
    }

}
const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    clearData: (payload) => dispatch(clearData(payload)),
    setProps: (payload) => dispatch(setProps(payload)),
    setLoading: (payload) => dispatch(setLoading(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(WakeupCallRightsidebar)
