import React, { useEffect, useState } from 'react'
import { setProps } from '../redux/actions'
import { connect } from 'react-redux'
import { Config, IISMethods, allowdedImage } from '../config/IISMethods'
import NearByController from '../Controller/NearByController'
import { store } from '../index'

const getCurrentState = () => {
  return store.getState()['root']
}

const NearBy = (props) => {

  useEffect(() => {
    async function fetchMyAPI() {
      await props.setProps({
        rightSidebarFormData: []
      })
    }
    fetchMyAPI()
  }, [])

  return <NearByController pagename="nearby" backpage={false} masterjson={masterjson} />
}

const mapStateToProps = state => ({
  ...state
})

const mapDispatchToProps = dispatch => ({
  setProps: (payload) => dispatch(setProps(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(NearBy)

const masterjson = (key) => {
  switch (key) {
      case 'nearby':
          return [
              {
                  pagename: 'nearby',
                  formname: 'Near By Location',
                  useraction: 'mobilecheckin',
                  apipath: '',
                  formfields: [
                    {
                      'field': 'locationid',
                      'text': 'NearBy',
                      'type': Config.getHtmlcontorls()['dropdown'],
                      'disabled': false,
                      'defaultvisibility': true,
                      'apipath': 'property/guestexperience/nearbylocation',
                      'masterdata': 'nearby',
                      'masterdatafield': 'nearby',
                      'formdatafield': 'location',
                      'onchangefill': ['locationtypeid'],
                      'onchangedata': ['locationid'],
                      'required': true,
                      'gridsize': Config.getGridclasses()['col-12'],
                      'staticfilter': { 'propertyid': getCurrentState().reservationDetails.propertyid }
                    },
                    {
                      'field': 'locationtypeid',
                      'text': 'NearByType',
                      'type': Config.getHtmlcontorls()['dropdown'],
                      'disabled': false,
                      'defaultvisibility': true,
                      'apipath': 'property/guestexperience/nearbylocationtype',
                      'masterdata': 'nearbytype',
                      'masterdatafield': 'nearbytype',
                      'formdatafield': 'locationtype',
                      'dependentfilter': { 'locationid': 'locationid' },
                      'onchangefill': ['nearbyaddress'],
                      'onchangedata': ['locationtypeid'],
                      'required': true,
                      'gridsize': Config.getGridclasses()['col-12'],
                      'masterdatadependancy': true,
                      'staticfilter': { 'propertyid': getCurrentState().reservationDetails.propertyid }
                    },
                    {
                      'field': 'nearbyaddress',
                      'text': 'NearByAddress',
                      'type': Config.getHtmlcontorls()['dropdown'],
                      'disabled': false,
                      'defaultvisibility': true,
                      'apipath': 'property/guestexperience/nearbylocationaddress',
                      'masterdata': 'nearbyaddress',
                      'masterdatafield': 'nearbyaddress',
                      'formdatafield': 'nearbyaddress',
                      'dependentfilter': { 'locationtypeid': 'locationtypeid' },
                      'onchangedata': ['nearbyaddress'],
                      'required': true,
                      'gridsize': Config.getGridclasses()['col-12'],
                      'masterdatadependancy': true,
                      'staticfilter': { 'propertyid': getCurrentState().reservationDetails.propertyid }
                    },
                  ]
              }
          ]

      default:
          return []
  }
}