
import React, { useEffect, useState } from 'react'
import { IISMethods, Config } from '../../config/IISMethods'
import 'rsuite/dist/rsuite.css'
import { store } from '../..'

export default function Progress(props) {
    // srushti
    // const getCurrentState = () => {
    //     return store.getState()
    // }
    const getColor = (colorCode, opacity) => {
        let r = colorCode?.r || 0
        let g = colorCode?.g || 0
        let b = colorCode?.b || 0
        let a = opacity ? opacity : colorCode?.a
        return `rgba(${r}, ${g}, ${b}, ${a})`
    }
    try {
        return (
            <>
                <div className="body-content">
                    <div className="col-12 shadow-box-content px-3 pb-3">

                        {props.pagename === "request" &&
                            <>
                                <div className="row">
                                    <div className="col-12 mt-3 request-item-progress">
                                        {props.masterdatastate.products?.length > 0 && props.masterdatastate.products.map((item) => {
                                            return (
                                                <>
                                                    {(item.productname !== '' || item.productname !== undefined) && <span>{item.productname}</span>}
                                                    {item.quantity !== 0 &&
                                                        <span>({item.quantity})</span>
                                                    }<br />
                                                    {(item.description !== '' || item.description !== undefined) &&
                                                        <div className='mainten-req-sentence-desc'>
                                                            <p>{item.description}</p>
                                                        </div>
                                                    }
                                                </>
                                            )
                                        })
                                        }
                                    </div>
                                </div>
                            </>
                        }

                        {props.pagename === "maintenance" &&
                            <>
                                <div className="request-card-body">
                                    {props.masterdatastate?.images?.length > 0 &&
                                        <div className="mt-3 request-card-body-img" onClick={() => props.handleGrid(props.masterdatastate._id, 'modal', 'multipalimagemodal', 1)}>
                                            {(props.masterdatastate.images[0].image !== '' || props.masterdatastate?.images[0].image !== undefined) &&
                                                <figure>
                                                    <img src={props.masterdatastate?.images[0]?.image} />
                                                </figure>
                                            }
                                        </div>
                                    }
                                    <div className="mt-3 request-item-progress">
                                        {
                                            props.masterdatastate?.productsubcategoryid === Config.othersubcategory
                                                ?
                                                'Other Product'
                                                :
                                                props.masterdatastate?.product
                                        }

                                        <label
                                            className='list-label ml-3'
                                            style={{
                                                // color: getColor(requestdata.prioritycolor, 1),
                                                color: "white",
                                                backgroundColor: getColor(props.masterdatastate?.prioritycolor)
                                            }}
                                        >{props.masterdatastate?.priority}</label>

                                        {(props.masterdatastate?.comment !== '' || props.masterdatastate?.comment !== undefined) &&
                                            <div className='mainten-req-sentence-desc'>
                                                <p>{props.masterdatastate?.comment}</p>
                                            </div>}
                                    </div>
                                </div>
                            </>
                        }

                        {props.pagename === 'reportproblem' &&
                            <>
                                <div className="request-card-body">
                                    {props.masterdatastate?.images?.length > 0 &&
                                        <div className="mt-3 request-card-body-img" onClick={() => props.handleGrid(props.masterdatastate?._id, 'modal', 'multipalimagemodal', 1)}>
                                            {(props.masterdatastate?.images[0].image !== '' || props.masterdatastate?.images[0].image !== undefined) &&
                                                <figure>
                                                    <img src={props.masterdatastate?.images[0]?.image} />
                                                </figure>
                                            }
                                        </div>
                                    }
                                    <div className="mt-3 request-item-progress">
                                        {
                                            props.masterdatastate.problem?.length > 0
                                            &&
                                            <span>
                                                {props.masterdatastate?.problem.map(item => item['problem']).join(', ')}
                                            </span>
                                        }
                                        {
                                            props.masterdatastate.productdata?.length > 0
                                            &&
                                            <span>
                                                {props.masterdatastate?.productdata?.map(item => item['productsubcategory']).join(', ')}
                                            </span>
                                        }
                                        <div className="mainten-req-sentence-desc">
                                            <p className='d-block m-0'>
                                                {props.masterdatastate?.productdata?.map(item => item['displayname']).join(', ')}
                                            </p>
                                            <p className='d-block m-0'>
                                                {props.masterdatastate?.productdata[0]?.issue?.map(item => item['issue']).join(', ')}
                                            </p>
                                            <p className='d-block m-0'>
                                                {props.masterdatastate?.description}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {props.masterdatastate?.areadetails?.length > 0 &&
                                    <div className="request-box">
                                        <div className="area-list" >
                                            <span>
                                                {props.masterdatastate?.areadetails.map(item => item['area']).join(', ')}
                                            </span>
                                        </div>
                                    </div>
                                }
                            </>
                        }

                        <div className="request-box">
                            <div className="request-item">
                                <span >{props.name}</span>
                            </div>
                            <div className="wrapper mt-3">
                                <ul className="timeline timeline-align-left">

                                    {/* for progressbar and show progress data  srushti*/}
                                    {props.masterdatastate.timeline?.map((item, index) => {
                                        return (
                                            <>
                                                <li className={`timeline-item ${item.stagestatus === 1 && ''}`} key={index}>
                                                    <span className={`timeline-item-tail ${(props.masterdatastate.timeline[index + 1]?.stagestatus === 1 && item.stagestatus === 1) ? 'completed' : ''}`}></span>
                                                    <span className={`timeline-item-dot ${item.stagestatus === 1 && 'completed'}`}></span>
                                                    <div className="timeline-item-content">
                                                        <div className='d-flex gap-10 align-center'>
                                                            {(item.title !== '' || item.title !== undefined) && <span className='progress-title'> {item.title}</span>}
                                                            {(item.date) && <span className='progress-date'>{IISMethods.GetDateFormat(item.date)}</span>}
                                                        </div>
                                                        <div className='pt-0 pl-3 pb-3'>
                                                            {(item.subtitle !== '' || item.subtitle !== undefined) && <div className="progress-detail">{item.subtitle}</div>}
                                                            {item.date && <div className="progress-date">{IISMethods.GetDateFormat(item.date)} {IISMethods.GetTimeFormat(item.date)}</div>}
                                                        </div>
                                                        <div className=''>
                                                            {(item.process?.length > 0) && item.process.map((items, index) => {
                                                                return (
                                                                    <div className='pb-2 pl-3'>
                                                                        {(items.title !== '' || items.title !== undefined) && <div className="progress-detail" key={index}>{items.title}</div>}
                                                                        {items.date && <div className="progress-date">{IISMethods.GetDateFormat(items.date)} {IISMethods.GetTimeFormat(items.date)}</div>}
                                                                    </div>
                                                                )
                                                            })
                                                            }
                                                        </div>
                                                    </div>
                                                </li>
                                            </>
                                        )
                                    })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    catch (e) {
        console.log(e)
        return <></>
    }
}
