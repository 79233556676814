
import React, { useState } from 'react'
import { store } from '../index'
import { Config, IISMethods, JsCall } from '../config/IISMethods'
import ModalRsuite from './Components/ModalRsuite'
import TimePickerRsuite from './Components/TimePickerRsuite'
import DateTimePicker from './Components/DateTimePicker'
import Loader from './Components/Loader'
import isBefore from 'date-fns/isBefore'

export default function SecoundHouseKeepingRightSidebar(props) {
    const getCurrentState = () => {
        return store.getState()['root']
    }

    const numberInputOnWheelPreventChange = (e) => {
        // Prevent the input value change
        e.target.blur()
        // Prevent the page/container scrolling
        e.stopPropagation()
        // Refocus immediately, on the next tick (after the current   function is done)
        setTimeout(() => {
            e.target.focus()
        }, 0)
    }

    const form = 'form-'
    try {
        return (
            <>
                {/* House Keeping Frist Modal */}
                {
                    <ModalRsuite
                        size={"xs"}
                        open={getCurrentState().modal['housekeepingModal']}
                        onClose={() => { props.handleGrid(false, 'modal', 'housekeepingModal', 0) }}
                        title={
                            <div className="model-header icon">
                                <span className="closeicon">
                                    Housekeeping
                                </span>
                            </div>
                        }
                        className="modalContentPad"
                        body={
                            <>
                                {/* housekeeping first modal srushti 7-4-23 */}
                                {getCurrentState().reservationDetails.ischeckinday === 0 &&
                                    <div className='col-12'>
                                        <form method="post" >
                                            <div className="row">
                                                <div className="col-12 mt-0">
                                                    <span className='checkout-email'>Found any issues?</span>
                                                </div>
                                                <div className="col-12 m-0">
                                                    <div className="row housekeeping-box">
                                                        {
                                                            getCurrentState().rightSidebarFormData[0]?.formfields.filter(formfield => {
                                                                let conditions = false
                                                                if (formfield.condition) {
                                                                    let condition = formfield.condition?.map((item) => {
                                                                        if (getCurrentState().formData[item.field] === item.onvalue) {
                                                                            return true
                                                                        }
                                                                        return false
                                                                    })
                                                                    let status = condition.includes(true) ? true : false
                                                                    conditions = status
                                                                }
                                                                try {
                                                                    return formfield.defaultvisibility || conditions
                                                                }
                                                                catch {
                                                                    return false
                                                                }
                                                            }).map((formfield, fieldindex) => {
                                                                if (formfield.type === 'radio') {
                                                                    return (
                                                                        <div className={formfield.gridsize} key={`${fieldindex}`}>
                                                                            <div className="form-group validate-input">
                                                                                <label className="guest-request-maintenance-lable invisible d-none">{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}</label>
                                                                                <div className="col-12 m-0 p-0">
                                                                                    <div className="btn-custom-control" id={'form-' + formfield.field}>
                                                                                        <div className="gridBox">
                                                                                            {formfield.field &&
                                                                                                getCurrentState().masterData?.problem?.map((data, index) => {
                                                                                                    return (
                                                                                                        <div className="">
                                                                                                            <div className="custom-control custom-radio housekeeping-img-box p-0" key={data.value}>
                                                                                                                <input
                                                                                                                    disabled={formfield.disabled}
                                                                                                                    type="radio"
                                                                                                                    id={formfield.field}
                                                                                                                    name={formfield.field}
                                                                                                                    className="custom-control-input"
                                                                                                                    checked={getCurrentState().formData[formfield.field] === data.value}
                                                                                                                />
                                                                                                                <label className="custom-control-label mb-0 p-2" htmlFor={formfield.field + data.value} onClick={() => props.handleFormData(formfield.type, formfield.field, data.value)}>
                                                                                                                    <img className='housekeeping-img' src={data.icon} />
                                                                                                                    <div className="text-size">
                                                                                                                        <p className="text-center">{data.label}</p>
                                                                                                                    </div>
                                                                                                                </label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                                else if (formfield.type === 'input-textarea') // for textarea
                                                                {
                                                                    return (
                                                                        <div className={`${formfield.gridsize} mb-0`} key={`${fieldindex}`}>
                                                                            <div className="form-group validate-input">
                                                                                <label className="guest-request-maintenance-lable">{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}</label>

                                                                                <textarea
                                                                                    disabled={formfield.disabled} type="text" rows={4}
                                                                                    defaultValue={getCurrentState().formData[formfield.field]}
                                                                                    onChange={(e) => props.handleFormData(formfield.type, formfield.field, e.target.value)}
                                                                                    className="form-control "
                                                                                    id={form + formfield.field}
                                                                                    name={formfield.field} autoComplete="off"
                                                                                    placeholder={`Enter ${formfield.text}`}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                }
                                {/* housekeeping second modal srushti 7-4-23 */}
                                {getCurrentState().reservationDetails.ischeckinday === 1 &&
                                    <div>
                                        <form method="post" >
                                            <div className={`col-12 text-center`}>
                                            </div>
                                            <div className="col-12  mt-0">
                                                <span className='checkout-email'>Clean My Room</span>
                                            </div>
                                            <div className="col-12 m-0">
                                                <div className="row housekeeping-box">
                                                    {
                                                        getCurrentState().rightSidebarFormData[0]?.formfields.filter(formfield => {
                                                            let conditions = false
                                                            if (formfield.condition) {
                                                                let condition = formfield.condition?.map((item) => {
                                                                    if (getCurrentState().formData[item.field] === item.onvalue) {
                                                                        return true
                                                                    }
                                                                    return false
                                                                })
                                                                let status = condition.includes(true) ? true : false
                                                                conditions = status
                                                            }
                                                            try {
                                                                return formfield.defaultvisibility || conditions
                                                            }
                                                            catch {
                                                                return false
                                                            }
                                                        }).map((formfield, fieldindex) => {
                                                            if (formfield.type === 'radio') {
                                                                return (
                                                                    <div className={formfield.gridsize} key={`${fieldindex}`}>
                                                                        <div className="form-group validate-input">
                                                                            <label className="guest-request-maintenance-lable invisible d-none">{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}</label>
                                                                            <div className="col-12 px-0">
                                                                                <div className="btn-custom-control" id={'form-' + formfield.field}>
                                                                                    <div className="gridBox">
                                                                                        {
                                                                                            formfield?.radiooptions?.map((data) => {
                                                                                                return (
                                                                                                    <div>
                                                                                                        <div className="custom-control custom-radio housekeeping-img-box p-0" key={data.value}>
                                                                                                            <input disabled={formfield.disabled} type="radio"
                                                                                                                id={form + formfield.field}
                                                                                                                // readOnly
                                                                                                                name={formfield.field} className="custom-control-input"
                                                                                                                checked={getCurrentState().formData[formfield.field] === data.value} />
                                                                                                            <label className="custom-control-label mb-0 p-2" htmlFor={formfield.field + data.value}
                                                                                                                onClick={() => props.handleFormData(formfield.type, formfield.field, data.value)}>
                                                                                                                <img className='housekeeping-img' src={data.icon} />
                                                                                                                <div className="text-size">
                                                                                                                    <p className="text-center">{data.label}</p>
                                                                                                                </div>
                                                                                                            </label>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                    {
                                                                                        !getCurrentState().formData.tab == 0 &&
                                                                                        <>
                                                                                            <div className="col-12 text-left text-14 m-0 px-0 mt-3">
                                                                                                <p>Select Time</p>
                                                                                            </div>
                                                                                            <hr className='housekeeping-later-line my-2' />
                                                                                        </>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                            else if (formfield.type === 'number-input') {
                                                                return (
                                                                    <div className={formfield.gridsize} key={`${fieldindex}`}>
                                                                        <div className="form-group validate-input">
                                                                            <label className="guest-request-maintenance-lable">{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}</label>
                                                                            <input
                                                                                onKeyPress={(e) => {
                                                                                    if (!((e.which >= 48 && e.which <= 57) || (e.which > 95 && e.which < 106))) {
                                                                                        e.preventDefault()
                                                                                    }
                                                                                }}
                                                                                type="number"
                                                                                pattern="[0-9]*"
                                                                                className="form-control form-control-sm number-input"
                                                                                id={form + formfield.field}
                                                                                name={formfield.field}
                                                                                value={getCurrentState().formData[formfield.field]}
                                                                                disabled={formfield.disabled}
                                                                                onChange={(e) => props.handleFormData(formfield.type, formfield.field, e.target.validity.valid ? e.target.value : getCurrentState().formData[formfield.field])}
                                                                                placeholder={`00`}
                                                                                onWheel={numberInputOnWheelPreventChange}
                                                                                min="0"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                            else if (formfield.type === 'input-textarea') // for textarea
                                                            {
                                                                return (
                                                                    <div className={formfield.gridsize} key={`${fieldindex}`}>
                                                                        <div className="form-group validate-input mt-0">
                                                                            <label className="guest-request-maintenance-lable">{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}</label>
                                                                            <textarea
                                                                                disabled={formfield.disabled} type="text" rows={4}
                                                                                defaultValue={getCurrentState().formData[formfield.field]}
                                                                                onChange={(e) => props.handleFormData(formfield.type, formfield.field, e.target.value)}
                                                                                className="form-control form-control-textarea"
                                                                                id={form + formfield.field}
                                                                                name={formfield.field} autoComplete="off"
                                                                                placeholder={`Enter ${formfield.text}`}

                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                            else if (formfield.type === 'timepicker') {
                                                                let value = getCurrentState().formData[formfield.field] || ''
                                                                return (
                                                                    <>
                                                                        <>
                                                                            {!getCurrentState().formData.tab == 0 &&
                                                                                <>
                                                                                    <div className="col-12">
                                                                                        <p className='report-or-border mb-2'>Or</p>
                                                                                    </div>
                                                                                </>
                                                                            }
                                                                        </>
                                                                        <div className={formfield.gridsize} key={`${fieldindex}`}>
                                                                            <div className="form-group validate-input">
                                                                                <label className="guest-request-maintenance-lable">{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}</label>
                                                                                <TimePickerRsuite
                                                                                    cleanable={formfield.cleanable}
                                                                                    className="form-control p-0 has-val"
                                                                                    placeholder={`Enter ${formfield.text}`}
                                                                                    format={IISMethods.getTimeFormatByProperty()}
                                                                                    id={form + formfield.field}
                                                                                    name={formfield.field}
                                                                                    value={value ? IISMethods.GetISOtoTZtime(value) : ''}
                                                                                    // value={value ? new Date(value) : null}
                                                                                    // type="text"
                                                                                    ranges={[]}
                                                                                    showMeridian
                                                                                    onChange={(e) => props.handleFormData(formfield.type, formfield.field, e)}
                                                                                />

                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                            else if (formfield.type === 'datetimepicker') {
                                                                let value = getCurrentState().formData[formfield.field] || ''
                                                                let options = {
                                                                    disabledDate: (date) => {
                                                                        let fromdate = IISMethods.GetDateTimefromISOString(IISMethods.GetTZdatetime(date).toISOString())
                                                                        if (formfield.disablefromdate) {
                                                                            let selectedDate = IISMethods.GetDateTimefromISOString(formfield.disablefromdate)
                                                                            selectedDate.setDate(selectedDate.getDate())
                                                                            return isBefore(date, selectedDate)
                                                                        }
                                                                        else if (formfield.disablefromfield) {
                                                                            let selectedDate = IISMethods.GetDateTimefromISOString(getCurrentState().formData[formfield.disablefromfield])
                                                                            selectedDate.setDate(selectedDate.getDate() - 1)
                                                                            return isBefore(date, selectedDate)
                                                                        }
                                                                    },
                                                                    editable: false
                                                                }
                                                                let currenttime = IISMethods.GetDateTimefromISOString(IISMethods.GetTZdatetime().toISOString())
                                                                return (
                                                                    <>
                                                                        <>
                                                                            {!getCurrentState().formData.tab == 0 &&
                                                                                <>
                                                                                    <div className="col-12">
                                                                                        <p className='report-or-border mb-2'>Or</p>
                                                                                    </div>
                                                                                </>
                                                                            }
                                                                        </>
                                                                        <div className={formfield.gridsize} key={`${fieldindex}`}>
                                                                            <div className="form-group validate-input">
                                                                                <label className="guest-request-maintenance-lable">{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}</label>
                                                                                <DateTimePicker
                                                                                    cleanable={formfield.cleanable}
                                                                                    className="form-control p-0 has-val"
                                                                                    placeholder={`Enter ${formfield.text}`}
                                                                                    format={IISMethods.getDateTimeFormatByProperty()}
                                                                                    id={form + formfield.field}
                                                                                    name={formfield.field}
                                                                                    value={value ? IISMethods.GetDateTimefromISOString(value) : ''}
                                                                                    // value={value ? new Date(value) : null}
                                                                                    // type="text"
                                                                                    ranges={[]}
                                                                                    showMeridian
                                                                                    onChange={(e) => props.handleFormData(formfield.type, formfield.field, e)}
                                                                                    editable={false}
                                                                                    {...options}
                                                                                // disabledHours={hour => hour < currenttime.getHours()}
                                                                                // disabledMinutes={minute => minute < currenttime.getMinutes()}
                                                                                />

                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                            else if (formfield.type === 'checkbox') {
                                                                return (
                                                                    <>
                                                                        <div className={`${formfield.gridsize}`} key={`${fieldindex}`}>
                                                                            <input
                                                                                className='invisible d-none'
                                                                                disabled={formfield.disabled} type="checkbox"
                                                                                id={form + formfield.field}
                                                                                checked={getCurrentState().formData[formfield.field]}
                                                                                name={formfield.field}
                                                                                onChange={(e) => props.handleFormData(formfield.type, formfield.field, e.target.checked)}
                                                                            />
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                }
                            </>
                        }
                        footer={
                            <>
                                <div className="col-12 mt-1">
                                    <button type="submit" className="vehicle-model-btn mb-3"
                                        onClick={(e) => {
                                            getCurrentState().loading === 0 && props.HouseKeepingAddButtonClick(e, 'housekeepingModal')
                                        }} >
                                        <Loader content={'Submit'} />
                                    </button>
                                </div>
                            </>
                        }
                    />
                }

            </>
        )
    } catch (error) {
        console.log(error)
        return <></>
    }

}