import React from 'react'
import { store } from '..'
import ModalRsuite from './Components/ModalRsuite'


const PropertyLayoutRightSidebar = (props) => {

    const getCurrentState = () => {
        return store.getState()['root']
    }

    return (
        <>
            {
                getCurrentState().modal["propertylayout"]
                &&
                <ModalRsuite
                    size={"xs"}
                    open={getCurrentState().modal['propertylayout']}
                    onClose={() => props.handleGrid(false, 'modal', 'propertylayout', 0)}

                    title={
                        <div className="model-header icon">
                            <span className="closeicon">
                                <span className='ml-1'>
                                    {getCurrentState().formData.layouttitle}
                                </span>
                            </span>
                        </div>
                    }
                    body={
                        <div className="col-12">
                            {(getCurrentState().formData.layoutimage === '' || getCurrentState().formData.layoutimage === undefined) ? '' :
                                <>
                                    {/* <span className='d-block text-format mb-2'>Map</span> */}
                                    <div className='row'>
                                        <div className='col-12 text-center'>
                                            <img className='map-img' src={getCurrentState().formData.layoutimage} />
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    }
                />
            }
        </>
    )
}

export default PropertyLayoutRightSidebar