import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { setProps } from '../redux/actions'
import PromotionsController from '../Controller/PromotionsController'

const Promotions = (props) => {
    useEffect(() => {
        async function fetchMyAPI() {
            await props.setProps({
                rightSidebarFormData: []
            })
        }

        fetchMyAPI()
    }, [])
    return (
        <PromotionsController />
    )
}

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    setProps: (payload) => dispatch(setProps(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Promotions)