import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Config, IISMethods, allowdedImage } from '../config/IISMethods'
import { setProps } from '../redux/actions'
import { store } from '..'
import HomeController from '../Controller/HomeController'
import Mail from '../assets/images/Eamil G.png'
import Print from '../assets/images/Print G.png'
import Receipt from '../assets/images/No Receipt G.png'
import Now from '../assets/images/time1.svg'
import Later from '../assets/images/Later.svg'
import DND from '../assets/images/DND.svg'

const Home = (props) => {
    return (
        <HomeController masterjson={masterjson} />
    )
}

const mapStateToProps = state => ({
    ...state
})
const mapDispatchToProps = dispatch => ({
    setProps: (payload) => dispatch(setProps(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)

const masterjson = (key) => {
    // const getCurrentState = () => store.getState()
    const getCurrentState = () => store.getState()['root']

    switch (key) {

        case 'vehiclejson':
            return [
                {
                    rightsidebarsize: Config.getModalsizeclasses()['sm'],
                    pagename: 'dynamicbutton',
                    formname: 'Dynamic Button',
                    apipath: 'mobilecheckin/update',
                    alias: 'dynamicbutton',
                    formfields: [
                        //0
                        {
                            'field': 'vehiclenumberplate',
                            'text': 'Licence Plate No.',
                            'type': Config.getHtmlcontorls()['input-text'],
                            'disabled': false,
                            'defaultvisibility': true,
                            'required': false,
                            'gridsize': Config.getGridclasses()['col-12'],
                        },
                        //1
                        {
                            'field': 'vehiclemakemodel',
                            'text': 'Vehicle Make And Model',
                            'type': Config.getHtmlcontorls()['input-text'],
                            'disabled': false,
                            'defaultvisibility': true,
                            'required': false,
                            'gridsize': Config.getGridclasses()['col-12'],
                        },
                        //2
                        {
                            'field': 'vehicleinformations',
                            'text': 'Vehicle Information',
                            'type': Config.getHtmlcontorls()['table'],
                            'disabled': false,
                            'defaultvisibility': true,
                            'required': false,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'tablefield': ['vehiclenumberplate', 'vehiclemakemodel'],
                        },
                        //3
                        {
                            'field': 'vehicleimages',
                            'text': 'Capture your vehicle image with licence plate',
                            'type': Config.getHtmlcontorls()['image'],
                            'disabled': false,
                            'defaultvisibility': true,
                            'required': false,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'filetypes': allowdedImage,
                            'multiple': true
                        },
                    ]
                }
            ]

        case 'vehiclerating':
            return [
                {
                    rightsidebarsize: Config.getModalsizeclasses()['sm'],
                    pagename: 'vehiclerating',
                    formname: 'vehicle rating',
                    apipath: 'guestexperience/reservation/add',
                    alias: 'vehiclerating',
                    formfields: [
                        //0
                        {
                            'field': 'reservationcheckinrating',
                            'text': 'stars',
                            'type': Config.getHtmlcontorls()['dropdown'],
                            'disabled': false,
                            'defaultvisibility': true,
                            'cleanable': true,
                            'required': true,
                            'gridsize': Config.getGridclasses()['col-12'] + ' ' + Config.getGridclasses()['col-md-4'],
                            'apipath': 'masters/ratings',
                            'masterdata': 'checkinrating',
                            'masterdatafield': 'rating',
                            'formdatafield': 'rating',
                            'istablefield': 1,
                            'staticfilter': {
                                'propertyid': getCurrentState().reservationDetails.propertyid,
                                'ratingcategoryid': Config.ratingcategoryid.checkinratings
                            },
                        },
                        //1
                        {
                            'field': 'checkinsuggestion',
                            'text': 'Suggestion',
                            'type': Config.getHtmlcontorls()['input-textarea'],
                            'disabled': false,
                            'cleanable': true,
                            'defaultvisibility': true,
                            'required': false,
                            'gridsize': Config.getGridclasses()['col-12'],
                        },
                    ]
                }
            ]

        case 'housekeepingtwo':
            return [
                {
                    rightsidebarsize: Config.getModalsizeclasses()['sm'],
                    pagename: 'house keeping',
                    formname: 'House Keeping',
                    apipath: 'task/reminder/add',
                    alias: 'housekeepingtwo',
                    formfields: [
                        //0
                        {
                            'field': 'tab',
                            'text': 'tab',
                            'type': Config.getHtmlcontorls()['radio'],
                            'disabled': false,
                            'defaultvisibility': true,
                            'required': true,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'resetfield': ['hours', 'minutes', 'notes', 'datetime'],
                            'defaultselected': 1,
                            "defaultvalue" : 0,
                            'radiooptions': [{
                                'icon': Now,
                                'label': 'Now',
                                'value': 0,
                            },
                            {
                                'icon': Later,
                                'label': 'Later',
                                'value': 1
                            },
                            {
                                'icon': DND,
                                'label': 'DND',
                                'value': 2,
                            }]
                        },
                        //1
                        {
                            'field': 'hours',
                            'text': 'Hours',
                            'type': Config.getHtmlcontorls()['number-input'],
                            'disabled': false,
                            'defaultvisibility': false,
                            'required': false,
                            'gridsize': Config.getGridclasses()['col-6'],
                            'condition': [
                                {
                                    'field': 'tab',
                                    'onvalue': 1
                                },
                                {
                                    'field': 'tab',
                                    'onvalue': 2
                                }
                            ]
                        },
                        //2
                        {
                            'field': 'minutes',
                            'text': 'Minutes',
                            'type': Config.getHtmlcontorls()['number-input'],
                            'disabled': false,
                            'defaultvisibility': false,
                            'required': false,
                            'gridsize': Config.getGridclasses()['col-6'],
                            'condition': [
                                {
                                    'field': 'tab',
                                    'onvalue': 1
                                },
                                {
                                    'field': 'tab',
                                    'onvalue': 2
                                }
                            ]
                        },

                        {
                            'field': 'datetime',
                            'text': 'Time',
                            'type': Config.getHtmlcontorls()['datetimepicker'],
                            'disabled': false,
                            'defaultvisibility': false,
                            'required': false,
                            'gridsize': Config.getGridclasses()['col-12'],
                            // 'defaultvalue': IISMethods.GetDT().toISOString(),
                            'cleanable': false,
                            'condition': [
                                {
                                    'field': 'tab',
                                    'onvalue': 1
                                }
                            ],
                            'disablefromdate': IISMethods.GetDateTimefromISOString(IISMethods.GetTZdatetime().toISOString())
                        },
                        //4
                        {
                            'field': 'revisittime',
                            'text': 'Time',
                            'type': Config.getHtmlcontorls()['datetimepicker'],
                            'disabled': false,
                            'defaultvisibility': false,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'cleanable': false,
                            'required': false,
                            'condition': [
                                {
                                    'field': 'tab',
                                    'onvalue': 2
                                }
                            ],
                            'disablefromdate': IISMethods.GetDateTimefromISOString(IISMethods.GetTZdatetime().toISOString())
                        },
                        //5
                        {
                            'field': 'notes',
                            'text': 'Please write here..',
                            'type': Config.getHtmlcontorls()['input-textarea'],
                            'disabled': false,
                            'defaultvisibility': false,
                            'required': false,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'condition': [
                                {
                                    'field': 'tab',
                                    'onvalue': 0
                                },
                                {
                                    'field': 'tab',
                                    'onvalue': 1
                                }
                            ]
                        },
                        //6
                        {
                            'field': 'flag',
                            'text': 'flag.',
                            'type': Config.getHtmlcontorls()['checkbox'],
                            'disabled': false,
                            'required': false,
                            'defaultvisibility': false,
                            'defaultvalue': 1,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'condition': [
                                {
                                    'field': 'tab',
                                    'onvalue': 2
                                }
                            ]
                        },
                        //7
                        {
                            'field': 'isdnd',
                            'text': 'isdnd.',
                            'type': Config.getHtmlcontorls()['checkbox'],
                            'disabled': false,
                            'required': false,
                            'defaultvisibility': false,
                            'defaultvalue': 1,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'condition': [
                                {
                                    'field': 'tab',
                                    'onvalue': 2
                                }
                            ]
                        },

                        {
                            'field': 'active',
                            'text': 'active',
                            'type': Config.getHtmlcontorls()['checkbox'],
                            'disabled': false,
                            'required': false,
                            'defaultvisibility': false,
                            'defaultvalue': 1,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'condition': [
                                {
                                    'field': 'tab',
                                    'onvalue': 2
                                }

                            ]
                        },

                        {
                            'field': 'iscompleted',
                            'text': 'iscompleted',
                            'type': Config.getHtmlcontorls()['checkbox'],
                            'disabled': false,
                            'required': false,
                            'defaultvisibility': false,
                            'defaultvalue': 0,
                            'gridsize': Config.getGridclasses()['col-12'],
                        },
                    ]
                }
            ]

        case 'housekeepingrating':
            return [
                {
                    rightsidebarsize: Config.getModalsizeclasses()['sm'],
                    pagename: 'housekeepingrating',
                    formname: 'House Keeping Rating',
                    apipath: 'guestexperience/taskguestrating/add',
                    alias: 'housekeepingrating',
                    formfields: [

                        //0
                        {
                            'field': 'taskguestrating',
                            'text': 'stars',
                            'type': Config.getHtmlcontorls()['dropdown'],
                            'disabled': false,
                            'defaultvisibility': true,
                            'cleanable': true,
                            'required': true,
                            'gridsize': Config.getGridclasses()['col-12'] + ' ' + Config.getGridclasses()['col-md-4'],
                            'apipath': 'masters/ratings',
                            'masterdata': 'housekeeprating',
                            'masterdatafield': 'rating',
                            'formdatafield': 'rating',
                            'istablefield': 1,
                            'staticfilter': {
                                'propertyid': getCurrentState().reservationDetails.propertyid,
                                'ratingcategoryid': Config.ratingcategoryid.housekeepingratings
                            },
                        },
                        //1
                        {
                            'field': 'suggestion',
                            'text': 'Suggestion',
                            'type': Config.getHtmlcontorls()['input-textarea'],
                            'disabled': false,
                            'cleanable': true,
                            'defaultvisibility': true,
                            'required': false,
                            'gridsize': Config.getGridclasses()['col-12'],
                        },

                    ]
                }
            ]

        case 'housekeeping':
            return [
                {
                    rightsidebarsize: Config.getModalsizeclasses()['sm'],
                    pagename: 'second house keeping',
                    formname: 'Second House Keeping',
                    apipath: 'complaint/add',
                    alias: 'housekeeping',
                    formfields: [

                        {
                            'field': 'problemid',
                            'text': 'problem',
                            'type': Config.getHtmlcontorls()['radio'],
                            'disabled': false,
                            'defaultvisibility': true,
                            'required': true,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'apipath': 'guestexperience/problem',
                            'masterdata': 'problem',
                            'masterdatafield': 'name',
                            'formdatafield': 'problem',
                            'searchable': false,
                            'defaultselected': 1,
                            'resetfield': ['description']
                        },
                        {
                            'field': 'propertyid',
                            'text': 'propertyid',
                            'type': Config.getHtmlcontorls()['input-text'],
                            'disabled': false,
                            'defaultvisibility': true,
                            'required': false,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'defaultvalue': getCurrentState().reservationDetails?.propertyid
                        },
                        {
                            'field': 'propertyname',
                            'text': 'propertyname',
                            'type': Config.getHtmlcontorls()['input-text'],
                            'disabled': false,
                            'defaultvisibility': true,
                            'required': false,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'defaultvalue': getCurrentState().reservationDetails?.propertyname

                        },
                        {
                            'field': 'assignmentforid',
                            'text': 'assignmentforid',
                            'type': Config.getHtmlcontorls()['input-text'],
                            'disabled': false,
                            'defaultvisibility': false,
                            'required': false,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'defaultvalue': Config.assignmentfor.room

                        },
                        {
                            'field': 'assignmentfor',
                            'text': 'assignmentfor',
                            'type': Config.getHtmlcontorls()['input-text'],
                            'disabled': false,
                            'defaultvisibility': false,
                            'required': false,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'defaultvalue': "Room"

                        },
                        {
                            'field': 'buildingid',
                            'text': 'buildingid',
                            'type': Config.getHtmlcontorls()['input-text'],
                            'disabled': false,
                            'defaultvisibility': false,
                            'required': false,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'defaultvalue': getCurrentState().reservationDetails?.buildingid

                        },
                        {
                            'field': 'buildingname',
                            'text': 'buildingname',
                            'type': Config.getHtmlcontorls()['input-text'],
                            'disabled': false,
                            'defaultvisibility': false,
                            'required': false,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'defaultvalue': getCurrentState().reservationDetails?.buildingname

                        },
                        {
                            'field': 'wingname',
                            'text': 'wingname',
                            'type': Config.getHtmlcontorls()['input-text'],
                            'disabled': false,
                            'defaultvisibility': false,
                            'required': false,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'defaultvalue': getCurrentState().reservationDetails?.wingname

                        },
                        {
                            'field': 'wingid',
                            'text': 'wingid',
                            'type': Config.getHtmlcontorls()['input-text'],
                            'disabled': false,
                            'defaultvisibility': false,
                            'required': false,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'defaultvalue': getCurrentState().reservationDetails?.wingid

                        },
                        {
                            'field': 'wingid',
                            'text': 'wingid',
                            'type': Config.getHtmlcontorls()['input-text'],
                            'disabled': false,
                            'defaultvisibility': false,
                            'required': false,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'defaultvalue': getCurrentState().reservationDetails?.wingid

                        },
                        {
                            'field': 'roomdetails',
                            'text': 'roomdetails',
                            'type': Config.getHtmlcontorls()['table'],
                            'disabled': false,
                            'defaultvisibility': true,
                            'required': false,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'tablefield': ['roomnoid', 'roomno', 'roomtypeid', 'roomtype'],
                        },
                        {
                            'field': 'isguestcomplaint',
                            'text': 'floorid',
                            'type': Config.getHtmlcontorls()['input-text'],
                            'disabled': false,
                            'defaultvisibility': false,
                            'required': false,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'defaultvalue': 1
                        },
                        {
                            'field': 'areadetails',
                            'text': 'areadetails',
                            'type': Config.getHtmlcontorls()['multipleselectdropdown'],
                            'disabled': false,
                            'required': true,
                            'defaultvisibility': false,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'searchable': false,
                            'istablefield': true,
                            "defaultvalue": 0

                        },
                        {
                            'field': 'productdata',
                            'text': 'productdata',
                            'type': Config.getHtmlcontorls()['multipleselectdropdown'],
                            'disabled': false,
                            'required': true,
                            'defaultvisibility': false,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'searchable': false,
                            'istablefield': true,
                            "defaultvalue": 0

                        },
                        {
                            'field': 'priorityid',
                            'text': 'priorityid',
                            'type': Config.getHtmlcontorls()['input-text'],
                            'disabled': false,
                            'defaultvisibility': false,
                            'required': false,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'defaultvalue': "6303126355c8ad547b0f809c"

                        },
                        {
                            'field': 'priority',
                            'text': 'priority',
                            'type': Config.getHtmlcontorls()['input-text'],
                            'disabled': false,
                            'defaultvisibility': false,
                            'required': false,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'defaultvalue': "Low"

                        },
                        {
                            'field': 'guestfirstname',
                            'text': 'guestfirstname',
                            'type': Config.getHtmlcontorls()['input-text'],
                            'disabled': false,
                            'defaultvisibility': false,
                            'required': false,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'defaultvalue': getCurrentState().reservationDetails?.firstname

                        },
                        {
                            'field': 'guestlastname',
                            'text': 'guestlastname',
                            'type': Config.getHtmlcontorls()['input-text'],
                            'disabled': false,
                            'defaultvisibility': false,
                            'required': false,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'defaultvalue': getCurrentState().reservationDetails?.lastname

                        },
                        {
                            'field': 'guestphoneno',
                            'text': 'guestphoneno',
                            'type': Config.getHtmlcontorls()['input-text'],
                            'disabled': false,
                            'defaultvisibility': false,
                            'required': false,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'defaultvalue': getCurrentState().reservationDetails?.guestphoneno

                        },
                        {
                            'field': 'guestemail',
                            'text': 'guestemail',
                            'type': Config.getHtmlcontorls()['input-text'],
                            'disabled': false,
                            'defaultvisibility': false,
                            'required': false,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'defaultvalue': getCurrentState().reservationDetails?.guestemail
                        },
                        {
                            'field': 'description',
                            'text': 'Comment',
                            'type': Config.getHtmlcontorls()['input-textarea'],
                            'disabled': false,
                            'required': true,
                            'defaultvisibility': true,
                            'gridsize': Config.getGridclasses()['col-12'],
                        },
                        //charmi(10-08-2023 10:11 GTB)
                        {
                            'field': 'reservationid',
                            'text': 'reservationid',
                            'type': Config.getHtmlcontorls()['input-text'],
                            'disabled': false,
                            'defaultvisibility': false,
                            'required': false,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'defaultvalue': getCurrentState().reservationDetails?._id
                        },
                    ]
                }
            ]

        case 'checkout':
            return [
                {
                    rightsidebarsize: Config.getModalsizeclasses()['sm'],
                    pagename: 'checkout',
                    formname: 'Check Out',
                    apipath: 'task/add',
                    alias: 'checkout',
                    formfields: [
                        //0
                        {
                            'field': 'foliotype',
                            'text': 'foliotype',
                            'type': Config.getHtmlcontorls()['radio'],
                            'disabled': false,
                            'defaultvisibility': true,
                            'required': true,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'radiooptions': [
                                {
                                    'icon': Mail,
                                    'label': 'email',
                                    'value': 1
                                },
                                {
                                    'icon': Print,
                                    'label': 'Print',
                                    'folioemail': '',
                                    'value': 2
                                },
                                {
                                    'icon': Receipt,
                                    'label': 'No Receipt',
                                    'folioemail': '',
                                    'value': 3
                                }
                            ]
                        },
                        //1
                        {
                            'field': 'folioemail',
                            'text': 'Enter Your Email',
                            'type': Config.getHtmlcontorls()['input-text'],
                            'disabled': false,
                            'required': true,
                            'defaultvisibility': false,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'regex': Config.getRegex()['email'],
                            'condition': [
                                {
                                    'field': 'foliotype',
                                    'onvalue': 1
                                }

                            ]
                        },

                    ]
                }
            ]

        case 'checkoutrating':
            return [
                {
                    rightsidebarsize: Config.getModalsizeclasses()['sm'],
                    pagename: 'checkoutrating',
                    formname: 'checkout rating',
                    apipath: 'guestexperience/reservation/add',
                    formfields: [
                        //0
                        {
                            'field': 'reservationcheckoutrating',
                            'text': 'stars',
                            'type': Config.getHtmlcontorls()['dropdown'],
                            'disabled': false,
                            'defaultvisibility': true,
                            'cleanable': true,
                            'required': true,
                            'gridsize': Config.getGridclasses()['col-12'] + ' ' + Config.getGridclasses()['col-md-4'],
                            'apipath': 'masters/ratings',
                            'masterdata': 'checkouttrating',
                            'masterdatafield': 'rating',
                            'formdatafield': 'rating',
                            'istablefield': 1,
                            'staticfilter': {
                                'propertyid': getCurrentState().reservationDetails.propertyid,
                                'ratingcategoryid': Config.ratingcategoryid.checkoutratings
                            },
                        },
                        //1
                        {
                            'field': 'checkoutsuggestion',
                            'text': 'Suggestion',
                            'type': Config.getHtmlcontorls()['input-textarea'],
                            'disabled': false,
                            'cleanable': true,
                            'defaultvisibility': true,
                            'required': false,
                            'gridsize': Config.getGridclasses()['col-12'],
                        },
                    ]
                }
            ]

        case 'latecheckout':
            return [
                {
                    rightsidebarsize: Config.getModalsizeclasses()['sm'],
                    pagename: 'latecheckout',
                    formname: 'Late Check Out',
                    apipath: 'task/add',
                    alias: 'latecheckout',
                    formfields: [
                        //0
                        {
                            'field': 'time',
                            'text': 'When do you plan to check-out ?',
                            'type': Config.getHtmlcontorls()['dropdown'],
                            'disabled': false,
                            'required': true,
                            'defaultvisibility': true,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'apipath': 'property/timing/checkout',
                            'masterdata': 'checkouttimefrom',
                            'masterdatafield': 'checkouttimefrom',
                            'formdatafield': 'latecheckouttime',
                            'searchable': false,
                            'cleanable': false,
                            'staticfilter': { 'propertyid': getCurrentState().reservationDetails.propertyid, 'roomtypeid': getCurrentState().reservationDetails.roomtypeid },
                            'extrafield': ['charge', 'checkouttimeto']
                        },
                        {
                            'field': 'latecheckoutcharges',
                            'text': 'Late Check-Out Charges',
                            'type': Config.getHtmlcontorls()['number-input'],
                            'disabled': false,
                            'defaultvisibility': true,
                            'disabled': true,
                            'gridsize': Config.getGridclasses()['col-12'],
                        },
                        // charmi(06-06-2023)(HV)(GTB)
                        // {
                        //     'field': 'subcategoryid',
                        //     'text': 'Subcategory',
                        //     'type': Config.getHtmlcontorls()['dropdown'],
                        //     'disabled': false,
                        //     'required': true,
                        //     'defaultvisibility': false,
                        //     'gridsize': Config.getGridclasses()['col-12'],
                        //     'apipath': 'masters/tasksubcategory',
                        //     'masterdata': 'subcategory',
                        //     'masterdatafield': 'tasksubcategory',
                        //     'formdatafield': 'subcategory',
                        //     'staticfilter': { 'propertyid': getCurrentState().reservationDetails.propertyid, 'taskcategoryid': Config.categoryid.frontdesk },
                        // },
                        // charmi(06-06-2023)(HV)(GTB)
                    ]
                }
            ]

        case 'extendstay':
            return [
                {
                    rightsidebarsize: Config.getModalsizeclasses()['sm'],
                    pagename: 'extendstay',
                    formname: 'Extend Stay',
                    apipath: 'task/add',
                    alias: 'extendstay',
                    formfields: [
                        //0
                        {
                            'field': 'checkoutdate',
                            'text': 'Date',
                            'type': Config.getHtmlcontorls()['datepicker'],
                            'defaultvisibility': true,
                            'required': true,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'cleanable': false,
                        },
                    ]
                }
            ]

        default:
            return []
    }
}