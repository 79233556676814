// import React from 'react'
// import { Helmet } from 'react-helmet'
// import favicon from './assets/images/favicon.ico'
// // import Menifest from './manifest.json'
// // import MenifestIOS from './manifest_ios.json'

// export default function Head() {
//     const getOS = () => {
//         let userAgent = navigator.userAgent,
//             platform = navigator.platform,
//             macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
//             windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
//             iosPlatforms = ["iPhone", "iPad", "iPod"],
//             os = null;
    
//         if (macosPlatforms.indexOf(platform) !== -1) {
//             os = "Mac OS";
//         } else if (iosPlatforms.indexOf(platform) !== -1) {
//             os = "iOS";
//         } else if (windowsPlatforms.indexOf(platform) !== -1) {
//             os = "Windows";
//         } else if (/Android/.test(userAgent)) {
//             os = "Android";
//         } else if (!os && /Linux/.test(platform)) {
//             os = "Linux";
//         }
    
//         return os;
//     };

//     return (
//         <>
//             <Helmet>
//                 <meta charset="utf-8" />
//                 <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, shrink-to-fit=no" />
//                 <meta http-equiv="X-UA-Compatible" content="ie=edge" />
//                 <meta name="keywords" content="Nextap : Guest Experience" />
//                 <meta name="author" content="Nextap : Guest Experience" />
//                 <meta name="robots" content="Nextap : Guest Experience" />
//                 <meta name="description" content="Nextap : Guest Experience" />
//                 <meta property="og:title" content="" />
//                 <meta property="og:description" content="Nextap : Guest Experience" />
//                 <meta property="og:image" content="assets/images/favicon.png" />
//                 <link rel="icon" type="image/x-icon" href={favicon} />
//                 <title>Nextap : Guest Experience</title>

//                 <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons"></link>
//                 <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons+Round"></link>
//                 <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons+Outlined"></link>
//                 <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons+Sharp"></link>
//                 <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons+Two%20Tone"></link>
//                 <link rel='stylesheet' href='https://cdn-uicons.flaticon.com/uicons-regular-rounded/css/uicons-regular-rounded.css'></link>
//                 <link rel="preconnect" href="https://fonts.googleapis.com"></link>
//                 <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin></link>
//                 <link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet"></link>
           
//             </Helmet>
//         </>
//     )
// }
import React from 'react'
import { Helmet } from 'react-helmet'
import favicon from './assets/images/favicon.ico'
// import Menifest from './manifest.json'
// import MenifestIOS from './manifest_ios.json'

export default function Head() {
    const getOS = () => {
        let userAgent = navigator.userAgent,
            platform = navigator.platform,
            macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
            windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
            iosPlatforms = ["iPhone", "iPad", "iPod"],
            os = null;
    
        if (macosPlatforms.indexOf(platform) !== -1) {
            os = "Mac OS";
        } else if (iosPlatforms.indexOf(platform) !== -1) {
            os = "iOS";
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
            os = "Windows";
        } else if (/Android/.test(userAgent)) {
            os = "Android";
        } else if (!os && /Linux/.test(platform)) {
            os = "Linux";
        }
    
        return os;
    };

    // const msclarity = () => {
    //     (function (c, l, a, r, i, t, y) {
    //         c[a] =
    //             c[a] ||
    //             function () {
    //                 (c[a].q = c[a].q || []).push(arguments);
    //             };
    //         t = l.createElement(r);
    //         t.async = 1;
    //         t.src = "https://www.clarity.ms/tag/" + i;
    //         y = l.getElementsByTagName(r)[0];
    //         y.parentNode.insertBefore(t, y);
    //     })(window, document, "clarity", "script", 'k7rz8yntww');
    // } 

    return (
        <>
            <Helmet>
                <meta charset="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, shrink-to-fit=no" />
                <meta http-equiv="X-UA-Compatible" content="ie=edge" />
                <meta name="keywords" content="Nextap : Guest Experience" />
                <meta name="author" content="Nextap : Guest Experience" />
                <meta name="robots" content="Nextap : Guest Experience" />
                <meta name="description" content="Nextap : Guest Experience" />
                <meta property="og:title" content="" />
                <meta property="og:description" content="Nextap : Guest Experience" />
                <meta property="og:image" content="assets/images/favicon.png" />
                <link rel="icon" type="image/x-icon" href={favicon} />
                <title>NexTap : Guest Experience</title>

                <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons"></link>
                <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons+Round"></link>
                <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons+Outlined"></link>
                <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons+Sharp"></link>
                <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons+Two%20Tone"></link>
                <link rel='stylesheet' href='https://cdn-uicons.flaticon.com/uicons-regular-rounded/css/uicons-regular-rounded.css'></link>
                <link rel="preconnect" href="https://fonts.googleapis.com"></link>
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin></link>
                <link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet"></link>
                {/* <script type='text/javascript'>{msclarity()}</script> */}
            </Helmet>
        </>
    )
}

