import React, { useState } from 'react'
import { connect } from 'react-redux'
import 'rsuite/dist/rsuite.css'
import { setProps } from '../redux/actions'
import MaintenanceRightsidebar from './MaintenanceRightsidebar'
import MaintenanceProgress from './MaintenanceProgress'
import BackHeader from '../BackHeader'
import Footer from '../Footer'
import MaintenanceList from './MaintenanceList'
import { useLocation, useNavigate } from 'react-router-dom'
import Progress from './Components/Progress'
import { store } from '..'

let initialstate = 'maintenhome'

const MaintenanceView = (props) => {
    const {page, setPage} = props

    const location = useLocation()

    const getCurrentState = () => {
        return store.getState()['root']
    }
    // srushti 27-2-23
    return (
        <> 
            {/* back history not static link JENIL 13/9/2023 10:47 PM */}
            <BackHeader isHistoryBack={page === 'maintenhome' ? true : false} linkto={page === 'maintenhome' ? '/mystay' : location.pathname} backtopagename='Maintenance Request' onClick={() => setPage(initialstate)}  handleFilterData={props.handleFilterData}  handleApplyFilterButtonClick={props.handleApplyFilterButtonClick} handleSerachClose={props.handleSerachClose} /> {/* keval 7-4-2023 */}
            {
                page === "maintenhome" ?
                    <MaintenanceList
                        setSecondaryFormData={props.setSecondaryFormData}
                        setFormData={props.setFormData}
                        handleGrid={props.handleGrid}
                        handleScroll={props.handleScroll}
                        reopenData={props.reopenData}
                        setReopen={props.setReopen}
                        setPage={setPage}
                        handleAddButtonClick={props.handleAddButtonClick}
                        setFilterData={props.setFilterData}
                        handleApplyFilterData={props.handleApplyFilterData}
                        getList={props.getList}
                    />
                    : page === "maintendemo" ?
                        <Progress 
                            name = 'Maintenance In Progress'
                            pagename = 'maintenance'
                            masterdatastate = {getCurrentState().secondarySourceMasterData}
                            handleGrid={props.handleGrid}
                            handleScroll={props.handleScroll}
                            setFormData={props.setFormData}
                            handleFormData={props.handleFormData}
                    />
                        // <MaintenanceProgress
                        //     handleFormData={props.handleFormData}
                        //     setFormData={props.setFormData}
                        //     handleScroll={props.handleScroll}
                        //     handleGrid={props.handleGrid}
                        // />
                        :
                        ""
            }
            <MaintenanceRightsidebar
                handleFormData={props.handleFormData}
                handleGrid={props.handleGrid}
                setFormData={props.setFormData}
                handleAddButtonClick={props.handleAddButtonClick}
                handleScroll={props.handleScroll}
                reopenData={props.reopenData}
                setReopen={props.setReopen}
                handleRemoveFilterData={props.handleRemoveFilterData}
                setFilterData={props.setFilterData}
                handleApplyFilterData={props.handleApplyFilterData}
                handleFilterData={props.handleFilterData}
                getList={props.getList}
                handleAddFilterButtonClick={props.handleAddFilterButtonClick}
                getIssueList={props.getIssueList}
                maintenancetloder={props.maintenancetloder}
            />
            <Footer />
        </>
    )



}


const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    setProps: (payload) => dispatch(setProps(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceView)


// const MaintenanceList = (props) => {

//     return (
//         <>
//             <div className="body-content" onScroll={props.handleScroll}>
//                 <div className="col-12" >
//                         {
//                             getCurrentState().data?.map((requestdata, index) => {
//                                 return (
//                                     <div className="request-box">
//                                         <div className="request-create mt-2 ml-2" key={index}>
//                                             <span className='d-inline-block'>
//                                             <img src={requestdata.taskstatussvg} />
//                                             </span>
//                                             <span className='d-inline-block ml-2'>
//                                             <span className='d-block'>{requestdata.taskstatus}</span>
//                                             <p className='d-block'>{IISMethods.dateToFormat(requestdata.startdate)} | {IISMethods.timeToFormat(requestdata.startdate)}</p>
//                                             </span>
//                                         </div>
                        
//                                         <div className="hr mt-3">

//                                         </div>
//                                         <div className="row">
//                                             {requestdata.images[0]?.image.length > 0 &&
//                                                 <div className="col-3  ml-3 mt-3 mainten-img" onClick={() => props.handleGrid(requestdata._id, 'modal', 'multipalimagemodal', 1)}>
//                                                     <img src={requestdata.images[0]?.image} />
//                                                 </div>
//                                             }
//                                             <div className="col-8 mt-3">
//                                                 {/* <span>{item.service_or_product}</span> */}
//                                                 <span className="mainten-req-sentence">{requestdata.maintenproductdesc}</span>
//                                                 <p className="mainten-req-sentence-desc">
//                                                     {requestdata.comment !== '' &&
//                                                         <span>({requestdata.comment})</span>
//                                                     }
//                                                 </p>
//                                             </div>
//                                         </div>

//                                         {requestdata.iscompleted === 0 &&
//                                             <div className="with-req">
//                                                 <div className="withdraw" onClick={() => { props.setFormData(requestdata._id, '', 'withdrawmodal'); props.handleGrid(true, 'modal', 'withdrawmodal', 1); }} >
//                                                     <span>WITHDRAW</span>
//                                                 </div>
//                                                 <div className="track" onClick={() => { props.setSecondaryFormData(requestdata._id); props.setPage("maintendemo"); }}>
//                                                     <span>TRACK</span>
//                                                 </div>
//                                             </div>
//                                         }

//                                         {requestdata.iscompleted === 1 &&
//                                             <div className="reopen"
//                                                 onClick={() => { props.setFormData(requestdata._id, '', 'reopenmodal'); props.handleGrid(true, 'modal', 'reopenmodal', 1); }}>
//                                                 <span>REOPEN</span>
//                                             </div>
//                                         }
//                                     </div>
//                                 )
//                             })
//                         }
//                     </div>
//                 </div>

          
//             {/* withdraw modal 3-4-23 */}
//             {
//                 getCurrentState().modal['withdrawmodal']
//                 &&

//                 <ModalRsuite
//                     size={"xs"}
//                     open={getCurrentState().modal['withdrawmodal']}
//                     onClose={() => props.handleGrid(false, 'modal', 'withdrawmodal', 0)}

//                     body={
//                         <>
//                             <div className="text-center">
//                                 <img src={Withdraw} /><br />
//                             </div>
//                             <div className="mt-3 text-center">
//                                 <span className="text-center">Are You Sure Withdraw ??</span>
//                             </div>
//                             <div className='col-12 btn-hover mt-3'>
//                                 <button type="submit" className="btn-yes-no m-2 ml-3 col-5 "
//                                     onClick={() => {
//                                         props.handleGrid(false, 'modal', 'withdrawmodal', 0)
//                                     }}
//                                 >No</button>

//                                 <button type="submit" className="btn-yes-no m-2  ml-3 col-5"
//                                     onClick={(e) => {
                                      
//                                         props.handleAddButtonClick(e, 'withdrawmodal')
//                                     }}
//                                 > Yes</button>
//                             </div>
//                         </>
//                     }
//                 />
//             }
//             {/* reopen modal 3-4-23 */}
//             {
//                 getCurrentState().modal['reopenmodal']
//                 &&
//                 <ModalRsuite
//                     size={"xs"}
//                     open={getCurrentState().modal['reopenmodal']}
//                     onClose={() => props.handleGrid(false, 'modal', 'reopenmodal', 0)}

//                     body={
//                         <>
//                             <div className="text-center">
//                                 <img src={Withdraw} /><br />
//                             </div>
//                             <div className="mt-3 text-center">
//                                 <span className="text-center">Are You Sure reopen ??</span>
//                             </div>
//                             <div className='col-12 btn-hover mt-3'>
//                                 <button type="submit" className="btn-yes-no m-2 ml-3 col-5 "
//                                     onClick={() => {
//                                         props.handleGrid(false, 'modal', 'reopenmodal', 0)
//                                     }}
//                                 >No</button>

//                                 <button type="submit" className="btn-yes-no m-2  ml-3 col-5"
//                                     onClick={(e) => {
                                        
//                                         props.handleAddButtonClick(e, 'reopenmodal')
//                                     }}
//                                 > Yes</button>
//                             </div>
//                         </>
//                     }
//                 />
//             }
//         </>
//     )
// }