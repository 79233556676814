import React, { Component } from "react";
import { CheckPicker } from "rsuite";
import { Config } from "../../config/IISMethods";

const MultipleSelectDropdown = (props) => {
    var masterdatalength = props?.data?.length ? props.data.length : 0;
    // try {
    //   masterdatalength = props.data.length
    // }
    // catch {
    //   masterdatalength = 0
    // }
    const mountRef = React.useRef(null);
    return (
        <div className='main-selectpicker' ref={mountRef}>
            <CheckPicker
                cleanable={props.cleanable}
                className={props.className}
                searchable={props.searchable || masterdatalength > 8}
                data={props.data || []}
                id={props.id}
                container={() => mountRef.current} //Anjali 24/7/2023
                name={props.name}
                value={props.value}
                disabled={props.disabled}
                onSelect={props.onSelect}
                placement={props.placement}
                placeholder={props.placeholder}
                onClean={props.onClean}
                onChange={props.onChange}
                defaultValue={props.defaultValue}
                //add class for select all menudata JENIL 30/6/2023 12:26 PM
                menuClassName={props.selectall && masterdatalength > 1 ? "selectall-menu-checkpicker" : ""}
                renderExtraFooter={() => {
                    return <>
                        {props.selectall && masterdatalength > 1
                            ? <div className="rs-checkpicker-footer multiple-filter-box">
                                <label className="checkbox mb-0">
                                    <input
                                        type="checkbox"
                                        className=""
                                        indeterminate={props.value?.length > 0 && props.value?.length < masterdatalength}
                                        checked={props.value?.length === masterdatalength}
                                        //  name={formfield.field}
                                        onChange={(e) => props.onChange(e.target.checked ? props.data.map(data => data.value) : [])}
                                    />
                                    <span className="text-14 font-weight-400 select-all-text">Select All</span>
                                    <span className={`checkmark rs-checkbox-inner ${props.value?.length === masterdatalength ? 'select-box-check-box' : ''}`}></span>
                                </label>
                            </div>
                            : <></>
                        }
                        {props.masterFormName
                            ? <a className="rs-dropdown-footer" onClick={props.onAdd}>
                                <i className="material-icons-round">add</i>
                                {`${Config.savesidebar} ${props.masterFormName}`}
                            </a>
                            : <></>
                        }
                    </>

                }
                }
            />
        </div>
    );
};

export default MultipleSelectDropdown;

MultipleSelectDropdown.props = {
    placement: "",
    placeholder: "Select",
    data: [],
};

                // props.masterFormName ? (
                //     <a className="rs-dropdown-footer" onClick={props.onAdd}>
                //         <i className="material-icons-round">add</i>
                //         {`${Config.savesidebar} ${props.masterFormName}`}
                //     </a>
                // ) : (
                //     <></>
                // )