import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Config, IISMethods, allowdedImage } from '../config/IISMethods'
import WifiController from '../Controller/WifiController'
import { setProps } from '../redux/actions'
import { store } from '../index'

const Wifi = (props) => {
    useEffect(() => {
        async function fetchMyAPI() {
            await props.setProps({
                rightSidebarFormData: []
            })
        }

        fetchMyAPI()
    }, [])
    return (
        <WifiController
            masterjson={masterjson}
        />
    )
}

const getCurrentState = () => {
    return store.getState()['root']
}

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    setProps: (payload) => dispatch(setProps(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Wifi)

const masterjson = (key) => {
    const reservationDetails = getCurrentState().reservationDetails
    switch (key) {
        case 'wifijson':
            return [
                {
                    formfields: [
                        // for wifi set all feild and json
                        //0
                        {
                            'field': 'wifisetting',
                            'text': 'Department',
                            'type': Config.getHtmlcontorls()['dropdown'],
                            'disabled': false,
                            'defaultvisibility': true,
                            'cleanable': true,
                            'required': true,
                            'gridsize': Config.getGridclasses()['col-12'] + ' ' + Config.getGridclasses()['col-md-4'],
                            'apipath': 'property/wifisetting',
                            'masterdata': 'wifisetting',
                            'masterdatafield': 'devicetype',
                            'formdatafield': 'devicetype',
                            'staticfilter': {
                                'propertyid': reservationDetails?.propertyid // srushti 29-3-2023
                            },
                        },
                        //1
                        {
                            'field': 'wifidetails',
                            'text': 'Break Type',
                            'type': Config.getHtmlcontorls()['dropdown'],
                            'disabled': false,
                            'defaultvisibility': true,
                            'required': true,
                            'gridsize': Config.getGridclasses()['col-12'] + ' ' + Config.getGridclasses()['col-md-4'],
                            'apipath': 'property/wifidetails',
                            'masterdata': 'wifidetails',
                            'masterdatafield': 'wifidetails',
                            'formdatafield': 'wifidetails',
                            'cleanable': true,
                            'staticfilter': {
                                'propertyid': reservationDetails?.propertyid // srushti 29-3-2023
                            },
                        },
                    ]
                }
            ]
        default:
            return []
    }
}