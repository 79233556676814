import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Config, IISMethods, allowdedImage } from '../config/IISMethods'
import GuestrequestController from '../Controller/GuestrequestController'
import { setProps } from '../redux/actions'
import { store } from '../index'

const GuestRequest = (props) => {
    useEffect(() => {
        async function fetchMyAPI() {
            await props.setProps({
                rightSidebarFormData: []
            })
        }
        fetchMyAPI()
    }, [])
    return (
        <GuestrequestController
            masterjson={masterjson}
            pagename={props.pagename}
        />
    )
}

const getCurrentState = () => {
    return store.getState()['root']
}

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    setProps: (payload) => dispatch(setProps(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(GuestRequest)

const masterjson = (key) => {
    let propertyid = getCurrentState().reservationDetails.propertyid
    switch (key) {
        case 'requestitem':
            return [
                {
                    rightsidebarsize: Config.getModalsizeclasses()['sm'],
                    pagename: 'requestitem',
                    formname: 'requestitem',
                    apipath: 'task/add',
                    alias: 'requestitem',
                    formfields: [
                        //0
                        {
                            'field': 'productsubcategoryid',
                            'text': 'Sub Category',
                            'type': Config.getHtmlcontorls()['dropdown'],
                            'disabled': false,
                            'required': true,
                            'cleanable': false,
                            'defaultvisibility': false,
                            'gridsize': Config.getGridclasses()['col-12'] + ' ' + Config.getGridclasses()['col-md-12'],
                            'apipath': 'product/productsubcategory',
                            'masterdata': 'productsubcategory',
                            'masterdatafield': 'productsubcategory',
                            'formdatafield': 'productsubcategory',
                            'searchable': false,
                            // 'onchangefill': ['productid'],
                            'parantstaticfilter': {'isrequestitem': 1, 'getotheropt': 1 }, // for pass filter outside pagination
                            'staticfilter': { 'isactive': 1, 'propertyid': propertyid },
                            'istablefield': true,
                        },
                        //1
                        {
                            'field': 'productid',
                            'text': 'Item',
                            'type': Config.getHtmlcontorls()['dropdown'],
                            'disabled': false,
                            'defaultvisibility': true,
                            'required': true,
                            'cleanable': false,
                            'apipath': 'product/productmaster',
                            'masterdata': 'product',
                            'masterdatafield': 'productname',
                            'formdatafield': 'product',
                            'gridsize': Config.getGridclasses()['col-12'] + ' ' + Config.getGridclasses()['col-md-12'],
                            'staticfilter': {
                                'isactive': 1,
                                'isrequestitem': 1,
                                // 'ismaintainable': 0,//charmi(27-07-2023 10:43)
                                'propertyid': propertyid
                            },
                            // 'dependentfilter': { 'productsubcategoryid': 'productsubcategoryid' },
                            'istablefield': true,
                            // 'masterdatadependancy': true,
                            'condition': {
                                'field': 'productsubcategoryid',
                                'onvalue': Config.othersubcategory
                            },
                            'groupwise': true,
                            'groupfield': 'productsubcategory',
                            'extrafield': ['productcategoryid', 'productcategory', 'productsubcategoryid', "productsubcategory", "image"],//,'description'
                            // 'extrafield': ['getotheropt'],
                        },
                        //2
                        {
                            'field': 'description',
                            'text': 'Description',
                            'type': Config.getHtmlcontorls()['input-textarea'],
                            'disabled': false,
                            'required': false,
                            'defaultvisibility': true,
                            'cleanable': true,
                            'gridsize': Config.getGridclasses()['col-md-12'],
                            'istablefield': true,
                            // 'condition': {
                            //     'field': 'getotheropt',
                            //     'onvalue': 1
                            // },
                        },
                        //3
                        {
                            'field': 'itemqty',
                            'text': 'Item Quantity',
                            'type': 'counter',
                            'disabled': true,
                            'required': false,
                            'istablefield': true,
                            'defaultvisibility': false,
                            'gridsize': Config.getGridclasses()['col-12'] + ' ' + Config.getGridclasses()['col-md-12'],
                            'condition': {
                                'field': 'productsubcategoryid',
                                'onvalue': Config.othersubcategory
                            },
                        },
                        //4
                        {
                            'field': 'products',
                            'text': 'table',
                            'type': Config.getHtmlcontorls()['table'],
                            'disabled': false,
                            'defaultvisibility': true,
                            'required': true,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'tablefield': ['Item Name', 'Quantity'],
                            'requiredfields': ['productsubcategoryid', 'productid']
                        },
                    ]
                }
            ]

        case 'requestrating':
            return [
                {
                    rightsidebarsize: Config.getModalsizeclasses()['sm'],
                    pagename: 'requestrating',
                    formname: 'request rating',
                    apipath: 'guestexperience/taskguestrating/add',

                    formfields: [
                        //0
                        {
                            'field': 'taskguestrating',
                            'text': 'stars',
                            'type': Config.getHtmlcontorls()['dropdown'],
                            'disabled': false,
                            'defaultvisibility': true,
                            'cleanable': true,
                            'required': true,
                            'gridsize': Config.getGridclasses()['col-12'] + ' ' + Config.getGridclasses()['col-md-4'],
                            'apipath': 'masters/ratings',
                            'masterdata': 'guestrequestrating',
                            'masterdatafield': 'rating',
                            'formdatafield': 'rating',
                            'istablefield': 1,
                            'staticfilter': {
                                'propertyid': getCurrentState().reservationDetails.propertyid,
                                'ratingcategoryid': Config.ratingcategoryid.requestratings
                            },
                        },
                        //1
                        {
                            'field': 'suggestion',
                            'text': 'Suggestion',
                            'type': Config.getHtmlcontorls()['input-textarea'],
                            'disabled': false,
                            'cleanable': true,
                            'defaultvisibility': true,
                            'required': false,
                            'gridsize': Config.getGridclasses()['col-12'],
                        },
                    ]
                }
            ]

        case 'requestfilterjson':
            return [
                {
                    rightsidebarsize: Config.getModalsizeclasses()['sm'],
                    pagename: 'rquestfilter',
                    formname: 'request filter',
                    apipath: 'tasklistforweb',

                    formfields: [
                        //0
                        {
                            'field': 'taskstatustype',
                            'text': 'Status',
                            'type': Config.getHtmlcontorls()['multipleselectdropdown'],
                            'disabled': false,
                            'defaultvisibility': true,
                            'cleanable': true,
                            'required': false,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'apipath': 'masters/taskstatus',
                            'masterdata': 'status',
                            'masterdatafield': 'status',
                            'formdatafield': 'taskstatustype',
                            'selectall': true,
                            'staticfilter': { 'taskcategoryid': Config.categoryid.request }
                        },
                        //1 JENIL 28/6/2023 6:22 PM
                        {
                            'field': 'fromdate',
                            'text': 'From Date',
                            'type': Config.getHtmlcontorls()['datepicker'],
                            'disabled': false,
                            'cleanable': true,
                            'defaultvisibility': true,
                            'required': false,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'resetfield': ['todate'],
                            'disablefromdate': IISMethods.GetDateTimefromISOString()
                        },
                        //2 JENIL 28/6/2023 6:22 PM
                        {
                            'field': 'todate',
                            'text': 'To Date',
                            'type': Config.getHtmlcontorls()['datepicker'],
                            'disabled': false,
                            'cleanable': true,
                            'defaultvisibility': false,
                            'required': true,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'disablefromfield': 'fromdate'
                        },
                    ]
                }
            ]

        default:
            return []
    }
}