import ServerMode from './ServerENV'
export default class Config {

	constructor() {
		var weburl
		var imageurl
		var endpointurl
		var endpointverson
		var docurl
		var regdomain
		var mailurl
		var protocol
		var docpath
		var cdnurl
		var servermode
		var maindirpath
		var dirpath
		var page404url
		var nodatafound
		var reporturl

		var mssqldefval
		var mysqldefval
		var errmsg

		var sessionname
		var adminutype

		var savesidebar
		var resetsidebar
		var closesidebar
		var applyfiltersidebar

		var updatemyprofile
		var changepassword
		var changeusertype

		var submit
		var generatereport

		//for Company Type
		var cmpregisterid
		var cmpunregisterid
		var cmpcompositionschemeid


		var stateid
		var defaultbirthdate
		var dummyid

		var defaultcmpid

		var adminuid
		var defaultimageurl
		var defaultcatimageurl
		var paytyperupeeimageurl
		var defaultnotiimageurl

		var socketurl
		var socketserver
		var greetingmessages

		// srushti 24-2-23
		// var category

		// srushti 12-5-23
		var departments

		// srushti 24-2-23
		var assignmentfor
		var othersubcategory
		var dummyserialno//charmi(09-08-2023 03:58)

		// Bhautik 26-04-2023 04:10 PM
		var cookiestorage

		var encryptdata // Heli 15/08/2023 01:18
		var socketencryptdata // BR 01-09-2023 03:04 PM 

		this.countryid = 'us'
		this.countryname = 'United States'
		this.codecontact = '1'

		// srushti
		// 8-2-23
		this.categoryid = {
			'housekeeping': '62fe1707cbac206928991220',
			'request': '62fe170fcbac206928991221',
			'maintenance': '62fe1718cbac206928991222',
			'premaintenance': '632463afbf80360f174730be',
			'deepcleaning': '632463ebbf80360f174730c2',
			'other': '6369daffba0ae0028ae00a8d',
			'deposit': '63aec47cb9c01030ff5ee799',
			'frontdesk': '6405b5777f61a8195eac47ce',
			'lost': '647993d75e33b3717857c216'
		}

		// srushti
		// 14-4-23
		this.ratingcategoryid = {
			'checkinratings': '6437da50e96836548505c514',
			'housekeepingratings': '6437da57e96836548505c515',
			'requestratings': '6437da5de96836548505c516',
			'maintenanceratings': '6437da66e96836548505c517',
			'checkoutratings': '6437da74e96836548505c518',
			'complaintratings': '643e60c728d7025ca7bb2d37',
			'lostratings': '6482f344b198105d02f701b6'
		}

		// srushti
		// 12-5-23
		this.departments = {
			'housekeeping': '6364d2c4af746f99830a4013',
			'maintenance': '6364d2cfaf746f99830a4026',
			'frontdesk': '6364d2dcaf746f99830a4039',//charmi(18-05-2023)
		}

		// srushti 24-2-23
		// this.category = {
		// 	'housekeeping': 'housekeeping', 
		// 	'request': 'request', 
		// 	'maintenance': 'maintenance', 
		// 	'premaintenance': 'premaintenance', 
		// 	'deepcleaning': 'deepcleaning',
		// 	'other': 'other',
		// 	'deposit': 'deposit'
		// }

		// srushti 24-2-23
		this.assignmentfor = {
			'room': '62d11c45b1bd257960e992b5',
			'area': '62d11c40b1bd257960e992b4'
		}

		this.othersubcategory = '642d094455d84a4775b87ebe'
		this.dummyserialno = '1i4dqwsp'

		this.tooltip = {
			'filter': 'Filter',
			'add': 'Add',
			'remove': 'Remove',
			'close': 'Close',
			'placement': 'top'
		}

		this.dummyid = '625d5ed5220ccd5a20904f79'

		// Bhautik 26-04-2023 04:10 PM
		this.cookiestorage = {
			'loginInfo': 'guestexperience_logininfo'
		}


		// date_default_timezone_set("Asia/Kolkata")        

		this.protocol = 'http://'  // http:// or https://
		this.servermode = ServerMode // prod - Live | uat - test | dev = development
		this.docroot = __dirname + '/'

		//  project lavel configuration 
		this.sessionname = 'kiosist_27102021'
		this.adminutype = '622ed36cbe213c85a88da9a0'
		this.page404url = '/views/404'

		this.adminuid = '034FB884-A865-4127-B90B-3D06047A72CC'


		this.savesidebar = 'Create' // button name
		this.addsidebar = 'Add'
		this.savesidebarname = 'Create'
		this.adddatabuttonname = 'Create'
		this.resetsidebar = 'Reset'
		this.closesidebar = 'Close'
		this.cancelsidebar = 'Cancel'
		this.updatesidebar = 'Update' // button name
		this.updatesidebarname = 'Update'
		this.applyfiltersidebar = 'Apply'
		this.cancelfiltersidebar = 'Close'

		this.updatemyprofile = 'Update Profile'
		this.changepassword = 'Change Password'
		this.changeusertype = 'Change Usertype'

		this.submitbutton = 'Submit'
		this.generatereport = 'Generate Report'

		// for delete modal
		this.canceldelete = 'Close'
		// for delete modal
		this.confirmdelete = 'Confirm'

		this.savebutton = 'Save'
		this.closebutton = 'Close'
		this.cancelbutton = 'Cancel'
		this.printbutton = 'Print'


		// this.defaultCanidateimgupload = 'https://bizcompass.s3.ap-south-1.amazonaws.com/IIS/defaultCandidateimgupload.png'

		//company Type configuration
		this.cmpregisterid = 'E80F52C1-F01C-4301-9438-62CE1E7DD58A'
		this.cmpunregisterid = '0920A058-CFF3-4000-B018-7F168E87054D'
		this.cmpcompositionschemeid = '4A23364E-8A23-4852-9ACF-D14F1294961E'

		this.stateid = '65754311-A0BB-4A7F-9043-05670385BF13'

		this.defaultcmpid = 'B3E18DB5-2A63-4C02-B763-0D3D4A9057CA'


		this.encryptdata = false // Heli 15/08/2023 01:19
		this.socketencryptdata = false // BR 15-08-2023 10:15 PM 

		// end of strings 

		/*****************************************************************   
		* 
		* Server Lavel Enviroment Configuration 
		* 
		* 
		*/
		if (this.servermode == "dev") // Development 
		{
			this.maindirpath = '/'
			this.dirpath = this.maindirpath + ''  /* Directory Name also look in index.php script tag*/
			// this.host = "192.168.2.69:8082" + this.dirpath // charmi
			// this.host = "192.168.2.78:8082" + this.dirpath // Tushar 
			this.host = "192.168.1.64:8082" + this.dirpath // BR 
			// this.host = "192.168.2.79:8082" + this.dirpath // paras
			// this.host = "192.168.1.55:8082" + this.dirpath // harsh v
			// this.host = "192.168.2.82:8082" + this.dirpath // JENIL
			// this.host = "192.168.2.77:8082" + this.dirpath // MD
			// this.host = "192.168.1.56:8082" + this.dirpath // harsh d
			// this.host = "192.168.1.57:8082" + this.dirpath // parth
			this.endpointverson = 'v1/'
			this.weburl = this.protocol + this.host + this.endpointverson
			this.docpath = this.docroot3
			this.cdnurl = this.protocol + this.host
			// this.regdomain = '.thenextap.app'
			this.regdomain = '.thenextap.io'
			this.mailurl = "192.168.1.2/kiosist-saas/"

			this.endpointurl = this.protocol + "" + this.dirpath + 'api/' + this.endpointverson
			this.imageurl = this.protocol + this.maindirpath + 'assets/'
			this.page404url = this.protocol + this.host + this.page404url
			this.nodatafound = this.protocol + this.host + 'views/nodatafound.php'
			this.reporturl = this.protocol + this.host + 'report/'

			this.defaultimageurl = this.imageurl + 'images/defaultitem.png'
			this.defaultcatimageurl = this.imageurl + 'images/defaultcategory.png'
			this.paytyperupeeimageurl = this.imageurl + 'images/rupee.png'
			this.defaultnotiimageurl = this.imageurl + 'images/defaultnotification.png'
			this.socketurl = 'http://192.168.1.26:3001/socket.io/socket.io.js'
			// this.socketserver = 'http://192.168.1.26:3001/' 
			this.socketserver = 'http://192.168.1.56:8083' //jenil changes 27-4 18:42
			
			this.encryptdata = false// Heli 15/08/2023 01:19
			this.socketencryptdata = false // BR 15-08-2023 10:15 PM
		}		
		else if (this.servermode == "poc") // live 
		{

			this.maindirpath = '/'
			this.dirpath = this.maindirpath + ''  /* Directory Name also look in index.php script tag*/
			// this.host = "kiosk.thenextap.io" + this.dirpath
			// this.host = "api.thenextap.app" + this.dirpath
			this.host = "api.nextaphospitality.com" + this.dirpath
			this.protocol = 'https://'
			this.endpointverson = 'v1/'
			this.weburl = this.protocol + this.host + this.endpointverson
			this.docpath = this.docroot
			this.cdnurl = this.protocol + this.host
			this.regdomain = 'thenextap.app'
			this.mailurl = "192.168.1.2/kiosist-saas/"

			this.endpointurl = this.protocol + "" + this.dirpath + 'api/' + this.endpointverson
			this.imageurl = this.protocol + this.maindirpath + 'assets/'
			this.page404url = this.protocol + this.host + this.page404url
			this.nodatafound = this.protocol + this.host + 'views/nodatafound.php'
			this.reporturl = this.protocol + this.host + 'report/'

			this.defaultimageurl = this.imageurl + 'images/defaultitem.png'
			this.defaultcatimageurl = this.imageurl + 'images/defaultcategory.png'
			this.paytyperupeeimageurl = this.imageurl + 'images/rupee.png'
			this.defaultnotiimageurl = this.imageurl + 'images/defaultnotification.png'

			this.socketurl = 'http://192.168.1.26:3001/socket.io/socket.io.js'
			this.socketserver = 'https://socket.thenextap.io:3000'
			
			this.encryptdata = false
			this.socketencryptdata = false // BR 15-08-2023 10:15 PM
		}
		//bhautik
		else if (this.servermode == "prod") // live 
		{
			this.maindirpath = '/'
			this.dirpath = this.maindirpath + ''  /* Directory Name also look in index.php script tag*/
			// this.host = "api.thenextap.app" + this.dirpath
			this.host = "api.thenextap.io" + this.dirpath
			this.endpointverson = 'v1/'
			this.protocol = 'https://'  // http:// or https://
			this.weburl = this.protocol + this.host + this.endpointverson
			this.docpath = this.docroot
			this.cdnurl = this.protocol + this.host
			this.regdomain = '.thenextap.io'
			// this.regdomain = '.thenextap.app'
			this.mailurl = "192.168.1.2/kiosist-saas/"

			this.endpointurl = this.protocol + "" + this.dirpath + 'api/' + this.endpointverson
			this.imageurl = this.protocol + this.maindirpath + 'assets/'
			this.page404url = this.protocol + this.host + this.page404url
			this.nodatafound = this.protocol + this.host + 'views/nodatafound.php'
			this.reporturl = this.protocol + this.host + 'report/'

			this.defaultimageurl = this.imageurl + 'images/defaultitem.png'
			this.defaultcatimageurl = this.imageurl + 'images/defaultcategory.png'
			this.paytyperupeeimageurl = this.imageurl + 'images/rupee.png'
			this.defaultnotiimageurl = this.imageurl + 'images/defaultnotification.png'
			// this.socketurl = 'http://192.168.1.26:3001/socket.io/socket.io.js'
			// this.socketserver = 'http://192.168.1.26:3001/'
			this.socketurl = 'http://192.168.1.26:3001/socket.io/socket.io.js'
			this.socketserver = 'https://socket.thenextap.io:3000'

			this.encryptdata = true // Heli 15/08/2023 01:19
			this.socketencryptdata = true // BR 15-08-2023 10:15 PM
		}
		else if (this.servermode == "uat") // test 
		{
			this.maindirpath = '/'
			this.dirpath = this.maindirpath + '' /* Directory Name */
			this.host = "uat.thenextap.us" + this.dirpath
			this.endpointverson = 'v1/'
			this.protocol = 'https://'  // http:// or https://
			this.weburl = this.protocol + this.host + this.endpointverson
			this.docpath = this.docroot
			this.cdnurl = this.protocol + this.host
			// this.regdomain = '.thenextap.app'
			this.regdomain = '.thenextap.us'
			this.mailurl = " "
			this.endpointurl = this.protocol + "" + this.dirpath + 'api/' + this.endpointverson
			this.imageurl = this.protocol + "" + this.maindirpath + 'assets/'
			this.page404url = this.protocol + this.host + this.page404url
			this.nodatafound = this.protocol + this.host + 'views/nodatafound.php'
			this.reporturl = this.protocol + this.host + 'report/'

			this.defaultimageurl = this.imageurl + 'images/defaultitem.jpg'
			this.defaultcatimageurl = this.imageurl + 'images/defaultcategory.png'
			this.paytyperupeeimageurl = this.imageurl + 'images/rupee.png'
			this.defaultnotiimageurl = this.imageurl + 'images/defaultnotification.png'

			this.socketserver = 'https://socket.thenextap.us'
	
			this.encryptdata = true // Heli 15/08/2023 01:19
			this.socketencryptdata = true // BR 15-08-2023 10:15 PM
		}
		this.defaultimageupload = 'https://bizcompass.s3.ap-south-1.amazonaws.com/defaultImageUpload.svg%2Bxml'
		// else if(this.servermode=="uat") // test 
		// { 
		// 	this.maindirpath=''
		// 	this.dirpath=this.maindirpath + '' /* Directory Name */
		//     this.host="" + this.dirpath
		//     this.weburl = this.protocol + this.host + '/'
		// 	this.docpath = this.docroot
		//     this.cdnurl = 'https://d13gezj0xhzun1.cloudfront.net/'
		//     this.regdomain = 'totalityuat.com'
		//     this.mailurl = "totalityuat.com/"
		// 	this.endpointverson = 'v1.0/'
		// 	this.endpointurl = this.protocol + "" + this.dirpath + 'api/' + this.endpointverson
		// 	this.imageurl = this.protocol + "" + this.maindirpath + 'assets/'
		// 	this.page404url=this.protocol + this.host + this.page404url
		// 	this.nodatafound=this.protocol + this.host + 'views/nodatafound.php'
		// 	this.reporturl=this.protocol + this.host + 'report/'

		// 	this.defaultimageurl=this.imageurl + 'images/defaultitem.png'
		// 	this.defaultcatimageurl=this.imageurl + 'images/defaultcategory.png'
		// 	this.paytyperupeeimageurl=this.imageurl + 'images/rupee.png'
		// 	this.defaultnotiimageurl=this.imageurl + 'images/defaultnotification.png'
		// }

		//for error message

		this.errmsg = {
			'insert': 'Data inserted successfully.',
			'inserteddata': 'Your data has been successfully submitted.',
			'update': 'Data updated successfully.',
			'delete': 'Data deleted successfully.',
			'reqired': 'Please fill in all required fields.',
			'inuse': 'Data is already in use.',
			'isexist': 'Data already exist.',
			'dberror': 'Something went wrong, Error Code : ',
			'userright': "Sorry, You don't have enough permissions to perform this action",
			'size': "Sorry, You don't have enough permissions to perform this action",
			'type': "Sorry, You don't have enough permissions to perform this action.",
			'success': "Data found",
			'error': "Error",
			'nodatafound': "No data found",
			'uservalidate': "User Validate.",
			'deactivate': "Your account is suspended, please contact administrator to activate account.",
			'invalidrequest': "Invalid request.",
			'sessiontimeout': "Session timeout",
			'dataduplicate': "Data Duplicated Succesfully",
			'tokenvalidate': "Token validated",
			'invalidtoken': "Invalid token.",
			'usernotfound': "User not found",
			'invalidusername': "Invalid Username or Password.",
			'invalidpassword': "Invalid Username or Password..",
			'verifyemail': "Please verify your email addess",
			'filetype': "Invalid file extension",
			'loginright': "Sorry, You don't have enough permissions to login. Please contact admin",
			'somethingwrong': 'Sorry, something went wrong.',
			'appupdate': "We have released new version of Poly Cafe App. Download the update and install to continue use this App.",
			'itemalreadyassign': "Item already assigned",
			'itemassign': "Item assigned successfully",
			'itemreassign': "Item reassigned successfully",
			'newnodeadd': "New Nodes added successfully",
			'nodedelnot': "Can't delete super admin node",
			'nodedel': "Node deleted successfully",
			'noitemfound': "No item found",
			'cancelorditem': "Order item cancelled successfully",
			'reqorderseries': "Order series data is required",
			'nonotifound': "No notification found",
			'noorderfound': "No order found",
			'itemready': "Item has been ready successfully",
			'itemserved': "Item has been served successfully",
			'paycollectsuccess': "Payment collected successfully",
			'orderaccept': "Order accepted successfully",
			'ordpreparetime': "Order preparation time updated successfully",
		}

		this.greetingmessages = [
			'Welcome, ', 'Howdy, ', 'Hello, '
		]

		this.regex = {
			// 'phone' : '^(?:[0-9] ?){9,14}[0-9]$',
			'phone': '^(?:[0-9] ?){7,13}[0-9]$',//heli(16-03-2023)
			'email': '^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+[.][a-zA-Z]{2,4}$',// '^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$',
			'gstno': '^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$',
			'pincode': '^[1-9][0-9]{5}$',
			'aadharno': '^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$',
			'panno': '^[A-Z]{5}[0-9]{4}[A-Z]{1}$',
			'ifsccode': '^[A-Z]{4}0[A-Z0-9]{6}$',
			'tinno': '^[A-Z]{5}[0-9]{4}[A-Z]{1}$', //temp
			// 'tinno' : '^(9\d{2})([ \-]?)([7]\d|8[0-8])([ \-]?)(\d{4})$', //ori
			'name': '^[a-zA-Z\\s]*$',
			'contactno': '^(?:[0-9] ?){6,14}[0-9]$'
		}

		this.htmlcontorls = {
			'switch': 'switch',
			'text': 'text',
			'icon': 'icon',
			'input-text': 'input-text',
			'password': 'password',
			'input-textarea': 'input-textarea',
			'checkbox': 'checkbox',
			'dropdown': 'dropdown',
			'radio': 'radio',
			'default-radio': 'default-radio',
			'multipleselectdropdown': 'multipleselectdropdown',
			'lookup': 'lookup',
			'combobox': 'combobox',
			'number-input': 'number-input',
			'input': 'input',
			'datepicker': 'datepicker',
			'datetimepicker': 'datetimepicker',
			'rangepicker': 'rangepicker',
			'daterangepicker': 'daterangepicker',
			'timerangepicker': 'timerangepicker',
			'timepicker': 'timepicker',
			'monthpicker': 'monthpicker',
			'colorpicker': 'colorpicker',
			'image': 'image',
			'email': 'email',
			'phone': 'phone',
			'html-editor': 'html-editor',
			'text-array': 'text-array',
			'modal-eye': 'modal-eye',
			'modal-text': 'modal-text',
			'file': 'file',
			'svg-file': 'svg-file',
			'table': 'table',
			'table-add-button': 'table-add-button',
			'colorpickerpreview': 'colorpickerpreview',
			'drag-container': 'drag-container',
			'combinefield': 'combinefield',
			'number-input-message': 'number-input-message',
			'tag-input': 'tag-input',
			'tag-picker': 'tag-picker',
			'multiplefilepicker': 'multiplefilepicker',
			'checkpicker': 'checkpicker',
			'personimg': "personimg",
			'map': 'map',
			'generateinputs': 'generateinputs',
			'product-assign-button': 'product-assign-button',
			'number-count': 'number-count',
			'intltelinput': 'intltelinput',
			'address-suggestion': 'address-suggestion',
			'groupwisedropdown': 'groupwisedropdown'

		}

		this.modalsizeclasses = {
			'xs': 'xs',
			'sm': 'sm',
			'md': 'md',
			'lg': 'lg',
			'xl': 'xl',
			'full': 'full'
		}

		this.gridclasses = {
			'col': 'col',
			'col-auto': 'col-auto',
			'col-1': 'col-1',
			'col-2': 'col-2',
			'col-3': 'col-3',
			'col-4': 'col-4',
			'col-5': 'col-5',
			'col-6': 'col-6',
			'col-7': 'col-7',
			'col-8': 'col-8',
			'col-9': 'col-9',
			'col-10': 'col-10',
			'col-11': 'col-11',
			'col-12': 'col-12',

			'col-xs': 'col-xs',
			'col-xs-auto': 'col-xs-auto',
			'col-xs-1': 'col-xs-1',
			'col-xs-2': 'col-xs-2',
			'col-xs-3': 'col-xs-3',
			'col-xs-4': 'col-xs-4',
			'col-xs-5': 'col-xs-5',
			'col-xs-6': 'col-xs-6',
			'col-xs-7': 'col-xs-7',
			'col-xs-8': 'col-xs-8',
			'col-xs-9': 'col-xs-9',
			'col-xs-10': 'col-xs-10',
			'col-xs-11': 'col-xs-11',
			'col-xs-12': 'col-xs-12',

			'col-sm': 'col-sm',
			'col-sm-auto': 'col-sm-auto',
			'col-sm-1': 'col-sm-1',
			'col-sm-2': 'col-sm-2',
			'col-sm-3': 'col-sm-3',
			'col-sm-4': 'col-sm-4',
			'col-sm-5': 'col-sm-5',
			'col-sm-6': 'col-sm-6',
			'col-sm-7': 'col-sm-7',
			'col-sm-8': 'col-sm-8',
			'col-sm-9': 'col-sm-9',
			'col-sm-10': 'col-sm-10',
			'col-sm-11': 'col-sm-11',
			'col-sm-12': 'col-sm-12',

			'col-md': 'col-md',
			'col-md-auto': 'col-md-auto',
			'col-md-1': 'col-md-1',
			'col-md-2': 'col-md-2',
			'col-md-3': 'col-md-3',
			'col-md-4': 'col-md-4',
			'col-md-5': 'col-md-5',
			'col-md-6': 'col-md-6',
			'col-md-7': 'col-md-7',
			'col-md-8': 'col-md-8',
			'col-md-9': 'col-md-9',
			'col-md-10': 'col-md-10',
			'col-md-11': 'col-md-11',
			'col-md-12': 'col-md-12',

			'col-lg': 'col-lg',
			'col-lg-auto': 'col-lg-auto',
			'col-lg-1': 'col-lg-1',
			'col-lg-2': 'col-lg-2',
			'col-lg-3': 'col-lg-3',
			'col-lg-4': 'col-lg-4',
			'col-lg-5': 'col-lg-5',
			'col-lg-6': 'col-lg-6',
			'col-lg-7': 'col-lg-7',
			'col-lg-8': 'col-lg-8',
			'col-lg-9': 'col-lg-9',
			'col-lg-10': 'col-lg-10',
			'col-lg-11': 'col-lg-11',
			'col-lg-12': 'col-lg-12',

			'col-xl': 'col-xl',
			'col-xl-auto': 'col-xl-auto',
			'col-xl-1': 'col-xl-1',
			'col-xl-2': 'col-xl-2',
			'col-xl-3': 'col-xl-3',
			'col-xl-4': 'col-xl-4',
			'col-xl-5': 'col-xl-5',
			'col-xl-6': 'col-xl-6',
			'col-xl-7': 'col-xl-7',
			'col-xl-8': 'col-xl-8',
			'col-xl-9': 'col-xl-9',
			'col-xl-10': 'col-xl-10',
			'col-xl-11': 'col-xl-11',
			'col-xl-12': 'col-xl-12',

			'col-exl': 'col-exl',
			'col-exl-auto': 'col-exl-auto',
			'col-exl-1': 'col-exl-1',
			'col-exl-2': 'col-exl-2',
			'col-exl-3': 'col-exl-3',
			'col-exl-4': 'col-exl-4',
			'col-exl-5': 'col-exl-5',
			'col-exl-6': 'col-exl-6',
			'col-exl-7': 'col-exl-7',
			'col-exl-8': 'col-exl-8',
			'col-exl-9': 'col-exl-9',
			'col-exl-10': 'col-exl-10',
			'col-exl-11': 'col-exl-11',
			'col-exl-12': 'col-exl-12',

			'p-0': 'p-0'
		}

		// this.tblSize

		this.sidebarsizeclasses = {
			'sm-sidebar': 'sm-sidebar',
			'md-sidebar': 'md-sidebar',
			'lg-sidebar': 'lg-sidebar',
			'xl-sidebar': 'xl-sidebar'
		}

		this.marginclasses = {
			'm-auto': 'm-auto',
			'm-0': 'm-0',
			'm-1': 'm-1',
			'm-2': 'm-2',
			'm-3': 'm-3',
			'm-4': 'm-4',
			'm-5': 'm-5',
			'mt-auto': 'mt-auto',
			'mt-0': 'mt-0',
			'mt-1': 'mt-1',
			'mt-2': 'mt-2',
			'mt-3': 'mt-3',
			'mt-4': 'mt-4',
			'mt-5': 'mt-5',
			'mb-auto': 'mb-auto',
			'mb-0': 'mb-0',
			'mb-1': 'mb-1',
			'mb-2': 'mb-2',
			'mb-3': 'mb-3',
			'mb-4': 'mb-4',
			'mb-5': 'mb-5',
			'mr-auto': 'mr-auto',
			'mr-0': 'mr-0',
			'mr-1': 'mr-1',
			'mr-2': 'mr-2',
			'mr-3': 'mr-3',
			'mr-4': 'mr-4',
			'mr-5': 'mr-5',
			'ml-auto': 'ml-auto',
			'ml-0': 'ml-0',
			'ml-1': 'ml-1',
			'ml-2': 'ml-2',
			'ml-3': 'ml-3',
			'ml-4': 'ml-4',
			'ml-5': 'ml-5',

			'm-xs-auto': 'm-xs-auto',
			'm-xs-0': 'm-xs-0',
			'm-xs-1': 'm-xs-1',
			'm-xs-2': 'm-xs-2',
			'm-xs-3': 'm-xs-3',
			'm-xs-4': 'm-xs-4',
			'm-xs-5': 'm-xs-5',
			'mt-xs-auto': 'mt-xs-auto',
			'mt-xs-0': 'mt-xs-0',
			'mt-xs-1': 'mt-xs-1',
			'mt-xs-2': 'mt-xs-2',
			'mt-xs-3': 'mt-xs-3',
			'mt-xs-4': 'mt-xs-4',
			'mt-xs-5': 'mt-xs-5',
			'mb-xs-auto': 'mb-xs-auto',
			'mb-xs-0': 'mb-xs-0',
			'mb-xs-1': 'mb-xs-1',
			'mb-xs-2': 'mb-xs-2',
			'mb-xs-3': 'mb-xs-3',
			'mb-xs-4': 'mb-xs-4',
			'mb-xs-5': 'mb-xs-5',
			'mr-xs-auto': 'mr-xs-auto',
			'mr-xs-0': 'mr-xs-0',
			'mr-xs-1': 'mr-xs-1',
			'mr-xs-2': 'mr-xs-2',
			'mr-xs-3': 'mr-xs-3',
			'mr-xs-4': 'mr-xs-4',
			'mr-xs-5': 'mr-xs-5',
			'ml-xs-auto': 'ml-xs-auto',
			'ml-xs-0': 'ml-xs-0',
			'ml-xs-1': 'ml-xs-1',
			'ml-xs-2': 'ml-xs-2',
			'ml-xs-3': 'ml-xs-3',
			'ml-xs-4': 'ml-xs-4',
			'ml-xs-5': 'ml-xs-5',

			'm-sm-auto': 'm-sm-auto',
			'm-sm-0': 'm-sm-0',
			'm-sm-1': 'm-sm-1',
			'm-sm-2': 'm-sm-2',
			'm-sm-3': 'm-sm-3',
			'm-sm-4': 'm-sm-4',
			'm-sm-5': 'm-sm-5',
			'mt-sm-auto': 'mt-sm-auto',
			'mt-sm-0': 'mt-sm-0',
			'mt-sm-1': 'mt-sm-1',
			'mt-sm-2': 'mt-sm-2',
			'mt-sm-3': 'mt-sm-3',
			'mt-sm-4': 'mt-sm-4',
			'mt-sm-5': 'mt-sm-5',
			'mb-sm-auto': 'mb-sm-auto',
			'mb-sm-0': 'mb-sm-0',
			'mb-sm-1': 'mb-sm-1',
			'mb-sm-2': 'mb-sm-2',
			'mb-sm-3': 'mb-sm-3',
			'mb-sm-4': 'mb-sm-4',
			'mb-sm-5': 'mb-sm-5',
			'mr-sm-auto': 'mr-sm-auto',
			'mr-sm-0': 'mr-sm-0',
			'mr-sm-1': 'mr-sm-1',
			'mr-sm-2': 'mr-sm-2',
			'mr-sm-3': 'mr-sm-3',
			'mr-sm-4': 'mr-sm-4',
			'mr-sm-5': 'mr-sm-5',
			'ml-sm-auto': 'ml-sm-auto',
			'ml-sm-0': 'ml-sm-0',
			'ml-sm-1': 'ml-sm-1',
			'ml-sm-2': 'ml-sm-2',
			'ml-sm-3': 'ml-sm-3',
			'ml-sm-4': 'ml-sm-4',
			'ml-sm-5': 'ml-sm-5',

			'm-md-auto': 'm-md-auto',
			'm-md-0': 'm-md-0',
			'm-md-1': 'm-md-1',
			'm-md-2': 'm-md-2',
			'm-md-3': 'm-md-3',
			'm-md-4': 'm-md-4',
			'm-md-5': 'm-md-5',
			'mt-md-auto': 'mt-md-auto',
			'mt-md-0': 'mt-md-0',
			'mt-md-1': 'mt-md-1',
			'mt-md-2': 'mt-md-2',
			'mt-md-3': 'mt-md-3',
			'mt-md-4': 'mt-md-4',
			'mt-md-5': 'mt-md-5',
			'mb-md-auto': 'mb-md-auto',
			'mb-md-0': 'mb-md-0',
			'mb-md-1': 'mb-md-1',
			'mb-md-2': 'mb-md-2',
			'mb-md-3': 'mb-md-3',
			'mb-md-4': 'mb-md-4',
			'mb-md-5': 'mb-md-5',
			'mr-md-auto': 'mr-md-auto',
			'mr-md-0': 'mr-md-0',
			'mr-md-1': 'mr-md-1',
			'mr-md-2': 'mr-md-2',
			'mr-md-3': 'mr-md-3',
			'mr-md-4': 'mr-md-4',
			'mr-md-5': 'mr-md-5',
			'ml-md-auto': 'ml-md-auto',
			'ml-md-0': 'ml-md-0',
			'ml-md-1': 'ml-md-1',
			'ml-md-2': 'ml-md-2',
			'ml-md-3': 'ml-md-3',
			'ml-md-4': 'ml-md-4',
			'ml-md-5': 'ml-md-5',

			'm-lg-auto': 'm-lg-auto',
			'm-lg-0': 'm-lg-0',
			'm-lg-1': 'm-lg-1',
			'm-lg-2': 'm-lg-2',
			'm-lg-3': 'm-lg-3',
			'm-lg-4': 'm-lg-4',
			'm-lg-5': 'm-lg-5',
			'mt-lg-auto': 'mt-lg-auto',
			'mt-lg-0': 'mt-lg-0',
			'mt-lg-1': 'mt-lg-1',
			'mt-lg-2': 'mt-lg-2',
			'mt-lg-3': 'mt-lg-3',
			'mt-lg-4': 'mt-lg-4',
			'mt-lg-5': 'mt-lg-5',
			'mb-lg-auto': 'mb-lg-auto',
			'mb-lg-0': 'mb-lg-0',
			'mb-lg-1': 'mb-lg-1',
			'mb-lg-2': 'mb-lg-2',
			'mb-lg-3': 'mb-lg-3',
			'mb-lg-4': 'mb-lg-4',
			'mb-lg-5': 'mb-lg-5',
			'mr-lg-auto': 'mr-lg-auto',
			'mr-lg-0': 'mr-lg-0',
			'mr-lg-1': 'mr-lg-1',
			'mr-lg-2': 'mr-lg-2',
			'mr-lg-3': 'mr-lg-3',
			'mr-lg-4': 'mr-lg-4',
			'mr-lg-5': 'mr-lg-5',
			'ml-lg-auto': 'ml-lg-auto',
			'ml-lg-0': 'ml-lg-0',
			'ml-lg-1': 'ml-lg-1',
			'ml-lg-2': 'ml-lg-2',
			'ml-lg-3': 'ml-lg-3',
			'ml-lg-4': 'ml-lg-4',
			'ml-lg-5': 'ml-lg-5',

			'm-xl-auto': 'm-xl-auto',
			'm-xl-0': 'm-xl-0',
			'm-xl-1': 'm-xl-1',
			'm-xl-2': 'm-xl-2',
			'm-xl-3': 'm-xl-3',
			'm-xl-4': 'm-xl-4',
			'm-xl-5': 'm-xl-5',
			'mt-xl-auto': 'mt-xl-auto',
			'mt-xl-0': 'mt-xl-0',
			'mt-xl-1': 'mt-xl-1',
			'mt-xl-2': 'mt-xl-2',
			'mt-xl-3': 'mt-xl-3',
			'mt-xl-4': 'mt-xl-4',
			'mt-xl-5': 'mt-xl-5',
			'mb-xl-auto': 'mb-xl-auto',
			'mb-xl-0': 'mb-xl-0',
			'mb-xl-1': 'mb-xl-1',
			'mb-xl-2': 'mb-xl-2',
			'mb-xl-3': 'mb-xl-3',
			'mb-xl-4': 'mb-xl-4',
			'mb-xl-5': 'mb-xl-5',
			'mr-xl-auto': 'mr-xl-auto',
			'mr-xl-0': 'mr-xl-0',
			'mr-xl-1': 'mr-xl-1',
			'mr-xl-2': 'mr-xl-2',
			'mr-xl-3': 'mr-xl-3',
			'mr-xl-4': 'mr-xl-4',
			'mr-xl-5': 'mr-xl-5',
			'ml-xl-auto': 'ml-xl-auto',
			'ml-xl-0': 'ml-xl-0',
			'ml-xl-1': 'ml-xl-1',
			'ml-xl-2': 'ml-xl-2',
			'ml-xl-3': 'ml-xl-3',
			'ml-xl-4': 'ml-xl-4',
			'ml-xl-5': 'ml-xl-5',

			'm-exl-auto': 'm-exl-auto',
			'm-exl-0': 'm-exl-0',
			'm-exl-1': 'm-exl-1',
			'm-exl-2': 'm-exl-2',
			'm-exl-3': 'm-exl-3',
			'm-exl-4': 'm-exl-4',
			'm-exl-5': 'm-exl-5',
			'mt-exl-auto': 'mt-exl-auto',
			'mt-exl-0': 'mt-exl-0',
			'mt-exl-1': 'mt-exl-1',
			'mt-exl-2': 'mt-exl-2',
			'mt-exl-3': 'mt-exl-3',
			'mt-exl-4': 'mt-exl-4',
			'mt-exl-5': 'mt-exl-5',
			'mb-exl-auto': 'mb-exl-auto',
			'mb-exl-0': 'mb-exl-0',
			'mb-exl-1': 'mb-exl-1',
			'mb-exl-2': 'mb-exl-2',
			'mb-exl-3': 'mb-exl-3',
			'mb-exl-4': 'mb-exl-4',
			'mb-exl-5': 'mb-exl-5',
			'mr-exl-auto': 'mr-exl-auto',
			'mr-exl-0': 'mr-exl-0',
			'mr-exl-1': 'mr-exl-1',
			'mr-exl-2': 'mr-exl-2',
			'mr-exl-3': 'mr-exl-3',
			'mr-exl-4': 'mr-exl-4',
			'mr-exl-5': 'mr-exl-5',
			'ml-exl-auto': 'ml-exl-auto',
			'ml-exl-0': 'ml-exl-0',
			'ml-exl-1': 'ml-exl-1',
			'ml-exl-2': 'ml-exl-2',
			'ml-exl-3': 'ml-exl-3',
			'ml-exl-4': 'ml-exl-4',
			'ml-exl-5': 'ml-exl-5',

			'my-auto': 'my-auto',
			'my-0': 'my-0',
			'my-1': 'my-1',
			'my-2': 'my-2',
			'my-3': 'my-3',
			'my-4': 'my-4',
			'my-5': 'my-5',
			'mx-auto': 'mx-auto',
			'mx-0': 'mx-0',
			'mx-1': 'mx-1',
			'mx-2': 'mx-2',
			'mx-3': 'mx-3',
			'mx-4': 'mx-4',
			'mx-5': 'mx-5',

			'my-xs-auto': 'my-xs-auto',
			'my-xs-0': 'my-xs-0',
			'my-xs-1': 'my-xs-1',
			'my-xs-2': 'my-xs-2',
			'my-xs-3': 'my-xs-3',
			'my-xs-4': 'my-xs-4',
			'my-xs-5': 'my-xs-5',
			'mx-xs-auto': 'mx-xs-auto',
			'mx-xs-0': 'mx-xs-0',
			'mx-xs-1': 'mx-xs-1',
			'mx-xs-2': 'mx-xs-2',
			'mx-xs-3': 'mx-xs-3',
			'mx-xs-4': 'mx-xs-4',
			'mx-xs-5': 'mx-xs-5',

			'my-sm-auto': 'my-sm-auto',
			'my-sm-0': 'my-sm-0',
			'my-sm-1': 'my-sm-1',
			'my-sm-2': 'my-sm-2',
			'my-sm-3': 'my-sm-3',
			'my-sm-4': 'my-sm-4',
			'my-sm-5': 'my-sm-5',
			'mx-sm-auto': 'mx-sm-auto',
			'mx-sm-0': 'mx-sm-0',
			'mx-sm-1': 'mx-sm-1',
			'mx-sm-2': 'mx-sm-2',
			'mx-sm-3': 'mx-sm-3',
			'mx-sm-4': 'mx-sm-4',
			'mx-sm-5': 'mx-sm-5',

			'my-md-auto': 'my-md-auto',
			'my-md-0': 'my-md-0',
			'my-md-1': 'my-md-1',
			'my-md-2': 'my-md-2',
			'my-md-3': 'my-md-3',
			'my-md-4': 'my-md-4',
			'my-md-5': 'my-md-5',
			'mx-md-auto': 'mx-md-auto',
			'mx-md-0': 'mx-md-0',
			'mx-md-1': 'mx-md-1',
			'mx-md-2': 'mx-md-2',
			'mx-md-3': 'mx-md-3',
			'mx-md-4': 'mx-md-4',
			'mx-md-5': 'mx-md-5',

			'my-lg-auto': 'my-lg-auto',
			'my-lg-0': 'my-lg-0',
			'my-lg-1': 'my-lg-1',
			'my-lg-2': 'my-lg-2',
			'my-lg-3': 'my-lg-3',
			'my-lg-4': 'my-lg-4',
			'my-lg-5': 'my-lg-5',
			'mx-lg-auto': 'mx-lg-auto',
			'mx-lg-0': 'mx-lg-0',
			'mx-lg-1': 'mx-lg-1',
			'mx-lg-2': 'mx-lg-2',
			'mx-lg-3': 'mx-lg-3',
			'mx-lg-4': 'mx-lg-4',
			'mx-lg-5': 'mx-lg-5',

			'my-xl-auto': 'my-xl-auto',
			'my-xl-0': 'my-xl-0',
			'my-xl-1': 'my-xl-1',
			'my-xl-2': 'my-xl-2',
			'my-xl-3': 'my-xl-3',
			'my-xl-4': 'my-xl-4',
			'my-xl-5': 'my-xl-5',
			'mx-xl-auto': 'mx-xl-auto',
			'mx-xl-0': 'mx-xl-0',
			'mx-xl-1': 'mx-xl-1',
			'mx-xl-2': 'mx-xl-2',
			'mx-xl-3': 'mx-xl-3',
			'mx-xl-4': 'mx-xl-4',
			'mx-xl-5': 'mx-xl-5',

			'my-exl-auto': 'my-exl-auto',
			'my-exl-0': 'my-exl-0',
			'my-exl-1': 'my-exl-1',
			'my-exl-2': 'my-exl-2',
			'my-exl-3': 'my-exl-3',
			'my-exl-4': 'my-exl-4',
			'my-exl-5': 'my-exl-5',
			'mx-exl-auto': 'mx-exl-auto',
			'mx-exl-0': 'mx-exl-0',
			'mx-exl-1': 'mx-exl-1',
			'mx-exl-2': 'mx-exl-2',
			'mx-exl-3': 'mx-exl-3',
			'mx-exl-4': 'mx-exl-4',
			'mx-exl-5': 'mx-exl-5',
		}

		this.paddingclasses = {
			'p-auto': 'p-auto',
			'p-0': 'p-0',
			'p-1': 'p-1',
			'p-2': 'p-2',
			'p-3': 'p-3',
			'p-4': 'p-4',
			'p-5': 'p-5',
			'pt-auto': 'pt-auto',
			'pt-0': 'pt-0',
			'pt-1': 'pt-1',
			'pt-2': 'pt-2',
			'pt-3': 'pt-3',
			'pt-4': 'pt-4',
			'pt-5': 'pt-5',
			'pb-auto': 'pb-auto',
			'pb-0': 'pb-0',
			'pb-1': 'pb-1',
			'pb-2': 'pb-2',
			'pb-3': 'pb-3',
			'pb-4': 'pb-4',
			'pb-5': 'pb-5',
			'pr-auto': 'pr-auto',
			'pr-0': 'pr-0',
			'pr-1': 'pr-1',
			'pr-2': 'pr-2',
			'pr-3': 'pr-3',
			'pr-4': 'pr-4',
			'pr-5': 'pr-5',
			'pl-auto': 'pl-auto',
			'pl-0': 'pl-0',
			'pl-1': 'pl-1',
			'pl-2': 'pl-2',
			'pl-3': 'pl-3',
			'pl-4': 'pl-4',
			'pl-5': 'pl-5',

			'p-xs-auto': 'p-xs-auto',
			'p-xs-0': 'p-xs-0',
			'p-xs-1': 'p-xs-1',
			'p-xs-2': 'p-xs-2',
			'p-xs-3': 'p-xs-3',
			'p-xs-4': 'p-xs-4',
			'p-xs-5': 'p-xs-5',
			'pt-xs-auto': 'pt-xs-auto',
			'pt-xs-0': 'pt-xs-0',
			'pt-xs-1': 'pt-xs-1',
			'pt-xs-2': 'pt-xs-2',
			'pt-xs-3': 'pt-xs-3',
			'pt-xs-4': 'pt-xs-4',
			'pt-xs-5': 'pt-xs-5',
			'pb-xs-auto': 'pb-xs-auto',
			'pb-xs-0': 'pb-xs-0',
			'pb-xs-1': 'pb-xs-1',
			'pb-xs-2': 'pb-xs-2',
			'pb-xs-3': 'pb-xs-3',
			'pb-xs-4': 'pb-xs-4',
			'pb-xs-5': 'pb-xs-5',
			'pr-xs-auto': 'pr-xs-auto',
			'pr-xs-0': 'pr-xs-0',
			'pr-xs-1': 'pr-xs-1',
			'pr-xs-2': 'pr-xs-2',
			'pr-xs-3': 'pr-xs-3',
			'pr-xs-4': 'pr-xs-4',
			'pr-xs-5': 'pr-xs-5',
			'pl-xs-auto': 'pl-xs-auto',
			'pl-xs-0': 'pl-xs-0',
			'pl-xs-1': 'pl-xs-1',
			'pl-xs-2': 'pl-xs-2',
			'pl-xs-3': 'pl-xs-3',
			'pl-xs-4': 'pl-xs-4',
			'pl-xs-5': 'pl-xs-5',

			'p-sm-auto': 'p-sm-auto',
			'p-sm-0': 'p-sm-0',
			'p-sm-1': 'p-sm-1',
			'p-sm-2': 'p-sm-2',
			'p-sm-3': 'p-sm-3',
			'p-sm-4': 'p-sm-4',
			'p-sm-5': 'p-sm-5',
			'pt-sm-auto': 'pt-sm-auto',
			'pt-sm-0': 'pt-sm-0',
			'pt-sm-1': 'pt-sm-1',
			'pt-sm-2': 'pt-sm-2',
			'pt-sm-3': 'pt-sm-3',
			'pt-sm-4': 'pt-sm-4',
			'pt-sm-5': 'pt-sm-5',
			'pb-sm-auto': 'pb-sm-auto',
			'pb-sm-0': 'pb-sm-0',
			'pb-sm-1': 'pb-sm-1',
			'pb-sm-2': 'pb-sm-2',
			'pb-sm-3': 'pb-sm-3',
			'pb-sm-4': 'pb-sm-4',
			'pb-sm-5': 'pb-sm-5',
			'pr-sm-auto': 'pr-sm-auto',
			'pr-sm-0': 'pr-sm-0',
			'pr-sm-1': 'pr-sm-1',
			'pr-sm-2': 'pr-sm-2',
			'pr-sm-3': 'pr-sm-3',
			'pr-sm-4': 'pr-sm-4',
			'pr-sm-5': 'pr-sm-5',
			'pl-sm-auto': 'pl-sm-auto',
			'pl-sm-0': 'pl-sm-0',
			'pl-sm-1': 'pl-sm-1',
			'pl-sm-2': 'pl-sm-2',
			'pl-sm-3': 'pl-sm-3',
			'pl-sm-4': 'pl-sm-4',
			'pl-sm-5': 'pl-sm-5',

			'p-md-auto': 'p-md-auto',
			'p-md-0': 'p-md-0',
			'p-md-1': 'p-md-1',
			'p-md-2': 'p-md-2',
			'p-md-3': 'p-md-3',
			'p-md-4': 'p-md-4',
			'p-md-5': 'p-md-5',
			'pt-md-auto': 'pt-md-auto',
			'pt-md-0': 'pt-md-0',
			'pt-md-1': 'pt-md-1',
			'pt-md-2': 'pt-md-2',
			'pt-md-3': 'pt-md-3',
			'pt-md-4': 'pt-md-4',
			'pt-md-5': 'pt-md-5',
			'pb-md-auto': 'pb-md-auto',
			'pb-md-0': 'pb-md-0',
			'pb-md-1': 'pb-md-1',
			'pb-md-2': 'pb-md-2',
			'pb-md-3': 'pb-md-3',
			'pb-md-4': 'pb-md-4',
			'pb-md-5': 'pb-md-5',
			'pr-md-auto': 'pr-md-auto',
			'pr-md-0': 'pr-md-0',
			'pr-md-1': 'pr-md-1',
			'pr-md-2': 'pr-md-2',
			'pr-md-3': 'pr-md-3',
			'pr-md-4': 'pr-md-4',
			'pr-md-5': 'pr-md-5',
			'pl-md-auto': 'pl-md-auto',
			'pl-md-0': 'pl-md-0',
			'pl-md-1': 'pl-md-1',
			'pl-md-2': 'pl-md-2',
			'pl-md-3': 'pl-md-3',
			'pl-md-4': 'pl-md-4',
			'pl-md-5': 'pl-md-5',

			'p-lg-auto': 'p-lg-auto',
			'p-lg-0': 'p-lg-0',
			'p-lg-1': 'p-lg-1',
			'p-lg-2': 'p-lg-2',
			'p-lg-3': 'p-lg-3',
			'p-lg-4': 'p-lg-4',
			'p-lg-5': 'p-lg-5',
			'pt-lg-auto': 'pt-lg-auto',
			'pt-lg-0': 'pt-lg-0',
			'pt-lg-1': 'pt-lg-1',
			'pt-lg-2': 'pt-lg-2',
			'pt-lg-3': 'pt-lg-3',
			'pt-lg-4': 'pt-lg-4',
			'pt-lg-5': 'pt-lg-5',
			'pb-lg-auto': 'pb-lg-auto',
			'pb-lg-0': 'pb-lg-0',
			'pb-lg-1': 'pb-lg-1',
			'pb-lg-2': 'pb-lg-2',
			'pb-lg-3': 'pb-lg-3',
			'pb-lg-4': 'pb-lg-4',
			'pb-lg-5': 'pb-lg-5',
			'pr-lg-auto': 'pr-lg-auto',
			'pr-lg-0': 'pr-lg-0',
			'pr-lg-1': 'pr-lg-1',
			'pr-lg-2': 'pr-lg-2',
			'pr-lg-3': 'pr-lg-3',
			'pr-lg-4': 'pr-lg-4',
			'pr-lg-5': 'pr-lg-5',
			'pl-lg-auto': 'pl-lg-auto',
			'pl-lg-0': 'pl-lg-0',
			'pl-lg-1': 'pl-lg-1',
			'pl-lg-2': 'pl-lg-2',
			'pl-lg-3': 'pl-lg-3',
			'pl-lg-4': 'pl-lg-4',
			'pl-lg-5': 'pl-lg-5',

			'p-xl-auto': 'p-xl-auto',
			'p-xl-0': 'p-xl-0',
			'p-xl-1': 'p-xl-1',
			'p-xl-2': 'p-xl-2',
			'p-xl-3': 'p-xl-3',
			'p-xl-4': 'p-xl-4',
			'p-xl-5': 'p-xl-5',
			'pt-xl-auto': 'pt-xl-auto',
			'pt-xl-0': 'pt-xl-0',
			'pt-xl-1': 'pt-xl-1',
			'pt-xl-2': 'pt-xl-2',
			'pt-xl-3': 'pt-xl-3',
			'pt-xl-4': 'pt-xl-4',
			'pt-xl-5': 'pt-xl-5',
			'pb-xl-auto': 'pb-xl-auto',
			'pb-xl-0': 'pb-xl-0',
			'pb-xl-1': 'pb-xl-1',
			'pb-xl-2': 'pb-xl-2',
			'pb-xl-3': 'pb-xl-3',
			'pb-xl-4': 'pb-xl-4',
			'pb-xl-5': 'pb-xl-5',
			'pr-xl-auto': 'pr-xl-auto',
			'pr-xl-0': 'pr-xl-0',
			'pr-xl-1': 'pr-xl-1',
			'pr-xl-2': 'pr-xl-2',
			'pr-xl-3': 'pr-xl-3',
			'pr-xl-4': 'pr-xl-4',
			'pr-xl-5': 'pr-xl-5',
			'pl-xl-auto': 'pl-xl-auto',
			'pl-xl-0': 'pl-xl-0',
			'pl-xl-1': 'pl-xl-1',
			'pl-xl-2': 'pl-xl-2',
			'pl-xl-3': 'pl-xl-3',
			'pl-xl-4': 'pl-xl-4',
			'pl-xl-5': 'pl-xl-5',

			'p-exl-auto': 'p-exl-auto',
			'p-exl-0': 'p-exl-0',
			'p-exl-1': 'p-exl-1',
			'p-exl-2': 'p-exl-2',
			'p-exl-3': 'p-exl-3',
			'p-exl-4': 'p-exl-4',
			'p-exl-5': 'p-exl-5',
			'pt-exl-auto': 'pt-exl-auto',
			'pt-exl-0': 'pt-exl-0',
			'pt-exl-1': 'pt-exl-1',
			'pt-exl-2': 'pt-exl-2',
			'pt-exl-3': 'pt-exl-3',
			'pt-exl-4': 'pt-exl-4',
			'pt-exl-5': 'pt-exl-5',
			'pb-exl-auto': 'pb-exl-auto',
			'pb-exl-0': 'pb-exl-0',
			'pb-exl-1': 'pb-exl-1',
			'pb-exl-2': 'pb-exl-2',
			'pb-exl-3': 'pb-exl-3',
			'pb-exl-4': 'pb-exl-4',
			'pb-exl-5': 'pb-exl-5',
			'pr-exl-auto': 'pr-exl-auto',
			'pr-exl-0': 'pr-exl-0',
			'pr-exl-1': 'pr-exl-1',
			'pr-exl-2': 'pr-exl-2',
			'pr-exl-3': 'pr-exl-3',
			'pr-exl-4': 'pr-exl-4',
			'pr-exl-5': 'pr-exl-5',
			'pl-exl-auto': 'pl-exl-auto',
			'pl-exl-0': 'pl-exl-0',
			'pl-exl-1': 'pl-exl-1',
			'pl-exl-2': 'pl-exl-2',
			'pl-exl-3': 'pl-exl-3',
			'pl-exl-4': 'pl-exl-4',
			'pl-exl-5': 'pl-exl-5',

			'py-auto': 'py-auto',
			'py-0': 'py-0',
			'py-1': 'py-1',
			'py-2': 'py-2',
			'py-3': 'py-3',
			'py-4': 'py-4',
			'py-5': 'py-5',
			'px-auto': 'px-auto',
			'px-0': 'px-0',
			'px-1': 'px-1',
			'px-2': 'px-2',
			'px-3': 'px-3',
			'px-4': 'px-4',
			'px-5': 'px-5',

			'py-xs-auto': 'py-xs-auto',
			'py-xs-0': 'py-xs-0',
			'py-xs-1': 'py-xs-1',
			'py-xs-2': 'py-xs-2',
			'py-xs-3': 'py-xs-3',
			'py-xs-4': 'py-xs-4',
			'py-xs-5': 'py-xs-5',
			'px-xs-auto': 'px-xs-auto',
			'px-xs-0': 'px-xs-0',
			'px-xs-1': 'px-xs-1',
			'px-xs-2': 'px-xs-2',
			'px-xs-3': 'px-xs-3',
			'px-xs-4': 'px-xs-4',
			'px-xs-5': 'px-xs-5',

			'py-sm-auto': 'py-sm-auto',
			'py-sm-0': 'py-sm-0',
			'py-sm-1': 'py-sm-1',
			'py-sm-2': 'py-sm-2',
			'py-sm-3': 'py-sm-3',
			'py-sm-4': 'py-sm-4',
			'py-sm-5': 'py-sm-5',
			'px-sm-auto': 'px-sm-auto',
			'px-sm-0': 'px-sm-0',
			'px-sm-1': 'px-sm-1',
			'px-sm-2': 'px-sm-2',
			'px-sm-3': 'px-sm-3',
			'px-sm-4': 'px-sm-4',
			'px-sm-5': 'px-sm-5',

			'py-md-auto': 'py-md-auto',
			'py-md-0': 'py-md-0',
			'py-md-1': 'py-md-1',
			'py-md-2': 'py-md-2',
			'py-md-3': 'py-md-3',
			'py-md-4': 'py-md-4',
			'py-md-5': 'py-md-5',
			'px-md-auto': 'px-md-auto',
			'px-md-0': 'px-md-0',
			'px-md-1': 'px-md-1',
			'px-md-2': 'px-md-2',
			'px-md-3': 'px-md-3',
			'px-md-4': 'px-md-4',
			'px-md-5': 'px-md-5',

			'py-lg-auto': 'py-lg-auto',
			'py-lg-0': 'py-lg-0',
			'py-lg-1': 'py-lg-1',
			'py-lg-2': 'py-lg-2',
			'py-lg-3': 'py-lg-3',
			'py-lg-4': 'py-lg-4',
			'py-lg-5': 'py-lg-5',
			'px-lg-auto': 'px-lg-auto',
			'px-lg-0': 'px-lg-0',
			'px-lg-1': 'px-lg-1',
			'px-lg-2': 'px-lg-2',
			'px-lg-3': 'px-lg-3',
			'px-lg-4': 'px-lg-4',
			'px-lg-5': 'px-lg-5',

			'py-xl-auto': 'py-xl-auto',
			'py-xl-0': 'py-xl-0',
			'py-xl-1': 'py-xl-1',
			'py-xl-2': 'py-xl-2',
			'py-xl-3': 'py-xl-3',
			'py-xl-4': 'py-xl-4',
			'py-xl-5': 'py-xl-5',
			'px-xl-auto': 'px-xl-auto',
			'px-xl-0': 'px-xl-0',
			'px-xl-1': 'px-xl-1',
			'px-xl-2': 'px-xl-2',
			'px-xl-3': 'px-xl-3',
			'px-xl-4': 'px-xl-4',
			'px-xl-5': 'px-xl-5',

			'py-exl-auto': 'py-exl-auto',
			'py-exl-0': 'py-exl-0',
			'py-exl-1': 'py-exl-1',
			'py-exl-2': 'py-exl-2',
			'py-exl-3': 'py-exl-3',
			'py-exl-4': 'py-exl-4',
			'py-exl-5': 'py-exl-5',
			'px-exl-auto': 'px-exl-auto',
			'px-exl-0': 'px-exl-0',
			'px-exl-1': 'px-exl-1',
			'px-exl-2': 'px-exl-2',
			'px-exl-3': 'px-exl-3',
			'px-exl-4': 'px-exl-4',
			'px-exl-5': 'px-exl-5',
		}

		this.priorityseverityclasses = {
			'High': 'high',
			'Medium': 'medium',
			'Low': 'low',
			'None': 'none'
		}

		this.recordinfomodalsize = this.getModalsizeclasses()['xs']
	}

	getHtmlcontorls() {
		return this.htmlcontorls
	}
	getModalsizeclasses() {
		return this.modalsizeclasses
	}
	getGridclasses() {
		return this.gridclasses
	}
	getSidebarsizeclasses() {
		return this.sidebarsizeclasses
	}
	getMarginclasses() {
		return this.marginclasses
	}
	getPaddingclasses() {
		return this.paddingclasses
	}
	getPrioritySeverityclasses() {
		return this.priorityseverityclasses
	}

	getErrmsg() {
		return this.errmsg
	}

	getRecordinfomodalsize() {
		return this.recordinfomodalsize
	}

	getRegex() {
		return this.regex
	}

	getMssqldefval() {
		return this.weburl
	}

	getMysqldefval() {
		return this.weburl
	}

	getWeburl() {
		return this.weburl
	}

	setWeburl(weburl) {
		this.weburl = weburl
	}

	getEndpointverson() {
		return this.endpointverson
	}

	setEndpointverson(endpointverson) {
		this.endpointverson = endpointverson
	}

	getEndpointurl() {
		return this.endpointurl
	}

	setEndpointurl(endpointurl) {
		this.endpointurl = endpointurl
	}

	getDocurl() {
		return this.docurl
	}

	setDocurl(docurl) {
		this.docurl = docurl
	}

	getRegdomain() {
		return this.regdomain
	}

	setRegdomain(regdomain) {
		this.regdomain = regdomain
	}

	getMailurl() {
		return this.mailurl
	}

	setMailurl(mailurl) {
		this.mailurl = mailurl
	}

	getProtocol() {
		return this.protocol
	}

	getGreetings() {
		return this.greetingmessages
	}
	getDefaulImageUploadUrl() {
		return this.defaultimageupload
	}
	getDefaultCandidateImageUploadUrl() {
		return this.defaultCanidateimgupload
	}
	setProtocol(protocol) {
		this.protocol = protocol
	}

	getDocpath() {
		return this.docpath
	}

	setDocpath(docpath) {
		this.docpath = docpath
	}

	getCdnurl() {
		return this.cdnurl
	}

	setCdnurl(cdnurl) {
		this.cdnurl = cdnurl
	}

	getServermode() {
		return this.servermode
	}

	setServermode(servermode) {
		this.servermode = servermode
	}

	getMainDirpath() {
		return this.maindirpath
	}

	setMainDirpath(maindirpath) {
		this.maindirpath = maindirpath
	}

	getDirpath() {
		return this.dirpath
	}

	setDirpath(dirpath) {
		this.dirpath = dirpath
	}

	getMainDB() {
		return this.MainDB
	}

	setMainDB(MainDB) {
		this.MainDB = MainDB
	}

	getDB() {
		return this.DB
	}

	setDB(DB) {
		this.DB = DB
	}

	getMainDBUser() {
		return this.MainDBUser
	}

	setMainDBUser(MainDBUser) {
		this.MainDBUser = MainDBUser
	}

	getDBUser() {
		return this.DBUser
	}

	setDBUser(DBUser) {
		this.DBUser = DBUser
	}

	getMainDBHost() {
		return this.MainDBHost
	}

	setMainDBHost(MainDBHost) {
		this.MainDBHost = MainDBHost
	}

	getDBHost() {
		return this.DBHost
	}

	setDBHost(DBHost) {
		this.DBHost = DBHost
	}

	getMainDBPass() {
		return this.MainDBPass
	}

	setMainDBPass(MainDBPass) {
		this.MainDBPass = MainDBPass
	}

	getDBPass() {
		return this.DBPass
	}

	setDBPass(DBPass) {
		this.DBPass = DBPass
	}

	getMainDBPort() {
		return this.MainDBPort
	}

	setMainDBPort(MainDBPort) {
		this.MainDBPort = MainDBPort
	}

	getDBPort() {
		return this.DBPort
	}

	setDBPort(DBPort) {
		this.DBPort = DBPort
	}

	getDBType() {
		return this.DBType
	}

	setDBType(DBType) {
		this.DBType = DBType
	}

	getSessionName() {
		return this.sessionname
	}

	getAdminutype() {
		return this.adminutype
	}

	getSaveSidebar() {
		return this.savesidebar
	}

	getSubmitbutton() {
		return this.submitbutton
	}

	getGeneratereport() {
		return this.generatereport
	}

	getApplyFilterSidebar() {
		return this.applyfiltersidebar
	}

	getUpdateSidebar() {
		return this.updatesidebar
	}

	getResetSidebar() {
		return this.resetsidebar
	}

	getCloseSidebar() {
		return this.closesidebar
	}

	getUpdateMyProfile() {
		return this.updatemyprofile
	}

	getChangePassword() {
		return this.changepassword
	}

	getChangeUsertype() {
		return this.changeusertype
	}

	getPage404url() {
		return this.page404url
	}

	getImageurl() {
		return this.imageurl
	}

	getNoDataFound() {
		return this.nodatafound
	}

	getReporturl() {
		return this.reporturl
	}

	getCmpRegisterId() {
		return this.cmpregisterid
	}

	getCmpUnRegisterId() {
		return this.cmpunregisterid
	}

	getCompositionSchemeId() {
		return this.cmpcompositionschemeid
	}

	getTooltip() {
		return this.tooltip
	}



	getStateId() {
		return this.stateid
	}

	getDefaultBirthDate() {
		return this.defaultbirthdate
	}

	getAdminUserId() {
		return this.adminuid
	}

	getDefaultCmpId() {
		return this.defaultcmpid
	}

	setDefaultCmpId(defaultcmpid) {
		this.defaultcmpid = defaultcmpid
	}



	getDefaultImageurl() {
		return this.defaultimageurl
	}

	getDefaultCatImageurl() {
		return this.defaultcatimageurl
	}

	getPaytypeRupeeimageurl() {
		return this.paytyperupeeimageurl
	}

	getDefaultNotiImageurl() {
		return this.defaultnotiimageurl
	}
	getSocketurl() {
		return this.socketurl
	}

	getSocketserver() {
		return this.socketserver
	}
}