// import React from 'react'
// import { Link } from 'react-router-dom'
// import { IISMethods } from './config/IISMethods'
// import Home from './assets/images/home.svg'
// import MyStay from './assets/images/mystay.svg'
// import MyHotel from './assets/images/myhotel.svg'
// import NearBy from './assets/images/nearby.svg'
// import ContactUs from './assets/images/contactus.svg'

// export default function Footer(props) {
//     // let pURL = window.location.pathname.split('/')[1]
//     const pagename = IISMethods.getpagename()

//     return (
//         <>
//             <div className='footer footer-icon'>
//                 <div className='col-12'>
//                     <div className='row'>
//                         <div className="col">
//                             <Link to={IISMethods.setURL('/home')} className={`footer-menu${(pagename === 'home' || pagename === '') ? " active" : ""}`}>{/* ${pURL === 'home' && 'active'}*/}
//                                 <img src={Home} />
//                                 <span>Home</span>
//                             </Link>
//                         </div>
//                         <div className="col">
//                             <Link to={IISMethods.setURL('/mystay')} className={`footer-menu${pagename === 'mystay' || pagename === 'guestrequest' || pagename === 'maintenance' || pagename === 'wifi' || pagename === 'tvchannellineup' || pagename === 'lost' || pagename === 'reportproblem'  ? " active" : ""} `}>{/* ${pURL === 'mystay' && 'active'} */}
//                                 <img src={MyStay} />
//                                 <span>My Stay</span>
//                             </Link>
//                         </div>
//                         <div className="col">
//                             <Link to={IISMethods.setURL('/myhotel')} className={`footer-menu${pagename === 'myhotel' || pagename === 'amenities' ? " active" : ""}`}>{/* ${pURL === 'myhotel' && 'active'} */}
//                                 <img src={MyHotel} />
//                                 <span>My Hotel</span>
//                             </Link>
//                         </div>
//                         <div className="col">
//                             <Link to={IISMethods.setURL('/nearby')} className={`footer-menu${pagename === 'nearby' ? " active" : ""}`}>{/* ${pURL === 'nearby' && 'active'} */}
//                                 <img src={NearBy} />
//                                 <span>Near By</span>
//                             </Link>
//                         </div>
//                         <div className="col">
//                             <Link to={IISMethods.setURL('/contactus')} className={`footer-menu ${pagename === 'contactus' ? " active" : ""}`}>{/* ${pURL === 'nearby' && 'active'} */}
//                                 <img src={ContactUs} />
//                                 <span>Contact Us</span>
//                             </Link>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     )
// }

import React from 'react'
import { Link } from 'react-router-dom'
import { IISMethods } from './config/IISMethods'
import Home from './assets/images/home.svg'
import MyStay from './assets/images/mystay.svg'
import MyHotel from './assets/images/myhotel.svg'
import NearBy from './assets/images/nearby.svg'
import ContactUs from './assets/images/contactus.svg'
import { store } from '.'

export default function Footer(props) {
    // let pURL = window.location.pathname.split('/')[1]
    const pagename = IISMethods.getpagename()

    const getCurrentState = () => {
        return store.getState()['root']
    }

    return (
        <>
            <div className='footer footer-icon'>
                <div className='col-12'>
                    <div className='row'>
                        <div className="col">
                            <Link to={'/home'} className={`footer-menu${(pagename === 'home' || pagename === '') ? " active" : ""}`}>{/* ${pURL === 'home' && 'active'}*/}
                            {/* <Link to={IISMethods.setURL('/home')} className={`footer-menu${(pagename === 'home' || pagename === '') ? " active" : ""}`}>${pURL === 'home' && 'active'} */}
                                <img src={Home} />
                                <span>Home</span>
                            </Link>
                        </div>
                        <div className="col">
                            <Link to={'/mystay'} className={`footer-menu${pagename === 'mystay' || pagename === 'requestitem' || pagename === 'maintenance' || pagename === 'wifi' || pagename === 'tvchannellineup' || pagename === 'lost' || pagename === 'reportproblem' || pagename === 'promotions' || pagename === 'survey' ? " active" : ""} `}>{/* ${pURL === 'mystay' && 'active'} */}
                            {/* <Link to={IISMethods.setURL('/mystay')} className={`footer-menu${pagename === 'mystay' || pagename === 'requestitem' || pagename === 'maintenance' || pagename === 'wifi' || pagename === 'tvchannellineup' || pagename === 'lost' || pagename === 'reportproblem'  ? " active" : ""} `}>${pURL === 'mystay' && 'active'} */}
                                <img src={MyStay} />
                                <span>My Stay</span>
                            </Link>
                        </div>
                        <div className="col">
                            <Link to={'/myhotel'} className={`footer-menu${pagename === 'myhotel' || pagename === 'amenities' || pagename === 'propertylayout' ? " active" : ""}`}>{/* ${pURL === 'myhotel' && 'active'} */}
                            {/* <Link to={IISMethods.setURL('/myhotel')} className={`footer-menu${pagename === 'myhotel' || pagename === 'amenities' ? " active" : ""}`}>${pURL === 'myhotel' && 'active'} */}
                                <img src={MyHotel} />
                                <span>My Hotel</span>
                            </Link>
                        </div>
                        <div className="col">
                            <Link to={getCurrentState().reservationDetails.isguestcheckout === 1 ? '/successfullycheckout' : '/nearby'} className={`footer-menu${pagename === 'nearby' ? " active" : ""}`}>{/* ${pURL === 'nearby' && 'active'} */}
                            {/* <Link to={IISMethods.setURL('/nearby')} className={`footer-menu${pagename === 'nearby' ? " active" : ""}`}>${pURL === 'nearby' && 'active'} */}
                                <img src={NearBy} />
                                <span>Near By</span>
                            </Link>
                        </div>
                        <div className="col">
                            <Link to={'/contactus'} className={`footer-menu ${pagename === 'contactus' ? " active" : ""}`}>{/* ${pURL === 'nearby' && 'active'} */}
                            {/* <Link to={IISMethods.setURL('/contactus')} className={`footer-menu ${pagename === 'contactus' ? " active" : ""}`}>${pURL === 'nearby' && 'active'} */}
                                <img src={ContactUs} />
                                <span>Contact Us</span>
                            </Link>
                        </div>
                        
                            {/* <div className='slide-border'></div> */}
                        
                    </div>
                </div>
            </div>
        </>
    )
}

