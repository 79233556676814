import React, { useEffect, useState, use } from "react";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, deleteToken, isSupported, onMessage } from "firebase/messaging";
// import { firebaseToken, removeToken } from "./firebaseConfig";
// import * as SW from './SW'
// import os from 'os'
import { Config, IISMethods, resBody, setReqParams } from "../config/IISMethods";
import { toast } from 'react-toastify'
// importScripts('https://www.gstatic.com/firebasejs/7.8.0/firebase-app.js');
// importScripts('https://www.gstatic.com/firebasejs/7.8.0/firebase-messaging.js');


let vapidKey = process.env.REACT_APP_FB_PUBLIC_VAPID_KEY // Vapid Public Key

let firebaseConfig = {
    apiKey: process.env.REACT_APP_FB_API_KEY,
    authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FB_DATABASE_URL,
    projectId: process.env.REACT_APP_FB_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FB_APP_ID,
    measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID,
};

// let vapidKey =
//     "BJ3GWWsXifEsJmIlQEBU7DMP4Dy_P2TEgLoWN9teReWXWcalU6DXh26rRH-iMqACcbGh4inFAUzTH5GYiH3n8cM";

// let firebaseConfig = {
//     apiKey: "AIzaSyAsNdHlrBn789RSS8t2Iib2xgsRdjNUfn8",
//     authDomain: "kibbit-a81b9.firebaseapp.com",
//     databaseURL:
//     "https://kibbit-a81b9-default-rtdb.asia-southeast1.firebasedatabase.app",
//     projectId: "kibbit-a81b9",
//     storageBucket: "kibbit-a81b9.appspot.com",
//     messagingSenderId: "23800766540",
//     appId: "1:23800766540:web:fcfec7eb208a2090f294e9",
//     measurementId: "G-424JZJDRN3",
// };


var firebaseApp;
var messaging;

const useFirebase = () => {
    // console.log('VAPID_KEY', process.env.REACT_APP_FB_VAPID_PUBLIC_KEY, process.env.REACT_APP_FB_VAPID_PRIVATE_KEY)
    const [permission, setPermission] = useState(null)

    const firebaseConfiguration = async () => {
        firebaseApp = initializeApp(firebaseConfig);
        messaging = getMessaging(firebaseApp);
        await firebaseToken()
    }

    useEffect(() => {
        // showMacAddress()
        // let loginInfo = IISMethods.getCookiesData(Config.cookiestorage['loginInfo'])
        // if (!loginInfo?.fbtoken) {

        //     onForegroundMessage()
        //     .then((payload) => {
        //         console.log("Received foreground message: ", payload);
        //         const {
        //             notification: { title, body },
        //         } = payload;
        //         // navigator.serviceWorker.ready((swRegister) => {
        //             //     swRegister.showNotification
        //             // })
        //             toast.success(title, {
        //                 theme: "light",
        //             icon: "🟢"
        //         })
        //         // toast(<ToastifyNotification title={title} body={body} />);
        //     })
        //     .catch((err) =>
        //         console.log(
        //             "An error occured while retrieving foreground message. ",
        //             err
        //             )
        //             );
        //     }
    }, [])

    const onForegroundMessage = () =>
        new Promise((resolve) =>
            onMessage(messaging, (payload) => resolve(payload))
        );

    function showMacAddress() {
        // navigator
        //     console.log('Ethernet', window.ger.networkInterfaces())
        // var obj = new window.ActiveXObject("WbemScripting.SWbemLocator");
        // var s = obj.ConnectServer(".");
        // var properties = s.ExecQuery("SELECT * FROM Win32_NetworkAdapterConfiguration");
        // // var e = new Enumerator(properties);

        // // while (!e.atEnd()) {
        // //     e.moveNext();
        // //     var p = e.item();
        // //     if (!p) continue;
        // //     output = output + '<tr bgColor="#FFFFFF">';
        // //     output = output + '<td>' + p.Caption; +'</td>';
        // //     output = output + '<td>' + p.MACAddress + '</td>';
        // //     output = output + '</tr>';
        // // }
        // // output = output + '</table>';
        // console.log('output >> ', properties)
        // document.getElementById("box").MACAddress = output;
    }

    const firebaseToken = async () => {
        // if (getCookieToken) {

        // }
        try {
            let fbtoken = await getToken(messaging, { vapidKey: vapidKey })
            let loginInfo = IISMethods.getCookiesData(Config.cookiestorage['loginInfo'])
            // navigator.serviceWorker.getRegistration().then((sw) => {
            //     sw.pushManager.subscribe({ userVisibleOnly: true, applicationServerKey: vapidKey })
            // })
            if (!loginInfo?.fbtoken || (loginInfo?.fbtoken !== fbtoken)) {
                loginInfo['fbtoken'] = fbtoken
                IISMethods.setCookiesData(Config.cookiestorage['loginInfo'], loginInfo)
                await addData(fbtoken)
            }
        }
        catch (error) {
            console.log("Token Error: ", error)
        }
    }

    const requestPermission = async () => {
        try {
            let permission = await Notification.requestPermission()
            setPermission(permission)
            if (permission === 'granted') {
                let issupported = await isSupported()
                if (issupported) {
                    // SW.register()
                    await firebaseConfiguration()
                }
            }
        } catch (error) {
            setPermission(null)
        }
    }

    // Bhautik 26-04-2023 04:51 PM ~ insert add device data for notification
    const addData = async (fbtoken) => {
        let loginInfo = IISMethods.getCookiesData(Config.cookiestorage['loginInfo'])

        let url = Config.weburl + 'device/add'
        let useraction = 'adddata'

        let reqData = {}
        // reqData['uid'] = loginInfo['bookingid']
        reqData['appversion'] = process.env.REACT_APP_VERSION
        reqData['deviceid'] = fbtoken
        reqData['os'] = IISMethods.getOSDetail()
        reqData['osversion'] = window.navigator.appVersion
        reqData['macaddress'] = IISMethods.generateUUID()
        reqData['devicemodelname'] = 'Windows'
        reqData['edate'] = IISMethods.GetDT().toISOString()
        // reqData['moduletype'] = 'guestexperience'

        await setReqParams(url, reqData)

        await IISMethods.addData(useraction, null, false, { uid: loginInfo.bookingid })

        if (resBody.status === 200) { }
    }

    // Bhautik 26-04-2023 03:35 PM ~ for Firebase delete token
    const removeToken = async () => {
        let loginInfo = IISMethods.getCookiesData(Config.cookiestorage['loginInfo'])
        if (loginInfo.fbtoken) {
            await deleteData(loginInfo.fbtoken)
        }
    }

    // Bhautik 27-04-2023 11:02 PM ~ For Delete Firebase Token
    const deleteData = async (fbtoken) => {
        let loginInfo = IISMethods.getCookiesData(Config.cookiestorage['loginInfo'])

        let url = Config.weburl + 'logout'
        let useraction = 'deletedata'

        let reqData = {}
        // reqData['uid'] = loginInfo['bookingid']
        reqData['appversion'] = process.env.REACT_APP_VERSION
        reqData['deviceid'] = fbtoken
        reqData['os'] = IISMethods.getOSDetail()
        reqData['osversion'] = window.navigator.appVersion
        reqData['macaddress'] = IISMethods.generateUUID()
        reqData['devicemodelname'] = 'Windows'
        reqData['edate'] = IISMethods.GetDT().toISOString()
        // reqData['moduletype'] = 'guestexperience'

        await setReqParams(url, reqData)

        await IISMethods.addData(useraction, null, false, { uid: loginInfo.bookingid })

        if (resBody.status === 200) {
            await deleteToken(messaging)
            delete loginInfo['fbtoken']
            IISMethods.setCookiesData(Config.cookiestorage['loginInfo'], loginInfo)
        }
    }
    // console.log('showMacAddress', showMacAddress)

    return {
        requestPermission,
        removeToken,
        permission
        // firebaseToken
    };
};

export default useFirebase;
