import { IISMethods } from "../config/IISMethods"

const initialState = {
    reservation: {}
}

const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setInfo':
            return {
                ...state,
                reservation: (action.payload.reservation !== undefined ? action.payload.reservation : state.reservation),
            }     

        default:
            return state
    }
}

export default appReducer