import { store } from '../index'
import { Config, IISMethods } from '../config/IISMethods'
import ModalRsuite from './Components/ModalRsuite'
import Car from '../assets/images/car.png'
import Camera from '../assets/images/camera.png'
import HomeViewPhoto from './HomeViewPhoto'
import Rating from '../assets/images/rating.svg'
import Loader from './Components/Loader'
import React, { useRef, useMemo, useEffect, useState } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

export default function RightSidebar(props) {

    const getCurrentState = () => {
        return store.getState()['root']
    }
    const form = 'form-'

    const star_fill = <svg className='star star-curser' xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 64 61.335">
        <path id="star_13_" data-name="star (13)" d="M63.833,23.669A3.4,3.4,0,0,0,60.9,21.33L42.433,19.653l-7.3-17.1a3.4,3.4,0,0,0-6.256,0l-7.3,17.094L3.093,21.33a3.406,3.406,0,0,0-1.931,5.953L15.125,39.528,11.008,57.666a3.4,3.4,0,0,0,5.059,3.675L32,51.817l15.928,9.524a3.4,3.4,0,0,0,5.062-3.675L48.873,39.528,62.836,27.285a3.4,3.4,0,0,0,1-3.616Zm0,0" transform="translate(0 -0.491)" fill="#ffc107" />
    </svg>

    const star_unfill = <svg className='star star-curser' xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 64 61.335">
        <path id="star_14_" data-name="star (14)" d="M14.325,61.827a3.407,3.407,0,0,1-3.315-4.16l4.117-18.137L1.165,27.284a3.4,3.4,0,0,1,1.928-5.953l18.475-1.677,7.3-17.094a3.4,3.4,0,0,1,6.256,0l7.3,17.1L60.9,21.331a3.405,3.405,0,0,1,1.934,5.953L48.875,39.527l4.117,18.137a3.4,3.4,0,0,1-5.061,3.675L32,51.818,16.069,61.344a3.409,3.409,0,0,1-1.744.483ZM32,47.717a3.41,3.41,0,0,1,1.744.482L48.78,57.192,44.894,40.074a3.4,3.4,0,0,1,1.078-3.315L59.156,25.2,41.712,23.612a3.4,3.4,0,0,1-2.827-2.062L32,5.418,25.107,21.552a3.388,3.388,0,0,1-2.818,2.054L4.842,25.19,18.026,36.753A3.4,3.4,0,0,1,19.1,40.071L15.221,57.189,30.256,48.2A3.408,3.408,0,0,1,32,47.717ZM21.427,19.984a.008.008,0,0,1,0,.005Zm21.142-.008,0,.005S42.571,19.979,42.568,19.976Zm0,0" transform="translate(0 -0.492)" fill="#ffe188" />
    </svg>

    const [uploadImageIndex, setUploadImageIndex] = useState(-1)

    const handleVehicleImgModal = (modalname) => {
        props.handleGrid(false, 'modal', modalname, 0)
        if (!getCurrentState().reservationDetails?.reservationratingsubmitted || getCurrentState().reservationDetails?.reservationratingsubmitted === 0) {
            props.setFormData(null, "vehiclerating", "vehicleratingModal")
        }
    }

    try {
        return (
            <>
                {/* vehicle model srushti 21-2-23 */}
                {
                    getCurrentState().modal['vehicleInfoModal']
                    &&
                    <ModalRsuite
                        size={'xs'}
                        open={getCurrentState().modal['vehicleInfoModal']}
                        onClose={() => handleVehicleImgModal('vehicleInfoModal')}
                        title={
                            <></>
                        }
                        body={
                            <div className="col-12">
                                <div className="right-sidebar-content">
                                    <div className="card-content">
                                        <form method="post">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className='col-12'>
                                                            <div className="first-pop-logo">
                                                                <img src={Car} />
                                                            </div>
                                                        </div>

                                                        {/* home page vehicle-model srushti 21-2-23 */}
                                                        <div className="col-12 text-center">
                                                            <h1 className="vehicle-model-title mt-2 mb-4">Please enter your vehicle details</h1>
                                                        </div>

                                                        {
                                                            getCurrentState().rightSidebarFormData[0]?.formfields.filter(formfield => {
                                                                try {
                                                                    return formfield.defaultvisibility || getCurrentState().formData[formfield.condition.field] === formfield.condition.onvalue
                                                                }
                                                                catch {
                                                                    return false
                                                                }
                                                            }).map((formfield, fieldindex) => {
                                                                if (formfield.type === 'table') {
                                                                    return (
                                                                        getCurrentState().formData.vehicleinformations?.length > 0
                                                                            ?
                                                                            getCurrentState().formData.vehicleinformations?.map((data, index) => {
                                                                                return <>{
                                                                                    getCurrentState().rightSidebarFormData[0]?.formfields.map((field, i) => {
                                                                                        if (field.type === 'input-text') {
                                                                                            return (
                                                                                                <div className={field.gridsize} key={i}>
                                                                                                    <div className="form-group validate-input">
                                                                                                        <label className="label-form-control guest-request-maintenance-lable">{field.text}{field.required ? <span className="text-danger"> * </span> : <></>}</label>
                                                                                                        <input disabled={field.disabled} type="text"
                                                                                                            defaultValue={getCurrentState().formData[formfield.field][index][field.field]}
                                                                                                            // onChange={({ target }) => checkValidation(field.type, field.field, target.value)}
                                                                                                            onBlur={(e) => props.handleFormData(field.type, field.field, e.target.value, formfield.type, formfield.field, index)}
                                                                                                            className="form-control" id={'form' + field.field}
                                                                                                            name={field.field} autoComplete="off" placeholder={`Enter ${field.text}`}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                        else if (field.type === 'image') {
                                                                                            return (
                                                                                                <>
                                                                                                    <div className="col-12">
                                                                                                        <p className='report-or-border mb-2'>Or</p>
                                                                                                    </div>

                                                                                                    <div className="col-12 text-center mt-2">
                                                                                                        <h1 className="vehicle-model-title my-2"><span className='d-block'>Capture your vehicle image</span><span className='d-block'>with licence plate</span> </h1>
                                                                                                    </div>

                                                                                                    <div className="col-12 text-center mt-2">
                                                                                                        <div className="vehicle-model-camera-logo">
                                                                                                            <img src={Camera} onClick={(e) => { setUploadImageIndex(index); props.handleGrid(false, 'modal', 'vehicleInfoModal', 0); props.handleGrid(true, 'modal', 'VehicleImageCaptureModal', 1) }} />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </>
                                                                                            )
                                                                                        }
                                                                                    })
                                                                                }</>
                                                                            })
                                                                            :
                                                                            getCurrentState().rightSidebarFormData[0]?.formfields.map((field, index) => {
                                                                                if (field.type === 'input-text') {
                                                                                  
                                                                                    return (
                                                                                        <div className={field.gridsize} key={index}>
                                                                                            <div className="form-group validate-input">
                                                                                                <label className="label-form-control guest-request-maintenance-lable">{field.text}{field.required ? <span className="text-danger"> * </span> : <></>}</label>
                                                                                                <input disabled={field.disabled} type="text"
                                                                                                    defaultValue={getCurrentState().formData[formfield.field][index][field.field] ? getCurrentState().formData[formfield.field][index][field.field] : ''}
                                                                                                    onChange={(e) => props.handleFormData(field.type, field.field, e.target.value, formfield.type, formfield.field, index)}// checkValidation(field.type, field.field, target.value)
                                                                                                    // onBlur={(e) => props.handleFormData(field.type, field.field, e.target.value, formfield.type, formfield.field, index)}
                                                                                                    className="form-control" id={'form' + field.field}
                                                                                                    name={field.field} autoComplete="off" placeholder={`Enter ${field.text}`}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                                else if (field.type === 'image') {
                                                                                    return (
                                                                                        <>
                                                                                            <div className="col-12">
                                                                                                <div className="vehicle-model-or">
                                                                                                    <p>OR</p>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="col-12 text-center mt-2">
                                                                                                <h1 className="vehicle-model-title my-2"><span className='d-block'>Capture your vehicle image</span><span className='d-block'>with licence plate</span> </h1>
                                                                                            </div>

                                                                                            <div className="col-12 text-center mt-2">
                                                                                                <div className="vehicle-model-camera-logo">
                                                                                                    <img src={Camera} onClick={(e) => { setUploadImageIndex(index); props.handleGrid(false, 'modal', 'vehicleInfoModal', 0); props.handleGrid(true, 'modal', 'VehicleImageCaptureModal', 1) }} />
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                            })
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        }
                        footer={
                            <>
                                <div className="col-12 mt-1">
                                    <button type="submit" className="vehicle-model-btn"
                                        onClick={(e) => {
                                            getCurrentState().loading === 0 && props.handleAddButtonClick(e, 'vehicleInfoModal')
                                        }} >
                                        <Loader content={'Submit'} />
                                    </button>
                                </div>
                            </>
                        }
                    />
                }

                {/* optinal vehicle image capture model srushti 22-2-23 */}
                {
                    getCurrentState().modal['VehicleImageCaptureModal']
                    &&
                    <ModalRsuite
                        size={"xs"}
                        open={getCurrentState().modal['VehicleImageCaptureModal']}
                        onClose={() => handleVehicleImgModal('VehicleImageCaptureModal')}
                        body={
                            <HomeViewPhoto
                                handleFormData={props.handleFormData}
                                fieldtype='table'
                                fieldkey='image'
                                fieldvalue={uploadImageIndex}
                            />
                        }
                    />
                }

                {/* rating model srushti 10-4-23 */}
                {
                    getCurrentState().modal['vehicleratingModal']
                    &&
                    <ModalRsuite
                        size={"xs"}
                        open={getCurrentState().modal['vehicleratingModal']}
                        onClose={() => props.handleGrid(false, 'modal', 'vehicleratingModal', 0)}
                        // closeButton={false}
                        title={""}
                        body={
                            <form method='post'>
                                <div className={`col-12 text-center`}>
                                    <div className="first-pop-logo">
                                        <img src={Rating} />
                                    </div>
                                </div>
                                <div className="col-12 text-center">
                                    <h1 className="first-popup-title mt-2 mb-1">Hello {getCurrentState().reservationDetails?.firstname} {getCurrentState().reservationDetails?.lastname}</h1>
                                    <p className="rate-modal-sub-title">We Value Your Feedback</p>
                                </div>
                                {
                                    getCurrentState().rightSidebarFormData[0]?.formfields.filter(formfield => {
                                        try {
                                            return formfield.defaultvisibility || getCurrentState().formData[formfield.condition.field] === formfield.condition.onvalue
                                        }
                                        catch {
                                            return false
                                        }
                                    }).map((formfield, fieldindex) => {
                                        if (formfield.type === 'dropdown') {
                                            return (
                                                <>
                                                    {/* vehicle modal rating 17-4-23 */}

                                                    {/* charmi(09-06-2023)(rating tag print change) */}
                                                    {getCurrentState().masterDataList[formfield.masterdata]?.map((data, dataindex) => {
                                                        let tagdata = data.tagdata
                                                        let ratingdataobj = {}
                                                        ratingdataobj[dataindex] = []
                                                        let singlearray = ratingdataobj[dataindex]
                                                        for (const item of tagdata) {
                                                            if (getCurrentState().formData['reservationcheckinrating'] && getCurrentState().formData['reservationcheckinrating'][dataindex]['stars'] !== 0 && getCurrentState().formData['reservationcheckinrating'][dataindex]['stars'] <= item.stars && item.abovebelowflag === 1) {
                                                                let temptagdataarray = singlearray.map(data => data.tagid)
                                                                let tagdata = item.tags.filter(data => !temptagdataarray.includes(data.tagid))
                                                                singlearray.push(...tagdata)
                                                            }
                                                            else if (getCurrentState().formData['reservationcheckinrating'] && getCurrentState().formData['reservationcheckinrating'][dataindex]['stars'] !== 0 && getCurrentState().formData['reservationcheckinrating'][dataindex]['stars'] >= item.stars && item.abovebelowflag === 0) {
                                                                let temptagdataarray = singlearray.map(data => data.tagid)
                                                                let tagdata = item.tags.filter(data => !temptagdataarray.includes(data.tagid))
                                                                singlearray.push(...tagdata)
                                                            }
                                                        }
                                                        return (
                                                            <div className="col-12 mt-4" key={dataindex}>
                                                                <div className="rate-box">
                                                                    <div className="rate-box-content">
                                                                        <p>{data.rating}</p>
                                                                    </div>
                                                                    <div className="d-flex justify-content-between mx-5">
                                                                        {
                                                                            [...Array(5)].map((star, index) => {
                                                                                if (getCurrentState().formData['reservationcheckinrating'] && getCurrentState().formData['reservationcheckinrating'][dataindex]['stars'] - 1 >= index) {
                                                                                    return <div className=' my-3' key={index}
                                                                                        onClick={() => { props.handleFormData('add-remove-star', formfield.field, index, '', dataindex, data._id, '', formfield.masterdata) }}
                                                                                    >
                                                                                        {star_fill}
                                                                                    </div>
                                                                                } else {
                                                                                    return <div className=' my-3' key={index}
                                                                                        onClick={() => { props.handleFormData('add-remove-star', formfield.field, index, '', dataindex, data._id, '', formfield.masterdata) }}
                                                                                    >
                                                                                        {star_unfill}
                                                                                    </div>
                                                                                }
                                                                            })
                                                                        }
                                                                    </div>
                                                                    {
                                                                        singlearray?.length > 0
                                                                        &&
                                                                        <div className='ratingTagGrid'>
                                                                            {
                                                                                singlearray.map((tagitem, index) => {
                                                                                    let tagdata = IISMethods.getindexfromarray(getCurrentState().formData['reservationcheckinrating'][dataindex]['tags'], 'tagid', tagitem.tagid)
                                                                                    return (
                                                                                        <div className="task-tag-custom-control" key={index}>
                                                                                            <div className="custom-control-tag ">
                                                                                                <label className={`custom-label-rt content-center ${(tagdata !== -1) ? "tagactive" : ''}`}
                                                                                                    onClick={() => props.handleFormData('add-remove-star', formfield.field, tagitem.tagid, 'tags', dataindex, tagitem.tag)}
                                                                                                    style={{
                                                                                                        borderRadius: '20px',
                                                                                                        border: `1px solid #9E9E9E`,
                                                                                                        // background : 'black'
                                                                                                    }} >
                                                                                                    {tagitem.tag}
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                    }
                                                </>
                                            )
                                        }
                                        else if (formfield.type === 'input-textarea') { // for textarea
                                            return (
                                                <div className={formfield.gridsize} key={`${fieldindex}`}>
                                                    <div className="form-group validate-input mt-0">
                                                        <center><label className="first-popup-label my-3">{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}</label></center>
                                                        <textarea
                                                            disabled={formfield.disabled} type="text" rows={3}
                                                            defaultValue={getCurrentState().formData[formfield.field]}
                                                            onChange={(e) => props.handleFormData(formfield.type, formfield.field, e.target.value, '', 0, 'suggestion')}
                                                            className="form-control form-control-textarea"
                                                            id={form + formfield.field}
                                                            name={formfield.field} autoComplete="off"
                                                            placeholder={`Enter ${formfield.text}`}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })
                                }
                            </form>
                        }
                        footer={
                            <>
                                {/* Bhautik 06-07-2023 ~ Comment */}
                                {/* {(getCurrentState().formData.ratingbtnlink && getCurrentState().formData.ratingbtnlink) &&
                                    <div className="col-12 mt-1">
                                        <a target='_blank' href={getCurrentState().formData.ratingbtnlink}>
                                            <span className='vehicle-model-btn text-center'>{getCurrentState().formData.ratingbtnname}</span>
                                        </a>
                                    </div>
                                } */}
                                {/* Bhautik 06-07-2023 ~ */}
                                <div className="col-12 mt-1">
                                    <button type="submit" className="btn-first-popup mb-3"
                                        onClick={(e) => {
                                            getCurrentState().loading === 0 && props.handleAddButtonClick(e, 'vehicleratingModal', null, getCurrentState().formData.ratingbtnlink)
                                        }} >
                                        <Loader content={'Submit'} />
                                    </button>
                                </div>
                            </>
                        }
                    />
                }

                {/* location model srushti 23-2-23 */}
                {
                    getCurrentState().modal?.locationmodal &&
                    <>
                        {
                            getCurrentState().modal['locationmodal']
                            &&
                            <ModalRsuite
                                size={"xs"}
                                open={getCurrentState().modal['locationmodal']}
                                onClose={() => props.handleGrid(false, 'modal', 'locationmodal', 0)}
                                title={
                                    <div className="model-header icon">
                                        <span className="closeicon">
                                            Location
                                        </span>
                                    </div>
                                }
                                body={
                                    <div className="col-md-12 text-center">
                                        <div className='allImages location-transform'>
                                            <TransformWrapper>
                                                <TransformComponent>
                                                    <img
                                                        src={getCurrentState().reservationDetails?.roomplan}
                                                    />
                                                </TransformComponent>
                                            </TransformWrapper>
                                        </div>
                                    </div>
                                }
                            />
                        }
                    </>
                }


            </>
        )
    }
    catch (e) {
        console.log(e)
        return <></>
    }
}


