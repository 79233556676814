import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { store } from '..'
import { Config, IISMethods, JsCall, pagelimit, resBody, setReqParams } from '../config/IISMethods'
import { clearData, setLoading, setProps } from '../redux/actions'
import TvChannelLineUpView from '../view/TvChannelLineUpView'
import { useNavigate, useLocation } from 'react-router-dom'
import useFirebase from '../ServiceWorker/useFirebase'
import useAPICall from '../hooks/useApiCall'


// srushti
const TvChannelLiupController = (props) => {
    const { getReservationDetails, getAccessToken, getPropertyData } = useAPICall()

    // for notification request
    const { requestPermission } = useFirebase()

    const getCurrentState = () => {
        return store.getState()['root']
    }
    // const history = useNavigate()
    // const subdomainname = IISMethods.getBookingIDSubDomain()['subdomainname']
    // const bookingid = IISMethods.getBookingIDSubDomain()['bookingid']
    // const querystring = IISMethods.getQueryString()

    useEffect(() => {
        async function fetchMastersJson() {
            props.setProps({
                fieldOrder: [],
                newFieldOrder: [],
                nextpage: 0,
                pageNo: 1,
                pagelimit: 20,
                pageName: '',
                formName: '',
                sortData: {},
                formData: {},
                filterData: {},
                filterMasterDataList: {},
                oldFilterData: {},
                // modal: {},
                rightSidebarFormData: [],
                masterData: {},
                masterDataList: {}
            })
            //(DO NOT CHANGE WITHOUT PERMISSION)charmi(06-09-2023 12:35)(listing api calling issue fix & install application modal open issue fix)
            await props.clearData()
            await getAccessToken()
            await getReservationDetails()//charmi(06-09-2023 12:11)(API calling issue fix)(wp point > data not loading)
            if (getCurrentState().reservationDetails.ischeckin === 1) {
                await getPropertyData()
                if (getCurrentState().reservationDetails['isreservationexpired'] !== 1) {
                    await requestPermission()
                }
                await setFilterData()//charmi(06-09-2023 12:11)(API calling issue fix)(wp point > data not loading)
                await getList()//charmi(06-09-2023 12:11)(API calling issue fix)(wp point > data not loading)
            }
            //(DO NOT CHANGE WITHOUT PERMISSION)charmi(06-09-2023 12:35)(listing api calling issue fix & install application modal open issue fix)
        }
        fetchMastersJson()
    }, [])

    // const getAccessToken = async () => {
    //     const url = Config.weburl + 'getaccesstoken'
    //     const reqData = {}
    //     let headers = {
    //         key: "Kiosist_98F2B6E0-240A-457B-853A-A0079862315F",
    //         issuer: "website",
    //         'user-agent': window.navigator.userAgent
    //         // key: process.env.REACT_APP_KEY,
    //         // issuer: process.env.REACT_APP_ISSUER
    //     }
    //     if (Config.encryptdata) { headers = { reqheader: IISMethods.encryptData(headers) } }


    //     await IISMethods.axiosrequest("POST", url, reqData, headers, successCallback, errorCallback);
    //     var resp
    //     // function successCallback(response) {
    //     //     resp = response
    //     // }
    //     // function errorCallback(error) {
    //     //     resp = error
    //     // }
    //     function successCallback(response) {
    //         if (Config.encryptdata) {
    //             resp = response || {}
    //             resp.data = JSON.parse(JSON.parse(IISMethods.decryptData(response.data)))
    //             resp.headers = JSON.parse(JSON.parse(IISMethods.decryptData(response.headers.resheader)))
    //         }
    //         else { resp = response }
    //     }

    //     function errorCallback(error) {
    //         if (Config.encryptdata) {
    //             resp = error
    //             resp.data = JSON.parse(JSON.parse(IISMethods.decryptData(error.response.data)))
    //             resp.headers = JSON.parse(JSON.parse(IISMethods.decryptData(error.response.headers)))
    //         }
    //         else { resp = error }
    //     }
    //     if (resp.status === 200) {
    //         let obj = Object.assign({}, {})
    //         if (subdomainname && bookingid) {
    //             obj.subdomainname = subdomainname
    //             obj.bookingid = bookingid
    //             obj.querystring = querystring
    //             obj.token = resp.headers.token
    //             obj.unqkey = resp.data.data.unqkey
    //             obj.uid = resp.data.data.uid
    //             obj.step = 0
    //             IISMethods.setCookiesData(Config.cookiestorage['loginInfo'], obj)
    //             await getReservationDetails()
    //             if (getCurrentState().reservationDetails.ischeckin === 1) {
    //                 await getPropertyData()
    //                 await setFilterData()
    //                 await getList()
    //                 await requestPermission()
    //             }
    //         }
    //     }
    //     else {
    //         localStorage.clear()
    //     }
    // }

    // const getReservationDetails = async () => {
    //     const reqData = {
    //         paginationinfo: {
    //             pageno: 1,
    //             pagelimit: 20,
    //             filter: {
    //                 _id: bookingid
    //             },
    //             sort: {}
    //         }
    //     }
    //     if (bookingid !== null) {
    //         const url = Config.weburl + "guestexpreservation"
    //         await setReqParams(url, reqData)
    //         await IISMethods.addData("reservation", "home")
    //     }
    //     if (resBody.status === 200) {
    //         await props.setProps({ reservationDetails: resBody.data[0] })
    //         if (resBody.data[0].ischeckin === 0) {
    //             history(`/notcheckedin${querystring}`)
    //         }
    //         if (getCurrentState().reservationDetails.isreservationexpired === 1) {
    //             history({ pathname: `/linkexpired`, querystring })
    //         }
    //     } else {
    //         localStorage.clear()
    //     }
    // }
    // const getPropertyData = async () => {
    //     const url = Config.weburl + 'property'
    //     const useraction = 'listproperty'
    //     const reqBody = {
    //         paginationinfo: {
    //             pageno: 1,
    //             filter: {
    //                 _id: getCurrentState().reservationDetails.propertyid
    //             },
    //             sort: {}
    //         }
    //     }

    //     await setReqParams(url, reqBody)
    //     await IISMethods.listData(useraction)
    //     if (resBody.status === 200) {
    //         let cookiedata = IISMethods.getCookiesData(Config.cookiestorage['loginInfo'])
    //         cookiedata.companyid = resBody.data[0].companyid
    //         IISMethods.setCookiesData(Config.cookiestorage['loginInfo'], cookiedata)
    //         await props.setProps({ propertyData: resBody.data[0] })
    //     }
    // }

    // List Data
    const getList = async () => {
        const url = Config.weburl + getCurrentState().rightSidebarFormData[0]?.apipath
        const useraction = 'list' + props.pagename + 'data'
        let filter = {}
        Object.entries(getCurrentState().filterData).forEach(([key, value]) => {
            if (typeof (value) === 'number') {
                if (value !== 0)
                    filter[key] = value
            }
            else
                filter[key] = value
        })

        if (!getCurrentState().data.length) {
            await props.setProps({ pageNo: 1 })
        }
        let searchtext = filter.searchtext || ''
        delete filter.searchtext
        const reqBody = {
            searchtext: searchtext,
            paginationinfo: {
                pageno: getCurrentState().pageNo,
                pagelimit: getCurrentState().pagelimit,
                filter: filter,
                sort: getCurrentState().sortData
            }
        }
        await setReqParams(url, reqBody)
        await IISMethods.listData(useraction)
        if (resBody.status === 200) {
            if (getCurrentState().pageNo === 1) {
                await props.clearData()
            }
            await props.setProps({
                data: resBody.data,
                nextpage: resBody.nextpage,
                pageName: resBody.pagename,
                formName: resBody.formname
            })
        }
        else {
        }
    }

    const setFilterData = async () => {
        //formdata set 
        let tempFormData = {}
        getCurrentState().rightSidebarFormData && getCurrentState().rightSidebarFormData?.map((data) => {
            return data.formfields.map((fields) => {
                tempFormData[fields.field] = fields.defaultvalue !== undefined ? fields.defaultvalue : ''
            })
        })
        tempFormData['propertyid'] = getCurrentState().reservationDetails.propertyid
        props.setProps({ filterData: tempFormData })
        //error handling
        for (const data of getCurrentState().rightSidebarFormData) {
            for (const fields of data.formfields) {
                JsCall.handleError('form-' + fields.field, false)
            }
        }
        //error handling
        //select picker data call
        for (const data of getCurrentState().rightSidebarFormData) {
            for (const fields of data.formfields) {

                if (fields.masterdata && !fields.masterdataarray) {
                    if (fields.masterdatadependancy || !getCurrentState().masterData[fields.masterdata])
                        await getFilterData(1, fields, getCurrentState().formData)
                    if (fields.field === 'categoryid') {
                        getCurrentState().filterData[fields.field] = getCurrentState().filterMasterDataList[fields.masterdata].length > 0 ? getCurrentState().filterMasterDataList[fields.masterdata][0]._id : ''
                        props.setProps({ filterData: getCurrentState().filterData })
                    }
                }
                else if (fields.masterdata && fields.masterdataarray && !getCurrentState().masterData[fields.masterdata]) {
                    var array = []
                    for (const object of fields.masterdataarray) {
                        array.push({
                            label: object,
                            value: object
                        })
                    }
                    getCurrentState().masterData[fields.masterdata] = array
                    await props.setProps({ masterData: IISMethods.getCopy(getCurrentState().masterData) })
                }
            }
        }
    }

    const handleFilterData = async (type, key, value) => {
        getCurrentState().filterData[key] = value
        await props.setProps({ filterData: IISMethods.getCopy(getCurrentState().filterData), pageNo: 1 })
        let obj = IISMethods.getobjectfromarray(getCurrentState().fieldOrder, 'formdatafield', key)
        if (obj?.onchangefill) {
            for (const field of obj.onchangefill) {
                let fieldobj = IISMethods.getobjectfromarray(getCurrentState().fieldOrder, 'field', field)
                getCurrentState().filterData[fieldobj.formdatafield] = []
                await getFilterData(1, fieldobj)
            }
        }
        if(!getCurrentState().filterData['searchtext']){
            await getList()
        }
    }
    // jatin 14-08-2023
    const handleApplyFilterButtonClick = async (e, type) => {
        if(e) e.preventDefault()
         if (type === 'removevalue') {
             getCurrentState().filterData['searchtext'] = ''
             await props.setProps({ pageNo: 1, oldFilterData: IISMethods.getCopy(getCurrentState().filterData) })
             await props.clearData()
             await getList()
         }
         else {
             await props.setProps({ pageNo: 1, oldFilterData: IISMethods.getCopy(getCurrentState().filterData) })
             await props.clearData()
             await getList()
         }
     }
      // jatin 15-08-2023 11:53 PM
    const handleSerachClose = async()=>{
        getCurrentState().filterData["searchtext"] = " "
        await props.setProps({ filterData: IISMethods.getCopy(getCurrentState().filterData), pageNo: 1 })     
        await getList()

     }

    const getFilterData = async (pageNo, fieldobj, formData, storemasterdatabyfield) => {
        var filter = {}
        var isdepent = 0
        if (fieldobj.dependentfilter) { //dependent filter set - object set
            Object.keys(fieldobj.dependentfilter).map(key => {
                const value = formData[fieldobj.dependentfilter[key]]
                if (value) {
                    isdepent = 1
                    filter[key] = value
                }
            })
        }

        if (fieldobj.staticfilter) { //static filter object assign
            Object.assign(filter, fieldobj.staticfilter)
        }

        const masterdatakey = (fieldobj.storemasterdatabyfield || storemasterdatabyfield ? fieldobj.field : fieldobj.masterdata)
        if (!fieldobj.masterdatadependancy || isdepent == 1) { //show only non dependent dropdown or dependent dropdown dependency fullfill
            const url = Config.weburl + fieldobj.apipath
            const useraction = 'list' + fieldobj.masterdata + 'data'
            filter = {
                ...IISMethods.getCopy(fieldobj.filter),
                ...filter,
            }
            var reqBody = {
                getallcategory: 1,
                paginationinfo: {
                    pageno: pageNo,
                    pagelimit: pagelimit[pagelimit.length - 1].value,
                    filter: filter,
                    sort: {}
                }
            }
            await setReqParams(url, reqBody)
            await IISMethods.listData(useraction, true)
            if (resBody.status === 200) {
                getCurrentState().filterMasterDataList = IISMethods.getCopy(getCurrentState().filterMasterDataList)
                if (pageNo === 1) {
                    getCurrentState().filterMasterDataList[masterdatakey] = []
                }
                getCurrentState().filterMasterDataList[masterdatakey] = getCurrentState().filterMasterDataList[masterdatakey].concat(resBody.data)
                await props.setProps({ filterMasterDataList: getCurrentState().filterMasterDataList })
            }
            else {
            }
        }
        else {
            delete getCurrentState().filterMasterDataList[masterdatakey]
            await props.setProps({ filterMasterDataList: IISMethods.getCopy(getCurrentState().filterMasterDataList) })
        }
    }

    const handleScroll = async (e) => {
        let element = e.target
        if (element.scrollHeight - element.scrollTop === element.clientHeight || ((element.scrollHeight - element.scrollTop) - 1 === element.clientHeight) || ((element.scrollHeight - element.scrollTop) - 1 > element.clientHeight)) {
            if (getCurrentState().nextpage && (getCurrentState().pageNo * getCurrentState().pagelimit === getCurrentState().data.length)) {
                await props.setProps({ pageNo: getCurrentState().pageNo + 1 })
                await getList()
            }
        }
    }
    
    return (
        <>
            <TvChannelLineUpView
                handleFilterData={handleFilterData}
                handleScroll={handleScroll}
                handleApplyFilterButtonClick={handleApplyFilterButtonClick}
                handleSerachClose={handleSerachClose}
            />
        </>
    )
}

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    clearData: (payload) => dispatch(clearData(payload)),
    setProps: (payload) => dispatch(setProps(payload)),
    setLoading: (payload) => dispatch(setLoading(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TvChannelLiupController)
