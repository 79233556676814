import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Config, IISMethods, allowdedImage } from '../config/IISMethods'
import ReportProblemController from '../Controller/ReportProblemController'
import { setProps } from '../redux/actions'
import { store } from '..'

const ReportProblem = (props) => {
    useEffect(() => {
        async function fetchMyAPI() {
            await props.setProps({
                rightSidebarFormData: []
            })
        }
        fetchMyAPI()
    }, [])
    return (
        <ReportProblemController
            masterjson={masterjson}
            pagename={props.pagename}
        />
    )
}

const getCurrentState = () => {
    return store.getState()['root']
}

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    setProps: (payload) => dispatch(setProps(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(ReportProblem)

const masterjson = (key) => {
    let propertyid = getCurrentState().reservationDetails.propertyid
    let roomnoid = getCurrentState().reservationDetails.roomnoid

    switch (key) {
        case 'reportproblemjson':
            return [
                {
                    rightsidebarsize: Config.getModalsizeclasses()['sm'],
                    pagename: 'reportproblem',
                    formname: 'reportproblem',
                    apipath: 'complaint/add',
                    alias: 'reportproblem',
                    formfields: [
                        //0
                        {

                            'field': 'assignmentforid',
                            'text': 'Assignment For',
                            'type': Config.getHtmlcontorls()['radio'],
                            'disabled': false,
                            'defaultvisibility': true,
                            'required': false,
                            'cleanable': false,
                            'apipath': 'masters/amenitiescategory',//api path
                            'masterdata': 'assignmentfor',
                            'masterdatafield': 'name',
                            'formdatafield': 'assignmentforid',
                            // 'onchangefill': ['buildingid', 'wingid', 'productsubcategoryid'],
                            'gridsize': Config.getGridclasses()['col-12'],
                            'defaultselected': 0,
                            // 'resetfield': ['floorwiseroomid'],
                            // 'extrafield' : 'assignmentforid'
                        },
                        {
                            // 1
                            'field': 'buildingid',
                            'text': 'Building',
                            'type': Config.getHtmlcontorls()['dropdown'],
                            'disabled': false,
                            'defaultvisibility': true,
                            'required': false,
                            'cleanable': false,
                            'apipath': 'property/building',//api path
                            'masterdata': 'buildingname',
                            'masterdatafield': 'buildingname',
                            'formdatafield': 'buildingid',
                            'dependentfilter': { 'propertyid': 'propertyid' },
                            'onchangefill': ['wingid'],
                            'onchangedata': ['buildingid'],
                            'gridsize': Config.getGridclasses()['col-12'] + ' ' + Config.getGridclasses()['col-md-6'],
                            'extrafield': ['propertyid', 'propertyname'],
                            'staticfilter': { 'propertyid': propertyid, 'isactive': 1 },
                        },
                        {
                            // 2
                            'field': 'wingid',
                            'text': 'Wing',
                            'type': Config.getHtmlcontorls()['dropdown'],
                            'disabled': false,
                            'defaultvisibility': true,
                            'required': false,
                            'cleanable': false,
                            'apipath': 'property/wing',//api path
                            'masterdata': 'wingname',
                            'masterdatafield': 'wingname',
                            'formdatafield': 'wingid',
                            'dependentfilter': { 'buildingid': 'buildingid' },
                            'gridsize': Config.getGridclasses()['col-12'] + ' ' + Config.getGridclasses()['col-md-6'],
                            'masterdatadependancy': true,
                            'staticfilter': { 'isactive': 1 },
                        },
                        {
                            // 3
                            'field': 'areaid',
                            'text': 'Area',
                            'type': Config.getHtmlcontorls()['text-array'],
                            'disabled': false,
                            'defaultvisibility': true,
                            'required': false,
                            'cleanable': false,
                            'datafield': 'areadata',
                            'masterdatafield': 'area',
                            'formdatafield': 'floorwisearea',
                            'gridsize': Config.getGridclasses()['col-12'],
                        },
                        {
                            //4
                            'field': 'productsubcategoryid',
                            'text': ' Sub Category',
                            'type': Config.getHtmlcontorls()['dropdown'],
                            'disabled': false,
                            'required': true,
                            'defaultvisibility': false,
                            'gridsize': Config.getGridclasses()['col-12'] + ' ' + Config.getGridclasses()['col-md-12'],
                            'apipath': 'complaint/productsubcategory',
                            'masterdata': 'productsubcategory',
                            'masterdatafield': 'productsubcategory',
                            'formdatafield': 'productsubcategory',
                            'cleanable': false,
                            'searchable': false,
                            'istablefield': 1,
                            'onchangefill': ['productid'],
                            'parantstaticfilter': { 'ismaintainable': 1, 'isrequestitem': 1 }, // for pass filter outside pagination
                            'staticfilter': { 'propertyid': propertyid },
                        },
                        {
                            //5
                            'field': 'productid',
                            'text': 'Problem Item',
                            'type': Config.getHtmlcontorls()['dropdown'],
                            'apipath': 'property/amenitiesserialnumber',//api path
                            'disabled': false,
                            'defaultvisibility': true,
                            'required': true,
                            'gridsize': Config.getGridclasses()['col-12'] + ' ' + Config.getGridclasses()['col-md-12'],
                            'masterdata': 'product',
                            'masterdatafield': 'productname',
                            'formdatafield': 'product',
                            'onchangefill': ['issue'],
                            'istablefield': 1,
                            // 'masterdatadependancy': true,
                            // 'staticfilter': {
                            //     // 'roomnoid': [roomdata._id]
                            //     'roomnoid': [roomnoid]
                            // },
                            'parantstaticfilter': {
                                'ismaintainable': 1,//charmi(27-07-2023 10:47)(PARAS)
                                'isrequestitem': 1//charmi(27-07-2023 10:47)(PARAS)
                            },
                            // 'dependentfilter': { 'productsubcategoryid': 'productsubcategoryid' },
                            // 'condition': [
                            //     {
                            //         'field': 'isoutoforder',
                            //         'onvalue': 0
                            //     }
                            // ],
                            'groupwise': true,//charmi(08-08-2023 112:06)
                            'groupfield': 'productsubcategory',//charmi(08-08-2023 112:06)
                        },
                        {
                            //6
                            'field': 'issue',
                            'text': 'Issue',
                            'type': Config.getHtmlcontorls()['multipleselectdropdown'],
                            'disabled': false,
                            'required': true,
                            'defaultvisibility': true,
                            'gridsize': Config.getGridclasses()['col-12'] + ' ' + Config.getGridclasses()['col-md-12'],
                            'apipath': 'product/productmaster/issue',
                            'masterdata': 'issue',
                            'masterdatafield': 'issue',
                            'formdatafield': 'issue',
                            'cleanable': true,
                            'searchable': false,
                            'istablefield': 1,
                            // 'extrafield': ['maintenance', 'housekeeping'],
                            'parantfilter': 1,
                            'masterdatadependancy': true,
                            'dependentfilter': { 'productid': 'productid' },
                        },
                        {
                            //7
                            'field': 'productdata',
                            'text': 'table',
                            'type': Config.getHtmlcontorls()['table'],
                            'disabled': false,
                            'defaultvisibility': false,
                            'required': true,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'tablefield': ['productsubcategoryid', 'productid', 'issue'],
                            'condition': [
                                {
                                    'field': 'isoutoforder',
                                    'onvalue': 0
                                }
                            ],
                        },
                        {
                            //8
                            'field': 'problem',
                            'text': 'Problem',
                            'type': Config.getHtmlcontorls()['multipleselectdropdown'],
                            'disabled': false,
                            'defaultvisibility': false,
                            'required': false,
                            'cleanable': true,
                            'apipath': 'guestexperience/problemmaster',//api path
                            'masterdata': 'problem',
                            'masterdatafield': 'name',
                            'formdatafield': 'problem',
                            'gridsize': Config.getGridclasses()['col-12'] + ' ' + Config.getGridclasses()['col-md-12'],
                            // 'dependentfilter': { 'guestmoodid': 'guestmoodid' },
                            'staticfilter': { 'propertyid': propertyid },
                            'condition': [
                                {
                                    'field': 'isoutoforder',
                                    'onvalue': 0
                                }
                            ]
                        },
                        {
                            //9
                            'field': 'description',
                            'text': 'Problem Description',
                            'type': Config.getHtmlcontorls()['input-textarea'],
                            'disabled': false,
                            'required': false,
                            'defaultvisibility': true,
                            'gridsize': Config.getGridclasses()['col-12'] + ' ' + Config.getGridclasses()['col-10'],
                        },
                        {
                            //10
                            'field': 'images',
                            'text': 'Add Photos',
                            'type': Config.getHtmlcontorls()['image'],
                            'disabled': false,
                            'defaultvisibility': true,
                            'required': false,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'filetypes': allowdedImage,
                            'multiple': true
                        },

                        // {
                        //     // 11
                        //     'field': 'floorwiseareaid',
                        //     'text': 'Floor',
                        //     'type': 'tree-label-picker',
                        //     'disabled': false,
                        //     'required': true,
                        //     'defaultvisibility': true,
                        //     'gridsize': Config.getGridclasses()['col-12'] + ' ' + Config.getGridclasses()['col-md-9'],
                        //     'dependentfilter': { 'areatype': 'areatypeid' },
                        // },

                        {
                            'field': 'floorwiseareaid',
                            'text': 'Floor',
                            'type': 'tree-label-picker',
                            'disabled': false,
                            'required': true,
                            'defaultvisibility': true,
                            'gridsize': Config.getGridclasses()['col-12'] + ' ' + Config.getGridclasses()['col-md-9'],
                            'apipath': 'task/listarea',
                            'masterdata': 'floorwisearea',
                            'masterdatafield': 'floor',
                            'formdatafield': 'floorwiseareaid',
                            'cleanable': true,
                            'searchable': false,
                            'parantfilter': 1,
                            'masterdatadependancy': true,
                            'dependentfilter': { 'categoryid': 'categoryid', 'propertyid': 'propertyid', 'buildingid': 'buildingid', 'wingid': 'wingid', 'subcategory': 'tasksubcategoryid', 'areatype': 'areatypeid' },

                        },
                        {

                            'field': 'areatypeid',
                            'text': 'Area',
                            'type': 'multipletagpicker',
                            'disabled': false,
                            'required': true,
                            'defaultvisibility': true,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'apipath': 'property/areatype',
                            'masterdata': 'areatype',
                            'masterdatafield': 'name',
                            'formdatafield': ['areatypeid'],
                            'cleanable': true,
                            'defaultselected': 1,
                            'searchable': false,
                            'parantfilter': { 'showall': 1 },
                            'dependentfilter': { 'propertyid': 'propertyid' },
                            'masterdatadependancy': true,
                            'defaultselected': 1,
                            'onchangefill': ['floorwiseareaid'],
                            'staticfilter': {
                                'isactive': 1
                            },
                        },
                    ]
                }
            ]
        case 'complaintrating':
            return [
                {
                    rightsidebarsize: Config.getModalsizeclasses()['sm'],
                    pagename: 'complaintrating',
                    formname: 'complaint rating',
                    apipath: 'guestexperience/complaintguestrating/add',

                    formfields: [
                        //0
                        {
                            'field': 'complaintguestrating',
                            'text': 'stars',
                            'type': Config.getHtmlcontorls()['dropdown'],
                            'disabled': false,
                            'defaultvisibility': true,
                            'cleanable': true,
                            'required': true,
                            'gridsize': Config.getGridclasses()['col-12'] + ' ' + Config.getGridclasses()['col-md-4'],
                            'apipath': 'masters/ratings',
                            'masterdata': 'reportrating',
                            'masterdatafield': 'rating',
                            'formdatafield': 'rating',
                            'istablefield': 1,
                            'staticfilter': {
                                'propertyid': getCurrentState().reservationDetails.propertyid,
                                'ratingcategoryid': Config.ratingcategoryid.complaintratings
                            },
                        },
                        //1
                        {
                            'field': 'suggestion',
                            'text': 'Suggestion',
                            'type': Config.getHtmlcontorls()['input-textarea'],
                            'disabled': false,
                            'cleanable': true,
                            'defaultvisibility': true,
                            'required': false,
                            'gridsize': Config.getGridclasses()['col-12'],
                        },

                    ]
                }
            ]

        case 'reportfilterjson':
            return [
                {
                    rightsidebarsize: Config.getModalsizeclasses()['sm'],
                    pagename: 'reportfilter',
                    formname: 'report filter',
                    // apipath: 'complaint',
                    formfields: [
                        //0
                        {
                            'field': 'complaintstatustype',
                            'text': 'Status',
                            'type': Config.getHtmlcontorls()['multipleselectdropdown'],
                            'disabled': false,
                            'defaultvisibility': true,
                            'cleanable': true,
                            'required': false,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'apipath': 'masters/complaintstatus',
                            'masterdata': 'status',
                            'masterdatafield': 'status',
                            'formdatafield': 'complaintstatustype',
                            'selectall': true,
                        },
                        //1 JENIL 28/6/2023 6:22 PM
                        {
                            'field': 'fromdate',
                            'text': 'From Date',
                            'type': Config.getHtmlcontorls()['datepicker'],
                            'disabled': false,
                            'cleanable': true,
                            'defaultvisibility': true,
                            'required': false,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'resetfield': ['todate'],
                            'disablefromdate': IISMethods.GetDateTimefromISOString()
                        },
                        //2 JENIL 28/6/2023 6:22 PM
                        {
                            'field': 'todate',
                            'text': 'To Date',
                            'type': Config.getHtmlcontorls()['datepicker'],
                            'disabled': false,
                            'cleanable': true,
                            'defaultvisibility': false,
                            'required': true,
                            'gridsize': Config.getGridclasses()['col-12'],
                            'disablefromfield': 'fromdate'
                        },
                    ]
                }
            ]

        default:
            return []
    }
}