import { connect } from "react-redux"
import { clearData, setLoading, setProps } from "../redux/actions"
import ChatUsView from "../view/ChatUsView"
import { useEffect, useRef, useState } from "react"
import { Config, IISMethods, allowdedAudio, allowdedDocs, allowdedSVG, allowdedVideo, resBody, setReqParams, socket } from "../config/IISMethods"
import { store } from ".."
import useFirebase from "../ServiceWorker/useFirebase"
import { useLocation, useNavigate } from "react-router-dom"
import { allowdedImage } from "../config/IISMethods"
import useAPICall from '../hooks/useApiCall'
import html2canvas from 'html2canvas'
import { useSocket, useSocketListener } from "../hooks/useSocket"
import axios from "axios"


const ChatUsController = (props) => {
    const { socketEmit } = useSocket()

    const { getReservationDetails, getAccessToken, getPropertyData } = useAPICall()

    //render pdf state JENIL 10/8/2023 5:03 PM
    const [renderPdf, setRenderPdf] = useState("")
    const [chatMsgID, setChatMsgID] = useState("")

    // for notification request
    const { requestPermission, removeToken } = useFirebase()
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false);
    //chat option load JENIL
    const [optionIdLoad, setOptionIdLoad] = useState("");
    const [fileLoader, setFileLoader] = useState(false);
    const getCurrentState = () => store.getState()['root']
    const getInfoState = () => store.getState()['info']

    const location = useLocation();
    const [chatId, setChatId] = useState("");
    // console.log('chatId', chatId)

    //find pagename JENIL 04/08/2023 9:40 PM
    const pagename = IISMethods.getpagename()

    //send categoryids to list and add API JENIL 9:42 PM
    const categoryIds = {
        extendstay: "646ddb78f288c1b0423cc3a5", //extend stay
        latecheckout: "645a348357f45b2c947e5282", //late checkout
        checkout: "645a348357f45b2c947e5283", //Checkout subcategory
        chatwithus: "625d5ed5220ccd5a20904b79",
        other: "625d5ed5220ccd5a20904c79",
    }

    //focus and blur event to subscribe
    const [isFocus, setIsFocus] = useState(false);

    //first call - default props set & grid call - getlist
    useEffect(() => {
        async function fetchMastersJson() {
            props.setProps({
                fieldOrder: [],
                newFieldOrder: [],
                nextpage: 0,
                pageNo: 1,
                pagelimit: 20,
                pageName: '',
                formName: '',
                sortData: {},
                formData: {},
                filterData: {},
                oldFilterData: {},
                modal: {},
                rightSidebarFormData: [],
                masterData: {},
                masterDataList: {},
                notificationCount: 0,
            })
            //remove notification count when chat open
            IISMethods.setLocalData("notificationCount", 0)
            await props.clearData()
            // let guestInfo = IISMethods.getCookiesData(Config.cookiestorage['loginInfo'])
            // if (!guestInfo || !guestInfo.querystring || (guestInfo.querystring !== querystring)) {
            //     await getAccessToken()
            // }
            // else {
            //     await getReservationDetails()
            //     //  in this module don't need menu - for the reason comment srushti 22-6-23
            //     //  await getMenus()
            //     if (getCurrentState().reservationDetails.ischeckin === 1) {
            //         await getPropertyData()
            //     }
            // }
            //JENIL 11/7/2023 4:08 PM
            await getAccessToken()
            await getReservationDetails()
            await getPropertyData()
            if (getCurrentState().reservationDetails.ischeckin === 1 && getCurrentState().reservationDetails['isreservationexpired'] !== 1) {
                await requestPermission()
            }
            getCurrentState().chatList["guestchatbotcategory"] = categoryIds[pagename];
            await props.setProps({ chatList: IISMethods.getCopy(getCurrentState().chatList) })
        }
        fetchMastersJson()
        //find OS JENIL 5/7/2023 4:55 PM
        const getOS = () => {
            var userAgent = navigator.userAgent,
                platform = navigator.platform,
                macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
                windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
                iosPlatforms = ["iPhone", "iPad", "iPod"],
                os = null;
            if (macosPlatforms.indexOf(platform) !== -1) {
                os = "Mac OS";
            } else if (iosPlatforms.indexOf(platform) !== -1) {
                os = "iOS";
            } else if (windowsPlatforms.indexOf(platform) !== -1) {
                os = "Windows";
            } else if (/Android/.test(userAgent)) {
                os = "Android";
            } else if (!os && /Linux/.test(platform)) {
                os = "Linux";
            }
            return os;
        }
        //when focus on ios device tab this function called JENIL 5/7/2023 4:55 PM
        const focusTab = async () => {
            if (getOS() === "iOS" && location.pathname === "/chatwithus") {
                setIsFocus(!isFocus)
            }
        }
        window.addEventListener("focus", focusTab)
        //unsubscribe socket when leave the component
        return () => {
            // socket.emit("unsubscribe", getCurrentState()?.reservationDetails?._id);
            props.setProps({ chatList: {} })
        }
    }, [])

    useEffect(() => {
        if (getCurrentState()?.reservationDetails?._id) {
            //set roomid-> setuid & reservationid-> subscribe
            // subdomain +  'guestmsg' + reservationid
            socketEmit("subscribe", IISMethods.getGuestInfo()['subdomainname'] + 'guestmsg' + getCurrentState()?.reservationDetails?._id);
        }
        return () => {
            socketEmit("unsubscribe", IISMethods.getGuestInfo()['subdomainname'] + 'guestmsg' + getCurrentState()?.reservationDetails?._id);
        }
    }, [isFocus, getCurrentState()?.reservationDetails?._id])

    //get chat list and socket called
    useEffect(() => {
        if (getCurrentState()?.reservationDetails?._id) {
            handleUserList();
        }
    }, [getCurrentState().reservationDetails?._id])

    //push message into chatlist state
    const getSocketResponse = async (response) => {
        //resposne is an array push to chatList JENIL 19/7/2023 3:08 PM
        console.log('response :>> ', response);
        console.log('getCurrentState().chatList["guestchatbotcategory"] ', getCurrentState().chatList["guestchatbotcategory"]);
        let changeChatList = getCurrentState()?.chatList?.chatList ? [...getCurrentState().chatList.chatList] : []
        if (response?.length > 0) {
            response.forEach((item) => {
                if (pagename === "chatwithus" || item?.categorytypeid === getCurrentState().chatList["guestchatbotcategory"]) {
                    let index = changeChatList.findIndex((msg) => msg._id === item._id);
                    if (index >= 0) {
                        changeChatList.splice(index, 1, item);
                    } else {
                        changeChatList.push({ ...item });
                    }
                }
            })
        }
        getCurrentState().chatList['chatList'] = [...changeChatList];
        await props.setProps({ chatList: IISMethods.getCopy(getCurrentState().chatList) })
    }

    const handleFormData = async (field, type, value, extraFields, fileTypes) => {
        let formData = {}
        switch (type) {
            case "input-text":
                // if (value._id) {
                //update message set form data
                //     getCurrentState().formData[field] = value.message
                //     getCurrentState().formData["_id"] = value._id
                // } else {
                formData[field] = value
                // }
                break;
            case "image"://charmi(05-10-2023) - add case for paymentcard
                if (field === 'cardimage') {
                    formData[field] = value
                }
                else {
                    formData[field] = await IISMethods.fileToBase64(value)
                }
                break;
            case "file":
                let arr = getCurrentState().formData[field]?.length > 0 ? [...getCurrentState().formData[field]] : []
                for (let i = 0; i < value.length; i++) {
                    let obj = {}
                    obj.name = await value[i].name
                    let type = "." + obj.name.split(".").pop();
                    //if file has image then resize them
                    if (allowdedImage.includes(type)) {
                        obj.url = await IISMethods.resizeImageFile(value[i]);
                        obj.type = 1//type =1 for image(allowdedImage)
                    } else {
                        if (!IISMethods.checkFiletype(value[i].name, fileTypes)) {
                            IISMethods.notify("Invalid File Type", 2)
                        } else {
                            if (allowdedSVG.includes(type)) {
                                obj.type = 1//type= 1 for svg image only(allowdedSVG)
                            } else if (allowdedVideo.includes(type)) {
                                obj.type = 2//type= 2 for video(allowdedVideo)
                            } else if (allowdedAudio.includes(type)) {
                                obj.type = 3//type= 3 for audio(allowdedAudio)
                            } else if (allowdedDocs.includes(type)) {
                                obj.type = 4//type= 4 for document(allowdedDocs)
                            } else {
                                obj.type = 5// type = 5 for other type
                            }
                            obj.url = await IISMethods.fileToBase64(value[i])
                        }
                    }
                    arr.push(obj)
                }
                formData[field] = [...arr];
                break;
            case "dropdown":
                formData[field] = value
                extraFields.forEach(element => {
                    formData[element] = 0
                });
                break;
            default:
                break;
        }
        await props.setProps({
            formData: IISMethods.getCopy({ ...getCurrentState().formData, ...formData })
        })
    }

    //get all files media of chat
    const handleFileList = async (scroll = 0) => {
        setFileLoader(true);
        if (scroll === 0) {
            getCurrentState().filterData["pageNo"] = 1;
            await props.setProps({ filterData: IISMethods.getCopy(getCurrentState().filterData) })
        }
        let filter = {}
        filter['reservationid'] = getCurrentState().reservationDetails?._id
        filter['type'] = [getCurrentState()?.filterData?.fileType || 1]
        const url = Config.weburl + 'guestexperience/guestmsg/files'
        const useraction = 'listguestexperiencefiles'
        let req = {
            paginationinfo: {
                pageno: getCurrentState().filterData.pageNo,
                pagelimit: 20,
                filter: filter,
                sort: {}
            }
        }
        await setReqParams(url, req)
        await IISMethods.listData(useraction)
        if (resBody.status === 200) {
            getCurrentState().chatList["allChatFiles"] =
                scroll === 1 ?
                    (getCurrentState().chatList?.allChatFiles).concat(IISMethods.getCopy(resBody.data))
                    :
                    IISMethods.getCopy(resBody.data)
            getCurrentState().filterData["nextpage"] = resBody.nextpage
            await props.setProps({ chatList: IISMethods.getCopy(getCurrentState().chatList), filterData: IISMethods.getCopy(getCurrentState().filterData) })
            setFileLoader(false);
        }
        else { console.log("err"); }
    }

    //to open modal
    const handleGrid = async (action, name, isOpen, value, chat = {}) => {
        switch (name) {
            case "allMedia":
                if (isOpen) {
                    //set filtype for gallery and pagination also
                    getCurrentState().filterData["fileType"] = 1;
                    getCurrentState().filterData["pageNo"] = 1;
                    await props.setProps({ filterData: IISMethods.getCopy(getCurrentState().filterData) })
                    await handleFileList();
                    //if direct open image from chat then set single image for that
                    if (value?._id) {
                        let file = getCurrentState().chatList.allChatFiles.find((item) => item.files._id === value._id);
                        if (!file) {
                            //if value is not in files array then make custom object for singleFile
                            getCurrentState().chatList["singleFile"] = { files: value }
                        } else {
                            getCurrentState().chatList["singleFile"] = file
                        }
                        await props.setProps({ chatList: IISMethods.getCopy(getCurrentState().chatList) })
                    }
                } else {
                    //when modal close delete all data for gallery view
                    delete getCurrentState().filterData["fileType"]
                    delete getCurrentState().filterData["pageNo"]
                    delete getCurrentState().chatList["singleFile"]
                    delete getCurrentState().chatList["allChatFiles"]
                    await props.setProps({ chatList: IISMethods.getCopy(getCurrentState().chatList), filterData: IISMethods.getCopy(getCurrentState().filterData) })
                }
                break;
            case "renderPdf":
                if (isOpen) {
                    //set pdf file JENIL 10/8/2023 4:24 PM
                    //if value is not in files array then make custom object for singleFile
                    getCurrentState().chatList["singleFile"] = value
                    getCurrentState().chatList["singleChat"] = chat
                    setRenderPdf(value.url);
                    await props.setProps({ chatList: IISMethods.getCopy(getCurrentState().chatList) })
                } else {
                    //when modal close delete
                    delete getCurrentState().chatList["singleFile"]
                    delete getCurrentState().chatList["singleChat"]
                    setRenderPdf("");
                    await props.setProps({ chatList: IISMethods.getCopy(getCurrentState().chatList), filterData: IISMethods.getCopy(getCurrentState().filterData) })
                }
                break;
            default:
                break;
        }
        getCurrentState().modal[name] = isOpen
        await props.setProps({ modal: IISMethods.getCopy(getCurrentState().modal) })
    }

    //set filter data of chat
    const handleFilterData = async (type, field, value, extraFields) => {
        let filterData = {};
        switch (type) {
            case "input-text":
                filterData[field] = value;
                break;
            case "radio":
                //toggle filter data if 1 then 0 else {value} by params
                filterData[field] = getCurrentState().filterData[field] ? getCurrentState().filterData[field] === 1 ? 0 : 1 : value;
                extraFields.forEach(element => {
                    filterData[element] = 0
                });
                break;
            default:
                break;
        }
        await props.setProps({ filterData: IISMethods.getCopy({ ...getCurrentState().filterData, ...filterData }) })
    }

    //handle remove file from form data
    const handleRemoveFile = async (index) => {
        let changeFiles = [...getCurrentState().formData.files];
        changeFiles.splice(index, 1);
        getCurrentState().formData.files = [...changeFiles]
        await props.setProps({ formData: IISMethods.getCopy(getCurrentState().formData) })
    }

    // jatin 22-08-2023 for msg redirect and Blink That Msg
    const redirectMsgReplay = (id) => {
        const element = document.getElementById("chat" + id);
        console.log('element', element)
        if (element) {
            element.classList.add("msg-redirect-blink");
            setTimeout(() => {
                element.classList.remove("msg-redirect-blink");
            }, 2000)
            element.scrollIntoView({ behavior: 'smooth' }, false);
        }
    }
    //handle user list
    const handleUserList = async () => {
        const url = Config.weburl + 'guestexperience/guestmsg/personlist'
        const useraction = 'personlist'
        let req = {
            paginationinfo: {
                pageno: 1,
                pagelimit: 20,
                filter: {},
                sort: {}
            }
        }
        await setReqParams(url, req)
        await IISMethods.listData(useraction)
        if (resBody.status === 200) {
            await props.setProps({ userList: [...resBody.data] })
            await handleChatList();
        } else { console.log("err"); }
    }

    //guest chat list api call
    const handleChatList = async (scroll = 0) => {
        if (scroll === 0) {
            props.setProps({ pageNo: 1 })
        }
        let filter = {}
        filter['reservationid'] = getCurrentState().reservationDetails._id
        if (pagename !== "chatwithus") {
            filter['categorytypeid'] = categoryIds[pagename]
        }
        // filter['roomnoid'] = getCurrentState().reservationDetails.roomnoid
        if (getCurrentState()?.filterData?.messageSearch && getCurrentState()?.filterData?.messageSearch?.trim() !== "") {
            filter['message'] = getCurrentState()?.filterData?.messageSearch
        }
        if (getCurrentState().filterData?.isstandard === 1) {
            filter['isstandard'] = getCurrentState()?.filterData?.isstandard
        } else if (getCurrentState().filterData?.isimportant === 1) {
            filter['isimportant'] = getCurrentState()?.filterData?.isimportant
        } else if (getCurrentState().filterData?.isurgent === 1) {
            filter['isurgent'] = getCurrentState()?.filterData?.isurgent
        }
        const url = Config.weburl + 'guestexperience/guestmsg'
        const useraction = 'listguestexperience'
        let req = {
            paginationinfo: {
                pageno: getCurrentState().pageNo,
                pagelimit: 20,
                filter: filter,
                sort: {}
            },
            guestexpcategoryid: categoryIds[pagename],
            propertyid: getCurrentState().reservationDetails.propertyid,
            roomnoid: getCurrentState().reservationDetails.roomnoid,
        }
        await setReqParams(url, req)
        await IISMethods.listData(useraction)
        if (resBody.status === 200) {
            const listArray = scroll === 1 ? ((getCurrentState().chatList?.chatList?.reverse()).concat(IISMethods.getCopy(resBody.data))).reverse() : IISMethods.getCopy(resBody.data.reverse())
            const msgids = listArray.map(data => data._id)
            getCurrentState().chatList["chatList"] = listArray
            await props.setProps({ chatList: IISMethods.getCopy(getCurrentState().chatList) })
            if (!msgids.includes(location.state) && location.state !== null) {
                await props.setProps({ pageNo: getCurrentState().pageNo + 1 })
                handleChatList(1)
            }
            else {
                if (location.state) {
                    await props.setProps({ formData: { isChatpreseant: true } })
                    redirectMsgReplay(location.state)
                    navigate(location.pathname, { replace: true });
                }
            }
        }
        else { console.log("err"); }
    }

    //add task api JENIL 17/7/2023 11:23 AM
    const addData = async (callApi, signData, option, sessionid) => {
        const url = Config.weburl + "task/add"
        const useraction = 'guestrequest'
        let reqData = {
            propertyid: getCurrentState().reservationDetails?.propertyid,
            propertyname: getCurrentState().reservationDetails?.propertyname,
            categoryid: Config.categoryid.frontdesk,
            category: "Front Desk",
            assignmentforid: Config.assignmentfor.room,
            assignmentfor: "Room",
        }
        //find from sessionid and request saved in chat, charges from list JENIL 12/7/2023 3:24 PM
        let answer = getCurrentState().chatList?.chatList?.filter(a => a.sessionid === sessionid && a.request) || []
        let autorequest = {}
        //find all request from array
        answer?.forEach(element => {
            autorequest = { ...autorequest, ...element.request }
        });
        switch (callApi) {
            case 1:
                reqData.subcategoryid = "645a348357f45b2c947e5282"
                reqData.subcategory = "Late Checkout"
                reqData.details = [{
                    roomnoid: getCurrentState().reservationDetails?.roomnoid,
                    roomno: getCurrentState().reservationDetails?.roomno,
                    roomtypeid: getCurrentState().reservationDetails?.roomtypeid,
                    roomtype: getCurrentState().reservationDetails?.roomtype,
                }]
                reqData.departments = [{
                    departmentid: Config.departments.frontdesk,
                    department: "Front Desk",
                }]
                reqData.time = autorequest.latecheckouttimeid //late checkouttime range id
                reqData.latecheckouttodate = autorequest.latecheckouttodate //latecheckout select time
                reqData.latecheckoutcharges = autorequest.latecheckoutguestcharges //latecheckout charges
                reqData.guestsignature = signData//latecheckout signature
                break;
            case 2:
                reqData.reservationid = getCurrentState().reservationDetails?._id
                reqData.subcategoryid = "646ddb78f288c1b0423cc3a5"
                reqData.subcategory = "Extend Stay"
                reqData.tasktypeid = "62fe1749cbac20692899122a"
                reqData.tasktype = "Once"
                reqData.buildingid = getCurrentState().reservationDetails?.buildingid
                reqData.buildingname = getCurrentState().reservationDetails?.buildingname
                reqData.wingid = getCurrentState().reservationDetails?.wingid
                reqData.wingname = getCurrentState().reservationDetails?.wingname
                reqData.details = [
                    {
                        roomnoid: getCurrentState().reservationDetails?.roomnoid,
                        roomno: getCurrentState().reservationDetails?.roomno,
                        subcategory: "Extend Stay",
                        subcategoryid: "646ddb78f288c1b0423cc3a5",
                        priorityid: "6303126355c8ad547b0f809c",
                        priority: "Low"
                    }
                ]
                reqData.extendstaydetails = {
                    //if  autorequest?.isnewreservation not found then answer should be yes the value default is 1
                    isnewreservation: autorequest?.isnewreservation || 1,//from guestmessage list api
                }
                if (autorequest?.isnewreservation === 0) {
                    reqData.extendstaydetails = {
                        isnewreservation: autorequest?.isnewreservation,//from guestmessage list api
                        checkindate: getCurrentState().reservationDetails?.checkindate, //from reservation details
                        checkoutdate: option.extendstaydate,//direact api calls when submit request take it from sendmessage request
                        noofnight: option.extendstaydays//direact api calls when submit request take it from sendmessage request
                    }
                }
                break;
            case 3:
                reqData.subcategoryid = "645a348357f45b2c947e5283"
                reqData.subcategory = "Checkout"
                reqData.details = [{
                    roomnoid: getCurrentState().reservationDetails?.roomnoid,
                    roomno: getCurrentState().reservationDetails?.roomno,
                    roomtypeid: getCurrentState().reservationDetails?.roomtypeid,
                    roomtype: getCurrentState().reservationDetails?.roomtype,
                }]
                reqData.departments = [{
                    departmentid: Config.departments.frontdesk,
                    department: "Front Desk"
                }]
                reqData.checkoutdetails = {
                    isguestcheckout: 1,
                }
                if (option?.foliovalue) {
                    reqData.checkoutdetails.foliovalue = option.foliovalue
                    if (option?.foliomail) {
                        reqData.checkoutdetails.foliomail = option.foliomail
                    }
                }
                break;
            default:
                break;
        }
        await setReqParams(url, reqData)
        await IISMethods.addData(useraction)
        if (resBody.status === 200) {
            console.log(resBody.data, "data")
        } else {
            console.log("err")
        }
    }

    //handle send message function and add message api call
    const handleSendMessage = async (setIsScroll, isStaticmsg, staticmsg, isAnswer, option, chat, isTime, sign) => {
        const msg = getCurrentState().formData?.chatmessage
        const url = Config.weburl + 'guestexperience/guestmsg/add'
        const useraction = 'addright'
        const files = getCurrentState()?.formData?.files ? getCurrentState()?.formData?.files : [];
        //if request from add message
        let req = {
            reservationid: getCurrentState().reservationDetails?._id,
            roomnoid: getCurrentState().reservationDetails?.roomnoid,
            roomtypeid: getCurrentState().reservationDetails?.roomtypeid,
            propertyid: getCurrentState().reservationDetails?.propertyid,
            personid: getCurrentState().reservationDetails?._id, //new changes
            buildingid: getCurrentState()?.reservationDetails?.buildingid,
            wingid: getCurrentState()?.reservationDetails?.wingid,
            message: msg || "",
            entrybyname: getCurrentState().reservationDetails?.firstname + " " + getCurrentState().reservationDetails?.lastname,
            files: files || [],
            isstandard: getCurrentState().formData?.isstandard ? getCurrentState().formData?.isstandard : 1,
            isimportant: getCurrentState().formData?.isimportant ? getCurrentState().formData?.isimportant : 0,
            isurgent: getCurrentState().formData?.isurgent ? getCurrentState().formData?.isurgent : 0,
            isanswer: 0,
            requestadd: 0,
            guestexpcategoryid: categoryIds[pagename]
        }
        if (chat?.requestadd && option.option === "YES") {
            req.requestadd = chat.requestadd
        }
        if (req.isimportant === 1 || req.isurgent === 1) {
            req.isstandard = 0
        }
        if (isStaticmsg) {
            req.requestadd = staticmsg.requestadd
            req.message = staticmsg.message
            //when select option from menu in chatwithus JENIL 16/8/2023 12:48 PM
            req.ischatwithussessionstart = 1
        }
        if (chat?.sessionid) {
            req.sessionid = chat.sessionid
        }
        //not click on send button JENIL 17/7/2023 11:40 AM
        if (isAnswer) {
            req.message = option.option
            req.answerid = option.answerid
            req.categorytypeid = option.categorytypeid
            req.isanswer = 1
            req.replytooptionid = option._id
            req.replytomessageid = chat._id
            req.replytomessage = chat.message
            delete req.bodymessage
            //when latecheckout time range select send this into request
            if (isTime) {
                req.latecheckouttotime = option.latecheckouttotime
                req.latecheckouttimeid = option.latecheckouttimeid
                req.latecheckouttimerange = option.option
                req.latecheckoutcharges = option.latecheckoutcharges
            }
            //when latecheckout guest signature is an answer 
            if (chat.optiontype === 5) {
                req.latecheckoutsignature = sign ? sign.getTrimmedCanvas().toDataURL('image/png') : ""
            }
            //when extendstay guest signature is sending pdf with signature JENIL 14/8/2023 1:15 PM
            if (chat.optiontype === 6) {
                req.extendstayguestpdfsignature = 1
                req.guestsignaturepdf = sign
                req.guestsignaturepdfname = "sign" + getCurrentState().chatList.singleFile['name']
                req.guestsignaturepdftype = 4
            }
            //extend stay select date message
            if (chat.optionshowtype === 5 && chat.optiontype === 3) {
                req.extendstaydate = option.date
                req.extendstaydays = option.days
                req.extendstayguestselfie = option.selfie //add selfie in extend stay
            }
            //extend stay credit card scan payload
            if (chat.optionshowtype === 7 && chat.optiontype === 7) {
                // req.cardimage = getCurrentState().formData?.cardimage//charmi(06-10-2023)
                req.cardnumber = getCurrentState().formData?.cardnumber
                req.cardholdername = getCurrentState().formData?.cardholdername
                req.cardexpirydate = getCurrentState().formData?.cardexpirydate
            }
            //late checkout form payload
            if (chat.optionshowtype === 8 && chat.optiontype === 8) {
                req.latecheckouttodate = getCurrentState().chatList?.lateCheckoutTime
                req.latecheckouttimeid = getCurrentState().chatList?.lateCheckoutCharges?._id
                req.latecheckoutguestcharges = getCurrentState().chatList?.lateCheckoutCharges?.charge
                req.latecheckoutguestselfie = option.selfie
            }
            //checkout form payload
            if (chat.optionshowtype === 9 && chat.optiontype === 9) {
                req.foliovalue = option.foliovalue
                if (option?.foliomail) {
                    req.foliomail = option.foliomail
                }
            }
        } else {
            //if isanwer is false then send pdf with signature JENIL 14/8/2023 2:11 PM
            if (getCurrentState().chatList.singleChat && sign) {
                req.message = "Submit"
                req.answerid = getCurrentState().chatList.singleChat._id
                req.extendstayguestpdfsignature = 1
                req.guestsignaturepdf = sign
                req.guestsignaturepdfname = "sign-" + getCurrentState().chatList.singleFile['name']
                req.guestsignaturepdftype = 4
            }
        }
        //is message and files is not there show error
        if (((req?.message?.trim().length > 0) || (req?.files?.length > 0)) && !loader) {
            if (getCurrentState().pageNo === 1) {
                setIsScroll(true);
            }
            setOptionIdLoad(option?._id ? option?._id : "")
            setLoader(true);
            if (chat?.callapi) {
                if (chat?.callapi === 1 && option.option === "Approve") {
                    await addData(chat?.callapi, req.latecheckoutsignature || "", {}, chat?.sessionid)
                }
                if (chat?.callapi === 2) {
                    await addData(chat?.callapi, "", req, chat?.sessionid)
                }
                //direct is paid in cash questin after api called, take request data from option JENIL 13/7/2023 1:05 PM
                if (chat?.callapi === 3) {
                    await addData(chat?.callapi, "", option, chat?.sessionid)
                }
                //if extend stay new reservation answer is yes then call callapi 4 
                //addData function case type is for extend stay so value should be 2
                if (chat?.callapi === 4 && option.option === "YES") {
                    await addData(2, "", req, chat?.sessionid)
                }
            }
            await setReqParams(url, req)
            await IISMethods.addData(useraction);
            // }
            if (resBody.status === 200) {
                getCurrentState().formData = {}
                await props.setProps({ formData: IISMethods.getCopy(getCurrentState().formData) })
                setLoader(false);
                setOptionIdLoad("")
                //when send pdf with signature close modal JENIL 14/8/2023 2:18 PM
                if (getCurrentState().chatList.singleChat && sign) {
                    handleGrid('modal', 'renderPdf', false)
                }
                if (resBody?.callapi === 3) {
                    await addData(3, "", option, chat?.sessionid)
                }
            } else {
                setLoader(false);
                setOptionIdLoad("")
            }
        } else {
            IISMethods.notify("Please enter message", 2)
        }
    }

    //select option from multiple JENIL 17/7/2023 11:14 PM
    const handleOptionSelect = async (setIsScroll, item, chat, isTime = false, sign) => {
        if (!loader) {
            await handleSendMessage(setIsScroll, false, {}, true, item, chat, isTime, sign)
        }
    }

    //manage scrolling for chat
    const handleChatScroll = async (e, setIsScroll) => {
        let element = e.target
        if ((element?.scrollTop + element?.clientHeight) === element?.scrollHeight) {
            setIsScroll(true)
        }
        if (element.scrollTop < element.clientHeight) {
            if (getCurrentState().pageNo * 20 <= getCurrentState().chatList?.chatList?.length) {
                await props.setProps({ pageNo: getCurrentState().pageNo + 1 })
                handleChatList(1)
            }
        }
    }

    //manage scroll for chat
    const handleFileScroll = async (e) => {
        let element = e.target
        if (element.scrollTop + element.clientHeight >= element.scrollHeight && getCurrentState()?.filterData?.nextpage > 0) {
            getCurrentState().filterData["pageNo"] = getCurrentState().filterData["pageNo"] + 1;
            await props.setProps({ filterData: IISMethods.getCopy(getCurrentState().filterData) })
            await handleFileList(1)
        }
    }

    //click on single file from gallery
    const handleFileClickFromGallery = async (file, isOpen) => {
        if (isOpen) {
            getCurrentState().chatList["singleFile"] = file
        } else {
            delete getCurrentState().chatList["singleFile"]
        }
        await props.setProps({ chatList: IISMethods.getCopy(getCurrentState().chatList) })
    }

    //handle all media modal tab changes
    const handleTabChange = async (item) => {
        getCurrentState().filterData["fileType"] = item.type;
        getCurrentState().filterData["pageNo"] = 1;
        await props.setProps({ filterData: IISMethods.getCopy(getCurrentState().filterData) })
        await handleFileList()
    }

    //handle send message function and add message api call JENIL
    const handleUpdateEmojiReactionMessage = async (id, reactions) => {
        const url = Config.weburl + 'guestexperience/guestmsg/update'
        const useraction = 'updateright'
        //if request from add message
        let req = {
            _id: id,
            reactions: reactions
        }
        await setReqParams(url, req)
        await IISMethods.updateData(useraction, false);
    }

    //handle add reaction emoji on message JENIL 21/6/2023 4:33 PM
    const handleReactionEmojiSelect = async (value, chat, setIsScroll) => {
        setIsScroll(false);
        let newReactionObj = {
            reaction: value.native,
            reactionid: value.id,
            reactionbyuid: getCurrentState().reservationDetails?._id
        }
        let reactions = chat.reactions ? [...chat.reactions] : []
        let sameUserIndex = reactions.findIndex(a => a.reactionbyuid === getCurrentState().reservationDetails?._id);
        if (sameUserIndex >= 0) {
            if (reactions[sameUserIndex].reactionid === newReactionObj.reactionid) {
                reactions.splice(sameUserIndex, 1)
            } else {
                reactions.splice(sameUserIndex, 1, newReactionObj)
            }
        } else {
            reactions.push(newReactionObj)
        }
        let index = getCurrentState()?.chatList?.chatList?.findIndex(a => a._id === chat._id);
        if (index >= 0) {
            let obj = { ...getCurrentState()?.chatList?.chatList[index] }
            obj.reactions = [...reactions]
            getCurrentState().chatList.chatList[index] = { ...obj };
            await props.setProps({ chatList: IISMethods.getCopy(getCurrentState().chatList) })
        }
        await handleUpdateEmojiReactionMessage(chat._id, reactions)
    }



    //cardnumber state
    // const [finalCardNum, setFinalCardNum] = useState("")
    // const [pxObj, setPxObj] = useState({})
    // const [blurCardImage, setBlurCardImage] = useState("")
    // const [scancard, setScancard] = useState('')//documentfile
    const originalPhoto = useRef();

    //for credit card right sidebar JENIL 25/7/2023 5:17 PM
    const scanCardRightSidebarFormData = [
        {
            pagename: 'paymentmethod',
            formname: 'Payment Method',
            useraction: 'mobilecheckin',
            apipath: 'mobilecheckin/update',
            alias: 'paymentmethod',
            formfields: [
                {
                    'field': 'cardnumber',
                    'text': 'Card Number',
                    'type': Config.getHtmlcontorls()['input-text'],
                    'disabled': true,
                    'defaultvisibility': true,
                    'required': false,
                    'gridsize': Config.getGridclasses()['col-12'],

                },
                {
                    'field': 'cardexpirydate',
                    'text': 'Expiry Date',
                    'type': Config.getHtmlcontorls()['input-text'],
                    'disabled': false,
                    'defaultvisibility': true,
                    'required': false,
                    'gridsize': Config.getGridclasses()['col-12'],

                },
            ]
        },
    ]

    // // Bhautik 14-07-2023 04:00 PM ~ Start 
    // const handleScanImage = (imgURL) => {
    //     let img = new Image()
    //     img.onload = async function () {
    //         // let per = 30
    //         let imgWidth = img.width
    //         let imgHeight = img.height
    //         // let cw = (imgWidth * per) / 100
    //         // let ch = (imgHeight * per) / 100
    //         // const canvas = document.createElement('canvas');
    //         // const ctx = canvas.getContext('2d');

    //         // canvas.width = imgWidth - cw;
    //         // canvas.height = imgHeight - ch;
    //         // ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    //         // console.log('imgWidth', imgWidth - cw, imgHeight - ch, {imgWidth, imgHeight, cw, ch})
    //         let dataURL = imgURL //canvas.toDataURL()
    //         setScancard(dataURL)
    //         getCurrentState().formData.originalPhoto = dataURL
    //         await props.setProps({ formData: IISMethods.getCopy(getCurrentState().formData) })
    //         await handleScanCard({ url: dataURL, width: imgWidth, height: imgHeight })

    //     }
    //     img.src = imgURL
    // }

    // //form value prepare - onblut & onclick
    // const handleScanImageFormData = async (type, key, value) => {
    //     if (type === 'image' || type === 'file' || type === 'svg-file') {
    //         if (key === 'paymentblurcardimage') {
    //             getCurrentState().formData[key] = value
    //         } else {
    //             getCurrentState().formData[key] = await IISMethods.fileToBase64(value)
    //         }
    //     }
    //     else {
    //         getCurrentState().formData[key] = value
    //     }
    //     await props.setProps({ formData: IISMethods.getCopy(getCurrentState().formData) })
    // }

    // base64 to file
    // const dataURLtoFile = async (dataurl, filename) => {
    //     let arr = dataurl.split(','),
    //         mime = arr[0].match(/:(.*?);/)[1],
    //         bstr = atob(arr[arr.length - 1]),
    //         n = bstr.length,
    //         u8arr = new Uint8Array(n);
    //     while (n--) {
    //         u8arr[n] = bstr.charCodeAt(n);
    //     }
    //     return new File([u8arr], filename, { type: mime });
    // }

    // const handleHTMLtoCanvas = async (data) => {
    //     let { tempcreditcardnum, expmonth, expyear, pxdata } = data
    //     //set height, width, top, left in black-line JENIL 27/7/2023 10:43 PM
    //     document.getElementById("black-line").style.width = pxdata.xone
    //     document.getElementById("black-line").style.height = pxdata.yone
    //     document.getElementById("black-line").style.top = pxdata.yzero
    //     document.getElementById("black-line").style.left = pxdata.xzero
    //     //get masking image JENIL 27/7/2023 10:43 PM
    //     let originalPhoto = document.getElementById("creadit-card-original-photo");
    //     let header = { useCORS: true, quality: 1, allowTaint: true }
    //     try {
    //         let canvas = await html2canvas(originalPhoto, header)
    //         let img = { token: canvas.toDataURL("image/jpeg", 1.0) }
    //         let urlfile = await dataURLtoFile(img.token)
    //         let dataURL = await IISMethods.resizeImageFile(urlfile)
    //         setBlurCardImage(dataURL)
    //         await handleScanImageFormData('image', 'paymentblurcardimage', dataURL)
    //         await handleScanImageFormData('input-text', 'paymentcardnumber', tempcreditcardnum)
    //         if ((expmonth <= 12) && expyear) {
    //             await handleScanImageFormData('input-text', 'paymentcardexpirydate', `${expmonth}/${expyear}`)
    //         }
    //         IISMethods.notify("Your credit card has been scanned successfully.")
    //     }
    //     catch (err) {
    //         console.log('error: ', err)
    //         IISMethods.notify("Card not scanned properly", 2)
    //     }
    // }

    const styleObj = useRef(null);

    // const handleScanCard = async (props) => {
    //     let ciURL = props.url
    //     let ciWidth = props.width
    //     let ciHeight = props.height
    //     try {
    //         let payload = {
    //             requests: [
    //                 {
    //                     image: {
    //                         content: ciURL.split('base64,')[1]
    //                     },
    //                     features: [
    //                         { type: "TEXT_DETECTION", maxResults: 15 },
    //                     ],
    //                 }
    //             ]
    //         }
    //         let key = 'AIzaSyBIs_lQSoWx-2CFwOLN7UjEtB_z9MiZwRg'
    //         let googleVision = await fetch(`https://vision.googleapis.com/v1/images:annotate?key=${key}`, {//change api-key 
    //             method: 'POST', body: JSON.stringify(payload)
    //         });
    //         let gvResult = await googleVision.json()
    //         if (gvResult.responses[0]?.fullTextAnnotation) {
    //             const { height, width } = gvResult.responses[0].fullTextAnnotation.pages[0]
    //             let expmonth
    //             let expyear
    //             let textExpiry = gvResult.responses[0]?.fullTextAnnotation.text?.split('/') || []
    //             if (textExpiry.length > 0) {
    //                 expmonth = textExpiry[0]?.substr(-2)
    //                 expyear = textExpiry[1]?.substr(0, 2)
    //             }
    //             if (gvResult) {
    //                 // downloadTxtFile(JSON.stringify(googleVisionRes.responses[0].textAnnotations))
    //                 let block = gvResult.responses[0].fullTextAnnotation.text
    //                 // downloadTxtFile(block)
    //                 let lines = block.split('\n')
    //                 //16 digits
    //                 let numberArray = lines.filter(line => { if (parseInt(line) && line.length >= 19 && line.split(" ").length === 4) return line })//19
    //                 // more than 8 digit                           
    //                 let numberArrayeightdigit = lines.filter(line => { if ((parseInt(line)) && line.length >= 9 && line.split(" ").length >= 1) return line })//9 

    //                 if (numberArray.length > 0) {
    //                     let words = numberArray[0].split(" ")
    //                     let filteredArray = gvResult.responses[0].textAnnotations.filter((obj) => words.includes(obj.description)) || []
    //                     let match = []
    //                     filteredArray.map(word => {
    //                         let vertices = word.boundingPoly.vertices
    //                         if (
    //                             vertices[0].x - vertices[3].x < 2 ||
    //                             vertices[0].y - vertices[1].y < 2 ||
    //                             vertices[1].x - vertices[2].x < 2 ||
    //                             vertices[2].y - vertices[3].y < 2
    //                         ) { match.push(true) }
    //                     })
    //                     let getcardnumber = filteredArray.map(obj => Number(obj.description))
    //                     let cardNumStatus = getcardnumber.length === 5 && getcardnumber[0] === getcardnumber[4]
    //                     if (match.length > 1 && (getcardnumber.length === 4 || cardNumStatus)) {
    //                         // if store converted desc in number occurs issue
    //                         // so store the digits as string is necessary
    //                         let tempArray = []
    //                         for (let i = 0; i < 4; i++) {
    //                             tempArray.push(filteredArray[i].description)
    //                         }
    //                         let tempcreditcardnum = `**** **** **** ${tempArray[3]}`
    //                         setFinalCardNum(tempcreditcardnum)

    //                         let { x, y } = filteredArray[0].boundingPoly.vertices[0]
    //                         let xone = filteredArray[2].boundingPoly.vertices[2].x
    //                         let yone = filteredArray[2].boundingPoly.vertices[2].y

    //                         let iw = ciWidth
    //                         let ih = ciHeight
    //                         let pxdata = {
    //                             xzero: ((iw * x) / width).toFixed(2) - 5 + "px",//xzero
    //                             yzero: ((ih * y) / height).toFixed(2) - 2 + "px",//yzero
    //                             xone: ((iw * xone / width) - ((iw * x) / width) + 7).toFixed(2) + "px",//width
    //                             yone: ((ih * yone / height) - ((ih * y) / height) + 8).toFixed(2) + "px",//height
    //                         }
    //                         styleObj.current = pxdata
    //                         setPxObj(pxdata)

    //                         // store height,width,(left,top position )of masked portion
    //                         if (((iw * xone / width) - ((iw * x) / width) + 7) > 0 && ((iw * yone / height) - ((iw * y) / height)) > 0) {
    //                             //generate blurcardimage
    //                             await handleHTMLtoCanvas({ el: originalPhoto.current, tempcreditcardnum, expmonth, expyear, pxdata })
    //                         }
    //                     }
    //                     else {
    //                         IISMethods.notify("Card not scanned properly", 2)
    //                     }
    //                 }
    //                 else if (numberArrayeightdigit.length > 0) {
    //                     let words = numberArrayeightdigit[0].split(" ")
    //                     let filteredArray = gvResult.responses[0].textAnnotations.filter((obj) => words.includes(obj.description))
    //                     let match = []
    //                     filteredArray.map(word => {
    //                         let vertices = word.boundingPoly.vertices
    //                         if (
    //                             vertices[0].x - vertices[3].x < 2 ||
    //                             vertices[0].y - vertices[1].y < 2 ||
    //                             vertices[1].x - vertices[2].x < 2 ||
    //                             vertices[2].y - vertices[3].y < 2
    //                         ) { match.push(true) }
    //                     })
    //                     if (match.length > 1 && (filteredArray.filter(obj => Number(obj.description)).length === filteredArray.length)) {
    //                         // if store converted desc in number occurs issue
    //                         // Number(['1100', '2354','0254','8888' ])
    //                         // result : [1100 ,2354 ,254 ,8888 ] 
    //                         // so store the digits as string is necessary
    //                         let tempArray = []

    //                         for (let i = 0; i < filteredArray.length; i++) {
    //                             let tempcreditcardnum = ''
    //                             if (i < filteredArray.length - 1) {
    //                                 [...filteredArray[i].description].map(letter => {
    //                                     tempcreditcardnum += '*'
    //                                 })
    //                                 tempArray.push(tempcreditcardnum)

    //                             } else {
    //                                 tempArray.push(filteredArray[i].description)
    //                             }
    //                         }
    //                         let letterofCard = ''
    //                         tempArray.map(letterarray => {
    //                             letterofCard += letterarray + ' '
    //                         })
    //                         setFinalCardNum(letterofCard)
    //                         setBlurCardImage(scancard)
    //                         await handleScanImageFormData('image', 'paymentblurcardimage', scancard)
    //                         await handleScanImageFormData('input-text', 'paymentcardnumber', letterofCard)
    //                         if ((expmonth <= 12) && expyear) {
    //                             await handleScanImageFormData('input-text', 'paymentcardexpirydate', `${expmonth}/${expyear}`)
    //                         }
    //                     }
    //                 }
    //                 else {
    //                     IISMethods.notify("Card not scanned properly", 2)
    //                 }
    //             }
    //         }
    //         else {
    //             IISMethods.notify("Card not scanned properly", 2)
    //         }
    //     }
    //     catch (err) {
    //         console.log('error: ', err)
    //         IISMethods.notify("Card not scanned properly", 2)
    //     }
    // }

    //late checkout chages JENIL 04/08/2023 3:00 PM
    const getLateCheckoutCharges = async (checkouttime) => {
        let time = checkouttime ? IISMethods.GetISOFromTMobj(checkouttime) : "";
        getCurrentState().chatList["lateCheckoutTime"] = time;
        await props.setProps({ chatList: IISMethods.getCopy(getCurrentState().chatList) })
        if (time !== "") {
            const url = Config.weburl + "guestexperience/guestmsg/latecheckoutcharges"
            const useraction = 'listguestexperience'
            let reqData = {
                latecheckouttodate: time,
                roomtypeid: getCurrentState().reservationDetails?.roomtypeid
            }
            await setReqParams(url, reqData)
            await IISMethods.addData(useraction)
            if (resBody.status === 200) {
                console.log(resBody.data, "data")
                getCurrentState().chatList["lateCheckoutCharges"] = resBody?.data?.length > 0 ? resBody?.data[0] : {}
                await props.setProps({ chatList: IISMethods.getCopy(getCurrentState().chatList) })
            } else {
                IISMethods.notify(resBody.message, 2)
            }
        } else {
            IISMethods.notify("Please enter Late Checkout Time", 2)
        }
    }

    //when add msg api called this receiver called
    useSocketListener("receiveguestmsg", getSocketResponse)

    //charmi(05-10-2023) - scancard - start
    let initialCardState = { cardnumber: '', expdate: '', cardholdername: '', cvv: '' }
    const originalImgRef = useRef(null)
    const bookingid = IISMethods.getGuestInfo()?.bookingid

    const [imgURL, setImgURL] = useState({})
    // const [imgData, setImgData] = useState(null)
    // const [cardDatails, setCardDatails] = useState(initialCardState)
    const [maskPos, setMaskPos] = useState({})

    const handleCreditCardUploadImage = async (event, id) => {
        await props.setLoading({ loading: id })
        let file = event.target.files[0]
        await handleFileUpload(file)
        // let dataUrl = await IISMethods.resizeImageFile(file, { maxHW: 600 }) //await imgToBase64(file)
        // handleScanImage(dataUrl)
    }

    const handleFileUpload = async (files) => {
        // let { pagename, apipath  } = getCurrentState().rightSidebarFormData[0]
        let url = Config.weburl + 'mobilecheckin/getpaymentcarddata'

        let useraction = 'update' + 'data'

        let formData = new FormData()
        formData.append('paymentcard', files)
        await setReqParams(url, formData)
        await IISMethods.updateData(useraction, true, true)
        
        if (resBody.status === 200) {
            if (resBody.data.cardnumber) {
                getCurrentState().formData.cardnumber = resBody.data.cardnumber || ''
                getCurrentState().formData.cardexpirydate = resBody.data.expirationdate || ''//date.reverse().join('/')
                getCurrentState().formData.cardholdername = resBody.data.cardholdername || ''
                setChatMsgID(getCurrentState().loading)
                await props.setProps({ formData: IISMethods.getCopy(getCurrentState().formData) })
            }           
        }       
        await props.setLoading({ loading: 0 })
        // let reqData = { screen: 'paymentverification', _id: bookingid }
        // await addData(reqData, pagename, apipath, useraction)
    }

    // const handleScanImage = (imgurl) => {
    //     let img = new Image()
    //     img.onload = async function () {
    //         let imgWidth = img.width
    //         let imgHeight = img.height
    //         setImgURL({ url: imgurl, blurUrl: '' })
    //         // setImgData({ url: imgurl, width: imgWidth, height: imgHeight })
    //         await handleScanCard({ url: imgurl, width: imgWidth, height: imgHeight })
    //     }
    //     img.src = imgurl
    // }

    // const handleScanCard = async (imgData) => {
    //     if (!imgData.url) return
    //     // await addLoaders('imagescan', 1)

    //     let ciURL = imgData.url
    //     try {
    //         let response = await axios.post(
    //             `https://vision.googleapis.com/v1/images:annotate?key=${process.env.REACT_APP_GOOGLE_VISION_API_KEY}`,
    //             { requests: [{ image: { content: ciURL.split('base64,')[1] }, features: [{ type: "TEXT_DETECTION" }] }] }
    //         );

    //         let fullTextAnnotation = response.data.responses[0].fullTextAnnotation
    //         let textAnnotations = response.data.responses[0].textAnnotations

    //         let scantext = response.data.responses[0].fullTextAnnotation.text;
    //         let carddata = getCardDetails(scantext)

    //         if (carddata.cardnumber) {
    //             // setCardDatails(carddata)    
    //             getCardNumberPos(fullTextAnnotation, textAnnotations, carddata, imgData)
    //         }
    //         else {
    //             IISMethods.notify("Card not scanned properly", 2)
    //         }
    //         // await addLoaders('imagescan', 0)
    //         // let textAnnotations = response.data.responses[0].textAnnotations.slice(1);
    //         // getCardPositions(textAnnotations, response.data.responses[0].fullTextAnnotation, carddata, )


    //         // let extractedText = response.data.responses[0].fullTextAnnotation.text;
    //         // setExtractedText(extractedText);
    //     }
    //     catch (error) {
    //         console.error("Error processing image:", error)
    //         IISMethods.notify("Card not scanned properly", 2)
    //         props.setProps({ formData: {} })
    //         setChatMsgID('')
    //         props.setLoading({ loading: 0 })
    //         // await addLoaders('imagescan', 0)
    //     }
    // }

    // const getCardNumberPos = (fullTextAnnotation, textAnnotations, carddata, imgData) => {
    //     let cardnumber = carddata.cardnumber || ''
    //     let expdate = carddata.expdate || ''
    //     let cardholdername = carddata.cardholdername || ''

    //     let imgId = document.getElementById('creditcardimg')

    //     let iw = imgId.clientWidth
    //     let ih = imgId.clientHeight

    //     let { height, width } = fullTextAnnotation.pages[0]

    //     let cardnumbers = cardnumber.split(' ')
    //     let cardPosA = textAnnotations.filter(cn => cn.description === cardnumbers[0])
    //     let cardPosB = textAnnotations.filter(cn => cn.description === cardnumbers[cardnumbers.length - 2])

    //     let { x, y } = cardPosA[0].boundingPoly.vertices[0]
    //     let xone = cardPosB[cardPosB.length - 1].boundingPoly.vertices[2].x
    //     let yone = cardPosB[cardPosB.length - 1].boundingPoly.vertices[2].y

    //     let maskdata = {
    //         xzero: ((iw * x) / width).toFixed(2) - 5 + "px",//xzero
    //         yzero: ((ih * y) / height).toFixed(2) - 2 + "px",//yzero
    //         xone: ((iw * xone / width) - ((iw * x) / width) + 16).toFixed(2) + "px",//width
    //         yone: ((ih * yone / height) - ((ih * y) / height) + 8).toFixed(2) + "px",//height
    //     }
    //     setMaskPos(maskdata)
    //     setTimeout(async () => await handleHTMLtoCanvas({ el: originalImgRef.current, url: imgData.url, cardnumber: cardnumbers.join(' '), expdate, cardholdername }), 1)
    // }

    // const getCardDetails = (scantext) => {
    //     let cardRegEx = /(\d[ -]*?){13,19}/g;
    //     let expdateRegEx = /\b(0[1-9]|1[0-2])\/(?:[0-9]{4}|[0-9]{2})\b/g
    //     let numberRegEx = /[0-9]/

    //     // for CVV code
    //     // let cvv = scantext.match(cvvRegEx)

    //     // for card number
    //     let cardnumber = scantext.match(cardRegEx);
    //     let cardnumberindex = scantext.indexOf(cardnumber)
    //     let textlist = scantext.slice(cardnumberindex).split('\n')

    //     // for card holder name
    //     let cardholder = textlist.reverse().filter(text => text.split(' ').length >= 2 && !numberRegEx.test(text))

    //     // for card expiry date
    //     let cardexpdate = textlist.filter(text => expdateRegEx.test(text))


    //     let cardobj = initialCardState
    //     // if (cvv.length > 0) cardobj['cvv'] = cvv[0]
    //     if (cardnumber.length && cardexpdate.length > 0) {
    //         cardobj['cardnumber'] = cardnumber[0]
    //         cardobj['expdate'] = cardexpdate[0].match(expdateRegEx)
    //         if (cardholder.length > 0) cardobj['cardholdername'] = cardholder[0]
    //     }

    //     return cardobj
    // };

    // base64 to file
    // const dataURLtoFile = (dataurl, filename) => {
    //     let arr = dataurl.split(','),
    //         mime = arr[0].match(/:(.*?);/)[1],
    //         bstr = atob(arr[arr.length - 1]),
    //         n = bstr.length,
    //         u8arr = new Uint8Array(n);
    //     while (n--) {
    //         u8arr[n] = bstr.charCodeAt(n);
    //     }
    //     return new File([u8arr], filename, { type: mime });
    // }

    // const handleHTMLtoCanvas = async (data) => {
    //     let { pagename, apipath, useraction } = scanCardRightSidebarFormData[0]
    //     let { el, cardnumber, expdate, cardholdername } = data
    //     let header = { useCORS: true, quality: 1, allowTaint: true }
    //     try {
    //         let canvas = await html2canvas(el, header)
    //         let img = { token: canvas.toDataURL("image/jpeg", 1.0) }
    //         let base64tofile = dataURLtoFile(img.token)
    //         let dataURL = await IISMethods.resizeImageFile(base64tofile)
    //         setImgURL({ ...imgURL, blurUrl: dataURL })
    //         // await handleFormData('paymentcardimage', 'image', data.url)
    //         await handleFormData('cardimage', 'image', dataURL)
    //         await handleFormData('cardnumber', 'input-text', cardnumber)
    //         if (expdate?.length) await handleFormData('cardexpirydate', 'input-text', expdate[0])
    //         await handleFormData('cardholdername', 'input-text', cardholdername)
    //         IISMethods.localnotify("Your card has been successfully scanned", 1)
    //         setChatMsgID(getCurrentState().loading)
    //         // let reqData = { screen: 'paymentverification', _id: bookingid}
    //         // await addCreditDardData(reqData, pagename, apipath, useraction)
    //         // await addLoaders('imagescan', 0)
    //         // setMaskImgURL(dataURL)
    //     }
    //     catch (err) {
    //         console.log('error: ', err)
    //     }
    //     props.setLoading({ loading: 0 })
    // }

    // const addCreditDardData = async (reqData, pagename, apipath, useraction) => {
    //     await props.setLoading({ loading: 1 })
    //     const url = Config.weburl + apipath
    //     await setReqParams(url, reqData)
    //     await IISMethods.addData(useraction, pagename)

    //     if (resBody.status === 200) {
    //         if (pagename === "paymentverificationsubmit") {
    //             props.setProps({
    //                 reservationDetails: resBody.data
    //             })
    //             // IISMethods.setLocalData("reservationDetails", resBody.data)
    //             // handleNextPage("hoteltnc", 'paymentverificationsubmit')
    //         }
    //         await props.setLoading({ loading: 0 })
    //     }
    //     else {
    //         IISMethods.notify(resBody.message, 2)
    //         await props.setLoading({ loading: 0 })
    //     }
    // }
    //charmi(05-10-2023) - scancard - end

    return (
        <>
            {/* to show masking image JENIL 26/7/2023 3:45 pm */}
            <div style={{ opacity: 1 }}>
                <div ref={originalImgRef} id="creadit-card-original-photo" className='position-absolute'>
                    <img
                        id="creditcardimg"
                        src={imgURL.url}
                        style={{ position: 'relative' }}
                        alt="image"
                    />
                    <div id="black-line" style={{
                        // width: `${pxObj?.xone}`,
                        // height: `${pxObj?.yone}`,
                        // top: `${pxObj?.yzero}`,
                        // left: `${pxObj?.xzero}`,
                        // width: `199px`,
                        // height: `24px`,
                        // top: `83px`,
                        // left: `11px`,
                        width: `${maskPos.xone}`,
                        height: `${maskPos.yone}`,
                        top: `${maskPos.yzero}`,
                        left: `${maskPos.xzero}`,
                        position: 'absolute',
                        background: 'black',
                    }}
                    >
                    </div>
                </div>
            </div>
            <ChatUsView
                handleFormData={handleFormData}
                handleSendMessage={handleSendMessage}
                handleRemoveFile={handleRemoveFile}
                handleChatScroll={handleChatScroll}
                handleFilterData={handleFilterData}
                handleChatList={handleChatList}
                handleGrid={handleGrid}
                handleFileScroll={handleFileScroll}
                handleFileClickFromGallery={handleFileClickFromGallery}
                handleTabChange={handleTabChange}
                loader={loader}
                fileLoader={fileLoader}
                handleReactionEmojiSelect={handleReactionEmojiSelect}
                pagename={pagename}
                handleOptionSelect={handleOptionSelect}
                // handleScanImageFormData={handleScanImageFormData}
                scanCardRightSidebarFormData={scanCardRightSidebarFormData}
                originalPhoto={originalPhoto}
                // scancard={scancard}
                // pxObj={pxObj}
                // handleScanImage={handleScanImage}
                styleObj={styleObj}
                getLateCheckoutCharges={getLateCheckoutCharges}
                renderPdf={renderPdf}
                setRenderPdf={setRenderPdf}
                optionIdLoad={optionIdLoad}
                chatId={chatId}
                redirectMsgReplay={redirectMsgReplay}
                handleCreditCardUploadImage={handleCreditCardUploadImage}
                chatMsgID={chatMsgID}

            // originalImgRef={originalImgRef}
            />
        </>
    )
}

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    clearData: (payload) => dispatch(clearData(payload)),
    setProps: (payload) => dispatch(setProps(payload)),
    setLoading: (payload) => dispatch(setLoading(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChatUsController)