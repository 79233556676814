import React, { useState } from 'react'
import { store } from '..'
import Header from '../Header'
import Direction from '../assets/images/MicrosoftTeams-image.png'
import BackHeader from '../BackHeader'
import Footer from '../Footer'
import { useLocation, useNavigate } from 'react-router-dom'
import NoDataImage from '../assets/images/no-data-img.png'
import placeholderImg from '../assets/images/placeholderImgNotify.png'
import { Placeholder } from 'rsuite'
import { Carousel } from 'react-bootstrap'

const NearByTypeView = (props) => {

    const location = useLocation()
    const [deatilDataExists, setDeatilDataExists] = useState({ exists: false, data: {} })

    const getCurrentState = () => {
        return store.getState()['root']
    }

    const handleDetailPage = async (data) => {
        setDeatilDataExists({ exists: true, data: data })
    }

    const star_fill = <svg className='star' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 64 61.335">
        <path id="star_13_" data-name="star (13)" d="M63.833,23.669A3.4,3.4,0,0,0,60.9,21.33L42.433,19.653l-7.3-17.1a3.4,3.4,0,0,0-6.256,0l-7.3,17.094L3.093,21.33a3.406,3.406,0,0,0-1.931,5.953L15.125,39.528,11.008,57.666a3.4,3.4,0,0,0,5.059,3.675L32,51.817l15.928,9.524a3.4,3.4,0,0,0,5.062-3.675L48.873,39.528,62.836,27.285a3.4,3.4,0,0,0,1-3.616Zm0,0" transform="translate(0 -0.491)" fill="#ffc107" />
    </svg>
    const star_half_fill = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" className="bi bi-star-half" viewBox="0 0 16 16">
        <path d="M5.354 5.119 7.538.792A.516.516 0 0 1 8 .5c.183 0 .366.097.465.292l2.184 4.327 4.898.696A.537.537 0 0 1 16 6.32a.548.548 0 0 1-.17.445l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256a.52.52 0 0 1-.146.05c-.342.06-.668-.254-.6-.642l.83-4.73L.173 6.765a.55.55 0 0 1-.172-.403.58.58 0 0 1 .085-.302.513.513 0 0 1 .37-.245l4.898-.696zM8 12.027a.5.5 0 0 1 .232.056l3.686 1.894-.694-3.957a.565.565 0 0 1 .162-.505l2.907-2.77-4.052-.576a.525.525 0 0 1-.393-.288L8.001 2.223 8 2.226v9.8z" fill="#ffc107" />
    </svg>
    const star_unfill = <svg className='star' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 64 61.335">
        <path id="star_14_" data-name="star (14)" d="M14.325,61.827a3.407,3.407,0,0,1-3.315-4.16l4.117-18.137L1.165,27.284a3.4,3.4,0,0,1,1.928-5.953l18.475-1.677,7.3-17.094a3.4,3.4,0,0,1,6.256,0l7.3,17.1L60.9,21.331a3.405,3.405,0,0,1,1.934,5.953L48.875,39.527l4.117,18.137a3.4,3.4,0,0,1-5.061,3.675L32,51.818,16.069,61.344a3.409,3.409,0,0,1-1.744.483ZM32,47.717a3.41,3.41,0,0,1,1.744.482L48.78,57.192,44.894,40.074a3.4,3.4,0,0,1,1.078-3.315L59.156,25.2,41.712,23.612a3.4,3.4,0,0,1-2.827-2.062L32,5.418,25.107,21.552a3.388,3.388,0,0,1-2.818,2.054L4.842,25.19,18.026,36.753A3.4,3.4,0,0,1,19.1,40.071L15.221,57.189,30.256,48.2A3.408,3.408,0,0,1,32,47.717ZM21.427,19.984a.008.008,0,0,1,0,.005Zm21.142-.008,0,.005S42.571,19.979,42.568,19.976Zm0,0" transform="translate(0 -0.492)" fill="#ffe188" />
    </svg>

    return (
        <>
            {
                deatilDataExists.exists
                    ?
                    <>
                        <BackHeader linkto={props.pagename === 'nearbydetail' ? '/nearby' : location.pathname} backtopagename={deatilDataExists.data.locationtype} onClick={() => { props.setPageName(props.pagename === 'nearbydetail' ? 'nearbyaddress' : ''); setDeatilDataExists({ exists: false, data: {} }) }} handleFilterData={props.handleFilterData} handleApplyFilterButtonClick={props.handleApplyFilterButtonClick} handleSerachClose={props.handleSerachClose} nearbydetail={props.pagename} />
                        <div className="body-content notify-body-content" onScroll={(e) => props.handleScroll(e, 'nearbyaddress')} >
                            <div className="col-12">
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='nearby-container'>
                                            <h5>{deatilDataExists.data.locationname}</h5>
                                            <h4 className='nearby-title'>
                                                <i className='material-icons-outlined text-primary'>location_on</i>
                                                <span>{deatilDataExists.data.locationaddress}</span>
                                            </h4>
                                            {
                                                deatilDataExists.data.locationimages.length > 0
                                                &&
                                                <Carousel className="custom-slider mb-3" interval={5000} fade={true}>
                                                    {deatilDataExists.data.locationimages?.map((item) => {
                                                        return (
                                                            <Carousel.Item>
                                                                <div className="multiple-image-slider">
                                                                    <img src={item.image} />
                                                                </div>
                                                            </Carousel.Item>
                                                        )
                                                    })}
                                                </Carousel>
                                            }
                                            <div className='other-info'>
                                                {
                                                    deatilDataExists.data.rating > 0
                                                        ?
                                                        <p className='star-data'>
                                                            <span className='text-18 font-weight-500'>{deatilDataExists.data.rating}</span>
                                                            <span className='text-10 ml-1'>({deatilDataExists.data.userratingtotal})</span>
                                                            <span className='d-flex'>
                                                                {
                                                                    [...Array(5)].map((star, index) => {
                                                                        let ratingtype = Number.isInteger(deatilDataExists.data.rating)
                                                                        let filstar
                                                                        let halffillstar
                                                                        if (!ratingtype) {
                                                                            filstar = deatilDataExists.data.rating.toString().split('.')[0]
                                                                            halffillstar = deatilDataExists.data.rating.toString().split('.')[1]
                                                                        }
                                                                        else {
                                                                            filstar = deatilDataExists.data.rating
                                                                        }
                                                                        if (filstar - 1 >= index) {
                                                                            return <div className='mr-1'>
                                                                                {star_fill}
                                                                            </div>
                                                                        }
                                                                        else if (halffillstar && parseInt(filstar) === index) {
                                                                            return <div className='mr-1'>
                                                                                {star_half_fill}
                                                                            </div>
                                                                        }
                                                                        else {
                                                                            return <div className='mr-1'>
                                                                                {star_unfill}
                                                                            </div>
                                                                        }
                                                                    })
                                                                }
                                                            </span>
                                                        </p>
                                                        :
                                                        <p className='star-data'>
                                                            <span className='text-18'>Not rated yet</span>
                                                        </p>
                                                }
                                                {
                                                    deatilDataExists.data.distance > 0
                                                    &&
                                                    <p className='distance-text'>
                                                        {/* <i className='material-icons-round'>route</i> */}
                                                        <span className='font-weight-500 text-18'>{deatilDataExists.data.distance} km away</span>
                                                        {/* <span className='text-gray text-10'>(Straight Distance)</span> */}
                                                    </p>
                                                }
                                            </div>
                                            {/* <p className='text-12 nearby-subtext'>{deatilDataExists.data.description}</p> */}
                                            <iframe
                                                width="100%"
                                                height="300"
                                                style={{ border: 0 }}
                                                loading="lazy"
                                                allowfullscreen
                                                src={`https://www.google.com/maps/embed/v1/place?q=${deatilDataExists.data.latitude},${deatilDataExists.data.longitude}&key=AIzaSyDnROQJW4hFTvFdFbfGrIUr7ux6thEV_44`}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </>
                    :
                    <>
                        <BackHeader linkto={props.pagename === 'nearbyaddress' ? '/nearby' : location.pathname} backtopagename={getCurrentState().formData.locationtype} onClick={() => props.setPageName(props.pagename === 'nearbyaddress' ? 'nearbytype' : '')} handleFilterData={props.handleFilterData} handleApplyFilterButtonClick={props.handleApplyFilterButtonClick} handleSerachClose={props.handleSerachClose} />
                        <div className="body-content notify-body-content" onScroll={(e) => props.handleScroll(e, 'nearbyaddress')} >
                            <div className="col-12">
                                <div className='row'>
                                    <div className='col-12'>
                                        {
                                            (getCurrentState().formName === '')
                                                ?
                                                <>
                                                    <Placeholder.Graph active className='notify-inner mt-3 mb-0'>
                                                        <div className='notify-graph'>
                                                            <div className='notify-img'>
                                                                <img graph="image" src={placeholderImg} className='placeholder-img' />
                                                            </div>
                                                            <div className='notify-graph-content'>
                                                                <p className='graph'></p>
                                                                <p className='graph'></p>
                                                                <p className='graph'></p>
                                                            </div>
                                                        </div>
                                                    </Placeholder.Graph>
                                                    <Placeholder.Graph active className='notify-inner mt-3 mb-0'>
                                                        <div className='notify-graph'>
                                                            <div className='notify-img'>
                                                                <img graph="image" src={placeholderImg} className='placeholder-img' />
                                                            </div>
                                                            <div className='notify-graph-content'>
                                                                <p className='graph'></p>
                                                                <p className='graph'></p>
                                                                <p className='graph'></p>
                                                            </div>
                                                        </div>
                                                    </Placeholder.Graph>
                                                    <Placeholder.Graph active className='notify-inner mt-3 mb-0'>
                                                        <div className='notify-graph'>
                                                            <div className='notify-img'>
                                                                <img graph="image" src={placeholderImg} className='placeholder-img' />
                                                            </div>
                                                            <div className='notify-graph-content'>
                                                                <p className='graph'></p>
                                                                <p className='graph'></p>
                                                                <p className='graph'></p>
                                                            </div>
                                                        </div>
                                                    </Placeholder.Graph>
                                                    <Placeholder.Graph active className='notify-inner mt-3 mb-0'>
                                                        <div className='notify-graph'>
                                                            <div className='notify-img'>
                                                                <img graph="image" src={placeholderImg} className='placeholder-img' />
                                                            </div>
                                                            <div className='notify-graph-content'>
                                                                <p className='graph'></p>
                                                                <p className='graph'></p>
                                                                <p className='graph'></p>
                                                            </div>
                                                        </div>
                                                    </Placeholder.Graph>
                                                    <Placeholder.Graph active className='notify-inner mt-3 mb-0'>
                                                        <div className='notify-graph'>
                                                            <div className='notify-img'>
                                                                <img graph="image" src={placeholderImg} className='placeholder-img' />
                                                            </div>
                                                            <div className='notify-graph-content'>
                                                                <p className='graph'></p>
                                                                <p className='graph'></p>
                                                                <p className='graph'></p>
                                                            </div>
                                                        </div>
                                                    </Placeholder.Graph>
                                                    <Placeholder.Graph active className='notify-inner mt-3 mb-0'>
                                                        <div className='notify-graph'>
                                                            <div className='notify-img'>
                                                                <img graph="image" src={placeholderImg} className='placeholder-img' />
                                                            </div>
                                                            <div className='notify-graph-content'>
                                                                <p className='graph'></p>
                                                                <p className='graph'></p>
                                                                <p className='graph'></p>
                                                            </div>
                                                        </div>
                                                    </Placeholder.Graph>
                                                </>
                                                :
                                                ((getCurrentState().formName?.length > 0) && (getCurrentState().masterDataList['nearbyaddress']?.length === 0))
                                                    ?
                                                    <div className='col-12'>
                                                        <div className='no-data-found'>
                                                            <div className='noData-content'>
                                                                <img src={NoDataImage} />
                                                                <h2 className="text-center text-16 mb-0 font-weight-600">No nearby locations found</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    (getCurrentState().masterDataList['nearbyaddress']?.length > 0) && getCurrentState().masterDataList['nearbyaddress']?.map((data, index) => {
                                                        return (
                                                            <div className="content content-boder-left" key={index}>
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <div className="">
                                                                        {(data.locationname !== '' || data.locationname !== undefined) && <><span className='maintext d-block nearbyContentTitle'>{data?.locationname}</span> </>}
                                                                        {(data.distance !== '' || data.distance !== undefined) && <span className='subtext d-block nearbyContentText'>{data?.distance || 0} km from your hotel</span>}
                                                                    </div>
                                                                    <a className="nearDirection cursor-pointer" onClick={() => { handleDetailPage(data); props.setPageName('nearbydetail') }}>
                                                                        <img className='direction-img' src={Direction} />{/* target='_blank' href={`http://www.google.com/maps/place/${data.latitude},${data.longitude}`} */}
                                                                        <span className='direction'>Direction</span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </>
            }
        </>
    )
}

export default NearByTypeView