import React, { useState, useEffect } from "react";
import useAddToHomescreenPrompt from "../hooks/useAddToHomescreenPrompt";
import NextapIcon from '../assets/images/favicon.png'
import useIsIOS from "../hooks/useIsIOS";

const PWA = () => {
    const { isIOS, isSafari, iosPrompt } = useIsIOS()
    const [promptstatus, prompt, promptToInstall] = useAddToHomescreenPrompt();
    const [isVisible, setVisibleState] = useState(1);

    const hide = () => setVisibleState(0);
    // console.log('prompt.', prompt?.userChoice)

    useEffect(() => {
        (async function() {
            if (prompt) {
                setVisibleState(promptstatus)
                // handleInstallPWA()
            }
        })()
    }, [isIOS, isSafari, iosPrompt, prompt, promptstatus]);

    const handleInstallPWA = () => {
        setVisibleState(0); promptToInstall();
    }
    // if (!isVisible || !promptstatus) {
    //     return <div />;
    // }

    return {
        isVisible,
        promptstatus,
        handleInstallPWA,
        prompt,
        isIOS, isSafari, iosPrompt
        // hide: hidePWA
    }

    // return (
    //     <div className='pwa-popup p-2'>
    //         <div className='cursor-pointer' onClick={() => { setVisibleState(0); promptToInstall(); }}>
    //             <img src={NextapIcon} className='mr-2' height={30} />
    //             <span className='text-12 font-weight-600 pwa-text'>Add Nextap to Home Screen</span>
    //         </div>
    //         <div className="d-flex">                        
    //             <i className='material-icons-outlined my-auto pwa-icons' onClick={hide}>close</i>
    //         </div>
    //     </div>
    // )
}

export default PWA;