import React, { useRef, useState } from 'react'
import { DatePicker } from 'rsuite'
import { IISMethods } from '../../config/IISMethods'

const TimePickerRsuite = (props) => {
    const timeRef = useRef(null)
    const [defaultValue, setDefaultValue] = useState('')

    const mountRef = React.useRef(null);

    return (
        <div ref={mountRef} className='date-picker'>
            <DatePicker
                onOpen={() => {
                    setDefaultValue(IISMethods.GetDateTimefromISOString(IISMethods.GetTZdatetime().toISOString()))
                }}
                onClose={() => {
                    setDefaultValue('')
                }}
                ref={timeRef}
                cleanable={props.cleanable}
                className={props.className}
                placeholder={props.placeholder}
                format={props.format}
                container={() => mountRef.current} //anjali 10-08-2023
                // defaultValue={defaultValue}
                name={props.name}
                value={props.value || defaultValue}
                onClean={() => props.onChange('')}
                disabled={props.disabled}
                calendarDefaultDate={IISMethods.GetDateTimefromISOString(IISMethods.GetTZdatetime().toISOString())}
                {...props.options}
                onOk={(time) => props.onChange(time ? time : '')}
                showMeridian id={props.id}
                // onChange={props.onChange} //comment code JENIL 04/08/2023 5:51 PM
                type={props.type}
                ranges={props.ranges}
                // placement={props.placement || 'auto'}
                placement={"bottom"}
                editable={false}
            />
        </div>
    )
}

export default TimePickerRsuite