import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Config, IISMethods, allowdedImage } from '../config/IISMethods'
import { setProps } from '../redux/actions'
import { store } from '../index'
import SurveyController from '../Controller/SurveyController'

const Survey = (props) => {
    useEffect(() => {
        async function fetchMyAPI() {
            await props.setProps({
                rightSidebarFormData: []
            })
        }
        fetchMyAPI()
    }, [])
    return (
        <SurveyController
            masterjson={masterjson}
            pagename={props.pagename}
        />
    )
}

const getCurrentState = () => {
    return store.getState()['root']
}

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    setProps: (payload) => dispatch(setProps(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Survey)

const masterjson = (key) => {
    let propertyid = getCurrentState().reservationDetails.propertyid
    switch (key) {
        case 'surveyrating':
            return [
                {
                    rightsidebarsize: Config.getModalsizeclasses()['sm'],
                    pagename: 'requestrating',
                    formname: 'request rating',
                    apipath: 'guestexperience/taskguestrating/add',

                    formfields: [
                        //0
                        {
                            'field': 'taskguestrating',
                            'text': 'stars',
                            'type': Config.getHtmlcontorls()['dropdown'],
                            'disabled': false,
                            'defaultvisibility': true,
                            'cleanable': true,
                            'required': true,
                            'gridsize': Config.getGridclasses()['col-12'] + ' ' + Config.getGridclasses()['col-md-4'],
                            'apipath': 'masters/ratings',
                            'masterdata': 'guestrequestrating',
                            'masterdatafield': 'rating',
                            'formdatafield': 'rating',
                            'istablefield': 1,
                            'staticfilter': {
                                'propertyid': getCurrentState().reservationDetails.propertyid,
                                'ratingcategoryid': Config.ratingcategoryid.requestratings
                            },
                        },
                        //1
                        {
                            'field': 'suggestion',
                            'text': 'Suggestion',
                            'type': Config.getHtmlcontorls()['input-textarea'],
                            'disabled': false,
                            'cleanable': true,
                            'defaultvisibility': true,
                            'required': false,
                            'gridsize': Config.getGridclasses()['col-12'],
                        },
                    ]
                }
            ]

        default:
            return []
    }
}