import React, { useEffect } from 'react'
import { store } from '..'
import Header from '../Header'
import { setProps } from '../redux/actions'
import { connect } from 'react-redux'
import AmenitiesRightSidebar from './AmenitiesRightSidebar'
import BackHeader from '../BackHeader'
import Footer from '../Footer'
import { Placeholder } from 'rsuite'
import NoDataImage from '../assets/images/no-data-img.png'
import PropertyLayoutRightSidebar from './PropertyLayoutRightSidebar'


const PropertyLayoutView = (props) => {
    const getCurrentState = () => {
        return store.getState()['root']
    }
    try {
        return (
            <>
                {/* back history not static link JENIL 13/9/2023 10:47 PM */}
                <BackHeader isHistoryBack={true} linkto='/myhotel' backtopagename='Property Layout' handleFilterData={props.handleFilterData} handleApplyFilterButtonClick={props.handleApplyFilterButtonClick} handleSerachClose={props.handleSerachClose} />
                {/* for menu listing filterwise 29-3-2023 srushti */}
                <div className="body-content amenitiesBody">
                    <div className="col-12">

                        {
                            (getCurrentState().formName === '')
                                ?
                                <div className="row">
                                    {
                                        [...Array(5)].map((data, index) => {
                                            return <div className='col-6 box-space propertyLayoutBox '>
                                                <Placeholder.Graph active className='' style={{ height: "200px", width: "100%" }}> </Placeholder.Graph>
                                            </div>
                                        })
                                    }
                                </div>
                                :
                                ((getCurrentState().formName?.length > 0) && (getCurrentState().data?.length === 0)) ?
                                    <div className='col-12'>
                                        <div className='no-data-found'>
                                            <div className='noData-content'>
                                                <img src={NoDataImage} />
                                                <h2 className="text-center text-16 mb-0 font-weight-600">Layout not available at the moment</h2>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="row p-2">
                                        {getCurrentState().data?.map((data, index) => {
                                            return (
                                                <div className="col-6 box-space propertyLayoutBox" key={index}>
                                                    <div className="card-stay" onClick={() => props.setFormData(data._id)} >
                                                        <div className="card-stay-imgs">
                                                            {(data.layoutimage !== '' || data.layoutimage !== undefined) && <img src={data.layoutimage} />}
                                                        </div>
                                                    </div>
                                                    {(data.layouttitle !== '' || data.layouttitle !== undefined) && <p className="label-name-stay mt-2 text-18">{data.layouttitle}</p>}
                                                </div>
                                            )
                                        })}
                                    </div>
                        }
                    </div>
                </div>


                <Footer />
                <PropertyLayoutRightSidebar
                    handleGrid={props.handleGrid}
                />
            </>
        )
    } catch (error) {
    }
}

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    setProps: (payload) => dispatch(setProps(payload))
})


export default connect(mapStateToProps, mapDispatchToProps)(PropertyLayoutView)



