import React, { useEffect } from 'react'
import PromotionView from '../view/PromotionView'
import { clearData, setLoading, setProps } from '../redux/actions'
import { connect } from 'react-redux'
import { Config, IISMethods, resBody, setReqParams } from '../config/IISMethods'
import useAPICall from '../hooks/useApiCall'
import { store } from '..'
import useFirebase from '../ServiceWorker/useFirebase'

const PromotionsController = (props) => {
    const { getAccessToken, getReservationDetails, getPropertyData } = useAPICall()

    const getCurrentState = () => store.getState()['root']

    // for notification request
    const { requestPermission } = useFirebase()

    useEffect(() => {
        async function fetchMastersJson() {
            props.setProps({
                fieldOrder: [],
                newFieldOrder: [],
                nextpage: 0,
                pageNo: 1,
                pagelimit: 20,
                pageName: '',
                formName: '',
                sortData: {},
                formData: {},
                filterData: {},
                oldFilterData: {},
                modal: {},
                reservationDetails: {},
                propertyData: {},
                // rightSidebarFormData : [],
                masterData: {},
                masterDataList: {}
            })
            //(DO NOT CHANGE WITHOUT PERMISSION)charmi(06-09-2023 12:35)(listing api calling issue fix & install application modal open issue fix)
            await props.clearData()
            await getAccessToken()
            await getReservationDetails()
            if (getCurrentState().reservationDetails.ischeckin === 1) {
                await getPropertyData()
                if (getCurrentState().reservationDetails['isreservationexpired'] !== 1) {
                    await requestPermission()
                }
                await getList()
            }
            //(DO NOT CHANGE WITHOUT PERMISSION)charmi(06-09-2023 12:35)(listing api calling issue fix & install application modal open issue fix)
        }
        fetchMastersJson()
    }, [])

    //for open modal - recordinfo - create button
    const handleGrid = (id, type, key, value) => {
        if (type === 'modal') {
            if (value) {
                getCurrentState().modal[key] = id
            }
            else {
                delete getCurrentState().modal[key]
            }
            props.setProps({ modal: IISMethods.getCopy(getCurrentState().modal) })
        }
        else { }
    }

    // List Data
    const getList = async () => {
        if (!getCurrentState().data.length > 0) {
            await props.setProps({ formName: '' })
        }
        await props.setProps({ formName: '' })

        const url = Config.weburl + 'guestexperience/promotions'
        const useraction = 'l'
        let filter = {}
        Object.entries(getCurrentState().filterData).forEach(([key, value]) => {
            if (typeof (value) === 'number') {
                if (value !== 0)
                    filter[key] = value
            }
            else
                filter[key] = value
        })

        let searchtext = filter.searchtext || ''
        delete filter.searchtext
        const reqBody = {
            searchtext: searchtext,
            fromdate: filter.fromdate,
            todate: filter.todate,
            paginationinfo: {
                pageno: getCurrentState().pageNo,
                pagelimit: getCurrentState().pagelimit,
                filter: {
                    isactive: 1
                },
                sort: {
                    // _id: -1
                }
            }
        }

        await setReqParams(url, reqBody)
        await IISMethods.listData(useraction)

        if (resBody.status === 200) {
            if (getCurrentState().pageNo === 1) {
                await props.clearData()
            }
            await props.setProps({
                data: resBody.data,
                pageNo: resBody.pageno,
                pagelimit: resBody.pagelimit,
                nextpage: resBody.nextpage,
                formName: resBody.formname,
                pageName: resBody.pagename,
            })
        }
        else {

        }
    }

    //for grid scroll event - pagination
    const handleScroll = async (e) => {
        let element = e.target
        if (element.scrollHeight - element.scrollTop === element.clientHeight || ((element.scrollHeight - element.scrollTop) - 1 === element.clientHeight)) {
            if (getCurrentState().nextpage && (getCurrentState().pageNo * getCurrentState().pagelimit === getCurrentState().data.length)) {
                await props.setProps({ pageNo: getCurrentState().pageNo + 1 })
                getList()
            }
        }
    }

    // jatin 14-08-2023 for  Search
    const handleFilterData = async (type, key, value) => {
        getCurrentState().filterData[key] = value
        await props.setProps({ filterData: IISMethods.getCopy(getCurrentState().filterData), pageNo: 1 })       
        // await getList()
    }
    // jatin 14-08-2023 for Search
    const handleApplyFilterButtonClick = async (e, type) => {
        if(e) e.preventDefault()
         if (type === 'removevalue') {
             getCurrentState().filterData['searchtext'] = ''
             await props.setProps({ pageNo: 1, oldFilterData: IISMethods.getCopy(getCurrentState().filterData) })
             await props.clearData()
             await getList()
         }
         else {
             await props.setProps({ pageNo: 1, oldFilterData: IISMethods.getCopy(getCurrentState().filterData) })
             await props.clearData()
             await getList()
         }
     }
      // jatin 15-08-2023 11:53 PM
    const handleSerachClose = async()=>{
        getCurrentState().filterData["searchtext"] = " "
        await props.setProps({ filterData: IISMethods.getCopy(getCurrentState().filterData), pageNo: 1 })     
        await getList()

     }

    return (
        <PromotionView
            handleGrid={handleGrid}
            handleScroll={handleScroll}
            handleFilterData={handleFilterData}
            handleApplyFilterButtonClick={handleApplyFilterButtonClick}
            handleSerachClose={handleSerachClose}
        />
    )
}

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    clearData: (payload) => dispatch(clearData(payload)),
    setProps: (payload) => dispatch(setProps(payload)),
    setLoading: (payload) => dispatch(setLoading(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PromotionsController)