// import React from "react";
// import ReactDOM from "react-dom/client";
// import App from "./App";
// import "./Css";
// import { Provider } from "react-redux";
// import { legacy_createStore as createStore } from "redux";
// import reducer from "./redux/reducer";
// import Head from "./Head";
// import { Config } from "./config/IISMethods";
// // import * as SWRegistration from "./SWRegistration"; // for service worker registert/unregister

// const root = ReactDOM.createRoot(document.getElementById("root"));

// if (Config.getServermode() !== 'dev') { // Bhautik 22-06-2023 03:04 PM ~ for remove all console on server
//     // console.log = console.warn = console.error = () => {}
//     console.log = () => {}
// }

// export const store = createStore(
//     reducer,
//     window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
// );

// root.render(
//     <Provider store={store}>
//         <Head />
//         <App />
//     </Provider>
// );

// // for service worker register
// // SWRegistration.register();
// // for service worker unregister
// // SWRegistration.unregister();
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./Css";
import { Provider } from "react-redux";
import { combineReducers, legacy_createStore as createStore } from "redux";
// import appReducer from "./redux/appReducer";
import appReducer from "./redux/appReducer";
// import infoReducer from "./redux/infoReducer";
import infoReducer from "./redux/infoReducer";

import Head from "./Head";
import { Config } from "./config/IISMethods";
import storage from 'redux-persist/lib/storage'
import { persistReducer } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import persistStore from "redux-persist/es/persistStore";
import { BrowserRouter } from "react-router-dom";
// import * as SWRegistration from "./SWRegistration"; // for service worker registert/unregister

const root = ReactDOM.createRoot(document.getElementById("root"));

if (Config.getServermode() !== 'dev') { // Bhautik 22-06-2023 03:04 PM ~ for remove all console on server
    // console.log = console.warn = console.error = () => {}
    console.log = () => {}
}

const persistConfig = {
    key: 'root',
    storage,
}

const rootReducer = combineReducers({
    root: appReducer,
    info: persistReducer(persistConfig, infoReducer)
})

export const store = createStore(
    // persistReducer(persistConfig, rootReducer),
    rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

// Bhautik persostor store
const persistor = persistStore(store);

const msclarity = () => {
    (function (c, l, a, r, i, t, y) {
        c[a] =
            c[a] ||
            function () {
                (c[a].q = c[a].q || []).push(arguments);
            };
        t = l.createElement(r);
        t.async = 1;
        t.src = "https://www.clarity.ms/tag/" + i;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
    })(window, document, "clarity", "script", 'kiifo8yf3i');
} 

root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor} >
            <BrowserRouter>
                <Head />
                <App />
            </BrowserRouter>
        </PersistGate>
        <script type='text/javascript'>{msclarity()}</script>
    </Provider>
);

// for service worker register
// SWRegistration.register();
// for service worker unregister
// SWRegistration.unregister();