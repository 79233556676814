import React, { useState } from 'react'
import { store } from '../index'
import { Config, IISMethods, JsCall } from '../config/IISMethods'
import ModalRsuite from './Components/ModalRsuite'
import DatePickerRsuite from "./Components/DatePickerRsuite"
import TooltipRsuite from './Components/TooltipRsuite'
import Calender from '../assets/images/Calender.svg'
import isBefore from 'date-fns/isBefore'
import Loader from './Components/Loader'

export default function ExtendStayRightSidebar(props) {

    const getCurrentState = () => {
        return store.getState()['root']
    }

    try {
        return (
            <>
                {/* extend Stay frit modal srushti*/}
                {
                    getCurrentState().modal['extendstayModal']
                    &&
                    <ModalRsuite
                        size={"xs"}
                        open={getCurrentState().modal['extendstayModal']}
                        onClose={() => props.handleGrid(false, 'modal', 'extendstayModal', 0)}
                        body={
                            <>
                                <div className={`col-12`}>
                                    <div className="first-pop-logo">
                                        <img src={Calender} />
                                    </div>
                                </div>
                                <div className="col-12 mt-3">
                                    <span className='text-center d-block'>Do you have a new reservation</span>
                                    <span className='text-center d-block'>for Tonight ? </span>
                                </div>
                                <div className="col-12 mt-4">
                                    <div className='row justify-content-center'>
                                        <div className='col-6 pr-2'>
                                            <button type="submit" className="btn-yes-no  btn-secondary-light"
                                                onClick={(e) => {
                                                    getCurrentState().loading === 0 && props.handleAddButtonClick(e, 'extendstayModal')
                                                }}
                                            >Yes</button>
                                        </div>
                                        <div className='col-6 pl-2'>
                                            <button type="submit" className="btn-yes-no  btn-primary-light"
                                                onClick={() => {
                                                    props.handleGrid(false, 'modal', 'extendstayModal', 0)
                                                    props.handleGrid(true, 'modal', 'extendstaydetailsModal', 1)
                                                    props.setFormData(null, 'extendstay', 'extendstaydetailsModal')
                                                }}
                                            >No</button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    />
                }

                {/* extend stay Modal Frist Modal srushti*/}

                <ModalRsuite
                    size={"xs"}
                    open={getCurrentState().modal['extendstaydetailsModal']}
                    onClose={() => { props.handleGrid(false, 'modal', 'extendstaydetailsModal', 0) }}
                    className="modalContentPad"
                    body={
                        <form method="post">
                            <div className='col-12 text-center mb-3'>
                                <img src={Calender} height="50px" />
                                <div className={`col-12 checkout-email px-0 pt-2`}>
                                    <span className='text-center d-block'>How many night would you like to</span>
                                    <span className='text-center d-block'> extend your stay ?</span>
                                </div>
                            </div>

                            <div className="col-12 mb-2">  {/* keval 4-4-2023 */}
                                <span className='guest-request-maintenance-lable'>Days</span>
                            </div>

                            <div className='col-12'>
                                <div className="form-group product-add-box">
                                    <div className="d-flex justify-content-between">
                                        <TooltipRsuite
                                            placement={Config.getTooltip()['placement']}
                                            tooltip={Config.getTooltip()['remove']}
                                            content={
                                                <a className="add-field-btn btn-icon position-static remove-box m-2" onClick={() => props.handleFormData('counter', 'noofnight', 'dec')}><i className="material-icons-round">remove</i></a>
                                            }
                                        />
                                        <div className="col-9 d-flex justify-content-center align-items-center notifyText">{getCurrentState().formData.extendstaydetails?.noofnight}</div>
                                        <TooltipRsuite
                                            placement={Config.getTooltip()['placement']}
                                            tooltip={Config.getTooltip()['add']}
                                            content={
                                                <a className="add-field-btn btn-icon position-static remove-box m-2" onClick={() => props.handleFormData('counter', 'noofnight', 'inc')}><i className="material-icons-round">add</i></a>
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            {
                                getCurrentState().rightSidebarFormData[0]?.formfields.filter(formfield => {
                                    let conditions = false
                                    if (formfield.condition) {
                                        let condition = formfield.condition?.map((item) => {
                                            if (getCurrentState().formData[item.field] === item.onvalue) {
                                                return true
                                            }
                                            return false
                                        })
                                        let status = condition.includes(true) ? true : false
                                        conditions = status
                                    }
                                    try {
                                        return formfield.defaultvisibility || conditions
                                    }
                                    catch {
                                        return false
                                    }
                                }).map((formfield, fieldindex) => {
                                    if (formfield.type === 'datepicker') {
                                        let value = getCurrentState().formData.extendstaydetails[formfield.field] || ''
                                        return (
                                            <div className={formfield.gridsize} key={`${fieldindex}`}>
                                                <div className="form-group validate-input">
                                                    <label className='guest-request-maintenance-lable'>{formfield.text}</label>
                                                    <DatePickerRsuite
                                                        placement="bottomStart"
                                                        className="form-control p-0 has-val custom-picker"
                                                        placeholder={`Enter ${formfield.text}`}
                                                        format={IISMethods.getDateFormatByProperty()}
                                                        id={getCurrentState().formname + formfield.field}
                                                        name={formfield.field}
                                                        value={value ? IISMethods.GetDateTimefromISOString(value) : value}
                                                        onChange={(e) => props.handleFormData(formfield.type, formfield.field, e)}
                                                        cleanable={formfield.cleanable}
                                                        disabledDate={date => isBefore(date, IISMethods.GetDT(getCurrentState().reservationDetails['checkoutdate']))}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </form>
                    }
                    footer={
                        <>
                            <div className="col-12">
                                <button type="submit" className="vehicle-model-btn"
                                    onClick={(e) => {
                                        getCurrentState().loading === 0 && props.handleAddButtonClick(e, 'extendstaydetailsModal')
                                    }} >
                                    <Loader content={'Extend Stay'} />
                                </button>
                            </div>
                        </>
                    }
                />

                {/* last Thank You Pop Modal srushti*/}

                <ModalRsuite
                    size={"xs"}
                    open={getCurrentState().modal['ExtendstayThankyou']}
                    onClose={() => props.handleGrid(false, 'modal', 'ExtendstayThankyou', 0)}
                    body={
                        <>
                            <div className='col-12 text-center'>
                                <img src={Calender} height="50px" />
                            </div>
                            <div className="col-12 mt-3 checkout-email">
                                <span className='text-center d-block'>You will be receiving a link momentarily to complete your registration for your stay.
                                    Please complete the recheck-in process within 30 minutes once you receive the link. </span>
                                {/* <h1 className='extend-popup-body'>to complete your registration for your</h1>
                                    <h1 className='extend-popup-body'> stay.Please complete the recheck-in </h1>
                                    <h1 className='extend-popup-body'>process within 30 minutes once you</h1>
                                    <h1 className='extend-popup-body'> receive the link.</h1> */}
                            </div>
                            <div className="col-12">
                                <div className='row'>
                                    <div className="col-12 mt-4">
                                        <button type="submit" className="btn-first-popup mb-0"
                                            onClick={() => {
                                                props.handleGrid(false, 'modal', 'ExtendstayThankyou', 0)
                                            }} >
                                            Okay
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                />


                {/* verification data modal srushti 23-5-23 */}
                {getCurrentState().modal?.verificationdatamodal &&
                    <>
                        {getCurrentState().modal['verificationdatamodal']
                            &&
                            <ModalRsuite
                                size={"xs"}
                                open={getCurrentState().modal['verificationdatamodal']}
                                onClose={() => props.handleGrid(false, 'modal', 'verificationdatamodal', 0)}

                                body={
                                    <>
                                        <div className="col-md-12 text-center mt-4">
                                            <span>{getCurrentState().reservationDetails.popupmessage}</span>
                                        </div>
                                        {getCurrentState().reservationDetails.isshowbutton === 1 &&
                                            <div className="col-12 mt-4">
                                                <a target='_blank' href={getCurrentState().reservationDetails.mobilecheckinlink}>
                                                    <span className='vehicle-model-btn text-center '>Go to Mobile checkin link</span>
                                                </a>
                                            </div>
                                        }

                                        <div className="col-12 ">
                                            <div className='row'>
                                                {getCurrentState().reservationDetails.addnewrequest === 1 &&
                                                    <div className="col-6 m-auto">
                                                        <button type="submit" className="vehicle-model-btn mt-4"
                                                            onClick={(e) => {
                                                                props.handleGrid(false, 'modal', 'verificationdatamodal', 0)
                                                                props.setFormData(null, 'extendstay', 'extendstayModal')
                                                            }} >
                                                            <Loader content={'Add New request'} />
                                                        </button>
                                                    </div>
                                                }

                                                {getCurrentState().reservationDetails.showrejectreason === 1 &&
                                                    <div className="col-6 m-auto">
                                                        <button type="submit" className="vehicle-model-btn mt-4"
                                                            onClick={(e) => {
                                                                props.handleGrid(false, 'modal', 'verificationdatamodal', 0)
                                                                props.handleGrid(true, 'modal', 'rejectreasonmodal', 1)
                                                            }} >
                                                            <Loader content={'Reject Reason'} />
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </>
                                }
                            />}
                    </>}

                {/* reject Reason msg modal srushti 30-5-23 */}
                {getCurrentState().modal?.rejectreasonmodal &&
                    <>
                        {getCurrentState().modal['rejectreasonmodal']
                            &&
                            <ModalRsuite
                                size={"xs"}
                                open={getCurrentState().modal['rejectreasonmodal']}
                                onClose={() => props.handleGrid(false, 'modal', 'rejectreasonmodal', 0)}

                                body={
                                    <>
                                        <div className="col-md-12 text-center mt-4">
                                            <span>{getCurrentState().reservationDetails.rejectreason}</span>
                                        </div>
                                        <div className="col-12 mt-3">
                                            <button type="submit" className="btn-first-popup mb-0" onClick={(e) => props.handleGrid(false, 'modal', 'rejectreasonmodal', 0)}>Okay</button>
                                        </div>
                                    </>
                                }
                            />}
                    </>}
            </>
        )
    } catch (error) {
        console.log(error)
        return <></>
    }
}
