import React, { useEffect } from 'react'
import { store } from '..'
import Header from '../Header'
import { setProps } from '../redux/actions'
import { connect } from 'react-redux'
import AmenitiesRightSidebar from './AmenitiesRightSidebar'
import BackHeader from '../BackHeader'
import Footer from '../Footer'
import { Placeholder } from 'rsuite'
import NoDataImage from '../assets/images/no-data-img.png'


// jatin 
const AmenitiesView = (props) => {
    const getCurrentState = () => {
        return store.getState()['root']
    }
    try {
        return (
            <>
                <BackHeader linkto='/myhotel' backtopagename='Amenities' handleFilterData={props.handleFilterData} handleApplyFilterButtonClick={props.handleApplyFilterButtonClick} handleSerachClose={props.handleSerachClose} />
                {/* for menu listing filterwise 29-3-2023 srushti */}
                <div className="body-content amenitiesBody">
                    <div className="col-12">
                        {/* <div className="row p-2"> */}
                        {
                            (getCurrentState().formName === '')
                                ?
                                // <div className="col-12">
                                //     <Placeholder.Graph active className='placeHolderHeight mt-3'></Placeholder.Graph>
                                // </div>
                                <div className="row">
                                    {
                                        [...Array(9)].map((data, index) => {
                                            return <div className='col-4 col-sm-3 box-space amenitiesBox'>
                                                <Placeholder.Graph active className='' style={{ height: "100px", width: "100%" }}> </Placeholder.Graph>
                                            </div>
                                        })
                                    }
                                </div>
                                :
                                ((getCurrentState().formName?.length > 0) && (getCurrentState().data?.length === 0)) ?
                                    <div className='col-12'>
                                        <div className='no-data-found'>
                                            <div className='noData-content'>
                                                <img src={NoDataImage} />
                                                <h2 className="text-center text-16 mb-0 font-weight-600">Amenities are currently unavailable</h2>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="row p-2">
                                        {getCurrentState().data.length > 0 &&
                                            getCurrentState().data?.map((data, index) => {
                                                return (
                                                    <div className="col-4 col-sm-3 box-space amenitiesBox" key={index}>
                                                        <div className="card-stay" onClick={() => props.setFormData(data._id)} >
                                                            <div className="card-stay-imgs">
                                                                {(data.icon !== '' || data.icon !== undefined) && <img src={data.icon} />}
                                                            </div>
                                                        </div>
                                                        {(data.name !== '' || data.name !== undefined) && <p className="label-name-stay mt-2">{data.name}</p>}
                                                    </div>
                                                )
                                            })}
                                    </div>
                        }
                        {/* </div> */}
                    </div>
                </div>


                <Footer />
                <AmenitiesRightSidebar
                    handleGrid={props.handleGrid}
                />
            </>
        )
    } catch (error) {
    }
}

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    setProps: (payload) => dispatch(setProps(payload))
})


export default connect(mapStateToProps, mapDispatchToProps)(AmenitiesView)



