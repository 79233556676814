import React, { useState } from 'react'
import { Config, IISMethods, resBody, setReqParams } from '../config/IISMethods'
import { connect } from 'react-redux'
import 'rsuite/dist/rsuite.css'
import ModalRsuite from './Components/ModalRsuite'
import { setProps } from '../redux/actions'
import { store } from '../index'
import { Placeholder } from 'rsuite'
import NoDataImage from '../assets/images/no-data-img.png'
import { useLocation } from "react-router-dom"
// import Loader from './Components/Loader'
import { Loader } from 'rsuite';
import Filter from '../assets/images/filter.svg'
import { Accordion } from 'react-bootstrap'
import Rating from '../assets/images/rating.svg'


const SurveyGridList = (props) => {
    const location = useLocation()
    const getCurrentState = () => {
        return store.getState()['root']
    }

    const [activeacc, setActiveacc] = useState(['1', '2'])

    const star_fill = <svg className='star star-curser' xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 64 61.335">
        <path id="star_13_" data-name="star (13)" d="M63.833,23.669A3.4,3.4,0,0,0,60.9,21.33L42.433,19.653l-7.3-17.1a3.4,3.4,0,0,0-6.256,0l-7.3,17.094L3.093,21.33a3.406,3.406,0,0,0-1.931,5.953L15.125,39.528,11.008,57.666a3.4,3.4,0,0,0,5.059,3.675L32,51.817l15.928,9.524a3.4,3.4,0,0,0,5.062-3.675L48.873,39.528,62.836,27.285a3.4,3.4,0,0,0,1-3.616Zm0,0" transform="translate(0 -0.491)" fill="#ffc107" />
    </svg>
    const star_half_fill = <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" className="bi bi-star-half" viewBox="0 0 16 16">
        <path d="M5.354 5.119 7.538.792A.516.516 0 0 1 8 .5c.183 0 .366.097.465.292l2.184 4.327 4.898.696A.537.537 0 0 1 16 6.32a.548.548 0 0 1-.17.445l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256a.52.52 0 0 1-.146.05c-.342.06-.668-.254-.6-.642l.83-4.73L.173 6.765a.55.55 0 0 1-.172-.403.58.58 0 0 1 .085-.302.513.513 0 0 1 .37-.245l4.898-.696zM8 12.027a.5.5 0 0 1 .232.056l3.686 1.894-.694-3.957a.565.565 0 0 1 .162-.505l2.907-2.77-4.052-.576a.525.525 0 0 1-.393-.288L8.001 2.223 8 2.226v9.8z" fill="#ffc107" />
    </svg>
    const star_unfill = <svg className='star star-curser' xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 64 61.335">
        <path id="star_14_" data-name="star (14)" d="M14.325,61.827a3.407,3.407,0,0,1-3.315-4.16l4.117-18.137L1.165,27.284a3.4,3.4,0,0,1,1.928-5.953l18.475-1.677,7.3-17.094a3.4,3.4,0,0,1,6.256,0l7.3,17.1L60.9,21.331a3.405,3.405,0,0,1,1.934,5.953L48.875,39.527l4.117,18.137a3.4,3.4,0,0,1-5.061,3.675L32,51.818,16.069,61.344a3.409,3.409,0,0,1-1.744.483ZM32,47.717a3.41,3.41,0,0,1,1.744.482L48.78,57.192,44.894,40.074a3.4,3.4,0,0,1,1.078-3.315L59.156,25.2,41.712,23.612a3.4,3.4,0,0,1-2.827-2.062L32,5.418,25.107,21.552a3.388,3.388,0,0,1-2.818,2.054L4.842,25.19,18.026,36.753A3.4,3.4,0,0,1,19.1,40.071L15.221,57.189,30.256,48.2A3.408,3.408,0,0,1,32,47.717ZM21.427,19.984a.008.008,0,0,1,0,.005Zm21.142-.008,0,.005S42.571,19.979,42.568,19.976Zm0,0" transform="translate(0 -0.492)" fill="#ffe188" />
    </svg>

    // const filterBatch = () => {
    //     let filterData = IISMethods.getCopy(getCurrentState().oldFilterData)
    //     let activeFilter = Object.keys(filterData).map(field => {
    //         if ((['string', 'number'].includes(typeof filterData[field]) && filterData[field]) || (typeof filterData[field] === 'object' && Object.values(filterData[field])?.length > 0)) return 1
    //         return 0
    //     })
    //     return activeFilter.find(status => status === 1)
    // }

    return (
        <>
            <div className="body-content" onScroll={props.handleScroll}>
                <div className="col-12 p-1 my-3">
                    {
                        (getCurrentState().formName === "")
                            ?
                            <>
                                <Placeholder.Graph active></Placeholder.Graph>
                                <Placeholder.Graph active></Placeholder.Graph>
                            </>
                            :
                            ((getCurrentState().formName?.length > 0) && (getCurrentState().masterDataList["pendingdata"]?.length === 0 && getCurrentState().masterDataList["submittedData"]?.length === 0)) ?
                                <div className='no-data-found'>
                                    <div className='noData-content'>
                                        <img src={NoDataImage} />
                                        <h2 className="text-center text-16 mb-0 font-weight-600">No Surveys available at the moment</h2>
                                    </div>
                                </div>
                                :
                                getCurrentState().masterDataList["pendingdata"]?.length > 0 &&
                                <Accordion className='border rounded mb-3' activeKey={activeacc} style={{ backgroundColor: "white" }}>
                                    <Accordion.Item eventKey='1' className='my-2 mx-2'>
                                        <Accordion.Header className='text-18 text-center'
                                            onClick={() => {
                                                let indexofkey = activeacc.findIndex(_ => _ === '1')
                                                if (indexofkey === -1) setActiveacc([...activeacc, '1'])
                                                else setActiveacc(activeacc.filter(_ => _ !== '1'))
                                            }
                                            }>
                                            <span style={{ fontSize: "15px" }}>Pending Surveys</span>
                                            <i className={`justify-content-end material-icons-outlined ${activeacc.includes('1') ? "rotate-180" : ""}`} > expand_more </i>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            {
                                                getCurrentState().masterDataList["pendingdata"]?.map((data, index) => {
                                                    return (
                                                        <div className="request-box" style={{ marginLeft: "3px", marginRight: "3px" }} key={index} onClick={() => { props.setFormData(data.ratingcategoryid, "surveyrating", "ratingmodal", data) }} >
                                                            {/* 111 */}
                                                            <div className="request-create">
                                                                <span className='d-inline-block ml-2 w-100'>
                                                                    <span className='d-block  text-center' style={{ fontSize: "13px", fontWeight: "500" }}>{data.title}</span>
                                                                </span>
                                                            </div>
                                                            <div className='d-block text-center'>
                                                                <div className='d-flex justify-content-between my-2 mx-5'>{/* guestratingsavg */}
                                                                    {
                                                                        [...Array(5)].map((star, index) => {
                                                                            let ratingtype = Number.isInteger(data.star)
                                                                            let filstar
                                                                            let halffillstar
                                                                            if (!ratingtype) {
                                                                                filstar = data.star.toString().split('.')[0]
                                                                                halffillstar = data.star.toString().split('.')[1]
                                                                            }
                                                                            else {
                                                                                filstar = data.star
                                                                            }
                                                                            if (filstar - 1 >= index) {
                                                                                return <div className=' mx-1'>
                                                                                    {star_fill}
                                                                                </div>
                                                                            }
                                                                            else if (halffillstar && parseInt(filstar) === index) {
                                                                                return <div className=' mx-1'>
                                                                                    {star_half_fill}
                                                                                </div>
                                                                            }
                                                                            else {
                                                                                return <div className=' mx-1'>
                                                                                    {star_unfill}
                                                                                </div>
                                                                            }
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }

                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                    }


                    {
                        getCurrentState().masterDataList["submittedData"]?.length > 0 &&
                        <Accordion className='border rounded mb-3' activeKey={activeacc} style={{ backgroundColor: "white" }}>
                            <Accordion.Item eventKey='2' className='my-2 mx-2'>
                                <Accordion.Header className='text-18 text-center'
                                    onClick={() => {
                                        let indexofkey = activeacc.findIndex(_ => _ === '2')
                                        if (indexofkey === -1) setActiveacc([...activeacc, '2'])
                                        else setActiveacc(activeacc.filter(_ => _ !== '2'))
                                    }
                                    }>
                                    <span style={{ fontSize: "15px" }}>Submitted Surveys</span>
                                    <span className={`accordion-icon justify-content-end material-icons-outlined ${activeacc.includes('2') ? "rotate-180" : ""}`} > expand_more </span>

                                </Accordion.Header>
                                <Accordion.Body>
                                    {
                                        getCurrentState().masterDataList["submittedData"]?.map((data, index) => {
                                            return (
                                                <div className="request-box" style={{ marginLeft: "3px", marginRight: "3px" }} key={index} onClick={() => {
                                                    // props.handleGrid(true , "modal" , "submittedratingmodal" ,1 , data)}
                                                    props.setFormData(data.ratingcategoryid, "surveyrating", "submittedratingmodal", data)
                                                }
                                                }>
                                                    {/* 111 */}
                                                    <div className="request-create">
                                                        <span className='d-inline-block ml-2 w-100'>
                                                            <span className='d-block text-center' style={{ fontSize: "13px", fontWeight: "500" }}>{data.title}</span>
                                                        </span>
                                                    </div>
                                                    <div className='d-block'>
                                                        <div className='d-flex justify-content-between my-2 mx-5'>{/* guestratingsavg */}
                                                            {
                                                                [...Array(5)].map((star, index) => {
                                                                    let ratingtype = Number.isInteger(data.star)
                                                                    let filstar
                                                                    let halffillstar
                                                                    if (!ratingtype) {
                                                                        filstar = data.star.toString().split('.')[0]
                                                                        halffillstar = data.star.toString().split('.')[1]
                                                                    }
                                                                    else {
                                                                        filstar = data.star
                                                                    }
                                                                    if (filstar - 1 >= index) {
                                                                        return <div className=' mx-1'>
                                                                            {star_fill}
                                                                        </div>
                                                                    }
                                                                    else if (halffillstar && parseInt(filstar) === index) {
                                                                        return <div className=' mx-1'>
                                                                            {star_half_fill}
                                                                        </div>
                                                                    }
                                                                    else {
                                                                        return <div className=' mx-1'>
                                                                            {star_unfill}
                                                                        </div>
                                                                    }
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    }

                </div>
            </div>

            <ModalRsuite
                size={"xs"}
                open={getCurrentState().modal['ratingmodal']}
                onClose={() => props.handleGrid(false, 'modal', 'ratingmodal', 0)}
                title={""}
                body={
                    <form method='post'>
                        <div className={`col-12 text-center`}>
                            <div className="first-pop-logo">
                                <img src={Rating} />
                            </div>
                        </div>
                        <div className="col-12 text-center">
                            <h1 className="first-popup-title mt-2 mb-4">We Value Your Feedback</h1>
                        </div>
                        {
                            getCurrentState().rightSidebarFormData[0]?.formfields.filter(formfield => {
                                try {
                                    return formfield.defaultvisibility || getCurrentState().formData[formfield.condition.field] === formfield.condition.onvalue
                                }
                                catch {
                                    return false
                                }
                            }).map((formfield) => {
                                if (formfield.type === 'dropdown') {
                                    return (

                                        <>
                                            {
                                                props.load === true ?
                                                <div className="content-center">
                                                    <Loader />
                                                </div>
                                                // <div className="col-12 my-3">
                                                //     <Placeholder.Graph className='rate-box'  style={{ height: 90 }} active/>
                                                // </div>
                                                :
                                                getCurrentState().masterDataList["surveyrating"]?.map((data, dataindex) => {
                                                    let tagdata = data.tagdata || []
                                                    let ratingdataobj = {}
                                                    ratingdataobj[dataindex] = []
                                                    let singlearray = ratingdataobj[dataindex]
                                                    for (const item of tagdata) {
                                                        if (getCurrentState().formData['taskguestrating'] && getCurrentState().formData['taskguestrating'][dataindex]['stars'] !== 0 && getCurrentState().formData['taskguestrating'][dataindex]['stars'] <= item.stars && item.abovebelowflag === 1) {
                                                            let temptagdataarray = singlearray.map(data => data.tagid)
                                                            let tagdata = item.tags.filter(data => !temptagdataarray.includes(data.tagid))
                                                            singlearray.push(...tagdata)
                                                        }
                                                        else if (getCurrentState().formData['taskguestrating'] && getCurrentState().formData['taskguestrating'][dataindex]['stars'] !== 0 && getCurrentState().formData['taskguestrating'][dataindex]['stars'] >= item.stars && item.abovebelowflag === 0) {
                                                            let temptagdataarray = singlearray.map(data => data.tagid)
                                                            let tagdata = item.tags.filter(data => !temptagdataarray.includes(data.tagid))
                                                            singlearray.push(...tagdata)
                                                        }
                                                    }
                                                    return (
                                                        <div className="col-12 my-3" key={dataindex}>
                                                            <div className="rate-box">
                                                                <div className="rate-box-content">
                                                                    <p>
                                                                        {data.rating}
                                                                    </p>
                                                                </div>
                                                                <div className="d-flex justify-content-between mx-5">
                                                                    {
                                                                        // handlestars(formfield.field,dataindex,data._id)
                                                                        [...Array(5)].map((star, index) => {
                                                                            if (getCurrentState().formData['taskguestrating'] && getCurrentState().formData['taskguestrating'][dataindex]['stars'] - 1 >= index) {
                                                                                return <div className='my-3'
                                                                                    onClick={() => { props.handleFormData('add-remove-star', formfield.field, index, '', dataindex, data._id) }}
                                                                                >
                                                                                    {star_fill}
                                                                                </div>
                                                                            } else {
                                                                                return <div className='my-3'
                                                                                    onClick={() => { props.handleFormData('add-remove-star', formfield.field, index, '', dataindex, data._id) }}
                                                                                >
                                                                                    {star_unfill}
                                                                                </div>
                                                                            }
                                                                        })

                                                                    }
                                                                </div>
                                                                {
                                                                    singlearray?.length > 0
                                                                    &&
                                                                    <div className='ratingTagGrid'>
                                                                        {singlearray.map((tagitem, index) => {
                                                                            let tagdata = IISMethods.getindexfromarray(getCurrentState().formData['taskguestrating'][dataindex]['tags'], 'tagid', tagitem.tagid)
                                                                            return (
                                                                                <div className="task-tag-custom-control" key={index}>
                                                                                    <div className="custom-control-tag ">
                                                                                        <label className={`custom-label-rt content-center ${(tagdata !== -1) ? "tagactive" : ''}`}
                                                                                            onClick={() => props.handleFormData('add-remove-star', formfield.field, tagitem.tagid, 'tags', dataindex, tagitem.tag)}
                                                                                            style={{
                                                                                                borderRadius: '20px',
                                                                                                border: `1px solid #9E9E9E`,
                                                                                                // background : 'black'
                                                                                            }} >
                                                                                            {tagitem.tag}
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </>
                                    )
                                }
                                else if (formfield.type === 'input-textarea') // for textarea
                                {
                                    return (
                                        <div className={formfield.gridsize}>
                                            <div className="form-group validate-input mt-0">
                                                <center><label className="first-popup-label mt-3 mb-3">{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}</label></center>
                                                <textarea
                                                    disabled={formfield.disabled} type="text" rows={3}
                                                    defaultValue={getCurrentState().formData[formfield.field]}
                                                    onChange={(e) => props.handleFormData(formfield.type, formfield.field, e.target.value, '', 0, 'suggestion')}
                                                    className="form-control form-control-textarea"
                                                    id={"form-" + formfield.field}
                                                    name={formfield.field} autoComplete="off"
                                                    placeholder={`Enter ${formfield.text}`}
                                                />
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        }
                    </form>
                }
                footer={
                    <>
                        {/* {(getCurrentState().formData.ratingbtnlink && getCurrentState().formData.ratingbtnlink) &&
                                <div className="col-12 mt-1">
                                    <a target='_blank' href={getCurrentState().formData.ratingbtnlink}>
                                        <span className='vehicle-model-btn text-center'>{getCurrentState().formData.ratingbtnname}</span>
                                    </a>
                                </div>
                            } */}
                        <div className="col-12 mt-1">
                            <button type="submit" className="btn-first-popup mb-3"
                                onClick={(e) => {
                                    getCurrentState().loading === 0 && props.handleAddButtonClick(e, 'ratingmodal', getCurrentState().formData.ratingbtnlink)
                                }} >
                                 {/* <Loader size="sm" content={'Submit'} /> */}
                                 Submit
                            </button>
                        </div>
                    </>
                }
            />

            {
                getCurrentState().modal['submittedratingmodal'] &&
                <ModalRsuite
                    size={"xs"}
                    open={getCurrentState().modal['submittedratingmodal']}
                    onClose={() => props.handleGrid(false, 'modal', 'submittedratingmodal', 0)}
                    title={""}
                    body={
                        <form method='post'>
                            <div className={`col-12 text-center`}>
                                <div className="first-pop-logo">
                                    <img src={Rating} />
                                </div>
                            </div>
                            <div className="col-12 text-center">
                                <h1 className="first-popup-title mt-2 mb-4">We Value Your Feedback</h1>
                            </div>
                            {
                                props.load === true ?
                                    // <div className="col-12 my-3">
                                    // <Placeholder.Graph className='rate-box'  style={{ height: 90 }} active/>
                                    // </div>
                                    <div className="content-center">
                                        <Loader />
                                    </div>
                                :
                                props.ratingData["ratingdata"]?.length > 0 &&
                                props.ratingData["ratingdata"]?.map((data) => {
                                    return (
                                        <div className="col-12 my-3">
                                            <div className="rate-box">
                                                <div className="rate-box-content">
                                                    <p>
                                                        {data.rating}
                                                    </p>
                                                </div>
                                                <div className="d-flex justify-content-between mx-5">
                                                    {
                                                        [...Array(5)].map((star, index) => {
                                                            let ratingtype = Number.isInteger(data.stars)
                                                            let filstar
                                                            let halffillstar
                                                            if (!ratingtype) {
                                                                filstar = data?.stars.toString().split('.')[0]
                                                                halffillstar = data?.stars.toString().split('.')[1]
                                                            }
                                                            else {
                                                                filstar = data.stars
                                                            }
                                                            if (filstar - 1 >= index) {
                                                                return <div className='my-3'>
                                                                    {star_fill}
                                                                </div>
                                                            }
                                                            else if (halffillstar && parseInt(filstar) === index) {
                                                                return <div className='my-3'>
                                                                    {star_half_fill}
                                                                </div>
                                                            }
                                                            else {
                                                                return <div className='my-3'>
                                                                    {star_unfill}
                                                                </div>
                                                            }
                                                        })
                                                    }
                                                </div>
                                                {
                                                    data['tags'].length > 0 &&
                                                    <div className='ratingTagGrid'>
                                                        {data['tags'].map((tagitem, index) => {
                                                            // let tagdata = IISMethods.getindexfromarray(getCurrentState().formData['taskguestrating'][dataindex]['tags'], 'tagid', tagitem.tagid)
                                                            return (
                                                                <div className="task-tag-custom-control" key={index}>
                                                                    <div className="custom-control-tag ">
                                                                        <label className={`custom-label-rt content-center tagactive `}
                                                                            // onClick={() => props.handleFormData('add-remove-star', formfield.field, tagitem.tagid, 'tags', dataindex, tagitem.tag)}
                                                                            style={{
                                                                                borderRadius: '20px',
                                                                                border: `1px solid #9E9E9E`,
                                                                                // background : 'black'
                                                                            }} >
                                                                            {tagitem.tag}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                        }
                                                    </div>
                                                }

                                            </div>

                                        </div>
                                    )
                                })
                            }
                            {
                                props.ratingData['ratingsuggestion'] &&
                                <div className={`col-12`}>
                                    <div className="form-group validate-input mt-0 disabled ">
                                        <center><label className="first-popup-label mt-3 mb-3">suggestion</label></center>
                                        <textarea
                                            // readonly
                                            disabled={true}
                                            type="text"
                                            rows={3}
                                            defaultValue={props.ratingData['ratingsuggestion'] || ""}
                                            className="form-control form-control-textarea" style={{ backgroundColor: "white", pointerEvents: "none" }}
                                            id={"form-"}
                                            name={"suggestion"}
                                            autoComplete="off"
                                            placeholder={`Enter Suggestion`}
                                        />
                                    </div>
                                </div>
                            }
                        </form>
                    }
                />
            }

        </>
    )
}

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    setProps: (payload) => dispatch(setProps(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(SurveyGridList)
