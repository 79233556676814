import React from 'react'
import { DatePicker } from 'rsuite'
import { IISMethods } from '../../config/IISMethods'

const DatePickerRsuite = (props) => {
    let calendarDefaultDate = IISMethods.GetDateTimefromISOString(IISMethods.GetTZdatetime().toISOString())

    return (
        <>
            <DatePicker
                className={props.className}
                placeholder={props.placeholder}
                format={props.format}
                onSelect={props.onChange}
                name={props.name}
                value={props.value ? props.value : null}
                ranges={props.ranges}
                cleanable={props.cleanable}
                id={props.id}
                placement="auto"
                type={props.type}
                disabled={props.disabled}
                oneTap
                // onClean={props.onClean}
                onClean={() => props.onChange('')}
                contentEditable={false}
                showMeridian={props.showMeridian}
                disabledDate={props.disabledDate}
                calendarDefaultDate={calendarDefaultDate}
                editable={false}
            />
        </>
    )
}
export default DatePickerRsuite