import React, { useEffect, useState } from 'react'
import Header from '../Header'
import { connect } from 'react-redux'
import Call from '../assets/images/call.png'
import 'rsuite/dist/rsuite.css'
import { setProps } from '../redux/actions'
import { store } from '../index'
import WifiRightSidebar from './WifiRightSidebar'
import BackHeader from '../BackHeader'
import Footer from '../Footer'
import { Placeholder } from 'rsuite'
import { IISMethods } from '../config/IISMethods'

const WifiView = (props) => {
    // srushti 23-2-23
    const getCurrentState = () => {
        return store.getState()['root']
    }
    const pagename = IISMethods.getpagename()
    // srushti 23-2-23
    const reservationDetails = getCurrentState().reservationDetails
    // jatin 2:39 PM 06-07-2023
    let contactno
    if (getCurrentState().masterDataList['wifidetails']) {
        contactno = `${getCurrentState().masterDataList['wifidetails'][0].supportcontactnocountrycode && '(+' + getCurrentState().masterDataList['wifidetails'][0].supportcontactnocountrycode + ')'}-${getCurrentState().masterDataList['wifidetails'][0].supportcontactno}`
    }
    // srushti 23-2-23
    try {
        return (
            <>
                {/* back history not static link JENIL 13/9/2023 10:47 PM */}
                <BackHeader isHistoryBack={true} linkto='/mystay' backtopagename='Wi-Fi' />
                <div className='body-content'>
                    <div className="col-12 my-3">

                        {
                            (getCurrentState().formName === "" && pagename === "wifi")
                                ? <>
                                    {/* <Placeholder.Paragraph style={{ marginTop: 30 }} />     */}
                                    <Placeholder.Graph active></Placeholder.Graph>
                                    <Placeholder.Graph active></Placeholder.Graph>
                                </>
                                : <>
                                    <div className="foundreservation">
                                        <div className="title-foundsec mb-3">
                                            <h1 className="sec-title text-center text-light-blue">Welcome to {reservationDetails?.propertyname}</h1>
                                            <p className="text-center">Here you can find your free wifi password</p>
                                        </div>
                                        <div className='row'>
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-12 col-md-12 mx-auto">
                                                        <div className="wifi-pass-box">
                                                            {/* for wifi networkname and passowrd listing srushti 29-3-23 */}
                                                            <h1 className="wifi-room-title text-center text-black">Room No</h1>
                                                            <div className="room-no-box">
                                                                <h1 className="text-white mt-2">{reservationDetails?.roomno}</h1>
                                                            </div>
                                                            {
                                                                getCurrentState().masterDataList['wifidetails']?.map((detaildata, index) => {
                                                                    if (detaildata.networkname?.length > 0) {
                                                                        return (
                                                                            <div>
                                                                                <div className="text-center mb-1 network" >
                                                                                    {(detaildata.networkname !== '' || detaildata.networkname !== undefined) && <span>Network Name : <b>{detaildata.networkname}</b></span>}<br />
                                                                                </div>
                                                                                <div className="text-center network ">
                                                                                    {<span>Password : <b>{detaildata.password ? detaildata.password : "-"}</b></span>}
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                    else
                                                                        return <></>
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                getCurrentState().masterDataList['wifisetting']?.length > 0
                                                &&
                                                <div className="col-12 mt-4">
                                                    <div className="row">
                                                        <div className="col-12 col-md-12 mx-auto">
                                                            <div className="wifi-device-box">
                                                                <h1 className="sec-title text-center text-blue mb-3">How to connect your device to <span className="d-inline-block">Wi-Fi?</span></h1>
                                                                <div className="col-12 col-md-9 col-lg-10 mx-auto px-0">
                                                                    <div className="wifi-box-scroll">
                                                                        {/* for wifi model open / device-wise different model open srushti 29-3-23 */}
                                                                        {
                                                                            getCurrentState().masterDataList['wifisetting']?.map((detaildata, index) => {
                                                                                return (
                                                                                    <div className="box-space-wifi" onClick={() => props.handleFormData('modal', 'modaldata', detaildata?._id, 'wifiModal')}>
                                                                                        <div className="device-images">
                                                                                            {(detaildata.icon !== '' || detaildata.icon !== undefined) && <img src={detaildata.icon} />}
                                                                                        </div>
                                                                                        <div className="device-name">
                                                                                            {(detaildata.devicetype !== '' || detaildata.devicetype !== undefined) && <p className="text-blue1 text-center">{detaildata?.devicetype}</p>}
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {contactno &&
                                                <div className="col-12 mt-5">
                                                    <div className="row">
                                                        <div className="col-12 col-md-12 mx-auto">
                                                            <h1 className="sec-title text-center text-blue mb-1">Still having trouble ?</h1>
                                                        </div>
                                                        <div className="col-12 col-md-12 mx-auto">
                                                            <p className="text-center text-blue support">Contact Technical Support 24/7</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {contactno &&
                                                <div className="col-12 mt-3">
                                                    <div className="row">
                                                        <div className="col-12 col-md-12 mx-auto">
                                                            <div className="contact-box mt-1 mb-3 mx-auto">
                                                                <div className="call-images d-inline-block">
                                                                    <img src={Call} />
                                                                </div>
                                                                <div className="call-number d-inline-block">
                                                                    <a href={`tel:${contactno}`}>{contactno}</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                    </div>
                                </>
                        }

                    </div>
                </div>

                <Footer />

                <WifiRightSidebar
                    handleFormData={props.handleFormData}
                    handleGrid={props.handleGrid}
                />
            </>
        )
    }
    catch (e) {
        console.log(e)
        return <></>
    }
}


const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    setProps: (payload) => dispatch(setProps(payload))
})


export default connect(mapStateToProps, mapDispatchToProps)(WifiView)
