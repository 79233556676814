import React, { memo, useEffect } from 'react'
import { ToastContainer } from 'react-toastify'
import { Navigate, Routes, Route, useNavigate, useLocation } from 'react-router-dom'
// import PWAPrompt from 'react-ios-pwa-prompt'
import { Config, IISMethods } from './config/IISMethods'
import 'react-toastify/dist/ReactToastify.css' // for applaying style to toaster
import { store } from './index'
import { connect } from 'react-redux'

import Home from './view/Home'
import Wifi from './view/Wifi'
import GuestRequest from './view/GuestRequest'
import Maintenance from './view/Maintenance'
import ReportProblem from './view/ReportProblem'
import Lost from './view/Lost'
import Notification from './view/Notification'
import Announcement from './view/Announcement'
import Amenities from './view/Amenities'
import MyStay from './view/MyStay'
import MyHotel from './view/MyHotel'
import ContactUs from './view/ContactUs'
import NearBy from './view/NearBy'
import TvchannelLineUp from './view/TvchannelLineUp'
import SuccessfullyCheckoutView from './view/SuccessfullyCheckoutView'
import VerificationData from './view/VerificationData'
// import ChatUsView from './view/ChatUsView'
import { register, unregister } from './ServiceWorker/SW'
import ChatUs from './view/ChatUs'
import PWA from './ServiceWorker/PWA'
import WarningModal from './view/Components/WarningModal'
import ModalRSuite from './view/Components/ModalRsuite'
import NextapLogo from './assets/images/favicon.png'
import { setInfo, setLoadings, setProps } from './redux/actions'
import useAPICall from './hooks/useApiCall'
import Promotions from './view/Promotions'
import PropertyLayout from './view/PropertyLayout'
// import ImagesPage from './view/ImagesPage'
import Survey from './view/Survey'
import LinkExpired from './view/LinkExpired'
import BadRequest from './view/BadRequest'
import { useSocket, useSocketListener } from './hooks/useSocket'
import useIsIOS from './hooks/useIsIOS'

const App = (props) => {
    
    const getCurrentState = () => store.getState()['root']
    const getInfoState = () => store.getState()['info']
    const { socketEmit } = useSocket()
    const { handleInstallPWA, promptstatus  } = PWA()
    const { isIOS, isSafari, iosPrompt } = useIsIOS()
    const { getQueryObj } = useAPICall()

    // const loginInfo = IISMethods.getGuestInfo()

    const location = useLocation()
    const history = useNavigate()


    // for loader
    const addLoaders = async (key, value) => {
        if (value === 1) getCurrentState().loadings[key] = value
        else delete getCurrentState().loadings[key]
        await props.setLoadings({ loadings: IISMethods.getCopy(getCurrentState().loadings) })
    }

    // useEffect(() => {
    //     if (getCurrentState().reservationDetails._id) {
    //         socketEmit('setuid', getCurrentState().reservationDetails._id)
    //     }
    // }, [getCurrentState().reservationDetails._id])

    useEffect(() => {
        (async function () {
            await addLoaders('pageload', 1)
            let qparams = getQueryObj()
            if (qparams.bookingid && qparams.subdomainame) {
                getInfoState().reservation['bookingid'] = qparams['bookingid']
                getInfoState().reservation['subdomainname'] = qparams['subdomainame']
                await props.setInfo({ reservation: getInfoState().reservation })
            }
            else {
                //if persist and cookie has no booking data then redirect to 404 JENIL 27/7/2023 12:05 PM
                if (!getInfoState().reservation.bookingid && !IISMethods.getGuestInfo()['bookingid']) {
                    await history({ pathname: '/404' })
                    //otherwise cookie has bookingid then store into persist JENIL 27/7/2023 12:05 PM
                } 
                else if (IISMethods.getGuestInfo()['bookingid']) {
                    getInfoState().reservation['bookingid'] = IISMethods.getGuestInfo()['bookingid']
                    getInfoState().reservation['subdomainname'] = IISMethods.getGuestInfo()['subdomainname']
                    await props.setInfo({ reservation: getInfoState().reservation })
                }
            }
            if (location.search) {
                // BR 23-08-2023 01:28 PM ~
                let sp = new URLSearchParams(location.search)
                let spObj = Object.fromEntries(sp)
                delete spObj.bookingid
                let spstr = new URLSearchParams(spObj)
                await history({ pathname: location.pathname, search: spstr.toString() })
            }
            await register()
            await addLoaders('pageload', 0)
        })()
        return async () => await unregister()
    }, [])

    useEffect(() => {
        (async function () {
            let pathname = window.location.pathname
            if (getCurrentState().reservationDetails?.ischeckin === 1) {
                if (getCurrentState().reservationDetails.reservationratingsubmitted === 0 && (pathname === '/' || pathname === '/home')) {

                }
                else {
                    if (promptstatus || (isIOS && iosPrompt)) await handleGrid(1, 'modal', 'pwainstall', true)
                }
            }
        })()
    }, [isIOS, iosPrompt, isSafari, promptstatus, getCurrentState().reservationDetails?.ischeckin])

    const handleGrid = async (id, type, key, value) => {
        if (type === 'modal') {
            if (value) getCurrentState().modal[key] = value
            else delete getCurrentState().modal[key]
        }
        await props.setProps({ modal: IISMethods.getCopy(getCurrentState().modal) })
    }

    const guestExpNotifyResponse = async (resp) => {
        let menuindex = getCurrentState()?.menuData?.findIndex((a) => a.alias === resp.alias)
        if (menuindex >= 0) {
            let obj = { ...getCurrentState().menuData[menuindex] }
            obj.ishighlight = 1
            getCurrentState().menuData?.splice(menuindex, 1, obj);
            await props.setProps({ menuData: IISMethods.getCopy(getCurrentState().menuData) })
        }
        if (resp.isguestexpnotify === 1) {
            getCurrentState().reservationDetails["notificationcounts"] = getCurrentState().reservationDetails["notificationcounts"] + 1
            await props.setProps({ reservationDetails: IISMethods.getCopy(getCurrentState().reservationDetails) })
        }
    }

    useSocketListener("guestexpnotify", guestExpNotifyResponse)

    return (
        <>
            {/* {(getCurrentState().reservationDetails?.ischeckin === 1) && 
                <PWAPrompt delay={1500} promptOnVisit={1} timesToShow={3} copyClosePrompt="Close" permanentlyHideOnDismiss={false}/>
            } */}
            <div className='container'>
                <div className="inner-container">
                    {/* <BrowserRouter> */}
                    <Routes>
                        {
                            <>
                                <Route exact path='/' element={<Protected><Home /></Protected>} />
                                <Route exact path='/home' element={<Protected><Home /></Protected>} />
                                <Route exact path='/notification' element={<Protected><Notification pagename='notification' /></Protected>} />
                                <Route exact path='/announcement' element={<Protected><Announcement pagename='property/guestexperience/listannouncement' /></Protected>} />
                                <Route exact path='/wifi' element={<Protected><Wifi /></Protected>} />
                                <Route exact path='/requestitem' element={<Protected><GuestRequest pagename='tasklistforweb' /></Protected>} />
                                <Route exact path='/maintenance' element={<Protected><Maintenance pagename='tasklistforweb' /></Protected>} />
                                <Route exact path='/reportproblem' element={<Protected><ReportProblem pagename='complaint' /></Protected>} />
                                <Route exact path='/lost' element={<Protected><Lost pagename='lostandfound' /></Protected>} />
                                <Route exact path='/mystay' element={<Protected><MyStay /></Protected>} />
                                <Route exact path='/myhotel' element={<Protected><MyHotel /></Protected>} />
                                <Route exact path='/survey' element={<Protected><Survey pagename='guestexperience/survey' /></Protected>} />
                                <Route exact path='/amenities' element={<Protected><Amenities /></Protected>} />
                                <Route exact path='/propertylayout' element={<Protected><PropertyLayout /></Protected>} />
                                <Route exact path='/nearby' element={<Protected><NearBy /></Protected>} />
                                <Route exact path="/tvchannellineup" element={<Protected><TvchannelLineUp /></Protected>} />
                                <Route exact path="/contactus" element={<Protected><ContactUs /></Protected>} />
                                <Route exact path="/verificationdata" element={<Protected><VerificationData /></Protected>} />
                                <Route exact path="/chatwithus" element={<Protected><ChatUs /></Protected>} />
                                {/* added chatwith us on all three routes JENIL 03/08/2023 3:45 PM */}
                                <Route exact path="/extendstay" element={<Protected><ChatUs /></Protected>} />
                                <Route exact path="/latecheckout" element={<Protected><ChatUs /></Protected>} />
                                <Route exact path="/checkout" element={<Protected><ChatUs /></Protected>} />
                                {/*  */}
                                <Route exact path='/promotions' element={<Protected><Promotions /></Protected>} />{/* charmi(07-08-2023 03:53) */}
                                <Route exact path="/notcheckedin" element={<WarningModal />} />
                                <Route exact path="/successfullycheckout" element={<Protected><SuccessfullyCheckoutView /></Protected>} />
                                <Route exact path="/linkexpired" element={<Protected><LinkExpired /></Protected>} />
                                <Route exact path="*" element={<Protected><BadRequest /></Protected>} />
                                {/* <Route exact path="/imagespage" element={<Protected><ImagesPage /></Protected>} /> */}
                                {/* <Route exact path="/404" element={<Protected><NoDataFoundView /></Protected>} /> */}
                            </>
                        }
                    </Routes>
                </div>
            </div>

            {/* {(getCurrentState().reservationDetails?.ischeckin === 1 && !getCurrentState().modal?.vehicleratingModal) && */}
                {/* <ModalRSuite
                     size={'xs'}
                    //  open={getCurrentState().modal?.pwainstall}
                     open={true}
                     className={'home-screen-modal'}
                     // onClose={() => setActiveModal(false)}
                     closeButton={false}
                     onClose={() => Config.getServermode() === 'dev' && handleGrid(0, 'modal', 'pwainstall', false)}
                     body={
                         <div className='col-12'>
                             <p className='text-14 mb-3'>Install App ?</p>
                             <div className='d-flex gap-10'>
                                 <div className=''>
                                     <img src={NextapLogo} width={50} />
                                 </div>
                                 <div className=''>
                                </div>
                            </div>
                        </div>
                     }
                /> */}
            {/* } */}


            {/* Bhautik 30-06-2023 11:48 PM ~ PWA Custom Modal  */}
            {(getCurrentState().reservationDetails?.ischeckin === 1 && !getCurrentState().modal?.vehicleratingModal) &&
                <ModalRSuite
                    size={'xs'}
                    open={getCurrentState().modal?.pwainstall}
                    className={'home-screen-modal'}
                    // onClose={() => setActiveModal(false)}
                    closeButton={false}
                    onClose={() => Config.getServermode() === 'dev' && handleGrid(0, 'modal', 'pwainstall', false)}
                    body={
                        <div className='col-12'>
                            <p className='text-14 mb-3'>Install App ?</p>
                            <div className='d-flex gap-10'>
                                <div className=''>
                                    <img src={NextapLogo} width={50} />
                                </div>
                               {isIOS && iosPrompt 
                                    ? <div>
                                        IOS
                                    </div>
                                    : <div className=''>
                                        <p className='text-16 text-dark font-weight-600 mb-0'>Guest Experience</p>
                                        <div><span className='text-14 text-dark'>{(Config.getServermode() === 'dev' || Config.getServermode() === 'uat') ? window.location.host : window.location.host.split('.').slice(1, 3).join('.')}</span></div>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                    footer={
                        <div className='col-12 my-2'>
                            <div className='row justify-content-end'>
                                <div className='col-4'>
                                    <button className='btn-yes-no btn-primary-light' onClick={() => { handleInstallPWA(); handleGrid(0, 'modal', 'pwainstall', false) }}><span className=''>Install</span></button>
                                </div>
                                {/* <div className='col-6 pl-2'>
                                    {Config.getServermode() === 'dev' &&
                                        <button className='btn-yes-no btn-secondary-light' id="btnsidebarcancel" onClick={() => handleGrid(0, 'modal', 'pwainstall', false)}><span className='text-14'>Cancel</span></button>
                                    }
                                </div> */}
                            </div>
                        </div>
                    }
                />
            }

            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={true}
                // closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </>
    )
}

const mapStateToProps = (state) => ({
    ...state,
});

const mapDispatchToProps = dispatch => ({
    setProps: (payload) => dispatch(setProps(payload)),
    setInfo: (payload) => dispatch(setInfo(payload)),
    setLoadings: (payload) => dispatch(setLoadings(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(App));

// proptected router
const Protected = ({ children }) => {
    const { getQueryString } = useAPICall()
    const getCurrentState = () => store.getState()['root']

    
    return getCurrentState().reservationDetails?.ischeckin === 0
        ? <Navigate to={"/notcheckedin"} />
        : children  //&& IISMethods.getCookiesData('mobilecheckin_loginInfo')?.propertyid 
}