import React from 'react'
import { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { IISMethods } from './config/IISMethods'
import { store } from '.'
import TooltipRsuite from './view/Components/TooltipRsuite'

export default function BackHeader(props) {
    const location = useLocation()
    let querystring = IISMethods.getQueryString()
    const pagename = IISMethods.getpagename()

    const [expandSearch, setExpandsearch] = useState("")
    // const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
    // const [previousHeight, setPreviousHeight] = useState(window.innerHeight);

    let options = {}
    const getCurrentState = () => {
        return store.getState()['root']
    }

    try {
        if (props.linkto !== location.pathname) {
            options['onClick'] = () => { }
        }
        else {
            options['onClick'] = props.onClick
        }
    }
    catch {

    }

    const handleExpand = (isFocus) => {
        const search = document.querySelector(".search-input-1");
        search.classList.toggle("search-expanded");
        setExpandsearch(!expandSearch);
        if (isFocus) {
            search.focus()
        }
    };

    // const handleResize = () => {
    //     // debugger
    //     const windowHeight = window.innerHeight;
    //     const heightDifference = Math.abs(previousHeight - windowHeight);
    //     if (heightDifference > 100) {
    //         setIsKeyboardOpen(false);
    //     } else {
    //         setIsKeyboardOpen(true);
    //     }

    //     setPreviousHeight(windowHeight);
    // };
    // useEffect(() => {
    //     window.addEventListener('resize', handleResize);
    //     return () => {
    //         window.removeEventListener('resize', handleResize);
    //     };
    // }, [previousHeight]);


    // bhautik 06-04-2023 07:25 PM ~

    const navigate = useNavigate()

    //back history from back button function JENIL 13/9/2023 9:53 PM
    const handleBack = (e) => {
        e.preventDefault()
        navigate(-1)
    }

    //common header function JENIL 13/9/2023 10:16 PM
    const showHeader = () => {
        return (
            <div className='header'>
                <div className='col-12'>
                    <div className='row'>
                        <div className='col-auto'>
                            <div className='back-header'>
                                <span className='redirection-icon'>
                                    <i className='material-icons-round'>arrow_back_ios</i>
                                </span>
                            </div>
                        </div>
                        <div className='col-8 pl-0'>
                            <div className='back-header'>
                                <span className='redirection-label'>
                                    {props.backtopagename ? props.backtopagename : 'Back'}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            {
                //when isHistoryBack navigate -1 otherwise props.linkto redirect JENIL 13/9/2023 10:33 AM
                props?.isHistoryBack === true ?
                    <span {...options} onClick={(e) => { handleBack(e) }}>
                        {showHeader()}
                    </span>
                    :
                    <Link to={`${props.linkto !== location.pathname ? props.linkto : location.pathname}`} {...options}>
                        {/* <a {...options} onClick={() => { handleBack() }}> */}
                        {showHeader()}
                    </Link>
            }
            {
                (!["latecheckout", "wifi", "checkout", "extendstay", "chatwithus", "survey"].includes(pagename) && !props.nearbydetail) &&
                <div className="row">
                    <div className="col-12 search-input-top">
                        <TooltipRsuite
                            placement={'top'}
                            tooltip={'Search'}
                            content={
                                <a className="search-wrapper search-wrapper-header">
                                    <span className={`material-icons-outlined ${expandSearch ? 'd-none' : 'd-inline-flex'}`}
                                        onClick={() => handleExpand(true)}
                                    >
                                        search
                                    </span>
                                    <span className={`material-icons-outlined ${expandSearch ? 'd-inline-flex' : 'd-none'}`}

                                        onClick={() => {
                                            if (expandSearch === true) {
                                                props.handleSerachClose()
                                                handleExpand()
                                            }
                                        }}
                                    >
                                        close
                                    </span>
                                </a>
                            } />
                        <div className='search-input-inner'>
                            <input
                                className="search-input-1 form-control"
                                type="text"
                                id={`customize_search`}
                                name={`search`}
                                autoComplete="off"
                                disabled={false}
                                value={getCurrentState().filterData["searchtext"] ? getCurrentState().filterData["searchtext"] : ""}
                                onChange={(e) => { props.handleFilterData('text', 'searchtext', e.target.value); !e.target.value && props.handleApplyFilterButtonClick(e.target.value) }}
                                placeholder="Search"
                                onKeyUp={(e) => {
                                    e.preventDefault()
                                    if (e.key === 'Enter') {
                                        props.handleApplyFilterButtonClick(e)
                                    }
                                }}
                            />
                            <label className="input-group-text search-btn" onClick={(e) => (getCurrentState().filterData['searchtext'] && getCurrentState().filterData['searchtext'] !== '') && props.handleApplyFilterButtonClick(e)}>
                                <i className="material-icons-round">search</i>
                            </label>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
