// import { useEffect } from "react";
import { Config, IISMethods, resBody, setReqParams } from '../config/IISMethods'
import { useLocation, useNavigate } from 'react-router-dom'
import { store } from '..'
import { setLoadings, setProps } from '../redux/actions'
import { useDispatch } from "react-redux";
import { useSocket } from './useSocket';

const useAPICall = (props) => {

    const dispatch = useDispatch()
    const getCurrentState = () => store.getState()['root']
    const getInfoState = () => store.getState()['info']

    const location = useLocation()
    const history = useNavigate()
    const { socketEmit } = useSocket()

    // for loader
    const addLoaders = async (key, value) => {
        if (value === 1) getCurrentState().loadings[key] = value
        else delete getCurrentState().loadings[key]
        await dispatch(setLoadings({ loadings: IISMethods.getCopy(getCurrentState().loadings) }))
    }

    const accessStatus = async () => {
        return (getInfoState().reservation?.bookingid === IISMethods.getGuestInfo()?.bookingid)
    }

    const getAccessToken = async () => {
        const url = Config.weburl + 'getaccesstoken'
        const reqData = {}
        let headers = {
            key: "Kiosist_98F2B6E0-240A-457B-853A-A0079862315F",
            issuer: "website",
            'user-agent': window.navigator.userAgent
        }
        if (Config.encryptdata) { headers = { reqheader: IISMethods.encryptData(headers) } }

        await IISMethods.axiosrequest("POST", url, reqData, headers, successCallback, errorCallback)
        var resp
        // function successCallback(response) {
        //     resp = response
        // }
        // function errorCallback(error) {
        //     resp = error
        // }
        function successCallback(response) {
            if (Config.encryptdata) {
                resp = response || {}
                resp.data = JSON.parse(JSON.parse(IISMethods.decryptData(response.data)))
                resp.headers = JSON.parse(JSON.parse(IISMethods.decryptData(response.headers.resheader)))
            }
            else { resp = response }
        }

        function errorCallback(error) {
            if (Config.encryptdata) {
                resp = error
                resp.data = JSON.parse(JSON.parse(IISMethods.decryptData(error.response.data)))
                resp.headers = JSON.parse(JSON.parse(IISMethods.decryptData(error.response.headers)))
            }
            else { resp = error }
        }

        if (resp.status === 200) {
            if (getInfoState().reservation.subdomainname && getInfoState().reservation.bookingid) {
                let obj = {}
                obj.subdomainname = getInfoState().reservation.subdomainname
                obj.bookingid = getInfoState().reservation.bookingid
                obj.querystring = getQueryString()
                obj.token = resp.headers.token
                obj.unqkey = resp.data.data.unqkey
                obj.uid = resp.data.data.uid
                obj.step = 0
                //if booking id is match and cookies has already fbtoken then default set because 
                // if cookie has fbtoken then again add device not called JENIL 21/8/2023 11:30 AM
                let cookiedata = IISMethods.getGuestInfo()
                if (cookiedata.bookingid === getInfoState().reservation.bookingid && cookiedata.fbtoken) {
                    obj['fbtoken'] = cookiedata.fbtoken
                }
                IISMethods.setCookiesData(Config.cookiestorage['loginInfo'], IISMethods.getCopy(obj))
            }
        }
        else {
            localStorage.clear()
        }
    }

    // Bhautik 06-07-2023 11:09 AM ~ for get quary obj
    const getQueryObj = () => {
        // let host = window.location.host
        let serchparams = new URLSearchParams(location.search)
        let bookingid = ''
        let subdomainame = ''

        if (Config.getServermode() === 'dev' || Config.getServermode() === 'uat') {
            if (serchparams.get('bookingid')) {
                let bid = serchparams.get('bookingid')
                bookingid = bid.split('-')[1] || IISMethods.getGuestInfo()?.bookingid
                subdomainame = bid.split('-')[0] || IISMethods.getGuestInfo()?.subdomainname
            }
        }
        else {
            // BR ~ 08-09-2023 05:33 PM ~
            let host = window.location.host.split('.')
            if (host.length === 3) {
                let reservationId = host[0].split('.')[0]
                subdomainame = reservationId.split('-')[0]
                bookingid = reservationId.split('-')[1]
            }
            else {
                history({ pathname: '/404' })
            }
        }

        return { bookingid, subdomainame }
    }

    // Bhautik 06-07-2023 11:09 AM ~ for get quary string
    const getQueryString = () => {
        let bookingid = getQueryObj()['bookingid']
        let subdomainname = getQueryObj()['subdomainame']
        return `?bookingid=${subdomainname}-${bookingid}`
    }

    const getReservationDetails = async () => {
        await addLoaders('pageload', 1)
        const reqData = {
            paginationinfo: {
                pageno: 1,
                pagelimit: 20,
                filter: {
                    _id: getInfoState().reservation?.bookingid || ''
                },
                sort: {}
            }
        }
        if (getInfoState().reservation?.bookingid) {
            const url = Config.weburl + "guestexpreservation"//reservation guestexpreservation
            await setReqParams(url, reqData)
            await IISMethods.addData("reservation", "home")
        }

        if (resBody.status === 200) {
            if (!resBody.data.length) {
                await history({ pathname: '/404' })
            }
            else if (resBody.data[0]?.ischeckin === 0) {
                await history({ pathname: '/notcheckedin' })
            }
            else {
                let resultData = resBody.data[0]
                // if (resultData.ischeckin === 1) {
                //     resultData.subscribe = 1
                //     socketEmit('setuid', resultData._id)
                //     socketEmit('subscribe', getInfoState().reservation.subdomainname + resultData._id)
                // }
                await dispatch(setProps({ reservationDetails: resultData }))
                if (resultData?.isguestcheckout === 1) {
                    if (!['/', '/home', '/wifi', '/chatwithus', '/reportproblem', '/contactus', '/nearby', '/mystay', '/myhotel', '/propertylayout', '/amenities', '/tvchannellineup', '/lost', '/promotions', '/survey', '/announcement', '/notification'].includes(window.location.pathname)) {
                        history({ pathname: `/successfullycheckout` })
                    }
                }
                if (resultData?.isreservationexpired === 1) {
                    history({ pathname: `/linkexpired` })
                }
            }
            await addLoaders('pageload', 0)
        }
        else {
            localStorage.clear()
            await addLoaders('pageload', 0)
        }
    }

    const getMenus = async (menuchange) => {
        let status = await accessStatus()
        if (menuchange || (getCurrentState().menuData.length === 0 && getCurrentState().reservationDetails?.ischeckin === 1 && status)) {
            const url = Config.weburl + 'listguestexperiencemenu'
            const useraction = 'viewright'
            const reqBody = {
                propertyid: getCurrentState().reservationDetails.propertyid,
                roomnoid: getCurrentState().reservationDetails.roomnoid,
                reservationid: getCurrentState().reservationDetails?._id,
            }
            await setReqParams(url, reqBody)
            await IISMethods.listData(useraction)

            if (resBody.status === 200) {
                await dispatch(setProps({ menuData: resBody.userrights, formName: resBody.formname }))
            }
        }
    }

    const getPropertyData = async () => {
        // console.log('getCurrentState().reservationDetails.ischeckin ', getCurrentState().reservationDetails.ischeckin )
        if (getCurrentState().reservationDetails.ischeckin === 1) {
            const url = Config.weburl + 'property'
            const useraction = 'listproperty'
            const reqBody = {
                paginationinfo: {
                    pageno: 1,
                    filter: {
                        _id: getCurrentState().reservationDetails.propertyid
                    },
                    sort: {}
                }
            }
            await setReqParams(url, reqBody)
            await IISMethods.listData(useraction)
            if (resBody.status === 200) {
                let cookiedata = IISMethods.getGuestInfo()
                cookiedata.companyid = resBody.data[0].companyid
                IISMethods.setCookiesData(Config.cookiestorage['loginInfo'], cookiedata)
                await dispatch(setProps({ propertyData: resBody.data[0] }))
            }
        }
    }

    return { getReservationDetails, getQueryObj, getQueryString, getAccessToken, getMenus, getPropertyData }
}

// const mapStateToProps = () => ({
//     // ...state
// })

// const mapDispatchToProps = dispatch => ({
//     // clearData: (payload) => dispatch(clearData(payload)),
//     // setProps: (payload) => dispatch(setProps(payload)),
//     // setLoading: (payload) => dispatch(setLoading(payload)),
//     setLoadings: (payload) => dispatch(setLoadings(payload)),
// })

// export default connect(mapStateToProps, mapDispatchToProps)(useAPICall)

export default useAPICall;






