import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { store } from '..'
import { Config, IISMethods, JsCall, pagelimit, resBody, setReqParams } from '../config/IISMethods'
import { clearData, setLoading, setProps } from '../redux/actions'
import NotificationView from '../view/NotificationView'
import { useNavigate, useLocation } from 'react-router-dom'
import useFirebase from '../ServiceWorker/useFirebase'
import PWA from '../ServiceWorker/PWA'
import useAPICall from '../hooks/useApiCall'



// srushti
const NotificationController = (props) => {
    const { getReservationDetails, getAccessToken, getPropertyData } = useAPICall()

    // for notification request
    const { requestPermission } = useFirebase()
    const { handleInstallPWA } = PWA()

    const getCurrentState = () => {
        return store.getState()['root']
    }
    // const history = useNavigate()
    // let subdomainname = IISMethods.getBookingIDSubDomain()['subdomainname']
    // let bookingid = IISMethods.getBookingIDSubDomain()['bookingid']
    // let querystring = IISMethods.getQueryString()

    //first call - default props set & grid call - getlist
    useEffect(() => {
        async function fetchMastersJson() {

            props.setProps({
                fieldOrder: [],
                newFieldOrder: [],
                nextpage: 0,
                pageNo: 1,
                pagelimit: 20,
                pageName: '',
                formName: '',
                sortData: {},
                formData: {},
                filterData: {},
                oldFilterData: {},
                modal: {},
                // rightSidebarFormData : [],
                masterData: {},
                masterDataList: {},
                notificationCount: 0,
            })
            //remove notification count when notification open
            IISMethods.setLocalData("notificationCount", 0)
            //(DO NOT CHANGE WITHOUT PERMISSION)charmi(06-09-2023 12:35)(listing api calling issue fix & install application modal open issue fix)
            await props.clearData()
            await getAccessToken()
            await getReservationDetails()
            if (getCurrentState().reservationDetails.ischeckin === 1) {
                await getPropertyData()
                if (getCurrentState().reservationDetails['isreservationexpired'] !== 1) {
                    await requestPermission()
                }
                await getList()
            }
            //(DO NOT CHANGE WITHOUT PERMISSION)charmi(06-09-2023 12:35)(listing api calling issue fix & install application modal open issue fix)
            // if (getCurrentState().reservationDetails.ischeckin === 1) { await handleInstallPWA() }
        }
        fetchMastersJson()
    }, [])

    // const getAccessToken = async () => {

    //     const url = Config.weburl + 'getaccesstoken'
    //     const reqData = {}
    //     const headers = {
    //         key: "Kiosist_98F2B6E0-240A-457B-853A-A0079862315F",
    //         issuer: "website",
    //         // key: process.env.REACT_APP_KEY,
    //         // issuer: process.env.REACT_APP_ISSUER
    //     }

    //     await IISMethods.axiosrequest("POST", url, reqData, headers, successCallback, errorCallback);

    //     var resp

    //     function successCallback(response) {
    //         resp = response
    //     }
    //     function errorCallback(error) {
    //         resp = error
    //     }

    //     if (resp.status === 200) {
    //         let obj = Object.assign({}, {})
    //         if (subdomainname && bookingid) {
    //             obj.subdomainname = subdomainname
    //             obj.bookingid = bookingid
    //             obj.querystring = querystring
    //             obj.token = resp.headers.token
    //             obj.unqkey = resp.data.data.unqkey
    //             obj.uid = resp.data.data.uid
    //             obj.step = 0
    //             IISMethods.setCookiesData(Config.cookiestorage['loginInfo'], obj)
    //             await getReservationDetails()
    //             if (getCurrentState().reservationDetails.ischeckin === 1) {
    //                 await getPropertyData()
    //                 await requestPermission()
    //             }
    //             // await getMenus()
    //         }
    //     }
    //     else {
    //         localStorage.clear()
    //         // cookies.remove(Config.cookiestorage['loginInfo']);
    //     }
    // }

    // const getPropertyData = async () => {
    //     const url = Config.weburl + 'property'
    //     const useraction = 'listproperty'
    //     const reqBody = {

    //         paginationinfo: {
    //             pageno: 1,
    //             filter: {
    //                 _id: getCurrentState().reservationDetails.propertyid
    //             },
    //             sort: {}
    //         }
    //     }

    //     await setReqParams(url, reqBody)
    //     await IISMethods.listData(useraction)

    //     if (resBody.status === 200) {
    //         let cookiedata = IISMethods.getCookiesData(Config.cookiestorage['loginInfo'])
    //         cookiedata.companyid = resBody.data[0].companyid
    //         IISMethods.setCookiesData(Config.cookiestorage['loginInfo'], cookiedata)
    //         await props.setProps({ propertyData: resBody.data[0] })
    //     }
    // }

    // const getReservationDetails = async () => {
    //     const reqData = {
    //         paginationinfo: {
    //             pageno: 1,
    //             pagelimit: 20,
    //             filter: {
    //                 _id: bookingid
    //             },
    //             sort: {}
    //         }
    //     }
    //     if (bookingid !== null) {
    //         const url = Config.weburl + "guestexpreservation"//reservation guestexpreservation
    //         await setReqParams(url, reqData)
    //         await IISMethods.addData("reservation", "home")
    //     }

    //     if (resBody.status === 200) {
    //         await props.setProps({ reservationDetails: resBody.data[0] })
    //         if (resBody.data[0].ischeckin === 0) {
    //             history(`/notcheckedin${querystring}`)
    //         }
    //         if (getCurrentState().reservationDetails.isreservationexpired === 1) {
    //             history({ pathname: `/linkexpired`, querystring })
    //         }
    //     } else {
    //         localStorage.clear()
    //     }
    // }



    //for grid scroll event - pagination
    // const handleScroll = async (e) => {
    //     debugger
    //     let element = e.target
    //     console.log('element', element)
    //     if (element.scrollHeight - element.scrollTop === element.clientHeight || ((element.scrollHeight - element.scrollTop) - 1 === element.clientHeight)) {
    //         if (getCurrentState().nextpage && (getCurrentState().pageNo * getCurrentState().pagelimit === getCurrentState().data.length)) {
    //             await props.setProps({ pageNo: getCurrentState().pageNo + 1 })
    //             getList()
    //         }
    //     }
    // }
    const handleScroll = async (e) => {
        let element = e.target
        let data = getCurrentState().data.map((data)=>data.data).flat()
        if (element.scrollHeight - element.scrollTop === element.clientHeight || ((element.scrollHeight - element.scrollTop) - 1 === element.clientHeight)) {
            if (getCurrentState().nextpage && (getCurrentState().pageNo * getCurrentState().pagelimit === data.length)) {
                await props.setProps({ pageNo: getCurrentState().pageNo + 1 })
                getList()
            }
        }
    }

    // List Data
    const getList = async () => {
        const url = Config.weburl + props.pagename
        const useraction = 'viewright'
        let filter = {}

        Object.entries(getCurrentState().filterData).forEach(([key, value]) => {
            if (typeof (value) === 'number') {
                if (value !== 0)
                    filter[key] = value
            }
            else
                filter[key] = value
        })

        if (!getCurrentState().data.length) {
            await props.setProps({ pageNo: 1 })
        }

        let searchtext = filter.searchtext || ''
        delete filter.searchtext
        const reqBody = {
            searchtext: searchtext,
            reservationid: getCurrentState().reservationDetails._id,
            paginationinfo: {
                pageno: getCurrentState().pageNo,
                pagelimit:getCurrentState().pagelimit,
                filter: filter,
                sort: getCurrentState().sortData
            }
        }

        await setReqParams(url, reqBody)
        await IISMethods.listData(useraction)

        if (resBody.status === 200) {
            if (getCurrentState().pageNo === 1) {
                await props.clearData()
            }
            await props.setProps({
                data: resBody.data,
                pageNo: resBody.pageno,
                pagelimit: resBody.pagelimit,
                nextpage: resBody.nextpage,
                formName: resBody.formname,
                masterDataList: getCurrentState().masterDataList,
                masterData: getCurrentState().masterData,
            })
        }
        else {
        }
    }

     // jatin 15-08-2023 for  Search
     const handleFilterData = async (type, key, value) => {
        getCurrentState().filterData[key] = value
        await props.setProps({ filterData: IISMethods.getCopy(getCurrentState().filterData), pageNo: 1 })       
        // await getList()
    }
    // jatin 15-08-2023 for Search
    const handleApplyFilterButtonClick = async (e, type) => {
        if(e) e.preventDefault()
         if (type === 'removevalue') {
             getCurrentState().filterData['searchtext'] = ''
             await props.setProps({ pageNo: 1, oldFilterData: IISMethods.getCopy(getCurrentState().filterData) })
             await props.clearData()
             await getList()
         }
         else {
             await props.setProps({ pageNo: 1, oldFilterData: IISMethods.getCopy(getCurrentState().filterData) })
             await props.clearData()
             await getList()
         }
     }
      // jatin 15-08-2023 11:53 PM
    const handleSerachClose = async()=>{
        getCurrentState().filterData["searchtext"] = " "
        await props.setProps({ filterData: IISMethods.getCopy(getCurrentState().filterData), pageNo: 1 })     
        await getList()

     }

    return (
        <>
            <NotificationView
                handleScroll={handleScroll}
                handleFilterData={handleFilterData}
                handleApplyFilterButtonClick={handleApplyFilterButtonClick}
                handleSerachClose={handleSerachClose}
            />
        </>
    )
}

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    clearData: (payload) => dispatch(clearData(payload)),
    setProps: (payload) => dispatch(setProps(payload)),
    setLoading: (payload) => dispatch(setLoading(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NotificationController)

