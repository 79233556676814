import React, { useState } from 'react'
import { store } from '../index'
import { Config, IISMethods, JsCall } from '../config/IISMethods'
import ModalRsuite from './Components/ModalRsuite'
import Rating from '../assets/images/rating.svg'
import Loader from './Components/Loader'

export default function HousekeepingRating(props) {
    const getCurrentState = () => {
        return store.getState()['root']
    }

    const form = 'form-'
    const star_fill = <svg className='star star-curser' xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 64 61.335">
        <path id="star_13_" data-name="star (13)" d="M63.833,23.669A3.4,3.4,0,0,0,60.9,21.33L42.433,19.653l-7.3-17.1a3.4,3.4,0,0,0-6.256,0l-7.3,17.094L3.093,21.33a3.406,3.406,0,0,0-1.931,5.953L15.125,39.528,11.008,57.666a3.4,3.4,0,0,0,5.059,3.675L32,51.817l15.928,9.524a3.4,3.4,0,0,0,5.062-3.675L48.873,39.528,62.836,27.285a3.4,3.4,0,0,0,1-3.616Zm0,0" transform="translate(0 -0.491)" fill="#ffc107" />
    </svg>
    const star_unfill = <svg className='star star-curser' xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 64 61.335">
        <path id="star_14_" data-name="star (14)" d="M14.325,61.827a3.407,3.407,0,0,1-3.315-4.16l4.117-18.137L1.165,27.284a3.4,3.4,0,0,1,1.928-5.953l18.475-1.677,7.3-17.094a3.4,3.4,0,0,1,6.256,0l7.3,17.1L60.9,21.331a3.405,3.405,0,0,1,1.934,5.953L48.875,39.527l4.117,18.137a3.4,3.4,0,0,1-5.061,3.675L32,51.818,16.069,61.344a3.409,3.409,0,0,1-1.744.483ZM32,47.717a3.41,3.41,0,0,1,1.744.482L48.78,57.192,44.894,40.074a3.4,3.4,0,0,1,1.078-3.315L59.156,25.2,41.712,23.612a3.4,3.4,0,0,1-2.827-2.062L32,5.418,25.107,21.552a3.388,3.388,0,0,1-2.818,2.054L4.842,25.19,18.026,36.753A3.4,3.4,0,0,1,19.1,40.071L15.221,57.189,30.256,48.2A3.408,3.408,0,0,1,32,47.717ZM21.427,19.984a.008.008,0,0,1,0,.005Zm21.142-.008,0,.005S42.571,19.979,42.568,19.976Zm0,0" transform="translate(0 -0.492)" fill="#ffe188" />
    </svg>

    try {
        return (
            <>

                <ModalRsuite
                    size={"xs"}
                    open={getCurrentState().modal['housekeepingratingModal']}
                    onClose={() => props.handleGrid(false, 'modal', 'housekeepingratingModal', 0)}
                    title={""}
                    body={
                        <form method='post'>
                            <div className={`col-12 text-center`}>
                                <div className="first-pop-logo">
                                    <img src={Rating} />
                                </div>
                            </div>
                            <div className="col-12 text-center">
                                <h1 className="first-popup-title mt-2 mb-4">We Value Your Feedback</h1>
                            </div>
                            {
                                getCurrentState().rightSidebarFormData[0]?.formfields.filter(formfield => {
                                    try {
                                        return formfield.defaultvisibility || getCurrentState().formData[formfield.condition.field] === formfield.condition.onvalue
                                    }
                                    catch {
                                        return false
                                    }
                                }).map((formfield, fieldindex) => {
                                    if (formfield.type === 'dropdown') {
                                        return (
                                            <>

                                                {/* charmi(09-06-2023)(rating tag print change) */}
                                                {getCurrentState().masterDataList[formfield.masterdata]?.map((data, dataindex) => {
                                                    let tagdata = data.tagdata || []
                                                    let ratingdataobj = {}
                                                    ratingdataobj[dataindex] = []
                                                    let singlearray = ratingdataobj[dataindex]
                                                    for (const item of tagdata) {
                                                        if (getCurrentState().formData['taskguestrating'] && getCurrentState().formData['taskguestrating'][dataindex]['stars'] !== 0 && getCurrentState().formData['taskguestrating'][dataindex]['stars'] <= item.stars && item.abovebelowflag === 1) {
                                                            let temptagdataarray = singlearray.map(data => data.tagid)
                                                            let tagdata = item.tags.filter(data => !temptagdataarray.includes(data.tagid))
                                                            singlearray.push(...tagdata)
                                                        }
                                                        else if (getCurrentState().formData['taskguestrating'] && getCurrentState().formData['taskguestrating'][dataindex]['stars'] !== 0 && getCurrentState().formData['taskguestrating'][dataindex]['stars'] >= item.stars && item.abovebelowflag === 0) {
                                                            let temptagdataarray = singlearray.map(data => data.tagid)
                                                            let tagdata = item.tags.filter(data => !temptagdataarray.includes(data.tagid))
                                                            singlearray.push(...tagdata)
                                                        }
                                                    }
                                                    return (
                                                        <div className="col-12 mt-3">
                                                            <div className="rate-box">
                                                                <div className="rate-box-content">
                                                                    <p>
                                                                        {data.rating}
                                                                    </p>
                                                                </div>
                                                                <div className="d-flex justify-content-between mx-5">
                                                                    {
                                                                        // handlestars(formfield.field,dataindex,data._id)
                                                                        [...Array(5)].map((star, index) => {
                                                                            if (getCurrentState().formData['taskguestrating'] && getCurrentState().formData['taskguestrating'][dataindex]['stars'] - 1 >= index) {
                                                                                return <div className='my-3'
                                                                                    onClick={() => { props.handleFormData('add-remove-star', formfield.field, index, '', dataindex, data._id, '', formfield.masterdata) }}
                                                                                >
                                                                                    {star_fill}
                                                                                </div>
                                                                            } else {
                                                                                return <div className='my-3'
                                                                                    onClick={() => { props.handleFormData('add-remove-star', formfield.field, index, '', dataindex, data._id, '', formfield.masterdata) }}
                                                                                >
                                                                                    {star_unfill}
                                                                                </div>
                                                                            }
                                                                        })

                                                                    }
                                                                </div>
                                                                {
                                                                    singlearray?.length > 0
                                                                    &&
                                                                    <div className='ratingTagGrid'>
                                                                        {
                                                                            singlearray.map((tagitem, index) => {
                                                                                let tagdata = IISMethods.getindexfromarray(getCurrentState().formData['taskguestrating'][dataindex]['tags'], 'tagid', tagitem.tagid)
                                                                                return (
                                                                                    <div className="task-tag-custom-control" key={index}>
                                                                                        <div className="custom-control-tag ">

                                                                                            <label className={`custom-label-rt content-center ${(tagdata !== -1) ? "tagactive" : ''}`}
                                                                                                onClick={() => props.handleFormData('add-remove-star', formfield.field, tagitem.tagid, 'tags', dataindex, tagitem.tag)}
                                                                                                style={{
                                                                                                    borderRadius: '20px',
                                                                                                    border: `1px solid #9E9E9E`,
                                                                                                    // background : 'black'
                                                                                                }} >
                                                                                                {tagitem.tag}
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                }
                                            </>
                                        )
                                    }
                                    else if (formfield.type === 'input-textarea') // for textarea
                                    {
                                        return (
                                            <div className={formfield.gridsize} key={`${fieldindex}`}>
                                                <div className="form-group validate-input mt-0">
                                                    <center><label className="first-popup-label mt-3 mb-3">{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}</label></center>
                                                    <textarea
                                                        disabled={formfield.disabled} type="text" rows={3}
                                                        defaultValue={getCurrentState().formData[formfield.field]}
                                                        onChange={(e) => props.handleFormData(formfield.type, formfield.field, e.target.value, '', 0, 'suggestion')}
                                                        className="form-control form-control-textarea"
                                                        id={form + formfield.field}
                                                        name={formfield.field} autoComplete="off"
                                                        placeholder={`Enter ${formfield.text}`}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            }

                        </form>
                    }
                    footer={
                        <>
                            {/* {(getCurrentState().formData.ratingbtnlink && getCurrentState().formData.ratingbtnlink) &&
                                <div className="col-12 mt-1">
                                    <a target='_blank' href={getCurrentState().formData.ratingbtnlink}>
                                        <span className='vehicle-model-btn text-center'>{getCurrentState().formData.ratingbtnname}</span>
                                    </a>
                                </div>
                            } */}
                            <div className="col-12 mt-1">
                                <button type="submit" className="btn-first-popup mb-3"
                                    onClick={(e) => {
                                        getCurrentState().loading === 0 && props.handleAddButtonClick(e, 'housekeepingratingModal', '', getCurrentState().formData?.ratingbtnlink)
                                    }} >
                                    <Loader content={'Submit'} />
                                </button>
                            </div>
                        </>
                    }
                />

            </>

        )
    } catch (error) {
        console.log(error)
        return <></>
    }

}