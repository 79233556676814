import React, { useEffect } from 'react'
import BackHeader from '../BackHeader'
import RequestBad from '../assets/images/bad-request.png'
import useFirebase from '../ServiceWorker/useFirebase'
import { useNavigate } from 'react-router-dom'
import { Config, IISMethods } from '../config/IISMethods'

const BadRequest = (props) => {
    const { removeToken } = useFirebase()
    const history = useNavigate()

    useEffect(() => {
        (async function () {
            await removeToken()
        })()
    }, [])

    return (
        <>
            <div className="row body-fix-height checkout-content">
                <div className="col-11 col-md-9 col-lg-10 mx-auto">
                    <div className='completed-content'>
                        {/* <div className="container-scheckout mt-0 mb-3"> */}
                            {/* <div className="d-flex justify-content-center align-items-center"> */}
                                <img src={RequestBad} className='scheckout-img' />
                            {/* </div> */}
                        {/* </div> */}
                        <div className="col-9 mx-auto mt-5">
                            {/* <div className="successfulcheckin-desc text-center text-blue fw-500"> */}
                                {
                                    (IISMethods.getCookiesData(Config.cookiestorage['loginInfo']) && IISMethods.getCookiesData(Config.cookiestorage['loginInfo']).bookingid !== (undefined || ''))
                                    &&
                                    <>
                                        <a onClick={() => history({ pathname: `/home` })} className="btn btn-primary-dark btn-sm success-back-btn">
                                            <span class="material-icons-outlined text-20">keyboard_backspace</span>
                                            <span>Back To Home</span>
                                        </a>
                                    </>
                                }
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BadRequest