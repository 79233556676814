import React, { useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { store } from '..'
import { Config, IISMethods, JsCall, pagelimit, resBody, setReqParams } from '../config/IISMethods'
import { clearData, setLoading, setProps } from '../redux/actions'
import LostView from '../view/LostView'
import { useNavigate, useLocation } from 'react-router-dom'
import useFirebase from '../ServiceWorker/useFirebase'
import useAPICall from '../hooks/useApiCall'


let initialstate = 'listlost'

// srushti
const LostController = (props) => {

    const { getReservationDetails, getAccessToken, getPropertyData } = useAPICall()

    // for notification request
    const { requestPermission } = useFirebase()
    const location = useLocation()
    const getCurrentState = () => {
        return store.getState()['root']
    }
    // const history = useNavigate();
    const [page, setPage] = useState(initialstate)
    const [lostloader, setLostLoader] = useState(false)
    // let subdomainname = IISMethods.getBookingIDSubDomain()['subdomainname']
    // let bookingid = IISMethods.getBookingIDSubDomain()['bookingid']
    // let querystring = IISMethods.getQueryString()

    //first call - default props set & grid call - getlist
    // useEffect(() => {
    //     async function fetchMastersJson() {
    //         props.setProps({
    //             fieldOrder: [],
    //             newFieldOrder: [],
    //             nextpage: 0,
    //             pageNo: 1,
    //             pagelimit: 5,
    //             pageName: '',
    //             formName: '',
    //             sortData: {},
    //             formData: {},
    //             filterData: {},
    //             oldFilterData: {},
    //             modal: {},
    //             // rightSidebarFormData : [],
    //             masterData: {},
    //             masterDataList: {}
    //         })

    //         let guestInfo = IISMethods.getCookiesData(Config.cookiestorage['loginInfo'])
    //         if (!guestInfo || !guestInfo.querystring || (guestInfo.querystring !== querystring)) {
    //             await getAccessToken()
    //         }
    //         else {
    //             await getReservationDetails()
    //             if (getCurrentState().reservationDetails.ischeckin === 1) await getPropertyData()
    //         }

    //         await props.clearData()
    //         if (getCurrentState().reservationDetails.ischeckin === 1) {
    //             await getList()

    //             let fieldobj = {
    //                 'apipath': 'masters/ratings',
    //                 'masterdata': 'lostrequestrating',
    //                 'masterdatafield': 'rating',
    //                 'formdatafield': 'rating',
    //                 'staticfilter': {
    //                     'propertyid': getCurrentState().reservationDetails.propertyid,
    //                     'ratingcategoryid': Config.ratingcategoryid.lostratings
    //                 }
    //             }
    //             await getMasterData(1, fieldobj, getCurrentState().formData)

    //             if (getCurrentState().data.length === 0) {
    //                 setFormData(null, 'lostjson', 'lostModal')
    //             }
    //         }
    //     }
    //     fetchMastersJson()
    // }, [])
    useEffect(() => {
        (async function () {
            await props.setProps({
                fieldOrder: [],
                newFieldOrder: [],
                nextpage: 0,
                pageNo: 1,
                pagelimit: 5,
                pageName: '',
                formName: '',
                sortData: {},
                formData: {},
                filterData: {},
                oldFilterData: {},
                modal: {},
                // rightSidebarFormData : [],
                masterData: {},
                masterDataList: {}
            })
            //(DO NOT CHANGE WITHOUT PERMISSION)charmi(06-09-2023 12:35)(listing api calling issue fix & install application modal open issue fix)
            await props.clearData()
            await getAccessToken()
            await getReservationDetails()
            if (getCurrentState().reservationDetails.ischeckin === 1) {
                await getPropertyData()
                if (getCurrentState().reservationDetails['isreservationexpired'] !== 1) {
                    await requestPermission()
                }
                await getList()
                let fieldobj = {
                    'apipath': 'masters/ratings',
                    'masterdata': 'lostrequestrating',
                    'masterdatafield': 'rating',
                    'formdatafield': 'rating',
                    'staticfilter': {
                        'propertyid': getCurrentState().reservationDetails.propertyid,
                        'ratingcategoryid': Config.ratingcategoryid.lostratings
                    }
                }
                await getMasterData(1, fieldobj, getCurrentState().formData)
                if (getCurrentState().data.length === 0) {
                    setFormData(null, 'lostjson', 'lostModal')
                }
            }
            //(DO NOT CHANGE WITHOUT PERMISSION)charmi(06-09-2023 12:35)(listing api calling issue fix & install application modal open issue fix)
        })()
    }, [])


    // const getAccessToken = async () => {

    //     const url = Config.weburl + 'getaccesstoken'
    //     const reqData = {}
    //     const headers = {
    //         key: "Kiosist_98F2B6E0-240A-457B-853A-A0079862315F",
    //         issuer: "website",
    //     }

    //     await IISMethods.axiosrequest("POST", url, reqData, headers, successCallback, errorCallback);

    //     var resp
    //     function successCallback(response) {
    //         resp = response
    //     }
    //     function errorCallback(error) {
    //         resp = error
    //     }

    //     if (resp.status === 200) {
    //         let obj = Object.assign({}, {})
    //         if (subdomainname && bookingid) {
    //             obj.subdomainname = subdomainname
    //             obj.bookingid = bookingid
    //             obj.querystring = querystring
    //             obj.token = resp.headers.token
    //             obj.unqkey = resp.data.data.unqkey
    //             obj.uid = resp.data.data.uid
    //             obj.step = 0
    //             IISMethods.setCookiesData(Config.cookiestorage['loginInfo'], obj)
    //             await getReservationDetails()
    //             if (getCurrentState().reservationDetails.ischeckin === 1) {
    //                 await getPropertyData()
    //                 await requestPermission()
    //             }
    //         }
    //     }
    //     else {
    //         localStorage.clear()
    //     }
    // }

    // const getPropertyData = async () => {
    //     const url = Config.weburl + 'property'
    //     const useraction = 'listproperty'
    //     const reqBody = {
    //         paginationinfo: {
    //             pageno: 1,
    //             filter: {
    //                 _id: getCurrentState().reservationDetails.propertyid
    //             },
    //             sort: {}
    //         }
    //     }

    //     await setReqParams(url, reqBody)
    //     await IISMethods.listData(useraction)

    //     if (resBody.status === 200) {
    //         let cookiedata = IISMethods.getCookiesData(Config.cookiestorage['loginInfo'])
    //         cookiedata.companyid = resBody.data[0].companyid
    //         IISMethods.setCookiesData(Config.cookiestorage['loginInfo'], cookiedata)
    //         await props.setProps({ propertyData: resBody.data[0] })
    //     }
    // }

    // const getReservationDetails = async () => {
    //     const reqData = {
    //         paginationinfo: {
    //             pageno: 1,
    //             pagelimit: 20,
    //             filter: {
    //                 _id: bookingid
    //             },
    //             sort: {}
    //         }
    //     }
    //     if (bookingid !== null) {
    //         const url = Config.weburl + "guestexpreservation"//reservation guestexpreservation
    //         await setReqParams(url, reqData)
    //         await IISMethods.addData("reservation", "home")
    //     }
    //     if (resBody.status === 200) {
    //         await props.setProps({ reservationDetails: resBody.data[0] })
    //         if (resBody.data[0].ischeckin === 0) {
    //             history(`/notcheckedin${querystring}`)
    //         }
    //         if (getCurrentState().reservationDetails.isreservationexpired === 1) {
    //             history({ pathname: `/linkexpired`, querystring })
    //         }
    //     } else {
    //         localStorage.clear()
    //     }
    // }

    //delete modal click
    const handleDeleteData = () => {
        if (document.getElementById('deletemodalinput').value === 'DELETE') {
            // deleteData(getCurrentState().formData)
            handleGrid(false, 'modal', 'deletemodal', 0)
        }
    }

    //for grid scroll event - pagination
    const handleScroll = async (e) => {
        let element = e.target
        if (element.scrollHeight - element.scrollTop === element.clientHeight || ((element.scrollHeight - element.scrollTop) - 1 === element.clientHeight)) {
            if (getCurrentState().nextpage && (getCurrentState().pageNo * getCurrentState().pagelimit === getCurrentState().data.length)) {
                await props.setProps({ pageNo: getCurrentState().pageNo + 1 })
                getList()
            }
        }
    }

    //select picker printing 
    const printSelectPicker = (data, fieldobj) => {
        return (
            `${data[fieldobj.masterdatafield]}`
        )
    }

    const getMasterData = async (pageNo, fieldobj, formData, storemasterdatabyfield) => {

        var filter = {}
        var isdepent = 0

        if (fieldobj.dependentfilter) { //dependent filter set - object set
            Object.keys(fieldobj.dependentfilter).map(key => {
                const value = formData[fieldobj.dependentfilter[key]]
                if (value) {
                    isdepent = 1
                    filter[key] = value
                }
            })
        }

        if (fieldobj.staticfilter) { //static filter object assign
            Object.assign(filter, fieldobj.staticfilter)
        }

        const masterdatakey = (fieldobj.storemasterdatabyfield || storemasterdatabyfield ? fieldobj.field : fieldobj.masterdata)

        if (!fieldobj.masterdatadependancy || isdepent == 1) { //show only non dependent dropdown or dependent dropdown dependency fullfill
            const url = Config.weburl + fieldobj.apipath
            const useraction = 'list' + fieldobj.masterdata + 'data'

            filter = {
                ...IISMethods.getCopy(fieldobj.filter),
                ...filter,
            }

            if (fieldobj.staticfilter) { //static filter object assign
                filter = Object.assign(filter, fieldobj.staticfilter)
            }

            if (fieldobj.field === 'productid') {
                let parentfilter
                let areadata = getCurrentState().formData['areadetails'] || []
                if (getCurrentState().formData['assignmentforid'] === Config.assignmentfor.area) {
                    if (areadata.length) {
                        let fieldkey = 'areaid'
                        areadata = areadata.map(data => data[fieldkey])
                    }
                    parentfilter = {
                        'areaid': areadata,
                        'roomnoid': []
                    }
                    filter = Object.assign(filter, parentfilter)
                    delete filter.roomnoid
                }
                else {
                    parentfilter = {
                        'areaid': [],
                        'roomnoid': [getCurrentState().reservationDetails.roomnoid]
                    }
                    filter = Object.assign(filter, parentfilter)
                    delete filter.areaid
                }
            }

            var reqBody = {
                paginationinfo: {
                    pageno: pageNo,
                    pagelimit: pagelimit[pagelimit.length - 1].value,
                    filter: filter,
                    sort: {}
                }
            }

            // if (fieldobj.parantfilter === 1) { //static filter object assign
            //     reqBody = Object.assign(reqBody.paginationinfo.filter, reqBody)
            //     reqBody.paginationinfo.filter = {}
            // }

            if (fieldobj.parantfilter) {
                if (fieldobj.parantfilter === 1) {
                    if (fieldobj.field === 'floorwiseareaid' || fieldobj.field === 'floorwiseroomid') {

                        if (filter?.subcategory?.includes('%')) {
                            filter['subcategory'] = []
                        }
                        if (filter?.roomtype?.includes('%')) {
                            filter['roomtype'] = []
                        }
                        else if (filter?.areatype?.includes('%')) {
                            filter['areatype'] = []
                        }
                    }
                    reqBody = Object.assign(reqBody.paginationinfo.filter, { propertyid: getCurrentState().reservationDetails.propertyid })
                } else {
                    reqBody.paginationinfo.filter = ['areatypeid', 'roomtypeid', 'tasksubcategoryid', 'productid'].includes(fieldobj.field) ? reqBody.paginationinfo.filter : {}
                    reqBody = Object.assign(reqBody, fieldobj.parantfilter === 2 ? filter : fieldobj.parantfilter)
                }
            }

            if (fieldobj.parantstaticfilter) {
                if (fieldobj.field === 'productsubcategoryid') {
                    let parentfilter
                    let areadata = getCurrentState().formData['areadetails'] || []
                    if (getCurrentState().formData['assignmentforid'] === Config.assignmentfor.area) {
                        if (areadata.length) {
                            let fieldkey = 'areaid'
                            areadata = areadata.map(data => data[fieldkey])
                        }
                        parentfilter = {
                            'areaid': areadata,
                            'roomnoid': []
                        }
                        reqBody = Object.assign(reqBody, parentfilter)
                        delete reqBody.roomnoid
                    }
                    else {
                        parentfilter = {
                            'areaid': [],
                            'roomnoid': [getCurrentState().reservationDetails.roomnoid]
                        }
                        reqBody = Object.assign(reqBody, parentfilter)
                        delete reqBody.areaid
                    }
                }
                reqBody = Object.assign(reqBody, fieldobj.parantstaticfilter)
            }

            await setReqParams(url, reqBody)
            await IISMethods.listData(useraction, true)

            if (resBody.status === 200) {

                getCurrentState().masterData = IISMethods.getCopy(getCurrentState().masterData)
                getCurrentState().masterDataList = IISMethods.getCopy(getCurrentState().masterDataList)

                if (pageNo === 1) {
                    getCurrentState().masterData[masterdatakey] = []
                    getCurrentState().masterDataList[masterdatakey] = []
                }

                resBody.data.map((data) => {
                    let role = {}
                    if (fieldobj.groupwise) {
                        // for groupwise dropdown
                        role = { role: data[fieldobj.groupfield] }
                    }
                    // for product masterdata
                    if (fieldobj.field === 'productid') {
                        data['_id'] = data['productid'] + data['serialnumber']
                    }
                    getCurrentState().masterData[masterdatakey].push({ label: printSelectPicker(data, fieldobj), value: data._id, ...role })
                })

                getCurrentState().masterDataList[masterdatakey] = getCurrentState().masterDataList[masterdatakey].concat(resBody.data)

                await props.setProps({ masterData: getCurrentState().masterData, masterDataList: getCurrentState().masterDataList })

                if (resBody.nextpage)
                    await getMasterData(pageNo + 1, fieldobj, formData, storemasterdatabyfield)
            }
            else {
            }
        }
        else {
            delete getCurrentState().masterData[masterdatakey]
            delete getCurrentState().masterDataList[masterdatakey]
            await props.setProps({ masterData: IISMethods.getCopy(getCurrentState().masterData), masterDataList: IISMethods.getCopy(getCurrentState().masterDataList) })
        }
    }

    // List Data
    const getList = async (pagename) => {
        if (!getCurrentState().data.length > 0) {
            await props.setProps({ formName: '' })
        }

        const url = Config.weburl + props.pagename
        const useraction = 'l'
        let filter = {}
        let filterData = IISMethods.getCopy(getCurrentState().filterData)

        if (pagename === 'lostprogress') {
            filter = {
                "_id": getCurrentState().secondarySourceMasterData._id,
                "propertyid": getCurrentState().reservationDetails.propertyid
            }
        }
        else if (pagename === 'lostlist') {
            filter = {
                propertyid: getCurrentState().reservationDetails.propertyid,
                "lostandfoundcategory.categoryid": Config.categoryid.lost,
                // "guestdetails.guestid": getCurrentState().reservationDetails.guestid,
                "guestdetails.reservationid": getCurrentState().reservationDetails._id,
                fromfilterdate: filter.fromfilterdate,
                tofilterdate: filter.tofilterdate,
                statusid: filter.statusid
            }
        }
        else {
            filter = {
                propertyid: getCurrentState().reservationDetails.propertyid,
                "lostandfoundcategory.categoryid": Config.categoryid.lost,
                "guestdetails.reservationid": getCurrentState().reservationDetails._id
                // "guestdetails.guestid": getCurrentState().reservationDetails.guestid
            }
        }

        Object.entries(filterData).forEach(([key, value]) => {
            if (typeof (value) === 'number') {
                if (value !== 0) {
                    filter[key] = value
                }
            }
            else {
                filter[key] = value
            }
        })
        let searchtext = filter.searchtext || ''
        delete filter.searchtext
        const reqBody = {
            searchtext: searchtext,
            paginationinfo: {
                pageno: getCurrentState().pageNo,
                pagelimit: getCurrentState().pagelimit,
                filter: filter,
                sort: {
                    _id: -1
                }
            }
        }

        // let statusid = filter.statusid?.map((item) => item['nameid'])

        await setReqParams(url, reqBody)
        await IISMethods.listData(useraction)

        if (resBody.status === 200) {
            if (pagename === 'lostprogress') {
                await props.setProps({ secondarySourceMasterData: resBody.data })
            }
            else {
                if (pagename === 'lostlist') {
                    props.clearData()
                }
                await props.setProps({
                    data: resBody.data,
                    pageNo: resBody.pageno,
                    pagelimit: resBody.pagelimit,
                    nextpage: resBody.nextpage,
                    formName: resBody.formname,
                    pageName: resBody.pagename,
                })
            }

            if (location.state?.taskid) {
                await setSecondaryFormData(location.state?.taskid)
                await setPage("lostprogress")
            }
        }
        else {
        }
    }

    const getAreaList = async (status) => {
        const url = Config.weburl + 'task/listarea'
        const useraction = 'listproperty'

        const reqBody = {
            propertyid: getCurrentState().reservationDetails.propertyid,
            buildingid: getCurrentState().formData.buildingid,
            wingid: getCurrentState().formData.wingid,
            subcategory: [],
            areatype: getCurrentState().formData.areatypeid.includes('%') ? [] : getCurrentState().formData.areatypeid || [],
        }

        await setReqParams(url, reqBody)
        await IISMethods.listData(useraction)

        if (resBody.status === 200) {

            getCurrentState().masterData = IISMethods.getCopy(getCurrentState().masterData)
            getCurrentState().masterDataList = IISMethods.getCopy(getCurrentState().masterDataList)

            getCurrentState().masterData['floorwisearea'] = resBody.data
            getCurrentState().masterDataList['floorwisearea'] = resBody.data

            if (!status) {
                getCurrentState().formData['areatypeid'] = ['%']
            }

            await props.setProps({
                masterData: IISMethods.getCopy(getCurrentState().masterData),
                masterDataList: IISMethods.getCopy(getCurrentState().masterDataList),
                formData: IISMethods.getCopy(getCurrentState().formData),
            })
            handleGrid(true, 'modal', 'lostareamodal', 1)
        }
        else {
        }
    }

    const getRoomList = async (status) => {
        const url = Config.weburl + 'task/listroom'
        const useraction = 'listproperty'

        const reqBody = {
            propertyid: getCurrentState().reservationDetails.propertyid,
            buildingid: getCurrentState().formData.buildingid,
            wingid: getCurrentState().formData.wingid,
            subcategory: [],
            roomtype: getCurrentState().formData.roomtypeid.includes('%') ? [] : getCurrentState().formData.roomtypeid || [],
        }

        await setReqParams(url, reqBody)
        await IISMethods.listData(useraction)

        if (resBody.status === 200) {

            getCurrentState().masterData = IISMethods.getCopy(getCurrentState().masterData)
            getCurrentState().masterDataList = IISMethods.getCopy(getCurrentState().masterDataList)

            getCurrentState().masterData['floorwiseroom'] = resBody.data
            getCurrentState().masterDataList['floorwiseroom'] = resBody.data

            if (!status) {
                getCurrentState().formData['roomtypeid'] = ['%']
            }

            await props.setProps({
                masterData: IISMethods.getCopy(getCurrentState().masterData),
                masterDataList: IISMethods.getCopy(getCurrentState().masterDataList),
                formData: IISMethods.getCopy(getCurrentState().formData),
            })

            handleGrid(true, 'modal', 'lostroommodal', 1)
        }
        else {
        }
    }

    //form value prepare - onblut & onclick
    const handleFormData = async (type, key, value, fieldtype, fieldkey, fieldvalue) => {
        if (type === 'input-textarea') {
            getCurrentState().formData[key] = value
        }

        else if (type === 'dropdown' || type === 'groupwisedropdown') {
            const fieldobj = JsCall.getobjectfromformdata(getCurrentState().rightSidebarFormData, key)
            let getObjFromArray = IISMethods.getobjectfromarray(getCurrentState().masterDataList[fieldobj.masterdata], '_id', value)

            if (fieldobj.masterdataarray) {
                getCurrentState().formData[key] = value ? value : ''
            }
            else if (key === "assignmentforid") {
                getCurrentState().formData.areadetails = [] || ''
            }
            else {
                try {
                    getCurrentState().formData[fieldobj.formdatafield] = IISMethods.getobjectfromarray(getCurrentState().masterDataList[fieldobj.masterdata], '_id', value)[fieldobj.masterdatafield]
                    getCurrentState().formData[key] = value
                }
                catch {
                    delete getCurrentState().formData[fieldobj.formdatafield]
                    delete getCurrentState().formData[key]
                }
            }

            if (fieldobj.extrafield) {
                fieldobj.extrafield.map((item) => {
                    getCurrentState().formData[item] = getObjFromArray[item]
                })
            }
        }

        else if (type === 'multipletagpicker') {
            try {
                let formdata = getCurrentState().formData[key] || []
                let getFormData = formdata.filter(item => item !== value)
                // getCurrentState().formData[key] = ['%'] 
                getCurrentState().formData[key] = value === "%" ? [value] : formdata.includes(value) ? getFormData.length === 0 ? ["%"] : getFormData : formdata.filter(d => d !== "%").concat(value)
            }
            catch {
                getCurrentState().formData[key] = []
            }
        }

        else if (type === 'submit-floorwisedata') {
            getCurrentState().formData[key] = value
            await getMasterData(1, getCurrentState().rightSidebarFormData[0].formfields[4], getCurrentState().formData)
            // getCurrentState().formData['areatypeid'] = ['%']
        }

        // else if (type === 'submit-floorwisedataroom') {
        //     getCurrentState().formData[key] = value
        //     await getMasterData(1, getCurrentState().rightSidebarFormData[0].formfields[4], getCurrentState().formData)
        // }

        else if (type === "add-remove-star") {
            if (fieldtype === 'tags') {
                let tagdata = IISMethods.getindexfromarray(getCurrentState().formData[key][fieldkey][fieldtype], 'tagid', value)
                if (tagdata === -1) {
                    let tagdata = {}
                    tagdata['tag'] = fieldvalue
                    tagdata['tagid'] = value
                    getCurrentState().formData[key][fieldkey][fieldtype].push(tagdata)
                }
                else {
                    getCurrentState().formData[key][fieldkey][fieldtype] = getCurrentState().formData[key][fieldkey][fieldtype].filter(item => item.tagid !== value)
                }
            }
            else {
                if (getCurrentState().formData[key][fieldkey]['stars'] == 1 && value == 0) {
                    getCurrentState().formData[key][fieldkey]['stars'] = value
                }
                else {
                    getCurrentState().formData[key][fieldkey]['stars'] = value + 1
                }
            }

            let b = getCurrentState().masterDataList.lostrequestrating[fieldkey]
            let a = IISMethods.getCopy(b.tagdata.find(item => item['stars'] === getCurrentState().formData[key][fieldkey]['stars']))

            if (a) {
                getCurrentState().formData['ratingbtnlink'] = a.ratingbtnlink
                getCurrentState().formData['ratingbtnname'] = a.ratingbtnname
            }
            else {
                delete getCurrentState().formData['ratingbtnlink']
                delete getCurrentState().formData['ratingbtnname']
            }
        }

        else if (type === "radio") {
            let obj = JsCall.getobjectfromformdata(getCurrentState().rightSidebarFormData, key)
            let findRecord = IISMethods.getobjectfromarray(getCurrentState().masterData[obj.masterdata], 'value', value)
            getCurrentState().formData[key] = value
            getCurrentState().formData[obj.masterdata] = findRecord.label

            if (key === 'assignmentforid') {
                // if (value === Config.assignmentfor.room) {
                //     getCurrentState().formData['lostdetails'] = []
                // }
                // else if (value === Config.assignmentfor.area) {
                getCurrentState().formData['lostdetails'] = []
                // }
            }
        }
        // 21-2-23 srushti
        else {
            getCurrentState().formData[key] = value
        }

        let fieldobj = JsCall.getobjectfromformdata(getCurrentState().rightSidebarFormData, key)
        if (fieldobj.resetfield) {
            for (let resetfield of fieldobj.resetfield) {
                let obj = JsCall.getobjectfromformdata(getCurrentState().rightSidebarFormData, resetfield)
                if (obj.type === 'checkbox') {
                    getCurrentState().formData[resetfield] = 0
                } else {
                    getCurrentState().formData[resetfield] = ''
                }
            }
        }

        await props.setProps({ formData: IISMethods.getCopy(getCurrentState().formData) })

        JsCall.validateForm(getCurrentState().formData, getCurrentState().rightSidebarFormData, key)
        const obj = JsCall.getobjectfromformdata(getCurrentState().rightSidebarFormData, key)

        if (obj.onchangefill) {

            for (const field of obj.onchangefill) {
                let obj2 = IISMethods.getCopy(JsCall.getobjectfromformdata(getCurrentState().rightSidebarFormData, field))
                if ((obj2.field === 'buildingid' || obj2.field === 'wingid') && getCurrentState().formData['assignmentforid'] === Config.assignmentfor.room) {
                    getCurrentState().rightSidebarFormData[0].formfields[1].defaultvisibility = false
                    getCurrentState().rightSidebarFormData[0].formfields[2].defaultvisibility = false
                    await props.setProps({ rightSidebarFormData: getCurrentState().rightSidebarFormData })
                }
                if (obj2.field === 'buildingid' && getCurrentState().formData['assignmentforid'] === Config.assignmentfor.area) {
                    if (getCurrentState().masterDataList[obj2.masterdata].length === 1) {
                        getCurrentState().formData[obj2.field] = getCurrentState().masterDataList[obj2.masterdata][0]['_id']
                        getCurrentState().formData[obj2.masterdatafield] = getCurrentState().masterDataList[obj2.masterdata][0][obj2.masterdatafield]
                        getCurrentState().rightSidebarFormData[0].formfields[1].defaultvisibility = false
                        await props.setProps({ formData: getCurrentState().formData, rightSidebarFormData: getCurrentState().rightSidebarFormData })
                    }
                    else {
                        getCurrentState().rightSidebarFormData[0].formfields[1].defaultvisibility = true
                        await props.setProps({ formData: getCurrentState().formData, rightSidebarFormData: getCurrentState().rightSidebarFormData })
                    }
                }
                if (obj2.field === 'wingid' && getCurrentState().formData['assignmentforid'] === Config.assignmentfor.area) {
                    if (getCurrentState().masterDataList[obj2.masterdata].length === 1) {
                        getCurrentState().formData[obj2.field] = getCurrentState().masterDataList[obj2.masterdata][0]['_id']
                        getCurrentState().formData[obj2.masterdatafield] = getCurrentState().masterDataList[obj2.masterdata][0][obj2.masterdatafield]
                        getCurrentState().rightSidebarFormData[0].formfields[2].defaultvisibility = false
                        await props.setProps({ formData: getCurrentState().formData, rightSidebarFormData: getCurrentState().rightSidebarFormData })
                    }
                    else {
                        getCurrentState().rightSidebarFormData[0].formfields[2].defaultvisibility = true
                        await props.setProps({ formData: getCurrentState().formData, rightSidebarFormData: getCurrentState().rightSidebarFormData })
                    }
                }

                else if (obj2.type === 'dropdown' && obj2.masterdata) {
                    await getMasterData(1, obj2, getCurrentState().formData)
                    let masterdata = IISMethods.getobjectfromarray(getCurrentState().masterDataList[obj2.masterdata], obj2.masterdatafield, fieldvalue[obj2.masterdatafield])
                    getCurrentState().formData[obj2.field] = masterdata ? masterdata._id : ''
                    getCurrentState().formData[obj2.masterdata] = masterdata ? masterdata[obj2.masterdatafield] : ''
                }

                else {
                    if (obj2.field === 'floorwiseareaid') {
                        await getAreaList(1)
                    }
                    else if (obj2.field === 'floorwiseroomid') {
                        await getRoomList(1)
                    }
                    else {
                        const obj2 = JsCall.getobjectfromformdata(getCurrentState().rightSidebarFormData, field)
                        await getMasterData(1, obj2, getCurrentState().formData)
                    }

                }
            }
        }
    }

    // edit button formdata set - props set 
    const setFormData = async (id, jsoncase, modalname) => {
        getCurrentState().rightSidebarFormData = props.masterjson(jsoncase)
        var tempFormData = {}

        if (id && (modalname === "withdrawmodal")) {
            tempFormData = IISMethods.getCopy(IISMethods.getobjectfromarray(getCurrentState().data, '_id', id))
        }
        else {
            //formdata set 
            getCurrentState().rightSidebarFormData && getCurrentState().rightSidebarFormData.map((data) => {
                return data.formfields.map((fields) => {
                    tempFormData[fields.field] = fields.defaultvalue !== undefined ? fields.defaultvalue : ''
                })
            })
        }

        if (modalname === "withdrawmodal") {
            tempFormData['flag'] = 8
        }

        if (modalname === "lostModal") {
            tempFormData["propertyid"] = getCurrentState().reservationDetails.propertyid
            tempFormData["propertyname"] = getCurrentState().reservationDetails.propertyname
            tempFormData["lostandfoundcategory"] = [{
                categoryid: Config.categoryid.lost,
                category: "Lost"
            }]
            tempFormData["guestdetails"] = {
                reservationid: getCurrentState().reservationDetails._id,
                firstname: getCurrentState().reservationDetails.firstname,
                lastname: getCurrentState().reservationDetails.lastname,
                guestid: getCurrentState().reservationDetails.guestid,
                guestemail: getCurrentState().reservationDetails.guestemail,
                guestphoneno: getCurrentState().reservationDetails.guestphoneno,
                fulladdress: getCurrentState().reservationDetails.fulladdress,
                buildingid: getCurrentState().reservationDetails.buildingid,
                buildingname: getCurrentState().reservationDetails.buildingname,
                wingid: getCurrentState().reservationDetails.wingid,
                wingname: getCurrentState().reservationDetails.wingname,
                floorid: getCurrentState().reservationDetails.floorid,
                floor: getCurrentState().reservationDetails.floor,
                roomnoid: getCurrentState().reservationDetails.roomnoid,
                roomno: getCurrentState().reservationDetails.roomno,
                roomtypeid: getCurrentState().reservationDetails.roomtypeid,
                roomtype: getCurrentState().reservationDetails.roomtype,
            }

            tempFormData["assignmentforid"] = Config.assignmentfor.room
            tempFormData["assignmentfor"] = "Room"
            tempFormData["buildingid"] = getCurrentState().reservationDetails.buildingid
            tempFormData["buildingname"] = getCurrentState().reservationDetails.buildingname
            tempFormData["wingid"] = getCurrentState().reservationDetails.wingid
            tempFormData["wingname"] = getCurrentState().reservationDetails.wingname
            tempFormData["lostdetails"] = [{
                assignmentforid: Config.assignmentfor.room,
                assignmentfor: "Room",
                buildingid: getCurrentState().reservationDetails.buildingid,
                buildingname: getCurrentState().reservationDetails.buildingname,
                wingid: getCurrentState().reservationDetails.wingid,
                wingname: getCurrentState().reservationDetails.wingname,
                roomnoid: getCurrentState().reservationDetails.roomnoid,
                roomno: getCurrentState().reservationDetails.roomno,
                floorid: getCurrentState().reservationDetails.floorid,
                floor: getCurrentState().reservationDetails.floor,
                roomnoid: getCurrentState().reservationDetails.roomnoid,
                roomno: getCurrentState().reservationDetails.roomno,
                roomtypeid: getCurrentState().reservationDetails.roomtypeid,
                roomtype: getCurrentState().reservationDetails.roomtype,
            }]
            tempFormData[tempFormData.assignmentfor?.toLowerCase() === 'area' ? 'areaid' : 'roomnoid'] = tempFormData[tempFormData.assignmentfor?.toLowerCase() === 'area' ? 'area' : 'roomno']
        }

        await props.setProps({ formData: IISMethods.getCopy(tempFormData) })

        //formdata set 
        if (getCurrentState().formData['assignmentforid'] === Config.assignmentfor.room && modalname === "lostModal") {
            getCurrentState().rightSidebarFormData[0].formfields[1].defaultvisibility = false
            getCurrentState().rightSidebarFormData[0].formfields[2].defaultvisibility = false
            props.setProps({ rightSidebarFormData: getCurrentState().rightSidebarFormData })
        }

        //error handling
        for (const data of getCurrentState().rightSidebarFormData) {
            for (const fields of data.formfields) {
                JsCall.handleError('form-' + fields.field, false)
            }
        }

        //error handling
        if (modalname === 'lostModal') {
            for (const data of getCurrentState().rightSidebarFormData) {
                for (const fields of data.formfields) {
                    if (fields.masterdata && !fields.masterdatadependancy) {
                        await getMasterData(1, fields, tempFormData)
                        let tempObj = IISMethods.getCopy(getCurrentState().formData)
                        if (fields.field === 'assignmentforid') {
                            if (fields.defaultselected) {
                                let index = IISMethods.getindexfromarray(getCurrentState().masterData[fields.masterdata], 'value', getCurrentState().formData[fields.field])
                                let masterdataindex = tempObj._id && (index !== -1) ? index : fields.field === 'assignmentforid' ? getCurrentState().masterData[fields.masterdata].length - 1 : 0
                                tempObj[fields.field] = getCurrentState().masterData[fields.masterdata][masterdataindex].value
                                tempObj[fields.masterdata] = getCurrentState().masterData[fields.masterdata][masterdataindex].label
                            }
                            await props.setProps({ formData: tempObj })
                        }
                    }
                }
            }
        }

        handleGrid(true, 'modal', modalname, 1)

        //select picker data call
        for (const data of getCurrentState().rightSidebarFormData) {
            for (const fields of data.formfields) {
                if (fields.masterdata && !fields.masterdataarray) {
                    if (fields.masterdatadependancy || !getCurrentState().masterData[fields.masterdata]) //
                        await getMasterData(1, fields, getCurrentState().formData) //
                }
                else if (fields.masterdata && fields.masterdataarray && !getCurrentState().masterData[fields.masterdata]) { //
                    var array = []
                    for (const object of fields.masterdataarray) {
                        array.push({
                            label: object,
                            value: object
                        })
                    }
                    getCurrentState().masterData[fields.masterdata] = array
                    await props.setProps({ masterData: IISMethods.getCopy(getCurrentState().masterData) }) //
                }
            }
            if (modalname === "lostratingmodal") {
                tempFormData['lostfoundid'] = id
                tempFormData['lostandfoundguestratings'] = []
                getCurrentState().masterDataList['lostrequestrating'].map((data, index) => {
                    let tempdata = {}
                    tempdata['ratingid'] = data._id
                    tempdata['stars'] = 0
                    tempdata['rating'] = data.rating
                    tempdata['comments'] = data.comments ? data.comments : ''
                    tempdata['tags'] = []
                    return tempFormData['lostandfoundguestratings'].push(tempdata)
                })
                await props.setProps({ formData: tempFormData })
            }
        }
    }

    const setSecondaryFormData = async (id) => {
        var tempFormData = {}
        tempFormData = IISMethods.getCopy(IISMethods.getobjectfromarray(getCurrentState().data, '_id', id))
        await props.setProps({ secondarySourceMasterData: tempFormData })
    }

    //for open modal - recordinfo - create button   
    const handleGrid = (id, type, key, value) => {
        if (type === 'modal') {
            if (value) {
                getCurrentState().modal[key] = id
            }
            else {
                delete getCurrentState().modal[key]
            }
            props.setProps({ modal: IISMethods.getCopy(getCurrentState().modal) })
        }
        else { }
    }

    const handleRemoveAreaItems = async (_id) => {
        getCurrentState().formData['lostdetails'] = getCurrentState().formData['lostdetails'].filter((data) => [data.areaid ? data.areaid : data.roomnoid] != _id)
        await props.setProps({ formData: IISMethods.getCopy(getCurrentState().formData) })
    }

    //form data prepare - state set - button click event 
    const handleAddButtonClick = async (e, modalName, link) => {
        e.preventDefault()

        if (JsCall.validateForm(getCurrentState().formData, getCurrentState().rightSidebarFormData)) {
            IISMethods.notify("Fill all required data", 2)
        }

        else {
            //to pass the fields with empty value
            let tempdata = IISMethods.getCopy(getCurrentState().formData)

            let removefield = ['areaid', 'areatypeid', 'roomtypeid', 'floorwiseareaid', 'floorwiseroomid', 'assignmentforid', 'assignmentfor', 'buildingid', 'buildingname', 'wingid', 'wingname', 'productsubcategoryid']

            for (let rf of removefield) {
                delete tempdata[rf]
            }
            await props.setProps({ formData: IISMethods.getCopy(getCurrentState().formData) })
            if (getCurrentState().formData._id) {
                await updateData(tempdata, modalName)
            } else {
                await addData(tempdata, modalName, link)
            }
            //reset formdata variable after api called JENIL 28/6/2023 5:25 PM
            getCurrentState().rightSidebarFormData.map((data) =>
                data.formfields.map((fields) => {
                    if (!document.getElementById('form-' + fields.field)) {
                        if (fields.type === 'dropdown') {
                            if (fields.masterdataarray) {
                                getCurrentState().formData[fields.field] = ''
                            }
                            else {
                                delete getCurrentState().formData[fields.field]
                                delete getCurrentState().formData[fields.formdatafield]
                            }
                        }
                        else
                            getCurrentState().formData[fields.field] = fields.defaultvalue || ''
                    }
                    if (fields.istablefield) {
                        delete getCurrentState().formData[fields.field]
                        delete getCurrentState().formData[fields.formdatafield]
                    }
                })
            )
        }
    }

    const handleFilterData = async (type, key, value, formfield) => {
        if (type === 'multipleselectdropdown') {
            getCurrentState().filterData[key] = value
        }
        else if (type === 'filter-reset-data') {
            getCurrentState().filterData = {}
            getCurrentState().oldFilterData = {}
            //when resetfield remove defaultvisibility of "todate" JENIL 28/6/2023 7:43 PM
            getCurrentState().rightSidebarFormData[0].formfields[2].defaultvisibility = false
        }
        else if (type === 'daterangepicker') {
            if (value?.length) {
                let fromfilterdate = IISMethods.ConvertDateObjTOISOString(IISMethods.setStartTimeOfDay(value[0]))
                let tofilterdate = IISMethods.ConvertDateObjTOISOString(IISMethods.setEndTimeOfDay(value[1]))
                getCurrentState().filterData['fromfilterdate'] = value ? fromfilterdate : ''
                getCurrentState().filterData['tofilterdate'] = value ? tofilterdate : ''

            }
            else {
                delete getCurrentState().filterData['fromfilterdate']
                delete getCurrentState().filterData['tofilterdate']
            }
            // else {
            //     getCurrentState().filterData = {}
            // }
        } else if (type === "datepicker") {
            //datepicker type if value has then set otherwise blank JENIL 28/6/2023 6:33 PM
            if (key === 'fromdate') {
                getCurrentState().filterData[key] = value ? IISMethods.ConvertDateObjTOISOString(IISMethods.setStartTimeOfDay(value)) : ""
                //todate defaultvisibillity hide/show when from date
                getCurrentState().rightSidebarFormData[0].formfields[2].defaultvisibility = value ? true : false
            } else {
                getCurrentState().filterData[key] = value ? IISMethods.ConvertDateObjTOISOString(IISMethods.setEndTimeOfDay(value)) : ""
            }
        }
        else {
            getCurrentState().filterData[key] = value
        }
        //when reset field, fields should be reset JENIL 28/6/2023 6:33 PM
        if (formfield?.resetfield) {
            formfield.resetfield.forEach((item) => {
                getCurrentState().filterData[item] = ""
            })
        }
        await props.setProps({ filterData: IISMethods.getCopy(getCurrentState().filterData) })
    }

    //click handle filter button click JENIL 28/6/2023 7:12 PM
    const handleAddFilterButtonClick = () => {
        if (JsCall.validateForm(getCurrentState().filterData, getCurrentState().rightSidebarFormData)) {
            IISMethods.notify("Fill all required data", 2)
        } else {
            handleGrid(false, 'modal', 'lostfiltermodal', 0)
            handleApplyFilterData()
            getList('lostlist')
        }
    }
    const handleApplyFilterButtonClick = async (e, type) => {
        if (e) e.preventDefault()
        if (type === 'removevalue') {
            getCurrentState().filterData['searchtext'] = ''
            await props.setProps({ pageNo: 1, oldFilterData: IISMethods.getCopy(getCurrentState().filterData) })
            await props.clearData()
            await getList()
        }
        else {
            await props.setProps({ pageNo: 1, oldFilterData: IISMethods.getCopy(getCurrentState().filterData) })
            await props.clearData()
            await getList()
        }
    }

    // jatin 15-08-2023 11:53 PM
    const handleSerachClose = async () => {
        getCurrentState().filterData["searchtext"] = " "
        await props.setProps({ filterData: IISMethods.getCopy(getCurrentState().filterData), pageNo: 1 })
        await getList()

    }

    const handleApplyFilterData = async () => {
        await props.setProps({ pageNo: 1, oldFilterData: IISMethods.getCopy(getCurrentState().filterData) })
    }

    const setFilterData = async (jsoncase, modalname) => {
        getCurrentState().rightSidebarFormData = props.masterjson(jsoncase)
        //when filter modal open fromdate is not null then show "todate" defaultvisibility true JENIL 28/6/2023 7:23 PM
        if (modalname === "lostfiltermodal") {
            if (getCurrentState().filterData["fromdate"]) {
                getCurrentState().rightSidebarFormData[0].formfields[2].defaultvisibility = true
            }
        }
        handleGrid(true, 'modal', modalname, 1)
        await props.setProps({ pageNo: 1, filterData: IISMethods.getCopy(getCurrentState().oldFilterData) })

        //select picker data call
        for (const data of getCurrentState().rightSidebarFormData) {
            for (const fields of data.formfields) {
                if (fields.masterdata && !fields.masterdataarray) {
                    if (fields.masterdatadependancy || !getCurrentState().masterData[fields.masterdata]) //
                        await getMasterData(1, fields, getCurrentState().filterData) //
                }
            }
        }
    }

    //insert operation
    const addData = async (reqData, modalName, link) => {
        setLostLoader(true)
        await props.setLoading({ loading: 1 })
        const url = Config.weburl + getCurrentState().rightSidebarFormData[0].apipath
        const useraction = 'addright'

        await setReqParams(url, reqData)
        await IISMethods.addData(useraction, '', true)
        if (resBody.status === 200) {
            await props.setProps({ pageNo: 1 })
            await props.clearData()
            await props.setProps({ pageName: '' })
            handleGrid(false, 'modal', modalName, 0)
            await getList()
            await props.setLoading({ loading: 0 })
            setLostLoader(false)
            //if rating submit get link then redirect to new page JENIL 12/9/2023 5:40 PM
            if (link) window.open(link, '_blank')
        }
        else {
            await props.setLoading({ loading: 0 })
            setLostLoader(false)
        }
    }

    //update operation
    const updateData = async (reqData, modalName) => {
        setLostLoader(true)
        await props.setLoading({ loading: 1 })
        const url = Config.weburl + 'lostandfound/status/update'
        const useraction = 'listhousekeeping'
        await setReqParams(url, reqData)
        await IISMethods.updateData(useraction)
        if (resBody.status === 200) {
            handleGrid(false, 'modal', modalName, 0)
            await props.setProps({ pageNo: 1 })
            await props.clearData()
            await props.setProps({ pageName: '' })
            getList()
            await props.setLoading({ loading: 0 })
            setLostLoader(false)
        }
        else {
            await props.setLoading({ loading: 0 })
            setLostLoader(false)
        }
    }

    return (
        <>
            <LostView
                handleDeleteData={handleDeleteData}
                handleScroll={handleScroll}
                handleFormData={handleFormData}
                setFormData={setFormData}
                handleAddButtonClick={handleAddButtonClick}
                handleGrid={handleGrid}
                getAreaList={getAreaList}
                setSecondaryFormData={setSecondaryFormData}
                updateData={updateData}
                handleApplyFilterData={handleApplyFilterData}
                handleFilterData={handleFilterData}
                handleRemoveAreaItems={handleRemoveAreaItems}
                page={page}
                setPage={setPage}
                getRoomList={getRoomList}
                getList={getList}
                setFilterData={setFilterData}
                handleAddFilterButtonClick={handleAddFilterButtonClick}
                handleApplyFilterButtonClick={handleApplyFilterButtonClick}
                handleSerachClose={handleSerachClose}
                lostloader={lostloader}
            />
        </>
    )
}

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    clearData: (payload) => dispatch(clearData(payload)),
    setProps: (payload) => dispatch(setProps(payload)),
    setLoading: (payload) => dispatch(setLoading(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LostController)