
import React, { useEffect, useState } from 'react'
import { Config, IISMethods, resBody, setReqParams } from '../config/IISMethods'
import { connect, useSelector } from 'react-redux'
import 'rsuite/dist/rsuite.css'
import { store } from '..'
import { useDispatch } from 'react-redux'
import TooltipRsuite from './Components/TooltipRsuite'
import SelectPickerRsuite from './Components/SelectPickerRsuite'
import ModalRsuite from './Components/ModalRsuite'
import Rating from '../assets/images/rating.svg'
// import Loader from './Components/Loader';
import { Loader } from 'rsuite';

import MultipleSelectDropdown from './Components/MultipleSelectDropdown'
import { isBefore } from "date-fns"
import DatePickerRsuite from './Components/DatePickerRsuite'
import noimgfound from "../assets/images/noimgfound.jpg"

export default function GuestRequestRightSidebar(props) {
    const dispatch = useDispatch()
    const checkinData = useSelector(state => state)
    const getCurrentState = () => {
        return store.getState()['root']
    }

    //modal step of maintenance modal JENIL 23/8/2023 10:35 PM
    const [modalStep, setModalStep] = useState(1)
    //search product subcategory and products JENIL 23/8/2023 10:34 PM
    const [search, setSearch] = useState("")

    //selected item of maintenance JENIL 23/8/2023 10:41 AM
    const [selectedProduct, setSelectedProduct] = useState({})

    const form = 'form-'
    const star_fill = <svg className='star star-curser' xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 64 61.335">
        <path id="star_13_" data-name="star (13)" d="M63.833,23.669A3.4,3.4,0,0,0,60.9,21.33L42.433,19.653l-7.3-17.1a3.4,3.4,0,0,0-6.256,0l-7.3,17.094L3.093,21.33a3.406,3.406,0,0,0-1.931,5.953L15.125,39.528,11.008,57.666a3.4,3.4,0,0,0,5.059,3.675L32,51.817l15.928,9.524a3.4,3.4,0,0,0,5.062-3.675L48.873,39.528,62.836,27.285a3.4,3.4,0,0,0,1-3.616Zm0,0" transform="translate(0 -0.491)" fill="#ffc107" />
    </svg>
    const star_unfill = <svg className='star star-curser' xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 64 61.335">
        <path id="star_14_" data-name="star (14)" d="M14.325,61.827a3.407,3.407,0,0,1-3.315-4.16l4.117-18.137L1.165,27.284a3.4,3.4,0,0,1,1.928-5.953l18.475-1.677,7.3-17.094a3.4,3.4,0,0,1,6.256,0l7.3,17.1L60.9,21.331a3.405,3.405,0,0,1,1.934,5.953L48.875,39.527l4.117,18.137a3.4,3.4,0,0,1-5.061,3.675L32,51.818,16.069,61.344a3.409,3.409,0,0,1-1.744.483ZM32,47.717a3.41,3.41,0,0,1,1.744.482L48.78,57.192,44.894,40.074a3.4,3.4,0,0,1,1.078-3.315L59.156,25.2,41.712,23.612a3.4,3.4,0,0,1-2.827-2.062L32,5.418,25.107,21.552a3.388,3.388,0,0,1-2.818,2.054L4.842,25.19,18.026,36.753A3.4,3.4,0,0,1,19.1,40.071L15.221,57.189,30.256,48.2A3.408,3.408,0,0,1,32,47.717ZM21.427,19.984a.008.008,0,0,1,0,.005Zm21.142-.008,0,.005S42.571,19.979,42.568,19.976Zm0,0" transform="translate(0 -0.492)" fill="#ffe188" />
    </svg>

    const renderHtmlControls = (formfield) => {
        if (formfield && (formfield.defaultvisibility || getCurrentState().formData[formfield?.condition?.field] !== formfield?.condition?.onvalue)) {

            if (formfield.type === 'input-textarea') // for textarea
            {
                return (
                    <div className={formfield.gridsize}>
                        <div className="form-group validate-input">
                            <label className="guest-request-maintenance-lable">{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}</label>
                            <textarea
                                // disabled={disabled}
                                type="text"
                                onChange={(e) => props.handleFormData(formfield.type, formfield.field, e.target.value)}
                                value={getCurrentState().formData[formfield.field]}
                                className="form-control"
                                id={"form-" + formfield.field}
                                name={formfield.field}
                                autoComplete="off"
                                placeholder={`Enter ${formfield.text}`}
                            />
                        </div>
                    </div>
                )
            }
            else if (formfield.type === 'dropdown') {
                return (
                    <div className={formfield.gridsize} >
                        <div className="form-group validate-input">
                            <label className="guest-request-maintenance-lable">{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}</label>
                            {
                                //when productid then show select item button and show selected button JENIL 23/8/2023 4:04 PM
                                formfield.field === "productid" ?
                                    <>
                                        {/* add item structure JENIL 23/8/2023 1:14 PM */}
                                        {
                                            getCurrentState().formData[formfield.field] &&
                                            <div className='product-list'>
                                                <div className='single-product request-product'>
                                                    <div className='d-block mb-2'>
                                                        <div className='single-product-image pr-0'>
                                                            <img src={getCurrentState()?.formData?.image ? getCurrentState()?.formData?.image : noimgfound} alt="product" />
                                                        </div>
                                                    </div>
                                                    <div className='single-product-content text-center'>
                                                        <div className='single-product-description'>
                                                            <span className='text-12 text-dark font-weight-600 d-block'>{getCurrentState().formData["product"]}</span>
                                                            {/* <TooltipRsuite
                                                                placement={'top'}
                                                                trigger="click"
                                                                tooltip={getCurrentState().formData["product"]}
                                                                content={
                                                                    <span className="material-icons d-block product-info text-14 text-dark">info</span>
                                                                } /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        }
                                        <div className="col-12 form-group col mt-2 px-0">
                                            <button onClick={(e) => { setModalStep(2) }} className="guest-request-add-item">
                                                Select Item
                                            </button>
                                        </div>
                                    </>
                                    :
                                    <SelectPickerRsuite
                                        cleanable={formfield.cleanable}
                                        // searchable={formfield.searchable}
                                        placeholder={`${formfield.text}`}
                                        className="form-control p-0"
                                        data={getCurrentState().masterData[formfield.masterdata]}
                                        // id={"form-" + formfield.field}
                                        id="dropdownselct"
                                        name={formfield.field}
                                        onChange={(e) => props.handleFormData(formfield.type, formfield.field, e)}
                                        value={getCurrentState().formData[formfield.field]}
                                        disabled={formfield.disabled}
                                        groupBy={"role"}
                                    />
                            }
                        </div>
                    </div>
                )
            }
            else if (formfield.type === 'table') {
                return (
                    <div className={formfield.gridsize + ' mb-3'}>
                        {/* <div className="table-responsive guest-request-table mt-3"> */}
                        <div className="mt-2">
                            <div className="req-item-list mb-2">
                                Request Item List
                            </div>
                            <div className="table-responsive project-table">
                                <table className="table mb-0" id={'form-' + formfield.field}>
                                    <thead>
                                        <tr >
                                            {formfield.tablefield.map((item, index) => {
                                                return <th key={index} className={index && "text-center"}>{item}</th>
                                            })}
                                            {(!getCurrentState().formData._id || getCurrentState().formData['isrequestitemseditable'] === 1) && <th className="text-center">Action</th>}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            getCurrentState().formData.products && getCurrentState().formData.products.length > 0 ?
                                                getCurrentState().formData.products && getCurrentState().formData.products.map((item, index) => {
                                                    return <tr key={index}>
                                                        <td>{item.productname}
                                                            {/* {(getCurrentState().formData['getotheropt'] === 1) && */}
                                                            <p className='item-desc'>{item.description}</p>
                                                            {/* }  */}
                                                        </td>
                                                        <td className="text-center">
                                                            {item.quantity ? item.quantity : '-'}
                                                        </td>
                                                        {(!getCurrentState().formData._id || getCurrentState().formData['isrequestitemseditable'] === 1) &&
                                                            <td className="text-center close-button">
                                                                <a className="action-col-icon content-center" onClick={() => props.handleFormData('counter', 'products', 'removeitem', '', '_id', item._id)}>
                                                                    <TooltipRsuite
                                                                        tooltip={Config.getTooltip()['remove']}
                                                                        content={
                                                                            <div className='close-icon'>
                                                                                <i className="material-icons-round closeicon-guest-req d-block text-danger">close</i>
                                                                            </div>
                                                                        }
                                                                    />
                                                                </a>
                                                            </td>
                                                        }
                                                    </tr>
                                                })
                                                : <tr>
                                                    <td colSpan={12} className='text-14 font-weight-500 text-gray text-center'>No Data</td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                )
            }

            else {
                return <>{formfield.field}</>
            }
        }
    }

    // for filter rendering
    const renderFilterHtmlControls = (formfield) => {
        if (formfield && (formfield.defaultvisibility)) {
            if (formfield.type === 'multipleselectdropdown') { // for multipleselect dropdown
                let value = getCurrentState().filterData[formfield.field] || []

                value = value.map((fieldid) => {
                    return IISMethods.getobjectfromarray(getCurrentState().masterDataList[formfield.masterdata], 'statustype', fieldid)['_id']
                })

                return (
                    <div className={formfield.gridsize} >
                        <div className="form-group validate-input">
                            <label className=" guest-request-maintenance-lable">{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}</label>
                            <MultipleSelectDropdown
                                cleanable={formfield.cleanable}
                                placeholder={`Select ${formfield.text}`} className="form-control has-val p-0"
                                data={getCurrentState().masterData[formfield.masterdata]}
                                id={"form-" + formfield.field}
                                value={value}
                                name={formfield.field}
                                onChange={(e) => props.handleFilterData(formfield.type, formfield.field, e, formfield)}
                                selectall={formfield.selectall}
                            />
                        </div>
                    </div>
                )
            } else if (formfield.type === 'datepicker') {
                let options = {
                    disabledDate: (date) => {
                        let fromdate = IISMethods.GetDateTimefromISOString(IISMethods.GetTZdatetime(date).toISOString())
                        if (formfield.disablefromdate) {
                            let selectedDate = IISMethods.GetDateTimefromISOString(formfield.disablefromdate)
                            selectedDate.setDate(selectedDate.getDate())
                            return isBefore(fromdate, selectedDate)
                        }
                        else if (formfield.disablefromfield) {
                            let selectedDate = IISMethods.GetDateTimefromISOString(getCurrentState().filterData[formfield.disablefromfield])
                            selectedDate.setDate(selectedDate.getDate())
                            return isBefore(fromdate, selectedDate)
                        }
                    }
                }
                let value = getCurrentState().filterData[formfield.field]
                return (
                    <div className={formfield.gridsize} >
                        <div className="form-group validate-input">
                            <label className="guest-request-maintenance-lable">{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}</label>
                            <DatePickerRsuite
                                className="form-control p-0 has-val"
                                placeholder={`Enter ${formfield.text}`}
                                format={IISMethods.getDateFormatByProperty()}
                                id={"form-" + formfield.field}
                                name={formfield.field}
                                value={value ? IISMethods.GetDT(IISMethods.GetISOdate(value)) : ''}
                                type="text"
                                onChange={(e) => props.handleFilterData(formfield.type, formfield.field, e, formfield)}
                                cleanable={formfield.cleanable}
                                disabled={formfield.disabled}
                                ranges={[]}
                                {...options}
                            />
                        </div>
                    </div>
                )
            }
            else {
                return <></>
            }
        }
    }

    try {
        var rightSidebarFormData
        var rightSidebar
        var pageName
        var formData
        var formname
        var filterData
        var oldFilterData

        filterData = IISMethods.getCopy(getCurrentState().filterData)
        oldFilterData = IISMethods.getCopy(getCurrentState().oldFilterData)

        rightSidebar = 'rightSidebar'
        rightSidebarFormData = getCurrentState().rightSidebarFormData
        pageName = checkinData.pageName
        formname = 'form-'
        formData = IISMethods.getCopy(checkinData.formData)

        var dateValueFrom = filterData['fromdate']
        var dateValueTo = filterData['todate']

        var dateValue = (dateValueFrom && dateValueTo) ? [dateValueFrom, dateValueTo] : []

        //handleSelect category of guest request JENIL 23/8/2023 10:50 AM
        const handleSelectCategory = (category) => {
            setSelectedProduct({ ...selectedProduct, productsubcategoryid: category._id, productsubcategory: category.productsubcategory })
            setModalStep(3);
        }

        //handleSelect product of maintenance JENIL 23/8/2023 10:50 AM
        const handleSelectProduct = async (product) => {
            let changeProduct = { ...selectedProduct, product: product.productname, productid: product._id }
            setSelectedProduct(changeProduct)
            await props.handleFormData("selectproduct", "productid", changeProduct)
            setModalStep(1);
        }

        // //product quantity decrease 
        // const handleQtyDecrease = (product) => {
        //     let changeProduct = selectedProduct?.product?.length > 0 ? [...selectedProduct.product] : []
        //     let index = changeProduct.findIndex(a => a.productid === product._id)
        //     if (index >= 0) {
        //         let obj = { ...changeProduct[index] }
        //         if (obj.quantity > 0) {
        //             obj.quantity = obj.quantity - 1
        //         }
        //         changeProduct.splice(index, 1, obj)
        //     }
        //     setSelectedProduct({ ...selectedProduct, product: [...changeProduct] })
        // }

        // //product quantity increase 
        // const handleQtyIncrease = (product) => {
        //     let changeProduct = selectedProduct?.product?.length > 0 ? [...selectedProduct.product] : []
        //     let index = changeProduct.findIndex(a => a.productid === product._id)
        //     if (index >= 0) {
        //         let obj = { ...changeProduct[index] }
        //         obj.quantity = obj.quantity + 1
        //         changeProduct.splice(index, 1, obj)
        //     } else {
        //         let obj = {
        //             product: product.productname,
        //             productid: product._id,
        //             quantity: 1,
        //         }
        //         changeProduct.push(obj)
        //     }
        //     setSelectedProduct({ ...selectedProduct, product: [...changeProduct] })
        // }

        return (
            <>
                {/* requestitem modal 29-3-23  */}

                <ModalRsuite
                    size={"xs"}
                    open={getCurrentState().modal['guestrequestmodal']}
                    onClose={() => props.handleGrid(false, 'modal', 'guestrequestmodal', 0)}
                    title={
                        <div className="model-header icon">
                            <span className="closeicon">
                                {getCurrentState().formData?._id ? 'Update Request Item' : 'Request Item'}
                            </span>
                        </div>
                    }
                    body={
                        <div className="col-12">
                            {
                                modalStep === 1 &&
                                <div className="row">
                                    {/* for model in all field set index wise and calling json index */}
                                    {renderHtmlControls(rightSidebarFormData[0]?.formfields[0])}
                                    {renderHtmlControls(rightSidebarFormData[0]?.formfields[1])}
                                    {renderHtmlControls(rightSidebarFormData[0]?.formfields[2])}

                                    {/* for add and remove item increment/decrement  */}
                                    {getCurrentState().formData.productsubcategoryid !== Config.othersubcategory &&
                                        <>
                                            <div className="col-12">
                                                <div className='form-group validate-input'>
                                                    <label className='guest-request-maintenance-lable'>Enter Item Quantity</label>
                                                    <div className='row'>
                                                        <div className='col-6 pr-0'>
                                                            <div className="product-add-box d-flex justify-content-between align-items-center">
                                                                {/* <div className="d-flex"> */}
                                                                <TooltipRsuite
                                                                    placement={Config.getTooltip()['placement']}
                                                                    tooltip={Config.getTooltip()['remove']}
                                                                    content={
                                                                        <a className="add-field-btn btn-icon position-static remove-box m-1" onClick={() => props.handleFormData('counter', 'itemqty', 'dec')}><i className="material-icons-round">remove</i></a>
                                                                    }
                                                                />
                                                                <div className="d-flex justify-content-center align-items-center notifyText">{props.itemqty}</div>
                                                                <TooltipRsuite
                                                                    placement={Config.getTooltip()['placement']}
                                                                    tooltip={Config.getTooltip()['add']}
                                                                    content={
                                                                        <a className="add-field-btn btn-icon position-static remove-box m-1" onClick={() => props.handleFormData('counter', 'itemqty', 'inc')}><i className="material-icons-round">add</i></a>
                                                                    }
                                                                />
                                                                {/* </div> */}
                                                            </div>
                                                        </div>
                                                        <div className='col-6'>
                                                            <div className="form-group mb-0 margin-bottom-0">
                                                                <button onClick={(e) => { props.handleFormData('counter', 'products', 'submit') }} className="guest-request-add-item">
                                                                    Add Item
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }

                                    {renderHtmlControls(rightSidebarFormData[0]?.formfields[4])}
                                </div>
                            }
                            {
                                modalStep === 2 &&
                                <div className='categories-list'>
                                    <div className='search-categories'>
                                        <div className='back-icon d-flex align-items-center gap-5' onClick={() => {
                                            setModalStep(1);
                                            setSearch("")
                                        }}>
                                            <i className='material-icons-round text-18 font-weight-700'>arrow_back_ios</i>
                                            <span className='text-16 text-dark font-weight-600'>Add Item</span>
                                        </div>
                                        <div className='search-input-icon'>
                                            <i className="material-icons-round">search</i>
                                            <input
                                                type='text'
                                                placeholder='Search Category'
                                                value={search}
                                                onChange={(e) => setSearch(e?.target?.value ? e.target.value : "")}
                                            />
                                        </div>
                                    </div>
                                    {
                                        <div className='single-category-grid-list'>
                                            {
                                                getCurrentState().masterDataList["productsubcategory"]?.length > 0 &&
                                                getCurrentState().masterDataList["productsubcategory"].filter((category) => {
                                                    if (search?.trim().length === 0) {
                                                        return true
                                                    } else if (category?.productsubcategory?.replace(/\s+/g, '')?.toLowerCase()?.includes(search.replace(/\s+/g, '')?.toLocaleLowerCase())) {
                                                        return true
                                                    }
                                                })?.map((category, index) => {
                                                    //if category has product then show other wise not JENIL 23/8/2023 3:25 PM
                                                    let isshow = getCurrentState().masterDataList["product"]?.some(obj => obj.productsubcategoryid === category?._id)
                                                    return (
                                                        isshow &&
                                                        <div className='single-category' key={category._id + index.toString()}>
                                                            <div className='category-image' onClick={() => { handleSelectCategory(category) }}>
                                                                <img className={'active'} src={category?.icon ? category?.icon : noimgfound} alt="Category" />
                                                            </div>
                                                            <div className='category-name-info d-flex align-items-center mt-2 justify-content-center gap-5'>
                                                                <span className='product-name text-12 text-dark font-weight-600'>{category.productsubcategory}</span>
                                                                <TooltipRsuite
                                                                    placement={'top'}
                                                                    trigger="click"
                                                                    tooltip={category.productsubcategory}
                                                                    content={
                                                                        <span className="material-icons d-block product-info text-16 text-dark">info</span>
                                                                    } />
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                </div>
                            }
                            {
                                modalStep === 3 && selectedProduct?.productsubcategoryid &&
                                <div className='categories-list'>
                                    <div className='search-categories'>
                                        <div className='back-icon d-flex align-items-center gap-5' onClick={() => {
                                            setModalStep(2);
                                            setSearch("")
                                        }}>
                                            <i className='material-icons-round text-18 font-weight-700'>arrow_back_ios</i>
                                            <span className='text-16 text-dark font-weight-600'>{selectedProduct?.productsubcategory}</span>
                                        </div>
                                        <div className='search-input-icon'>
                                            <i className="material-icons-round">search</i>
                                            <input
                                                type='text'
                                                placeholder='Search Product'
                                                value={search}
                                                onChange={(e) => setSearch(e?.target?.value ? e.target.value : "")}
                                            />
                                        </div>
                                    </div>
                                    {
                                        <div className='single-category-grid-list'>
                                            {
                                                getCurrentState().masterDataList["product"]?.length > 0 &&
                                                getCurrentState().masterDataList["product"]?.filter(obj => obj.productsubcategoryid === selectedProduct?.productsubcategoryid)?.filter((product) => {
                                                    if (search?.trim().length === 0) {
                                                        return true
                                                    } else if (product?.productname?.replace(/\s+/g, '')?.toLowerCase()?.includes(search.replace(/\s+/g, '')?.toLocaleLowerCase())) {
                                                        return true
                                                    }
                                                })?.map((product, index) => {
                                                    return (
                                                        <div className='single-category' key={product._id + index.toString()} onClick={() => handleSelectProduct(product)}>
                                                            <div className='category-image'>
                                                                <img className='active' src={product?.image ? product?.image : noimgfound} alt="Category" />
                                                            </div>
                                                            <div className='category-name-info d-flex align-items-center mt-2 justify-content-center gap-5'>
                                                                <span className='product-name text-12 text-dark font-weight-600'>{product.productname}</span>
                                                                <TooltipRsuite
                                                                    placement={'top'}
                                                                    trigger="click"
                                                                    tooltip={product.productname}
                                                                    content={
                                                                        <span className="material-icons d-block product-info text-16 text-dark">info</span>
                                                                    } />
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    }
                    footer={
                        modalStep === 1 &&
                        <div className='col-12 mt-1'>
                            <button className="btn-first-popup mb-3 " disabled={getCurrentState().loading} type="button" onClick={(e) => {props.handleAddButtonClick(e, 'guestrequestmodal') }} >
                                {
                                    props.requestloder === true ? 
                                    <Loader size="sm" content={getCurrentState().formData?._id ? Config.updatesidebar : 'Request Item'} />
                                    :
                                    getCurrentState().formData?._id ? Config.updatesidebar : 'Request Item'
                                    // <Loader  loding={getCurrentState().loading} size="sm" content={getCurrentState().formData?._id ? Config.updatesidebar : 'Request Item'} />
                                }
                            </button>
                        </div>
                    }
                />


                {/* guest request rating modal  */}

                <ModalRsuite
                    size={"xs"}
                    open={getCurrentState().modal['requestratingmodal']}
                    onClose={() => props.handleGrid(false, 'modal', 'requestratingmodal', 0)}
                    title={""}
                    body={
                        <form method='post'>
                            <div className={`col-12 text-center`}>
                                <div className="first-pop-logo">
                                    <img src={Rating} />
                                </div>
                            </div>
                            <div className="col-12 text-center">
                                <h1 className="first-popup-title mt-2 mb-4">We Value Your Feedback</h1>
                            </div>
                            {
                                getCurrentState().rightSidebarFormData[0]?.formfields.filter(formfield => {
                                    try {
                                        return formfield.defaultvisibility || getCurrentState().formData[formfield.condition.field] === formfield.condition.onvalue
                                    }
                                    catch {
                                        return false
                                    }
                                }).map((formfield, fieldindex) => {
                                    if (formfield.type === 'dropdown') {
                                        return (
                                            <>
                                                {/* vehicle modal rating 17-4-23 */}

                                                {/* charmi(09-06-2023)(rating tag print change) */}
                                                {getCurrentState().masterDataList[formfield.masterdata]?.map((data, dataindex) => {
                                                    let tagdata = data.tagdata || []
                                                    let ratingdataobj = {}
                                                    ratingdataobj[dataindex] = []
                                                    let singlearray = ratingdataobj[dataindex]
                                                    for (const item of tagdata) {
                                                        if (getCurrentState().formData['taskguestrating'] && getCurrentState().formData['taskguestrating'][dataindex]['stars'] !== 0 && getCurrentState().formData['taskguestrating'][dataindex]['stars'] <= item.stars && item.abovebelowflag === 1) {
                                                            let temptagdataarray = singlearray.map(data => data.tagid)
                                                            let tagdata = item.tags.filter(data => !temptagdataarray.includes(data.tagid))
                                                            singlearray.push(...tagdata)
                                                        }
                                                        else if (getCurrentState().formData['taskguestrating'] && getCurrentState().formData['taskguestrating'][dataindex]['stars'] !== 0 && getCurrentState().formData['taskguestrating'][dataindex]['stars'] >= item.stars && item.abovebelowflag === 0) {
                                                            let temptagdataarray = singlearray.map(data => data.tagid)
                                                            let tagdata = item.tags.filter(data => !temptagdataarray.includes(data.tagid))
                                                            singlearray.push(...tagdata)
                                                        }
                                                    }
                                                    return (
                                                        <div className="col-12 my-3" key={dataindex}>
                                                            <div className="rate-box">
                                                                <div className="rate-box-content">
                                                                    <p>
                                                                        {data.rating}
                                                                    </p>
                                                                </div>
                                                                <div className="d-flex justify-content-between mx-5">
                                                                    {
                                                                        // handlestars(formfield.field,dataindex,data._id)
                                                                        [...Array(5)].map((star, index) => {
                                                                            if (getCurrentState().formData['taskguestrating'] && getCurrentState().formData['taskguestrating'][dataindex]?.['stars'] - 1 >= index) {
                                                                                return <div className='my-3'
                                                                                    onClick={() => { props.handleFormData('add-remove-star', formfield.field, index, '', dataindex, data._id) }}
                                                                                >
                                                                                    {star_fill}
                                                                                </div>
                                                                            } else {
                                                                                return <div className='my-3'
                                                                                    onClick={() => { props.handleFormData('add-remove-star', formfield.field, index, '', dataindex, data._id) }}
                                                                                >
                                                                                    {star_unfill}
                                                                                </div>
                                                                            }
                                                                        })

                                                                    }
                                                                </div>
                                                                {
                                                                    singlearray?.length > 0
                                                                    &&
                                                                    <div className='ratingTagGrid'>
                                                                        {singlearray.map((tagitem, index) => {
                                                                            let tagdata = IISMethods.getindexfromarray(getCurrentState().formData['taskguestrating'][dataindex]['tags'], 'tagid', tagitem.tagid)
                                                                            return (
                                                                                <div className="task-tag-custom-control" key={index}>
                                                                                    <div className="custom-control-tag ">
                                                                                        <label className={`custom-label-rt content-center ${(tagdata !== -1) ? "tagactive" : ''}`}
                                                                                            onClick={() => props.handleFormData('add-remove-star', formfield.field, tagitem.tagid, 'tags', dataindex, tagitem.tag)}
                                                                                            style={{
                                                                                                borderRadius: '20px',
                                                                                                border: `1px solid #9E9E9E`,
                                                                                                // background : 'black'
                                                                                            }} >
                                                                                            {tagitem.tag}
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                        }
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                }
                                            </>
                                        )
                                    }
                                    else if (formfield.type === 'input-textarea') // for textarea
                                    {
                                        return (
                                            <div className={formfield.gridsize} key={`${fieldindex}`}>
                                                <div className="form-group validate-input mt-0">
                                                    <center><label className="first-popup-label mt-3 mb-3">{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}</label></center>
                                                    <textarea
                                                        disabled={formfield.disabled} type="text" rows={3}
                                                        defaultValue={getCurrentState().formData[formfield.field]}
                                                        onChange={(e) => props.handleFormData(formfield.type, formfield.field, e.target.value, '', 0, 'suggestion')}
                                                        className="form-control form-control-textarea"
                                                        id={form + formfield.field}
                                                        name={formfield.field} autoComplete="off"
                                                        placeholder={`Enter ${formfield.text}`}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </form>
                    }
                    footer={
                        <>
                            {/* {(getCurrentState().formData.ratingbtnlink && getCurrentState().formData.ratingbtnlink) &&
                                <div className="col-12 mt-1">
                                    <a target='_blank' href={getCurrentState().formData.ratingbtnlink}>
                                        <span className='vehicle-model-btn text-center'>{getCurrentState().formData.ratingbtnname}</span>
                                    </a>
                                </div>
                            } */}
                            <div className="col-12 mt-1">
                                <button type="submit" className="btn-first-popup mb-3"
                                    onClick={(e) => {
                                        getCurrentState().loading === 0 && props.handleAddButtonClick(e, 'requestratingmodal', getCurrentState().formData?.ratingbtnlink)
                                    }} >
                                    <Loader content={'Submit'} />
                                </button>
                            </div>
                        </>
                    }
                />


                {/* guest request filter modal 13-6-23 */}
                <ModalRsuite
                    size={"xs"}
                    open={getCurrentState().modal['requestfiltermodal']}
                    onClose={(e) => { props.handleGrid(false, 'modal', 'requestfiltermodal', 0); }}
                    title={
                        <div className="model-header icon">
                            <span className="closeicon">
                                Apply Filter
                            </span>
                        </div>
                    }
                    body={
                        <>
                            <div className="col-12 mt-0 mb-0 px-0">
                                {renderFilterHtmlControls(rightSidebarFormData[0]?.formfields[0])}
                                {renderFilterHtmlControls(rightSidebarFormData[0]?.formfields[1])}
                                {renderFilterHtmlControls(rightSidebarFormData[0]?.formfields[2])}
                            </div>
                            {/* <div className="col-11 mt-0 mb-0 px-0 ml-3">
                                <label className=" guest-request-maintenance-lable">Date</label>
                                <DateRangePickerRsuite
                                    className="form-control p-0 has-val"
                                    placeholder={`Enter Date`}
                                    format={IISMethods.getDateFormatByProperty()}
                                    id='filter-viewdate'
                                    name='filter-viewdate' ranges={[]}
                                    value={dateValue}
                                    onChange={(e) => props.handleFilterData('daterangepicker', '', e)}
                                />
                            </div> */}
                        </>
                    }
                    footer={
                        <div className="col-12 mt-1 mb-3">
                            <div className='row'>
                                <div className='col-6 pr-2'>
                                    <button
                                        onClick={async () => {
                                            await props.handleGrid(false, 'modal', 'requestfiltermodal', 0);
                                            await props.handleFilterData('filter-reset-data', '', '')
                                            await props.getList()
                                        }}
                                        className="btn btn-secondary-light btn-sm button">
                                        Reset
                                    </button>
                                </div>
                                <div className='col-6 pl-2'>
                                    <button
                                        onClick={(e) => {
                                            props.handleAddFilterButtonClick(e)
                                        }}
                                        className="btn btn-primary-light btn-sm button">
                                        <Loader content={'Apply'} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                />
            </>
        )
    }
    catch (e) {
        console.log(e)
        return <></>
    }
}
