import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { store } from '..'
import { Config, IISMethods, JsCall, pagelimit, resBody, setReqParams } from '../config/IISMethods'
import { clearData, setLoading, setProps } from '../redux/actions'
import MaintenanceView from '../view/MaintenanceView'
import { useNavigate, useLocation } from 'react-router-dom'
import useFirebase from '../ServiceWorker/useFirebase'
import useAPICall from '../hooks/useApiCall'


// srushti
let initialstate = 'maintenhome'
const MaintenanceController = (props) => {

    const { getReservationDetails, getAccessToken, getPropertyData } = useAPICall()

    // for notification request
    const { requestPermission } = useFirebase()

    const location = useLocation()

    const getCurrentState = () => {
        return store.getState()['root']
    }
    const history = useNavigate()
    const [page, setPage] = useState(initialstate)
    const [maintenancetloder, setMaintenancetloder] = useState(false)
    // let subdomainname = IISMethods.getBookingIDSubDomain()['subdomainname']
    // let bookingid = IISMethods.getBookingIDSubDomain()['bookingid']
    // let querystring = IISMethods.getQueryString()

    //first call - default props set & grid call - getlist
    // useEffect(() => {
    //     async function fetchMastersJson() {
    //         props.setProps({
    //             fieldOrder: [],
    //             newFieldOrder: [],
    //             nextpage: 0,
    //             pageNo: 1,
    //             pagelimit: 5,
    //             pageName: '',
    //             formName: '',
    //             sortData: {},
    //             formData: {},
    //             filterData: {},
    //             oldFilterData: {},
    //             modal: {},
    //             // rightSidebarFormData : [],
    //             masterData: {},
    //             masterDataList: {}
    //         })

    //         let guestInfo = IISMethods.getCookiesData(Config.cookiestorage['loginInfo'])
    //         if (!guestInfo || !guestInfo.querystring || (guestInfo.querystring !== querystring)) {
    //             await getAccessToken()
    //         }
    //         else {
    //             await getReservationDetails()
    //             if (getCurrentState().reservationDetails.ischeckin === 1) await getPropertyData()
    //         }

    //         await props.clearData()
    //         if (getCurrentState().reservationDetails.ischeckin === 1) {
    //             await getList()

    //             let fieldobj = {
    //                 'apipath': 'masters/ratings',
    //                 'masterdata': 'maintenrating',
    //                 'masterdatafield': 'rating',
    //                 'formdatafield': 'rating',
    //                 'staticfilter': {
    //                     'propertyid': getCurrentState().reservationDetails.propertyid,
    //                     'ratingcategoryid': Config.ratingcategoryid.maintenanceratings
    //                 }
    //             }
    //             await getMasterData(1, fieldobj, getCurrentState().formData)

    //             if (getCurrentState().data.length === 0) {
    //                 await setFormData(null, 'maintenancejson', 'maintenancemodal')
    //             }
    //         }
    //     }
    //     fetchMastersJson()
    // }, [])

    useEffect(() => {
        (async function () {
            await props.setProps({
                fieldOrder: [],
                newFieldOrder: [],
                nextpage: 0,
                pageNo: 1,
                pagelimit: 5,
                pageName: '',
                formName: '',
                sortData: {},
                formData: {},
                filterData: {},
                oldFilterData: {},
                modal: {},
                // rightSidebarFormData : [],
                masterData: {},
                masterDataList: {}
            })
            //(DO NOT CHANGE WITHOUT PERMISSION)charmi(06-09-2023 12:35)(listing api calling issue fix & install application modal open issue fix)
            await props.clearData()
            await getAccessToken()
            await getReservationDetails()
            if (getCurrentState().reservationDetails.ischeckin === 1) {
                await getPropertyData()
                if (getCurrentState().reservationDetails['isreservationexpired'] !== 1) {
                    await requestPermission()
                }
                await getList()
                let fieldobj = {
                    'apipath': 'masters/ratings',
                    'masterdata': 'maintenrating',
                    'masterdatafield': 'rating',
                    'formdatafield': 'rating',
                    'staticfilter': {
                        'propertyid': getCurrentState().reservationDetails.propertyid,
                        'ratingcategoryid': Config.ratingcategoryid.maintenanceratings
                    }
                }
                await getMasterData(1, fieldobj, getCurrentState().formData)
                if (getCurrentState().data.length === 0) {
                    await setFormData(null, 'maintenancejson', 'maintenancemodal')
                }
            }
            //(DO NOT CHANGE WITHOUT PERMISSION)charmi(06-09-2023 12:35)(listing api calling issue fix & install application modal open issue fix)
        })()
    }, [])


    // const getAccessToken = async () => {
    //     const url = Config.weburl + 'getaccesstoken'
    //     const reqData = {}
    //     const headers = {
    //         key: "Kiosist_98F2B6E0-240A-457B-853A-A0079862315F",
    //         issuer: "website",
    //         // key: process.env.REACT_APP_KEY,
    //         // issuer: process.env.REACT_APP_ISSUER
    //     }

    //     await IISMethods.axiosrequest("POST", url, reqData, headers, successCallback, errorCallback);
    //     var resp
    //     function successCallback(response) {
    //         resp = response
    //     }
    //     function errorCallback(error) {
    //         resp = error
    //     }

    //     if (resp.status === 200) {
    //         let obj = Object.assign({}, {})
    //         if (subdomainname && bookingid) {
    //             obj.subdomainname = subdomainname
    //             obj.bookingid = bookingid
    //             obj.querystring = querystring
    //             obj.token = resp.headers.token
    //             obj.unqkey = resp.data.data.unqkey
    //             obj.uid = resp.data.data.uid
    //             obj.step = 0
    //             IISMethods.setCookiesData(Config.cookiestorage['loginInfo'], obj)
    //             await getReservationDetails()
    //             if (getCurrentState().reservationDetails.ischeckin === 1) {
    //                 await getPropertyData()
    //                 await requestPermission()
    //             }
    //         }
    //     }
    //     else {
    //         localStorage.clear()
    //     }
    // }

    // const getReservationDetails = async () => {
    //     const reqData = {
    //         paginationinfo: {
    //             pageno: 1,
    //             pagelimit: 20,
    //             filter: {
    //                 _id: bookingid
    //             },
    //             sort: {}
    //         }
    //     }
    //     if (bookingid !== null) {
    //         const url = Config.weburl + "guestexpreservation"//reservation guestexpreservation
    //         await setReqParams(url, reqData)
    //         await IISMethods.addData("reservation", "home")
    //     }

    //     if (resBody.status === 200) {
    //         await props.setProps({ reservationDetails: resBody.data[0] })
    //         if (resBody.data[0].ischeckin === 0) {
    //             history(`/notcheckedin${querystring}`)
    //         }
    //         if (getCurrentState().reservationDetails.isreservationexpired === 1) {
    //             history({ pathname: `/linkexpired`, querystring })
    //         }
    //     } else {
    //         localStorage.clear()
    //     }
    // }

    // const getPropertyData = async () => {
    //     const url = Config.weburl + 'property'
    //     const useraction = 'listproperty'
    //     const reqBody = {

    //         paginationinfo: {
    //             pageno: 1,
    //             filter: {
    //                 _id: getCurrentState().reservationDetails.propertyid
    //             },
    //             sort: {}
    //         }
    //     }

    //     await setReqParams(url, reqBody)
    //     await IISMethods.listData(useraction)

    //     if (resBody.status === 200) {
    //         let cookiedata = IISMethods.getCookiesData(Config.cookiestorage['loginInfo'])
    //         cookiedata.companyid = resBody.data[0].companyid
    //         IISMethods.setCookiesData(Config.cookiestorage['loginInfo'], cookiedata)
    //         await props.setProps({ propertyData: resBody.data[0] })
    //     }
    // }

    //delete modal click
    const handleDeleteData = () => {
        if (document.getElementById('deletemodalinput').value === 'DELETE') {
            deleteData(getCurrentState().formData)
            handleGrid(false, 'modal', 'deletemodal', 0)
        }
    }

    //for grid scroll event - pagination
    const handleScroll = async (e) => {
        let element = e.target
        if (element.scrollHeight - element.scrollTop === element.clientHeight || ((element.scrollHeight - element.scrollTop) - 1 === element.clientHeight)) {
            if (getCurrentState().nextpage && (getCurrentState().pageNo * getCurrentState().pagelimit === getCurrentState().data.length)) {
                await props.setProps({ pageNo: getCurrentState().pageNo + 1 })
                getList()
            }
        }
    }

    //select picker printing 
    const printSelectPicker = (data, fieldobj) => {
        return (
            // for concat product in maintenance
            fieldobj.field === 'productid'
                ? ` ${data['displayname']
                    ? data['displayname']
                    : data[fieldobj.masterdatafield]} ${data['serialnumber'] && !data['displayname']
                        ? '(' + data['serialnumber'] + ')'
                        : ''}`
                : `${data[fieldobj.masterdatafield]}`

        )
    }

    //for selectpicker data print and api call 
    const getMasterData = async (pageNo, fieldobj, formData, storemasterdatabyfield) => {
        var filter = {}
        var isdepent = 0
        if (fieldobj.dependentfilter) { //dependent filter set - object set
            Object.keys(fieldobj.dependentfilter).map(key => {
                const value = formData[fieldobj.dependentfilter[key]]
                if (value) {
                    isdepent = 1
                    filter[key] = value
                }
            })
        }

        if (fieldobj.staticfilter) { //static filter object assign
            Object.assign(filter, fieldobj.staticfilter)
        }

        const masterdatakey = (fieldobj.storemasterdatabyfield || storemasterdatabyfield ? fieldobj.field : fieldobj.masterdata)

        if (!fieldobj.masterdatadependancy || isdepent == 1) { //show only non dependent dropdown or dependent dropdown dependency fullfill
            const url = Config.weburl + fieldobj.apipath
            const useraction = 'list' + fieldobj.masterdata + 'data'

            filter = {
                ...IISMethods.getCopy(fieldobj.filter),
                ...filter,
            }
            if (fieldobj.staticfilter) { //static filter object assign	
                filter = Object.assign(filter, fieldobj.staticfilter)
            }

            var reqBody = {
                paginationinfo: {
                    pageno: pageNo,
                    pagelimit: '*',
                    filter: filter,
                    sort: {}
                }
            }

            if (fieldobj.parantstaticfilter) {
                reqBody = Object.assign(fieldobj.parantstaticfilter, reqBody)
            }

            if (fieldobj.field === "productid") {
                reqBody = { ...reqBody, categoryid: Config.categoryid.maintenance }
            }

            await setReqParams(url, reqBody)
            await IISMethods.listData(useraction, true)

            if (resBody.status === 200) {
                getCurrentState().masterData = IISMethods.getCopy(getCurrentState().masterData)
                getCurrentState().masterDataList = IISMethods.getCopy(getCurrentState().masterDataList)

                if (pageNo === 1) {
                    getCurrentState().masterData[masterdatakey] = []
                    getCurrentState().masterDataList[masterdatakey] = []
                }

                resBody.data.map((data) => {
                    let role = {}
                    if (fieldobj.groupwise) {
                        // for groupwise dropdown
                        role = { role: data[fieldobj.groupfield] }
                    }

                    // for maintenance assign role productid
                    if (fieldobj.field === 'productid') {
                        data['_id'] = data['productid'] + data['serialnumber']
                        getCurrentState().masterData[masterdatakey].push({ label: printSelectPicker(data, fieldobj), value: data.serialnumber, ...role })
                    }
                    else {
                        getCurrentState().masterData[masterdatakey].push({ label: printSelectPicker(data, fieldobj), value: data._id, ...role })
                    }

                })
                getCurrentState().masterDataList[masterdatakey] = getCurrentState().masterDataList[masterdatakey].concat(resBody.data)

                await props.setProps({ masterData: getCurrentState().masterData, masterDataList: getCurrentState().masterDataList })

                if (resBody.nextpage)
                    await getMasterData(pageNo + 1, fieldobj, formData, storemasterdatabyfield)
            }
            else {
            }
        }
        else {
            delete getCurrentState().masterData[masterdatakey]
            delete getCurrentState().masterDataList[masterdatakey]
            await props.setProps({ masterData: IISMethods.getCopy(getCurrentState().masterData), masterDataList: IISMethods.getCopy(getCurrentState().masterDataList) })
        }
    }

    // List Data
    const getList = async () => {

        if (!getCurrentState().data.length > 0) {
            await props.setProps({ formName: '' })
        }

        const url = Config.weburl + props.pagename
        const useraction = 'l'
        let filter = {}
        let filterData = IISMethods.getCopy(getCurrentState().filterData)

        Object.entries(filterData).forEach(([key, value]) => {
            if (typeof (value) === 'number') {
                if (value !== 0) {
                    filter[key] = value
                }
            }
            else {
                filter[key] = value
            }
        })
        let searchtext = filter.searchtext || ''
        delete filter.searchtext
        const reqBody = {
            searchtext: searchtext,
            fromdate: filter.fromdate,
            todate: filter.todate,
            paginationinfo: {
                pageno: getCurrentState().pageNo,
                pagelimit: getCurrentState().pagelimit,
                filter: {
                    propertyid: getCurrentState().reservationDetails.propertyid,
                    categoryid: Config.categoryid.maintenance,
                    roomnoid: getCurrentState().reservationDetails.roomnoid,
                    reservationid: getCurrentState().reservationDetails._id,
                    taskstatustype: filter.taskstatustype,
                    priorityid: filter.priorityid
                },
                sort: {
                    _id: -1
                }
            }
        }

        await setReqParams(url, reqBody)
        await IISMethods.listData(useraction)

        if (resBody.status === 200) {
            if (getCurrentState().pageNo === 1) {
                await props.clearData()
            }
            await props.setProps({
                data: resBody.data,
                pageNo: resBody.pageno,
                pagelimit: resBody.pagelimit,
                nextpage: resBody.nextpage,
                pageName: resBody.pagename,
                formName: resBody.formname
            })

            if (location.state?.taskid) {
                await setSecondaryFormData(location.state?.taskid)
                await setPage("maintendemo")
                // if (getCurrentState().secondarySourceMasterData?._id) 

            }
        }
        else {
        }
    }

    // get list issue Data
    const getIssueList = async (productid) => {
        const url = Config.weburl + 'product/productmaster/issue'
        const useraction = 'listissue'
        const reqBody = {
            searchtext: "",
            paginationinfo: {
                pageno: 1,
                pagelimit: "*",
                filter: {},
                sort: {}
            },
            productid: productid
        }

        await setReqParams(url, reqBody)
        await IISMethods.listData(useraction)

        if (resBody.status === 200) {
            getCurrentState().masterDataList["issuemaster"] = resBody.data
            await props.setProps({ masterDataList: getCurrentState().masterDataList })
        }
        else {
            getCurrentState().masterDataList["issuemaster"] = []
            await props.setProps({ masterDataList: getCurrentState().masterDataList })
        }
    }

    //form value prepare - onblut & onclick
    const handleFormData = async (type, key, value, fieldtype, fieldkey, fieldvalue) => {

        if (type === 'image' || type === 'file' || type === 'svg-file') {
            if (key === "images") {
                if (fieldtype !== undefined) {
                    let tempArrList = getCurrentState().formData[key]
                    tempArrList = tempArrList.filter((data, index) => index !== fieldtype)
                    getCurrentState().formData[key] = tempArrList
                }
                else {
                    let tempArrList = getCurrentState().formData[key] || []
                    for (const index of Object.keys(value)) {
                        if (typeof (value) == 'string') {
                            tempArrList.push(value[index])
                        }
                        else {
                            tempArrList.push(await IISMethods.fileToBase64(value[index]))
                        }
                    }
                    getCurrentState().formData[key] = tempArrList
                }
            }
            else {
                getCurrentState().formData[key] = await IISMethods.fileToBase64(value)
            }
        }
        else if (type === 'input-textarea') {
            if (key === 'comment') {
                getCurrentState().formData[key] = value
            }
            else {
                getCurrentState().formData[key] = value
            }
        }
        //when data selected from add item modal JENIL 23/8/2023 12:48 PM
        else if (type === 'selectproduct') {
            const fieldobj = JsCall.getobjectfromformdata(getCurrentState().rightSidebarFormData, key)
            let serialnumber = value?.serialnumber || ""
            let getObjFromArray = IISMethods.getobjectfromarray(getCurrentState().masterDataList[fieldobj.masterdata], 'serialnumber', serialnumber)
            if (key === 'productid') {
                let productData = {}
                //when add issue of product JENIL 23/8/2023 12:50 PM
                if (value?.issue?.length > 0) {
                    productData.issue = value?.issue
                }
                getCurrentState().formData['productdata'] = []
                try {
                    productData[fieldobj.formdatafield] = IISMethods.getobjectfromarray(getCurrentState().masterDataList[fieldobj.masterdata], 'serialnumber', serialnumber)[fieldobj.masterdatafield]
                    productData['serialnumber'] = serialnumber
                    productData[key] = IISMethods.getobjectfromarray(getCurrentState().masterDataList[fieldobj.masterdata], 'serialnumber', serialnumber)[fieldobj.field]
                }
                catch {
                    delete productData[fieldobj.formdatafield]
                    delete productData[key]
                }
                if (serialnumber !== Config.dummyserialno) {
                    if (fieldobj.extrafield) {
                        fieldobj.extrafield.map((item) => {
                            productData[item] = getObjFromArray[item] || ""
                        })
                    }
                }
                getCurrentState().formData['productdata'] = [productData]
                if (serialnumber === Config.dummyserialno) {
                    getCurrentState().formData['productsubcategoryid'] = Config.othersubcategory
                    getCurrentState().formData['productsubcategory'] = 'Other'
                    getCurrentState().formData['productid'] = ''
                }
            }
        }
        else if (type === 'dropdown') {
            const fieldobj = JsCall.getobjectfromformdata(getCurrentState().rightSidebarFormData, key)
            let getObjFromArray = IISMethods.getobjectfromarray(getCurrentState().masterDataList[fieldobj.masterdata], 'serialnumber', value)
            if (key === 'productid') {
                let productData = {}
                getCurrentState().formData['productdata'] = []
                if (fieldobj.masterdataarray) {
                    productData[key] = value ? value : ''
                }
                else {
                    try {
                        productData[fieldobj.formdatafield] = IISMethods.getobjectfromarray(getCurrentState().masterDataList[fieldobj.masterdata], 'serialnumber', value)[fieldobj.masterdatafield]
                        productData['serialnumber'] = value
                        productData[key] = IISMethods.getobjectfromarray(getCurrentState().masterDataList[fieldobj.masterdata], 'serialnumber', value)[fieldobj.field]
                    }
                    catch {
                        delete productData[fieldobj.formdatafield]
                        delete productData[key]
                    }
                }
                if (value !== Config.dummyserialno) {
                    if (fieldobj.extrafield) {
                        fieldobj.extrafield.map((item) => {
                            productData[item] = getObjFromArray[item]
                        })
                    }
                }
                getCurrentState().formData['productdata'] = [productData]
                if (value === Config.dummyserialno) {
                    getCurrentState().formData['productsubcategoryid'] = Config.othersubcategory
                    getCurrentState().formData['productsubcategory'] = 'Other'
                    getCurrentState().formData['productid'] = ''
                }
            }
            else {
                if (fieldobj.masterdataarray) {
                    getCurrentState().formData[key] = value ? value : ''
                }
                else {
                    try {
                        getCurrentState().formData[fieldobj.formdatafield] = IISMethods.getobjectfromarray(getCurrentState().masterDataList[fieldobj.masterdata], '_id', value)[fieldobj.masterdatafield]
                        getCurrentState().formData[key] = value
                    }
                    catch {
                        delete getCurrentState().formData[fieldobj.formdatafield]
                        delete getCurrentState().formData[key]
                    }
                }
                if (fieldobj.extrafield) {
                    fieldobj.extrafield.map((item) => {
                        getCurrentState().formData[item] = getObjFromArray[item]
                    })
                }
            }
        }

        else if (type === "add-remove-star") {
            if (fieldtype === 'tags') {
                let tagdata = IISMethods.getindexfromarray(getCurrentState().formData[key][fieldkey][fieldtype], 'tagid', value)
                if (tagdata === -1) {
                    let tagdata = {}
                    tagdata['tag'] = fieldvalue
                    tagdata['tagid'] = value
                    getCurrentState().formData[key][fieldkey][fieldtype].push(tagdata)
                }
                else {
                    getCurrentState().formData[key][fieldkey][fieldtype] = getCurrentState().formData[key][fieldkey][fieldtype].filter(item => item.tagid !== value)
                }
            }
            else {
                if (getCurrentState().formData[key][fieldkey]['stars'] == 1 && value == 0) {
                    getCurrentState().formData[key][fieldkey]['stars'] = value
                }
                else {
                    getCurrentState().formData[key][fieldkey]['stars'] = value + 1
                }
            }

            let b = getCurrentState().masterDataList.maintenrating[fieldkey]
            let a = IISMethods.getCopy(b.tagdata.find(item => item['stars'] === getCurrentState().formData[key][fieldkey]['stars']))

            if (a) {
                getCurrentState().formData['ratingbtnlink'] = a.ratingbtnlink
                getCurrentState().formData['ratingbtnname'] = a.ratingbtnname
            }
            else {
                delete getCurrentState().formData['ratingbtnlink']
                delete getCurrentState().formData['ratingbtnname']
            }
        }
        else if (type === 'radio') {
            let obj = JsCall.getobjectfromformdata(getCurrentState().rightSidebarFormData, key)
            let findRecord = IISMethods.getobjectfromarray(getCurrentState().masterData[obj.masterdata], 'value', value)
            getCurrentState().formData[key] = value
            getCurrentState().formData[obj.masterdata] = findRecord.label
        }
        else {
            getCurrentState().formData[key] = value
        }

        let fieldobj = JsCall.getobjectfromformdata(getCurrentState().rightSidebarFormData, key)
        if (fieldobj.resetfield) {
            // debugger
            for (let resetfield of fieldobj.resetfield) {
                let obj = JsCall.getobjectfromformdata(getCurrentState().rightSidebarFormData, resetfield)
                if (obj.type === 'dropdown') {
                    getCurrentState().formData[resetfield] = ''
                }
                else if (obj.type === 'input-textarea') {
                    getCurrentState().formData[resetfield] = ''
                }
                else {
                    getCurrentState().formData[resetfield] = ''
                }
            }
        }

        await props.setProps({ formData: IISMethods.getCopy(getCurrentState().formData) })

        // JsCall.validateForm(getCurrentState().formData, getCurrentState().rightSidebarFormData, key)
        const obj = JsCall.getobjectfromformdata(getCurrentState().rightSidebarFormData, key)

        if (obj.onchangefill) {
            for (const field of obj.onchangefill) {
                const obj2 = JsCall.getobjectfromformdata(getCurrentState().rightSidebarFormData, field)
                await getMasterData(1, obj2, getCurrentState().formData)
            }
        }
    }

    //edit button formdata set - props set 
    const setFormData = async (id, jsoncase, modalname) => {
        getCurrentState().rightSidebarFormData = props.masterjson(jsoncase)
        var tempFormData = {}
        if (id && (modalname === "withdrawmodal" || modalname === "reopenmodal")) {
            tempFormData = IISMethods.getCopy(IISMethods.getobjectfromarray(getCurrentState().data, '_id', id))
        }
        else {
            //formdata set 
            getCurrentState().rightSidebarFormData && getCurrentState().rightSidebarFormData.map((data) => {
                return data.formfields.map((fields) => {
                    if (fields.type === 'radio') {
                        if (fields.field !== 'priorityid') {
                            tempFormData[fields.field] = fields.radiooptions[0].value
                        }
                    }
                    else if (fields.type === 'image') {
                        tempFormData[fields.field] = []
                    }
                    else if (fields.type === 'dropdown') {
                        if (fields.masterdataarray) {
                            if (fields.masterdata && !fields.masterdataarray) {
                                if (fields.masterdatadependancy || !getCurrentState().masterData[fields.masterdata])
                                    getMasterData(1, fields, getCurrentState().formData)
                            }
                            else if (fields.masterdata && fields.masterdataarray && !getCurrentState().masterData[fields.masterdata]) {
                                var array = []
                                for (const object of fields.masterdataarray) {
                                    array.push({
                                        label: object,
                                        value: object
                                    })
                                }
                                getCurrentState().masterData[fields.masterdata] = array
                                props.setProps({ masterData: IISMethods.getCopy(getCurrentState().masterData) })
                            }
                        }
                        else {
                            tempFormData[fields.field] = ''
                            tempFormData[fields.formdatafield] = ''
                        }
                    }
                })
            }
            )
        }

        // for withdrawmodal open setdata redux srushti
        if (modalname === "withdrawmodal") {
            tempFormData['withdrawrequest'] = 1
        }
        // for reopenmodal open setdata redux srushti
        else if (modalname === "reopenmodal") {
            // tempFormData['taskid'] = IISMethods.getobjectfromarray(getCurrentState().data, '_id', id).taskactionarray[0].taskid
            tempFormData['reopenmaintenance'] = 1
        }
        else if (modalname === "maintenancemodal") {
            tempFormData["categoryid"] = Config.categoryid.maintenance
            tempFormData["category"] = "Maintenance"
            tempFormData["assignmentforid"] = Config.assignmentfor.room
            tempFormData["assignmentfor"] = "Room"
            tempFormData["propertyid"] = getCurrentState().reservationDetails.propertyid
            tempFormData["propertyname"] = getCurrentState().reservationDetails.propertyname
            tempFormData["roomnoid"] = getCurrentState().reservationDetails.roomnoid
            tempFormData["productid"] = Config.dummyid
            tempFormData["product"] = 'Other Product'
            tempFormData["departments"] = [{
                departmentid: Config.departments.maintenance,
                department: 'maintenance'
            }]
            tempFormData["details"] = [{
                roomnoid: getCurrentState().reservationDetails.roomnoid,
                roomno: getCurrentState().reservationDetails.roomno
            }]
        }
        await props.setProps({ formData: IISMethods.getCopy(tempFormData) })
        //formdata set 

        //error handling
        for (const data of getCurrentState().rightSidebarFormData) {
            for (const fields of data.formfields) {
                JsCall.handleError('form-' + fields.field, false)
            }
        }
        //error handling
        if (modalname === 'maintenancemodal') {
            for (const data of getCurrentState().rightSidebarFormData) {
                for (const fields of data.formfields) {
                    if (fields.masterdata && !fields.masterdatadependancy) {
                        await getMasterData(1, fields, tempFormData)
                        if (fields.field === 'priorityid') {
                            let tempObj = IISMethods.getCopy(getCurrentState().formData)
                            if (fields.defaultselected) {
                                let index = IISMethods.getindexfromarray(getCurrentState().masterData[fields.masterdata], 'value', getCurrentState().formData[fields.field])
                                // let masterdataindex = tempObj._id && (index !== -1) ? index : fields.field === 'priorityid' ? getCurrentState().masterData[fields.masterdata].length - 1 : 0
                                //select high priority default selected JENIL 23/8/2023 1:17 PM
                                let masterdataindex = tempObj._id && (index !== -1) ? index : fields.field === 'priorityid' ? 0 : 0
                                tempObj[fields.field] = getCurrentState().masterData[fields.masterdata][masterdataindex].value
                                tempObj[fields.masterdata] = getCurrentState().masterData[fields.masterdata][masterdataindex].label
                            }

                            await props.setProps({ formData: tempObj })
                        }
                    }
                }
            }
        }

        handleGrid(true, 'modal', modalname, 1)

        //select picker data call
        for (const data of getCurrentState().rightSidebarFormData) {
            for (const fields of data.formfields) {

                if (fields.masterdata && !fields.masterdataarray) {
                    if (fields.masterdatadependancy || !getCurrentState().masterData[fields.masterdata]) //
                        await getMasterData(1, fields, getCurrentState().formData) //

                }
                else if (fields.masterdata && fields.masterdataarray && !getCurrentState().masterData[fields.masterdata]) { //
                    var array = []
                    for (const object of fields.masterdataarray) {
                        array.push({
                            label: object,
                            value: object
                        })
                    }
                    getCurrentState().masterData[fields.masterdata] = array
                    await props.setProps({ masterData: IISMethods.getCopy(getCurrentState().masterData) }) //
                }
            }

            if (modalname === "maintenanceratingmodal") {
                tempFormData['taskid'] = id
                tempFormData['taskguestrating'] = []
                getCurrentState().masterDataList['maintenrating'].map((data, index) => {
                    let tempdata = {}
                    tempdata['ratingid'] = data._id
                    tempdata['stars'] = 0
                    tempdata['rating'] = data.rating
                    tempdata['comments'] = data.comments ? data.comments : ''
                    tempdata['tags'] = []
                    return tempFormData['taskguestrating'].push(tempdata)
                })
                await props.setProps({ formData: tempFormData })
            }
        }
    }

    // for handle filter data field srusthi 14-6-23
    const handleFilterData = async (type, key, value, formfield) => {
        if (type === 'multipleselectdropdown') {
            const fieldobj = JsCall.getobjectfromformdata(getCurrentState().rightSidebarFormData, key)
            try {
                if (value.length > 0) {
                    value = value.map((fieldid) => {

                        if (key === 'taskstatustype') {
                            return IISMethods.getobjectfromarray(getCurrentState().masterDataList[fieldobj.masterdata], '_id', fieldid)['statustype']
                        }
                        else if (key === 'priorityid') {
                            return IISMethods.getobjectfromarray(getCurrentState().masterDataList[fieldobj.masterdata], '_id', fieldid)['_id']
                        }
                    })
                }
            }
            catch {
                value = []
            }
            getCurrentState().filterData[key] = value
        }
        else if (type === 'filter-reset-data') {
            delete getCurrentState().oldFilterData['taskstatustype']
            delete getCurrentState().oldFilterData['priorityid']
            delete getCurrentState().oldFilterData['fromdate']
            delete getCurrentState().oldFilterData['todate']
            delete getCurrentState().filterData['taskstatustype']
            delete getCurrentState().filterData['priorityid']
            delete getCurrentState().filterData['fromdate']
            delete getCurrentState().filterData['todate']
            //when resetfield remove defaultvisibility of "todate" JENIL 28/6/2023 7:43 PM
            getCurrentState().rightSidebarFormData[0].formfields[3].defaultvisibility = false
        }
        else if (type === 'daterangepicker') {
            if (value) {
                let fromdate = IISMethods.ConvertDateObjTOISOString(IISMethods.setStartTimeOfDay(value[0]))
                let todate = IISMethods.ConvertDateObjTOISOString(IISMethods.setEndTimeOfDay(value[1]))
                getCurrentState().filterData['fromdate'] = value ? fromdate : ''
                getCurrentState().filterData['todate'] = value ? todate : ''
            }
            else if (!value) {
                delete getCurrentState().filterData['fromdate']
                delete getCurrentState().filterData['todate']
            }
            else {
                getCurrentState().filterData = {}
            }
        }
        else if (type === "datepicker") {
            //datepicker type if value has then set otherwise blank JENIL 28/6/2023 6:33 PM
            if (key === 'fromdate') {
                getCurrentState().filterData[key] = value ? IISMethods.ConvertDateObjTOISOString(IISMethods.setStartTimeOfDay(value)) : ""
                //todate defaultvisibillity hide/show when from date
                getCurrentState().rightSidebarFormData[0].formfields[3].defaultvisibility = value ? true : false
            } else {
                getCurrentState().filterData[key] = value ? IISMethods.ConvertDateObjTOISOString(IISMethods.setEndTimeOfDay(value)) : ""
            }
        }
        else {
            getCurrentState().filterData[key] = value
        }
        //when reset field, fields should be reset JENIL 28/6/2023 6:33 PM
        if (formfield?.resetfield) {
            formfield.resetfield.forEach((item) => {
                getCurrentState().filterData[item] = ""
            })
        }
        await props.setProps({ filterData: IISMethods.getCopy(getCurrentState().filterData) })
    }

    //click handle filter button click JENIL 28/6/2023 7:12 PM
    const handleAddFilterButtonClick = () => {
        if (JsCall.validateForm(getCurrentState().filterData, getCurrentState().rightSidebarFormData)) {
            IISMethods.notify("Fill all required data", 2)
        } else {
            handleGrid(false, 'modal', 'maintenancefiltermodal', 0)
            handleApplyFilterData()
            getList()
        }
    }
    // jatin 14-08-2023 
    const handleApplyFilterButtonClick = async (e, type) => {
        if (e) e.preventDefault()
        if (type === 'removevalue') {
            getCurrentState().filterData['searchtext'] = ''
            await props.setProps({ pageNo: 1, oldFilterData: IISMethods.getCopy(getCurrentState().filterData) })
            await props.clearData()
            await getList()
        }
        else {
            await props.setProps({ pageNo: 1, oldFilterData: IISMethods.getCopy(getCurrentState().filterData) })
            await props.clearData()
            await getList()
        }
    }

    // jatin 15-08-2023 11:53 PM
    const handleSerachClose = async () => {
        getCurrentState().filterData["searchtext"] = " "
        await props.setProps({ filterData: IISMethods.getCopy(getCurrentState().filterData), pageNo: 1 })
        await getList()

    }

    // for handle apply button  filter data store in old filter data srusthi 14-6-23
    const handleApplyFilterData = async () => {
        await props.setProps({ oldFilterData: IISMethods.getCopy(getCurrentState().filterData) })
    }

    // for set button old filter data store in filter data srusthi 14-6-23
    const setFilterData = async (jsoncase, modalname) => {
        getCurrentState().rightSidebarFormData = props.masterjson(jsoncase)
        //when filter modal open fromdate is not null then show "todate" defaultvisibility true JENIL 28/6/2023 7:23 PM
        if (modalname === "maintenancefiltermodal") {
            if (getCurrentState().filterData["fromdate"]) {
                getCurrentState().rightSidebarFormData[0].formfields[3].defaultvisibility = true
            }
        }
        handleGrid(true, 'modal', modalname, 1)
        await props.setProps({ filterData: IISMethods.getCopy(getCurrentState().oldFilterData) })

        //select picker data call
        for (const data of getCurrentState().rightSidebarFormData) {
            for (const fields of data.formfields) {
                if (fields.masterdata && !fields.masterdataarray) {
                    if (fields.masterdatadependancy || !getCurrentState().masterData[fields.masterdata]) //
                        await getMasterData(1, fields, getCurrentState().filterData) //
                }
            }
        }
    }

    // 2-3-23 srushti for maintenance progress data 
    const setSecondaryFormData = async (id) => {
        var tempFormData = {}
        tempFormData = IISMethods.getCopy(IISMethods.getobjectfromarray(getCurrentState().data, '_id', id))
        await props.setProps({ secondarySourceMasterData: tempFormData })
        //formdata set
        //func of pagechage
    }

    //for open modal - recordinfo - create button
    const handleGrid = (id, type, key, value, editeDataIndex) => {
        if (type === 'modal') {
            if (value) {
                getCurrentState().modal[key] = id
            }
            else {
                delete getCurrentState().modal[key]
            }
            props.setProps({ modal: IISMethods.getCopy(getCurrentState().modal) })
        }
        else { }
    }

    //form data prepare - state set - button click event 
    const handleAddButtonClick = async (e, modalname, link) => {
        e.preventDefault()
        Object.keys(getCurrentState().formData).map((key) => {
            if (typeof getCurrentState().formData[key] === 'string') {
                getCurrentState().formData[key] = getCurrentState().formData[key].trim()
            }
        })

        if (modalname === 'maintenancemodal' && (getCurrentState().formData.productsubcategoryid === '' || getCurrentState().formData.productsubcategoryid === undefined) && (getCurrentState().formData.productdata === undefined || !getCurrentState().formData?.productdata?.length)) {
            IISMethods.notify("Fill all required data", 2)
        }
        else if ((modalname === 'maintenancemodal' && getCurrentState().formData.productsubcategoryid !== Config.othersubcategory) && (getCurrentState().formData.productdata === undefined || !getCurrentState().formData?.productdata?.length)) {
            IISMethods.notify("Fill all required data", 2)
        }
        else if ((modalname === 'maintenancemodal' && getCurrentState().formData.productsubcategoryid === Config.othersubcategory) && (getCurrentState().formData.comment === '' || getCurrentState().formData.comment === undefined)) {
            IISMethods.notify("Please add description", 2)
        }
        else {
            //to pass the fields with empty value
            let tempdata = IISMethods.getCopy(getCurrentState().formData)

            if (modalname === 'maintenancemodal' && tempdata['productdata'][0]['serialnumber'] === Config.dummyserialno) {
                tempdata['productdata'] = ''
            }

            if (getCurrentState().formData._id) {
                if (modalname === 'reopenmodal') {
                    await reopenData(tempdata, modalname)
                }
                else {
                    await updateData(tempdata, modalname)
                }
            }
            else {
                await addData(tempdata, modalname, link)
            }
            //reset formdata variable after api called JENIL 28/6/2023 5:25 PM
            getCurrentState().rightSidebarFormData.map((data) =>
                data.formfields.map((fields) => {
                    if (!document.getElementById('form-' + fields.field)) {
                        if (fields.type === 'radio')
                            getCurrentState().formData[fields.field] = fields.radiooptions[0].value
                        else if (fields.type === 'dropdown') {
                            if (fields.masterdataarray) {
                                getCurrentState().formData[fields.field] = ''
                            }
                            else {
                                delete getCurrentState().formData[fields.field]
                                delete getCurrentState().formData[fields.formdatafield]
                            }
                        }
                        else
                            getCurrentState().formData[fields.field] = fields.defaultvalue || ''
                    }
                    if (fields.istablefield) {
                        delete getCurrentState().formData[fields.field]
                        delete getCurrentState().formData[fields.formdatafield]
                    }
                })
            )
            await props.setProps({ formData: IISMethods.getCopy(getCurrentState().formData) })
        }
    }

    //insert operation
    const addData = async (reqData, modalName, link) => {
        setMaintenancetloder(true)
        await props.setLoading({ loading: 1 })
        const url = Config.weburl + getCurrentState().rightSidebarFormData[0].apipath
        const useraction = 'guestrequest'

        await setReqParams(url, reqData)
        await IISMethods.addData(useraction, '', true)
        if (resBody.status === 200) {

            await props.setProps({ pageNo: 1 })
            await props.clearData()
            handleGrid(false, 'modal', modalName, 0)
            getList()
            await props.setLoading({ loading: 0 })
            setMaintenancetloder(false)
            //if rating submit get link then redirect to new page JENIL 12/9/2023 5:40 PM
            if (link) window.open(link, '_blank')
        }
        else {
            setMaintenancetloder(false)
            await props.setLoading({ loading: 0 })
        }
    }

    //update operation
    const updateData = async (reqData, modalName) => {
        setMaintenancetloder(true)
        await props.setLoading({ loading: 1 })
        const url = Config.weburl + 'task/request/withdraw'
        const useraction = 'listhousekeeping'
        await setReqParams(url, reqData)
        await IISMethods.updateData(useraction)
        if (resBody.status === 200) {
            handleGrid(false, 'modal', modalName, 0)
            await props.setProps({ pageNo: 1 })
            await props.clearData()
            getList()
            await props.setLoading({ loading: 0 })
            setMaintenancetloder(false)
        }
        else {
            await props.setLoading({ loading: 0 })
            setMaintenancetloder(false)
        }
    }

    //reopen model maintenance operation
    const reopenData = async (reqData, modalName) => {
        // debugger
        await props.setLoading({ loading: 1 })
        const url = Config.weburl + 'task/reopenmaintenancetask'
        const useraction = 'listhousekeeping'
        await setReqParams(url, reqData)
        await IISMethods.addData(useraction, '', true)
        if (resBody.status === 200) {
            handleGrid(false, 'modal', modalName, 0)
            await props.setProps({ pageNo: 1 })
            await props.clearData()
            getList()
            await props.setLoading({ loading: 0 })
        }
        else {
            await props.setLoading({ loading: 0 })
        }
    }

    //delete operation
    const deleteData = async (reqData) => {
        await props.setLoading({ loading: 1 })
        const url = Config.weburl + props.apipagename + '/delete'
        const useraction = 'delete' + props.pagename + 'data'
        await setReqParams(url, reqData)
        await IISMethods.deleteData(useraction)
        if (resBody.status === 200) {
            await props.setProps({ pageNo: 1 })
            await props.clearData()
            await getList()
            await props.setLoading({ loading: 0 })
        }
        else {
            await props.setLoading({ loading: 0 })
        }
    }

    return (
        <>
            <MaintenanceView
                handleDeleteData={handleDeleteData}
                handleScroll={handleScroll}
                handleFormData={handleFormData}
                setFormData={setFormData}
                handleAddButtonClick={handleAddButtonClick}
                handleGrid={handleGrid}
                setSecondaryFormData={setSecondaryFormData}
                reopenData={reopenData}
                page={page}
                setPage={setPage}
                handleFilterData={handleFilterData}
                setFilterData={setFilterData}
                handleApplyFilterData={handleApplyFilterData}
                getList={getList}
                handleAddFilterButtonClick={handleAddFilterButtonClick}
                handleApplyFilterButtonClick={handleApplyFilterButtonClick}
                handleSerachClose={handleSerachClose}
                getIssueList={getIssueList}
                setMaintenancetloder={setMaintenancetloder}
                maintenancetloder={maintenancetloder}
            />
        </>
    )
}

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    clearData: (payload) => dispatch(clearData(payload)),
    setProps: (payload) => dispatch(setProps(payload)),
    setLoading: (payload) => dispatch(setLoading(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceController)

