
import React, { useEffect, useState } from 'react'
import { Config, IISMethods, resBody, setReqParams } from '../config/IISMethods'
import 'rsuite/dist/rsuite.css'
import { store } from '..'

export default function LostProgress(props) {
    // srushti
    const getCurrentState = () => {
        return store.getState()['root']
    }

    // srushti color 
    const getColor = (colorCode, opacity) => {
        let r = colorCode?.r || 0
        let g = colorCode?.g || 0
        let b = colorCode?.b || 0
        let a = opacity ? opacity : colorCode?.a
        return `rgba(${r}, ${g}, ${b}, ${a})`
    }

    try {
        return (
            <>
                <div className="body-content">
                    <div className="col-12">
                       
                            {/* <div className="request-card-body"> */}
                            <div className="col-12 mt-3 request-item-progress px-0">
                                {getCurrentState().secondarySourceMasterData?.length > 0 && getCurrentState().secondarySourceMasterData?.map((item) => {

                                    return (
                                        <>
                                            <span className='text-right d-block'>
                                                {(item.status !== '' || item.status !== undefined) && <span className='d-block'>{item.status}</span>}
                                                {item.createddate && <p className='d-block'> {IISMethods.GetDateFormat(item.createddate)} | {IISMethods.GetTimeFormat(item.createddate)} </p>}
                                            </span>

                                            <span className='d-block mt-3'>
                                                {(item.itemlostfound !== '' || item.itemlostfound !== undefined) && <span>{item.itemlostfound}</span>}

                                                {(item.lostcomment !== '' || item.lostcomment !== undefined) &&
                                                    <div className='mainten-req-sentence-desc'>
                                                        <p>{item.lostcomment}</p>
                                                    </div>}
                                            </span>

                                            {item.lostdetails?.map(item => item['area'] || item['roomno']).length > 0 &&
                                                <>
                                                    <span className='lostat mt-3 d-block'> Lost At  : </span>
                                                    <div className='progress-desc mt-2 progress-desc mt-2 d-flex flex-wrap gap-10'>
                                                        {/* <p> <span className='lostat'> Lost At  :</span> {item.lostdetails?.map(item => item['area'] || item['roomno']).join(', ')} </p> */}

                                                        {item.lostdetails?.map((d, i) => {
                                                            let a = d.areatypecolor
                                                            return (
                                                                <>
                                                                    {d.area?.length > 0 &&
                                                                        <label
                                                                            className='treee-label-text content-center px-2 border-radius-5 '
                                                                            style={{
                                                                                borderWidth: 2,
                                                                                borderStyle: 'solid',
                                                                                borderColor: getColor(a, 1),
                                                                                color: getColor(a, 1),
                                                                                backgroundColor: getColor(a, 0.1)
                                                                            }}
                                                                        >{d.area}</label>
                                                                    }
                                                                </>
                                                            )
                                                        })
                                                        }

                                                        {item.lostdetails?.map((c, i) => {
                                                            let b = c.roomtypecolor
                                                            return (
                                                                <>
                                                                    {c.roomno?.length > 0 &&
                                                                        < label
                                                                            className='col-2 treee-label-text content-center px-2 border-radius-5 '
                                                                            style={{
                                                                                borderWidth: 2, borderStyle: 'solid',
                                                                                borderColor: getColor(b, 1),
                                                                                color: getColor(b, 1),
                                                                                backgroundColor: getColor(b, 0.1)
                                                                            }}
                                                                        >{c.roomno}</label>
                                                                    }
                                                                </>
                                                            )
                                                        })
                                                        }
                                                    </div>
                                                </>
                                            }
                                        </>
                                    )
                                })
                                }
                            </div>
                            {/* </div> */}

                            <div className="request-box">
                                <div className="request-item">
                                    <span>Lost In Progress</span>
                                </div>
                                <div className="wrapper mt-3">
                                    <ul className="timeline timeline-align-left">

                                        {/* for progressbar and show progress data  srushti*/}
                                        {
                                            getCurrentState().secondarySourceMasterData[0]?.timeline?.map((item, index) => {
                                                return (
                                                    <>
                                                        <li className={`timeline-item ${item.stagestatus === 1 && ''}`} key={index}>
                                                            <span className={`timeline-item-tail ${(getCurrentState().secondarySourceMasterData[0].timeline[index + 1]?.stagestatus === 1 && item.stagestatus === 1) ? 'completed' : ''}`}></span>
                                                            <span className={`timeline-item-dot ${item.stagestatus === 1 && 'completed'}`}></span>
                                                            <div className="timeline-item-content">
                                                                <div className='d-flex gap-10 align-center'>
                                                                    {(item.title !== '' || item.title !== undefined) && <span className='progress-title'>{item.title}</span>}
                                                                    {(item.date) && <span className='progress-date'>{IISMethods.GetDateFormat(item.date)}</span>}
                                                                </div>
                                                                <div className='pt-0 pl-3 pb-3'>
                                                                    {(item.subtitle !== '' || item.subtitle !== undefined) && <div className="progress-detail">{item.subtitle}</div>}
                                                                    {item.date && <div className="progress-date">{IISMethods.GetDateFormat(item.date)} {IISMethods.GetTimeFormat(item.date)}</div>}
                                                                </div>
                                                                <div className=''>
                                                                    {(item.process?.length > 0) && item.process.map((items, index) => {
                                                                        return (
                                                                            <div className='pb-2 pl-3'>
                                                                                {(items.title !== '' || items.title !== undefined) && <div className="progress-detail" key={index}>{items.title}</div>}
                                                                                {items.date && <div className="progress-date">{IISMethods.GetDateFormat(items.date)} {IISMethods.GetTimeFormat(items.date)}</div>}
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        
                    </div>
                </div>
            </>
        )
    }
    catch (e) {
        console.log(e)
        return <></>
    }
}



