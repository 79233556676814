import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { IISMethods } from '../config/IISMethods'
import { store } from '../index'
import Camera from '../assets/images/camera.png'


const HomeViewPhoto = (props) => {
    const getCurrentState = () => {
        return store.getState()['root']
    }

    const capturePhoto = () => {

        return <>

                <div className='col-12'>
                    <label className="submit-btn-sec">
                        <input
                            accept="image/*"
                            className='input d-none'
                            id="icon-button-file"
                            type="file"
                            capture="environment"
                            onChange={(e) => {
                                props.handleFormData("image", "vehicleimages", e.target.files[0], props.fieldtype, props.fieldkey, props.fieldvalue)
                            }}
                        />
                        <div className="btn" htmlFor='icon-button-file'>
                            Capture Photo Of Vehicle
                        </div>
                    </label>
            </div>
          

        </>
    }

    // const handleRemoveImage = async (id) => {
    //     const temparr = getCurrentState().formData.vehicleimages.filter((temp) => temp._id !== id)
    //     props.handleFormData('image', 'vehicleimages', temparr, id)// remove particular image and pass to the api
    // }
    return (
        <section className="stayinformation-sec section-spacing">
         
                {(getCurrentState().reservationDetails?.sampleimages?.length > 0 && !getCurrentState().formData.vehicleimages?.length) &&
                    <>
                        <div className="row">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12 col-md-9 col-lg-12 mx-auto text-center">
                                        <div className="stay-information-info info-data">
                                            <h1 className="main-title text-blue">SAMPLE IMAGE</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            getCurrentState().reservationDetails?.sampleimages.length > 0 &&
                            <>
                                <div className="col-12 my-3">
                                    <div className="row ">
                                        <div className="col-12 col-md-9 col-lg-12 mx-auto">
                                            <div className="row">
                                                <div className="col-12 d-flex align-content-between flex-wrap">
                                                    {getCurrentState().reservationDetails?.sampleimages?.map((vehiclePhoto, index) => {
                                                        return (
                                                            <>
                                                                <div className='sample-img' key={vehiclePhoto._id + index}>
                                                                    <img src={vehiclePhoto.image} height="120px" width={"120px"} alt="Sample Image" />
                                                                </div>
                                                                {
                                                                    (index !== getCurrentState().reservationDetails?.sampleimages.length - 1) &&
                                                                    <div className="col-12">
                                                                        <div className="form-or">
                                                                            <p>OR</p>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </>
                }
                {
                    getCurrentState().formData.vehicleimages?.length > 0 &&
                    <>
                        <div className="row">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12 col-md-9 col-lg-6 mx-auto text-center">
                                        <div className="stay-information-info info-data">
                                            <h1 className="main-title text-blue">PREVIEW OF IMAGES</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mb-0">
                            <div className="row ">
                                <div className="col-12 col-md-9 col-lg-6 mx-auto">
                                    <div className="row">
                                        <div className="col-12 d-flex align-content-between flex-wrap">
                                            {getCurrentState().formData.vehicleimages.map(vehiclePhoto => {
                                                return <div className='preview-img'>
                                                    {/* <span className='close-vehicle-image' onClick={() => handleRemoveImage(vehiclePhoto._id)}>
                                                        <svg id="Icons" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="m7.293 16.707a1 1 0 0 0 1.414 0l3.293-3.293 3.293 3.293a1 1 0 0 0 1.414-1.414l-3.293-3.293 3.293-3.293a1 1 0 1 0 -1.414-1.414l-3.293 3.293-3.293-3.293a1 1 0 1 0 -1.414 1.414l3.293 3.293-3.293 3.293a1 1 0 0 0 0 1.414z" />
                                                        </svg>
                                                    </span> */}
                                                    <img src={vehiclePhoto.image} height="120px" width={"120px"} alt="Vehicle Image" />
                                                </div>
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* {props.hrLine} */}
                    </>
                }
                {capturePhoto()}
           
        </section >
    )
}

export default HomeViewPhoto


