import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { store } from '..'
import { Config, IISMethods, JsCall, resBody, setReqParams } from '../config/IISMethods'
import { clearData, setLoading, setLoadings, setProps } from '../redux/actions'
import HomeView from '../view/HomeView'
import MyStayView from '../view/MyStayView'
import MyHotelView from '../view/MyHotelView'
import ContactUsView from '../view/ContactUsView'
import useFirebase from '../ServiceWorker/useFirebase'
import VerificationDataView from '../view/VerificationDataView'
import { /*useSocketListener,*/ useSocket } from '../hooks/useSocket'
import PWA from '../ServiceWorker/PWA'
import useAPICall from '../hooks/useApiCall'

const initialItemQty = 1
// srushti
const HomeController = (props) => {
    const { getReservationDetails, getAccessToken, getMenus, getPropertyData } = useAPICall()
    const { handleInstallPWA } = PWA()
    const { socketEmit } = useSocket('')
    // for notification request
    const { requestPermission } = useFirebase()

    const getCurrentState = () => store.getState()['root']

    const [itemqty, setItemqty] = useState(initialItemQty)
    const pagename = IISMethods.getpagename()
    // let subdomainname = getQueryObj()['subdomainname']
    // let bookingid = getQueryObj()['bookingid']
    // let querystring = getQueryString()

    const [load, setLoad] = useState(false);

    //first call - default props set & grid call - getlist
    useEffect(() => {
        (async function () {
            await props.setProps({
                fieldOrder: [],
                newFieldOrder: [],
                nextpage: 0,
                pageNo: 1,
                pagelimit: 20,
                pageName: '',
                formName: '',
                sortData: {},
                formData: {},
                filterData: {},
                oldFilterData: {},
                modal: {},
                // rightSidebarFormData : [],
                masterData: {},
                masterDataList: {}
            })
            //(DO NOT CHANGE WITHOUT PERMISSION)charmi(06-09-2023 12:35)(listing api calling issue fix & install application modal open issue fix)
            await props.clearData()
            await getAccessToken()
            await getReservationDetails()
            if (getCurrentState().reservationDetails.ischeckin === 1) {
                await getPropertyData()
                await getMenus()
                if (getCurrentState().reservationDetails['isreservationexpired'] !== 1) {
                    await requestPermission()
                }
                if (pagename === 'home' || pagename === '') {
                    if (getCurrentState().propertyData.showvehicledetailmodal === 1 && (getCurrentState().reservationDetails?.vehicleinfocollected === undefined || getCurrentState().reservationDetails?.vehicleinfocollected === 0)) {//charmi(07-06-2023)(GTB-showvehicledetailmodal)
                        await setFormData(null, 'vehiclejson', 'vehicleInfoModal')
                    }
                    else if (getCurrentState().reservationDetails?.reservationratingsubmitted === undefined || getCurrentState().reservationDetails?.reservationratingsubmitted === 0) {
                        await setFormData(null, "vehiclerating", "vehicleratingModal")
                    }
                }
                if (pagename === "contactus") {
                    await getWifiDetails()
                }
            }
            //(DO NOT CHANGE WITHOUT PERMISSION)charmi(06-09-2023 12:35)(listing api calling issue fix & install application modal open issue fix)
            //setuid and subscribe for extend stay and late checkout socket JENIL 14/6/2023
            socketEmit('setuid', getCurrentState().reservationDetails._id)
            socketEmit('subscribe', IISMethods.getGuestInfo()['subdomainname'] + getCurrentState().reservationDetails._id)
        })()
        return () => socketEmit('unsubscribe', IISMethods.getGuestInfo()['subdomainname'] + getCurrentState().reservationDetails._id)
    }, [])

    //select picker printing 
    const printSelectPicker = (data, fieldobj) => {
        const timefrom = IISMethods.GetTimeFormat(data.checkouttimefrom, 1)
        const timeto = IISMethods.GetTimeFormat(data.checkouttimeto, 1)
        return (
            fieldobj.field === 'time' ? <span>{`${timefrom} - ${timeto} `} </span>
                :
                `${data[fieldobj.masterdatafield]}`
        )
    }

    // for loader
    const addLoaders = async (key, value) => {
        if (value === 1) getCurrentState().loadings[key] = value
        else delete getCurrentState().loadings[key]
        await props.setLoadings({ loadings: IISMethods.getCopy(getCurrentState().loadings) })
    }


    const getList = async (listpath, listuseraction, jsoncase, modalname) => {
        const url = Config.weburl + listpath
        const useraction = listuseraction

        let filter = {}

        Object.entries(getCurrentState().filterData).forEach(([key, value]) => {
            if (typeof (value) === 'number') {
                if (value !== 0)
                    filter[key] = value
            }
            else
                filter[key] = value
        })

        if (!getCurrentState().data.length) {
            await props.setProps({ pageNo: 1 })
        }

        const reqBody = {
            paginationinfo: {
                pageno: getCurrentState().pageNo,
                pagelimit: getCurrentState().pagelimit,
                filter: filter,
                sort: getCurrentState().sortData
            }
        }

        await setReqParams(url, reqBody)

        await IISMethods.listData(useraction)

        if (resBody.status === 200) {
            if (getCurrentState().pageNo === 1) {
                await props.clearData()
            }

            await props.setProps({
                data: resBody.data
            })

            if (resBody.data[0]) {
                setFormData(resBody.data[0]?._id, jsoncase, modalname)
            }
            else {
                setFormData(null, jsoncase, modalname)
            }
        }
        else {
        }
    }

    //contact us page loading JENIL 13/9/2023 1:26 PM
    const [contactUsLoad, setContactUsLoad] = useState(true);

    //  jatin 07-07-2023 11:15 Wifi details Api Called to use in Contace Us Page
    const getWifiDetails = async () => {
        setContactUsLoad(true);
        const url = Config.weburl + 'property/wifidetails'
        const useraction = 'listwifidetailsdata'
        const reqBody = {
            _id: getCurrentState().reservationDetails.propertyid,
        }
        await setReqParams(url, reqBody)
        await IISMethods.listData(useraction)

        if (resBody.status === 200) {
            await props.setProps({ masterData: { "wifidetails": resBody.data } })
            setContactUsLoad(false);
            setLoad(true)
        } else {
            setContactUsLoad(false);
        }
    }

    //for selectpicker data print and api call 
    const getMasterData = async (pageNo, fieldobj, formData, storemasterdatabyfield) => {
        var filter = {}
        var isdepent = 0
        if (fieldobj.dependentfilter) { //dependent filter set - object set
            Object.keys(fieldobj.dependentfilter).map(key => {
                const value = formData[fieldobj.dependentfilter[key]]
                if (value) {
                    isdepent = 1
                    filter[key] = value
                }
            })
        }

        if (fieldobj.staticfilter) { //static filter object assign
            Object.assign(filter, fieldobj.staticfilter)
        }

        const masterdatakey = (fieldobj.storemasterdatabyfield || storemasterdatabyfield ? fieldobj.field : fieldobj.masterdata)
        if (!fieldobj.masterdatadependancy || isdepent == 1) { //show only non dependent dropdown or dependent dropdown dependency fullfill
            const url = Config.weburl + fieldobj.apipath
            const useraction = 'list' + fieldobj.masterdata + 'data'
            filter = {
                ...IISMethods.getCopy(fieldobj.filter),
                ...filter,
            }
            var reqBody = {
                paginationinfo: {
                    pageno: pageNo,
                    pagelimit: '*',
                    filter: filter,
                    sort: {}
                }
            }
            await setReqParams(url, reqBody)
            await IISMethods.listData(useraction, true)
            if (resBody.status === 200) {
                getCurrentState().masterData = IISMethods.getCopy(getCurrentState().masterData)
                getCurrentState().masterDataList = IISMethods.getCopy(getCurrentState().masterDataList)
                if (pageNo === 1) {
                    getCurrentState().masterData[masterdatakey] = []
                    getCurrentState().masterDataList[masterdatakey] = []
                }
                resBody.data.map((data) =>
                    getCurrentState().masterData[masterdatakey].push({ label: printSelectPicker(data, fieldobj), value: data?._id, icon: data.svg })
                )
                getCurrentState().masterDataList[masterdatakey] = getCurrentState().masterDataList[masterdatakey].concat(resBody.data)
                await props.setProps({ masterData: getCurrentState().masterData, masterDataList: getCurrentState().masterDataList })

                if (resBody.nextpage)
                    await getMasterData(pageNo + 1, fieldobj, formData, storemasterdatabyfield)
            }
            else {
            }
        }
        else {
            delete getCurrentState().masterData[masterdatakey]
            delete getCurrentState().masterDataList[masterdatakey]
            await props.setProps({ masterData: IISMethods.getCopy(getCurrentState().masterData), masterDataList: IISMethods.getCopy(getCurrentState().masterDataList) })
        }
    }

    //form value prepare - onblut & onclick
    const handleFormData = async (type, key, value, fieldtype, fieldkey, fieldvalue, e, ratebtnname) => {

        if (type === 'checkbox' || type === 'switch') {
            getCurrentState().formData[key] = (value ? 1 : 0)
        }
        else if (type === 'image' || type === 'file' || type === 'svg-file') {
            const fieldobj = JsCall.getobjectfromformdata(getCurrentState().rightSidebarFormData, key)
            if (fieldtype == 'table') {
                if (!IISMethods.checkFiletype(value.name, fieldobj.filetypes)) {
                    IISMethods.notify("Invalid File Type", 2)
                    let inputelement = document.getElementById(`form-${fieldobj.field}`)
                    inputelement.value = ''
                    getCurrentState().formData[key][fieldvalue][fieldkey] = ''
                }
                else {
                    if (fieldkey === 'image') {
                        getCurrentState().formData[key] = [{
                            [fieldkey]: await IISMethods.fileToBase64(value)
                        }]
                    }
                    else {
                        getCurrentState().formData[key][fieldvalue][fieldkey] = await IISMethods.fileToBase64(value)
                    }
                }
            }
            else {
                if (!IISMethods.checkFiletype(value.name, fieldobj.filetypes)) {
                    IISMethods.notify("Invalid File Type", 2)
                    let inputelement = document.getElementById(`form-${fieldobj.field}`)
                    inputelement.value = ''
                    getCurrentState().formData[key] = ''
                }
                else {
                    getCurrentState().formData[key] = await IISMethods.fileToBase64(value)
                }
            }
        }
        else if (type === 'dropdown') {
            const fieldobj = JsCall.getobjectfromformdata(getCurrentState().rightSidebarFormData, key)
            let getObjFromArray = IISMethods.getobjectfromarray(getCurrentState().masterDataList[fieldobj.masterdata], '_id', value)

            if (fieldobj.masterdataarray) {
                getCurrentState().formData[key] = value ? value : ''
            }
            else {
                try {
                    getCurrentState().formData[fieldobj.formdatafield] = IISMethods.getobjectfromarray(getCurrentState().masterDataList[fieldobj.masterdata], '_id', value)[fieldobj.masterdatafield]
                    getCurrentState().formData[key] = value
                }
                catch {
                    delete getCurrentState().formData[fieldobj.formdatafield]
                    delete getCurrentState().formData[key]
                }
            }

            if (fieldobj.extrafield) {
                fieldobj.extrafield.map((item) => {
                    if (item === 'charge') {
                        getCurrentState().formData['latecheckoutcharges'] = `$${getObjFromArray[item]}`
                    }
                    else if (item === 'checkouttimeto') {
                        let currentdate = IISMethods.GetDateTimefromISOString(IISMethods.GetTZdatetime().toISOString()).toISOString()
                        let selecteddate = getObjFromArray[item]
                        getCurrentState().formData['latecheckouttime'] = currentdate.split('T')[0] + 'T' + selecteddate.split('T')[1]
                    }
                    else {
                        getCurrentState().formData[item] = getObjFromArray[item]
                    }
                })
            }
        }
        else if (type === 'number-input') {
            if (key === 'hours') {
                value = value !== '' ? parseInt(value) : 0
                getCurrentState().formData[key] = parseInt(value ? value : 0)
                let currenttime = IISMethods.GetDateTimefromISOString(IISMethods.GetTZdatetime().toISOString())
                currenttime.setHours(parseInt(currenttime.getHours()) + parseInt(getCurrentState().formData[key]), parseInt(currenttime.getMinutes()) + parseInt(getCurrentState().formData['minutes']), currenttime.getSeconds(), currenttime.getMilliseconds())
                if (getCurrentState().formData['tab'] === 1) {
                    getCurrentState().formData['datetime'] = IISMethods.GetDateTime(currenttime).toISOString()
                }
                else {
                    getCurrentState().formData['revisittime'] = IISMethods.GetDateTime(currenttime).toISOString()
                }
                // getCurrentState().formData[key] = value > 24 ? 24 : value < 0 ? 0 : value
            }
            else if (key === 'minutes') {
                // value = value !== '' ? parseInt(value) : 0
                value = value !== '' ? parseInt(value) : 0
                getCurrentState().formData[key] = value > 60 ? 60 : value < 0 ? 0 : value
                let currenttime = IISMethods.GetDateTimefromISOString(IISMethods.GetTZdatetime().toISOString())
                currenttime.setHours(parseInt(currenttime.getHours()) + parseInt(getCurrentState().formData['hours']), parseInt(currenttime.getMinutes()) + parseInt(getCurrentState().formData[key]), currenttime.getSeconds(), currenttime.getMilliseconds())
                if (getCurrentState().formData['tab'] === 1) {
                    getCurrentState().formData['datetime'] = IISMethods.GetDateTime(currenttime).toISOString()
                }
                else {
                    getCurrentState().formData['revisittime'] = IISMethods.GetDateTime(currenttime).toISOString()
                }
            }
            else {
                getCurrentState().formData[key] = parseInt(value ? value : 0)
            }
        }
        // extend stay counter inc/dec and date update srushti 10-4-2023
        else if (type === "counter") {
            if (value === "inc") {
                setItemqty(itemqty + 1)
                getCurrentState().formData.extendstaydetails[key] = itemqty + 1
                let checkoutdate = getCurrentState().formData.extendstaydetails[key] ? getTotalDayAndDate(getCurrentState().reservationDetails['checkoutdate'], getCurrentState().formData.extendstaydetails[key]) : ''
                getCurrentState().formData.extendstaydetails['checkoutdate'] = IISMethods.GetDT(checkoutdate)
            }
            else if (value === "dec" && itemqty > 1) {
                setItemqty(itemqty - 1)
                getCurrentState().formData.extendstaydetails[key] = itemqty - 1
                let checkoutdate = getCurrentState().formData.extendstaydetails[key] ? getTotalDayAndDate(getCurrentState().reservationDetails['checkoutdate'], getCurrentState().formData.extendstaydetails[key]) : ''
                getCurrentState().formData.extendstaydetails['checkoutdate'] = IISMethods.GetDT(checkoutdate)
            }
        }
        //  extend stay  date update and counter inc/dec srushti 10-4-2023
        else if (type === 'datepicker' || type === 'datetimepicker') {
            if (key === 'checkoutdate') {
                let days = IISMethods.GetDiffInDay(IISMethods.GetDT(getCurrentState().reservationDetails['checkoutdate']), IISMethods.GetDT(value))
                setItemqty(days)
                getCurrentState().formData.extendstaydetails['noofnight'] = days
                getCurrentState().formData.extendstaydetails[key] = value ? IISMethods.GetISOFromDTobj(value) : ''
            }
            else if (key === 'datetime' || key === 'revisittime') {
                getCurrentState().formData[key] = value ? IISMethods.GetISOFromDTobj(value) : ''
                let currenttime = IISMethods.GetDateTimefromISOString(IISMethods.GetTZdatetime().toISOString())
                let selectedtime = IISMethods.GetDateTimefromISOString(getCurrentState().formData[key])
                let difference = IISMethods.secondToHrsAndMins(((selectedtime - currenttime) / 1000))
                getCurrentState().formData['hours'] = difference.split(':')[0]
                getCurrentState().formData['minutes'] = difference.split(':')[1]
            }
            else {
                getCurrentState().formData[key] = value ? IISMethods.GetISOFromDTobj(value) : ''
            }
        }
        else if (type === 'timepicker') {
            getCurrentState().formData[key] = value ? IISMethods.GetISOFromTMobj(value) : ''
        }
        else if (type === 'multipletagpicker') {
            let dataindex = IISMethods.getindexfromarray(getCurrentState().formData[key], '_id', value)
            if (dataindex === -1) {
                let daysdata = {}
                daysdata['_id'] = value
                daysdata['name'] = fieldtype
                getCurrentState().formData[key].push(daysdata)
            }
            else {
                getCurrentState().formData[key] = getCurrentState().formData[key].filter(data => data?._id !== value)
            }
        }
        else if (type === 'multipleselectdropdown') {
            try {
                const fieldobj = JsCall.getobjectfromformdata(getCurrentState().rightSidebarFormData, key)
                value = value.map((fieldid) => {
                    return {
                        [fieldobj.formdatafield + 'id']: fieldid,
                        [fieldobj.formdatafield]: IISMethods.getobjectfromarray(getCurrentState().masterDataList[fieldobj.masterdata], '_id', fieldid)[fieldobj.masterdatafield]
                    }
                })
            }
            catch {
                value = []
            }
            getCurrentState().formData[key] = value
        }
        else if (type === "add-remove-star") {
            if (fieldtype === 'tags') {
                let tagdata = IISMethods.getindexfromarray(getCurrentState().formData[key][fieldkey][fieldtype], 'tagid', value)
                if (tagdata === -1) {
                    let tagdata = {}
                    tagdata['tag'] = fieldvalue
                    tagdata['tagid'] = value
                    getCurrentState().formData[key][fieldkey][fieldtype].push(tagdata)
                }
                else {
                    getCurrentState().formData[key][fieldkey][fieldtype] = getCurrentState().formData[key][fieldkey][fieldtype].filter(item => item.tagid !== value)
                }
            }
            else {
                if (getCurrentState().formData[key][fieldkey]['stars'] == 1 && value == 0) {
                    getCurrentState().formData[key][fieldkey]['stars'] = value
                }
                else {
                    getCurrentState().formData[key][fieldkey]['stars'] = value + 1
                }
                let b = getCurrentState().masterDataList[ratebtnname][fieldkey]
                let a = b.tagdata.find(item => item['stars'] === getCurrentState().formData[key][fieldkey]['stars'])

                if (a) {
                    getCurrentState().formData['ratingbtnlink'] = a.ratingbtnlink
                    getCurrentState().formData['ratingbtnname'] = a.ratingbtnname
                }
                else {
                    delete getCurrentState().formData['ratingbtnlink']
                    delete getCurrentState().formData['ratingbtnname']
                }
            }
        }

        // srushti 4-4-2023 
        else if (type === "input-textarea") {
            getCurrentState().formData[key] = value
        }
        // srushti 31-5-2023
        else if (type === "input-text") {
            if (key === "folioemail") {
                getCurrentState().formData['checkoutdetails']['folioemail'] = value
                getCurrentState().formData[key] = value
            }
            else {
                //charmi(02-06-2023)
                if (fieldtype == 'table') {
                    getCurrentState().formData[fieldkey][fieldvalue][key] = value
                }
                else {
                    getCurrentState().formData[key] = value
                }
            }
        }
        else if (type === "radio") {
            if (key === "foliotype") {
                getCurrentState().formData['checkoutdetails']['foliotype'] = value
                getCurrentState().formData[key] = value
            }
            else {
                getCurrentState().formData[key] = value
            }
        }

        // srushti 21-2-23 
        else {
            if (fieldtype == 'table') {
                getCurrentState().formData[fieldkey][fieldvalue][key] = value
            }
            else {
                if (key === 'problemid') {
                    getCurrentState().formData[key] = value
                    getCurrentState().formData['problem'] = IISMethods.getobjectfromarray(getCurrentState().masterData['problem'], "value", value).label
                }
                else {
                    getCurrentState().formData[key] = value
                }
            }
        }

        let fieldobj = JsCall.getobjectfromformdata(getCurrentState().rightSidebarFormData, key)

        if (fieldobj.resetfield) {
            for (let resetfield of fieldobj.resetfield) {
                let obj = JsCall.getobjectfromformdata(getCurrentState().rightSidebarFormData, resetfield)
                if (obj.type === 'checkbox') {
                    getCurrentState().formData[resetfield] = 0
                }
                else if (obj.type === 'number-input') {
                    getCurrentState().formData[resetfield] = 0
                }
                else {
                    getCurrentState().formData[resetfield] = ''
                    if (document.getElementById(`form-${obj.field}`)) {
                        document.getElementById(`form-${obj.field}`).value = ''
                    }
                }
            }
        }

        await props.setProps({ formData: IISMethods.getCopy(getCurrentState().formData) })

        if (key === 'vehicleimages' && getCurrentState().formData['vehicleimages'].length > 0) {
            updateData(getCurrentState().formData, 'vehicleInfoModal')
        }

        JsCall.validateForm(getCurrentState().formData, getCurrentState().rightSidebarFormData, key)
        const obj = JsCall.getobjectfromformdata(getCurrentState().rightSidebarFormData, key)
        if (obj.onchangefill) {
            for (const field of obj.onchangefill) {
                const obj2 = JsCall.getobjectfromformdata(getCurrentState().rightSidebarFormData, field)
                await getMasterData(1, obj2, getCurrentState().formData)
            }
        }
    }

    //edit button formdata set - props set 
    const setFormData = async (id, jsoncase, modalname) => {

        // debugger
        if (modalname === 'housekeepingModal' && getCurrentState().reservationDetails.ischeckinday === 0) {
            getCurrentState().rightSidebarFormData = props.masterjson('housekeeping')
        }
        else if (modalname === 'housekeepingModal' && getCurrentState().reservationDetails.ischeckinday === 1) {
            getCurrentState().rightSidebarFormData = props.masterjson('housekeepingtwo')
        }
        else {
            getCurrentState().rightSidebarFormData = props.masterjson(jsoncase)
        }
        var tempFormData = {}
        //formdata set 
        getCurrentState().rightSidebarFormData && getCurrentState().rightSidebarFormData.map((data) => {
            return data.formfields.map((fields) => {
                // for vehicle modal set payload 
                if (modalname === 'vehicleInfoModal') {
                    tempFormData['isearlycheckin'] = getCurrentState().reservationDetails?.isearlycheckin === 'Yes' ? 1 : 0
                    tempFormData['vehicleinfosubmit'] = 1
                    tempFormData['vehicleinfocollected'] = getCurrentState().reservationDetails?.vehicleinfocollected
                    tempFormData['_id'] = getCurrentState().reservationDetails?._id
                    if (fields.type === 'table') {
                        tempFormData[fields.field] = getCurrentState().reservationDetails?.vehicleinformations?.length > 0 ? getCurrentState().reservationDetails.vehicleinformations : [{
                            vehiclenumberplate: '',
                            vehiclemakemodel: ''
                        }]
                    }
                    else if (fields.type === 'image') {
                        tempFormData[fields.field] = getCurrentState().reservationDetails?.vehicleimages?.length > 0 ? getCurrentState().reservationDetails.vehicleimages : []
                    }
                }

                // srushti 4-4-2023 latecheckout model set payload
                else if (modalname === "latecheckoutModal") {
                    tempFormData['propertyid'] = getCurrentState().reservationDetails?.propertyid
                    tempFormData['propertyname'] = getCurrentState().reservationDetails?.propertyname
                    tempFormData['categoryid'] = Config.categoryid.frontdesk
                    tempFormData['category'] = 'Front Desk'
                    tempFormData['assignmentforid'] = Config.assignmentfor.room
                    tempFormData['assignmentfor'] = 'Room'
                    tempFormData['subcategoryid'] = '645a348357f45b2c947e5282'
                    tempFormData['subcategory'] = 'Late Checkout'
                    tempFormData['details'] = [
                        {
                            'roomnoid': getCurrentState().reservationDetails?.roomnoid,
                            'roomno': getCurrentState().reservationDetails?.roomno,
                            'roomtypeid': getCurrentState().reservationDetails?.roomtypeid,
                            'roomtype': getCurrentState().reservationDetails?.roomtype,
                        }
                    ]
                    tempFormData['departments'] = [
                        {
                            'departmentid': Config.departments.frontdesk,
                            'department': 'Front Desk'
                        }
                    ]
                }
                // srushti 10-4-2023 extend stay srushti no in submit button
                else if (modalname === "extendstaydetailsModal") {
                    let checkout = IISMethods.GetDT(getCurrentState().reservationDetails?.checkoutdate)
                    checkout.setDate(checkout.getDate() + 1)

                    tempFormData['propertyid'] = getCurrentState().reservationDetails?.propertyid
                    tempFormData['propertyname'] = getCurrentState().reservationDetails?.propertyname
                    tempFormData['reservationid'] = getCurrentState().reservationDetails?._id
                    tempFormData['categoryid'] = Config.categoryid.frontdesk
                    tempFormData['category'] = "Frontdesk"
                    tempFormData['subcategoryid'] = "646ddb78f288c1b0423cc3a5"
                    tempFormData['tasktypeid'] = "62fe1749cbac20692899122a"
                    tempFormData['tasktype'] = "Once"
                    tempFormData["assignmentforid"] = Config.assignmentfor.room
                    tempFormData["assignmentfor"] = "Room"
                    tempFormData["buildingid"] = getCurrentState().reservationDetails.buildingid
                    tempFormData["buildingname"] = getCurrentState().reservationDetails.buildingname
                    tempFormData["wingid"] = getCurrentState().reservationDetails.wingid
                    tempFormData["wingname"] = getCurrentState().reservationDetails.wingname
                    tempFormData["details"] = [{
                        roomnoid: getCurrentState().reservationDetails.roomnoid,
                        roomno: getCurrentState().reservationDetails.roomno,
                        subcategory: "Extend Stay",
                        subcategoryid: "646ddb78f288c1b0423cc3a5",
                        priorityid: "6303126355c8ad547b0f809c",
                        priority: "Low"
                    }]
                    tempFormData["extendstaydetails"] = {
                        isnewreservation: 0,
                        checkindate: getCurrentState().reservationDetails?.checkindate,
                        checkoutdate: checkout,
                        noofnight: initialItemQty
                    }
                    setItemqty(initialItemQty)
                }
                // srushti 23-5-2023 extend stay srushti yes button
                else if (modalname === "extendstayModal") {
                    let checkout = IISMethods.GetDT(getCurrentState().reservationDetails?.checkoutdate)
                    checkout.setDate(checkout.getDate() + 1)

                    tempFormData['propertyid'] = getCurrentState().reservationDetails?.propertyid
                    tempFormData['propertyname'] = getCurrentState().reservationDetails?.propertyname
                    tempFormData['reservationid'] = getCurrentState().reservationDetails?._id
                    tempFormData['categoryid'] = Config.categoryid.frontdesk
                    tempFormData['category'] = "Frontdesk"
                    tempFormData['subcategoryid'] = "646ddb78f288c1b0423cc3a5"
                    tempFormData['tasktypeid'] = "62fe1749cbac20692899122a"
                    tempFormData['tasktype'] = "Once"
                    tempFormData["assignmentforid"] = Config.assignmentfor.room
                    tempFormData["assignmentfor"] = "Room"
                    tempFormData["buildingid"] = getCurrentState().reservationDetails.buildingid
                    tempFormData["buildingname"] = getCurrentState().reservationDetails.buildingname
                    tempFormData["wingid"] = getCurrentState().reservationDetails.wingid
                    tempFormData["wingname"] = getCurrentState().reservationDetails.wingname
                    tempFormData["details"] = [{
                        roomnoid: getCurrentState().reservationDetails.roomnoid,
                        roomno: getCurrentState().reservationDetails.roomno,
                        subcategory: "Extend Stay",
                        subcategoryid: "646ddb78f288c1b0423cc3a5",
                        priorityid: "6303126355c8ad547b0f809c",
                        priority: "Low"
                    }]
                    tempFormData["extendstaydetails"] = {
                        isnewreservation: 1,
                        checkindate: getCurrentState().reservationDetails?.checkindate,
                        checkoutdate: checkout,
                        noofnight: initialItemQty
                    }
                    setItemqty(initialItemQty)
                }
                // for receipt modal flag set in payload 21-4-2023
                else if (modalname === "receiptModal") {
                    tempFormData['propertyid'] = getCurrentState().reservationDetails?.propertyid
                    tempFormData['propertyname'] = getCurrentState().reservationDetails?.propertyname
                    tempFormData['categoryid'] = Config.categoryid.frontdesk
                    tempFormData['category'] = 'Front Desk'
                    tempFormData['assignmentforid'] = Config.assignmentfor.room
                    tempFormData['assignmentfor'] = 'Room'
                    tempFormData['subcategoryid'] = "645a348357f45b2c947e5283"
                    tempFormData['subcategory'] = "Checkout"
                    tempFormData['details'] = [
                        {
                            'roomnoid': getCurrentState().reservationDetails?.roomnoid,
                            'roomno': getCurrentState().reservationDetails?.roomno,
                            'roomtypeid': getCurrentState().reservationDetails?.roomtypeid,
                            'roomtype': getCurrentState().reservationDetails?.roomtype,
                        }
                    ]
                    tempFormData['departments'] = [
                        {
                            'departmentid': Config.departments.frontdesk,
                            'department': 'Front Desk'
                        }
                    ]

                    tempFormData['checkoutdetails'] = {
                        'iscard': 1,
                        'iscash': 0,
                        'isguestcheckout': 1,
                        // 'foliotype' : 1,
                        // 'foliomail' : ''
                    }

                    props.setProps({ formData: IISMethods.getCopy(tempFormData) })
                }


                else if (modalname === 'wakeupcallModal') {
                    if (id) {
                        tempFormData = getCurrentState().data[0]
                    }
                    else {
                        tempFormData['reservationid'] = getCurrentState().reservationDetails?._id
                        tempFormData['days'] = []
                    }
                }

                // jatin 10-03-2023 for housekeeping modal 
                else if (modalname === "housekeepingModal") {
                    if (fields.type === 'radio') {
                        tempFormData[fields.field] = fields.radiooptions && fields.radiooptions[0].value
                    }
                    if (fields.type === 'multipleselectdropdown' || fields.type === 'daterangepicker' || fields.type === 'table') {
                        if (fields.field == 'roomdetails') {
                            tempFormData[fields.field] = [{
                                roomnoid: getCurrentState().reservationDetails?.roomnoid,
                                roomno: getCurrentState().reservationDetails?.roomno,
                                roomtypeid: getCurrentState().reservationDetails?.roomtypeid,
                                roomtype: getCurrentState().reservationDetails?.roomtype,
                            }]
                        }
                        else {
                            tempFormData[fields.field] = []
                        }
                    }
                    else if (fields.type === 'dropdown') {
                    }
                    else if (fields.type === 'number-input') {
                        tempFormData[fields.field] = 0
                    }
                    else {
                        tempFormData[fields.field] = fields.defaultvalue !== undefined ? fields.defaultvalue : ''
                    }
                }
                else {
                    if (fields.type === 'radio') {
                        tempFormData[fields.field] = fields.radiooptions[0].value
                    }
                    else if (fields.type === 'default-radio') {
                        tempFormData[fields.field] = fields.radiooptions[0].value
                    }
                    else if (fields.type === 'multipleselectdropdown' || fields.type === 'daterangepicker' || fields.type === 'table') {
                        tempFormData[fields.field] = []
                    }
                    else if (fields.type === 'dropdown') {
                    }
                    else if (fields.type === 'datepicker' || fields.type === 'timepicker') {
                        tempFormData[fields.field] = fields.defaultvalue ? IISMethods.GetCurrentTime() : ''
                    }
                    else {
                        tempFormData[fields.field] = fields.defaultvalue !== undefined ? fields.defaultvalue : ''
                    }
                }
            })
        })
        // for dnd on/off modal srushti 4-4-2023
        if (modalname === "dndonModal") {
            let dndObj = IISMethods.getobjectfromarray(getCurrentState().menuData, 'alias', jsoncase)
            tempFormData['propertyid'] = getCurrentState().reservationDetails?.propertyid  //srushti
            tempFormData['roomnoid'] = getCurrentState().reservationDetails?.roomnoid  //srushti
            tempFormData['roomno'] = getCurrentState().reservationDetails?.roomno  //srushti
            tempFormData['isdnd'] = 1
            tempFormData['active'] = dndObj.dndon
        }
        await props.setProps({ formData: tempFormData })
        //formdata set 
        //error handling
        for (const data of getCurrentState().rightSidebarFormData) {
            for (const fields of data.formfields) {
                JsCall.handleError('form-' + fields.field, false)
            }
        }
        //error handling
        handleGrid(true, 'modal', modalname, 1)

        //select picker data call
        for (const data of getCurrentState().rightSidebarFormData) {
            for (const fields of data.formfields) {
                if (fields.masterdata && !fields.masterdataarray) {
                    if (fields.masterdatadependancy || !getCurrentState().masterData[fields.masterdata]) //
                        await getMasterData(1, fields, getCurrentState().formData) //
                    // if (modalname === "latecheckoutModal" && fields.field === 'subcategoryid' && getCurrentState().masterDataList[fields.masterdata].length > 0) {
                    //     getCurrentState().formData[fields.field] = getCurrentState().masterDataList[fields.masterdata][0]['_id']
                    //     getCurrentState().formData[fields.formdatafield] = getCurrentState().masterDataList[fields.masterdata][0][fields.masterdatafield]
                    //     props.setProps({ formData: getCurrentState().formData })
                    // }
                    if (fields.field === 'problemid') {
                        getCurrentState().formData[fields.field] = getCurrentState().masterDataList[fields.masterdata][0]['_id']
                        getCurrentState().formData[fields.formdatafield] = getCurrentState().masterDataList[fields.masterdata][0][fields.masterdatafield]
                        props.setProps({ formData: getCurrentState().formData })
                    }
                    else if (fields.field === 'taskguestrating') {
                        await getMasterData(1, fields, getCurrentState().formData)
                    }
                    else if (fields.field === 'reservationcheckoutrating') {
                        await getMasterData(1, fields, getCurrentState().formData)
                    }
                }
                else if (fields.masterdata && fields.masterdataarray && !getCurrentState().masterData[fields.masterdata]) { //
                    var array = []
                    for (const object of fields.masterdataarray) {
                        array.push({
                            label: object,
                            value: object,
                            icon: object
                        })
                    }
                    getCurrentState().masterData[fields.masterdata] = array
                    await props.setProps({ masterData: IISMethods.getCopy(getCurrentState().masterData) }) //
                }
            }
        }

        // srushti 17-4-23 checkin/vehicle rating modal
        if (modalname === "vehicleratingModal") {
            tempFormData['reservationid'] = getCurrentState().reservationDetails?._id
            tempFormData['reservationcheckinrating'] = []
            getCurrentState().masterDataList['checkinrating']?.map((data, index) => {
                let tempdata = {}
                tempdata['ratingid'] = data._id
                tempdata['stars'] = 0
                tempdata['rating'] = data.rating
                tempdata['comments'] = data.comments ? data.comments : ''
                tempdata['tags'] = []
                return tempFormData['reservationcheckinrating'].push(tempdata)
            })
            await props.setProps({ formData: tempFormData })
        }
        // srushti 21-4-23 checkout rating modal
        else if (modalname === "checkoutratingModal") {
            tempFormData['reservationid'] = getCurrentState().reservationDetails?._id
            tempFormData['reservationcheckoutrating'] = []
            getCurrentState().masterDataList['checkouttrating'].map((data, index) => {
                let tempdata = {}
                tempdata['ratingid'] = data._id
                tempdata['stars'] = 0
                tempdata['rating'] = data.rating
                tempdata['comments'] = data.comments ? data.comments : ''
                tempdata['tags'] = []
                return tempFormData['reservationcheckoutrating'].push(tempdata)
            })
            await props.setProps({ formData: tempFormData })
        }
        // srushti 21-4-23 housekeeping rating modal
        else if (modalname === "housekeepingratingModal") {
            tempFormData['roomnoid'] = getCurrentState().reservationDetails.roomnoid
            tempFormData['propertyid'] = getCurrentState().reservationDetails.propertyid
            tempFormData['taskguestrating'] = []
            getCurrentState().masterDataList['housekeeprating'].map((data, index) => {
                let tempdata = {}
                tempdata['ratingid'] = data?._id
                tempdata['stars'] = 0
                tempdata['rating'] = data.rating
                tempdata['comments'] = data.comments ? data.comments : ''
                tempdata['tags'] = []
                return tempFormData['taskguestrating'].push(tempdata)
            })
            await props.setProps({ formData: tempFormData })
        }
    }

    //set CheckOut Modal FormData - props srushti 30-5-2023
    const setCheckOutFormData = async () => {
        var tempFormData = {}
        tempFormData['propertyid'] = getCurrentState().reservationDetails?.propertyid
        tempFormData['propertyname'] = getCurrentState().reservationDetails?.propertyname
        tempFormData['categoryid'] = Config.categoryid.frontdesk
        tempFormData['category'] = 'Front Desk'
        tempFormData['assignmentforid'] = Config.assignmentfor.room
        tempFormData['assignmentfor'] = 'Room'
        tempFormData['subcategoryid'] = "645a348357f45b2c947e5283"
        tempFormData['subcategory'] = "Checkout"
        tempFormData['details'] = [
            {
                'roomnoid': getCurrentState().reservationDetails?.roomnoid,
                'roomno': getCurrentState().reservationDetails?.roomno,
                'roomtypeid': getCurrentState().reservationDetails?.roomtypeid,
                'roomtype': getCurrentState().reservationDetails?.roomtype,
            }
        ]
        tempFormData['departments'] = [
            {
                'departmentid': Config.departments.frontdesk,
                'department': 'Front Desk'
            }
        ]
        // tempFormData['checkoutdetails'] = {
        //     'iscard' : 1,
        //     'iscash' : 0,
        //     'isguestcheckout' : 1,
        //     // 'foliotype' : 1,
        //     // 'foliomail' : ''
        // }
        await props.setProps({ formData: tempFormData }) //srushti
        handleGrid(true, 'modal', 'depositModal', 1)
    }

    //for open modal - recordinfo - create button
    const handleGrid = (id, type, key, value, editeDataIndex) => {
        if (type === 'modal') {
            if (value) {
                getCurrentState().modal[key] = id
            }
            else {
                delete getCurrentState().modal[key]
            }
            props.setProps({ modal: IISMethods.getCopy(getCurrentState().modal) })
        }
        else { }
    }

    // for extend stay date and counter manage srushti 21-4-23
    const getTotalDayAndDate = (checkoutdate, days) => {
        let selectedDate = IISMethods.GetDT(checkoutdate)
        selectedDate.setDate(selectedDate.getDate() + Number(days))
        return days ? selectedDate : ''
    }

    //form data prepare - state set - button click event 
    const handleAddButtonClick = async (e, modalName, yesbutton, link) => {

        Object.keys(getCurrentState().formData).map((key) => {
            if (typeof getCurrentState().formData[key] === 'string') {
                getCurrentState().formData[key] = getCurrentState().formData[key].trim()
            }
        })
        if (JsCall.validateForm(getCurrentState().formData, getCurrentState().rightSidebarFormData)) {
            IISMethods.notify("Fill all required data", 2)
        }
        else {
            let tempdata = IISMethods.getCopy(getCurrentState().formData)
            if (modalName === 'depositModal') {
                //charmi(30-05-2023)
                if (yesbutton) {
                    tempdata['checkoutdetails'] = {
                        'isguestcheckout': 1,
                        'iscash': 1,
                        'iscard': 0
                    }
                }
                else {
                    tempdata['checkoutdetails'] = {
                        'isguestcheckout': 1,
                        'iscash': 0,
                        'iscard': 1
                    }
                }
            } else if (modalName === 'receiptModal') {
                delete tempdata['folioemail']
                delete tempdata['foliotype']
            }
            if (getCurrentState().formData._id) {
                updateData(tempdata, modalName)
            } else {
                // srushti 22-5-23
                if (modalName === 'extendstaydetailsModal') {
                    addData(tempdata, 'extendstaydetailsModal', 'task/add')
                }
                else if (modalName === 'extendstayModal') {
                    addData(tempdata, 'extendstayModal', 'task/add')
                }
                else {
                    addData(tempdata, modalName, null, link)
                }
            }
            //to pass the fields with empty value
            //reset formdata variable after api called JENIL 28/6/2023 5:25 PM
            getCurrentState().rightSidebarFormData.map((data) =>
                data.formfields.map((fields) => {
                    if (!document.getElementById('form-' + fields.field)) {
                        if (fields.type === 'switch' || fields.type === 'checkbox')
                            getCurrentState().formData[fields.field] = 0
                        else if (fields.type === 'radio')
                            getCurrentState().formData[fields.field] = fields.radiooptions[0].value
                        else if (fields.type === 'default-radio')
                            getCurrentState().formData[fields.field] = fields.radiooptions[0].value
                        else if (fields.type === 'multipleselectdropdown' || fields.type === 'daterangepicker' || fields.type === 'table')
                            getCurrentState().formData[fields.field] = []
                        else if (fields.type === 'datepicker' || fields.type === 'timepicker')
                            getCurrentState().formData[fields.field] = fields.defaultvalue ? IISMethods.GetCurrentTime() : ''
                        else if (fields.type === 'dropdown') {
                            if (fields.masterdataarray) {
                                getCurrentState().formData[fields.field] = ''
                            }
                            else {
                                delete getCurrentState().formData[fields.field]
                                delete getCurrentState().formData[fields.formdatafield]
                            }
                        }
                        else
                            getCurrentState().formData[fields.field] = fields.defaultvalue || ''

                    }
                    if (fields.istablefield) {
                        delete getCurrentState().formData[fields.field]
                        delete getCurrentState().formData[fields.formdatafield]
                    }
                })
            )
            await props.setProps({ formData: IISMethods.getCopy(getCurrentState().formData) })
        }
    }

    // jatin 23-02-2023 House keeping Modal button click
    const HouseKeepingAddButtonClick = async (e, modalName) => {
        // debugger
        e.preventDefault()
        // srushti 21-4-2023 for housekeeping validaton and payload add
        if (JsCall.validateForm(getCurrentState().formData, getCurrentState().rightSidebarFormData)) {
            IISMethods.notify("Fill all required data", 2)
        }
        else if (getCurrentState().formData.tab === 1 && getCurrentState().reservationDetails.ischeckinday === 1 && ((getCurrentState().formData.hours >= 0 && getCurrentState().formData.minutes === 0) && (getCurrentState().formData.hours === 0 && getCurrentState().formData.minutes >= 0) && getCurrentState().formData.datetime === '')) {
            IISMethods.notify("Fill all required data", 2)
        }
        else if (getCurrentState().formData.tab === 2 && getCurrentState().reservationDetails.ischeckinday === 1 && ((getCurrentState().formData.hours >= 0 && getCurrentState().formData.minutes === 0) && (getCurrentState().formData.hours === 0 && getCurrentState().formData.minutes >= 0) && getCurrentState().formData.revisittime === '')) {
            IISMethods.notify("Fill all required data", 2)
        }
        else {
            let tempdata = IISMethods.getCopy(getCurrentState().formData)
            await props.setProps({ formData: IISMethods.getCopy(getCurrentState().formData) })
            if (getCurrentState().reservationDetails.ischeckinday === 1) {
                let reqData
                //srushti set payload tabwise different
                if (getCurrentState().formData.tab === 0) {
                    reqData = {
                        notes: tempdata.notes,
                        revisittime: tempdata.tab ? IISMethods.GetDT().toISOString() : tempdata.revisittime,
                        flag: tempdata.flag,
                        active: tempdata.active,
                        isdnd: tempdata.isdnd,
                        propertyid: getCurrentState().reservationDetails.propertyid,
                        roomnoid: getCurrentState().reservationDetails.roomnoid,
                        iscompleted: 0,
                    }
                }
                else {
                    reqData = {
                        hours: tempdata.tab ? parseInt(getCurrentState().formData.hours) : tempdata.hours,
                        minutes: tempdata.tab ? parseInt(getCurrentState().formData.minutes) : tempdata.minutes,
                        datetime: !tempdata.tab ? getCurrentState().formData.datetime : tempdata.datetime,
                        notes: tempdata.notes,
                        revisittime: tempdata.tab ? getCurrentState().formData.revisittime : tempdata.revisittime,
                        flag: tempdata.flag,
                        active: tempdata.active,
                        isdnd: tempdata.isdnd,
                        propertyid: getCurrentState().reservationDetails.propertyid,
                        roomnoid: getCurrentState().reservationDetails.roomnoid,
                        iscompleted: 0,
                    }
                }
                // tab is equal to DND then delet datetime
                if (tempdata.tab == 2) {
                    delete reqData.datetime
                    delete reqData.notes
                }
                else {
                    delete reqData.revisittime
                    delete reqData.flag
                    delete reqData.isdnd
                    delete reqData.active
                }
                if (getCurrentState().formData._id) {
                    updateData(reqData, modalName)
                }
                else {
                    addData(reqData, modalName, tempdata.tab == 2 ? "task/actionlog/add" : "")
                }
            }
            else if (getCurrentState().reservationDetails.ischeckinday === 0) {
                updateData(tempdata, modalName)
            }
        }
    }

    // dnd model button click on/off srushti
    const setDnd = async (e, modalName) => {
        let tempdata = IISMethods.getCopy(getCurrentState().formData)
        if (tempdata.active == 1) {
            tempdata['active'] = 0
        }
        else {
            tempdata['active'] = 1
        }
        await props.setProps({ formData: tempdata })
        await updateData(getCurrentState().formData, modalName, "task/actionlog/add")
    }

    const handleLateCheckout = (signature, modalName) => {
        const imageData = signature
        if (!signature.isEmpty()) {
            if (getCurrentState().formData.time === '' || getCurrentState().formData.time === undefined) {
                IISMethods.notify("Fill required fields.", 2)
            }
            else {
                getCurrentState().formData['guestsignature'] = imageData.getTrimmedCanvas().toDataURL('image/png')
                getCurrentState().formData['latecheckoutcharges'] = getCurrentState().formData['latecheckoutcharges'].substring(1)
                props.setProps({ formData: IISMethods.getCopy(getCurrentState().formData) })
                updateData(IISMethods.getCopy(getCurrentState().formData), modalName)
            }
        }
        else {
            IISMethods.notify("Please add your signature ", 2)
        }
    }



    //insert operation srushti
    const addData = async (reqData, modalName, urls, link) => {
        await props.setLoading({ loading: 1 })
        const url = Config.weburl + (urls ? urls : getCurrentState().rightSidebarFormData[0].apipath)
        const useraction = 'guestrequest'
        await setReqParams(url, reqData)
        await IISMethods.addData(useraction, '', true)
        if (resBody.status === 200) {
            getCurrentState().formData['message'] = resBody.message
            handleGrid(false, 'modal', modalName, 0)
            await props.setProps({ pageNo: 1 })
            await props.clearData()
            await props.setLoading({ loading: 0 })
            // for checkout rating modal
            if (modalName === 'checkoutratingModal') {
                handleGrid(false, 'modal', 'checkoutratingModal', 0)
                getReservationDetails()
                await props.setLoading({ loading: 0 })
            }
            else if (modalName === 'housekeepingModal') {
                await getMenus()
            }
            // for extend-stay modal srushti
            else if (modalName === "extendstaydetailsModal") {
                handleGrid(false, 'modal', 'extendstaydetailsModal', 0)
                await getReservationDetails()
                handleGrid(true, 'modal', 'ExtendstayThankyou', 1)
                await props.setLoading({ loading: 0 })
                getCurrentState().formData['message'] = resBody.message
            }
            else if (modalName === "extendstayModal") {
                handleGrid(false, 'modal', 'extendstayModal', 0)
                await getReservationDetails()
                handleGrid(true, 'modal', 'ExtendstayThankyou', 1)
                await props.setLoading({ loading: 0 })
                getCurrentState().formData['message'] = resBody.message
            }
            // for checkout deposit modal srushti
            else if (modalName === 'depositModal') {
                handleGrid(false, 'modal', 'depositModal', 0)
                handleGrid(true, 'modal', 'CheckoutThankyou', 1)
                getReservationDetails()
                await props.setLoading({ loading: 0 })
            }

            // for checkout receipt modal srushti
            else if (modalName === "receiptModal") {
                if (getCurrentState().formData.checkoutdetails.foliotype !== 3) {
                    handleGrid(true, 'modal', 'EmailThankyouModal', 1)
                }
                else if (getCurrentState().formData.checkoutdetails.foliotype === 3 && getCurrentState().formData?.checkoutdetails.isguestcheckout === 1) {
                    getReservationDetails()
                    setFormData(null, 'checkoutrating', 'checkoutratingModal')
                }
                handleGrid(false, 'modal', modalName, 0)
                getCurrentState().formData['message'] = resBody.message
                getReservationDetails()
                await props.setLoading({ loading: 0 })
            }

            else if (modalName === 'vehicleratingModal') {
                if (link) window.open(link, '_blank')
                await props.setLoading({ loading: 0 })
                handleInstallPWA()
            }
        }
        else {
            getCurrentState().formData['message'] = resBody.message
            await props.setLoading({ loading: 0 })
        }
    }

    //update operation
    const updateData = async (reqData, modalName, urls) => {
        await props.setLoading({ loading: 1 })
        const url = Config.weburl + (urls ? urls : getCurrentState().rightSidebarFormData[0].apipath)
        const useraction = 'guestrequest'
        await setReqParams(url, reqData)
        await IISMethods.updateData(useraction)

        if (resBody.status === 200) {
            // for vehicle modal srushti
            if (modalName === "vehicleInfoModal") {
                handleGrid(false, 'modal', 'vehicleInfoModal', 0)
                await props.setLoading({ loading: 0 })
                await getReservationDetails()
                if (!getCurrentState().modal['VehicleImageCaptureModal'] && (getCurrentState().reservationDetails?.reservationratingsubmitted === undefined || getCurrentState().reservationDetails?.reservationratingsubmitted === 0)) {
                    setFormData(null, "vehiclerating", "vehicleratingModal")
                }
            }
            // for wakeup call modal srushti
            else if (modalName === 'wakeupcallModal') {
                handleGrid(false, 'modal', 'wakeupcallModal', 0)
                handleGrid(true, 'modal', 'wakeupcallInfoModal', 1)
                await props.setLoading({ loading: 0 })
            }
            // for late-checkout modal srushti 
            else if (modalName === "latecheckoutModal") {
                handleGrid(false, 'modal', modalName, 0)
                handleGrid(true, 'modal', 'LateCheckoutThankyou', 1)
                await getReservationDetails()//charmi(06-06-2023)(HV)
                await props.setLoading({ loading: 0 })
            }
            else {
                handleGrid(false, 'modal', modalName, 0)
                getCurrentState().formData['message'] = resBody.message
                await props.setProps({ formData: IISMethods.getCopy(getCurrentState().formData), pageNo: 1 })
                // for dnd modal call menu api srushti
                if (modalName === 'dndonModal') {
                    await getMenus(true)
                }
                //srushti 21-02023
                await props.setLoading({ loading: 0 })
            }
        }
        else {
            await props.setLoading({ loading: 0 })
        }
    }

    //guestexpnotify socket resposne function JENIL
    // const guestExpNotifyResponse = async (resp) => {
    //     let menuindex = getCurrentState()?.menuData?.findIndex((a) => a.alias === resp.alias)
    //     if (menuindex >= 0) {
    //         let obj = { ...getCurrentState().menuData[menuindex] }
    //         obj.ishighlight = 1
    //         getCurrentState().menuData?.splice(menuindex, 1, obj);
    //         await props.setProps({ menuData: IISMethods.getCopy(getCurrentState().menuData) })
    //     }
    //     if (resp.isguestexpnotify === 1) {
    //         getCurrentState().reservationDetails["notificationcounts"] = getCurrentState().reservationDetails["notificationcounts"] + 1
    //         await props.setProps({ reservationDetails: IISMethods.getCopy(getCurrentState().reservationDetails) })
    //     }
    // }

    // useSocketListener("guestexpnotify", guestExpNotifyResponse)

    return (
        <>
            {
                pagename === 'home' || pagename === '' ?
                    <HomeView
                        handleFormData={handleFormData}
                        setFormData={setFormData}
                        handleAddButtonClick={handleAddButtonClick}
                        HouseKeepingAddButtonClick={HouseKeepingAddButtonClick}
                        handleGrid={handleGrid}
                        setCheckOutFormData={setCheckOutFormData}
                        setDnd={setDnd}
                        itemqty={itemqty}
                        handleLateCheckout={handleLateCheckout}
                        getReservationDetails={getReservationDetails}

                    />
                    : pagename === 'mystay' ?
                        <MyStayView
                            handleFormData={handleFormData}
                            setFormData={setFormData}
                            handleAddButtonClick={handleAddButtonClick}
                            HouseKeepingAddButtonClick={HouseKeepingAddButtonClick}
                            handleGrid={handleGrid}
                            handleLateCheckout={handleLateCheckout}
                            setCheckOutFormData={setCheckOutFormData}
                            itemqty={itemqty}
                            setDnd={setDnd}
                            getReservationDetails={getReservationDetails}
                            getList={getList}
                            load={load}
                        />
                        : pagename === 'myhotel' ?
                            <MyHotelView
                                handleFormData={handleFormData}
                                setFormData={setFormData}
                                handleAddButtonClick={handleAddButtonClick}
                                HouseKeepingAddButtonClick={HouseKeepingAddButtonClick}
                                handleGrid={handleGrid}
                                setCheckOutFormData={setCheckOutFormData}
                                setDnd={setDnd}
                            />
                            : pagename === 'contactus' ?
                                <ContactUsView
                                    handleFormData={handleFormData}
                                    setFormData={setFormData}
                                    contactUsLoad={contactUsLoad}
                                />
                                : pagename === 'verificationdata' ?
                                    <VerificationDataView
                                        handleFormData={handleFormData}
                                        setFormData={setFormData}
                                    />
                                    :
                                    ''
            }

        </>
    )
}

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    clearData: (payload) => dispatch(clearData(payload)),
    setProps: (payload) => dispatch(setProps(payload)),
    setLoading: (payload) => dispatch(setLoading(payload)),
    setLoadings: (payload) => dispatch(setLoadings(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(HomeController)