import React, { useState, useEffect } from 'react'
import { store } from '../index'
import { Config, IISMethods, JsCall, resBody, setReqParams } from '../config/IISMethods'
import { useDispatch, connect, useSelector } from 'react-redux'
import ModalRsuite from './Components/ModalRsuite'
import Thankyou from '../assets/images/Thankyou.png'
import Withdraw from '../assets/images/withdrw.svg'
import cash from '../assets/images/cash.png'
import { clearData, setLoading, setProps } from '../redux/actions'
import Checkout1 from '../assets/images/checkout1.svg'
import Rating from '../assets/images/rating.svg'
import Loader from './Components/Loader'

function CheckOutRightSidebar(props) {
    const getCurrentState = () => {
        return store.getState()['root']
    }

    const [state, setState] = useState()
    const form = 'form-'
    const star_fill = <svg className='star star-curser' xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 64 61.335">
        <path id="star_13_" data-name="star (13)" d="M63.833,23.669A3.4,3.4,0,0,0,60.9,21.33L42.433,19.653l-7.3-17.1a3.4,3.4,0,0,0-6.256,0l-7.3,17.094L3.093,21.33a3.406,3.406,0,0,0-1.931,5.953L15.125,39.528,11.008,57.666a3.4,3.4,0,0,0,5.059,3.675L32,51.817l15.928,9.524a3.4,3.4,0,0,0,5.062-3.675L48.873,39.528,62.836,27.285a3.4,3.4,0,0,0,1-3.616Zm0,0" transform="translate(0 -0.491)" fill="#ffc107" />
    </svg>
    const star_unfill = <svg className='star star-curser' xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 64 61.335">
        <path id="star_14_" data-name="star (14)" d="M14.325,61.827a3.407,3.407,0,0,1-3.315-4.16l4.117-18.137L1.165,27.284a3.4,3.4,0,0,1,1.928-5.953l18.475-1.677,7.3-17.094a3.4,3.4,0,0,1,6.256,0l7.3,17.1L60.9,21.331a3.405,3.405,0,0,1,1.934,5.953L48.875,39.527l4.117,18.137a3.4,3.4,0,0,1-5.061,3.675L32,51.818,16.069,61.344a3.409,3.409,0,0,1-1.744.483ZM32,47.717a3.41,3.41,0,0,1,1.744.482L48.78,57.192,44.894,40.074a3.4,3.4,0,0,1,1.078-3.315L59.156,25.2,41.712,23.612a3.4,3.4,0,0,1-2.827-2.062L32,5.418,25.107,21.552a3.388,3.388,0,0,1-2.818,2.054L4.842,25.19,18.026,36.753A3.4,3.4,0,0,1,19.1,40.071L15.221,57.189,30.256,48.2A3.408,3.408,0,0,1,32,47.717ZM21.427,19.984a.008.008,0,0,1,0,.005Zm21.142-.008,0,.005S42.571,19.979,42.568,19.976Zm0,0" transform="translate(0 -0.492)" fill="#ffe188" />
    </svg>

    //srushti 12-4-23 for handle modal condition
    const HandleModal = (modalname, buttontype) => {
        if (modalname === 'checkoutModal') {
            if (buttontype === 'yes') {
                props.handleGrid(false, 'modal', 'checkoutModal', 0)
                props.setCheckOutFormData()
            }
            else if (buttontype === 'no') {
                props.handleGrid(false, 'modal', 'checkoutModal', 0)
            }
        }
        else if (modalname === 'depositModal') {
            if (buttontype === 'no') {
                if (getCurrentState().reservationDetails.isprepay === 2) {
                    props.handleGrid(false, 'modal', 'depositModal', 0)
                    props.setFormData(null, 'checkout', 'receiptModal')
                }
                else if (getCurrentState().reservationDetails.isprepay === 1) {
                    getCurrentState().loading === 0 && props.handleAddButtonClick(null, 'depositModal')
                }
            }
        }
    }

    try {
        return (
            <>
                {/* Check-Out model */}

                <ModalRsuite
                    size={"xs"}
                    open={getCurrentState().modal['checkoutModal']}
                    onClose={() => props.handleGrid(false, 'modal', 'checkoutModal', 0)}
                    body={
                        <>
                            <div className={`col-12`}>
                                <div className="first-pop-logo">
                                    <img src={Checkout1} />
                                </div>
                            </div>
                            <div className="col-12 mt-3">
                                <span className="text-center d-block">Are you sure you want to </span>
                                <span className="text-center d-block">Check Out ?</span>
                            </div>
                            <div className='col-12 mt-4'>
                                <div className='row justify-content-center'>
                                    <div className='col-6 pr-2'>
                                        <button type="submit" className="btn-yes-no btn-secondary-light"
                                            onClick={(e) => {
                                                HandleModal('checkoutModal', 'no')
                                            }}
                                        >No</button>
                                    </div>
                                    <div className='col-6 pl-2'>
                                        <button type="submit" className="btn-yes-no btn-primary-light"
                                            onClick={(e) => {
                                                HandleModal('checkoutModal', 'yes')
                                            }}
                                        >Yes</button>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                />


                {/* Deposit Model */}

                <ModalRsuite
                    size={"xs"}
                    open={getCurrentState().modal['depositModal']}
                    onClose={() => props.handleGrid(false, 'modal', 'depositModal', 0)}
                    body={
                        <form method='post' onSubmit={props.handleAddButtonClick}>
                            <div>
                                <div className={`col-12`}  >
                                    <div className="first-pop-logo">
                                        <img src={cash} />
                                    </div>
                                </div>
                                <div className="col-12 mt-3">
                                    <span className="text-center d-block">Was the deposit paid in cash?</span>
                                </div>
                                <div className='col-12 mt-4'>
                                    <div className='row justify-content-center'>
                                        <div className='col-6 pr-2'>
                                            <button type="submit" className="btn-yes-no btn-secondary-light"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    HandleModal('depositModal', 'no')
                                                }}
                                            >
                                                {/* <Loader content={'No'}/> */}
                                                No
                                            </button>
                                        </div>
                                        <div className='col-6 pl-2'>
                                            <button type="submit" className="btn-yes-no btn-primary-light"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    getCurrentState().loading === 0 && props.handleAddButtonClick(e, 'depositModal', true)//charmi(30-05-2023)
                                                }}
                                            >
                                                <Loader content={'Yes'} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    }
                />


                {/* receipt modal */}
                {getCurrentState().modal['receiptModal'] &&
                    <ModalRsuite
                        size={"xs"}
                        open={getCurrentState().modal['receiptModal']}
                        onClose={() => props.handleGrid(false, 'modal', 'receiptModal', 0)}
                        title={
                            <div className="model-header icon">
                                <span className="closeicon">
                                    CheckOut
                                </span>
                            </div>
                        }
                        body={
                            <>
                                <div className='col-12'>
                                    <div className='row'>
                                        <div className="col-12">
                                            <span className='checkout-email'>How would you like to receive your Receipt ?</span>
                                        </div>
                                        <div className="col-12">
                                            <form method='post'>
                                                <div className="row">
                                                    {
                                                        getCurrentState().rightSidebarFormData[0]?.formfields?.filter(formfield => {
                                                            let conditions = false
                                                            if (formfield.condition) {
                                                                let condition = formfield.condition?.map((item) => {
                                                                    if (getCurrentState().formData[item.field] === item.onvalue) {
                                                                        return true
                                                                    }
                                                                    return false
                                                                })
                                                                let status = condition.includes(true) ? true : false
                                                                conditions = status
                                                            }
                                                            try {
                                                                return formfield.defaultvisibility || conditions
                                                            }
                                                            catch {
                                                                return false
                                                            }
                                                        }).map((formfield, fieldindex) => {
                                                            if (formfield.type === 'radio') {
                                                                return (
                                                                    <div className={formfield.gridsize} key={`${fieldindex}`}>
                                                                        <div className="form-group validate-input chechout-box-space">
                                                                            <label className="label-form-control invisible d-none">{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}</label>
                                                                            <div className="col-12 px-0 mt-3">
                                                                                <div className="btn-custom-control" id={'form' + formfield.field}>
                                                                                    <div className="gridBox">
                                                                                        {
                                                                                            formfield?.radiooptions?.map((data) => {
                                                                                                let checkoutdetails = getCurrentState().formData?.checkoutdetails || {}
                                                                                                return (
                                                                                                    <div className="">
                                                                                                        <div className="custom-control custom-radio checkout-img-box housekeeping-img-box p-0" key={data?.value}>
                                                                                                            <input disabled={formfield.disabled} type="radio"
                                                                                                                id={form + formfield.field}
                                                                                                                name={formfield.field} className="custom-control-input"
                                                                                                                checked={checkoutdetails[formfield.field] === data?.value}
                                                                                                            />
                                                                                                            <label className="custom-control-label mb-0 p-2" htmlFor={formfield.field + data?.value}
                                                                                                                onClick={() => props.handleFormData(formfield.type, formfield.field, data?.value)}>
                                                                                                                <img className='checkout-img housekeeping-img' src={data?.icon} />
                                                                                                                <div className="text-size">
                                                                                                                    <p className="text-center">{data?.label}</p>
                                                                                                                </div>
                                                                                                            </label>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                            else if (formfield.type === 'input-text' || formfield.type === 'email' || formfield.type === 'phone') //  
                                                            {
                                                                return (
                                                                    <div className={formfield.gridsize} key={`${fieldindex}`}>
                                                                        <div className="form-group validate-input ">
                                                                            <label className="first-popup-label">{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}<span className="text-capitalize">{formfield.field === 'status' && getCurrentState().pageName === 'Task Status' && `(Original Status: ${getCurrentState().formData['statustype']})`}</span></label>
                                                                            <input disabled={formfield.disabled} type="text"
                                                                                // onChange={({ target }) => checkValidation(formfield.type, formfield.field, target.value)}
                                                                                onBlur={(e) => props.handleFormData(formfield.type, formfield.field, e.target.value)}
                                                                                className="form-control form-control-checkout "
                                                                                id={form + formfield.field}
                                                                                name={formfield.field}
                                                                                autoComplete="off"
                                                                                placeholder={formfield.text}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 mt-1">
                                            <button type="submit" className="btn-first-popup mb-0"
                                                onClick={(e) => {
                                                    getCurrentState().loading === 0 && props.handleAddButtonClick(e, 'receiptModal')
                                                }}>
                                                <Loader content={'Submit'} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    />
                }

                {/* confirmation  Thank you modal */}

                <ModalRsuite
                    size={"xs"}
                    open={getCurrentState().modal['EmailThankyouModal']}
                    onClose={() => props.handleGrid(false, 'modal', 'EmailThankyouModal', 0)}
                    body={
                        <>
                            {(getCurrentState().formData.checkoutdetails?.folioemail && getCurrentState().formData.checkoutdetails?.foliotype == 1)
                                &&
                                <>
                                    <div className={`col-12 text-center`}>
                                        <div className="first-pop-logo">
                                            <img src={Thankyou} />
                                        </div>
                                    </div>
                                    <div className="col-12 text-center">
                                        <h1 className="first-popup-title mt-2">You will be get the mail on your</h1>
                                        <h1 className='first-popup-title mt-2 '>given mail id.</h1>
                                    </div>
                                </>
                            }

                            {(getCurrentState().formData.checkoutdetails?.foliotype == 2)
                                &&
                                <>
                                    <div className={`col-12 text-center`}>
                                        <div className="first-pop-logo">
                                            <img src={Withdraw} />
                                        </div>
                                    </div>
                                    <div className="col-12 text-center">
                                        <div className="col-12 text-center mt-3">
                                            <span>Please go to Frontdesk to collect </span><br />
                                            <span>your receipt.</span>
                                        </div>
                                    </div>
                                </>
                            }

                            {(getCurrentState().formData.checkoutdetails?.foliotype == 3)
                                &&
                                <>
                                    <div className={`col-12 text-center`}>
                                        <div className="first-pop-logo">
                                            <img src={Withdraw} />
                                        </div>
                                    </div>
                                    <div className="col-12 text-center">
                                        <div className="col-12 text-center mt-3">
                                            <span>Thank You</span>
                                        </div>
                                    </div>
                                </>
                            }

                            <div className="col-12">
                                <div className="col-12 mt-4">
                                    <button type="submit" className="btn-first-popup"
                                        onClick={(e) => {
                                            props.handleGrid(false, 'modal', 'EmailThankyouModal', 0)
                                            props.getReservationDetails()
                                            { getCurrentState().formData?.checkoutdetails.isguestcheckout === 1 && props.setFormData(null, 'checkoutrating', 'checkoutratingModal') }
                                        }} >
                                        Okay
                                    </button>
                                </div>
                            </div>
                        </>
                    }
                />


                {/* last Thank You Pop Modal */}

                <ModalRsuite
                    size={"xs"}
                    open={getCurrentState().modal['CheckoutThankyou']}
                    onClose={() => props.handleGrid(false, 'modal', 'CheckoutThankyou', 0)}
                    body={
                        <>
                            <div className='col-12 text-center'>
                                <img src={Withdraw} height="50px" />
                            </div>
                            <div className="col-12 mt-3">
                                <span className='text-center d-block'>Please go to Frontdesk to collect </span>
                                <span className='text-center d-block'>your room deposit.</span>
                            </div>
                            <div className="col-12">
                                <div className='row'>
                                    <div className="col-12 mt-4">
                                        <button type="submit" className="btn-first-popup mb-0"
                                            onClick={(e) => {
                                                props.handleGrid(false, 'modal', 'CheckoutThankyou', 0)
                                                { getCurrentState().reservationDetails?.isguestcheckout === 1 && props.setFormData(null, 'checkoutrating', 'checkoutratingModal') }
                                            }}>
                                            Okay
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                />


                {/* chechout rating modal */}

                <ModalRsuite
                    size={"xs"}
                    open={getCurrentState().modal['checkoutratingModal']}
                    onClose={() => props.handleGrid(false, 'modal', 'checkoutratingModal', 0)}
                    title={""}
                    body={
                        <form method='post'>
                            <div className={`col-12 text-center`}>
                                <div className="first-pop-logo">
                                    <img src={Rating} />
                                </div>
                            </div>
                            <div className="col-12 text-center">
                                <h1 className="first-popup-title mt-2 mb-1">Hello {getCurrentState().reservationDetails?.firstname} {getCurrentState().reservationDetails?.lastname}</h1>
                                <p className="rate-modal-sub-title">Your checkout request has been accepted.</p>
                                <p className="rate-modal-sub-title">We Value Your Feedback</p>
                            </div>
                            {
                                getCurrentState().rightSidebarFormData[0]?.formfields.filter(formfield => {
                                    try {
                                        return formfield.defaultvisibility || getCurrentState().formData[formfield.condition.field] === formfield.condition.onvalue
                                    }
                                    catch {
                                        return false
                                    }
                                }).map((formfield, fieldindex) => {
                                    if (formfield.type === 'dropdown') {
                                        return (
                                            <>
                                                {/* charmi(09-06-2023)(rating tag print change) */}
                                                {
                                                    getCurrentState().masterDataList[formfield.masterdata]?.map((data, dataindex) => {
                                                        let tagdata = data.tagdata || []
                                                        let ratingdataobj = {}
                                                        ratingdataobj[dataindex] = []
                                                        let singlearray = ratingdataobj[dataindex]
                                                        for (const item of tagdata) {
                                                            if (getCurrentState().formData['reservationcheckoutrating'] && getCurrentState().formData['reservationcheckoutrating'][dataindex]['stars'] !== 0 && getCurrentState().formData['reservationcheckoutrating'][dataindex]['stars'] <= item.stars && item.abovebelowflag === 1) {
                                                                let temptagdataarray = singlearray.map(data => data.tagid)
                                                                let tagdata = item.tags.filter(data => !temptagdataarray.includes(data.tagid))
                                                                singlearray.push(...tagdata)
                                                            }
                                                            else if (getCurrentState().formData['reservationcheckoutrating'] && getCurrentState().formData['reservationcheckoutrating'][dataindex]['stars'] !== 0 && getCurrentState().formData['reservationcheckoutrating'][dataindex]['stars'] >= item.stars && item.abovebelowflag === 0) {
                                                                let temptagdataarray = singlearray.map(data => data.tagid)
                                                                let tagdata = item.tags.filter(data => !temptagdataarray.includes(data.tagid))
                                                                singlearray.push(...tagdata)
                                                            }
                                                        }
                                                        return (
                                                            <div className="col-12 mt-4 ">
                                                                <div className="rate-box">
                                                                    <div className="rate-box-content">
                                                                        <p>
                                                                            {data.rating}
                                                                        </p>
                                                                    </div>
                                                                    <div className="d-flex justify-content-between mx-5">
                                                                        {
                                                                            // handlestars(formfield.field,dataindex,data._id)
                                                                            [...Array(5)].map((star, index) => {
                                                                                if (getCurrentState().formData['reservationcheckoutrating'] && getCurrentState().formData['reservationcheckoutrating'][dataindex]['stars'] - 1 >= index) {
                                                                                    return <div className=' my-3'
                                                                                        onClick={() => { props.handleFormData('add-remove-star', formfield.field, index, '', dataindex, data._id, '', formfield.masterdata) }}
                                                                                    >
                                                                                        {star_fill}
                                                                                    </div>
                                                                                } else {
                                                                                    return <div className=' my-3'
                                                                                        onClick={() => { props.handleFormData('add-remove-star', formfield.field, index, '', dataindex, data._id, '', formfield.masterdata) }}
                                                                                    >
                                                                                        {star_unfill}
                                                                                    </div>
                                                                                }
                                                                            })
                                                                        }
                                                                    </div>
                                                                    {
                                                                        singlearray?.length > 0
                                                                        &&
                                                                        <div className='ratingTagGrid'>
                                                                            {
                                                                                singlearray.map((tagitem, index) => {
                                                                                    let tagdata = IISMethods.getindexfromarray(getCurrentState().formData['reservationcheckoutrating'][dataindex]['tags'], 'tagid', tagitem.tagid)
                                                                                    return (
                                                                                        <div className="task-tag-custom-control" key={index}>
                                                                                            <div className="custom-control-tag ">
                                                                                                <label className={`custom-label-rt content-center ${(tagdata !== -1) ? "tagactive" : ''}`}
                                                                                                    onClick={() => props.handleFormData('add-remove-star', formfield.field, tagitem.tagid, 'tags', dataindex, tagitem.tag)}
                                                                                                    style={{
                                                                                                        borderRadius: '20px',
                                                                                                        border: `1px solid #9E9E9E`,
                                                                                                    }}
                                                                                                >
                                                                                                    {tagitem.tag}
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </>
                                        )
                                    }
                                    else if (formfield.type === 'input-textarea') // for textarea
                                    {
                                        return (
                                            <div className={formfield.gridsize} key={`${fieldindex}`}>
                                                <div className="form-group validate-input mt-0">
                                                    <center><label className="first-popup-label my-3">{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}</label></center>
                                                    <textarea
                                                        disabled={formfield.disabled} type="text" rows={3}
                                                        defaultValue={getCurrentState().formData[formfield.field]}
                                                        onChange={(e) => props.handleFormData(formfield.type, formfield.field, e.target.value, '', 0, 'suggestion')}
                                                        className="form-control form-control-textarea"
                                                        id={form + formfield.field}
                                                        name={formfield.field} autoComplete="off"
                                                        placeholder={`Enter ${formfield.text}`}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </form>
                    }
                    footer={
                        <>
                            {/* {(getCurrentState().formData.ratingbtnlink && getCurrentState().formData.ratingbtnlink) &&
                                <div className="col-12 mt-1">
                                    <a target='_blank' href={getCurrentState().formData.ratingbtnlink}>
                                        <span className='vehicle-model-btn text-center'>{getCurrentState().formData.ratingbtnname}</span>
                                    </a>
                                </div>
                            } */}
                            <div className="col-12 mt-1">
                                <button type="submit" className="btn-first-popup mb-3"
                                    onClick={(e) => {
                                        getCurrentState().loading === 0 && props.handleAddButtonClick(e, 'checkoutratingModal', '', getCurrentState().formData?.ratingbtnlink)
                                    }}
                                >
                                    <Loader content={'Submit'} />
                                </button>
                            </div>
                        </>
                    }
                />


                {/* Check out message modal */}

                <ModalRsuite
                    size={"xs"}
                    open={getCurrentState().modal['checkoutMessageModal']}
                    onClose={() => props.handleGrid(false, 'modal', 'checkoutMessageModal', 0)}
                    body={
                        <>
                            <div className="col-12 mt-3">
                                <span className='text-center d-block'>{getCurrentState().reservationDetails.guestcheckoutmessage}</span>
                            </div>
                            <div className="col-12">
                                <div className='row'>
                                    <div className="col-12 mt-4">
                                        <button type="submit" className="btn-first-popup mb-0" onClick={(e) => props.handleGrid(false, 'modal', 'checkoutMessageModal', 0)}>Okay</button>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                />


                {/* Check out message modal (after request submitted and from frontdesk, no action is performed) */}

                <ModalRsuite
                    size={"xs"}
                    open={getCurrentState().modal['checkoutRqstSubmitModal']}
                    onClose={() => props.handleGrid(false, 'modal', 'checkoutRqstSubmitModal', 0)}
                    body={
                        <>
                            <div className="col-12 mt-3">
                                <span className='text-center d-block'>Your check-out request has been successfully submited.</span>
                            </div>
                            <div className="col-12">
                                <div className='row'>
                                    <div className="col-12 mt-4">
                                        <button type="submit" className="btn-first-popup mb-0" onClick={(e) => props.handleGrid(false, 'modal', 'checkoutRqstSubmitModal', 0)}>Okay</button>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                />

            </>
        )
    } catch (error) {
        console.log(error)
        return <></>
    }

}
const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    clearData: (payload) => dispatch(clearData(payload)),
    setProps: (payload) => dispatch(setProps(payload)),
    setLoading: (payload) => dispatch(setLoading(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CheckOutRightSidebar)
