import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { store } from '..'
import { Config, IISMethods, JsCall, pagelimit, resBody, setReqParams } from '../config/IISMethods'
import { clearData, setLoading, setProps } from '../redux/actions'
import { useNavigate, useLocation } from 'react-router-dom'
import useFirebase from '../ServiceWorker/useFirebase'
import useAPICall from '../hooks/useApiCall'
import PropertyLayoutView from '../view/PropertyLayoutView'


const PropertyLayoutController = (props) => {
    // for notification request
    const { getReservationDetails, getAccessToken, getPropertyData } = useAPICall()

    // for notification request
    const { requestPermission } = useFirebase()

    const getCurrentState = () => {
        return store.getState()['root']
    }
    let querystring = IISMethods.getQueryString()

    useEffect(() => {

        async function fetchMastersJson() {
            props.setProps({
                fieldOrder: [],
                newFieldOrder: [],
                nextpage: 0,
                pageNo: 1,
                pagelimit: 20,
                pageName: '',
                formName: '',
                sortData: {},
                formData: {},
                filterData: {},
                filterMasterDataList: {},
                oldFilterData: {},
                modal: {},
                rightSidebarFormData: [],
                masterData: {},
                masterDataList: {}
            })
            //(DO NOT CHANGE WITHOUT PERMISSION)charmi(06-09-2023 12:35)(listing api calling issue fix & install application modal open issue fix)
            await props.clearData()
            await getAccessToken()
            await getReservationDetails()
            if (getCurrentState().reservationDetails.ischeckin === 1) {
                await getPropertyData()
                if (getCurrentState().reservationDetails['isreservationexpired'] !== 1) {
                    await requestPermission()
                }
                await getList()
            }
            //(DO NOT CHANGE WITHOUT PERMISSION)charmi(06-09-2023 12:35)(listing api calling issue fix & install application modal open issue fix)
        }
        fetchMastersJson()
    }, [])

    // List Data
    const getList = async () => {
        const url = Config.weburl + "guestexperience/propertylayout"
        const useraction = 'list' + props.pagename + 'data'

        let filter = { }

        Object.entries(getCurrentState().filterData).forEach(([key, value]) => {
            if (typeof (value) === 'number') {
                if (value !== 0)
                    filter[key] = value
            }
            else
                filter[key] = value
        })

        if (!getCurrentState().data.length) {
            await props.setProps({ pageNo: 1 })
        }

        let searchtext = filter.searchtext || ''
        delete filter.searchtext
        const reqBody = {
            searchtext: searchtext,
            paginationinfo: {
                pageno: getCurrentState().pageNo,
                pagelimit: getCurrentState().pagelimit,
                filter: { isactive : 1},
                sort: getCurrentState().sortData
            }
        }

        await setReqParams(url, reqBody)

        await IISMethods.listData(useraction)

        if (resBody.status === 200) {
            if (getCurrentState().pageNo === 1) {
                await props.clearData()
            }

            await props.setProps({
                data: resBody.data,
                nextpage: resBody.nextpage,
                pageName: resBody.pagename,
                formName: resBody.formname
            })
        }
        else {
        }
    }

    // set paticular object in formdata for popup modal
    const setFormData = async (id, jsoncase, modalname) => {
        var tempFormData = {}
        tempFormData = IISMethods.getCopy(IISMethods.getobjectfromarray(getCurrentState().data, '_id', id))
        await props.setProps({ formData: tempFormData })
        handleGrid(true, 'modal', 'propertylayout', 1)
    }

    //for open modal - recordinfo - create button
    const handleGrid = (id, type, key, value, editeDataIndex) => {
        if (value) {
            getCurrentState().modal[key] = id
        }
        else {
            delete getCurrentState().modal[key]
        }
        props.setProps({ modal: IISMethods.getCopy(getCurrentState().modal) })
    }

     // jatin 15-08-2023 for  Search
     const handleFilterData = async (type, key, value) => {
        getCurrentState().filterData[key] = value
        await props.setProps({ filterData: IISMethods.getCopy(getCurrentState().filterData), pageNo: 1 })       
        // await getList()
    }
    // jatin 15-08-2023 for Search
    const handleApplyFilterButtonClick = async (e, type) => {
        if(e) e.preventDefault()
         if (type === 'removevalue') {
             getCurrentState().filterData['searchtext'] = ''
             await props.setProps({ pageNo: 1, oldFilterData: IISMethods.getCopy(getCurrentState().filterData) })
             await props.clearData()
             await getList()
         }
         else {
             await props.setProps({ pageNo: 1, oldFilterData: IISMethods.getCopy(getCurrentState().filterData) })
             await props.clearData()
             await getList()
         }
     }
      // jatin 15-08-2023 11:53 PM
    const handleSerachClose = async()=>{
        getCurrentState().filterData["searchtext"] = " "
        await props.setProps({ filterData: IISMethods.getCopy(getCurrentState().filterData), pageNo: 1 })     
        await getList()

     }
    return (
        <>
            <PropertyLayoutView
                handleGrid={handleGrid}
                setFormData={setFormData}
                handleFilterData={handleFilterData}
                handleApplyFilterButtonClick={handleApplyFilterButtonClick}
                handleSerachClose={handleSerachClose}
            />
        </>
    )
}

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    clearData: (payload) => dispatch(clearData(payload)),
    setProps: (payload) => dispatch(setProps(payload)),
    setLoading: (payload) => dispatch(setLoading(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PropertyLayoutController)
