// import { IISMethods } from "../config/IISMethods"

// const initialState = {
//     data: [],
//     fieldOrder: [],
//     fltPageCollection: [],
//     newFieldOrder: [],
//     pagelimit: 20,
//     pageNo: 1,
//     nextpage: 1,
//     formName: '',
//     pageName: '',
//     sortData: {},
//     formData: {},
//     chatList: {},
//     propertyData: {},
//     filterMasterData: {},
//     filterMasterDataList: {},
//     filterData: {},
//     oldFilterData: {},
//     rightSidebarFormData: [],
//     fieldobjectformasterdata: {},
//     rightSidebarShimmer: false,
//     masterData: {},
//     masterDataList: {},
//     modal: {},
//     menuData: [],
//     secondarySourceMasterData: '',
//     reservationDetails: {},
//     logininfo: {},
//     loading: 0,
//     notificationCount: IISMethods.getLocalData("notificationCount") || 0,
//     userList: []
// }

// const appReducer = (state = initialState, action) => {
//     switch (action.type) {
//         case 'setProps':
//             return {
//                 ...state,
//                 data: (action.payload.data !== undefined ? state.data.concat(action.payload.data) : state.data),
//                 fieldOrder: (action.payload.fieldOrder !== undefined ? action.payload.fieldOrder : state.fieldOrder),
//                 fltPageCollection: (action.payload.fltPageCollection !== undefined ? action.payload.fltPageCollection : state.fltPageCollection),
//                 newFieldOrder: (action.payload.newFieldOrder !== undefined ? action.payload.newFieldOrder : state.newFieldOrder),
//                 pagelimit: (action.payload.pagelimit !== undefined ? action.payload.pagelimit : state.pagelimit),
//                 pageNo: (action.payload.pageNo !== undefined ? action.payload.pageNo : state.pageNo),
//                 nextpage: (action.payload.nextpage !== undefined ? action.payload.nextpage : state.nextpage),
//                 formName: (action.payload.formName !== undefined ? action.payload.formName : state.formName),     // keval 7-4-2023
//                 pageName: (action.payload.pageName !== undefined ? action.payload.pageName : state.pageName),
//                 sortData: (action.payload.sortData !== undefined ? action.payload.sortData : state.sortData),
//                 formData: (action.payload.formData !== undefined ? action.payload.formData : state.formData),
//                 chatList: (action.payload.chatList !== undefined) ? action.payload.chatList : state.chatList,
//                 propertyData: (action.payload.propertyData !== undefined ? action.payload.propertyData : state.propertyData),
//                 filterMasterData: (action.payload.filterMasterData !== undefined ? action.payload.filterMasterData : state.filterMasterData),
//                 filterMasterDataList: (action.payload.filterMasterDataList !== undefined ? action.payload.filterMasterDataList : state.filterMasterDataList),
//                 filterData: (action.payload.filterData !== undefined ? action.payload.filterData : state.filterData),
//                 oldFilterData: (action.payload.oldFilterData !== undefined ? action.payload.oldFilterData : state.oldFilterData),
//                 rightSidebarFormData: (action.payload.rightSidebarFormData !== undefined ? action.payload.rightSidebarFormData : state.rightSidebarFormData),
//                 fieldobjectformasterdata: (action.payload.fieldobjectformasterdata !== undefined ? action.payload.fieldobjectformasterdata : state.fieldobjectformasterdata),
//                 rightSidebarShimmer: (action.payload.rightSidebarShimmer !== undefined ? action.payload.rightSidebarShimmer : state.rightSidebarShimmer),
//                 masterData: (action.payload.masterData !== undefined) ? action.payload.masterData : state.masterData,
//                 masterDataList: (action.payload.masterDataList !== undefined) ? action.payload.masterDataList : state.masterDataList,
//                 modal: (action.payload.modal !== undefined) ? action.payload.modal : state.modal,
//                 menuData: (action.payload.menuData !== undefined ? action.payload.menuData : state.menuData),
//                 secondarySourceMasterData: (action.payload.secondarySourceMasterData !== undefined ? action.payload.secondarySourceMasterData : state.secondarySourceMasterData),
//                 reservationDetails: (action.payload.reservationDetails !== undefined ? action.payload.reservationDetails : state.reservationDetails),
//                 logininfo: (action.payload.logininfo !== undefined ? action.payload.logininfo : state.logininfo),
//                 loading: (action.payload.loading !== undefined ? action.payload.loading : state.loading),
//                 notificationCount: (action.payload.notificationCount !== undefined ? action.payload.notificationCount : state.notificationCount),
//                 userList: (action.payload.userList !== undefined ? action.payload.userList : state.userList),
//             }
//         case 'clearData':
//             return {
//                 ...state,
//                 data: []
//             }
//         case 'clearmasterData':
//             return {
//                 ...state,
//                 masterData: []
//             }
//         case 'updateData':
//             return {
//                 ...state,
//                 data: (action.payload.data !== undefined) ? action.payload.data : state.data
//             }
//         case 'setLoading':
//             return {
//                 ...state,
//                 loading: action.payload.loading
//             }


//         default:
//             return state
//     }
// }

// export default appReducer
import { IISMethods } from "../config/IISMethods"

const initialState = {
    data: [],
    fieldOrder: [],
    fltPageCollection: [],
    newFieldOrder: [],
    pagelimit: 20,
    pageNo: 1,
    nextpage: 1,
    formName: '',
    pageName: '',
    sortData: {},
    formData: {},
    chatList: {},
    propertyData: {},
    filterMasterData: {},
    filterMasterDataList: {},
    filterData: {},
    oldFilterData: {},
    rightSidebarFormData: [],
    fieldobjectformasterdata: {},
    rightSidebarShimmer: false,
    masterData: {},
    masterDataList: {},
    modal: {},
    menuData: [],
    secondarySourceMasterData: '',
    reservationDetails: {},
    logininfo: {},
    loading: 0,
    loadings: {},
    notificationCount: IISMethods.getLocalData("notificationCount") || 0,
    userList: []
}

const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setProps':
            return {
                ...state,
                data: (action.payload.data !== undefined ? state.data.concat(action.payload.data) : state.data),
                fieldOrder: (action.payload.fieldOrder !== undefined ? action.payload.fieldOrder : state.fieldOrder),
                fltPageCollection: (action.payload.fltPageCollection !== undefined ? action.payload.fltPageCollection : state.fltPageCollection),
                newFieldOrder: (action.payload.newFieldOrder !== undefined ? action.payload.newFieldOrder : state.newFieldOrder),
                pagelimit: (action.payload.pagelimit !== undefined ? action.payload.pagelimit : state.pagelimit),
                pageNo: (action.payload.pageNo !== undefined ? action.payload.pageNo : state.pageNo),
                nextpage: (action.payload.nextpage !== undefined ? action.payload.nextpage : state.nextpage),
                formName: (action.payload.formName !== undefined ? action.payload.formName : state.formName),     // keval 7-4-2023
                pageName: (action.payload.pageName !== undefined ? action.payload.pageName : state.pageName),
                sortData: (action.payload.sortData !== undefined ? action.payload.sortData : state.sortData),
                formData: (action.payload.formData !== undefined ? action.payload.formData : state.formData),
                chatList: (action.payload.chatList !== undefined) ? action.payload.chatList : state.chatList,
                propertyData: (action.payload.propertyData !== undefined ? action.payload.propertyData : state.propertyData),
                filterMasterData: (action.payload.filterMasterData !== undefined ? action.payload.filterMasterData : state.filterMasterData),
                filterMasterDataList: (action.payload.filterMasterDataList !== undefined ? action.payload.filterMasterDataList : state.filterMasterDataList),
                filterData: (action.payload.filterData !== undefined ? action.payload.filterData : state.filterData),
                oldFilterData: (action.payload.oldFilterData !== undefined ? action.payload.oldFilterData : state.oldFilterData),
                rightSidebarFormData: (action.payload.rightSidebarFormData !== undefined ? action.payload.rightSidebarFormData : state.rightSidebarFormData),
                fieldobjectformasterdata: (action.payload.fieldobjectformasterdata !== undefined ? action.payload.fieldobjectformasterdata : state.fieldobjectformasterdata),
                rightSidebarShimmer: (action.payload.rightSidebarShimmer !== undefined ? action.payload.rightSidebarShimmer : state.rightSidebarShimmer),
                masterData: (action.payload.masterData !== undefined) ? action.payload.masterData : state.masterData,
                masterDataList: (action.payload.masterDataList !== undefined) ? action.payload.masterDataList : state.masterDataList,
                modal: (action.payload.modal !== undefined) ? action.payload.modal : state.modal,
                menuData: (action.payload.menuData !== undefined ? action.payload.menuData : state.menuData),
                secondarySourceMasterData: (action.payload.secondarySourceMasterData !== undefined ? action.payload.secondarySourceMasterData : state.secondarySourceMasterData),
                reservationDetails: (action.payload.reservationDetails !== undefined ? action.payload.reservationDetails : state.reservationDetails),
                logininfo: (action.payload.logininfo !== undefined ? action.payload.logininfo : state.logininfo),
                loading: (action.payload.loading !== undefined ? action.payload.loading : state.loading),
                notificationCount: (action.payload.notificationCount !== undefined ? action.payload.notificationCount : state.notificationCount),
                userList: (action.payload.userList !== undefined ? action.payload.userList : state.userList),
            }

        case 'clearData':
            return {
                ...state,
                data: []
            }

        case 'clearmasterData':
            return {
                ...state,
                masterData: []
            }

        case 'updateData':
            return {
                ...state,
                data: (action.payload.data !== undefined) ? action.payload.data : state.data
            }

        case 'setLoading':
            return {
                ...state,
                loading: action.payload.loading
            }

        case 'setLoadings':
            return {
                ...state,
                loadings: action.payload.loadings
            }

        default:
            return state
    }
}

export default appReducer