import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { store } from '..'
import { Config, IISMethods, JsCall, pagelimit, resBody, setReqParams } from '../config/IISMethods'
import { clearData, setLoading, setProps } from '../redux/actions'
import { useNavigate, useLocation } from 'react-router-dom'
import useFirebase from '../ServiceWorker/useFirebase'
import useAPICall from '../hooks/useApiCall'
import SurveyView from '../view/SurveyView'


//for item counter 
let initialstate = 'listrequest'
const initialItemQty = 1

const SurveyController = (props) => {
    const { getReservationDetails, getAccessToken, getPropertyData } = useAPICall()

    const location = useLocation()
    const getCurrentState = () => {
        return store.getState()['root']
    }

    // for notification request
    const { requestPermission } = useFirebase()

    const history = useNavigate();
    const [itemqty, setItemqty] = useState(initialItemQty)
    const [page, setPage] = useState(initialstate)
    const [ratingData, setRatingData] = useState({})
    const [fromData, setFromData] = useState({})
    const [load, setLoad] = useState(false)
    console.log('load', load)
    const reservationDetails = getCurrentState().reservationDetails
    const propertyid = getCurrentState().propertyData["_id"] || ""
    const urlparams = new URLSearchParams(window.location.search)
    const stype = urlparams?.get('stype')
    const rcategoryid = urlparams?.get('rcid')
    const sid = urlparams?.get('sid')

    useEffect(() => {
        (async function () {
            await props.setProps({
                fieldOrder: [],
                newFieldOrder: [],
                nextpage: 0,
                pageNo: 1,
                pagelimit: 5,
                pageName: '',
                formName: '',
                sortData: {},
                formData: {},
                filterData: {},
                oldFilterData: {},
                modal: {},
                // rightSidebarFormData : [],
                masterData: {},
                masterDataList: {}
            })
            //(DO NOT CHANGE WITHOUT PERMISSION)charmi(06-09-2023 12:35)(listing api calling issue fix & install application modal open issue fix)
            await props.clearData()
            await getAccessToken()
            await getReservationDetails()
            if (getCurrentState().reservationDetails.ischeckin === 1) {
                await getPropertyData()
                if (getCurrentState().reservationDetails['isreservationexpired'] !== 1) {
                    await requestPermission()
                }
            }
            //(DO NOT CHANGE WITHOUT PERMISSION)charmi(06-09-2023 12:35)(listing api calling issue fix & install application modal open issue fix)
        })()
    }, [])


    //reservation id and propertyid
    useEffect(() => {
        (async () => {
            if (getCurrentState().reservationDetails?._id && getCurrentState().propertyData["_id"]) {
                await getList()
            }
            let obj = {}
            let pendingdata = getCurrentState().masterDataList['pendingdata']
            let submitteddata = getCurrentState().masterDataList['submittedData']
            if ((location.state || urlparams.size > 0) && (pendingdata?.length > 0 || pendingdata?.length === 0) && (submitteddata?.length > 0 || submitteddata?.length === 0)) {
                let surveytype = urlparams.size > 0 ? stype : location.state.guestexpsurveytype
                let surveyid = urlparams.size > 0 ? sid : location.state.guestexpsurveyid
                let ratingcategoryid = urlparams.size > 0 ? rcategoryid : location.state.guestexpratingcategoryid
                let alllistsurvey = pendingdata.concat(submitteddata)
                if (parseInt(surveytype) === 1) {
                    obj = IISMethods.getobjectfromarray(alllistsurvey, "taskid", surveyid)
                }
                else if (parseInt(surveytype) === 2) {
                    obj = IISMethods.getobjectfromarray(alllistsurvey, "complaintid", surveyid)
                }
                else if (parseInt(surveytype) === 3) {
                    obj = IISMethods.getobjectfromarray(alllistsurvey, "lostfoundid", surveyid)
                }
                else if (parseInt(surveytype) === 4) {
                    obj = IISMethods.getobjectfromarray(alllistsurvey, "reservationid", surveyid)
                }
                if (obj.ratingstatus === 1) {
                    await setFormData(ratingcategoryid, "surveyrating", "ratingmodal", obj)
                } else {
                    await setFormData(ratingcategoryid, "surveyrating", "submittedratingmodal", obj)
                }
            }

        })()
    }, [getCurrentState().reservationDetails?._id, getCurrentState().propertyData["_id"], location.state])


    //delete modal click
    const handleDeleteData = () => {
        if (document.getElementById('deletemodalinput').value === 'DELETE') {
            deleteData(getCurrentState().formData)
            handleGrid(false, 'modal', 'deletemodal', 0)
        }
    }

    //for grid scroll event - pagination
    const handleScroll = async (e) => {
        let element = e.target
        if (element.scrollHeight - element.scrollTop === element.clientHeight || ((element.scrollHeight - element.scrollTop) - 1 === element.clientHeight)) {
            if (getCurrentState().nextpage && (getCurrentState().pageNo * getCurrentState().pagelimit === getCurrentState().data.length)) {
                await props.setProps({ pageNo: getCurrentState().pageNo + 1 })
                getList()
            }
        }
    }

    //select picker printing 
    const printSelectPicker = (data, fieldobj) => {
        return (
            `${data[fieldobj.masterdatafield]}`
        )
    }


    //for selectpicker data print and api call 
    const getMasterData = async (pageNo, fieldobj, formData, storemasterdatabyfield) => {
        var filter = {}
        var isdepent = 0
        if (fieldobj.dependentfilter) { //dependent filter set - object set
            Object.keys(fieldobj.dependentfilter).map(key => {
                const value = formData[fieldobj.dependentfilter[key]]
                if (value) {
                    isdepent = 1
                    filter[key] = value
                }
            })
        }
        if (fieldobj.staticfilter) { //static filter object assign
            Object.assign(filter, fieldobj.staticfilter)
        }

        const masterdatakey = (fieldobj.storemasterdatabyfield || storemasterdatabyfield ? fieldobj.field : fieldobj.masterdata)

        if (!fieldobj.masterdatadependancy || isdepent == 1) { //show only non dependent dropdown or dependent dropdown dependency fullfill
            const url = Config.weburl + fieldobj.apipath
            const useraction = 'list' + fieldobj.masterdata + 'data'

            filter = {
                ...IISMethods.getCopy(fieldobj.filter),
                ...filter,
            }

            var reqBody = {
                paginationinfo: {
                    pageno: pageNo,
                    pagelimit: '*',
                    filter: filter,
                    sort: {}
                }
            }
            if (fieldobj.parantstaticfilter) {
                reqBody = Object.assign(fieldobj.parantstaticfilter, reqBody)
            }
            await setReqParams(url, reqBody)
            await IISMethods.listData(useraction, true)

            if (resBody.status === 200) {
                getCurrentState().masterData = IISMethods.getCopy(getCurrentState().masterData)
                getCurrentState().masterDataList = IISMethods.getCopy(getCurrentState().masterDataList)

                if (pageNo === 1) {
                    getCurrentState().masterData[masterdatakey] = []
                    getCurrentState().masterDataList[masterdatakey] = []
                }
                
                

                resBody.data.map((data) => {
                    let role = {}
                    if (fieldobj.groupwise) {
                        // for groupwise dropdown
                        role = { role: data[fieldobj.groupfield] }

                    }
                    getCurrentState().masterData[masterdatakey].push({ label: printSelectPicker(data, fieldobj), value: data._id, ...role })
                })
                // resBody.data.map((data) => {
                //     getCurrentState().masterData[masterdatakey].push({ label: printSelectPicker(data, fieldobj), value: data._id })
                // }
                // )
                getCurrentState().masterDataList[masterdatakey] = getCurrentState().masterDataList[masterdatakey].concat(resBody.data)
                await props.setProps({ masterData: getCurrentState().masterData, masterDataList: getCurrentState().masterDataList })
                if (resBody.nextpage)
                    await getMasterData(pageNo + 1, fieldobj, formData, storemasterdatabyfield)
            }
            else {
            }
        }
        else {
            delete getCurrentState().masterData[masterdatakey]
            delete getCurrentState().masterDataList[masterdatakey]
            await props.setProps({ masterData: IISMethods.getCopy(getCurrentState().masterData), masterDataList: IISMethods.getCopy(getCurrentState().masterDataList) })
        }
    }

    // List Data
    const getList = async () => {
        if (!getCurrentState().data.length > 0) {
            await props.setProps({ formName: '' })
        }

        const url = Config.weburl + props.pagename
        const useraction = 'l'//for listing
        let filter = {}
        let filterData = IISMethods.getCopy(getCurrentState().filterData)

        Object.entries(filterData).forEach(([key, value]) => {
            if (typeof (value) === 'number') {
                if (value !== 0) {
                    filter[key] = value
                }
            }
            else {
                filter[key] = value
            }
        })

        // pass payload for guestrequest listing
        let searchtext = filter.searchtext || ''
        delete filter.searchtext
        const reqBody = {
            "reservationid": reservationDetails["_id"],
            "propertyid": propertyid,
        }

        await setReqParams(url, reqBody)
        await IISMethods.listData(useraction)

        if (resBody.status === 200) {
            if (getCurrentState().pageNo === 1) {
                await props.clearData()
            }
            await props.setProps({
                masterDataList: { "pendingdata": resBody['pendingdata'], "submittedData": resBody['submittedData'] },
                pageNo: resBody.pageno,
                pagelimit: resBody.pagelimit,
                nextpage: resBody.nextpage,
                pageName: resBody.pagename,
                formName: resBody.formname,
                // loading: 
            })

            // if (location.state?.taskid) {
            //     await setSecondaryFormData(location.state?.taskid)
            //     await setPage("requestprogress")
            // }
        }
        else {
        }
    }

    const getRatingData = async () => {
        setLoad(true)

        const url = Config.weburl + "masters/ratings"
        const useraction = 'l'//for listing
        let filter = { "propertyid": propertyid, "ratingcategoryid": getCurrentState().modal["ratingmodal"] }

        const reqBody = {
            paginationinfo: {
                pageno: 1,
                pagelimit: '*',
                filter: filter,
                sort: {}
            }
        }
        await setReqParams(url, reqBody)
        await IISMethods.listData(useraction)

        if (resBody.status === 200) {
            setLoad(false)
            if (getCurrentState().pageNo === 1) {
                await props.clearData()
            }
            let tempmasterdatalist = IISMethods.getCopy(getCurrentState().masterDataList)
            tempmasterdatalist['surveyrating'] = resBody.data
            await props.setProps({
                masterDataList: IISMethods.getCopy(tempmasterdatalist),
                pageNo: resBody.pageno,
                pagelimit: resBody.pagelimit,
                nextpage: resBody.nextpage,
                pageName: resBody.pagename,
                formName: resBody.formname
            })

            if (location.state?.taskid) {
                await setSecondaryFormData(location.state?.taskid)
                await setPage("requestprogress")
            }
        }
        else {
            setLoad(true)
        }
    }

    //form value prepare - onblur & onclick
    const handleFormData = async (type, key, value, fieldtype, fieldkey, fieldvalue) => {
        if (type === 'dropdown') {
            const fieldobj = JsCall.getobjectfromformdata(getCurrentState().rightSidebarFormData, key)
            let getObjFromArray = IISMethods.getobjectfromarray(getCurrentState().masterDataList[fieldobj.masterdata], '_id', value)

            if (fieldobj.masterdataarray) {
                getCurrentState().formData[key] = value ? value : ''
            }
            else {
                try {
                    getCurrentState().formData[fieldobj.formdatafield] = IISMethods.getobjectfromarray(getCurrentState().masterDataList[fieldobj.masterdata], '_id', value)[fieldobj.masterdatafield]
                    getCurrentState().formData[key] = value
                }
                catch {
                    delete getCurrentState().formData[fieldobj.formdatafield]
                    delete getCurrentState().formData[key]
                }
            }

            if (fieldobj.extrafield) {
                fieldobj.extrafield.map((item) => {
                    getCurrentState().formData[item] = getObjFromArray[item]
                })
            }
        }

        // else if (key === 'description') {
        //     getCurrentState().formData[key] = value
        // }
        else if (type === 'input-textarea') {
            getCurrentState().formData[key] = value
        }

        else if (type === "add-remove-star") {
            if (fieldtype === 'tags') {
                let tagdata = IISMethods.getindexfromarray(getCurrentState().formData[key][fieldkey][fieldtype], 'tagid', value)
                if (tagdata === -1) {
                    let tagdata = {}
                    tagdata['tag'] = fieldvalue
                    tagdata['tagid'] = value
                    getCurrentState().formData[key][fieldkey][fieldtype].push(tagdata)
                }
                else {
                    getCurrentState().formData[key][fieldkey][fieldtype] = getCurrentState().formData[key][fieldkey][fieldtype].filter(item => item.tagid !== value)
                }
            }
            else {
                if (getCurrentState().formData[key][fieldkey]['stars'] == 1 && value == 0) {
                    getCurrentState().formData[key][fieldkey]['stars'] = value
                }
                else {
                    getCurrentState().formData[key][fieldkey]['stars'] = value + 1
                }
            }

            // let b = getCurrentState().masterDataList.guestrequestrating[fieldkey]
            // let a = IISMethods.getCopy(b.tagdata.find(item => item['stars'] === getCurrentState().formData[key][fieldkey]['stars']))

            // if (a) {
            //     getCurrentState().formData['ratingbtnlink'] = a.ratingbtnlink
            //     getCurrentState().formData['ratingbtnname'] = a.ratingbtnname
            // }
            // else {
            //     delete getCurrentState().formData['ratingbtnlink']
            //     delete getCurrentState().formData['ratingbtnname']
            // }
        }

        // for guestrequest item counter add-remove
        else if (type === "counter") {
            if (value === "inc") {
                setItemqty(itemqty + 1)
            } else if (value === "dec" && itemqty > 1) {
                setItemqty(itemqty - 1)
            } else if (value === "submit") {
                if (getCurrentState().formData.productsubcategoryid === '' || getCurrentState().formData.productsubcategoryid === undefined) {
                    IISMethods.localnotify('Fill required fields.')
                }
                else if (getCurrentState().formData.productsubcategoryid !== Config.othersubcategory && (getCurrentState().formData.productid === '' || getCurrentState().formData.productid === undefined)) {
                    IISMethods.localnotify('Fill required fields.')
                }
                else if (getCurrentState().formData.productsubcategoryid === Config.othersubcategory && (getCurrentState().formData.description === '' || getCurrentState().formData.description === undefined)) {
                    IISMethods.localnotify('Please add description.')
                }
                else {
                    let formdata = getCurrentState().formData[key] || []
                    let finditem = formdata.find(item => item._id === getCurrentState().formData.productid)
                    if (finditem) {
                        getCurrentState().formData[key] = formdata.map(item => {
                            if (item._id && (item._id === getCurrentState().formData.productid)) {
                                item.quantity = itemqty
                                item.description = getCurrentState().formData.description
                            }
                            else if (!item._id) {
                                item.quantity = 0
                                item.description = getCurrentState().formData.description
                            }
                            return item
                        })
                    } else {
                        // add button click set payload
                        getCurrentState().formData[key] =
                            formdata.concat({
                                _id: getCurrentState().formData.productid,
                                productname: getCurrentState().formData.product ? getCurrentState().formData.product : 'Other Product',
                                quantity: !getCurrentState().formData.productid ? 0 : itemqty,
                                productsubcategory: getCurrentState().formData.productsubcategory,
                                productsubcategoryid: getCurrentState().formData.productsubcategoryid,
                                productcategory: getCurrentState().formData.productcategory,
                                productcategoryid: getCurrentState().formData.productcategoryid,
                                description: getCurrentState().formData.description
                            })
                    }
                    if (document.getElementById('form-' + 'description')) {
                        document.getElementById('form-' + 'description').value = ''
                    }
                    delete getCurrentState().formData.product
                    delete getCurrentState().formData.productid
                    delete getCurrentState().formData.productsubcategory
                    delete getCurrentState().formData.productsubcategoryid
                    delete getCurrentState().formData.description
                    setItemqty(initialItemQty)
                }
            }
            else if (value === 'removeitem') {
                getCurrentState().formData[key] = getCurrentState().formData[key].filter(item => item[fieldkey] !== fieldvalue)
            }
        }
        else {
            getCurrentState().formData[key] = value
        }

        await props.setProps({ formData: IISMethods.getCopy(getCurrentState().formData) })

        JsCall.validateForm(getCurrentState().formData, getCurrentState().rightSidebarFormData, key)
        const obj = JsCall.getobjectfromformdata(getCurrentState().rightSidebarFormData, key)
        if (obj.onchangefill) {
            for (const field of obj.onchangefill) {
                const obj2 = JsCall.getobjectfromformdata(getCurrentState().rightSidebarFormData, field)
                await getMasterData(1, obj2, getCurrentState().formData)
            }
        }
    }

    //edit button formdata set - props set 
    const setFormData = async (id, jsoncase, modalname, data) => {
        setRatingData(data)
        getCurrentState().rightSidebarFormData = props.masterjson(jsoncase)
        var tempFormData = {}
        if (id && modalname === 'withdrawmodal') {
            tempFormData = IISMethods.getCopy(IISMethods.getobjectfromarray(getCurrentState().data, '_id', id))
        }
        else {
            //formdata set 
            getCurrentState().rightSidebarFormData && getCurrentState().rightSidebarFormData.map((data) => {
                return data.formfields.map((fields) => {
                    if (fields.type === 'dropdown') {
                    }
                    else {
                        tempFormData[fields.field] = fields.defaultvalue !== undefined ? fields.defaultvalue : ''
                    }
                })
            }
            )
        }
        await props.setProps({ formData: IISMethods.getCopy(tempFormData) })

        //error handling
        for (const data of getCurrentState().rightSidebarFormData) {
            for (const fields of data.formfields) {
                JsCall.handleError('form-' + fields.field, false)
            }
        }
        //error handling
        handleGrid(id, 'modal', modalname, 1, data)
        await getRatingData()
        if (modalname === "ratingmodal") {

            // if(data["ratingcategoryid"] === Config.ratingcategoryid.housekeepingratings){
            tempFormData['taskid'] = data.taskid
            tempFormData['taskguestrating'] = []
            getCurrentState().masterDataList['surveyrating'].map((data, index) => {
                let tempdata = {}
                tempdata['ratingid'] = data._id
                tempdata['stars'] = 0
                tempdata['rating'] = data.rating
                tempdata['comments'] = data.comments ? data.comments : ''
                tempdata['tags'] = []
                return tempFormData['taskguestrating'].push(tempdata)
            })
            setFromData(tempFormData)
            await props.setProps({ formData: IISMethods.getCopy(tempFormData) })
        }
    }

    // for handle filter data field srusthi 14-6-23
    const handleFilterData = async (type, key, value, formfield) => {
        if (type === 'multipleselectdropdown') {
            const fieldobj = JsCall.getobjectfromformdata(getCurrentState().rightSidebarFormData, key)
            try {
                if (value.length > 0) {
                    value = value.map((fieldid) => {
                        return IISMethods.getobjectfromarray(getCurrentState().masterDataList[fieldobj.masterdata], '_id', fieldid)['statustype']
                    })
                }
            }
            catch {
                value = []
            }
            getCurrentState().filterData[key] = value
        }

        // else if (type === 'filter-reset-data') {
        //     delete getCurrentState().oldFilterData['taskstatustype']
        //     delete getCurrentState().oldFilterData['fromdate']
        //     delete getCurrentState().oldFilterData['todate']
        //     delete getCurrentState().filterData['taskstatustype']
        //     delete getCurrentState().filterData['fromdate']
        //     delete getCurrentState().filterData['todate']
        //     //when resetfield remove defaultvisibility of "todate" JENIL 28/6/2023 7:43 PM
        //     getCurrentState().rightSidebarFormData[0].formfields[2].defaultvisibility = false
        // }
        // else if (type === 'daterangepicker') {
        //     if (value) {
        //         let fromdate = IISMethods.ConvertDateObjTOISOString(IISMethods.setStartTimeOfDay(value[0]))
        //         let todate = IISMethods.ConvertDateObjTOISOString(IISMethods.setEndTimeOfDay(value[1]))
        //         getCurrentState().filterData['fromdate'] = value ? fromdate : ''
        //         getCurrentState().filterData['todate'] = value ? todate : ''
        //     }
        //     else if (!value) {
        //         delete getCurrentState().filterData['fromdate']
        //         delete getCurrentState().filterData['todate']
        //     }
        //     else {
        //         getCurrentState().filterData = {}
        //     }
        // } else if (type === "datepicker") {
        //     //datepicker type if value has then set otherwise blank JENIL 28/6/2023 6:33 PM
        //     if (key === 'fromdate') {
        //         getCurrentState().filterData[key] = value ? IISMethods.ConvertDateObjTOISOString(IISMethods.setStartTimeOfDay(value)) : ""
        //         //todate defaultvisibillity hide/show when from date
        //         getCurrentState().rightSidebarFormData[0].formfields[2].defaultvisibility = value ? true : false
        //     } else {
        //         getCurrentState().filterData[key] = value ? IISMethods.ConvertDateObjTOISOString(IISMethods.setEndTimeOfDay(value)) : ""
        //     }
        // }
        else {
            getCurrentState().filterData[key] = value
        }
        //when reset field, fields should be reset JENIL 28/6/2023 6:33 PM
        if (formfield?.resetfield) {
            formfield.resetfield.forEach((item) => {
                getCurrentState().filterData[item] = ""
            })
        }
        await props.setProps({ filterData: IISMethods.getCopy(getCurrentState().filterData) })
    }

    //click handle filter button click JENIL 28/6/2023 7:12 PM
    const handleAddFilterButtonClick = () => {
        if (JsCall.validateForm(getCurrentState().filterData, getCurrentState().rightSidebarFormData)) {
            IISMethods.notify("Fill all required data", 2)
        } else {
            handleGrid(false, 'modal', 'requestfiltermodal', 0)
            handleApplyFilterData()
            getList()
        }
    }

    // jatin 14-08-2023 5:48 PM
    const handleApplyFilterButtonClick = async (e, type) => {
        if (e) e.preventDefault()
        if (type === 'removevalue') {
            getCurrentState().filterData['searchtext'] = ''
            await props.setProps({ pageNo: 1, oldFilterData: IISMethods.getCopy(getCurrentState().filterData) })
            await props.clearData()
            await getList()
        }
        else {
            await props.setProps({ pageNo: 1, oldFilterData: IISMethods.getCopy(getCurrentState().filterData) })
            await props.clearData()
            await getList()
        }
    }
    // jatin 15-08-2023 11:53 PM
    const handleSerachClose = async () => {
        getCurrentState().filterData["searchtext"] = " "
        await props.setProps({ filterData: IISMethods.getCopy(getCurrentState().filterData), pageNo: 1 })
        await getList()

    }

    // for handle apply button  filter data store in old filter data srusthi 14-6-23
    const handleApplyFilterData = async () => {
        await props.setProps({ oldFilterData: IISMethods.getCopy(getCurrentState().filterData) })
    }

    // for set button old filter data store in filter data srusthi 14-6-23
    const setFilterData = async (jsoncase, modalname) => {
        getCurrentState().rightSidebarFormData = props.masterjson(jsoncase)
        //when filter modal open fromdate is not null then show "todate" defaultvisibility true JENIL 28/6/2023 7:23 PM
        if (modalname === "requestfiltermodal") {
            if (getCurrentState().filterData["fromdate"]) {
                getCurrentState().rightSidebarFormData[0].formfields[2].defaultvisibility = true
            }
        }
        handleGrid(true, 'modal', modalname, 1)
        await props.setProps({ filterData: IISMethods.getCopy(getCurrentState().oldFilterData) })

        //select picker data call
        for (const data of getCurrentState().rightSidebarFormData) {
            for (const fields of data.formfields) {
                if (fields.masterdata && !fields.masterdataarray) {
                    if (fields.masterdatadependancy || !getCurrentState().masterData[fields.masterdata]) //
                        await getMasterData(1, fields, getCurrentState().filterData) //
                }
            }
        }
    }

    const setSecondaryFormData = async (id) => {
        var tempFormData = {}
        tempFormData = IISMethods.getCopy(IISMethods.getobjectfromarray(getCurrentState().data, '_id', id))
        await props.setProps({ secondarySourceMasterData: tempFormData })
    }

    //for open modal - recordinfo - create button
    const handleGrid = (id, type, key, value, editeDataIndex) => {
        setRatingData(editeDataIndex)
        if (type === 'modal') {
            if (value) {
                getCurrentState().modal[key] = id
            }
            else {
                delete getCurrentState().modal[key]
                //when rating modal close surveyrating should be empty JATIN 19/8/2023 18:27 PM
                if(key === "ratingmodal" || key === "submittedratingmodal"){
                    getCurrentState().masterDataList["surveyrating"] = []
                }
            }
            props.setProps({ modal: IISMethods.getCopy(getCurrentState().modal), masterDataList: IISMethods.getCopy(getCurrentState().masterDataList) })
        }
        else { }
    }

    //form data prepare - state set - button click event 
    const handleAddButtonClick = async (e, modalName, link) => {
        e.preventDefault()
        Object.keys(getCurrentState().formData).map((key) => {
            if (typeof getCurrentState().formData[key] === 'string') {
                getCurrentState().formData[key] = getCurrentState().formData[key].trim()
            }
        })

        if (JsCall.validateForm(getCurrentState().formData, getCurrentState().rightSidebarFormData)) {
            IISMethods.notify("Fill all required data", 2)
        } else {
            //to pass the fields with empty value
            // let tempdata = IISMethods.getCopy(getCurrentState().formData)
            let tempdata = {}
            if (ratingData['ratingcategoryid'] === Config.ratingcategoryid.lostratings) {
                tempdata["lostandfoundguestratings"] = getCurrentState().formData['taskguestrating']
                tempdata["guestratingsuggestion"] = getCurrentState().formData['suggestion']
                tempdata["lostfoundid"] = ratingData['lostfoundid']
            }
            else if (ratingData['ratingcategoryid'] === Config.ratingcategoryid.complaintratings) {
                tempdata["complaintguestrating"] = getCurrentState().formData['taskguestrating']
                tempdata["suggestion"] = getCurrentState().formData['suggestion']
                tempdata["complaintid"] = ratingData['complaintid']
            }
            else if (ratingData['ratingcategoryid'] === Config.ratingcategoryid.checkinratings) {
                tempdata["reservationcheckinrating"] = getCurrentState().formData['taskguestrating']
                tempdata["checkinsuggestion"] = getCurrentState().formData['suggestion']
                tempdata["reservationid"] = ratingData['reservationid']
            }
            else if (ratingData['ratingcategoryid'] === Config.ratingcategoryid.checkoutratings) {
                tempdata["reservationcheckoutrating"] = getCurrentState().formData['taskguestrating']
                tempdata["checkoutsuggestion"] = getCurrentState().formData['suggestion']
                tempdata["reservationid"] = ratingData['reservationid']
            }
            else {
                tempdata = IISMethods.getCopy(getCurrentState().formData)
            }

            if (getCurrentState().formData._id) {
                await updateData(tempdata, modalName)
            }
            else {

                await addData(tempdata, modalName, link)
            }
            //reset formdata variable after api called JENIL 28/6/2023 5:25 PM
            getCurrentState().rightSidebarFormData.map((data) =>
                data.formfields.map((fields) => {
                    if (!document.getElementById('form-' + fields.field)) {
                        if (fields.type === 'radio')
                            getCurrentState().formData[fields.field] = fields.radiooptions[0].value
                        else if (fields.type === 'dropdown') {
                            if (fields.masterdataarray) {
                                getCurrentState().formData[fields.field] = ''
                            }
                            else {
                                delete getCurrentState().formData[fields.field]
                                delete getCurrentState().formData[fields.formdatafield]
                            }
                        }
                        else
                            getCurrentState().formData[fields.field] = fields.defaultvalue || ''
                    }
                    if (fields.istablefield) {
                        delete getCurrentState().formData[fields.field]
                        delete getCurrentState().formData[fields.formdatafield]
                    }
                })
            )
            await props.setProps({ formData: IISMethods.getCopy(getCurrentState().formData) })
        }
    }

    //insert operation
    const addData = async (reqData, modalName, link) => {
        await props.setLoading({ loading: 1 })
        const url = Config.weburl + ratingData["apipath"]
        const useraction = 'guestrequest'

        await setReqParams(url, reqData)
        await IISMethods.addData(useraction, '', true)
        if (resBody.status === 200) {
            await props.setProps({ pageNo: 1 })
            await props.clearData()
            // await props.setProps({ pageName: '' })
            handleGrid(false, 'modal', modalName, 0)
            await getList()
            await props.setLoading({ loading: 0 })
            //if rating submit get link then redirect to new page JENIL 12/9/2023 5:40 PM
            if (link) window.open(link, '_blank')
        }
        else {
            await props.setLoading({ loading: 0 })
        }
    }

    //update operation
    const updateData = async (reqData, modalName) => {
        await props.setLoading({ loading: 1 })
        const url = Config.weburl + 'task/request/withdraw'
        const useraction = 'listhousekeeping'

        await setReqParams(url, reqData)
        await IISMethods.updateData(useraction)
        if (resBody.status === 200) {
            handleGrid(false, 'modal', modalName, 0)
            await props.setProps({ pageNo: 1 })
            await props.clearData()
            await props.setProps({ pageName: '' })
            getList()
            await props.setLoading({ loading: 0 })
        }
        else {
            await props.setLoading({ loading: 0 })
        }
    }

    //delete operation
    const deleteData = async (reqData) => {
        await props.setLoading({ loading: 1 })
        const url = Config.weburl + props.apipagename + '/delete'
        const useraction = 'delete' + props.pagename + 'data'

        await setReqParams(url, reqData)
        await IISMethods.deleteData(useraction)
        if (resBody.status === 200) {
            await props.setProps({ pageNo: 1 })
            await props.clearData()
            await getList()
            await props.setLoading({ loading: 0 })
        }
        else {
            await props.setLoading({ loading: 0 })
        }
    }

    return (
        <>
            <SurveyView
                handleDeleteData={handleDeleteData}
                handleScroll={handleScroll}
                handleFormData={handleFormData}
                setFormData={setFormData}
                handleAddButtonClick={handleAddButtonClick}
                handleGrid={handleGrid}
                itemqty={itemqty}
                setSecondaryFormData={setSecondaryFormData}
                updateData={updateData}
                page={page}
                setPage={setPage}
                handleFilterData={handleFilterData}
                getList={getList}
                setFilterData={setFilterData}
                handleApplyFilterData={handleApplyFilterData}
                handleAddFilterButtonClick={handleAddFilterButtonClick}
                handleApplyFilterButtonClick={handleApplyFilterButtonClick}
                handleSerachClose={handleSerachClose}
                fromData={fromData}
                ratingData={ratingData}
                load={load}
            />
        </>
    )
}

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    clearData: (payload) => dispatch(clearData(payload)),
    setProps: (payload) => dispatch(setProps(payload)),
    setLoading: (payload) => dispatch(setLoading(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SurveyController)