import React from 'react'
import ExpiredLink from '../assets/images/link-expired.png'

export default function LinkExpired() {
    return (
        <>
            <div className="row body-fix-height checkout-content">
                <div className="col-11 col-md-9 col-lg-10 mx-auto">
                    <div className='completed-content'>
                        {/* <div className="container-scheckout mt-0 mb-3"> */}
                            {/* <div className="d-flex justify-content-center align-items-center"> */}
                                {/* <img src={ExpiredLink} className='no-data-imgs' /> */}
                                <img src={ExpiredLink} className='scheckout-img' />
                            {/* </div> */}
                        {/* </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}
