import React, { useEffect, useState } from "react";
import { setProps } from "../redux/actions";
import { connect } from "react-redux";

import { Config, IISMethods, allowdedImage } from "../config/IISMethods";
import TvChannelLiupController from "../Controller/TvChannelLineUpController";

const TvchannelLineUp = (props) => {
    useEffect(() => {
        async function fetchMyAPI() {
            await props.setProps({
                rightSidebarFormData: [
                    {
                        pagename: "tvchannellineup",
                        formname: "TvChannel Lineup",
                        useraction: "mobilecheckin",
                        apipath: "property/guestexperience/propertywisechannel",
                        alias: "welcome",
                        formfields: [
                            {
                                field: "categoryid",
                                text: "Category",
                                type: Config.getHtmlcontorls()["dropdown"],
                                disabled: false,
                                defaultvisibility: true,
                                apipath:
                                    "property/guestexperience/channelcategory",
                                masterdata: "channelcategory",
                                masterdatafield: "channelcategory",
                                formdatafield: "category",
                                required: true,
                                gridsize: Config.getGridclasses()["col-12"],
                                staticfilter: { isactive: 1 },
                            },
                        ],
                    },
                ],
            });
        }

        fetchMyAPI();
    }, []);

    return (
        <TvChannelLiupController
            pagename="tvchannellineup"
            apipath={props.apipath}
            backpage={false}
        />
    );
};

const mapStateToProps = (state) => ({
    ...state,
});

const mapDispatchToProps = (dispatch) => ({
    setProps: (payload) => dispatch(setProps(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TvchannelLineUp);
