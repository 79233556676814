
import React, { useEffect, useState } from 'react'
import { Config, IISMethods, resBody, setReqParams } from '../config/IISMethods'
import 'rsuite/dist/rsuite.css'
import { store } from '..'
import SelectPickerRsuite from './Components/SelectPickerRsuite'
import ModalRsuite from './Components/ModalRsuite'
import Rating from '../assets/images/rating.svg'
// import Loader from './Components/Loader';
import { Loader } from 'rsuite';
import MultipleSelectDropdown from './Components/MultipleSelectDropdown'
import DateRangePickerRsuite from './Components/DateRangePickerRsuite'
import { FloatingLabel } from 'react-bootstrap'
import DatePickerRsuite from './Components/DatePickerRsuite'
import { isBefore } from "date-fns"

const form = 'form-'
const star_fill = <svg className='star star-curser' xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 64 61.335">
    <path id="star_13_" data-name="star (13)" d="M63.833,23.669A3.4,3.4,0,0,0,60.9,21.33L42.433,19.653l-7.3-17.1a3.4,3.4,0,0,0-6.256,0l-7.3,17.094L3.093,21.33a3.406,3.406,0,0,0-1.931,5.953L15.125,39.528,11.008,57.666a3.4,3.4,0,0,0,5.059,3.675L32,51.817l15.928,9.524a3.4,3.4,0,0,0,5.062-3.675L48.873,39.528,62.836,27.285a3.4,3.4,0,0,0,1-3.616Zm0,0" transform="translate(0 -0.491)" fill="#ffc107" />
</svg>
const star_unfill = <svg className='star star-curser' xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 64 61.335">
    <path id="star_14_" data-name="star (14)" d="M14.325,61.827a3.407,3.407,0,0,1-3.315-4.16l4.117-18.137L1.165,27.284a3.4,3.4,0,0,1,1.928-5.953l18.475-1.677,7.3-17.094a3.4,3.4,0,0,1,6.256,0l7.3,17.1L60.9,21.331a3.405,3.405,0,0,1,1.934,5.953L48.875,39.527l4.117,18.137a3.4,3.4,0,0,1-5.061,3.675L32,51.818,16.069,61.344a3.409,3.409,0,0,1-1.744.483ZM32,47.717a3.41,3.41,0,0,1,1.744.482L48.78,57.192,44.894,40.074a3.4,3.4,0,0,1,1.078-3.315L59.156,25.2,41.712,23.612a3.4,3.4,0,0,1-2.827-2.062L32,5.418,25.107,21.552a3.388,3.388,0,0,1-2.818,2.054L4.842,25.19,18.026,36.753A3.4,3.4,0,0,1,19.1,40.071L15.221,57.189,30.256,48.2A3.408,3.408,0,0,1,32,47.717ZM21.427,19.984a.008.008,0,0,1,0,.005Zm21.142-.008,0,.005S42.571,19.979,42.568,19.976Zm0,0" transform="translate(0 -0.492)" fill="#ffe188" />
</svg>

export default function LostRightsidebar(props) {
    // srushti
    const getCurrentState = () => {
        return store.getState()['root']
    }

    const [floorwise, setFloorwise] = useState([])
    // jatin 2:15 Pm For offline search in Room & Area Modal
    const [searchValue, setSearchValue] = useState('')
    const handleSearch = (e) => {
        setSearchValue(e.target.value)
    }
    const handleSearchClose = (e) => {
        setSearchValue('')
        var searchinput = document.querySelector('[class="form-control search-items"]')
        searchinput = document.getElementById(searchinput.id).value = "";
    }

    const handleFormRoomArea = (value, floorid) => {
        let assignmentfor = getCurrentState().formData.assignmentforid
        let fkey = assignmentfor === Config.assignmentfor.room ? 'floorwiseroom' : 'floorwisearea'
        let dkey = assignmentfor === Config.assignmentfor.room ? 'roomdata' : 'areadata'
        let ikey = assignmentfor === Config.assignmentfor.room ? 'roomno' : 'area'


        let floorwisedata = floorwise || []
        let floorobj = IISMethods.getobjectfromarray(getCurrentState().masterDataList[fkey], '_id', floorid)
        let valueobj = IISMethods.getobjectfromarray(floorobj[dkey], '_id', value)

        if (floorwisedata.length > 0) {
            let arrayindex = IISMethods.getindexfromarray(floorwisedata, ikey + 'id', value)

            if (arrayindex !== -1) {
                floorwisedata = floorwisedata.filter(data => data[ikey + 'id'] !== value)

            }
            else {
                let dataObj = {
                    'assignmentforid': assignmentfor,
                    'assignmentfor': assignmentfor === Config.assignmentfor.room ? 'Room' : 'Area',
                    'buildingid': getCurrentState().reservationDetails.buildingid,
                    'buildingname': getCurrentState().reservationDetails.buildingname,
                    'wingid': getCurrentState().reservationDetails.wingid,
                    'wingname': getCurrentState().reservationDetails.wingname,
                    'floorid': valueobj.floorid,
                    'floor': valueobj.floor,
                    [ikey + 'id']: valueobj._id,
                    [ikey]: valueobj[ikey]
                }
                if (Config.assignmentfor.room === assignmentfor) {
                    dataObj['roomtypeid'] = valueobj.roomtypeid
                    dataObj['roomtype'] = valueobj.roomtype
                    // dataObj['roomtypecolor'] = valueobj.roomtypecolor
                }
                else {
                    dataObj['areatypeid'] = valueobj.areatypeid
                    dataObj['areatype'] = valueobj.areatype
                    // dataObj['areatypecolor'] = valueobj.areatypecolor
                }
                floorwisedata = floorwisedata.concat([dataObj])
            }
        }
        else {
            let dataObj = {
                'assignmentforid': assignmentfor,
                'assignmentfor': assignmentfor === Config.assignmentfor.room ? 'Room' : 'Area',
                'buildingid': getCurrentState().reservationDetails.buildingid,
                'buildingname': getCurrentState().reservationDetails.buildingname,
                'wingid': getCurrentState().reservationDetails.wingid,
                'wingname': getCurrentState().reservationDetails.wingname,
                'floorid': valueobj.floorid,
                'floor': valueobj.floor,
                [ikey + 'id']: valueobj._id,
                [ikey]: valueobj[ikey]
            }
            if (Config.assignmentfor.room === assignmentfor) {
                dataObj['roomtypeid'] = valueobj.roomtypeid
                dataObj['roomtype'] = valueobj.roomtype
                // dataObj['roomtypecolor'] = valueobj.roomtypecolor
            }
            else {
                dataObj['areatypeid'] = valueobj.areatypeid
                dataObj['areatype'] = valueobj.areatype
                // dataObj['areatypecolor'] = valueobj.areatypecolor
            }
            floorwisedata = floorwisedata.concat([dataObj])
        }
        setFloorwise(floorwisedata)
    }

    const getColor = (colorCode, opacity) => {
        let r = colorCode?.r || 0
        let g = colorCode?.g || 0
        let b = colorCode?.b || 0
        let a = opacity ? opacity : colorCode?.a
        return `rgba(${r}, ${g}, ${b}, ${a})`
    }

    // srushti 
    const renderHtmlControls = (formfield) => {
        if (formfield && (formfield.defaultvisibility)) {
            if (formfield.type === 'dropdown') { // for dropdown
                return (
                    <div className={formfield.gridsize}>
                        <div className="form-group validate-input">
                            <label className=" guest-request-maintenance-lable">{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}</label>
                            <SelectPickerRsuite
                                cleanable={formfield.cleanable}
                                // searchable={formfield.searchable}
                                placeholder={`Select ${formfield.text}`}
                                className="form-control has-val p-0"
                                data={getCurrentState().masterData[formfield.masterdata]}
                                id={"form-" + formfield.field}
                                name={formfield.field}
                                onChange={(e) => props.handleFormData(formfield.type, formfield.field, e)}
                                value={getCurrentState().formData[formfield.field]}
                            />
                        </div>
                    </div>
                )
            }

            else if (formfield.type === 'radio') { // for radio
                return (
                    <div className={formfield.gridsize}>
                        <div className="form-group validate-input">
                            <label className="guest-request-maintenance-lable">{formfield.text}{formfield.required}</label>
                            <div className="row">
                                <div className="col-12">
                                    <div className="task-btn-custom-control " id={"form-" + formfield.field}>

                                        {formfield.field &&
                                            getCurrentState().masterData[formfield.masterdata]?.map((data, index) => {
                                                if (getCurrentState().formData._id && formfield.field !== "priorityid") {
                                                    if (getCurrentState().formData[formfield.field] === data.value) {
                                                        return (
                                                            <div className="custom-control " key={data.value}>
                                                                <input disabled={formfield.disabled} type="radio" id={formfield.field + data.value}
                                                                    readOnly name={formfield.field + data.value} className="custom-control-input" checked={getCurrentState().formData[formfield.field] === data.value} />

                                                                <label className="custom-control-label m-1 radio-label" htmlFor={formfield.field + data.value}
                                                                    onClick={() => !getCurrentState().formData._id && props.handleFormData(formfield.type, formfield.field, data.value)
                                                                        // && props.handleRoomAreaItems(data.value)
                                                                    }>{data.label}</label>
                                                            </div>
                                                        )
                                                    } else {
                                                        return <></>
                                                    }
                                                } else {
                                                    return (
                                                        <div className="custom-control" key={data.value}>
                                                            <input disabled={formfield.disabled} type="radio" id={formfield.field + data.value}
                                                                readOnly name={'form-' + formfield.field} className="custom-control-input"
                                                                defaultChecked={getCurrentState().formData[formfield.field] === data.value || index === 0}
                                                            />
                                                            {formfield.field === "priorityid"
                                                                ? <label className="custom-control-label m-1 radio-label" htmlFor={formfield.field + data.value} onClick={() => props.handleFormData(formfield.type, formfield.field, data.value)
                                                                    // && props.handleRoomAreaItems(data.value)
                                                                }
                                                                    style={{
                                                                        backgroundColor: getCurrentState().formData[formfield.field] === data.value ? `${getColor(getCurrentState().formData.color)}` : "white",
                                                                        color: getCurrentState().formData[formfield.field] === data.value ? "black" : "#000000"
                                                                    }}>{data.label}</label>
                                                                : <label className="custom-control-label radio-label" htmlFor={formfield.field + data.value} onClick={() => props.handleFormData(formfield.type, formfield.field, data.value)
                                                                    // && props.handleRoomAreaItems(data.value)
                                                                }
                                                                >{data.label}</label>
                                                            }
                                                        </div>
                                                    )
                                                }
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

            else if (formfield.type === 'tree-label-picker') { // for tree-label
                return <div className="tree-tag-picker px-3 mb-3">
                    {getCurrentState().masterDataList?.floorwisearea?.map((data, index) => {
                        return <div className="tree-tag-control pt-3">
                            <label className="tree-tag-label">{data.floor}</label>
                            <div className="tree-label-box mt-1">
                                {data.areadata?.filter((data) => {
                                    // jatin 10-08-2023 for offline Search
                                    let serachfields = ["area"]
                                    let findvalue = serachfields.map(item => {
                                        let datafield = data[item].toLowerCase()
                                        let searchtext = searchValue.toLowerCase()
                                        if (datafield.includes(searchtext)) return true
                                        return data[item]
                                    })
                                    if (!searchValue || findvalue.some(item => item === true)) return true
                                    else return false
                                }).map((item, i) => {
                                    let dataindex = -1
                                    let opacity
                                    let backgroundcolor
                                    let textcolor
                                    if (floorwise?.length > 0) {
                                        dataindex = IISMethods.getindexfromarray(floorwise, 'areaid', item._id)
                                    }
                                    opacity = dataindex !== -1 ? 1 : item.isdisabled === 1 ? 0.3 : 0.5
                                    backgroundcolor = dataindex !== -1 ? item.areatypecolor : { r: 255, g: 255, b: 255, a: 1 }
                                    textcolor = dataindex !== -1 ? { r: 255, g: 255, b: 255, a: 1 } : item.areatypecolor
                                    return <label
                                        key={i}
                                        className="tree-label-text content-center px-4"
                                        style={{
                                            borderWidth: 2,
                                            borderStyle: 'solid',
                                            borderColor: getColor(item.areatypecolor, opacity),
                                            background: getColor(backgroundcolor, opacity),
                                            color: getColor(textcolor, opacity)
                                        }}
                                        onClick={() => {
                                            if (!item.isdisabled) handleFormRoomArea(item._id, data._id)
                                        }}
                                    >{item.area}</label>
                                })}

                            </div>
                        </div>
                    })}
                </div>
            }

            else if (formfield.type === 'tree-tag-picker') { // for tree-tag
                return <div className="tree-tag-picker px-3 mb-3">
                    {getCurrentState().masterDataList?.floorwiseroom?.map((data, index) => {
                        return <div className="tree-tag-control pt-3">
                            <label className="tree-tag-label">{data.floor}</label>
                            <div className="tree-label-box mt-1">
                                {data.roomdata?.filter((data) => {
                                    // jatin 10-08-2023 for offline Search
                                    let serachfields = ["roomno"]
                                    let findvalue = serachfields.map(item => {
                                        let datafield = data[item].toString().toLocaleLowerCase()
                                        let searchtext = searchValue.toString().toLocaleLowerCase()
                                        if (datafield.includes(searchtext)) return true
                                        return data[item]
                                    })
                                    if (!searchValue || findvalue.some(item => item === true)) return true
                                    else return false
                                }).map((item, i) => {
                                    let dataindex = -1
                                    let opacity
                                    let backgroundcolor
                                    let textcolor
                                    if (floorwise?.length > 0) {
                                        dataindex = IISMethods.getindexfromarray(floorwise, 'roomnoid', item._id)
                                    }
                                    opacity = dataindex !== -1 ? 1 : item.isdisabled === 1 ? 0.3 : 0.5
                                    backgroundcolor = dataindex !== -1 ? item.roomtypecolor : { r: 255, g: 255, b: 255, a: 1 }
                                    textcolor = dataindex !== -1 ? { r: 255, g: 255, b: 255, a: 1 } : item.roomtypecolor
                                    return <label
                                        key={i}
                                        className="tree-label-text content-center px-4"
                                        style={{
                                            borderWidth: 2,
                                            borderStyle: 'solid',
                                            borderColor: getColor(item.roomtypecolor, opacity),
                                            background: getColor(backgroundcolor, opacity),
                                            color: getColor(textcolor, opacity)
                                        }}
                                        onClick={() => {
                                            if (!item.isdisabled) handleFormRoomArea(item._id, data._id)
                                        }}
                                    >{item.roomno}</label>
                                })}
                            </div>
                        </div>
                    })}
                </div>
            }

            else if (formfield.type === 'multipletagpicker') { // for mutlitag picker
                if (['tasksubcategoryid', 'roomtypeid', 'areatypeid'].includes(formfield.field)) {
                    return (
                        <div className={formfield.gridsize}>
                            <div className="task-tag-custom-control px-0 mb-2">
                                {getCurrentState().masterDataList[formfield.masterdata]?.map((data, index) => {
                                    let masterdata = data
                                    let formdata = getCurrentState().formData[formfield.field] ? getCurrentState().formData[formfield.field] : getCurrentState().formData['lostdetails']

                                    var bg_r
                                    var bg_g
                                    var bg_b
                                    var bg_a

                                    var txt_r
                                    var txt_g
                                    var txt_b
                                    var txt_a

                                    try {
                                        bg_r = masterdata.backgroundcolor?.r
                                        bg_g = masterdata.backgroundcolor?.g
                                        bg_b = masterdata.backgroundcolor?.b
                                        bg_a = masterdata.backgroundcolor?.a

                                        txt_r = masterdata.textcolor?.r
                                        txt_g = masterdata.textcolor?.g
                                        txt_b = masterdata.textcolor?.b
                                        txt_a = masterdata.textcolor?.a
                                    }
                                    catch {
                                    }
                                    let bgcolor = `rgba(${bg_r},${bg_g},${bg_b},${bg_a})`
                                    let textcolor = `rgba(${txt_r},${txt_g},${txt_b},${txt_a})`
                                    let bordercolor = bgcolor
                                    let backgroundcolor = formdata.includes(data._id) ? bgcolor : textcolor
                                    let color = formdata.includes(data._id) ? textcolor : bgcolor

                                    return <div className="custom-control-tag" key={data._id}>
                                        <input disabled={formfield.disabled} type="checkbox" id={formfield.field + data._id}
                                            readOnly name={formfield.field + data._id} className="custom-control-input" />
                                        <label className={`${formfield.field === "tasksubcategoryid" ? "custom-label" : "custom-label-rt"} `} style={{
                                            borderRadius: '14px',
                                            border: `1px solid ${bordercolor}`,
                                            backgroundColor: backgroundcolor,
                                            color: color
                                        }} htmlFor={formfield.field + data._id} onClick={() => {
                                            props.handleFormData(formfield.type, formfield.field, data._id)
                                        }}>{data[formfield.masterdatafield]}</label>
                                    </div>
                                })
                                }
                            </div>
                        </div>
                    )
                } else {
                    return (
                        <div className={formfield.gridsize}>
                            <div className="form-group validate-input">
                                <label className="label-form-control">{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}</label>
                                <div className="row">
                                    <div className="col-12" >
                                        <div className="task-btn-custom-control border-0 d-flex" id={"form-" + formfield.field} >
                                            {
                                                getCurrentState().masterData[formfield.masterdata].map((data, index) => {
                                                    return (
                                                        <div className="custom-control custom-ckeckbox mb-3 mr-3 bg-white" key={index}>
                                                            <input disabled={formfield.disabled} type="checkbox" id={formfield.field + data.value}
                                                                readOnly name={formfield.field + data.value} className="custom-control-input" checked={getCurrentState().formData[formfield.field]?.includes(data.value)} />
                                                            <label className="custom-control-label-checkbox mb-0 text-center mr-2 rounded-circle content-center" htmlFor={formfield.field + data.value}
                                                                onClick={() => props.handleFormData(formfield.type, formfield.field, data.value)}>{data.label.substring(0, 3)}</label>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            }

            else if (formfield.type === 'input-textarea') // for textarea
            {
                return (
                    <div className={formfield.gridsize}>
                        <div className="form-group validate-input">
                            <label className="guest-request-maintenance-lable">{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}</label> {/* keval 6-4-2023*/}
                            <textarea
                                // disabled={disabled}
                                type="text"
                                onChange={(e) => props.handleFormData(formfield.type, formfield.field, e.target.value)}
                                value={getCurrentState().formData[formfield.field]}
                                className="form-control"
                                id={"form-" + formfield.field}
                                name={formfield.field}
                                autoComplete="off"
                                placeholder={`Enter ${formfield.text}`}
                            />
                        </div>
                    </div>
                )
            }

            else if (formfield.type === 'input-text') // for text
            {
                return (
                    <div className={formfield.gridsize}>
                        <div className="form-group validate-input">
                            <label className="guest-request-maintenance-lable">{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}</label> {/* keval 6-4-2023*/}
                            <input
                                // disabled={disabled}
                                type="text"
                                onChange={(e) => props.handleFormData(formfield.type, formfield.field, e.target.value)}
                                value={getCurrentState().formData[formfield.field]}
                                className="form-control"
                                id={"form-" + formfield.field}
                                name={formfield.field}
                                autoComplete="off"
                                placeholder={`Enter ${formfield.text}`}
                            />
                        </div>
                    </div>
                )
            }

            else {
                return <></>
            }
        }
    }

    const renderFilterHtmlControls = (formfield) => {
        if (formfield && (formfield.defaultvisibility)) {
            if (formfield.type === 'multipleselectdropdown') { // for multipleselect dropdown
                let value = getCurrentState().filterData[formfield.field] || []
                return (
                    <div className={formfield.gridsize} >
                        <div className="form-group validate-input">
                            <label className=" guest-request-maintenance-lable">{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}</label>
                            <MultipleSelectDropdown
                                cleanable={formfield.cleanable}
                                placeholder={`Select ${formfield.text}`} className="form-control has-val p-0"
                                data={getCurrentState().masterData[formfield.masterdata]}
                                id={"form-" + formfield.field}
                                value={value}
                                name={formfield.field}
                                onChange={(e) => props.handleFilterData(formfield.type, formfield.field, e, formfield)}
                                selectall={formfield.selectall}
                            />
                        </div>
                    </div>
                )
            } else if (formfield.type === 'datepicker') {
                let options = {
                    disabledDate: (date) => {
                        let fromdate = IISMethods.GetDateTimefromISOString(IISMethods.GetTZdatetime(date).toISOString())
                        if (formfield.disablefromdate) {
                            let selectedDate = IISMethods.GetDateTimefromISOString(formfield.disablefromdate)
                            selectedDate.setDate(selectedDate.getDate())
                            return isBefore(fromdate, selectedDate)
                        }
                        else if (formfield.disablefromfield) {
                            let selectedDate = IISMethods.GetDateTimefromISOString(getCurrentState().filterData[formfield.disablefromfield])
                            selectedDate.setDate(selectedDate.getDate())
                            return isBefore(fromdate, selectedDate)
                        }
                    }
                }
                let value = getCurrentState().filterData[formfield.field]
                return (
                    <div className={formfield.gridsize} >
                        <div className="form-group validate-input">
                            <label className="guest-request-maintenance-lable">{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}</label>
                            <DatePickerRsuite
                                className="form-control p-0 has-val"
                                placeholder={`Enter ${formfield.text}`}
                                format={IISMethods.getDateFormatByProperty()}
                                id={"form-" + formfield.field}
                                name={formfield.field}
                                value={value ? IISMethods.GetDT(IISMethods.GetISOdate(value)) : ''}
                                type="text"
                                onChange={(e) => props.handleFilterData(formfield.type, formfield.field, e, formfield)}
                                cleanable={formfield.cleanable}
                                disabled={formfield.disabled}
                                ranges={[]}
                                {...options}
                            />
                        </div>
                    </div>
                )
            }
            else {
                return <></>
            }
        }
    }

    var rightSidebarFormData
    var rightSidebar
    var pageName
    var formData
    var formname
    var filterData
    var oldFilterData

    filterData = IISMethods.getCopy(getCurrentState().filterData)
    oldFilterData = IISMethods.getCopy(getCurrentState().oldFilterData)

    var dateValueFrom = filterData['fromfilterdate']
    var dateValueTo = filterData['tofilterdate']
    var dateValue = (dateValueFrom && dateValueTo) ? [dateValueFrom, dateValueTo] : []

    try {
        rightSidebar = 'rightSidebar'
        rightSidebarFormData = getCurrentState().rightSidebarFormData
        pageName = getCurrentState().rightSidebarFormData.pageName
        formname = 'form-'
        formData = IISMethods.getCopy(getCurrentState().rightSidebarFormData.formData)
    }
    catch {
    }

    try {
        return (
            <>
                {/* for lost main form modal */}
                <ModalRsuite
                    size={"xs"}
                    open={getCurrentState().modal['lostModal']}
                    onClose={() => props.handleGrid(false, 'modal', 'lostModal', 0)}
                    title={
                        <div className="model-header icon">
                            <span className="closeicon">
                                Report Lost
                            </span>
                        </div>
                    }
                    body={
                        <div className="col-12">
                            <div className="row">
                                {renderHtmlControls(rightSidebarFormData[0]?.formfields[0])}
                                {renderHtmlControls(rightSidebarFormData[0]?.formfields[1])}
                                {renderHtmlControls(rightSidebarFormData[0]?.formfields[2])}

                                {/* for area click handle */}
                                {(getCurrentState().formData?.assignmentforid === Config.assignmentfor.area) &&
                                    <div className="col-12 col-md-12 mt-auto mb-3 content-center">
                                        <button className="btn-first-popup mb-0 bg-dark area-btn" onClick={async () => {
                                            await props.getAreaList()
                                            setFloorwise(getCurrentState().formData.lostdetails)
                                        }}>Add Area</button>
                                    </div>
                                }

                                {/* for room click handle */}
                                {(getCurrentState().formData?.assignmentforid === Config.assignmentfor.room) &&
                                    <div className="col-12 col-md-12 mt-auto mb-3 content-center">
                                        <button className="btn-first-popup mb-0 bg-dark area-btn" onClick={async () => {
                                            await props.getRoomList()
                                            setFloorwise(getCurrentState().formData.lostdetails)
                                        }}>Add Room</button>
                                    </div>
                                }

                                {/* for area detail show */}
                                {getCurrentState().formData?.lostdetails?.map((areadata, i) => {
                                    return getCurrentState().masterDataList.floorwisearea?.map((floorwisedata, i) => {
                                        return floorwisedata?.areadata.filter(data => data._id === areadata.areaid).map((area, i) => {
                                            let textcolor = area.areatypecolor
                                            return (
                                                <div className='col-6'>
                                                    <div className='position-relative'>
                                                        <span className='remove-roomarea' style={{ color: getColor(textcolor, 1) }}
                                                            onClick={() => props.handleRemoveAreaItems(area._id)}
                                                        >
                                                            <i className="material-icons-round">cancel</i>
                                                        </span>
                                                        <label
                                                            className='tree-label-text content-center px-2 border-radius-5 '
                                                            style={{
                                                                borderWidth: 2, borderStyle: 'solid',
                                                                borderColor: getColor(textcolor, 1),
                                                                color: getColor(textcolor, 1),
                                                                backgroundColor: getColor(textcolor, 0.1)
                                                            }}
                                                        >{area.area}</label>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    })
                                })
                                }

                                {/* for room detail show */}
                                {(getCurrentState().formData?.assignmentforid === Config.assignmentfor.room) &&
                                    <>
                                        {getCurrentState().formData?.lostdetails?.map((roomdata, i) => {
                                            let roomtype = IISMethods.getobjectfromarray(getCurrentState().masterDataList['roomtype'], 'roomtype', roomdata?.roomtype)?.backgroundcolor
                                            return (
                                                <>
                                                    <div className='col-6'>
                                                        <div className='position-relative'>
                                                            <span className='remove-roomarea' style={{ color: getColor(roomtype, 1) }}
                                                                onClick={() => props.handleRemoveAreaItems(roomdata.roomnoid)}
                                                            >
                                                                <i className="material-icons-round">cancel</i>
                                                            </span>
                                                            <label
                                                                className='tree-label-text content-center px-2 border-radius-5 '
                                                                style={{
                                                                    borderWidth: 2, borderStyle: 'solid',
                                                                    borderColor: getColor(roomtype, 1),
                                                                    color: getColor(roomtype, 1),
                                                                    backgroundColor: getColor(roomtype, 0.1)
                                                                }}
                                                            >{roomdata.roomno}</label>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })
                                        }
                                    </>
                                }
                                {/* for json field show */}
                                {renderHtmlControls(rightSidebarFormData[0]?.formfields[5])}
                                {renderHtmlControls(rightSidebarFormData[0]?.formfields[6])}
                            </div>
                        </div>
                    }
                    footer={
                        // for add button click lost main lost modal
                        <div className="col-12 mt-1">
                            <button className="btn-first-popup mb-3" type="submit" onClick={(e) => { getCurrentState().loading === 0 && props.handleAddButtonClick(e, 'lostModal') }}>
                                {
                                    props.lostloader === true ? 
                                    <Loader  size='sm' content={'Submit'} /> 
                                    : "Submit"

                                }
                            </button>
                        </div>
                    }
                />

                {/* for lost area manage modal */}
                <ModalRsuite
                    size={"sm"}
                    open={getCurrentState().modal['lostareamodal']}
                    onClose={() => props.handleGrid(false, 'modal', 'lostareamodal', 0)}
                    title={
                        <div className="model-header icon">
                            <span className="closeicon">
                                Select Area
                            </span>
                        </div>
                    }
                    body={
                        <div className="col-12 mt-0 mb-0 px-0">
                            {renderHtmlControls(rightSidebarFormData[0]?.formfields[8])}
                            <div className="col-12 mt-1 ml-auto">
                                <div className="validate-input mb-1 position-relative">
                                    {
                                        searchValue?.length > 0 &&
                                        <label className="serachclose" onClick={(e) => { handleSearchClose(e) }}>
                                            <i className="material-icons-round text-12">close</i>
                                        </label>
                                    }
                                    <input className="form-control search-items" type="text" id="searchitemvalue" placeholder='Search Area' autoComplete='off' onChange={(e) => handleSearch(e)} />
                                </div>
                            </div>
                            {renderHtmlControls(rightSidebarFormData[0]?.formfields[7])}
                        </div>
                    }
                    footer={
                        <div className="col-12 mt-1 mb-3">
                            <div className='row'>
                                <div className='col-6 pr-2'>
                                    <button
                                        onClick={(e) => {
                                            props.handleGrid(false, 'modal', 'lostareamodal', 0)
                                        }}
                                        className="btn btn-secondary-light btn-sm button">
                                        Cancel
                                    </button>
                                </div>
                                <div className='col-6 pl-2'>
                                    <button
                                        onClick={(e) => {
                                            props.handleFormData('submit-floorwisedata', 'lostdetails', floorwise)
                                            setFloorwise({})
                                            props.handleGrid(false, 'modal', 'lostareamodal', 0)
                                        }}
                                        className="btn btn-primary-light btn-sm button">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                />

                {/* for lost room manage modal */}
                <ModalRsuite
                    size={"sm"}
                    open={getCurrentState().modal['lostroommodal']}
                    onClose={() => props.handleGrid(false, 'modal', 'lostroommodal', 0)}
                    title={
                        <div className="model-header icon">
                            <span className="closeicon">
                                Select room
                            </span>
                        </div>
                    }
                    body={
                        <div className="col-12 mt-0 mb-0 px-0">
                            {renderHtmlControls(rightSidebarFormData[0]?.formfields[10])}
                            <div className="col-12 mt-1 ml-auto">
                                <div className="validate-input mb-1 position-relative">
                                    {
                                        searchValue?.length > 0 &&
                                        <label className="serachclose" onClick={(e) => { handleSearchClose(e) }}>
                                            <i className="material-icons-round text-12">close</i>
                                        </label>
                                    }
                                    <input className="form-control search-items" type="text" id="searchitemvalue" placeholder='Search Room' autoComplete='off' onChange={(e) => handleSearch(e)} />
                                </div>
                            </div>
                            {renderHtmlControls(rightSidebarFormData[0]?.formfields[9])}
                        </div>
                    }
                    footer={
                        <div className="col-12 mt-1 mb-3">
                            <div className='row'>
                                <div className='col-6 pr-2'>
                                    <button
                                        onClick={(e) => {
                                            props.handleGrid(false, 'modal', 'lostroommodal', 0)
                                        }}
                                        className="btn btn-secondary-light btn-sm button">
                                        Cancel
                                    </button>
                                </div>
                                <div className='col-6 pl-2'>
                                    <button
                                        onClick={(e) => {
                                            props.handleFormData('submit-floorwisedata', 'lostdetails', floorwise)
                                            setFloorwise({})
                                            props.handleGrid(false, 'modal', 'lostroommodal', 0)
                                        }}
                                        className="btn btn-primary-light btn-sm button">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                />


                {/* Comment By Bhautik 13-06-2023 02:55 PM ~ */}
                {/* {getCurrentState().modal["lostfiltermodal"]
                    && */}
                {/* srushti 11-06-2023 lost-filter modal */}
                <ModalRsuite
                    size={"xs"}
                    open={getCurrentState().modal['lostfiltermodal']}
                    onClose={(e) => { props.handleGrid(false, 'modal', 'lostfiltermodal', 0); }}
                    title={
                        <div className="model-header icon">
                            <span className="closeicon">
                                Apply Filter
                            </span>
                        </div>
                    }
                    body={
                        <>
                            <div className="col-12 mt-0 mb-0 px-0">
                                {renderFilterHtmlControls(rightSidebarFormData[0]?.formfields[0])}
                                {renderFilterHtmlControls(rightSidebarFormData[0]?.formfields[1])}
                                {renderFilterHtmlControls(rightSidebarFormData[0]?.formfields[2])}
                                {/* {renderHtmlControls(rightSidebarFormData[0]?.formfields[1])} */}
                            </div>
                            {/* <div className="col-11 mt-0 mb-0 px-0 ml-3">
                                <label className=" guest-request-maintenance-lable">Date</label>
                                <DateRangePickerRsuite
                                    // cleanable={true}
                                    className="form-control p-0 has-val"
                                    placeholder={`Enter Date`}
                                    format={IISMethods.getDateFormatByProperty()}
                                    id='filter-viewdate'
                                    name='filter-viewdate' ranges={[]}
                                    value={dateValue}
                                    // value={dateValue?.map(date => IISMethods.stringToDate(date))}
                                    onChange={(e) => props.handleFilterData('daterangepicker', '', e)}

                                />
                            </div> */}
                        </>
                    }
                    footer={
                        <div className="col-12 mt-1 mb-3">
                            <div className='row'>
                                <div className='col-6 pr-2'>
                                    <button
                                        onClick={async () => {
                                            await props.handleGrid(false, 'modal', 'lostfiltermodal', 0);
                                            await props.handleFilterData('filter-reset-data', '', '')
                                            await props.getList('lostlist')
                                        }}
                                        className="btn btn-secondary-light btn-sm button">
                                        Reset
                                    </button>
                                </div>
                                <div className='col-6 pl-2'>
                                    <button
                                        onClick={(e) => {
                                            props.handleAddFilterButtonClick(e)
                                        }}
                                        className="btn btn-primary-light btn-sm button">
                                        <Loader content={'Apply'} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                />
                {/* } */}


                {/* Lost rating modal  */}
                {
                    getCurrentState().modal['lostratingmodal']
                    &&
                    <ModalRsuite
                        size={"xs"}
                        open={getCurrentState().modal['lostratingmodal']}
                        onClose={() => props.handleGrid(false, 'modal', 'lostratingmodal', 0)}
                        title={""}
                        body={
                            <form method='post'>
                                <div className={`col-12 text-center`}>
                                    <div className="first-pop-logo">
                                        <img src={Rating} />
                                    </div>
                                </div>
                                <div className="col-12 text-center">
                                    <h1 className="first-popup-title mt-2 mb-4">We Value Your Feedback</h1>
                                </div>
                                {
                                    getCurrentState().rightSidebarFormData[0]?.formfields.filter(formfield => {
                                        try {
                                            return formfield.defaultvisibility || getCurrentState().formData[formfield.condition.field] === formfield.condition.onvalue
                                        }
                                        catch {
                                            return false
                                        }
                                    }).map((formfield, fieldindex) => {
                                        if (formfield.type === 'dropdown') {
                                            return (
                                                <>
                                                    {/* vehicle modal rating 17-4-23 */}

                                                    {/* srushti 19-06-2023 rating tag print */}
                                                    {getCurrentState().masterDataList[formfield.masterdata]?.map((data, dataindex) => {
                                                        let tagdata = data.tagdata
                                                        let ratingdataobj = {}
                                                        ratingdataobj[dataindex] = []
                                                        let singlearray = ratingdataobj[dataindex]

                                                        for (const item of tagdata) {
                                                            if (getCurrentState().formData['lostandfoundguestratings'] && getCurrentState().formData['lostandfoundguestratings'][dataindex]['stars'] !== 0 && getCurrentState().formData['lostandfoundguestratings'][dataindex]['stars'] <= item.stars && item.abovebelowflag === 1) {
                                                                let temptagdataarray = singlearray.map(data => data.tagid)
                                                                let tagdata = item.tags.filter(data => !temptagdataarray.includes(data.tagid))
                                                                singlearray.push(...tagdata)
                                                            }
                                                            else if (getCurrentState().formData['lostandfoundguestratings'] && getCurrentState().formData['lostandfoundguestratings'][dataindex]['stars'] !== 0 && getCurrentState().formData['lostandfoundguestratings'][dataindex]['stars'] >= item.stars && item.abovebelowflag === 0) {
                                                                let temptagdataarray = singlearray.map(data => data.tagid)
                                                                let tagdata = item.tags.filter(data => !temptagdataarray.includes(data.tagid))
                                                                singlearray.push(...tagdata)
                                                            }
                                                        }
                                                        return (
                                                            <div className="col-12 mt-4" key={dataindex}>
                                                                <div className="rate-box">
                                                                    <div className="rate-box-content">
                                                                        <p>{data.rating}</p>
                                                                    </div>
                                                                    <div className="d-flex justify-content-between mx-5">
                                                                        {
                                                                            [...Array(5)].map((star, index) => {
                                                                                if (getCurrentState().formData['lostandfoundguestratings'] && getCurrentState().formData['lostandfoundguestratings'][dataindex]['stars'] - 1 >= index) {
                                                                                    return <div className=' my-3' key={index}
                                                                                        onClick={() => { props.handleFormData('add-remove-star', formfield.field, index, '', dataindex, data._id, '', formfield.masterdata) }}
                                                                                    >
                                                                                        {star_fill}
                                                                                    </div>
                                                                                } else {
                                                                                    return <div className=' my-3' key={index}
                                                                                        onClick={() => { props.handleFormData('add-remove-star', formfield.field, index, '', dataindex, data._id, '', formfield.masterdata) }}
                                                                                    >
                                                                                        {star_unfill}
                                                                                    </div>
                                                                                }
                                                                            })
                                                                        }
                                                                    </div>
                                                                    {
                                                                        singlearray?.length > 0
                                                                        &&
                                                                        <div className='ratingTagGrid'>
                                                                            {
                                                                                singlearray.map((tagitem, index) => {
                                                                                    let tagdata = IISMethods.getindexfromarray(getCurrentState().formData['lostandfoundguestratings'][dataindex]['tags'], 'tagid', tagitem.tagid)
                                                                                    return (
                                                                                        <div className="task-tag-custom-control" key={index}>
                                                                                            <div className="custom-control-tag ">
                                                                                                <label className={`custom-label-rt content-center ${(tagdata !== -1) ? "tagactive" : ''}`}
                                                                                                    onClick={() => props.handleFormData('add-remove-star', formfield.field, tagitem.tagid, 'tags', dataindex, tagitem.tag)}
                                                                                                    style={{
                                                                                                        borderRadius: '20px',
                                                                                                        border: `1px solid #9E9E9E`,
                                                                                                        // background : 'black'
                                                                                                    }} >
                                                                                                    {tagitem.tag}
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                    }
                                                </>
                                                // <>
                                                //     {/* vehicle modal rating 17-4-23 */}
                                                //     {getCurrentState().masterDataList[formfield.masterdata]?.map((data, dataindex) => {
                                                //         return (
                                                //             <div className="col-12 my-3">
                                                //                 <div className="rate-box">
                                                //                     <div className="rate-box-content">
                                                //                         <p>
                                                //                             {data.rating}
                                                //                         </p>
                                                //                     </div>
                                                //                     <div className="d-flex justify-content-between mx-3">
                                                //                         {
                                                //                             [...Array(5)].map((star, index) => {
                                                //                                 if (getCurrentState().formData['lostandfoundguestratings'] && getCurrentState().formData['lostandfoundguestratings'][dataindex]['stars'] - 1 >= index) {
                                                //                                     return <div className='my-3'
                                                //                                         onClick={() => { props.handleFormData('add-remove-star', formfield.field, index, '', dataindex, data._id) }}
                                                //                                     >
                                                //                                         {star_fill}
                                                //                                     </div>
                                                //                                 } else {
                                                //                                     return <div className='my-3'
                                                //                                         onClick={() => { props.handleFormData('add-remove-star', formfield.field, index, '', dataindex, data._id) }}
                                                //                                     >
                                                //                                         {star_unfill}
                                                //                                     </div>
                                                //                                 }
                                                //                             })
                                                //                         }
                                                //                     </div>
                                                //                     {data.tagdata?.map((item, index) => {
                                                //                         if (getCurrentState().formData['lostandfoundguestratings'] && getCurrentState().formData['lostandfoundguestratings'][dataindex]['stars'] !== 0 && getCurrentState().formData['lostandfoundguestratings'][dataindex]['stars'] <= item.stars && item.abovebelowflag === 1) {
                                                //                             return (
                                                //                                 <div className='ratingTagGrid' key={index}>
                                                //                                     {
                                                //                                         item.tags.map((tagitem, index) => {
                                                //                                             let tagdata = IISMethods.getindexfromarray(getCurrentState().formData['lostandfoundguestratings'][dataindex]['tags'], 'tagid', tagitem.tagid)
                                                //                                             return (
                                                //                                                 <div key={index}>
                                                //                                                     <div className="task-tag-custom-control">
                                                //                                                         <div className="custom-control-tag ">

                                                //                                                             <label className={`custom-label-rt content-center ${(tagdata !== -1) ? "tagactive" : ''}`}
                                                //                                                                 onClick={() => props.handleFormData('add-remove-star', formfield.field, tagitem.tagid, 'tags', dataindex, tagitem.tag)}
                                                //                                                                 style={{
                                                //                                                                     borderRadius: '20px',
                                                //                                                                     border: `1px solid #9E9E9E`,
                                                //                                                                     // background : 'black'
                                                //                                                                 }} >

                                                //                                                                 {tagitem.tag}
                                                //                                                             </label>
                                                //                                                         </div>
                                                //                                                     </div>
                                                //                                                 </div>
                                                //                                             )
                                                //                                         })
                                                //                                     }
                                                //                                 </div>
                                                //                             )
                                                //                         }
                                                //                         else if (getCurrentState().formData['lostandfoundguestratings'] && getCurrentState().formData['lostandfoundguestratings'][dataindex]['stars'] !== 0 && getCurrentState().formData['lostandfoundguestratings'][dataindex]['stars'] >= item.stars && item.abovebelowflag === 0) {

                                                //                             return (
                                                //                                 <div className='ratingTagGrid' key={index}>
                                                //                                     {
                                                //                                         item.tags.map((tagitem, index) => {
                                                //                                             let tagdata = IISMethods.getindexfromarray(getCurrentState().formData['lostandfoundguestratings'][dataindex]['tags'], 'tagid', tagitem.tagid)

                                                //                                             return (
                                                //                                                 <div key={index}>
                                                //                                                     <div className="task-tag-custom-control p-0">
                                                //                                                         <div className="custom-control-tag ">

                                                //                                                             <label className={`custom-label-rt content-center ${(tagdata !== -1) ? "tagactive" : ''}`}
                                                //                                                                 onClick={() => props.handleFormData('add-remove-star', formfield.field, tagitem.tagid, 'tags', dataindex, tagitem.tag)}
                                                //                                                                 style={{
                                                //                                                                     borderRadius: '20px',
                                                //                                                                     border: `1px solid #9E9E9E`,
                                                //                                                                     // background : 'black'
                                                //                                                                 }} >
                                                //                                                                 {tagitem.tag}
                                                //                                                             </label>
                                                //                                                         </div>
                                                //                                                     </div>
                                                //                                                 </div>
                                                //                                             )
                                                //                                         })
                                                //                                     }
                                                //                                 </div>
                                                //                             )
                                                //                         }
                                                //                     })
                                                //                     }
                                                //                 </div>
                                                //             </div>
                                                //         )
                                                //     })
                                                //     }
                                                // </>
                                            )
                                        }
                                        else if (formfield.type === 'input-textarea') // for textarea
                                        {
                                            return (
                                                <div className={formfield.gridsize} key={`${fieldindex}`}>
                                                    <div className="form-group validate-input mt-0">
                                                        <center><label className="first-popup-label mt-3 mb-3">{formfield.text}{formfield.required ? <span className="text-danger"> * </span> : <></>}</label></center>
                                                        <textarea
                                                            disabled={formfield.disabled} type="text" rows={3}
                                                            defaultValue={getCurrentState().formData[formfield.field]}
                                                            onChange={(e) => props.handleFormData(formfield.type, formfield.field, e.target.value, '', 0, 'suggestion')}
                                                            className="form-control form-control-textarea"
                                                            id={form + formfield.field}
                                                            name={formfield.field} autoComplete="off"
                                                            placeholder={`Enter ${formfield.text}`}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })
                                }
                            </form>
                        }
                        footer={
                            <>
                                <div className="col-12 mt-1">
                                    <button type="submit" className="btn-first-popup mb-3"
                                        onClick={(e) => {
                                            getCurrentState().loading === 0 && props.handleAddButtonClick(e, 'lostratingmodal', getCurrentState().formData?.ratingbtnlink)
                                        }} >
                                        <Loader content={'Submit'} />
                                    </button>
                                </div>
                            </>
                        }
                    />
                }

            </>
        )
    }
    catch (e) {
        console.log(e)
        return <></>
    }
}

