import React, { useEffect, useState } from 'react'
import Header from '../Header'
import { connect, useSelector } from 'react-redux'
import 'rsuite/dist/rsuite.css'
import { setProps } from '../redux/actions'
import LostRightsidebar from './LostRightsidebar'
import LostProgress from './LostProgress'
import BackHeader from '../BackHeader'
import Footer from '../Footer'
import { useLocation, useNavigate } from 'react-router-dom'
import LostList from './LostList'

let initialstate = 'listlost'

const LostView = (props) => {
    const { page, setPage } = props
    const location = useLocation()

    // srushti 05-06-23 set component condition-wise and same header/same router only change pagedata
    return (
        <>
            {/* back history not static link JENIL 13/9/2023 10:47 PM */}
            <BackHeader isHistoryBack={page === 'listlost' ? true : false} linkto={page === 'listlost' ? '/mystay' : location.pathname} backtopagename='Lost' onClick={() => setPage(initialstate)} handleFilterData={props.handleFilterData} handleApplyFilterButtonClick={props.handleApplyFilterButtonClick} handleSerachClose={props.handleSerachClose} />
            {
                page === "listlost" ?
                    <LostList
                        setSecondaryFormData={props.setSecondaryFormData}
                        // handleFormData={props.handleFormData}
                        setFormData={props.setFormData}
                        handleGrid={props.handleGrid}
                        handleScroll={props.handleScroll}
                        getAreaList={props.getAreaList}
                        getRoomList={props.getRoomList}
                        setPage={setPage}
                        handleAddButtonClick={props.handleAddButtonClick}
                        getList={props.getList}
                        handleViewFilterData={props.handleViewFilterData}
                        handleFilterData={props.handleFilterData}
                        setFilterData={props.setFilterData}
                    />
                    : page === "lostprogress" ?
                        <LostProgress
                            handleFormData={props.handleFormData}
                            setFormData={props.setFormData}
                            handleScroll={props.handleScroll}
                            handleGrid={props.handleGrid}

                        />
                        :
                        ''
            }
            <LostRightsidebar
                handleFormData={props.handleFormData}
                handleGrid={props.handleGrid}
                setFormData={props.setFormData}
                handleAddButtonClick={props.handleAddButtonClick}
                handleScroll={props.handleScroll}
                getAreaList={props.getAreaList}
                handleRemoveAreaItems={props.handleRemoveAreaItems}
                handleRemoveRoomItems={props.handleRemoveRoomItems}
                getRoomList={props.getRoomList}
                getList={props.getList}
                handleViewFilterData={props.handleViewFilterData}
                handleFilterData={props.handleFilterData}
                setFilterData={props.setFilterData}
                handleApplyFilterData={props.handleApplyFilterData}
                handleAddFilterButtonClick={props.handleAddFilterButtonClick}
                lostloader={props.lostloader}
            />
            <Footer />
        </>
    )
}

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    setProps: (payload) => dispatch(setProps(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(LostView)
