// import React, { useState } from 'react'
// import { connect } from 'react-redux'
// import { useNavigate } from 'react-router-dom'
// import { store } from '..'
// import { IISMethods } from '../config/IISMethods'
// import Location from '../assets/images/Location.png'
// import RightSidebar from './RightSidebar'
// import DndRightsidebar from './DndRightsidebar'
// import LateCheckOutRightSidebar from './LateCheckOutRightSidebar'
// import CheckOutRightSidebar from './CheckOutRightSidebar'
// import ExtendStayRightSidebar from './ExtendStayRightSidebar'
// import SecoundHouseKeepingRightSidebar from './SecoundHouseKeepingRightSidebar'
// import HousekeepingRating from './HouseKeepingRating'
// import Header from '../Header'
// import Footer from '../Footer'
// import { Placeholder } from 'rsuite'
// import { setProps } from '../redux/actions'

// const HomeView = (props) => {
//     const getCurrentState = () => {
//         return store.getState()
//     }

//     const queryString = IISMethods.getQueryString()
//     const history = useNavigate()
//     const [dndMenuData, setDndMenuData] = useState()

//     const handleRedirectMenu = (alias) => {
//         let menuindex = getCurrentState()?.menuData?.findIndex((a) => a.alias === alias)
//         let obj = { ...getCurrentState().menuData[menuindex] }
//         obj.ishighlight = 0
//         getCurrentState().menuData?.splice(menuindex, 1, obj);
//         props.setProps({ menuData: IISMethods.getCopy(getCurrentState().menuData) })

//         let pagelink = queryString

//         // reservation exprire conditin manage with hide and show menu details
//         if (!getCurrentState().reservationDetails.isguestcheckout || getCurrentState().reservationDetails.isguestcheckout === 0) {
//             if (alias === 'wifidetails') {
//                 history(`/wifi${pagelink}`)
//             }
//             else if (alias === 'guestrequest') {
//                 history(`/guestrequest${pagelink}`)
//             }
//             else if (alias === 'maintenance') {
//                 history(`/maintenance${pagelink}`)
//             }
//             else if (alias === 'reportproblem') {
//                 history(`/reportproblem${pagelink}`)
//             }
//             else if (alias === 'chatwithus') {
//                 history(`/chatwithus${pagelink}`)
//             }
//             else if (alias === 'dndon') {
//                 let dndmenudata = IISMethods.getobjectfromarray(getCurrentState().menuData, 'alias', alias)
//                 setDndMenuData(dndmenudata)
//                 props.setFormData(null, alias, alias + 'Modal')
//             }
//             else if (alias === 'housekeeping') {
//                 if (getCurrentState().reservationDetails.enablehousekeepingrating === 1) {
//                     props.setFormData(null, 'housekeepingrating', 'housekeepingratingModal')
//                 }
//                 else if (getCurrentState().reservationDetails.ischeckinday === 0) {
//                     props.setFormData(null, 'housekeeping', 'housekeepingModal')
//                 }
//                 else if (getCurrentState().reservationDetails.ischeckinday === 1) {
//                     props.setFormData(null, 'housekeepingtwo', 'housekeepingModal')
//                 }
//             }
//             else if (alias === 'checkout') {
//                 if (getCurrentState().reservationDetails.isguestexpcheckout === 1) {
//                     props.handleGrid(true, 'modal', 'checkoutRqstSubmitModal', 1)
//                 }
//                 else {
//                     props.setFormData(null, alias, alias + 'Modal')
//                 }
//                 // if (getCurrentState().reservationDetails.isguestcheckout === 0) {
//                 // props.setFormData(null, alias, alias + 'Modal')
//                 // }
//                 // else if (getCurrentState().reservationDetails.isguestcheckout === 1 && getCurrentState().reservationDetails.checkoutratingsubmitted === 0) {
//                 //     props.setFormData(null, 'checkoutrating', 'checkoutratingModal')
//                 // }
//                 // else if (getCurrentState().reservationDetails.isguestcheckout === 1 && getCurrentState().reservationDetails.checkoutratingsubmitted === 1) {
//                 //     history({ pathname: `/successfullycheckout` })
//                 // }
//             }
//             else if (alias === "extendstay") {
//                 if (getCurrentState().reservationDetails.isalreadyrequested === 0) {
//                     props.setFormData(null, 'extendstay', 'extendstayModal')
//                 }
//                 else if (getCurrentState().reservationDetails.isalreadyrequested === 1) {
//                     props.handleGrid(true, 'modal', 'verificationdatamodal', 1)
//                 }
//             }
//             else if (alias === "latecheckout") {
//                 //add navigate to open chat bot JENIL 7/7/2023 9:11 AM
//                 // history(`/chatbot${queryString}`)
//                 if (getCurrentState().reservationDetails.guestlatecheckoutrequest === 0) {
//                     props.setFormData(null, 'latecheckout', 'latecheckoutModal')
//                 }
//                 else if (getCurrentState().reservationDetails.guestlatecheckoutrequest === 1) {
//                     props.handleGrid(true, 'modal', 'latecheckoutdatamodal', 1)
//                 }
//             }
//             else {
//                 props.setFormData(null, alias, alias + 'Modal')
//             }
//         }
//         else if (getCurrentState().reservationDetails.isguestcheckout === 1) {
//             if (alias === 'reportproblem') {
//                 history(`/reportproblem${pagelink}`, { state: { pagename: 'reportproblem', goback: true } })
//             }
//             else if (alias === 'chatwithus') {
//                 history(`/chatwithus${pagelink}`)
//             }
//             else if (alias === 'checkout') {
//                 if (getCurrentState().reservationDetails.checkoutrequeststatus === 4 && getCurrentState().reservationDetails.isguestcheckout === 1 && getCurrentState().reservationDetails.checkoutratingsubmitted === 0) {
//                     props.setFormData(null, 'checkoutrating', 'checkoutratingModal')
//                 }
//                 else if (getCurrentState().reservationDetails.checkoutrequeststatus !== 4 && getCurrentState().reservationDetails.isguestcheckout === 1 && getCurrentState().reservationDetails.checkoutratingsubmitted === 0) {
//                     props.handleGrid(true, 'modal', 'checkoutMessageModal', 1)
//                 }
//                 else if (getCurrentState().reservationDetails.checkoutrequeststatus === 4 && getCurrentState().reservationDetails.isguestcheckout === 1 && getCurrentState().reservationDetails.checkoutratingsubmitted === 1) {
//                     history({ pathname: `/successfullycheckout` })
//                 }
//             }
//             else {
//                 IISMethods.localnotify("You have already checkout. You can't perform this action.")
//             }
//         }
//     }

//     //open chat bot 6/7/2023 4:03 PM
//     const handleOpenChatBot = () => {
//         history(`/chatbot${queryString}`)
//     }

//     const homeIcon = getCurrentState().menuData ? getCurrentState().menuData : []

//     return (
//         <>
//             <Header />
//             <div className="body-content">
//                 <div className="col-12">
//                     {/* <div className="row">
//                         <div className="col-12"> */}
//                     {
//                         // (getCurrentState().formName === '')
//                         // (getCurrentState().menuData.length === 0)
//                         // ?
//                         // <>
//                         //     <Placeholder.Graph active className='placeHolderHeight mt-3'></Placeholder.Graph>
//                         // </>
//                         // :
//                     }

//                     <div className="row">

//                         {/* home page main header title srushti 21-2-23 */}
//                         <div className="col-12 p-0">
//                             <div className="background-header-home gap-10">
//                                 <div className="home-header-left">
//                                     <p className="text-white customer-hello m-0">Hello,</p>
//                                     {getCurrentState().reservationDetails._id && <p className="text-white customer-name m-0">{getCurrentState().reservationDetails?.firstname} {getCurrentState().reservationDetails?.lastname}</p>}
//                                     <p className="text-white customer-checkout m-0">Check - Out</p>
//                                     {getCurrentState().reservationDetails._id && <p className="text-white customer-checkout m-0">{IISMethods.GetDateFormat(getCurrentState().reservationDetails?.checkoutdate)} At {getCurrentState().reservationDetails.islatecheckoutrequestaccepted === 1 ? IISMethods.GetTimeFormat(getCurrentState().reservationDetails?.latecheckoutdate) : IISMethods.GetTimeFormat(getCurrentState().propertyData?.timing?.checkouttime)} </p>}{/* charmi(05-06-2023) */}
//                                 </div>
//                                 <div className="tap-to-locate-block"
//                                     // srushti for after checkout do not able to seen room location map 21-6-23
//                                     onClick={() =>
//                                         getCurrentState().reservationDetails.isguestcheckout === 1 ?
//                                             IISMethods.localnotify("You have already checkout.you can't perform this action.")
//                                             :
//                                             getCurrentState().reservationDetails?.roomplan !== '' &&
//                                             props.handleGrid(true, 'modal', 'locationmodal', 1)
//                                     }
//                                 >
//                                     <div className='d-flex gap-10 justify-content-center'>
//                                         <img src={Location} alt="location" />
//                                         <p className="header-roomno">{getCurrentState().reservationDetails?.roomno}</p>
//                                     </div>
//                                     {getCurrentState().reservationDetails?.roomplan !== '' && <a className="tap-to-locate-label mt-2" >tap to locate me</a>}
//                                 </div>
//                             </div>
//                         </div>

//                         {/* home page header menu srushti 21-2-23 */}
//                         {getCurrentState().menuData?.length > 0 &&
//                             <div className="col-12 p-0">
//                                 <div className="header-menu">
//                                     <div className="row m-0">
//                                         {homeIcon.filter(obj => obj.sidebar === 1).sort((a, b) => a.order > b.order ? 1 : -1).map((data, index) => {
//                                             return (
//                                                 <a className="col-3 home-menu-border" key={index} onClick={() => { handleRedirectMenu(data.alias); }}>
//                                                     {/* <a className="col-3 home-menu-border" key={index} onClick={() => { props.setFormData(null, data.alias, data.alias + 'Modal'); handleRedirectMenu(data.alias) }}> */}
//                                                     <div className="home-menu-imgs">
//                                                         {(data.dashboardicon !== '' || data.dashboardicon !== undefined) && <img src={data.dashboardicon} />}
//                                                     </div>
//                                                     {data.ishighlight === 1 &&
//                                                         <span className='blink-red-dott'></span>}
//                                                     {(data.menuname !== '' || data.menuname !== undefined) && <span className="text-black home-menu-label-name">{data.menuname}</span>}
//                                                 </a>
//                                             )
//                                         })
//                                         }
//                                     </div>
//                                 </div>
//                             </div>
//                         }

//                         {/* home page body menu srushti 21-2-23 */}
//                         {getCurrentState().menuData?.length > 0 &&
//                             <div className="col-11 mx-auto mb-4 pr-0">
//                                 <div className="row">
//                                     {homeIcon.filter(obj => obj.dashboard === 1).sort((a, b) => a.order > b.order ? 1 : -1).map((data, index) => {
//                                         return (
//                                             <div className="col-4 col-lg-4 mt-3 home-modules" key={index}>
//                                                 {/* <div className="home-body-menu text-center" onClick={() => { props.setFormData(null, data.alias, data.alias + 'Modal'); handleRedirectMenu(data.alias) }}> */}
//                                                 <div className="home-body-menu text-center" onClick={() => { handleRedirectMenu(data.alias); }}>
//                                                     <div className="home-body-imgs">
//                                                         {(data.dashboardicon !== '' || data.dashboardicon !== undefined) && <img src={data.dashboardicon} />}
//                                                     </div>
//                                                     {
//                                                         //blink menu is hightlight = 1 in chatwithus
//                                                         // data.alias === "chatwithus" &&
//                                                         data.ishighlight === 1 &&
//                                                         <span className='blink-red-dot'></span>
//                                                     }
//                                                     {(data.menuname !== '' || data.menuname !== undefined) && <p className="text-black label-name mt-2">{data.menuname}</p>}
//                                                 </div>
//                                             </div>
//                                         )
//                                     })
//                                     }
//                                     {/* home page weather body srushti 21-2-23 */}
//                                     {/* <div className="col-12">
//                                     <div className="row">
//                                         <div className="col-12 col-md-12 mt-3">
//                                             <div className="weatherbox">
//                                                 <span>Sunny</span>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div> */}
//                                 </div>
//                             </div>
//                         }
//                     </div>

//                     <RightSidebar
//                         handleFormData={props.handleFormData}
//                         handleAddButtonClick={props.handleAddButtonClick}
//                         handleGrid={props.handleGrid}
//                         setFormData={props.setFormData}
//                     />

//                     <DndRightsidebar
//                         handleFormData={props.handleFormData}
//                         handleAddButtonClick={props.handleAddButtonClick}
//                         handleGrid={props.handleGrid}
//                         setFormData={props.setFormData}
//                         setDnd={props.setDnd}
//                         dndMenuData={dndMenuData}
//                     />

//                     <LateCheckOutRightSidebar
//                         handleFormData={props.handleFormData}
//                         handleAddButtonClick={props.handleAddButtonClick}
//                         handleGrid={props.handleGrid}
//                         setFormData={props.setFormData}
//                         HouseKeepingAddButtonClick={props.HouseKeepingAddButtonClick}
//                         handleLateCheckout={props.handleLateCheckout}
//                     />

//                     <CheckOutRightSidebar
//                         handleFormData={props.handleFormData}
//                         handleAddButtonClick={props.handleAddButtonClick}
//                         handleGrid={props.handleGrid}
//                         setFormData={props.setFormData}
//                         setCheckOutFormData={props.setCheckOutFormData}
//                         getReservationDetails={props.getReservationDetails}
//                     />

//                     <ExtendStayRightSidebar
//                         handleFormData={props.handleFormData}
//                         handleAddButtonClick={props.handleAddButtonClick}
//                         handleGrid={props.handleGrid}
//                         setFormData={props.setFormData}
//                         HouseKeepingAddButtonClick={props.HouseKeepingAddButtonClick}
//                         itemqty={props.itemqty}
//                     />

//                     <SecoundHouseKeepingRightSidebar
//                         handleFormData={props.handleFormData}
//                         handleAddButtonClick={props.handleAddButtonClick}
//                         handleGrid={props.handleGrid}
//                         setFormData={props.setFormData}
//                         HouseKeepingAddButtonClick={props.HouseKeepingAddButtonClick}
//                     />

//                     <HousekeepingRating
//                         handleFormData={props.handleFormData}
//                         handleAddButtonClick={props.handleAddButtonClick}
//                         handleGrid={props.handleGrid}
//                         setFormData={props.setFormData}
//                         HouseKeepingAddButtonClick={props.HouseKeepingAddButtonClick}
//                     />
//                 </div>
//             </div >
//             <Footer />
//         </>
//     )
// }

// const mapStateToProps = state => ({
//     ...state
// })

// const mapDispatchToProps = dispatch => ({
//     setProps: (payload) => dispatch(setProps(payload))
// })

// export default connect(mapStateToProps, mapDispatchToProps)(HomeView)
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { store } from '..'
import { IISMethods } from '../config/IISMethods'
import Location from '../assets/images/Location.png'
import RightSidebar from './RightSidebar'
import DndRightsidebar from './DndRightsidebar'
import LateCheckOutRightSidebar from './LateCheckOutRightSidebar'
import CheckOutRightSidebar from './CheckOutRightSidebar'
import ExtendStayRightSidebar from './ExtendStayRightSidebar'
import SecoundHouseKeepingRightSidebar from './SecoundHouseKeepingRightSidebar'
import HousekeepingRating from './HouseKeepingRating'
import Header from '../Header'
import Footer from '../Footer'
import { Placeholder } from 'rsuite'
import { setProps } from '../redux/actions'
import { Carousel } from 'react-bootstrap'
import ModalRsuite from './Components/ModalRsuite'
import promotionImg from '../assets/images/promotionImg.png'
import NoDataImage from '../assets/images/no-data-img.png'
import useIsIOS from '../hooks/useIsIOS'

const HomeView = (props) => {
    const getCurrentState = () => store.getState()['root']
    const iosData = useIsIOS()

    const queryString = IISMethods.getQueryString()
    const history = useNavigate()
    const [dndMenuData, setDndMenuData] = useState()
    const [modalData, setModalData] = useState({})

    const handleRedirectMenu = (alias) => {
        let menuindex = getCurrentState()?.menuData?.findIndex((a) => a.alias === alias)
        let obj = { ...getCurrentState().menuData[menuindex] }
        obj.ishighlight = 0
        getCurrentState().menuData?.splice(menuindex, 1, obj);
        props.setProps({ menuData: IISMethods.getCopy(getCurrentState().menuData) })

        let pagelink = queryString

        // reservation exprire conditin manage with hide and show menu details
        if (!getCurrentState().reservationDetails.isguestcheckout || getCurrentState().reservationDetails.isguestcheckout === 0) {
            if (alias === 'wifi') {
                // history(`/wifi${pagelink}`)
                history(`/wifi`)
            }
            else if (alias === 'requestitem') {
                // history(`/requestitem${pagelink}`)
                history(`/requestitem`)
            }
            else if (alias === 'maintenance') {
                // history(`/maintenance${pagelink}`)
                history(`/maintenance`)
            }
            else if (alias === 'reportproblem') {
                // history(`/reportproblem${pagelink}`)
                history(`/reportproblem`)
            }
            else if (alias === 'chatwithus') {
                // history(`/chatwithus${pagelink}`)
                history(`/chatwithus`)
            }
            else if (alias === 'dndon') {
                let dndmenudata = IISMethods.getobjectfromarray(getCurrentState().menuData, 'alias', alias)
                setDndMenuData(dndmenudata)
                props.setFormData(null, alias, alias + 'Modal')
            }
            else if (alias === 'housekeeping') {
                if (getCurrentState().reservationDetails.enablehousekeepingrating === 1) {
                    props.setFormData(null, 'housekeepingrating', 'housekeepingratingModal')
                }
                else if (getCurrentState().reservationDetails.ischeckinday === 0) {
                    props.setFormData(null, 'housekeeping', 'housekeepingModal')
                }
                else if (getCurrentState().reservationDetails.ischeckinday === 1) {
                    props.setFormData(null, 'housekeepingtwo', 'housekeepingModal')
                }
            }
            else if (alias === 'checkout') {
                //when extend stay menu click open chatwithus and routes "checkout" is JENIL 03/08/2023 03:51 PM
                history(`/checkout`)
                // if (getCurrentState().reservationDetails.isguestexpcheckout === 1) {
                //     props.handleGrid(true, 'modal', 'checkoutRqstSubmitModal', 1)
                // }
                // else {
                //     props.setFormData(null, alias, alias + 'Modal')
                // }
                // if (getCurrentState().reservationDetails.isguestcheckout === 0) {
                // props.setFormData(null, alias, alias + 'Modal')
                // }
                // else if (getCurrentState().reservationDetails.isguestcheckout === 1 && getCurrentState().reservationDetails.checkoutratingsubmitted === 0) {
                //     props.setFormData(null, 'checkoutrating', 'checkoutratingModal')
                // }
                // else if (getCurrentState().reservationDetails.isguestcheckout === 1 && getCurrentState().reservationDetails.checkoutratingsubmitted === 1) {
                //     history({ pathname: `/successfullycheckout` })
                // }
            }
            else if (alias === "extendstay") {
                //when extend stay menu click open chatwithus and routes "extendstay" is JENIL 03/08/2023 03:51 PM
                history(`/extendstay`)
                // if (getCurrentState().reservationDetails.isalreadyrequested === 0) {
                //     props.setFormData(null, 'extendstay', 'extendstayModal')
                // }
                // else if (getCurrentState().reservationDetails.isalreadyrequested === 1) {
                //     props.handleGrid(true, 'modal', 'verificationdatamodal', 1)
                // }
            }
            else if (alias === "latecheckout") {
                //when extend stay menu click open chatwithus and routes "latecheckout" is JENIL 03/08/2023 03:51 PM
                history(`/latecheckout`)
                // if (getCurrentState().reservationDetails.guestlatecheckoutrequest === 0) {
                //     props.setFormData(null, 'latecheckout', 'latecheckoutModal')
                // }
                // else if (getCurrentState().reservationDetails.guestlatecheckoutrequest === 1) {
                //     props.handleGrid(true, 'modal', 'latecheckoutdatamodal', 1)
                // }
            }
            else {
                props.setFormData(null, alias, alias + 'Modal')
            }
        }
        else if (getCurrentState().reservationDetails.isguestcheckout === 1) {
            if (alias === 'reportproblem') {
                history(`/reportproblem`, { state: { pagename: 'reportproblem', goback: true } })
            }
            else if (alias === 'wifi') {//charmi(06-09-2023 03:53)
                history(`/wifi`)
            }
            else if (alias === 'chatwithus') {//charmi(06-09-2023 03:53)
                history(`/chatwithus`)
            }
            else {
                history(`/successfullycheckout`)//charmi(21-08-2023 03:04)
                // IISMethods.localnotify("You have already checkout. You can't perform this action.")//charmi(21-08-2023 03:04)
            }
        }
    }

    const homeIcon = getCurrentState().menuData ? getCurrentState().menuData : []

    const loyalty = getCurrentState().reservationDetails?.promotions
    // [
    //     {
    //         title: 'Welcome Loyalty 1',
    //         desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
    //         img: 'https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80',
    //         link: 'https://www.instanceit.com/'
    //     },
    //     {
    //         title: 'Welcome Loyalty 2',
    //         desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
    //         img: 'https://images.unsplash.com/photo-1566438480900-0609be27a4be?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=694&q=80',
    //         link: 'https://www.instanceit.com/'
    //     },
    //     {
    //         title: 'Welcome Loyalty 3',
    //         desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
    //         img: 'https://images.unsplash.com/photo-1517329782449-810562a4ec2f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1163&q=80',
    //         link: 'https://www.instanceit.com/'
    //     },
    // ]

    const handleDescriptionModal = async (imgmodal, titleorurl, description) => {
        if (imgmodal) {
            await setModalData({
                url: titleorurl,
                title: description
            })
            await props.handleGrid(true, 'modal', 'promotionImgModal', 1)
        }
        else {
            await setModalData({
                title: titleorurl,
                desc: description
            })
            await props.handleGrid(true, 'modal', 'promotionDescModal', 1)
        }
    }

    function runWeatherScript(script, id) {
        let fjs = document.getElementsByTagName(script)[0]
        let js = document.createElement(script)
        js.id = id
        js.src = 'https://weatherwidget.io/js/widget.min.js'
        fjs.parentNode.insertBefore(js, fjs)
    }

    useEffect(() => {
        runWeatherScript('script', 'weatherwidget-io-js')
    }, [])

    return (
        <>
            <Header />
            <div className="body-content">
                <div className="col-12">
                    {/* <div className="row">
                        <div className="col-12"> */}
                    {
                        // (getCurrentState().formName === '')
                        // (getCurrentState().menuData.length === 0)
                        // ?
                        // <>
                        //     <Placeholder.Graph active className='placeHolderHeight mt-3'></Placeholder.Graph>
                        // </>
                        // :
                    }
                    <div>
                        {/* {JSON.stringify(iosData)} */}
                        {/* {isSafari.toString()}, {isIOS.toString()}, {iosPrompt.toString()} */}
                    </div>
                    <div className="row">

                        {/* home page main header title srushti 21-2-23 */}
                        <div className="col-12 p-0">
                            <div className="background-header-home gap-10">
                                <div className="home-header-left">
                                    <p className="text-white customer-hello m-0">Hello,</p>
                                    {getCurrentState().reservationDetails._id && <p className="text-white customer-name m-0">{getCurrentState().reservationDetails?.firstname} {getCurrentState().reservationDetails?.lastname}</p>}
                                    <p className="text-white customer-checkout m-0 mt-1">Check - Out</p>
                                    {getCurrentState().reservationDetails._id && <p className="text-white customer-checkout m-0">{IISMethods.GetDateFormat(getCurrentState().reservationDetails?.checkoutdate)} At {getCurrentState().reservationDetails.islatecheckoutrequestaccepted === 1 ? IISMethods.GetTimeFormat(getCurrentState().reservationDetails?.latecheckoutdate) : IISMethods.GetTimeFormat(getCurrentState().propertyData?.timing?.checkouttime)} </p>}{/* charmi(05-06-2023) */}
                                </div>
                                <div className="tap-to-locate-block"
                                    // srushti for after checkout do not able to seen room location map 21-6-23
                                    onClick={() =>
                                        (getCurrentState().reservationDetails.isguestcheckout === 1) ?
                                            IISMethods.localnotify("You have already checkout.you can't perform this action.")
                                            : (getCurrentState().reservationDetails?.roomplan !== '' && props.handleGrid(true, 'modal', 'locationmodal', 1))}>
                                    <div className='d-flex gap-10 justify-content-center'>
                                        <img src={Location} />
                                        <p className="header-roomno">{getCurrentState().reservationDetails?.roomno}</p>
                                    </div>
                                    {getCurrentState().reservationDetails?.roomplan !== '' && <a className="tap-to-locate-label mt-2" >tap to locate me</a>}
                                </div>
                            </div>
                        </div>

                        {/* home page header menu srushti 21-2-23 */}
                        {
                            //  getCurrentState().menuData.length === 0 ?
                            //  <>
                            //    <div className="col-12">
                            //           <div className="row">
                            //      {
                            //          [...Array(4)].map((data , index)=>{
                            //              return <div className='col-3 home-menu-border'>
                            //              <Placeholder.Graph active className='' style={{height : "100px", width : "100%"}}> </Placeholder.Graph>
                            //          </div>
                            //          })
                            //      }
                            //           </div>
                            //  </div>
                            //  </>
                            //  :
                        getCurrentState().menuData?.length > 0 &&
                            <div className="col-12 p-0">
                                <div className="header-menu">
                                    <div className="row m-0">
                                        {homeIcon.filter(obj => obj.sidebar === 1).sort((a, b) => a.order > b.order ? 1 : -1).map((data, index) => {
                                            return (
                                                <a className="col-3 home-menu-border" key={index} onClick={() => { handleRedirectMenu(data.alias); }}>
                                                    {/* <a className="col-3 home-menu-border" key={index} onClick={() => { props.setFormData(null, data.alias, data.alias + 'Modal'); handleRedirectMenu(data.alias) }}> */}
                                                    {data.ishighlight === 1 && <span className='blink-red-dott'></span>}
                                                    <div className="home-menu-imgs">
                                                        {(data.dashboardicon !== '' || data.dashboardicon !== undefined) && <img src={data.dashboardicon} />}
                                                    </div>
                                                    {(data.menuname !== '' || data.menuname !== undefined) && <span className="text-black home-menu-label-name">{data.menuname}</span>}
                                                </a>
                                            )
                                        })
                                        }
                                    </div>
                                </div>
                            </div>
                        }

                        {/* home page body menu srushti 21-2-23 */}
                        
                        {
                        getCurrentState().menuData.length === 0 ?
                        <>
                          <div className="col-12">
                                 <div className="row">
                            {
                                [...Array(6)].map((data , index)=>{
                                    return <div className='col-4 col-lg-4 home-modules'>
                                    <Placeholder.Graph active className='mb-0 mt-2' style={{height : "100px", width : "100%"}}> </Placeholder.Graph>
                                </div>
                                })
                            }
                                 </div>
                        </div>
                        </>
                        :
                        getCurrentState().menuData?.length > 0 &&
                            <div className="col-11 mx-auto mb-3">
                                <div className="row">
                                    {homeIcon.filter(obj => obj.dashboard === 1).sort((a, b) => a.order > b.order ? 1 : -1).map((data, index) => {
                                        return (
                                            <div className="col-4 col-lg-4 mt-3 home-modules" key={index}>
                                                {data.ishighlight === 1 && <span className='blink-red-dot home-page-dot'></span>}{/* charmi(09-08-2023 12:21) */}
                                                {/* <div className="home-body-menu text-center" onClick={() => { props.setFormData(null, data.alias, data.alias + 'Modal'); handleRedirectMenu(data.alias) }}> */}
                                                <div className={`home-body-menu text-center${data.alias === 'dndon' && data.dndon === 1 ? ' dnd-on' : ''}`} onClick={() => { handleRedirectMenu(data.alias); }}>
                                                    <div className="home-body-imgs">
                                                        {(data.dashboardicon !== '' || data.dashboardicon !== undefined) && <img src={data.dashboardicon} />}
                                                    </div>
                                                    {
                                                        //blink menu is hightlight = 1 in chatwithus
                                                        // data.alias === "chatwithus" &&
                                                        // data.ishighlight === 1 && <span className='blink-red-dot'></span>
                                                    }
                                                    {(data.menuname !== '' || data.menuname !== undefined) && <p className="text-black label-name mt-2">{data.menuname}</p>}
                                                </div>
                                            </div>
                                        )
                                    })
                                    }
                                    {/* home page weather body srushti 21-2-23 */}
                                    {
                                        ("geolocation" in navigator && getCurrentState().propertyData.location?.weatherkey)
                                        &&
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-12 col-md-12 mt-3 px-0">
                                                    <div className="weatherbox">
                                                        <a className="weatherwidget-io" href={`https://forecast7.com/en/${getCurrentState().propertyData.location?.weatherkey}/?unit=us`} data-label_1={`${getCurrentState().propertyData.propertyname}`} data-raincolor='#ffffff' data-mooncolor='#ffffff' data-cloudcolor='#ffffff' data-theme="original" data-icons='Climacons Animated' data-basecolor="#0075ef" data-highcolor="#ffffff" data-lowcolor="#ffffff" data-suncolor="#ffd72e" data-cloudfill='#0075ef'>
                                                            {/* {getCurrentState().propertyData.propertyname} */}
                                                            <Placeholder.Graph className='m-0' active></Placeholder.Graph>
                                                        </a>
                                                        {
                                                            // data-icons = 'Climacons', 'Climacons Animated', ''
                                                            // data-font = 'Open Sans'
                                                            // data-mode = 'Current', 'Forecast'
                                                            // data-days = '3', '5', ''
                                                            // data-basecolor = '#add6f2'
                                                            // data-accent = 'rgba(216, 82, 82, 0.3)'
                                                            // data-textcolor = '#000000'
                                                            // data-highcolor = '#ff0000'
                                                            // data-lowcolor = '#0000ff'
                                                            // data-shadow = '#df0000'
                                                            // data-suncolor = '#f8a900'
                                                            // data-mooncolor = '#cccccc'
                                                            // data-cloudcolor = '#b0e6f6'
                                                            // data-cloudfill = '#1290e7'
                                                            // data-raincolor = '#5495f2'
                                                            // data-snowcolor = '#a6a6a6'
                                                            // data-theme = 'pure', 'orange', 'gray', 'dark', 'desert', 'candy', 'beige', 'salmon', 'original', 'clear', 'sky', 'metallic', 'ruby', 'kiwi', 'kitty', 'hexellence', 'tile_wood', 'white_leather', 'pool_table', 'random_grey', 'retro-sky', 'marine', 'mountains', 'blue-mountains', 'pillows', 'grass', 'fall-leaves', 'weather_one'
                                                        }
                                                        {/* <script>
                                                            {
                                                                !function (d, s, id) {
                                                                    var js, fjs = d.getElementsByTagName(s)[0]
                                                                    if (!d.getElementById(id)) {
                                                                        js = d.createElement(s);
                                                                        js.id = id;
                                                                        js.src = 'https://weatherwidget.io/js/widget.min.js';
                                                                        fjs.parentNode.insertBefore(js, fjs);
                                                                    }
                                                                }
                                                                    (document, 'script', 'weatherwidget-io-js')
                                                            }
                                                        </script> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {/* charmi(05-08-2023) */}
                                    {
                                        loyalty?.length > 0
                                        &&
                                        <div className='col-12 p-0 mt-3'>
                                            <div className='text-right text-12 text-primary cursor-pointer view-all-btn' onClick={() => history(`/promotions`)}>View All</div>
                                            <Carousel className={`custom-slider hide-arrows mt-1 ${loyalty?.length > 1 ? "multiple-dot" : "single-dot"}`} interval={5000} fade={true}>
                                                { 
                                                    loyalty?.map((item, index) => {
                                                    return (
                                                        <Carousel.Item>
                                                            <div className='loyalty-sec-slider'>
                                                                <figure className='cursor-pointer'>
                                                                    <img src={item.photo || promotionImg} onClick={() => handleDescriptionModal(true, item.photo || promotionImg, item.promotiontitle)} />
                                                                </figure>
                                                                <div className='loyalty-details'>
                                                                    <h4>{item.promotiontitle}</h4>
                                                                    <div className='loyalty-desc-block'>
                                                                        <span className='loyalty-desc' id={'loyalty-desc'+index}>
                                                                            <span>{item.promotiondesc}</span>
                                                                        </span>
                                                                        {
                                                                            document.getElementById(`loyalty-desc${index}`)?.childNodes[0]?.offsetHeight > 50
                                                                            &&
                                                                            <span className='text-primary text-12 loyalty-read-btn cursor-pointer' onClick={() => handleDescriptionModal(false, item.promotiontitle, item.promotiondesc)}>...Read More</span>
                                                                        }
                                                                    </div>
                                                                    <div className='mt-2 mb-0 loyalty-top-btn'>
                                                                        <a href={item.redirectlink} target='_blank' className='btn btn-xs loyalty-btn'>{item.buttonname}</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Carousel.Item>
                                                    )
                                                })
                                                }
                                            </Carousel>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </div>

                    <RightSidebar
                        handleFormData={props.handleFormData}
                        handleAddButtonClick={props.handleAddButtonClick}
                        handleGrid={props.handleGrid}
                        setFormData={props.setFormData}
                    />

                    <DndRightsidebar
                        handleFormData={props.handleFormData}
                        handleAddButtonClick={props.handleAddButtonClick}
                        handleGrid={props.handleGrid}
                        setFormData={props.setFormData}
                        setDnd={props.setDnd}
                        dndMenuData={dndMenuData}
                    />

                    <LateCheckOutRightSidebar
                        handleFormData={props.handleFormData}
                        handleAddButtonClick={props.handleAddButtonClick}
                        handleGrid={props.handleGrid}
                        setFormData={props.setFormData}
                        HouseKeepingAddButtonClick={props.HouseKeepingAddButtonClick}
                        handleLateCheckout={props.handleLateCheckout}
                    />

                    <CheckOutRightSidebar
                        handleFormData={props.handleFormData}
                        handleAddButtonClick={props.handleAddButtonClick}
                        handleGrid={props.handleGrid}
                        setFormData={props.setFormData}
                        setCheckOutFormData={props.setCheckOutFormData}
                        getReservationDetails={props.getReservationDetails}
                    />

                    <ExtendStayRightSidebar
                        handleFormData={props.handleFormData}
                        handleAddButtonClick={props.handleAddButtonClick}
                        handleGrid={props.handleGrid}
                        setFormData={props.setFormData}
                        HouseKeepingAddButtonClick={props.HouseKeepingAddButtonClick}
                        itemqty={props.itemqty}
                    />

                    <SecoundHouseKeepingRightSidebar
                        handleFormData={props.handleFormData}
                        handleAddButtonClick={props.handleAddButtonClick}
                        handleGrid={props.handleGrid}
                        setFormData={props.setFormData}
                        HouseKeepingAddButtonClick={props.HouseKeepingAddButtonClick}
                    />

                    <HousekeepingRating
                        handleFormData={props.handleFormData}
                        handleAddButtonClick={props.handleAddButtonClick}
                        handleGrid={props.handleGrid}
                        setFormData={props.setFormData}
                        HouseKeepingAddButtonClick={props.HouseKeepingAddButtonClick}
                    />
                </div>
            </div >
            <Footer />

            {/* charmi(07-08-2023 03:33) */}
            <ModalRsuite
                size={"sm"}
                open={getCurrentState().modal['promotionDescModal']}
                onClose={(e) => { props.handleGrid(false, 'modal', 'promotionDescModal', 0); }}
                title={
                    <div className="model-header icon">
                        <span className="closeicon ellipsis-header">
                            {modalData.title}
                        </span>
                    </div>
                }
                body={
                    <div className='col-12'>
                        <div className='card-content'>
                            {
                                modalData.desc
                                    ?
                                    <div className='white-space-pre' dangerouslySetInnerHTML={{ __html: modalData.desc }}></div>
                                    :
                                    <div className="right-sidebar-content">
                                        <div className="no-data-imgs-modal">
                                            <img src={NoDataImage} className='no-data-imgs' />
                                            <h2 className="text-center text-16 mt-2 mb-0 font-weight-600">No information found</h2>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                }
            />
            {/* charmi(07-08-2023 03:33) */}

            {/* charmi(07-08-2023 03:33) */}
            <ModalRsuite
                size={"sm"}
                open={getCurrentState().modal['promotionImgModal']}
                onClose={(e) => { props.handleGrid(false, 'modal', 'promotionImgModal', 0); }}
                title={
                    <div className="model-header icon">
                        <span className="closeicon ellipsis-header">
                            {modalData.title}
                        </span>
                    </div>
                }
                body={
                    <>
                        <div className="col-12">
                            {
                                modalData.url
                                    ?
                                    <div className="single-image">
                                        <img className='' src={modalData.url} />
                                    </div>
                                    :
                                    <div className="right-sidebar-content">
                                        <div className="no-data-imgs-modal">
                                            <img src={NoDataImage} className='no-data-imgs' />
                                            <h2 className="text-center text-16 mb-0 font-weight-600">No information found</h2>
                                        </div>
                                    </div>
                            }
                        </div>
                    </>
                }
            />
            {/* charmi(07-08-2023 03:33) */}
        </>
    )
}

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    setProps: (payload) => dispatch(setProps(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(HomeView)