import React from 'react'
import { DatePicker } from 'rsuite'
import { IISMethods } from '../../config/IISMethods'

function DateTimePicker(props) {
    let calendarDefaultDate = IISMethods.GetDateTimefromISOString(IISMethods.GetTZdatetime().toISOString())

    return (
        <>
            <DatePicker
                className={props.className}
                placeholder={props.placeholder}
                format={props.format}
                cleanable={props.cleanable}
                // defaultValue={props.defaultValue}
                name={props.name}
                value={props.value ? props.value : null}
                showMeridian
                id={props.id}
                onChange={props.onChange}
                onClean={() => props.onChange('')}
                // type={props.type}
                // ranges={props.ranges}
                ranges={[]}
                disabled={props.disabled}
                calendarDefaultDate={calendarDefaultDate}
                disabledDate={props.disabledDate}
                editable={false} //mobile view on calendar open their keyboard JENIL 28/6/2023 5:54 PM
                {...props.options}
            />
        </>
    )
}


export default DateTimePicker