import React from 'react'
import { connect } from 'react-redux'
import { setProps } from '../redux/actions'
import ChatUsController from '../Controller/ChatUsController'

const ChatUs = (props) => {

    return (
        <ChatUsController />
    )
}

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    setProps: (payload) => dispatch(setProps(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(ChatUs)