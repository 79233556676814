import React from 'react'
import MobileCheckIn from '../../assets/images/selfverificationimg.png'

export default function WarningModal() {
    return (
        <>
            <div className="row body-fix-height checkout-content">
                <div className="col-11 col-md-9 col-lg-10 mx-auto">
                    <div className='completed-content'>
                        <div className="col-12 mx-auto d-flex justify-content-center">
                            <img src={MobileCheckIn} width={200} />
                            {/* <img src={'https://nextap.s3.amazonaws.com/kiosist/svg/frontdesk/filter_action/mobilecheckin.png'} /> */}
                        </div>
                        <div className="col-12 mx-auto mt-5">
                            <div className="successfulcheckin-desc text-center text-blue fw-500 d-flex flex-column text-18 font-weight-500">
                                <span>You can't access this link.</span>
                                <span>First you have to checkin.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </> 
    )
}
