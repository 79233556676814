import React, { useEffect, useState } from 'react'
import Header from '../Header'
import { connect, useSelector } from 'react-redux'
import 'rsuite/dist/rsuite.css'
import { setProps } from '../redux/actions'
import { store } from '../index'
import BackHeader from '../BackHeader'
import Footer from '../Footer'
import { Config, IISMethods, allowdedAudio, allowdedDocs, allowdedVideo, allowdedImage, resBody, setReqParams } from '../config/IISMethods'
import { Placeholder } from 'rsuite'
import placeholderImg from '../assets/images/placeholderImgNotify.png'
import NoDataImage from '../assets/images/no-data-img.png'
import Announcementimg from '../assets/images/announcement.svg'
import ModalRsuite from './Components/ModalRsuite'
import Audio from './Components/Audio'


const AnnouncementView = (props) => {

    const getCurrentState = () => {
        return store.getState()['root']
    }

    const Announcement = ({ announcement }) => {
        return (
            <>
                {announcement.isseenbygguest === 0 && <span className='blink-red-dot'></span>}
                <div className='notification-img'>
                    {announcement.icon && <img src={announcement.icon} width={"50px"} />}
                    {!announcement.icon && <img src={Announcementimg} width={"50px"} />}
                </div>
                <div className={`notification-text p-0`}>
                    <span className='d-block font-weight-600 announcement-subtitle'>{announcement.title}</span>
                    {/* <div className={`d-block font-weight-500  text-12  ${announcement.description.length > 30 ? " w-50 text-truncate" : ""}`} dangerouslySetInnerHTML={{ __html: announcement.description}}
                    
                    onClick={() =>{announcement.description.length > 30 && props.handleOpenPreviewImage(announcement , "DescModal")}}>
                    </div> */}
                    <span className={`d-block announcement-subdesc ${announcement.description.length > 30 ? " w-50 text-truncate" : ""}`} id='loyalty-desc'
                        onClick={() => { announcement.description.length > 30 && props.handleOpenPreviewImage(announcement, "DescModal") }}
                    >{announcement.description}</span>

                    {
                        // document.getElementById("loyalty-desc").childNodes[0]?.offsetHeight > 20 &&
                        //  <span className={`d-block font-weight-500 text-12`}>...</span>
                    }
                    {/* {
                            announcement.description.length > 40 &&
                            <span className='text-primary text-12 font-weight-500 cursor-pointer'  onClick={() => props.handleGrid(true,"modal", "DescModal" , 1  , announcement)}>...Read More</span>
                    } */}

                    {/* {(announcement.description?.length > 0) && <span className='d-block mb-1'>{announcement.description}</span>} */}
                    {/* <div className='d-flex notification'>
                        <img src={Clock} />
                        <p>{IISMethods.GetTimeFormat(announcement.announcementfromdate)} To {IISMethods.GetTimeFormat(announcement.announcementtodate)}</p>
                    </div> */}
                </div>
            </>
        )
    }
    // const htmlRendor=(filetype)=>{
    //     debugger
    //     return(<>
    //     {
    //         (allowdedImage.includes(filetype)) 
    //         ?<img className="filepreviewimg" src={props.getobj["image"] || Config.getDefaulImageUploadUrl()} alt="img" />
    //             : (props.formfield.filetypes === allowdedAudio)
    //              ?<audio controls>
    //                     <source src={props.value || Config.getDefaulImageUploadUrl()} type="audio/mpeg" />
    //                 </audio> 
    //                 :allowdedAudio.includes(filetype) 
    //                 ? <audio controls>
    //                     <source src={props.value || Config.getDefaulImageUploadUrl()} type="audio/mpeg" />
    //                 </audio>
    //                 :allowdedVideo.includes(filetype) 
    //                 ?
    //                 <video controls src={props.value || Config.getDefaulImageUploadUrl()}  style={{width:'100%',height:'30%'}}/> :
    //                  allowdedDocs.includes(filetype) ?
    //                 //  ('.' + type === '.pdf') ?
    //                 // <a>
    //                 //     <img className="filepreviewimg" src={props.getobj["image"]|| Config.getDefaulImageUploadUrl()} alt="img" />
    //                 // </a>:
    //                 <a>
    //                     <img className="filepreviewimg" src={props.getobj["image"]|| Config.getDefaulImageUploadUrl()} alt="img" />
    //                 </a> :
    //                         <img className="filepreviewimg" src={props.getobj["image"] || Config.getDefaulImageUploadUrl()} alt="img" />
    //     }
    //     </>)
    //     }

    // srushti 23-2-23
    return (
        <>
            {/* back history not static link JENIL 13/9/2023 10:47 PM */}
            <BackHeader isHistoryBack={true} linkto='/home' backtopagename='Announcement' handleFilterData={props.handleFilterData} handleApplyFilterButtonClick={props.handleApplyFilterButtonClick} handleSerachClose={props.handleSerachClose} />
            {/* <Header backTo="guestexperiencehome" title="Announcement" backtoPreviousPage={props.backtoPreviousPage} /> */}
            <div className="body-content notify-body-content" onScroll={props.handleScroll}>
                <div className="col-12 mb-3">
                    <div className="row">
                        {
                            (getCurrentState().formName === '')
                                ?
                                <div className="col-12">
                                    <Placeholder.Graph active className='notify-inner mt-3 mb-0'>
                                        <div className='notify-graph'>
                                            <div className='notify-img'>
                                                <img graph="image" src={placeholderImg} className='placeholder-img' />
                                            </div>
                                            <div className='notify-graph-content'>
                                                <p className='graph'></p>
                                                <p className='graph'></p>
                                                <p className='graph'></p>
                                            </div>
                                        </div>
                                    </Placeholder.Graph>
                                    <Placeholder.Graph active className='notify-inner mt-3 mb-0'>
                                        <div className='notify-graph'>
                                            <div className='notify-img'>
                                                <img graph="image" src={placeholderImg} className='placeholder-img' />
                                            </div>
                                            <div className='notify-graph-content'>
                                                <p className='graph'></p>
                                                <p className='graph'></p>
                                                <p className='graph'></p>
                                            </div>
                                        </div>
                                    </Placeholder.Graph>
                                    <Placeholder.Graph active className='notify-inner mt-3 mb-0'>
                                        <div className='notify-graph'>
                                            <div className='notify-img'>
                                                <img graph="image" src={placeholderImg} className='placeholder-img' />
                                            </div>
                                            <div className='notify-graph-content'>
                                                <p className='graph'></p>
                                                <p className='graph'></p>
                                                <p className='graph'></p>
                                            </div>
                                        </div>
                                    </Placeholder.Graph>
                                    <Placeholder.Graph active className='notify-inner mt-3 mb-0'>
                                        <div className='notify-graph'>
                                            <div className='notify-img'>
                                                <img graph="image" src={placeholderImg} className='placeholder-img' />
                                            </div>
                                            <div className='notify-graph-content'>
                                                <p className='graph'></p>
                                                <p className='graph'></p>
                                                <p className='graph'></p>
                                            </div>
                                        </div>
                                    </Placeholder.Graph>
                                    <Placeholder.Graph active className='notify-inner mt-3 mb-0'>
                                        <div className='notify-graph'>
                                            <div className='notify-img'>
                                                <img graph="image" src={placeholderImg} className='placeholder-img' />
                                            </div>
                                            <div className='notify-graph-content'>
                                                <p className='graph'></p>
                                                <p className='graph'></p>
                                                <p className='graph'></p>
                                            </div>
                                        </div>
                                    </Placeholder.Graph>
                                    <Placeholder.Graph active className='notify-inner mt-3 mb-0'>
                                        <div className='notify-graph'>
                                            <div className='notify-img'>
                                                <img graph="image" src={placeholderImg} className='placeholder-img' />
                                            </div>
                                            <div className='notify-graph-content'>
                                                <p className='graph'></p>
                                                <p className='graph'></p>
                                                <p className='graph'></p>
                                            </div>
                                        </div>
                                    </Placeholder.Graph>
                                </div>
                                :
                                ((getCurrentState().formName?.length > 0) && (getCurrentState().data?.length === 0)) ?
                                    <div className='col-12'>
                                        <div className='no-data-found'>
                                            <div className='noData-content'>
                                                <img src={NoDataImage} />
                                                <h2 className="text-center text-16 mb-0 font-weight-600">No announcement present</h2>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    getCurrentState().data && getCurrentState().data?.map((announcementdata, index) => {
                                        return (
                                            <div className="col-12 col-md-12 mt-3">
                                                <div className='text-center notification-date-text mb-3'>
                                                    {announcementdata.announcementdate && <span>{IISMethods.getDateFormat(IISMethods.GetDT(IISMethods.GetISOdate(announcementdata.announcementdate)))}</span>}
                                                </div>
                                                {announcementdata?.data?.map((announcement, index) => {
                                                    return (
                                                        <>
                                                            <div className={`notification-box mt-3 position-relative`}>
                                                                {/* ${announcement.description.length > 40 ? "" : "one-row-ann"} */}
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <div className="d-flex notificationContent announcmentContent align-items-center">
                                                                        <Announcement announcement={announcement} />
                                                                    </div>
                                                                </div>
                                                                {/* <div className='notification-box-border text-center '>
                                                                        {announcementdata.announcementdate && <span className='d-block'>{IISMethods.GetDateFormat(announcementdata.announcementdate)}</span>}
                                                                    </div> */}
                                                                {announcement.image &&
                                                                    <>
                                                                        {/* <div className='img-scroll'> */}
                                                                        <div className='view-attach-btn' onClick={() => props.handleOpenPreviewImage(announcement)}>
                                                                            {/* <img className='amenities-img' src={announcement.image} /> */}
                                                                            {/* <i className="material-icons-outlined text-16 text-dark mb-0 h4">info</i> */}
                                                                            <span className=''>View Attachment</span>
                                                                        </div>

                                                                        {/* <span className='text-12 d-block text-center'></span> */}
                                                                    </>
                                                                }

                                                            </div>
                                                        </>
                                                    )
                                                })}
                                            </div>
                                        )
                                    })
                        }
                    </div>
                </div>
            </div>
            <Footer />
            {
                getCurrentState().modal['imagemodal'] === true &&
                <ModalRsuite
                    size={"xs"}
                    open={getCurrentState().modal['imagemodal']}
                    onClose={() => props.handleGrid(false, 'modal', 'imagemodal', 0)}

                    title={
                        <div className="model-header icon">
                            <span className="closeicon">
                                <span className='modal-title-dotted direct-text'>
                                    {props.getobj["title"]}
                                </span>
                            </span>
                        </div>
                    }
                    body={
                        <div className="col-12">
                            {
                                <>
                                    <div className='row'>
                                        <div className='col-12 text-center'>
                                            {
                                                props.getobj["image"] &&
                                                <img className='map-img' src={props.getobj["image"]} />
                                            }
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    }
                />
            }
            {
                getCurrentState().modal['DescModal'] === true &&
                <ModalRsuite
                    size={"sm"}
                    open={getCurrentState().modal['DescModal']}
                    onClose={(e) => { props.handleGrid(false, 'modal', 'DescModal', 0); }}
                    title={
                        <div className="model-header icon">
                            <span className="closeicon">
                                <span className='modal-title-dotted direct-text'>
                                    {props.getobj["title"]}
                                </span>
                            </span>
                        </div>
                    }
                    body={
                        <>
                            {/* <div className='card-content'> */}
                            {

                                <div className='col-12 text-left'>
                                    {
                                        props.getobj["description"] &&
                                        <div className='white-space-pre'>
                                            {/* <span className='text-14 line-height'>{props.getobj["description"]}</span> */}
                                            <div className='m-0' dangerouslySetInnerHTML={{ __html: props.getobj["description"] }}></div>
                                        </div>

                                    }
                                </div>
                            }
                            {/* </div> */}
                        </>
                    }
                />
            }
            {
                getCurrentState().modal['audiofile']
                &&
                <ModalRsuite
                    size={"xs"}
                    open={getCurrentState().modal["audiofile"]}
                    onClose={() => props.handleGrid(false, 'modal', "audiofile", 0)}
                    title={
                        props.getobj["title"] &&
                        <div className="model-header icon">
                            <span className="closeicon">
                                <span className='modal-title-dotted direct-text'>
                                    {props.getobj["title"]}
                                </span>
                            </span>
                        </div>}
                    body={
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12">
                                    <div className="right-sidebar-content">
                                        <div className="col-12 pr-0">
                                            <Audio src={props.getobj["image"] || ""} />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                />
            }
            {
                getCurrentState().modal['videofile']
                &&
                <ModalRsuite
                    size={"xs"}
                    open={getCurrentState().modal["videofile"]}
                    onClose={() => props.handleGrid(false, 'modal', "videofile", 0)}
                    title={
                        props.getobj["title"] &&
                        <div className="model-header icon">
                            <span className="closeicon">
                                <span className='modal-title-dotted direct-text'>
                                    {props.getobj["title"]}
                                </span>
                            </span>
                        </div>}
                    body={
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12">
                                    <div className="right-sidebar-content">
                                        <div className="col-12">
                                            <video controls src={props.getobj["image"]} style={{ width: '100%', height: '30%' }} />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                />
            }
        </>
    )
}


const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    setProps: (payload) => dispatch(setProps(payload))
})


export default connect(mapStateToProps, mapDispatchToProps)(AnnouncementView)
