import React, { useEffect, useRef, useState } from 'react'
import BackHeader from '../BackHeader'
import Footer from '../Footer'
import pdfImg from '../assets/images/pdf-svgrepo-com.svg'
import blankimg from '../assets/images/chat-img.png'
import { Dropdown, IconButton, Loader, Placeholder, Tooltip, Whisper } from 'rsuite';
import { store } from '..';
import { Config, IISMethods, allowdedAll, allowdedAudio, allowdedDocs, allowdedImage, allowdedVideo, resBody, setReqParams } from '../config/IISMethods';
import { Link } from 'react-router-dom'
import ModalRsuite from './Components/ModalRsuite'
import SignaturePad from 'react-signature-canvas'
import DatePickerRsuite from './Components/DatePickerRsuite';
import isBefore from 'date-fns/isBefore'
import TimePickerRsuite from './Components/TimePickerRsuite'
import Mail from '../assets/images/Eamil G.png'
import Print from '../assets/images/Print G.png'
import Receipt from '../assets/images/No Receipt G.png'
import NoDataImage from '../assets/images/no-data-img.png'
import { Document, Page, pdfjs } from "react-pdf";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { PDFDocument } from "pdf-lib";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ChatUsView = (props) => {

    //rightsidebar form data JENIL 13/9/2023 3:00 PM
    const RightSidebarFormData = [
        // 0
        {
            'field': 'chatmessage',
            'text': 'Chat Message',
            'placeholder': 'Type a message',
            'type': Config.getHtmlcontorls()['input-text'],
            'disabled': false,
            'required': false,
            'defaultvisibility': true,
            'gridsize': Config.getGridclasses()['col-12'],
        },
        //1
        {
            'field': 'files',
            'text': 'Media',
            'type': Config.getHtmlcontorls()['file'],
            'disabled': false,
            'defaultvisibility': true,
            'required': false,
            'gridsize': Config.getGridclasses()['col-12'],
            'filetypes': [...allowdedAll]
        }
    ]

    // add class in chat container
    const [addclass, setAddclass] = useState(false);

    const [isPdfLoad, setIsPdfLoad] = useState(true);

    //pdf render state JENIL 10/8/2023 4:37 PM
    const [pageNum, setPageNum] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [pageDetails, setPageDetails] = useState(null);

    //pdf zoom pinch state JENIL 10/8/2023 4:44 PM
    const zoomRef = useRef();
    const [isDraw, setIsDraw] = useState(false);

    //canvas pad ref JENIL 10/8/2023 5:17 PM
    const signatureRef = useRef(null)
    const [heightSign, setHeightSign] = useState();
    const [widthSign, setWidthSign] = useState();
    const pagename = IISMethods.getpagename()


    useEffect(() => {
        let renderPdfCanvas = document.getElementsByClassName("regcard-pdf-drawimage-buttons")[0]
        // let upperButton = document.getElementById("pdf-upper-button");
        if (renderPdfCanvas) {
            setHeightSign(renderPdfCanvas?.clientHeight
                // - upperButton?.clientHeight
            );
            setWidthSign(renderPdfCanvas?.clientWidth);
        }
    }, [pageDetails])

    // jatin 21-08-2023 from Notiication to chat redirect
    // useEffect(()=>{
    //     // // debugger
    //     const element = document.getElementById("chat" + props.chatId);
    //     // console.log('element', element)
    //     if (element !== null) {
    //         // debugger
    //         element.classList.add("msg-redirect-blink");
    //         setTimeout(() => {
    //             element.classList.remove("msg-redirect-blink");
    //         }, 5000)
    //         element.scrollIntoView({ behavior: 'smooth' }, false);
    //     }
    //     // if(props.chatId) redirectMsgReplay(props.chatId)
    // } )

    //canvas singature state JENIL 10/8/2023 4:53 PM
    const [signatureURL, setSignatureURL] = useState(null);

    //on signature pad end JENIL 10/8/2023 5:24 PM
    const onSignEnd = () => {
        let url = signatureRef.current.toDataURL()
        setSignatureURL(url);
    }

    //save sign in pdf for single Page JENIL 10/8/2023 4:54 PM
    const saveSignaturePdf = async (isSendMessage) => {
        let { originalHeight, originalWidth } = pageDetails;

        let xx = Math.abs(zoomRef?.current?.instance?.transformState?.positionX) || 0
        let yy = Math.abs(zoomRef?.current?.instance?.transformState?.positionY) || 0

        let base64 = await fetch(props.renderPdf)
            .then(response => response.blob())
            .then(blob => {
                let reader = new FileReader();
                reader.readAsDataURL(blob);
                return new Promise((res) => {
                    reader.onloadend = () => {
                        res(reader.result);
                    }
                })
            })

        let pdfDoc = await PDFDocument.load(base64);
        let pages = pdfDoc.getPages();
        let firstPage = pages[pageNum];
        let zoomPdfScale = zoomRef?.current?.instance?.transformState?.scale || 1

        let pngImage = await pdfDoc.embedPng(signatureURL);
        let pngDims = pngImage.scale(1 / zoomRef?.current?.instance?.transformState?.scale);


        let xwidth = Math.abs((xx / zoomPdfScale));
        let yheight = Math.abs(originalHeight - (yy / zoomPdfScale) - pngDims.height);

        // if sign canvas is not same height as pdf canvas then get parent div height and set position of this parent div wise JENIL 15/8/2023 10:45 AM
        // if (zoomPdfScale > 1) {
        //     let upperButtonCanvas = document.getElementById("pdf-upper-button")?.clientHeight
        //     // let heightDiff = document.getElementsByClassName("render-signature-canvas")[0]?.clientHeight - heightSign
        //     yheight = yheight + (upperButtonCanvas / zoomPdfScale)
        //     // + (heightDiff / zoomPdfScale);
        // }

        firstPage.drawImage(pngImage, {
            x: xwidth,
            y: yheight,
            width: pngDims.width,
            height: pngDims.height,
        });

        //convert to base64 uri JENIL 14/8/2023 3:10 PM
        let base64DataUri = await pdfDoc.saveAsBase64({ dataUri: true })

        // let pdfBytes = await pdfDoc.save();
        // let blob = new Blob([new Uint8Array(pdfBytes)]);

        // let URL = await blobToURL(blob);

        // props.setRenderPdf(URL);
        props.setRenderPdf(base64DataUri);
        setSignatureURL("")
        setIsDraw(false)
        //if isSendMessage is true then api called JENIL 19/8/2023 12:09 PM
        if (isSendMessage) {
            await props.handleSendMessage(setIsScroll, false, "", false, {}, getCurrentState()?.chatList?.singleChat, false, base64DataUri)
        }
    }

    //download pdf with signature JENIL 14/8/2023 12:49 PM
    const downloadPdf = () => {
        let link = document.createElement("a");
        link.download = "list.pdf";
        link.href = props.renderPdf;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    //hide/show emoji picker state
    // const [showEmojiPicker, setShowEmojiPicker] = useState(false);

    const getCurrentState = () => {
        return store.getState()['root']
    }


    //signature pad styles JENIL 12/7/2023 10:43 AM
    let sigPad = {}
    //clear sign
    const clear = () => {
        sigPad.clear();
    }
    const styles = {
        sigContainer: {
            width: "200",
            height: "100",
            backgroundColor: "#e5e5ea",
        },
        signpad: {
            width: "200",
            height: "100",
            marginTop: "8px",
        }
    }

    const [isScroll, setIsScroll] = useState(true);

    //header filter tooltip open/close
    const [tooltipOpen, setTooltipOpen] = useState(false);

    //footer dropdown tooltip open/close
    const [dropdownOpen, setDropdownOpen] = useState(false);

    //get scroll to latest message
    const messagesColumnRef = useRef(null);
    useEffect(() => {
        if (isScroll) {//if add new message list call from page 1 and redirect to last message
            if (!getCurrentState().formData.isChatpreseant) {
                if (messagesColumnRef.current?.scrollHeight) {
                    messagesColumnRef.current.scrollTop = messagesColumnRef.current?.scrollHeight;
                }
            }
        }
        //focus into textarea fields JENIL
        // let inputEle = document.getElementById("guest-chat-msg-input")
        // inputEle?.focus();
    }, [getCurrentState().chatList.chatList]);

    //if you see old messages then don't scroll
    useEffect(() => {
        if (getCurrentState().pageNo > 1) {
            setIsScroll(false);
        }
    }, [getCurrentState().pageNo])

    const textareaRef = useRef(null);

    // const [currentValue, setCurrentValue] = useState("");
    // useEffect(() => {
    //     textareaRef.current.style.height = "0px";
    //     const scrollHeight = textareaRef.current.scrollHeight;
    //     textareaRef.current.style.height = scrollHeight + "px";
    // }, [currentValue]);

    const messagetype = [
        { id: 'isstandard', className: 'standard', icon: <span className="material-icons-outlined d-block"> message </span>, desc: 'Message will be sent as usual', text: 'Standard', color: '', deselect: ['isimportant', 'isurgent'] },
        { id: 'isimportant', className: 'important', icon: <span className="material-icons d-block"> info </span>, desc: 'Message will be mark as important', text: 'Important', color: 'orange', deselect: ['isurgent', 'isstandard'] },
        { id: 'isurgent', className: 'urgent', icon: <span className="material-icons d-block"> notifications_active </span>, desc: 'Message will be mark as urgent', text: 'Urgent', color: 'red', deselect: ['isimportant', 'isstandard'] },
    ]

    const allMediaTabs = [
        { type: 1, name: "Image" },
        { type: 2, name: "Video" },
        { type: 3, name: "Audio" },
        { type: 4, name: "Docs" },
    ]

    //html render form rightsidebarformdata JSON
    const renderHtmlControls = (formfield) => {
        switch (formfield.type) {
            case "input-text":
                return (
                    <textarea
                        placeholder={formfield.placeholder}
                        className='chat-bottom-input-field'
                        id="guest-chat-msg-input"
                        ref={textareaRef}
                        onChange={e => { props.handleFormData(formfield.field, formfield.type, e.target.value); }}
                        value={getCurrentState().formData[formfield.field] ? getCurrentState().formData[formfield.field] : ""}
                        onKeyDown={(e) => {
                            if (e.keyCode === 13 && !e.shiftKey) {
                                e.preventDefault()
                                if (!props.loader) {
                                    props.handleSendMessage(setIsScroll);
                                }
                            }
                        }}
                        style={{ height: "46px" }}
                    />
                )
            case "file":
                return (
                    <div className='chat-bottom-attachment footer-icon'>
                        <input
                            type="file"
                            multiple={true}
                            className="form-control"
                            autoComplete="off"
                            onChange={e => props.handleFormData(formfield.field, formfield.type, e.target.files, [], formfield.filetypes)}
                            accept={formfield.filetypes}
                        />
                        <i className="material-icons-outlined">
                            attachment
                        </i>
                    </div>
                )
            default:
                break;
        }
    }

    const renderIconButton = (props, ref) => {
        return (
            <IconButton {...props} ref={ref}
                className='chat-bottom-icon'
                icon={<i className="material-icons-outlined"> more_vert </i>}
                onClick={() => setDropdownOpen(!dropdownOpen)}
            />
        );
    };

    // const getFirstLetters = (name) => {
    //     let firstLetters = name
    //         .split(' ')
    //         .map(word => word.charAt(0))
    //         .join('');

    //     return firstLetters.toUpperCase();
    // }
    const [expandSearch, setExpandsearch] = useState("")
    const handleExpand = async () => {
        const search = document.querySelector(".search-input-1");
        search.classList.toggle("search-expanded");
        setExpandsearch(!expandSearch);
        //state is update directly that's why {expandSearch === true} in the condition
        //when close the search field then call this function
        if (expandSearch === true) {
            await props.handleFilterData("input-text", "messageSearch", "")
            props.handleChatList();
        }
    };

    //click out side tooltip and dropdown close 
    const menuRef = useRef(null)
    const tooltipRef = useRef(null)
    useEffect(() => {
        document.addEventListener(`click`, (evt) => {
            const curt = tooltipRef?.current?.root
            const nodet = evt.target
            if (curt?.contains(nodet)) return
            setTooltipOpen(false)
            const cur = menuRef?.current
            const node = evt.target
            if (cur?.contains(node)) return
            setDropdownOpen(false)
        })
        return () => {
            document.removeEventListener(`click`, (e) => console.log(e));
        }
    })

    //handle show more function
    const handleShowMore = (id) => {
        document.getElementById(id + 'Overflow').className = '';
        document.getElementById(id + 'MoreLink').className = 'hidden-message';
        document.getElementById(id + 'LessLink').className = '';
    }

    //handle show less function
    const handleShowLess = (id) => {
        document.getElementById(id + 'Overflow').className = 'hidden-message';
        document.getElementById(id + 'MoreLink').className = '';
        document.getElementById(id + 'LessLink').className = 'hidden-message';
    }

    // jatin 11-08-2023 for Replay msg redirect and Blink for Second
    // const redirectMsgReplay = (id) => {
    //     const element = document.getElementById("chat" + id);
    //     console.log('element', element)
    //     if (element) {
    //         element.classList.add("msg-redirect-blink");
    //         setTimeout(() => {
    //             element.classList.remove("msg-redirect-blink");
    //         }, 1000)
    //         element.scrollIntoView({ behavior: 'smooth' }, false);
    //     }
    // }

    //show type in media file also
    const messageTypeLabel = (chat) => {
        return (
            (chat.isimportant === 1 || chat.isurgent === 1) &&
            <p>
                {
                    chat.isimportant === 1 ?
                        <span className='important-text'><i className="material-icons">info</i>Important</span>
                        :
                        chat.isurgent === 1 ?
                            <span className='urgent-text'><i className="material-icons">notifications_active</i>Urgent</span>
                            :
                            ''
                }
            </p>
        )
    }

    //show default emoji icon and add emojipicker icon
    const defaultReactions = [
        { "id": "+1", "name": "Thumbs Up", "native": "👍", "unified": "1f44d", "keywords": ["+1", "thumbsup", "yes", "awesome", "good", "agree", "accept", "cool", "hand", "like"], "shortcodes": ":+1:", "skin": 1, "aliases": ["thumbsup"] },
        { "id": "heart", "name": "Red Heart", "native": "❤️", "unified": "2764-fe0f", "keywords": ["love", "like", "valentines"], "shortcodes": ":heart:", "emoticons": ["<3"] },
        { "id": "laughing", "name": "Grinning Squinting Face", "native": "😆", "unified": "1f606", "keywords": ["laughing", "satisfied", "happy", "joy", "lol", "haha", "glad", "XD", "laugh"], "shortcodes": ":laughing:", "aliases": ["satisfied"], "emoticons": [":>", ":->"] },
        { "id": "astonished", "name": "Astonished Face", "native": "😲", "unified": "1f632", "keywords": ["xox", "surprised", "poisoned"], "shortcodes": ":astonished:" },
        { "id": "pensive", "name": "Pensive Face", "native": "😔", "unified": "1f614", "keywords": ["sad", "depressed", "upset"], "shortcodes": ":pensive:" },
        { "id": "rage", "name": "Pouting Face", "native": "😡", "unified": "1f621", "keywords": ["rage", "angry", "mad", "hate", "despise"], "shortcodes": ":rage:" }
    ]
    const reactionEmoji = (chat) => {
        return (
            <div className='reaction-emoji-item'>
                {
                    defaultReactions.map((item, index) => {
                        return (
                            <div className='reaction-emoji' key={index.toString() + "reactionemojidata"} onClick={() => props.handleReactionEmojiSelect(item, chat, setIsScroll)}>
                                <span>{item.native}</span>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    //show bot message aaray JENIL 17/7/2023 10:09 AM
    const showBotCustomMessages = [
        { message: "Request Late Checkout", requestadd: 1 },
        { message: "Extend Stay", requestadd: 2 },
        { message: "Checkout", requestadd: 3 },
    ]

    //datepicker state
    const [date, setDate] = useState("")
    const [days, setDays] = useState(1)

    useEffect(() => {
        if (getCurrentState().reservationDetails._id) {
            let checkout = IISMethods.GetDT(getCurrentState().reservationDetails?.checkoutdate)
            checkout.setDate(checkout.getDate() + 1)
            setDate(checkout)
        }
    }, [getCurrentState().reservationDetails._id])

    //datepicker handle date change 
    const handleDateChange = (value) => {
        let days = IISMethods.GetDiffInDay(IISMethods.GetDT(getCurrentState().reservationDetails['checkoutdate']), IISMethods.GetDT(value))
        setDays(days)
        setDate(value ? IISMethods.GetISOFromDTobj(value) : '')
    }

    // for extend stay date and counter manage JENIL 17/7/2023 12:06 PM
    const getTotalDayAndDate = (checkoutdate, days) => {
        let selectedDate = IISMethods.GetDT(checkoutdate)
        selectedDate.setDate(selectedDate.getDate() + Number(days))
        return days ? selectedDate : ''
    }

    //days change JENIL 17/7/2023 12:02 PM
    const handleDayChange = (value) => {
        if (value === "inc") {
            setDays(days + 1)
            let checkoutdate = getTotalDayAndDate(getCurrentState().reservationDetails?.checkoutdate, days + 1)
            setDate(IISMethods.GetDT(checkoutdate))
        }
        else if (value === "dec" && days > 1) {
            setDays(days - 1)
            let checkoutdate = getTotalDayAndDate(getCurrentState().reservationDetails?.checkoutdate, days - 1)
            setDate(IISMethods.GetDT(checkoutdate))
        }
    }

    //latecheckout form state JENIL 04/08/2023 2:37 PM
    const [lateCheckoutTime, setLateCheckoutTime] = useState("");


    const [open, setOpen] = React.useState(false);
    const [size, setSize] = React.useState();

    const handleClose = () => setOpen(false);

    //modal menus showing state
    const [menus, setMenus] = useState([])
    const [selectMenu, setSelectMenu] = useState({});
    const [chatObj, setChatObj] = useState({})

    //open menu JENIL 11/7/2023 5:38 PM
    const handleModalOpen = (value, menus = [], chat) => {
        setMenus([...menus])
        setSize(value);
        setOpen(true);
        setChatObj(chat);
    }

    //show from to time option JENIL 12/7/2023 10:13 AM
    const fromToTimeOptionShow = (option) => {
        let timefrom = IISMethods.GetTimeFormat(option.latecheckoutfromtime, 1)
        let timeto = IISMethods.GetTimeFormat(option.latecheckouttotime, 1)
        return `${timefrom} - ${timeto} ($${option.latecheckoutcharges})`
    }

    //for extend stay take selfie JENIL 17/7/2023 12:45 PM
    const [selfieImage, setSelfieImage] = useState("");

    //open camera and take selfie
    const handleUploadImage = async (event) => {
        console.log(event, "event")
        let file = event.target.files[0]
        let dataUrl = await IISMethods.resizeImageFile(file) //await imgToBase64(file)
        console.log(dataUrl)
        setSelfieImage(dataUrl);
    }

    //show firstname lastname image data JENIL 18/7/2023 5:01 PM
    const getDefaulImageIconForName = (name) => {
        return name?.split(' ').map(item => item.toUpperCase().substring(0, 1)).join('').slice(0, 2)
    }

    //for credit card scan html JENIL 26/7/2023 10:21 AM
    // const [scanLoader, setScanLoader] = useState(false);
    // const renderScanCardHtmlControls = (formfield, submittedValue) => {
    //     if (formfield && (formfield.defaultvisibility)) {
    //         // if (formfield.field === 'cardnumber' && submittedValue) {
    //         //     debugger
    //         //     let val = submittedValue || ''
    //         //     if (val) {
    //         //         let cardnumber = val.split(' ')
    //         //         submittedValue = `**** **** **** ${cardnumber[cardnumber.length - 1].join('')}`
    //         //     } 
    //         // }
    //         let value = getCurrentState().formData[formfield.field] || ''
    //         if (formfield.field === 'cardnumber' && (submittedValue || value)) {
    //             let cardnumber = submittedValue ? submittedValue : value.split(' ')
    //             value = `**** **** **** ${cardnumber[cardnumber.length - 1]}`
    //         }
    //         if (formfield.type === 'input-text') {
    //             return (
    //                 <div className={formfield.gridsize}>
    //                     <div className="form-group">
    //                         <label className="label-form">{formfield.text}</label>
    //                         <input
    //                             onChange={(e) => { props.handleFormData(formfield.field, formfield.type, e.target.value) }}
    //                             id={"form-" + formfield.field}
    //                             type="text"
    //                             disabled={submittedValue ? true : formfield.disabled}
    //                             name={formfield.field}
    //                             className="form-control"
    //                             placeholder={"Enter " + formfield.text}
    //                             // value={submittedValue ? submittedValue : getCurrentState().formData[formfield.field] ? getCurrentState().formData[formfield.field] : ''}
    //                             value={value}
    //                             required={true}
    //                         />
    //                     </div>
    //                 </div>
    //             )
    //         }
    //     }
    // }

    //upload credit card image JENIL 26/7/2023 10:22 AM
    // const handleCreditCardUploadImage = async (event) => {
    //     setScanLoader(true);
    //     let file = event.target.files[0]
    //     let dataUrl = await IISMethods.resizeImageFile(file, { maxHW: 600 }) //await imgToBase64(file)
    //     await props.handleScanImage(dataUrl)
    //     setScanLoader(false);
    // }

    //checkout radio options JENIL 05/08/2023 1:20 PM
    const checkOutRadioOption = [
        {
            'icon': Mail,
            'label': 'Email',
            'value': 1,
            'answerid': "64ccc9763e672959d73166dc"
        },
        {
            'icon': Print,
            'label': 'Print',
            'value': 2,
            'answerid': "64ccc9a63e672959d73166dd"
        },
        {
            'icon': Receipt,
            'label': 'No Receipt',
            'value': 3,
            'answerid': "64ccc9e13e672959d73166de"
        }
    ]

    //checkout options JENIL 05/08/2023 1:16 PM
    const [checkoutOption, setCheckoutOption] = useState(3);
    const [checkoutEmail, setCheckoutEmail] = useState("");

    //slefie image of guest in form
    //chatSelfie = data from api
    //selfieImage = run time state
    //setSelfieImage = run time set state
    //isdisbale == isdiable api side
    // id= input id
    const guestSelfieImageForm = (chatSelfie, selfieImage, setSelfieImage, isdisbale, id) => {
        return (
            <div className='selfie-photo mt-2 product-add-box-inner'>
                <div className="col-12 px-0">
                    <div className="form-group selfie-box mb-0">
                        <label className='guest-request-maintenance-lable text-12'>Please take your photo for self verification</label>
                        <input
                            className='d-none'
                            id={id}
                            type='file'
                            capture="environment"
                            // accept={allowdedImage}
                            accept="image/*"
                            onChange={(e) => {
                                handleUploadImage(e)
                            }}
                        />
                        {
                            (chatSelfie || selfieImage) ?
                                <>
                                    <div className='show-selfie-image'>
                                        {
                                            !chatSelfie && selfieImage &&
                                            <div className='chat-img-close' onClick={() => setSelfieImage("")}>
                                                <i class="material-icons cancel-btn">close</i>
                                            </div>
                                        }
                                        <img src={chatSelfie ? chatSelfie : selfieImage} alt={"selfie"} />
                                    </div>
                                    {
                                        !isdisbale &&
                                        <label htmlFor={id} className='reatake-image-icon'>
                                            <i class="material-icons-round">
                                                photo_camera
                                            </i>
                                        </label>
                                    }
                                </>
                                :
                                <div className="col-auto px-0 my-auto cursor-pointer">
                                    <a className="add-field-btn-paymentmethod mt-2 btn-primary-light-paymentmethod border-radius-10">
                                        <label htmlFor={isdisbale ? "" : id} className='my-auto'><i className="material-icons-round">photo_camera</i></label>
                                    </a>
                                </div>
                        }
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            {/* back history not static link JENIL 13/9/2023 10:47 PM */}
            <BackHeader isHistoryBack={true} linkto='/mystay' />
            <div className={`body-content chat-box ${pagename === "chatwithus" ? "chatwith-bottom-btn" : "chatwithout-bottom-btn"}`}>
                <div className='col-12 px-0'>
                    <div className='chat-container'>
                        {
                            (getCurrentState()?.chatList?.chatList?.length === 0 || getCurrentState()?.chatList?.chatList) &&
                            <div className='chat-blank-img'>
                                <img src={blankimg} alt="chat-ui" />
                            </div>
                        }
                        {
                            /*comment by JENIL 03/08/2023 4:15 PM */
                            // !props.pagename &&
                            // <div className='top-bar-chat row'>
                            //     <div className="row">
                            //         <div className="col-12">
                            //             {/* <div className='row px-3'>
                            //                 <div className="pl-0 col">
                            //                     <div className="chat-search-form">
                            //                         <a className="search-wrapper mb-3" onClick={handleExpand}>
                            //                             <span className={`material-icons-outlined ${expandSearch ? 'd-none' : 'd-inline-flex'}`}>
                            //                                 search
                            //                             </span>
                            //                             <span className={`material-icons-outlined ${expandSearch ? 'd-inline-flex' : 'd-none'}`}>
                            //                                 close
                            //                             </span>
                            //                         </a>
                            //                         <input
                            //                             className="search-input-1 form-control"
                            //                             placeholder="Search"
                            //                             value={getCurrentState().filterData.messageSearch ? getCurrentState().filterData.messageSearch : ""}
                            //                             onChange={(e) => props.handleFilterData("input-text", "messageSearch", e.target.value)}
                            //                             onKeyDown={(e) => {
                            //                                 if (e.key === 'Enter') {
                            //                                     e.preventDefault()
                            //                                     props.handleChatList();
                            //                                 }
                            //                             }}
                            //                             onBlur={(e) => {
                            //                                 e.preventDefault()
                            //                                 props.handleChatList();
                            //                             }}
                            //                         />
                            //                     </div>
                            //                 </div>
                            //                 <div className='col-auto ml-auto chat-top-bar-icon mb-3' style={{ marginRight: "10px" }}>
                            //                     <Whisper
                            //                         placement="bottomEnd"
                            //                         trigger={'click'}
                            //                         open={tooltipOpen}
                            //                         ref={tooltipRef}
                            //                         speaker={
                            //                             <Tooltip className="hide-marker dropdown-tag-content" style={{ width: '150px', background: 'white', color: 'black', boxShadow: '5px 5px 15px lightgray' }}>
                            //                                 <div className=''>
                            //                                     {
                            //                                         messagetype.map(li => {
                            //                                             let checked = getCurrentState().filterData[li.id] === 1 ? true : false
                            //                                             return (
                            //                                                 <div className={`d-flex cursor-pointer justify-content-between mb-2 ${li.className}`} key={li.id.toString() + "tooltip"}
                            //                                                     onClick={async () => { await props.handleFilterData("radio", li.id, 1, li.deselect); setTooltipOpen(false); props.handleChatList() }}>
                            //                                                     <div className='d-flex cursor-pointer align-items-center drop-down-conntents justify-content-start'>
                            //                                                         <div className='drop-icons'>{li.icon}</div>
                            //                                                         <div className='text-left'>
                            //                                                             <div className='title mb-0'>{li.text}</div>
                            //                                                         </div>
                            //                                                     </div>
                            //                                                     {
                            //                                                         checked &&
                            //                                                         <span className="material-icons-round checked-icon">
                            //                                                             done
                            //                                                         </span>
                            //                                                     }
                            //                                                 </div>
                            //                                             )
                            //                                         })
                            //                                     }
                            //                                 </div>
                            //                             </Tooltip>
                            //                         }
                            //                         enterable={true}
                            //                     >
                            //                         <a className="material-icons-round rotate--180 d-block" onClick={() => setTooltipOpen(!tooltipOpen)}>sort</a>
                            //                     </Whisper>
                            //                 </div>
                            //                 <div className='col-auto ml-auto chat-top-bar-icon mb-3 ml-2' onClick={() => props.handleGrid("modal", "allMedia", true)}>
                            //                     <a className="material-icons-round d-block">images</a>
                            //                 </div>
                            //             </div> */}
                            //             <div className='d-flex w-100 justify-content-end align-items-center'>
                            //                 {
                            //                     (getCurrentState().reservationDetails?.firstname || getCurrentState().reservationDetails?.lastname) &&
                            //                     <div className='d-flex w-100 justify-content-start'>
                            //                         <p className='chat-top-title'>Hello, {getCurrentState().reservationDetails?.firstname && getCurrentState().reservationDetails?.firstname} {getCurrentState().reservationDetails?.lastname && getCurrentState().reservationDetails?.lastname}</p>
                            //                     </div>
                            //                 }
                            //                 <div className='d-flex gap-10 align-items-center'>
                            //                     <div className='chat-top-bar-icon'>
                            //                         <Whisper
                            //                             placement="bottomEnd"
                            //                             trigger={'click'}
                            //                             open={tooltipOpen}
                            //                             ref={tooltipRef}
                            //                             speaker={
                            //                                 <Tooltip className="hide-marker dropdown-tag-content" style={{ width: '150px', background: 'white', color: 'black', boxShadow: '5px 5px 15px lightgray' }}>
                            //                                     <div className=''>
                            //                                         {
                            //                                             messagetype.map(li => {
                            //                                                 let checked = getCurrentState().filterData[li.id] === 1 ? true : false
                            //                                                 return (
                            //                                                     <div className={`d-flex cursor-pointer justify-content-between mb-2 ${li.className}`} key={li.id.toString() + "tooltip"}
                            //                                                         onClick={async () => { await props.handleFilterData("radio", li.id, 1, li.deselect); setTooltipOpen(false); props.handleChatList() }}>
                            //                                                         <div className='d-flex cursor-pointer align-items-center drop-down-conntents justify-content-start'>
                            //                                                             <div className='drop-icons'>{li.icon}</div>
                            //                                                             <div className='text-left'>
                            //                                                                 <div className='title mb-0'>{li.text}</div>
                            //                                                             </div>
                            //                                                         </div>
                            //                                                         {
                            //                                                             checked &&
                            //                                                             <span className="material-icons-round checked-icon">
                            //                                                                 done
                            //                                                             </span>
                            //                                                         }
                            //                                                     </div>
                            //                                                 )
                            //                                             })
                            //                                         }
                            //                                     </div>
                            //                                 </Tooltip>
                            //                             }
                            //                             enterable={true}
                            //                         >
                            //                             <a className="material-icons-round rotate--180 d-block" onClick={() => setTooltipOpen(!tooltipOpen)}>sort</a>
                            //                         </Whisper>
                            //                     </div>
                            //                     <div className='chat-top-bar-icon' onClick={() => props.handleGrid("modal", "allMedia", true)}>
                            //                         <a className="material-icons-round d-block">images</a>
                            //                     </div>
                            //                 </div>
                            //                 <div className="chat-search-form">
                            //                     <input
                            //                         className="search-input-1 form-control"
                            //                         placeholder="Search"
                            //                         value={getCurrentState().filterData.messageSearch ? getCurrentState().filterData.messageSearch : ""}
                            //                         onChange={(e) => props.handleFilterData("input-text", "messageSearch", e.target.value)}
                            //                         onKeyDown={(e) => {
                            //                             if (e.key === 'Enter') {
                            //                                 e.preventDefault()
                            //                                 props.handleChatList();
                            //                             }
                            //                         }}
                            //                         onBlur={(e) => {
                            //                             e.preventDefault()
                            //                             props.handleChatList();
                            //                         }}
                            //                     />
                            //                     <a className="search-wrapper mb-3" onClick={handleExpand}>
                            //                         <span className={`material-icons-outlined ${expandSearch ? 'd-none' : 'd-inline-flex'}`}>
                            //                             search
                            //                         </span>
                            //                         <span className={`material-icons-outlined ${expandSearch ? 'd-inline-flex' : 'd-none'}`}>
                            //                             close
                            //                         </span>
                            //                     </a>
                            //                 </div>
                            //             </div>
                            //         </div>
                            //     </div>
                            // </div>
                        }
                        <div className='chat-block-content'>
                            <div className={`chat-block-inner-content ${pagename !== "chatwithus" || getCurrentState()?.reservationDetails?.isguestcheckout === 1 ? "chat-block-inner-content-bottom-zero" : ""} ${addclass ? "chat-important-content" : ""}`} ref={messagesColumnRef} onScroll={(e) => props.handleChatScroll(e, setIsScroll)}>
                                {
                                    getCurrentState()?.chatList?.chatList?.length > 0 &&
                                    getCurrentState()?.chatList?.chatList?.map((chat, i) => {
                                        //show date and time today/yesterday or date
                                        // let yesterdayObj = new Date();
                                        const currentDate = IISMethods.GetDateFormat(chat?.time)
                                        // yesterdayObj.setDate(yesterdayObj.getDate() - 1)
                                        // let yesterday = IISMethods.GetDateFormat(yesterdayObj)
                                        const day = IISMethods.handleDate(chat?.time)
                                        const previousDate = (i === 0) ? "" : IISMethods.GetDateFormat(getCurrentState()?.chatList?.chatList[i - 1]?.time)
                                        const time = IISMethods.GetTimeFormat(chat?.time)
                                        //current person right is from reservation details personid === reserverationId (guest) and other if from userlist(agent)
                                        let userObj = getCurrentState()?.userList?.find((user) => chat?.personid === user._id)
                                        let currentPerson =
                                            chat.personid === getCurrentState().reservationDetails?._id ?
                                                getCurrentState().reservationDetails?.firstname + " " + getCurrentState().reservationDetails?.lastname
                                                :
                                                userObj?.personname || "Nextap AI"
                                        let previousPerson =
                                            i === 0 ?
                                                ""
                                                :
                                                getCurrentState()?.chatList?.chatList[i - 1].personid === getCurrentState().reservationDetails?._id ?
                                                    getCurrentState().reservationDetails?.firstname + " " + getCurrentState().reservationDetails?.lastname
                                                    :
                                                    getCurrentState()?.userList?.find((user) => getCurrentState()?.chatList?.chatList[i - 1]?.personid === user._id)?.personname || "Nextap AI"
                                        //current person profile pic JENIL 18/7/2023 10:20 AM
                                        let profilepic =
                                            chat.personid === props?.roomData?.reservationid ?
                                                getCurrentState().reservationDetails?.selfphoto
                                                :
                                                userObj?.profilepic
                                        //read more and read less functionality 
                                        let startMsg = ""
                                        let endMsg = ""
                                        let len = 500
                                        let id = ""
                                        if (chat?.message?.length > len) {
                                            startMsg = chat?.message?.substring(0, len);
                                            endMsg = chat?.message?.substring(len, chat?.message?.length);
                                            id = `guestExpMsg${i}`
                                        }
                                        //chat reactions message functionality
                                        let reactions = chat.reactions ? [...chat.reactions] : []
                                        let showReactions = [];
                                        if (reactions.length > 0) {
                                            let reactionIdsArray = reactions.map(a => a.reactionid)
                                            let uniqueArray = reactionIdsArray.filter((value, index, self) => self.indexOf(value) === index);
                                            uniqueArray.forEach((a) => {
                                                let sameReactions = chat.reactions.filter(v => v.reactionid === a);
                                                let obj = {
                                                    id: a,
                                                    reaction: sameReactions[0].reaction,
                                                    count: sameReactions.length,
                                                    reactionbyuid: sameReactions.map(v => {
                                                        let name = getCurrentState()?.userList?.find((user) => v.reactionbyuid === user._id)?.personname
                                                        return getCurrentState().reservationDetails?._id === v.reactionbyuid ? getCurrentState().reservationDetails?.firstname + " " + getCurrentState().reservationDetails?.lastname : name
                                                    })
                                                }
                                                showReactions.push(obj)
                                            })
                                        }
                                        //video and image comes first is list then pdf and audio JENIL 26/6/2023 2:57 PM
                                        let videoImageFile = chat.files?.length > 0 ? [...chat.files?.filter((a) => [1, 2].includes(a.type))] : []
                                        let audioDocFile = chat.files?.length > 0 ? [...chat.files?.filter((a) => [3, 4].includes(a.type))] : []
                                        chat.files = videoImageFile.concat(audioDocFile);
                                        return (
                                            <div id={`chat${chat._id}`} className={`chat-item ${chat.personid === getCurrentState().reservationDetails?._id ? 'right' : 'left'}`} key={chat._id}>
                                                <div className='w-100'>
                                                    {
                                                        (currentDate !== previousDate) &&
                                                        <div className='text-center chat-date'>{day}</div>
                                                    }
                                                    {(currentPerson !== previousPerson || currentDate !== previousDate) &&
                                                        <div className={`gap-10 mt-2 ${chat.personid === getCurrentState().reservationDetails?._id ? 'd-flex flex-row-reverse' : 'd-flex flex-row'} `}>
                                                            {
                                                                profilepic ?
                                                                    <figure className="chat-profile-img-div mb-0">
                                                                        <img className='chat-profile-img' src={chat.personid === Config.dummyid ? getCurrentState()?.propertyData?.logo : profilepic} />
                                                                    </figure>
                                                                    :
                                                                    <div className='profile-text-icon'>
                                                                        <div className={`default-img-icon`}><span>{chat.personid === Config.dummyid ? getCurrentState()?.propertyData?.propertyname : getDefaulImageIconForName(currentPerson)}</span></div>
                                                                    </div>
                                                            }
                                                            <span className='d-flex align-items-center chat-name'>
                                                                {chat.personid === Config.dummyid ? getCurrentState()?.propertyData?.propertyname : currentPerson}
                                                            </span>
                                                        </div>
                                                    }
                                                </div>
                                                {
                                                    chat?.message && (chat?.files?.length === 0 || !chat?.files) &&
                                                    <div className="w-90">
                                                        <div className={`auto-width ${chat.optionshowtype === 1 ? "bottom-menu" : ""}`}>
                                                            <Whisper
                                                                placement="topEnd"
                                                                trigger={"contextMenu"}
                                                                speaker={
                                                                    <Tooltip className="show-reaction-emojis-tooltip">
                                                                        {reactionEmoji(chat)}
                                                                    </Tooltip>
                                                                }
                                                                enterable={true}
                                                            >
                                                                <div className={`chat-text ${(currentPerson !== previousPerson || currentDate !== previousDate) ? "corner-border" : ""} ${chat.reservationid === chat.msgfromid ? 'chatfrom left' : 'chatto right'} ${chat.isimportant === 1 ? 'chat-msgtype-important important' : chat.isurgent === 1 ? 'chat-msgtype-urgent urgent' : ''} `}>
                                                                    {messageTypeLabel(chat)}
                                                                    {
                                                                        //replyid is not dummyid then show reply message JENIL 19/7/2023 11:21 AM
                                                                        (chat?.replytomessageid && chat?.replytomessageid !== Config.dummyid) ?
                                                                            // reply message html
                                                                            <>
                                                                                <div id={`chat${chat._id}`} className='position-realtive' onClick={() => props.redirectMsgReplay(chat.replytomessageid)}>
                                                                                    <div className='forward-content'>
                                                                                        <span className='d-block message-wrap'>
                                                                                            {chat.replytomessage}
                                                                                        </span>
                                                                                        {
                                                                                            //in reply message show approval charges JENIL 29/7/2023 10:59 AM
                                                                                            chat.request?.extendstaycharges &&
                                                                                            <div className='show-average-nights-rates d-flex align-items-center gap-1 mt-1'>
                                                                                                <label className='guest-request-maintenance-lable mb-0 text-12 text-dark font-weight-600 d-block'>Avg. Nightly Rate : </label>
                                                                                                <span className='show-average-nights-rates-value font-weight-600 text-green text-14'>${chat.request?.extendstaycharges}</span>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                    <div className='chat-time d-block'>{time}</div>
                                                                                </div>
                                                                                <span className='d-block message-wrap'>
                                                                                    {
                                                                                        //when extend stay form submit show value no of nights and check out date JENIL 24/7/2023 12:23 PM
                                                                                        (chat?.optionshowtype === 0 && chat.optiontype === 4 && chat?.request?.extendstaydate) ?
                                                                                            <div className='extend-stay-labels'>
                                                                                                <div className='d-block'>
                                                                                                    <label className='mb-0 text-12 text-dark font-weight-600'>No. of Nights : </label>
                                                                                                    <span className='text-12 text-dark font-weight-600'>{chat?.request?.extendstaydays}</span>
                                                                                                </div>
                                                                                                <div className='d-block'>
                                                                                                    <label className='mb-0 text-12 text-dark font-weight-600'>Checkout Date : </label>
                                                                                                    <span className='text-12 text-dark font-weight-600'>{chat?.message}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                            chat.message
                                                                                    }
                                                                                </span>
                                                                            </>
                                                                            :
                                                                            <div className='position-realtive'>
                                                                                {
                                                                                    // startmasg and endmsg has value then show
                                                                                    (startMsg !== "" && endMsg !== "") ?
                                                                                        <span className='d-block message-wrap'>
                                                                                            {startMsg}
                                                                                            <span className='hidden-message' id={id + "Overflow"}>
                                                                                                {endMsg}
                                                                                            </span>
                                                                                            <Link id={id + "MoreLink"} to="#" onClick={() => handleShowMore(id)}>
                                                                                                ...Read More
                                                                                            </Link>
                                                                                            <Link className="hidden-message" to="#" id={id + "LessLink"} onClick={() => handleShowLess(id)}>
                                                                                                Read Less
                                                                                            </Link>
                                                                                        </span>
                                                                                        :
                                                                                        //normal message show
                                                                                        <span className='d-block message-wrap'>
                                                                                            {chat.message}
                                                                                            {
                                                                                                //add reject reason message JENIL 27/7/2023 7:08 PM
                                                                                                chat?.rejectreason &&
                                                                                                <div className='extend-stay-labels'>
                                                                                                    <div className='d-block'>
                                                                                                        <label className='mb-0 text-12 text-dark font-weight-600'>Reason : </label>
                                                                                                        <span className='text-12 text-dark font-weight-600'>{chat.rejectreason}</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            }
                                                                                            {
                                                                                                //if SignaturePad is there then remove sign for that latecheckoutsignature
                                                                                                chat?.optionshowtype === 4 && chat.optiontype === 5 && !chat?.request?.latecheckoutsignature && !chat?.isdisable &&
                                                                                                <span className='clear-sign-text d-block' onClick={() => {
                                                                                                    // if (chat.isdisable === 1) {
                                                                                                    // } else {
                                                                                                    clear()
                                                                                                    // }
                                                                                                }}>Clear Sign</span>
                                                                                            }
                                                                                            {
                                                                                                //if SignaturePad is there then remove sign for that extendstaysignature
                                                                                                chat?.optionshowtype === 4 && chat.optiontype === 6 && !chat?.request?.confirmationsignature && !chat?.isdisable &&
                                                                                                <span className='clear-sign-text d-block' onClick={() => {
                                                                                                    // if (chat.isdisable === 1) {
                                                                                                    // } else {
                                                                                                    clear()
                                                                                                    // }
                                                                                                }}>Clear Sign</span>
                                                                                            }
                                                                                        </span>
                                                                                }
                                                                                {
                                                                                    //show signpad or image latecheckoutsignature or extendstaysignature
                                                                                    (chat?.optionshowtype === 4) &&
                                                                                    <div style={styles.sigContainer} id='canvas' className='sign-edit-box'>
                                                                                        {
                                                                                            (chat?.request?.latecheckoutsignature || chat?.request?.confirmationsignature) ?
                                                                                                <img src={chat?.request?.latecheckoutsignature || chat?.request?.confirmationsignature} alt="sign" />
                                                                                                :
                                                                                                chat?.isdisable === 1 ?
                                                                                                    <div style={styles.signpad} className='cursor-not-allowed'></div>
                                                                                                    :
                                                                                                    <SignaturePad
                                                                                                        canvasProps={{ style: styles.signpad }}
                                                                                                        ref={(ref) => { sigPad = ref }}
                                                                                                    />
                                                                                        }
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    //show average nights per rate
                                                                                    chat?.optionshowtype === 6 && chat?.optiontype === 1 &&
                                                                                    <div className='show-average-nights-rates d-flex align-items-center gap-1 mt-1'>
                                                                                        <label className='guest-request-maintenance-lable mb-0 text-12 text-dark font-weight-600 d-block'>Avg. Nightly Rate : </label>
                                                                                        <span className='show-average-nights-rates-value font-weight-600 text-green text-14'>${chat.request?.extendstaycharges}</span>
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    //show datepicker
                                                                                    chat?.optionshowtype === 5 && chat?.optiontype === 3 &&
                                                                                    <div className='extend-stay-add-form'>
                                                                                        <div className='days-picker-edit-input mt-2 product-add-box-inner'>
                                                                                            <div className='col-12 px-0'>
                                                                                                <div className="form-group">
                                                                                                    <label className='guest-request-maintenance-lable mb-2 text-12'>No of Nights</label>
                                                                                                    <div className="d-flex justify-content-between product-add-box h-0 p-2">
                                                                                                        <a className={`add-field-btn btn-icon position-static remove-box m-0 ${chat?.isdisable ? "opacity-7" : ""}`} onClick={() => {
                                                                                                            if (!chat?.isdisable) {
                                                                                                                handleDayChange('dec')
                                                                                                            }
                                                                                                        }}><i className="material-icons-round">remove</i></a>
                                                                                                        <div className={`col-9 d-flex justify-content-center align-items-center notifyText ${chat?.isdisable ? "opacity-7" : ""}`}>{chat?.request?.extendstaynoofnights ? chat?.request?.extendstaynoofnights : days}</div>
                                                                                                        <a className="add-field-btn btn-icon position-static remove-box m-0" onClick={() => {
                                                                                                            if (!chat?.isdisable) {
                                                                                                                handleDayChange('inc')
                                                                                                            }
                                                                                                        }}><i className="material-icons-round">add</i></a>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <span className='date-days-or-text my-2'>OR</span>
                                                                                        <div className='date-picker-edit-input mt-0 product-add-box-inner'>
                                                                                            <div className="col-12 px-0">
                                                                                                <div className="form-group validate-input">
                                                                                                    <label className='guest-request-maintenance-lable text-12'>Select Checkout Date</label>
                                                                                                    <DatePickerRsuite
                                                                                                        placement="bottomStart"
                                                                                                        className="form-control p-0 has-val custom-picker"
                                                                                                        placeholder="Please Select date"
                                                                                                        format={IISMethods.getDateFormatByProperty()}
                                                                                                        id={"chatbot-datepicker"}
                                                                                                        name={"chatbot-datepicker"}
                                                                                                        value={chat?.request?.extendstaydate ? IISMethods.GetDateTimefromISOString(chat?.request?.extendstaydate) : date ? IISMethods.GetDateTimefromISOString(date) : ""}
                                                                                                        onChange={(e) => {
                                                                                                            if (!chat?.isdisable) {
                                                                                                                handleDateChange(e)
                                                                                                            }
                                                                                                        }}
                                                                                                        cleanable={false}
                                                                                                        disabledDate={date => isBefore(date, IISMethods.GetDT(getCurrentState().reservationDetails['checkoutdate']))}
                                                                                                        disabled={chat?.isdisable === 1 ? true : false}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='selfie-photo mt-2 product-add-box-inner'>
                                                                                            <div className="col-12 px-0">
                                                                                                <div className="form-group selfie-box mb-0">
                                                                                                    <label className='guest-request-maintenance-lable text-12'>Please take your photo for self verification</label>
                                                                                                    <input
                                                                                                        className='d-none'
                                                                                                        id={'paymentcard'}
                                                                                                        type='file'
                                                                                                        capture="environment"
                                                                                                        // accept={allowdedImage}
                                                                                                        accept="image/*"
                                                                                                        onChange={(e) => {
                                                                                                            console.log(e, "e")
                                                                                                            console.log(chat, "chat")
                                                                                                            // if (!chat?.isdisable) {
                                                                                                            handleUploadImage(e)
                                                                                                            // }
                                                                                                        }}
                                                                                                    />
                                                                                                    {
                                                                                                        (chat?.request?.extendstayguestselfie || selfieImage) ?
                                                                                                            <>
                                                                                                                <div className='show-selfie-image'>
                                                                                                                    {
                                                                                                                        !chat?.request?.extendstayguestselfie && selfieImage &&
                                                                                                                        <div className='chat-img-close' onClick={() => setSelfieImage("")}>
                                                                                                                            <i class="material-icons cancel-btn">close</i>
                                                                                                                        </div>
                                                                                                                    }
                                                                                                                    <img src={chat?.request?.extendstayguestselfie ? chat?.request?.extendstayguestselfie : selfieImage} alt={"selfie"} />
                                                                                                                </div>
                                                                                                                {
                                                                                                                    !chat?.isdisable &&
                                                                                                                    <label htmlFor={'paymentcard'} className='reatake-image-icon'>
                                                                                                                        <i class="material-icons-round">
                                                                                                                            {/* {
                                                                                                                                scanLoader ?
                                                                                                                                    <Loader size='sm' />
                                                                                                                                    : */}
                                                                                                                            photo_camera
                                                                                                                            {/* } */}
                                                                                                                        </i>
                                                                                                                    </label>
                                                                                                                }
                                                                                                            </>
                                                                                                            :
                                                                                                            <div className="col-auto px-0 my-auto cursor-pointer">
                                                                                                                <a className="add-field-btn-paymentmethod mt-2 btn-primary-light-paymentmethod border-radius-10">
                                                                                                                    <label htmlFor={chat?.isdisable ? "" : 'paymentcard'} className='my-auto'><i className="material-icons-round">photo_camera</i></label>
                                                                                                                </a>
                                                                                                            </div>
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    //credit card scan form JENIL 04/08/2023 2:25 PM
                                                                                    chat?.optionshowtype === 7 && chat?.optiontype === 7 &&
                                                                                    <div className='creditcard-scan-form extend-stay-add-form'>
                                                                                        {
                                                                                            chat?.request?.iscreditcardmsg === 1
                                                                                            ?
                                                                                            <span className='text-success text-14'>Your credit card details has been successfully submitted.</span>
                                                                                            :
                                                                                            <>
                                                                                                <input className='d-none' id={`creditcard-scan${chat._id}`} type='file' capture="environment" accept="image/*" onChange={(e) => props.handleCreditCardUploadImage(e, chat._id)} />
                                                                                                <div className="col-auto cursor-pointer">
                                                                                                    <a className="d-flex align-items-center justify-content-center">
                                                                                                        <label className={chat.isdisable === 1 ? "disable-camera-label m-0 d-flex border border-radius-5 p-3 mt-3" : "m-0 d-flex border border-radius-5 p-3 mt-3"} htmlFor={chat.isdisable === 1 ? "" : `creditcard-scan${chat._id}`}>
                                                                                                            {
                                                                                                                getCurrentState().loading === chat._id
                                                                                                                ?
                                                                                                                <Loader />
                                                                                                                :
                                                                                                                <i className="material-icons-round text-30">photo_camera</i>
                                                                                                            }
                                                                                                        </label>
                                                                                                    </a>
                                                                                                </div>
                                                                                                {
                                                                                                    getCurrentState().formData.cardnumber && props.chatMsgID === chat._id
                                                                                                    &&
                                                                                                    <span className='text-success d-block mt-3'>Your credit card has been scanned successfully. Please press Submit button to process ahead.</span>
                                                                                                }
                                                                                            </>
                                                                                        }
                                                                                        {/* <div className='date-picker-edit-input product-add-box-inner mt-2'>
                                                                                            <div className='row'>
                                                                                                {renderScanCardHtmlControls(props.scanCardRightSidebarFormData[0]?.formfields[0], chat.request?.cardnumber)}
                                                                                                {renderScanCardHtmlControls(props.scanCardRightSidebarFormData[0]?.formfields[0], chat.request?.cardnumber)}
                                                                                                <input className='d-none' id={'creditcard-scan'} type='file' capture="environment" accept="image/*" onChange={props.handleCreditCardUploadImage} />
                                                                                                <div className="col-auto px-0 my-auto cursor-pointer">
                                                                                                    <a className="mt-2 btn-primary-light-paymentmethod border-radius-10 card-icon-scan">
                                                                                                        <label className={chat.isdisable === 1 ? "disable-camera-label" : ""} htmlFor={chat.isdisable === 1 ? "" : 'creditcard-scan'}>
                                                                                                            <i className="material-icons-round">photo_camera</i>
                                                                                                        </label>
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div> */}
                                                                                        {/* <div className='date-picker-edit-input product-add-box-inner mt-2'>
                                                                                            <div className='row'>
                                                                                                {renderScanCardHtmlControls(props.scanCardRightSidebarFormData[0]?.formfields[1], chat.request?.cardexpirydate)}
                                                                                                {renderScanCardHtmlControls(props.scanCardRightSidebarFormData[0]?.formfields[1],  chat.request?.cardexpirydate)}
                                                                                            </div>
                                                                                        </div> */}
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    //late checkout form JENIL 05/08/2023 1:15 PM
                                                                                    chat?.optionshowtype === 8 && chat?.optiontype === 8 &&
                                                                                    <div className='late-checkout-time-form extend-stay-add-form'>
                                                                                        <div className='product-add-box-inner'>
                                                                                            <div className="col-12 px-0">
                                                                                                <div className="form-group validate-input">
                                                                                                    <label className='guest-request-maintenance-lable text-12'>Select Late Checkout Time</label>
                                                                                                    <TimePickerRsuite
                                                                                                        cleanable={false}
                                                                                                        className="form-control p-0 has-val"
                                                                                                        placeholder={`Enter time`}
                                                                                                        format={IISMethods.getTimeFormatByProperty()}
                                                                                                        id={"latecheckouttime"}
                                                                                                        onChange={(e) => {
                                                                                                            props.getLateCheckoutCharges(e)
                                                                                                        }}
                                                                                                        name={"latecheckouttime"}
                                                                                                        value={chat.request?.latecheckouttodate ? IISMethods.GetISOtoTZtime(chat.request?.latecheckouttodate) : getCurrentState().chatList?.lateCheckoutTime ? IISMethods.GetISOtoTZtime(getCurrentState().chatList?.lateCheckoutTime) : ''}
                                                                                                        ranges={[]}
                                                                                                        showMeridian
                                                                                                        disabled={chat?.isdisable}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='product-add-box-inner mt-2'>
                                                                                            <div className="col-12 px-0">
                                                                                                <div className="form-group validate-input">
                                                                                                    <label className='guest-request-maintenance-lable text-12'>Late Checkout Charges</label>
                                                                                                    <input
                                                                                                        id={"form-checkout-charges"}
                                                                                                        type="text"
                                                                                                        disabled={true}
                                                                                                        name={"form-checkout-charges"}
                                                                                                        className="form-control"
                                                                                                        placeholder={"Enter Charges"}
                                                                                                        value={chat.request?.latecheckoutguestcharges ? "$ " + chat.request?.latecheckoutguestcharges : getCurrentState().chatList?.lateCheckoutCharges?.charge ? "$ " + getCurrentState().chatList?.lateCheckoutCharges?.charge : ""}
                                                                                                        required={true}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        {guestSelfieImageForm(chat.request?.latecheckoutguestselfie, selfieImage, setSelfieImage, chat?.isdisable, "request-late-checkout-selfie")}
                                                                                    </div>
                                                                                }
                                                                                {
                                                                                    //checkout form JENIL 05/08/2023 1:15 PM
                                                                                    chat?.optionshowtype === 9 && chat?.optiontype === 9 &&
                                                                                    <div className='checkout-time-form extend-stay-add-form'>
                                                                                        <div className='product-add-box-inner'>
                                                                                            <div className="col-12 px-0">
                                                                                                <div className="form-group validate-input">
                                                                                                    <div className="btn-custom-control">
                                                                                                        <div className="gridBox chat-print-grid">
                                                                                                            {
                                                                                                                checkOutRadioOption?.map((data, index) => {
                                                                                                                    return (
                                                                                                                        <>
                                                                                                                            <div className="custom-control custom-radio checkout-img-box housekeeping-img-box p-0" key={data?.value}>
                                                                                                                                <input
                                                                                                                                    type="radio"
                                                                                                                                    id={`${index + i}checkoutoption`}
                                                                                                                                    name={"checkoutoption"}
                                                                                                                                    className="custom-control-input"
                                                                                                                                    checked={(chat?.request?.foliovalue ? chat?.request?.foliovalue : checkoutOption) === data?.value ? true : false}
                                                                                                                                    readOnly={chat?.isdisable}
                                                                                                                                />
                                                                                                                                <label
                                                                                                                                    className="custom-control-label mb-0 p-2"
                                                                                                                                    htmlFor={`${index + i}checkoutoption`}
                                                                                                                                    onClick={() => {
                                                                                                                                        if (!chat?.isdisable) {
                                                                                                                                            setCheckoutOption(data.value)
                                                                                                                                        }
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <img className='checkout-img housekeeping-img' src={data?.icon} />
                                                                                                                                    <div className="text-size">
                                                                                                                                        <p className="text-center">{data?.label}</p>
                                                                                                                                    </div>
                                                                                                                                </label>
                                                                                                                            </div>
                                                                                                                        </>
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        {
                                                                                            (checkoutOption === 1 || chat.request?.foliomail) &&
                                                                                            <div className='product-add-box-inner mt-2'>
                                                                                                <div className="col-12 px-0">
                                                                                                    <div className="form-group validate-input">
                                                                                                        <label className='guest-request-maintenance-lable text-12'>Email</label>
                                                                                                        <input
                                                                                                            id={"form-checkout-email"}
                                                                                                            type="text"
                                                                                                            disabled={chat?.isdisable}
                                                                                                            name={"form-checkout-email"}
                                                                                                            className="form-control"
                                                                                                            placeholder={"Enter email"}
                                                                                                            value={chat.request?.foliomail ? chat.request?.foliomail : checkoutEmail ? checkoutEmail : ""}
                                                                                                            onChange={(e) => setCheckoutEmail(e.target.value ? e.target.value : "")}
                                                                                                            required={true}
                                                                                                            readOnly={chat?.isdisable}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                }
                                                                                <div className='chat-time d-block'>{time}</div>
                                                                            </div>
                                                                    }
                                                                </div>
                                                            </Whisper>
                                                            {
                                                                //show menus in opened modal
                                                                chat.optionshowtype === 1 &&
                                                                <button className='bottom-menu-inner' onClick={(e) => {
                                                                    e.preventDefault()
                                                                    handleModalOpen('sm', chat.option, chat)
                                                                }}>
                                                                    <span className="material-icons-outlined">menu_open</span>
                                                                    <span className='bottom-menu-title'>Menu</span>
                                                                </button>
                                                            }
                                                            {
                                                                //(chat.optionshowtype === 2 || chat.optionshowtype === 6) && chat.optiontype === 1 yes or no options/ others options
                                                                ((chat.optionshowtype === 2 || chat.optionshowtype === 6) && chat.optiontype === 1) &&
                                                                <div className="select-radio">
                                                                    {
                                                                        chat?.option?.length > 0 && chat?.option?.map((opt, index) => {
                                                                            return (
                                                                                <>
                                                                                    <input
                                                                                        type="radio"
                                                                                        name={"radio" + i}
                                                                                        id={opt.answerid + i} checked={opt?.isselected === 1 ? true : false}
                                                                                        key={index.toString() + opt._id}
                                                                                        onChange={() => {
                                                                                            if (!chat?.isdisable) {
                                                                                                props.handleOptionSelect(setIsScroll, opt, chat)
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                    <label className={`chat-radio-btn ${chat?.isdisable ? "opacity-7" : ""}`} htmlFor={opt.answerid + i}>
                                                                                        {
                                                                                            //add loader in options JENIL 18/8/2023 2:06 PM
                                                                                            opt._id === props.optionIdLoad &&
                                                                                            <Loader />
                                                                                        }
                                                                                        {opt.option}
                                                                                    </label>
                                                                                </>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            }
                                                            {
                                                                //late checkout time range show
                                                                chat.optionshowtype === 2 && chat.optiontype === 2 &&
                                                                <div className="select-radio select-time-range">
                                                                    {
                                                                        chat?.option?.length > 0 && chat?.option?.map((opt, index) => {
                                                                            return (
                                                                                <>
                                                                                    <input
                                                                                        type="radio"
                                                                                        name={"radio" + i}
                                                                                        id={opt._id} checked={opt?.isselected === 1 ? true : false}
                                                                                        key={index.toString() + opt._id}
                                                                                        onChange={() => {
                                                                                            if (!chat?.isdisable) {
                                                                                                props.handleOptionSelect(setIsScroll, { ...opt, option: fromToTimeOptionShow(opt) }, chat, true)
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                    <label className={`chat-radio-btn ${chat?.isdisable ? "opacity-7" : ""}`} htmlFor={opt._id}>
                                                                                        {
                                                                                            //add loader in options JENIL 18/8/2023 2:06 PM
                                                                                            opt._id === props.optionIdLoad &&
                                                                                            <Loader />
                                                                                        }
                                                                                        {fromToTimeOptionShow(opt)}
                                                                                    </label>
                                                                                </>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            }
                                                            {
                                                                // chat.optionshowtype === 4 is canvas latecheckout signature or extendstay signature
                                                                chat.optionshowtype === 4 &&
                                                                <div className="select-radio">
                                                                    {
                                                                        chat?.option?.length > 0 && chat?.option?.map((opt, index) => {
                                                                            return (
                                                                                <>
                                                                                    <input
                                                                                        type="radio"
                                                                                        name={"radio" + i}
                                                                                        id={opt.answerid + i} checked={opt?.isselected === 1 ? true : false}
                                                                                        key={index.toString() + opt._id}
                                                                                        onChange={() => {
                                                                                            if (!chat?.isdisable) {
                                                                                                //if Submit then check sign is not empty and select option
                                                                                                if (opt.option === "Submit" || opt.option === "Approve") {
                                                                                                    if (!sigPad.isEmpty()) {
                                                                                                        props.handleOptionSelect(setIsScroll, opt, chat, false, sigPad)
                                                                                                    } else {
                                                                                                        IISMethods.notify("Please add your signature", 2)
                                                                                                    }
                                                                                                } else {
                                                                                                    //when decline signature message JENIL 1/9/2023 1:23 PM
                                                                                                    props.handleOptionSelect(setIsScroll, opt, chat, false)
                                                                                                }
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                    <label className={`chat-radio-btn ${chat?.isdisable ? "opacity-7" : ""}`} htmlFor={opt.answerid + i}>
                                                                                        {
                                                                                            //add loader in options JENIL 18/8/2023 2:06 PM
                                                                                            opt._id === props.optionIdLoad &&
                                                                                            <Loader />
                                                                                        }
                                                                                        {opt.option}
                                                                                    </label>
                                                                                </>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            }
                                                            {
                                                                //this is choose calendar submit button
                                                                chat.optionshowtype === 5 && chat.optiontype === 3 &&
                                                                <div className="select-radio">
                                                                    {
                                                                        chat?.option?.length > 0 && chat?.option?.map((opt, index) => {
                                                                            return (
                                                                                <>
                                                                                    <input
                                                                                        type="radio"
                                                                                        name={"radio" + i}
                                                                                        id={opt.answerid + i} checked={opt?.isselected === 1 ? true : false}
                                                                                        key={index.toString() + opt._id}
                                                                                        onChange={() => {
                                                                                            if (!chat?.isdisable) {
                                                                                                if (selfieImage) {
                                                                                                    props.handleOptionSelect(setIsScroll, { ...opt, option: IISMethods.GetDateFormat(date, 4), date: date, days: days, selfie: selfieImage }, chat)
                                                                                                } else {
                                                                                                    IISMethods.notify("Please add your selfie", 2)
                                                                                                }
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                    <label className={`chat-radio-btn ${chat?.isdisable ? "opacity-7" : ""}`} htmlFor={opt.answerid + i}>
                                                                                        {
                                                                                            //add loader in options JENIL 18/8/2023 2:06 PM
                                                                                            opt._id === props.optionIdLoad &&
                                                                                            <Loader />
                                                                                        }
                                                                                        {opt.option}
                                                                                    </label>
                                                                                </>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            }
                                                            {
                                                                //this is choose credit card scan button
                                                                chat.optionshowtype === 7 && chat.optiontype === 7 &&
                                                                <div className="select-radio">
                                                                    {
                                                                        chat?.option?.length > 0 && chat?.option?.map((opt, index) => {
                                                                            return (
                                                                                <>
                                                                                    <input
                                                                                        type="radio"
                                                                                        name={"radio" + i}
                                                                                        id={opt.answerid + i} checked={opt?.isselected === 1 ? true : false}
                                                                                        key={index.toString() + opt._id}
                                                                                        onChange={() => {
                                                                                            if (!chat?.isdisable) {
                                                                                                if (getCurrentState().formData?.cardnumber) {
                                                                                                    props.handleOptionSelect(setIsScroll, opt, chat)
                                                                                                } else {
                                                                                                    IISMethods.notify("Please scan your credit card", 2)
                                                                                                }
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                    <label className={`chat-radio-btn ${chat?.isdisable ? "opacity-7" : ""}`} htmlFor={opt.answerid + i}>
                                                                                        {
                                                                                            //add loader in options JENIL 18/8/2023 2:06 PM
                                                                                            opt._id === props.optionIdLoad &&
                                                                                            <Loader />
                                                                                        }
                                                                                        {opt.option}
                                                                                    </label>
                                                                                </>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            }
                                                            {
                                                                //this is choose latecheckout form JENIL 04/08/2023 4:21 PM
                                                                chat.optionshowtype === 8 && chat.optiontype === 8 &&
                                                                <div className="select-radio">
                                                                    {
                                                                        chat?.option?.length > 0 && chat?.option?.map((opt, index) => {
                                                                            return (
                                                                                <>
                                                                                    <input
                                                                                        type="radio"
                                                                                        name={"radio" + i}
                                                                                        id={opt.answerid + i} checked={opt?.isselected === 1 ? true : false}
                                                                                        key={index.toString() + opt._id}
                                                                                        onChange={() => {
                                                                                            if (!chat?.isdisable) {
                                                                                                if (selfieImage && getCurrentState().chatList?.lateCheckoutCharges?.charge) {
                                                                                                    props.handleOptionSelect(setIsScroll, { ...opt, selfie: selfieImage }, chat)
                                                                                                } else {
                                                                                                    if (!selfieImage && !getCurrentState().chatList?.lateCheckoutCharges?.charge) {
                                                                                                        IISMethods.notify("Please add late check-out time and self-photo", 2)
                                                                                                    } else if (!selfieImage) {
                                                                                                        IISMethods.notify("Please capture your photo", 2)
                                                                                                    } else {
                                                                                                        IISMethods.notify("Please add late check-out time", 2)
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                    <label className={`chat-radio-btn ${chat?.isdisable ? "opacity-7" : ""}`} htmlFor={opt.answerid + i}>
                                                                                        {
                                                                                            //add loader in options JENIL 18/8/2023 2:06 PM
                                                                                            opt._id === props.optionIdLoad &&
                                                                                            <Loader />
                                                                                        }
                                                                                        {opt.option}
                                                                                    </label>
                                                                                </>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            }
                                                            {
                                                                //this is choose checkout radio options for mail JENIL 05/08/2023 1:30 PM
                                                                chat.optionshowtype === 9 && chat.optiontype === 9 &&
                                                                <div className="select-radio">
                                                                    {
                                                                        chat?.option?.length > 0 && chat?.option?.map((opt, index) => {
                                                                            return (
                                                                                <>
                                                                                    <input
                                                                                        type="radio"
                                                                                        name={"radio" + i}
                                                                                        id={opt.answerid + i} checked={opt?.isselected === 1 ? true : false}
                                                                                        key={index.toString() + opt._id}
                                                                                        onChange={() => {
                                                                                            if (!chat?.isdisable) {
                                                                                                if ((checkoutOption === 1 && checkoutEmail?.trim() !== "") || [2, 3].includes(checkoutOption)) {
                                                                                                    props.handleOptionSelect(setIsScroll,
                                                                                                        { ...opt, answerid: checkOutRadioOption.find(a => a.value === checkoutOption).answerid, foliomail: checkoutEmail, foliovalue: checkoutOption }, chat)
                                                                                                } else {
                                                                                                    IISMethods.notify("Please enter your email", 2)
                                                                                                }
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                    <label className={`chat-radio-btn ${chat?.isdisable ? "opacity-7" : ""}`} htmlFor={opt.answerid + i}>
                                                                                        {
                                                                                            //add loader in options JENIL 18/8/2023 2:06 PM
                                                                                            opt._id === props.optionIdLoad &&
                                                                                            <Loader />
                                                                                        }
                                                                                        {opt.option}
                                                                                    </label>
                                                                                </>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    chat?.files?.length > 0 &&
                                                    <div className='w-90'>
                                                        <Whisper
                                                            placement="topStart"
                                                            trigger={"contextMenu"}
                                                            speaker={
                                                                <Tooltip className="show-reaction-emojis-tooltip">
                                                                    {reactionEmoji(chat)}
                                                                </Tooltip>
                                                            }
                                                            enterable={true}
                                                        >
                                                            <div className='new-block-container corner-border right'>
                                                                {
                                                                    chat.message &&
                                                                    <div className={`new-block-chat chat-text chat-file-content ${(currentPerson !== previousPerson || currentDate !== previousDate) ? "corner-border" : ""} ${chat.isimportant === 1 ? 'chat-msgtype-important important' : chat.isurgent === 1 ? 'chat-msgtype-urgent urgent' : ''}`}>
                                                                        {messageTypeLabel(chat)}
                                                                        <div className='position-realtive d-flex justify-content-between mb-2'>
                                                                            {
                                                                                (startMsg !== "" && endMsg !== "") ?
                                                                                    <span className='d-block message-wrap'>
                                                                                        {startMsg}
                                                                                        <span className='hidden-message' id={id + "Overflow"}>
                                                                                            {endMsg}
                                                                                        </span>
                                                                                        <Link id={id + "MoreLink"} to="#" onClick={() => handleShowMore(id)}>
                                                                                            Read More...
                                                                                        </Link>
                                                                                        <Link className="hidden-message" to="#" id={id + "LessLink"} onClick={() => handleShowLess(id)}>
                                                                                            &nbsp;Read Less
                                                                                        </Link>
                                                                                    </span>
                                                                                    :
                                                                                    <span className='d-block message-wrap'>
                                                                                        {chat.message}
                                                                                    </span>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    <div className='new-block-file'>
                                                                        {
                                                                            chat?.files?.length > 0 &&
                                                                            chat?.files?.map((file) => {
                                                                                return (
                                                                                    file.type === 4 ?
                                                                                        <div className='chat-file'>
                                                                                            <img src={pdfImg} alt='file' />
                                                                                            {
                                                                                                //if reg card sent then open modal to do signature on it JENIL 14/8/2023 9:35 AM
                                                                                                chat.isregcard === 1 ?
                                                                                                    <span className='d-block text-12 text-dark' onClick={() => props.handleGrid('modal', 'renderPdf', true, file, chat)}>{file.name || "File Name"}</span>
                                                                                                    :
                                                                                                    <a className='d-block text-12 text-dark' href={file.url} download target="_blank">{file.name || "File Name"}</a>
                                                                                            }
                                                                                            {/* <a className='d-block text-12 text-dark' href={file.url} download target="_blank">{file.name || "File Name"}</a> */}
                                                                                        </div>
                                                                                        :
                                                                                        file.type === 3 ?
                                                                                            <div className='chat-file'>
                                                                                                <audio controls>
                                                                                                    <source src={file.url} type="audio/mpeg" />
                                                                                                </audio>
                                                                                            </div>
                                                                                            :
                                                                                            file.type === 2 ?
                                                                                                <div className="chat-img">
                                                                                                    <video width="250" height="250" controls src={file.url} />
                                                                                                </div>
                                                                                                :
                                                                                                <div className="chat-img" onClick={() => props.handleGrid('modal', 'allMedia', true, file)}>
                                                                                                    <img src={file.url} alt='image upload' />
                                                                                                </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                }
                                                                <div className='chat-time d-block'>
                                                                    {time}
                                                                </div>
                                                            </div>
                                                        </Whisper>
                                                    </div>
                                                }
                                                {
                                                    showReactions?.length > 0 &&
                                                    <div className='chat-message-reactions d-flex gap-1'>
                                                        {showReactions?.map((rct, index) => {
                                                            return (
                                                                <div className='react-emojis' key={index.toString() + "reactemoji"}>
                                                                    <Whisper
                                                                        placement="bottom"
                                                                        speaker={
                                                                            <Tooltip className="show-reaction-username-tooltip">
                                                                                <div className='reaction-user-name'>
                                                                                    {rct.reactionbyuid.map(name => {
                                                                                        return <span key={name + "emoji-user-name"}>{name}</span>
                                                                                    })}
                                                                                </div>
                                                                            </Tooltip>
                                                                        }
                                                                        enterable={true}>
                                                                        <span className='react-emojis-block' onClick={() => props.handleReactionEmojiSelect({ native: rct.reaction, id: rct.id }, chat, setIsScroll)}>{rct.reaction}<p className='reaction-emoji-count'>{rct.count}</p></span>
                                                                    </Whisper>
                                                                </div>
                                                            )
                                                        })
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            {/* {
                                showEmojiPicker &&
                                <div className='emoji-contener'>
                                    <Picker
                                        data={data}
                                        onEmojiSelect={(value) => props.handleEmojiSelect(value, setShowEmojiPicker)}
                                        previewPosition="none"
                                        emojiSize={18}
                                        onClickOutside={() => setShowEmojiPicker(false)}
                                    />
                                </div>
                            } */}
                            <div className='chat-bottom-msg row'>
                                {
                                    pagename === "chatwithus" && !getCurrentState()?.reservationDetails?.isguestcheckout &&
                                    <div className='chat-bot-bottom-button'>
                                        <div className='chat-bot-bottom-button-inner'>
                                            {
                                                showBotCustomMessages?.map((msg, index) => {
                                                    return (
                                                        <button key={index.toString() + msg.message} onClick={(e) => {
                                                            e.preventDefault();
                                                            if (!props.loader) {
                                                                props.handleSendMessage(setIsScroll, true, msg);
                                                                setAddclass(false)
                                                            }
                                                        }}>
                                                            {msg.message}
                                                        </button>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                                {
                                    getCurrentState()?.formData?.files?.length > 0 &&
                                    <div className='row chat-file-grid'>
                                        {
                                            getCurrentState()?.formData?.files?.map((file, i) => {
                                                let type = "." + file.name.split(".").pop();
                                                return (
                                                    <div className='col-auto' key={i.toString() + "filebase64preview"}>
                                                        <div className='chat-img-block'>
                                                            <i className="material-icons cancel-btn" onClick={() => props.handleRemoveFile(i)}>close</i>
                                                            {
                                                                allowdedDocs.includes(type) ?
                                                                    <img src={pdfImg} alt='file' />
                                                                    :
                                                                    allowdedAudio.includes(type) ?
                                                                        <audio>
                                                                            <source src={file.url} type="audio/mpeg" />
                                                                        </audio>
                                                                        :
                                                                        allowdedVideo.includes(type) ?
                                                                            <video width="50" height="50" src={file.url} />
                                                                            :
                                                                            <img src={file?.url} alt='image-upload' />
                                                            }
                                                            <span>{file?.name}</span>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                }
                                {
                                    messagetype.filter(item => getCurrentState().formData[item.id] === 1 && item.id !== "isstandard").map((item, i) => {
                                        return (
                                            <div className={`chat-tag ${item.text.toLowerCase()}`} key={i.toString() + "messagetype"}>
                                                <p className='important-text'>{item.text}</p>
                                                <p className='important-close-icon' onClick={() => { props.handleFormData(item.id, "dropdown", 0, item.deselect); setAddclass(false) }}><i className="material-icons">close</i></p>
                                            </div>
                                        )
                                    })
                                }
                                <Dropdown renderToggle={renderIconButton} className='chat-drop-down' placement="topStart" open={dropdownOpen} ref={menuRef}>
                                    {
                                        messagetype.map((item, i) => {
                                            let checked = getCurrentState().formData[item.id] === 1 ? true : false
                                            if (!getCurrentState().formData["isurgent"] && !getCurrentState().formData["isimportant"] && item.id === "isstandard") {
                                                checked = true
                                            }
                                            return (
                                                <Dropdown.Item key={i.toString() + "guestchatdropdown"} onClick={() => { props.handleFormData(item.id, "dropdown", 1, item.deselect); setDropdownOpen(false); setAddclass(true) }} className={`${item.className}`}>
                                                    <div className='drop-down-conntents'>
                                                        <div className='drop-down-conntents-inners'>
                                                            <div className='drop-icons'>
                                                                {item.icon}
                                                            </div>
                                                            <div>
                                                                <span className='title d-block'>{item.text}</span>
                                                                <span className='text d-block'>{item.desc}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        checked &&
                                                        <span className="material-icons-round checked-icon">
                                                            done
                                                        </span>
                                                    }
                                                </Dropdown.Item>
                                            )
                                        })
                                    }
                                </Dropdown>
                                {
                                    RightSidebarFormData?.map((fields, i) => {
                                        return (
                                            <div key={i.toString() + "guestchatrightsidebarformdata"}>
                                                {renderHtmlControls(fields)}
                                            </div>
                                        )
                                    })
                                }
                                {/* show emoji picker */}
                                {/* <span className='chat-bottom-attachment emoji-icon'>
                                    <i className="material-icons-outlined" onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
                                        mood
                                    </i>
                                </span> */}
                                <span className='chat-bottom-send' onClick={(e) => {
                                    e.preventDefault();
                                    if (!props.loader) {
                                        props.handleSendMessage(setIsScroll);
                                        setAddclass(false)
                                    }
                                }}>
                                    <i className="material-icons-outlined">
                                        {
                                            props.loader ?
                                                <Loader size='sm' />
                                                :
                                                "send"
                                        }
                                    </i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*comment by JENIL 03/08/2023 4:15 PM */}
            {/* <Footer /> */}
            {
                getCurrentState().modal["allMedia"] &&
                <ModalRsuite
                    size={'sm'}
                    open={getCurrentState().modal['allMedia']}
                    onClose={() => props.handleGrid('modal', 'allMedia', false)}
                    title={""}
                    body={
                        // <div className="col-12">
                        //     <div className="row">
                        <>
                            {
                                getCurrentState()?.chatList?.singleFile ?
                                    <div className="col-12 guest-chat-single-mode">
                                        <span className='chat-profile-back' onClick={() => props.handleFileClickFromGallery({}, false)}>
                                            <span className="material-icons-outlined">
                                                keyboard_backspace
                                            </span>
                                        </span>

                                        {
                                            getCurrentState().filterData.fileType === 1 ?
                                                <div className='single-image'>
                                                    <img className='d-block w-100' src={getCurrentState()?.chatList?.singleFile?.files?.url} alt="img" />
                                                </div>
                                                :
                                                getCurrentState().filterData.fileType === 2 ?
                                                    <div className='single-video'>
                                                        <video className='d-block w-100' controls src={getCurrentState()?.chatList?.singleFile?.files?.url} autoPlay />
                                                    </div>
                                                    :
                                                    getCurrentState().filterData.fileType === 3 ?
                                                        <div className='single-audio'>
                                                            <audio controls className='d-block w-100'>
                                                                <source src={getCurrentState()?.chatList?.singleFile?.files?.url} type="audio/mpeg" className='d-block w-100 h-100' />
                                                            </audio>
                                                        </div>
                                                        :
                                                        ""
                                        }

                                    </div>
                                    :
                                    <div className="col-12 guest-chat-gallery-mode">
                                        <div className="col-12 animated-underline-content px-0 fixed-tab-bar" id="tabContentReservation">
                                            <ul className="nav nav-tabs main-tabs scrolling-tabs" id="myTab1" role="tablist">
                                                {
                                                    allMediaTabs.map((item, index) => {
                                                        return (
                                                            <li className="nav-item" key={index.toString() + item.name}>
                                                                <Link to="#" onClick={() => props.handleTabChange(item)} className={"nav-link nav-tab nav-link-tab nav-" + index + (((getCurrentState().filterData.fileType || 1) === item.type) ? " active" : "")}>
                                                                    {item.name}
                                                                </Link>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                        {
                                            props.fileLoader ?
                                                <Placeholder.Graph active height={380} />
                                                :
                                                getCurrentState()?.chatList?.allChatFiles?.length > 0 ?
                                                    <div className="gallery-contents pt-3" onScroll={(e) => props.handleFileScroll(e)}>
                                                        <div className={[3, 4].includes(getCurrentState().filterData.fileType) ? "guest-chat-doc-audio-view" : "guest-chat-gallery-view"}>
                                                            {
                                                                getCurrentState()?.chatList?.allChatFiles?.map((file, index) => {
                                                                    return (
                                                                        getCurrentState().filterData.fileType === 1 ?
                                                                            <div className="image-3x3" key={file.files._id.toString() + index} onClick={() => props.handleFileClickFromGallery(file, true)}>
                                                                                <img src={file.files.url} alt="img" className='d-block w-100 h-100' />
                                                                            </div>
                                                                            :
                                                                            getCurrentState().filterData.fileType === 2 ?
                                                                                <div className="video-3x3" key={file.files._id.toString() + index} onClick={() => props.handleFileClickFromGallery(file, true)}>
                                                                                    <video className='d-block w-100 h-100' controls src={file.files.url} autoPlay />
                                                                                    <div className='text-center'>
                                                                                        <span className='uploadedby-label'>{file.files.name}</span>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                getCurrentState().filterData.fileType === 3 ?
                                                                                    <div className="audio-3x3" key={file.files._id.toString() + index}
                                                                                    // onClick={() => props.handleFileClickFromGallery(file, true)}
                                                                                    >
                                                                                        <audio controls>
                                                                                            <source src={file.files.url} type="audio/mpeg" className='d-block w-auto h-100' />
                                                                                        </audio>
                                                                                    </div>
                                                                                    :
                                                                                    getCurrentState().filterData.fileType === 4 ?
                                                                                        <div className="docs-3x3" key={file.files._id.toString() + index}
                                                                                        // onClick={() => props.handleFileClickFromGallery(file, true)}
                                                                                        >
                                                                                            <img src={pdfImg} alt='file' className='d-block w-auto h-100' />

                                                                                            <a className='d-block uploadedby-label' href={file.files.url} download target="_blank">{file.files.name || "File Name"}</a>

                                                                                        </div>
                                                                                        :
                                                                                        ""
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="right-sidebar-content mx-auto">
                                                        <div className="d-flex justify-content-center align-items-center mb-4 no-data-imgs-content">
                                                            <img src={NoDataImage} className='no-data-imgs' />
                                                        </div>
                                                        <h1 className="no-result-label">No Data Found</h1>
                                                    </div>
                                        }
                                    </div>
                            }
                        </>
                        //     </div>
                        // </div>
                    }
                />
            }
            {
                getCurrentState().modal["renderPdf"] &&
                <ModalRsuite
                    size={'sm'}
                    open={getCurrentState().modal['renderPdf']}
                    onClose={() => {
                        setIsDraw(false);
                        setIsPdfLoad(true);
                        props.handleGrid('modal', 'renderPdf', false)
                    }}
                    className="pdf-modal"
                    title={
                        <div className="model-header icon">
                            <span className="closeicon">
                                Reg. Card
                            </span>
                        </div>}
                    body={
                        <>
                            {
                                props.renderPdf &&
                                <div className='render-regcard-pdf'>
                                    {/* <div className='col-12 sticky-btn' id="pdf-upper-button">
                                        <div className='d-flex gap-10 justify-between'> */}
                                    {/* <div className='drawimage-buttons d-flex gap-10'>
                                                <button className='btn btn-primary btn-org d-flex' onClick={() => setIsDraw(true)}>
                                                    <span class="material-icons-outlined text-18">draw</span>
                                                    <span>Draw</span>
                                                </button>
                                                <button id="done-signature-button" className='btn btn-primary btn-blue d-flex'
                                                    onClick={async () => {
                                                        if (signatureURL) {
                                                            await saveSignaturePdf()
                                                        }
                                                        setIsDraw(false)
                                                    }}
                                                >
                                                    Save
                                                </button>
                                            </div> */}
                                    {/* <div className='pdf-pagination d-flex gap-10 align-items-center'>
                                                <button
                                                    onClick={() => {
                                                        setIsPdfLoad(true);
                                                        setPageNum(pageNum - 1)
                                                        //on button click save sign pdf JENIL 19/8/2023 12:11 PM
                                                        saveSignaturePdf()
                                                    }}
                                                    disabled={pageNum - 1 === -1}
                                                    className='btn-arrow'
                                                >
                                                    <span class="material-icons-outlined">navigate_before</span>
                                                    <span>Previous Page</span>
                                                </button> */}
                                    {/* <div className='pdf-page-count'>
                                                    {pageNum + 1}/{totalPages}
                                                </div> */}
                                    {/* <button
                                                    onClick={() => {
                                                        setIsPdfLoad(true);
                                                        setPageNum(pageNum + 1);
                                                        //on button click save sign pdf JENIL 19/8/2023 12:11 PM
                                                        saveSignaturePdf()
                                                    }}
                                                    disabled={pageNum + 1 > totalPages - 1}
                                                    className='btn-arrow'
                                                >
                                                    <span class="material-icons-outlined">keyboard_arrow_right</span>
                                                    <span>Next Page</span>
                                                </button>
                                            </div> */}
                                    {/* </div>
                                    </div> */}
                                    {
                                        !(pageNum - 1 === -1) &&
                                        <div className='pdf-previous-button'>
                                            <button
                                                onClick={async () => {
                                                    //on button click save sign pdf JENIL 19/8/2023 12:11 PM
                                                    if (signatureURL) {
                                                        await saveSignaturePdf()
                                                    }
                                                    zoomRef.current.resetTransform()
                                                    setIsDraw(false)
                                                    setIsPdfLoad(true);
                                                    setPageNum(pageNum - 1)
                                                }}
                                                disabled={pageNum - 1 === -1}
                                                className='btn-arrow'
                                            >
                                                <span class="material-icons-outlined">navigate_before</span>
                                            </button>
                                        </div>
                                    }
                                    {
                                        isPdfLoad &&
                                        <div className='pdf-loader'>
                                            <Loader size='sm' content="Loading" />
                                        </div>
                                    }
                                    <div className='regcard-pdf-drawimage-buttons'>
                                        <div className='regcard-render-signature-canvas'>
                                            {
                                                isDraw &&
                                                <div className='render-signature-canvas'>
                                                    <SignaturePad
                                                        ref={signatureRef}
                                                        canvasProps={{
                                                            width: widthSign,
                                                            height: heightSign,
                                                            className: "signature-canvas-container"
                                                        }}
                                                        onEnd={onSignEnd}
                                                    />
                                                </div>
                                            }
                                            <TransformWrapper ref={zoomRef} disabled={isDraw ? true : false}>
                                                <TransformComponent>
                                                    <Document
                                                        file={props.renderPdf}
                                                        onLoadSuccess={(data) => {
                                                            setTotalPages(data.numPages);
                                                        }}
                                                        loading=""
                                                    >
                                                        <Page
                                                            pageNumber={pageNum + 1}
                                                            onLoadSuccess={(data) => {
                                                                setPageDetails(data);
                                                                setIsPdfLoad(false);
                                                            }}
                                                            className={"pdf-page"}
                                                            loading=""
                                                            renderTextLayer={false}
                                                            renderAnnotationLayer={false}
                                                        />
                                                    </Document>
                                                </TransformComponent>
                                            </TransformWrapper>
                                        </div>
                                    </div>
                                    {
                                        !(pageNum + 1 > totalPages - 1) &&
                                        <div className='pdf-next-button'>
                                            <button
                                                onClick={async () => {
                                                    //on button click save sign pdf JENIL 19/8/2023 12:11 PM
                                                    if (signatureURL) {
                                                        await saveSignaturePdf()
                                                    }
                                                    zoomRef.current.resetTransform()
                                                    setIsDraw(false)
                                                    setIsPdfLoad(true);
                                                    setPageNum(pageNum + 1);
                                                }}
                                                disabled={pageNum + 1 > totalPages - 1}
                                                className='btn-arrow'
                                            >
                                                <span class="material-icons-outlined">keyboard_arrow_right</span>
                                            </button>
                                        </div>
                                    }
                                </div>
                            }
                        </>
                    }
                    footer={
                        <div className='col-12'>
                            <div className='d-flex gap-10 pb-3'>
                                {
                                    isDraw ?
                                        <>
                                            <button
                                                onClick={() => {
                                                    props.setRenderPdf(getCurrentState().chatList?.singleFile?.url)
                                                    if (isDraw) {
                                                        signatureRef.current.clear()
                                                    }
                                                    setSignatureURL("")
                                                }}
                                                className="btn pdf-btn pdf-clear d-flex align-items-center justify-content-center"
                                            >
                                                {/* <span class="material-icons-outlined text-18">brush</span> */}
                                                <span class="material-icons-outlined text-18">close</span>
                                                <span>Clear</span>
                                            </button>
                                            <button
                                                onClick={async (e) => {
                                                    e.preventDefault();
                                                    if (signatureURL) {
                                                        await saveSignaturePdf(true)
                                                    } else {
                                                        await props.handleSendMessage(setIsScroll, false, "", false, {}, getCurrentState()?.chatList?.singleChat, false, props.renderPdf)
                                                    }
                                                }}
                                                className="btn pdf-btn pdf-submit d-flex align-items-center justify-content-center"
                                            >
                                                {
                                                    props.loader ?
                                                        <Loader size="sm" content={"Submit"} />
                                                        :
                                                        "Submit"
                                                }
                                            </button>
                                        </>
                                        :
                                        <button className={`btn pdf-btn pdf-draw ${isDraw ? "btn-org-outline" : ""} d-flex align-items-center justify-content-center`} onClick={async () => {
                                            setIsDraw(!isDraw)
                                            if (signatureURL) {
                                                await saveSignaturePdf()
                                            }
                                        }}>
                                            <span class="material-icons-outlined text-18">draw</span>
                                            <span>Draw Signature</span>
                                        </button>
                                }
                            </div>
                        </div>
                    }
                />
            }
        </>
    )
}

export default ChatUsView
