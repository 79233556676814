import React from 'react'
import { Config, IISMethods, JsCall } from '../config/IISMethods'
import { store } from '..'
import ModalRsuite from './Components/ModalRsuite'
import { Carousel } from 'react-bootstrap'
import Information from '../assets/images/information.svg'


const AmenitiesRightSidebar = (props) => {

    const getCurrentState = () => {
        return store.getState()['root']
    }

    const rendertimings = (timings = []) => {
        console.log('timings', timings)
        let currenttime = (IISMethods.GetDT().getHours() * 60 * 60) + (IISMethods.GetDT().getMinutes() * 60) + (IISMethods.GetDT().getSeconds())
        let a
        let currentdaytime
        if (timings.length > 1) {
            a = timings.find(time => {
                console.log('time', time)
                let fromtime = (new Date(time.fromtime).getHours() * 60 * 60) + (new Date(time.fromtime).getMinutes() * 60) + (new Date(time.fromtime).getSeconds())
                let totime = (new Date(time.totime).getHours() * 60 * 60) + (new Date(time.totime).getMinutes() * 60) + (new Date(time.totime).getSeconds())
                currentdaytime = fromtime >= currenttime || totime >= currenttime
                return currentdaytime
            })
        }
        else {
            // a = timings[0]
            a = timings
        }
        if (a) {
            return (
                <>
                    <span className='text-12 d-block text-bold w-80'>{a.timingname} : {IISMethods.GetTimeFormat(a.fromtime)} To {IISMethods.GetTimeFormat(a.totime)}</span>
                </>
            )
        }
        else {
            let fromtimearray = []
            timings.filter(time => {
                let fromtime = new Date(time.fromtime).getTime()
                fromtimearray.push(fromtime)
                if (fromtimearray.sort()[0] === fromtime) {
                    currentdaytime = time
                    return currentdaytime
                }
            })
            return (
                <>
                    <span className='text-12 d-block text-bold w-80'>{currentdaytime?.timingname} : {IISMethods.GetTimeFormat(currentdaytime?.fromtime)} To {IISMethods.GetTimeFormat(currentdaytime?.totime)}</span>
                </>
            )
        }
    }

    return (
        <>
            {
                getCurrentState().modal["amenities"]
                &&
                <ModalRsuite
                    size={"xs"}
                    open={getCurrentState().modal['amenities']}
                    onClose={() => props.handleGrid(false, 'modal', 'amenities', 0)}

                    title={
                        <div className="model-header icon">
                            <span className="closeicon">
                                {
                                    getCurrentState().formData.modalicon
                                    &&
                                    <img src={getCurrentState().formData.modalicon} height='25px' width='30px' />
                                }
                                <span className='ml-1'>
                                    {getCurrentState().formData.name}
                                </span>
                            </span>
                        </div>
                    }
                    body={
                        <div className="col-12">
                            <div className='d-flex flex-wrap justify-content-between mb-3'>
                                {
                                    getCurrentState().formData.timing?.length > 0
                                    &&
                                    <div className='d-flex gap-10 mb-2 w-100'>
                                        <>
                                            <span className='text-14 font-weight-400 d-block w-20'>Hours</span>
                                            {/* {getCurrentState().formData.timing?.length > 1 && */}
                                                {/* // <div className='info-amenities' onClick={() => props.handleGrid(true, 'modal', 'infomodal', 1)}>
                                                //     <span className="material-icons-outlined">
                                                //         visibility
                                                //     </span>
                                                // </div> */}
                                            {/* } */}
                                        </>
                                        {/* {rendertimings(getCurrentState().formData.timing)} */}
                                        {
                                            // jatin 09-08-2023
                                            <div className='w-80'>
                                            {
                                            getCurrentState().formData.timing.map((a)=>{
                                                return(
                                                    
                                                    <div className='text-14 d-block font-weight-600 w-100'>
                                                        <span>{a.timingname} : </span> 
                                                        <span>{IISMethods.GetTimeFormat(a.fromtime)} To {IISMethods.GetTimeFormat(a.totime)}</span>
                                                    </div>
                                                   
                                                    
                                                )
                                            })
                                            }
                                            </div>
                                        }
                                    </div>
                                }
                                <div className='d-flex gap-10 w-100'>
                                    <span className='text-14  font-weight-400 d-block w-20'>Location</span>
                                    {getCurrentState().formData.location ? <span className='text-14 d-block my-auto font-weight-600 w-80'>{getCurrentState().formData.location}</span> : <></>}
                                </div>
                            </div>

                            {getCurrentState().formData.photos?.length > 0 &&
                                <div className='mb-3'>
                                    <span className='d-block text-format mb-2'>Photos</span>
                                    <div className='overflow-auto' onClick={() => props.handleGrid(getCurrentState().formData.photos, 'modal', 'multipalimagemodal', 1)}>
                                        <div className='img-scroll'>
                                            {getCurrentState().formData.photos?.map((item) => {
                                                return <img className='amenities-img' src={item.file} />
                                            })}
                                        </div>
                                    </div>
                                </div>
                            }
                            {(getCurrentState().formData.mapicons?.length === 0 || getCurrentState().formData.mapicons === undefined) ? '' :
                                <>
                                    <span className='d-block text-format mb-2'>Map</span>
                                    <div className='row'>
                                        <div className='col-12 text-center'>
                                            {
                                                getCurrentState().formData.mapicons?.length > 1
                                                ?
                                                <Carousel className="custom-slider" interval={6000} fade={true}>
                                                    {getCurrentState().formData.mapicons?.map((item) => {
                                                        return (
                                                            <Carousel.Item>
                                                                <div className="multiple-image-slider">
                                                                    <img className='' src={item?.url} />
                                                                </div>
                                                            </Carousel.Item>
                                                        )
                                                    })}
                                                </Carousel>
                                                :
                                                <img className='map-img' src={getCurrentState().formData.mapicons?.length && getCurrentState().formData.mapicons[0]?.url} />
                                            }
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    }
                />
            }

            {
                getCurrentState().modal["multipalimagemodal"]
                &&
                <ModalRsuite
                    size={"xs"}
                    open={getCurrentState().modal['multipalimagemodal']}
                    onClose={() => props.handleGrid(false, 'modal', 'multipalimagemodal', 0)}

                    title={
                        <div className="model-header icon">
                            <span className="closeicon">
                                Photos
                            </span>
                        </div>
                    }
                    body={
                        <>
                            {
                                getCurrentState().formData.photos?.length > 1
                                ?
                                <div className="col-12">
                                    <Carousel className="custom-slider" interval={6000} fade={true}>
                                        {getCurrentState().formData.photos?.map((item) => {
                                            return (
                                                <Carousel.Item>
                                                    <div className="multiple-image-slider">
                                                        <img className='' src={item.file} />
                                                    </div>
                                                </Carousel.Item>
                                            )
                                        })}
                                    </Carousel>
                                </div>
                                :
                                <div className="col-12">
                                    <div className="single-image">
                                        <img className='' src={getCurrentState().formData.photos[0].file} />
                                    </div>
                                </div>
                            }
                        </>
                    }
                />
            }
            {
                getCurrentState().modal["infomodal"]
                &&
                <ModalRsuite
                    size={"xs"}
                    open={getCurrentState().modal['infomodal']}
                    onClose={() => props.handleGrid(false, 'modal', 'infomodal', 0)}
                    title={
                        <div className="model-header icon">
                            <span className="closeicon">
                                Timing
                            </span>
                        </div>
                    }
                    body={
                        <div className="col-12 ">
                            <div className='project-table table-responsive time-table-modal'>
                                <table className='table mb-0'>
                                    <thead>
                                        <tr>
                                            <th>Timing Name</th>
                                            <th>From Time</th>
                                            <th>To Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {getCurrentState().formData.timing?.map((item) => {
                                            return (
                                                <tr>
                                                    <td>{item.timingname}</td>
                                                    <td>{IISMethods.GetTimeFormat(item.fromtime)}</td>
                                                    <td>{IISMethods.GetTimeFormat(item.totime)}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                />
            }



        </>
    )
}

export default AmenitiesRightSidebar