import React, { useState } from 'react'
import { store } from '..'
import Nextarrows from '../assets/images/Group 28993.png'
import BackHeader from '../BackHeader'
import Footer from '../Footer'
import { useLocation, useNavigate } from 'react-router-dom'
import NoDataImage from '../assets/images/no-data-img.png'
import placeholderImg from '../assets/images/placeholderImgNotify.png'
import { Placeholder } from 'rsuite'

const NearByTypeView = (props) => {

    const location = useLocation()

    const getCurrentState = () => {
        return store.getState()['root']
    }

    try {
        return (
            <>
                <BackHeader linkto={props.pagename === 'nearbytype' ? '/nearby' : location.pathname} backtopagename={getCurrentState().formData.location} onClick={() => props.setPageName('nearby')} handleFilterData={props.handleFilterData} handleApplyFilterButtonClick={props.handleApplyFilterButtonClick} handleSerachClose={props.handleSerachClose} />
                <div className="body-content notify-body-content" onScroll={(e) => props.handleScroll(e, 'nearbytype')} >
                    <div className="col-12">
                        <div className='row'>
                            <div className='col-12'>
                                {
                                    (getCurrentState().formName === '')
                                        ?
                                        <>
                                            <Placeholder.Graph active className='notify-inner mt-3 mb-0'>
                                                <div className='notify-graph'>
                                                    <div className='notify-img'>
                                                        <img graph="image" src={placeholderImg} className='placeholder-img' />
                                                    </div>
                                                    <div className='notify-graph-content'>
                                                        <p className='graph'></p>
                                                        <p className='graph'></p>
                                                        <p className='graph'></p>
                                                    </div>
                                                </div>
                                            </Placeholder.Graph>
                                            <Placeholder.Graph active className='notify-inner mt-3 mb-0'>
                                                <div className='notify-graph'>
                                                    <div className='notify-img'>
                                                        <img graph="image" src={placeholderImg} className='placeholder-img' />
                                                    </div>
                                                    <div className='notify-graph-content'>
                                                        <p className='graph'></p>
                                                        <p className='graph'></p>
                                                        <p className='graph'></p>
                                                    </div>
                                                </div>
                                            </Placeholder.Graph>
                                            <Placeholder.Graph active className='notify-inner mt-3 mb-0'>
                                                <div className='notify-graph'>
                                                    <div className='notify-img'>
                                                        <img graph="image" src={placeholderImg} className='placeholder-img' />
                                                    </div>
                                                    <div className='notify-graph-content'>
                                                        <p className='graph'></p>
                                                        <p className='graph'></p>
                                                        <p className='graph'></p>
                                                    </div>
                                                </div>
                                            </Placeholder.Graph>
                                            <Placeholder.Graph active className='notify-inner mt-3 mb-0'>
                                                <div className='notify-graph'>
                                                    <div className='notify-img'>
                                                        <img graph="image" src={placeholderImg} className='placeholder-img' />
                                                    </div>
                                                    <div className='notify-graph-content'>
                                                        <p className='graph'></p>
                                                        <p className='graph'></p>
                                                        <p className='graph'></p>
                                                    </div>
                                                </div>
                                            </Placeholder.Graph>
                                            <Placeholder.Graph active className='notify-inner mt-3 mb-0'>
                                                <div className='notify-graph'>
                                                    <div className='notify-img'>
                                                        <img graph="image" src={placeholderImg} className='placeholder-img' />
                                                    </div>
                                                    <div className='notify-graph-content'>
                                                        <p className='graph'></p>
                                                        <p className='graph'></p>
                                                        <p className='graph'></p>
                                                    </div>
                                                </div>
                                            </Placeholder.Graph>
                                            <Placeholder.Graph active className='notify-inner mt-3 mb-0'>
                                                <div className='notify-graph'>
                                                    <div className='notify-img'>
                                                        <img graph="image" src={placeholderImg} className='placeholder-img' />
                                                    </div>
                                                    <div className='notify-graph-content'>
                                                        <p className='graph'></p>
                                                        <p className='graph'></p>
                                                        <p className='graph'></p>
                                                    </div>
                                                </div>
                                            </Placeholder.Graph>
                                        </>
                                        :
                                        ((getCurrentState().formName?.length > 0) && (getCurrentState().masterDataList['nearbytype']?.length === 0)) ?
                                            <div className='col-12'>
                                                <div className='no-data-found'>
                                                    <div className='noData-content'>
                                                        <img src={NoDataImage} />
                                                        <h2 className="text-center text-16 mb-0 font-weight-600">No nearby locations found</h2>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            (getCurrentState().masterDataList['nearbytype']?.length > 0) && getCurrentState().masterDataList['nearbytype'].map((data, index) => {
                                                return (
                                                    <div className='content' key={index}>
                                                        <div className={`${data.locationcount > 0 ? 'cursor-pointer' : ''}`}
                                                            onClick={() => {
                                                                props.handleFormData('dropdown', "locationtypeid", data._id)
                                                            }
                                                            }>
                                                            <div className='d-flex nearbyContent justify-content-between align-items-center'>
                                                                <div className="">
                                                                    {(data.locationtype !== '' || data.locationtype !== undefined) && <> <span className='maintext d-block nearbyContentTitle'>{data?.locationtype}</span></>}
                                                                    {(data.locationcount !== '' || data.locationcount !== undefined) && <span className='subtext d-block nearbyContentText'>Found {data?.locationcount} places near by you</span>}{/* charmi(10-08-2023 04:38) */}
                                                                </div>
                                                                {data?.locationcount > 0 &&
                                                                    <div className=''>
                                                                        <img className='arrow-img' src={Nextarrows} />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                }
                            </div>

                            {/* </div>  */}
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    } catch (error) {
        console.log(error)
    }

}

export default NearByTypeView

