import React, {useEffect} from "react";

function useAddToHomescreenPrompt() {
    const [prompt, setPrompt] = React.useState(null);
    const [promptstatus, setPromptstatus] = React.useState(0)

    // if (prompt) {
    // prompt?.userChoice
    //     .then(status => console.log('useAddToHomescreenPrompt', status.s)) //accepted / dismissed
    //     .catch(error => console.log('error', error))
        

    // }
    const promptToInstall = () => {
        if (prompt) {
            return prompt.prompt();
        }
        return Promise.reject(
            new Error(
                'Tried installing before browser sent "beforeinstallprompt" event'
            )
        );
    };

    useEffect(() => {
        (async function() {
            if (prompt) {
                setPromptstatus(await prompt.userChoice['outcome'] === 'accepted' ? 0 : 1)
            }
        })()
    }, [prompt])

    useEffect(() => {
        const ready = async (e) => {
            e.preventDefault();
            setPrompt(e);
        };     

        window.addEventListener("beforeinstallprompt", ready);

        return () => {
            window.removeEventListener("beforeinstallprompt", ready);
        };
    }, []);

    return [promptstatus, prompt, promptToInstall];
}

export default useAddToHomescreenPrompt;
