import { useEffect } from 'react'
import { IISMethods, socket } from '../config/IISMethods'

export const useSocket = (ename) => {    
    const socketEmit = (ekey, evalue) => {
        socket.emit(ekey, IISMethods.getEDD(1, evalue))
    }

    const socketOff = (ekey = ename, lister = () => {}) => socket.off(ekey, lister)

    return {
        socketEmit,
        socketOff,
        eventname: ename
    }
}

export const useSocketListener = (ename, listener) => {
    useEffect(() => {
        socket.on(ename, (data) => listener(IISMethods.getEDD(2, data)));
        return () => socketOff(ename)
    }, [])

    const socketOff = () => socket.off(ename, () => {})
}
