// Action Creators
export const clearData = () => {
    return {
        type: 'clearData'
    }
}
export const clearTimerData = () => {
    return {
        type: 'clearTimerData'
    }
}
export const setProps = (payload) => {
    return {
        type: 'setProps',
        payload
    }
}
export const updateData = (payload) => {
    return {
        type: 'updateData',
        payload
    }
}
export const clearMasterDataList = () => {
    return {
        type: 'clearMasterDataList'
    }
}
export const setLoading = (payload) => {
    return {
        type: 'setLoading',
        payload
    }
}

export const setLoadings = (payload) => {
    return {
        type: 'setLoadings',
        payload
    }
}

export const setInfo = (payload) => {
    return {
        type: 'setInfo',
        payload
    }
}