import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { store } from '..'
import { Config, IISMethods, JsCall, pagelimit, resBody, setReqParams } from '../config/IISMethods'
import { clearData, setLoading, setProps } from '../redux/actions'
import NearByView from '../view/NearByView'
import NearByTypeView from '../view/NearByTypeView'
import NearByAddressView from '../view/NearByAddressView'
import useFirebase from '../ServiceWorker/useFirebase'
import useAPICall from '../hooks/useApiCall'


let initialstate = 'nearby'

const NearByController = (props) => {

    const { getReservationDetails, getAccessToken, getPropertyData } = useAPICall()

    // for notification request
    const { requestPermission } = useFirebase()

    const getCurrentState = () => {
        return store.getState()['root']
    }
    // const pagenamea = IISMethods.getpagename()
    // console.log('pagenamea', pagenamea)

    const [pagename, setPageName] = useState(initialstate)
    // const history = useNavigate()
    // const subdomainname = IISMethods.getBookingIDSubDomain()['subdomainname']
    // const bookingid = IISMethods.getBookingIDSubDomain()['bookingid']
    let querystring = IISMethods.getQueryString()

    // useEffect(() => {
    //     async function fetchMastersJson() {
    //         await props.setProps({
    //             fieldOrder: [],
    //             newFieldOrder: [],
    //             nextpage: 0,
    //             pageNo: 1,
    //             pagelimit: 20,
    //             pageName: '',
    //             formName: '',
    //             sortData: {},
    //             formData: {},
    //             filterData: {},
    //             filterMasterDataList: {},
    //             oldFilterData: {},
    //             modal: {},
    //             rightSidebarFormData: [],
    //             masterData: {},
    //             masterDataList: {}
    //         })

    //         let guestInfo = IISMethods.getCookiesData(Config.cookiestorage['loginInfo'])
    //         if (!guestInfo || !guestInfo.querystring || (guestInfo.querystring !== querystring)) {
    //             await getAccessToken()
    //         }
    //         else {
    //             await getReservationDetails()
    //             if (getCurrentState().reservationDetails.ischeckin === 1) {
    //                 await getPropertyData()
    //                 await setFormData('nearby')
    //             }
    //         }

    //         await props.clearData()
    //     }
    //     fetchMastersJson()
    // }, [])

    useEffect(() => {

        (async function () {
            await props.setProps({
                fieldOrder: [],
                newFieldOrder: [],
                nextpage: 0,
                pageNo: 1,
                pagelimit: 20,
                pageName: '',
                formName: '',
                sortData: {},
                formData: {},
                filterData: {},
                filterMasterDataList: {},
                oldFilterData: {},
                modal: {},
                rightSidebarFormData: [],
                masterData: {},
                masterDataList: {}
            })
            //(DO NOT CHANGE WITHOUT PERMISSION)charmi(06-09-2023 12:35)(listing api calling issue fix & install application modal open issue fix)
            await props.clearData()
            await getAccessToken()
            await getReservationDetails()
            if (getCurrentState().reservationDetails.ischeckin === 1) {
                await getPropertyData()
                if (getCurrentState().reservationDetails['isreservationexpired'] !== 1) {
                    await requestPermission()
                }
                await setFormData('nearby')
            }
            //(DO NOT CHANGE WITHOUT PERMISSION)charmi(06-09-2023 12:35)(listing api calling issue fix & install application modal open issue fix)
        })()
    }, [])

    // const getAccessToken = async () => {

    //     const url = Config.weburl + 'getaccesstoken'
    //     const reqData = {}
    //     const headers = {
    //         key: "Kiosist_98F2B6E0-240A-457B-853A-A0079862315F",
    //         issuer: "website",
    //         // key: process.env.REACT_APP_KEY,
    //         // issuer: process.env.REACT_APP_ISSUER
    //     }

    //     await IISMethods.axiosrequest("POST", url, reqData, headers, successCallback, errorCallback);

    //     var resp

    //     function successCallback(response) {
    //         resp = response
    //     }
    //     function errorCallback(error) {
    //         resp = error
    //     }

    //     if (resp.status === 200) {
    //         let obj = Object.assign({}, {})
    //         if (subdomainname && bookingid) {
    //             obj.subdomainname = subdomainname
    //             obj.bookingid = bookingid
    //             obj.querystring = querystring
    //             obj.token = resp.headers.token
    //             obj.unqkey = resp.data.data.unqkey
    //             obj.uid = resp.data.data.uid
    //             obj.step = 0
    //             IISMethods.setCookiesData(Config.cookiestorage['loginInfo'], obj)
    //             await getReservationDetails()
    //             if (getCurrentState().reservationDetails.ischeckin === 1) {
    //                 await getPropertyData()
    //                 await requestPermission()
    //                 await setFormData()
    //             }                
    //         }
    //     }
    //     else {
    //         localStorage.clear()
    //     }
    // }

    // const getReservationDetails = async () => {
    //     const reqData = {
    //         paginationinfo: {
    //             pageno: 1,
    //             pagelimit: 20,
    //             filter: {
    //                 _id: bookingid
    //             },
    //             sort: {}
    //         }
    //     }
    //     if (bookingid !== null) {
    //         const url = Config.weburl + "guestexpreservation"//reservation guestexpreservation
    //         await setReqParams(url, reqData)
    //         await IISMethods.addData("reservation", "home")
    //     }

    //     if (resBody.status === 200) {
    //         await props.setProps({ reservationDetails: resBody.data[0] })
    //         if (resBody.data[0].ischeckin === 0) {
    //             history(`/notcheckedin${querystring}`)
    //         }
    //         if(getCurrentState().reservationDetails.isreservationexpired ===  1){   
    //             history({ pathname: `/linkexpired`, querystring })
    //         }
    //     } else {
    //         localStorage.clear()
    //     }
    // }

    // const getPropertyData = async () => {
    //     const url = Config.weburl + 'property'
    //     const useraction = 'listproperty'
    //     const reqBody = {

    //         paginationinfo: {
    //             pageno: 1,
    //             filter: {
    //                 _id: getCurrentState().reservationDetails.propertyid
    //             },
    //             sort: {}
    //         }
    //     }

    //     await setReqParams(url, reqBody)
    //     await IISMethods.listData(useraction)

    //     if (resBody.status === 200) {
    //         let cookiedata = IISMethods.getCookiesData(Config.cookiestorage['loginInfo'])
    //         cookiedata.companyid = resBody.data[0].companyid
    //         IISMethods.setCookiesData(Config.cookiestorage['loginInfo'], cookiedata)
    //         await props.setProps({ propertyData: resBody.data[0] })
    //     }
    // }

    //edit button formdata set - props set 
    const setFormData = async (jsoncase) => {
        //select picker data call
        getCurrentState().rightSidebarFormData = props.masterjson(jsoncase)
        for (const data of getCurrentState().rightSidebarFormData) {
            for (const fields of data.formfields) {

                if (fields.masterdata && !fields.masterdataarray) {
                    if (fields.masterdatadependancy || !getCurrentState().masterData[fields.masterdata]) //
                        await getLocationData(1, fields, getCurrentState().formData) //
                }
                else if (fields.masterdata && fields.masterdataarray && !getCurrentState().masterData[fields.masterdata]) { //
                    var array = []
                    for (const object of fields.masterdataarray) {
                        array.push({
                            label: object,
                            value: object
                        })
                    }
                    getCurrentState().masterData[fields.masterdata] = array
                    await props.setProps({ masterData: IISMethods.getCopy(getCurrentState().masterData) }) //
                }
            }
        }
    }

    //form value prepare - onblut & onclick
    const handleFormData = async (type, key, value) => {

        if (type === 'dropdown') {
            const fieldobj = JsCall.getobjectfromformdata(getCurrentState().rightSidebarFormData, key)

            if (fieldobj.masterdataarray) {
                getCurrentState().formData[key] = value ? value : ''
            }
            else {
                try {
                    getCurrentState().formData[fieldobj.formdatafield] = IISMethods.getobjectfromarray(getCurrentState().masterDataList[fieldobj.masterdata], '_id', value)[fieldobj.formdatafield]
                    getCurrentState().formData[key] = value
                }
                catch {
                    delete getCurrentState().formData[fieldobj.formdatafield]
                    delete getCurrentState().formData[key]
                }
            }
        }
        else {
            getCurrentState().formData[key] = value
        }
        await props.setProps({ formData: IISMethods.getCopy(getCurrentState().formData), pageNo: 1 })

        JsCall.validateForm(getCurrentState().formData, getCurrentState().rightSidebarFormData, key)
        const obj = JsCall.getobjectfromformdata(getCurrentState().rightSidebarFormData, key)
        if (obj.onchangefill) {
            for (const field of obj.onchangefill) {
                const obj2 = JsCall.getobjectfromformdata(getCurrentState().rightSidebarFormData, field)
                await getLocationData(1, obj2, getCurrentState().formData)
            }
        }
    }

    // getlist for near by Type data
    const getLocationData = async (pageNo, fieldobj, formData, storemasterdatabyfield, pagename) => {
        // console.log('fieldobj', fieldobj)
        var filter = {}

        var isdepent = 0
        if (fieldobj?.dependentfilter) { //dependent filter set - object set
            Object.keys(fieldobj.dependentfilter).map(key => {
                const value = formData[fieldobj.dependentfilter[key]]
                if (value) {
                    isdepent = 1
                    filter[key] = value
                }
            })
        }
        if (fieldobj.staticfilter) { //static filter object assign
            Object.assign(filter, fieldobj.staticfilter)
        }

        const masterdatakey = (fieldobj.storemasterdatabyfield || storemasterdatabyfield ? fieldobj.field : fieldobj.masterdata)

        if (!fieldobj.masterdatadependancy || isdepent == 1) { //show only non dependent dropdown or dependent dropdown dependency fullfill
            const url = Config.weburl + fieldobj.apipath
            const useraction = 'list' + fieldobj.masterdata + 'data'

            filter = {
                ...IISMethods.getCopy(fieldobj.filter),
                ...filter,
            }
            let searchtext = getCurrentState().filterData['searchtext'] || ''
            // console.log('searchtext', searchtext)
            // delete filter.searchtext
            var reqBody = {
                searchtext: searchtext,
                paginationinfo: {
                    pageno: pageNo,
                    pagelimit: getCurrentState().pagelimit,
                    filter: filter,
                    sort: {}
                }
            }

            await setReqParams(url, reqBody)

            await IISMethods.listData(useraction, true)

            if (resBody.status === 200) {
                getCurrentState().masterData = IISMethods.getCopy(getCurrentState().masterData)
                getCurrentState().masterDataList = IISMethods.getCopy(getCurrentState().masterDataList)

                if (pageNo === 1) {
                    getCurrentState().masterData[masterdatakey] = []
                    getCurrentState().masterDataList[masterdatakey] = []
                }

                resBody.data.map((data) =>

                    getCurrentState().masterData[masterdatakey].push({ label: printSelectPicker(data, fieldobj), value: data._id })
                )
                getCurrentState().masterDataList[masterdatakey] = getCurrentState().masterDataList[masterdatakey].concat(resBody.data)
                await props.setProps({
                    masterData: getCurrentState().masterData,
                    masterDataList: getCurrentState().masterDataList,
                    nextpage: resBody.nextpage,
                    formName: resBody.formname,   // keval 7-4-2023
                    pageName: resBody.pagename,
                })
                if (resBody.data.length > 0) {
                    if (fieldobj.field === 'locationid') {
                        setPageName('nearby')
                    }
                    else if (fieldobj.field === 'locationtypeid') {
                        setPageName('nearbytype')
                    }
                    else if (fieldobj.field === "nearbyaddress") {
                        // debugger
                        setPageName('nearbyaddress')
                    }
                }
            }
            else {
            }
        }
        else {
            delete getCurrentState().masterData[masterdatakey]
            delete getCurrentState().masterDataList[masterdatakey]
            await props.setProps({ masterData: IISMethods.getCopy(getCurrentState().masterData), masterDataList: IISMethods.getCopy(getCurrentState().masterDataList) })
        }
    }

    //select picker printing 
    const printSelectPicker = (data, fieldobj) => {
        return (
            `${data[fieldobj.masterdatafield]}`
        )
    }

    //for pagination in nearBy page
    const handleScroll = async (e, key) => {
        let element = e.target
        // if (element.scrollHeight - element.scrollTop === element.clientHeight || ((element.scrollHeight - element.scrollTop) - 1 === element.clientHeight)) {
        if (element.scrollHeight - element.scrollTop === element.clientHeight || ((element.scrollHeight - element.scrollTop) > element.clientHeight)) {
            if (getCurrentState().nextpage && (getCurrentState().pageNo * getCurrentState().pagelimit === Object.keys(getCurrentState().masterDataList[key]).length)) {
                await props.setProps({ pageNo: getCurrentState().pageNo + 1 })
                if (key === 'nearby') {
                    await getLocationData(getCurrentState().pageNo, getCurrentState().rightSidebarFormData[0].formfields[0], getCurrentState().formData)
                }
                else if (key === 'nearbytype') {
                    await getLocationData(getCurrentState().pageNo, getCurrentState().rightSidebarFormData[0].formfields[1], getCurrentState().formData)
                }
                else if (key === "nearbyaddress") {
                    await getLocationData(getCurrentState().pageNo, getCurrentState().rightSidebarFormData[0].formfields[2], getCurrentState().formData)
                }
            }
        }
    }
    // jatin 15-08-2023 for  Search
    const handleFilterData = async (type, key, value) => {
        getCurrentState().filterData[key] = value
        await props.setProps({ filterData: IISMethods.getCopy(getCurrentState().filterData), pageNo: 1 })
        // await getList()
    }
    // jatin 15-08-2023 for Search
    const handleApplyFilterButtonClick = async (e, type) => {
        if (e) e.preventDefault()
        if (type === 'removevalue') {
            getCurrentState().filterData['searchtext'] = ''
            await props.setProps({ pageNo: 1, oldFilterData: IISMethods.getCopy(getCurrentState().filterData) })
            await props.clearData()
            if (pagename === 'nearbytype') {
                await getLocationData(getCurrentState().pageNo, getCurrentState().rightSidebarFormData[0].formfields[1], getCurrentState().formData)
            }
            else if (pagename === 'nearbyaddress') {
                await getLocationData(getCurrentState().pageNo, getCurrentState().rightSidebarFormData[0].formfields[2], getCurrentState().formData)
            }
        }
        else {
            await props.setProps({ pageNo: 1, oldFilterData: IISMethods.getCopy(getCurrentState().filterData) })
            await props.clearData()
            if (pagename === 'nearbytype') {
                await getLocationData(getCurrentState().pageNo, getCurrentState().rightSidebarFormData[0].formfields[1], getCurrentState().formData)
            }
            else if (pagename === 'nearbyaddress') {
                await getLocationData(getCurrentState().pageNo, getCurrentState().rightSidebarFormData[0].formfields[2], getCurrentState().formData)
            }
        }
    }
     // jatin 15-08-2023 11:53 PM
     const handleSerachClose = async()=>{
        getCurrentState().filterData["searchtext"] = ""
        await props.setProps({ filterData: IISMethods.getCopy(getCurrentState().filterData), pageNo: 1 })     
        if (pagename === 'nearbytype') {
            await getLocationData(getCurrentState().pageNo, getCurrentState().rightSidebarFormData[0].formfields[1], getCurrentState().formData)
        }
        else if (pagename === 'nearbyaddress') {
            await getLocationData(getCurrentState().pageNo, getCurrentState().rightSidebarFormData[0].formfields[2], getCurrentState().formData)
        }

     }

    return (
        <>
            {
                (pagename === 'nearbyaddress' || pagename === 'nearbydetail')
                    ?
                    <NearByAddressView
                        setFormData={setFormData}
                        handleScroll={handleScroll}
                        pagename={pagename}
                        setPageName={setPageName}
                        handleFilterData={handleFilterData}
                        handleApplyFilterButtonClick={handleApplyFilterButtonClick}
                        handleSerachClose={handleSerachClose}
                    />
                    :
                    (pagename === 'nearbytype') ?
                        <NearByTypeView
                            setFormData={setFormData}
                            handleFormData={handleFormData}
                            handleScroll={handleScroll}
                            pagename={pagename}
                            setPageName={setPageName}
                            handleFilterData={handleFilterData}
                            handleApplyFilterButtonClick={handleApplyFilterButtonClick}
                            handleSerachClose={handleSerachClose}
                        />
                        :
                        <NearByView
                            setFormData={setFormData}
                            handleFormData={handleFormData}
                            handleScroll={handleScroll}
                        />
            }
        </>
    )
}

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    clearData: (payload) => dispatch(clearData(payload)),
    setProps: (payload) => dispatch(setProps(payload)),
    setLoading: (payload) => dispatch(setLoading(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NearByController)
