import React, { useEffect } from 'react'
import BackHeader from '../BackHeader'
import Successfulchecked from '../assets/images/successfullchecked.png'
import useFirebase from '../ServiceWorker/useFirebase'
import { store } from '../index'
import { Config, IISMethods } from '../config/IISMethods'
import { useNavigate } from 'react-router-dom'

const SuccessfullyCheckoutView = (props) => {
    const { removeToken } = useFirebase()
    const history = useNavigate()

    const getCurrentState = () => store.getState()

    useEffect(() => {
        (async function () {
            if (getCurrentState().reservationDetails.ischeckin === 1) await removeToken()
        })()
    }, [])

    return (
        <>
            <div className="row body-fix-height checkout-content">
                <div className="col-11 col-md-9 col-lg-10 mx-auto">
                    <div className='completed-content'>
                        <img src={Successfulchecked} className='scheckout-img only-scheckout' alt="" />
                        <div className="col-9 mx-auto mt-5">
                            {
                                (IISMethods.getCookiesData(Config.cookiestorage['loginInfo']) && IISMethods.getCookiesData(Config.cookiestorage['loginInfo']).bookingid !== (undefined || ''))
                                &&
                                <>
                                    <a onClick={() => history({ pathname: `/home` })} className="btn btn-primary-dark btn-sm success-back-btn">
                                        <span class="material-icons-outlined text-20">keyboard_backspace</span>
                                        <span>Back To Home</span>
                                    </a>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default SuccessfullyCheckoutView